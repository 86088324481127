import { ITaskType } from "../Task";
import { RunCustomerPOST_PUT, RunCustomerOrderPOST_PUT, RunCustomerOrderSimple } from "./Run";

export class RunTemplateMainFilter {
	dayOfWeek = 1
}

/** LIST ITEM */
export interface IRunTemplateListItem {
	id: number;
	dayOfWeek: number;
	runNumberCombined: string;
	startTime: string;
	startingDepotName: string;
	budgetedTime: number;
	area: string;
	isPreLoaded: boolean;
	budgetedKm?: number;
}

export interface IRunTemplateCustomer {
	id: number;
	runNumber: string;
	runTypeId: number;
	runTypeName: string;
}

export class RunTemplateCustomerForm {
	id?: number;
	runNumber: string;
	runTypeId: string;
	runTypeName?: string;

	constructor(runCustomer?: IRunTemplateCustomer) {
		this.id = runCustomer?.id;
		this.runNumber = runCustomer?.runNumber || '';
		this.runTypeId = runCustomer?.runTypeId?.toString() || '';
		this.runTypeName = runCustomer?.runTypeName;
	}
}

export interface IRunTemplateCustomerOrder {
	runTemplateCustomerId: number;
	placeId: number;
	placeName: string;
	deliverySequence: number;
	storeNumber: string;
}

export class RunTemplateCustomerOrderForm {
	runTemplateCustomerId?: number;
	placeId: string;
	placeName?: string;
	deliverySequence: number;
	storeNumber?: string;

	constructor(runCustomerOrder?: IRunTemplateCustomerOrder) {
		this.runTemplateCustomerId = runCustomerOrder?.runTemplateCustomerId;
		this.placeId = runCustomerOrder?.placeId?.toString() || '';
		this.placeName = runCustomerOrder?.placeName;
		this.deliverySequence = runCustomerOrder?.deliverySequence || 1;
		this.storeNumber = runCustomerOrder?.storeNumber;
	}
}

/** DETAILS */
export interface IRunTemplateDetails {
  id: number;
	dayOfWeek: number;
	startTime: string;
	startingDepotId: number;
	startingDepotName: string;
	taskTypeId: number;
	taskTypeName: string;
	TaskType?: ITaskType;
	budgetedTime: number;
	area: string;
	runNumberCombined: string;
	isPreLoaded: boolean;
	budgetedKm?: number;

	RunCustomers: IRunTemplateCustomer[];
	RunCustomerOrders: IRunTemplateCustomerOrder[];
}


/** FORM */
export class RunTemplateFormClass {
  id?: number;
	dayOfWeek: string;
	startTime: string;
	budgetedTime: string;
	area: string;
	runNumberCombined: string;
	isPreLoaded: boolean;
	budgetedKm: string;
	
	taskTypeId: string;
	taskTypeName?: string;
	TaskType?: ITaskType;

	startingDepotId: string;
	startingDepotName?: string;
	RunCustomers: RunTemplateCustomerForm[];
	RunCustomerOrders: RunTemplateCustomerOrderForm[];

	constructor(run?: IRunTemplateDetails) {
		this.id = run?.id;
		this.dayOfWeek = run?.dayOfWeek.toString() || '';
		this.startTime = run?.startTime || '';
		this.budgetedTime = run?.budgetedTime.toString() || '';
		this.area = run?.area || '';
		this.runNumberCombined = run?.runNumberCombined || '';
		this.isPreLoaded = run?.isPreLoaded || false;
		this.budgetedKm = run?.budgetedKm?.toString() || '';
		
		this.taskTypeId = run?.taskTypeId.toString() || '';
		this.taskTypeName = run?.taskTypeName;

		this.startingDepotId = run?.startingDepotId.toString() || '';
		this.startingDepotName = run?.startingDepotName;

		this.TaskType = run?.TaskType;
		this.RunCustomers = run?.RunCustomers ? run.RunCustomers.map(runCustomer => new RunTemplateCustomerForm(runCustomer)) : [new RunTemplateCustomerForm()];
		this.RunCustomerOrders = run?.RunCustomerOrders && run.RunCustomerOrders.length > 0 ? run.RunCustomerOrders.map(order => new RunTemplateCustomerOrderForm(order)) : [new RunCustomerOrderSimple(1)];
	}
}

/**
 * HTTP REQUESTS
 */

export class RunTemplateCustomerPOST_PUT {
	id?: number;
	runTypeId: number;
	runNumber: string;

	constructor(runCustomer: RunTemplateCustomerForm) {
		this.id = runCustomer.id;
		this.runTypeId = parseInt(runCustomer.runTypeId);
		this.runNumber = runCustomer.runNumber;
	}
}

export class RunTemplateCustomerOrderPOST_PUT {
	id?: number;
	deliverySequence: number;
	placeId: number;

	constructor(runCustomer: RunTemplateCustomerOrderForm) {
		this.deliverySequence = runCustomer.deliverySequence;
		this.placeId = parseInt(runCustomer.placeId);
	}
}

 export class RunTemplatePOST_PUT {
	id?: number;
	dayOfWeek: number;
	startTime: string;
	startingDepotId: number;
	budgetedTime: number;
	taskTypeId: number;
	area: string;
	isPreLoaded: boolean;
	budgetedKm?: number;

	RunCustomers: RunCustomerPOST_PUT[];
	RunCustomerOrders: RunCustomerOrderPOST_PUT[];
	
	constructor(runTemplate: RunTemplateFormClass) {
		this.id = runTemplate.id;
		this.dayOfWeek = parseInt(runTemplate.dayOfWeek);
		this.startTime = runTemplate.startTime;
		this.startingDepotId = parseInt(runTemplate.startingDepotId);
		this.budgetedTime = parseFloat(runTemplate.budgetedTime);
		this.taskTypeId = parseInt(runTemplate.taskTypeId);
		this.area = runTemplate.area;
		this.isPreLoaded = runTemplate.isPreLoaded;
		this.budgetedKm = parseInt(runTemplate.budgetedKm);
		this.RunCustomers = runTemplate.RunCustomers.map(runCustomer => new RunTemplateCustomerPOST_PUT(runCustomer));
		this.RunCustomerOrders = runTemplate.RunCustomerOrders.map(runCustomerOrder => new RunTemplateCustomerOrderPOST_PUT(runCustomerOrder));
	}
}