/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import WorkerApi from '../../../app/api/WorkerApi';
import { UserProfilePicture, Loader } from './';
import WorkerDetailsModal from '../../features/management/workers/subComponents/WorkerDetailsModal';
import componentRequestHandler from '../../../app/api/helpers/componentRequestHandler';

const propTypes = {
	azureId: PropTypes.string.isRequired,
	children: PropTypes.any.isRequired,
	showDetailsModal: PropTypes.bool,
	showPopover: PropTypes.bool,
	//container: PropTypes.any
};

const defaultProps = {
	showDetailsModal: true,
	showPopover: true
};

class UserDetailsLink extends Component {
	constructor(props) {
		super(props);
		this.state = {
			workerData: null,
			showDetailsModal: false,
			isLoading: true,
			error: null
		};
	}

	componentWillUnmount() {
		this.unmounted = true;    
	}

_handleClickLink = () => {
	if (this.props.showDetailsModal)
		this.setState({ showDetailsModal: true });
}

_handleCloseDetails = () => {
	this.setState({ showDetailsModal: false });
}

fetchWorkerData = () => {
	if (!this.fetchAllowed || (this.state.workerData && this.props.azureId === this.state.workerData.azureId))
		return;
	
	const promise = () => WorkerApi.getWorkerDetails(this.props.azureId, false);
	componentRequestHandler(this, promise, 'workerData');
}

handleEnter = () => {
	this.fetchAllowed = true;
	setTimeout(this.fetchWorkerData, 300);
}

handleExit = () => {
	this.fetchAllowed = false;
}

handleLoadPhoto = userPhotoBase64 => {
	this.setState({ userPhotoBase64 });
}

render() {
	const { state, props } = this;
	
	const popoverContent = (
		<Popover style={{ minHeight: 100, minWidth: 230 }} id="user-details-popover">
			<div className="widget-user-2">
				{
					!state.workerData || state.isLoading ? (
						<Loader />
					) : (
						<div>
							<UserProfilePicture 
								azureId={props.azureId} 
								// userPhotoBase64={state.userPhotoBase64}
								divClassName="widget-user-image" 
								// onLoadPhoto={this.handleLoadPhoto}
							/>
							<h4 style={{ fontSize: '16px' }} className="widget-user-username"><b>{ state.workerData.shortName }</b></h4>
							<ul className="list list-unstyled widget-user-desc">
								{ state.workerData.currentRoleName && <li>{ state.workerData.currentRoleName }</li> }
								{ 
									state.workerData.currentContactNumber && (
										<li>
											<a href={`tel:${state.workerData.currentContactNumber}`}>{ state.workerData.currentContactNumber }</a>
										</li> 
									)
								}
							</ul>
						</div>
					)
				}
			</div>
		</Popover>
	);

	const userNameLink = <a onClick={this._handleClickLink}>{ props.children }</a>;

	return (
		<u title={state.workerData ? state.workerData?.shortName : "Loading..."}>
			{
				props.showPopover ? (
					<OverlayTrigger 
						rootClose
						trigger={['hover', 'focus']}
						placement="top" 
						overlay={popoverContent}
						onEnter={this.handleEnter}
						onExit={this.handleExit}
					>
						{ userNameLink }
					</OverlayTrigger>
				) : (
					userNameLink
				)
			}
			{
				props.showDetailsModal && state.showDetailsModal && (
					<WorkerDetailsModal
						hideWorkerLeftReason
						isPictureChangeable={false}
						show={state.showDetailsModal} 
						azureId={props.azureId}
						onClose={this._handleCloseDetails} />
				)
			}
		</u>
	);
}
}

UserDetailsLink.propTypes = propTypes;
UserDetailsLink.defaultProps = defaultProps;

export { UserDetailsLink };