import Moment from "moment";
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment as any);


/**
 * Determines if the given start and finish dates fall within a block-out period.
 * Block-out periods include Christmas (December and January) and Easter (March and April).
 * If the leave request period overlaps with a block-out period, the function returns the corresponding block-out period.
 * Otherwise, it returns undefined.
 *
 * @param startDate - The start date of the leave request period in string format.
 * @param finishDate - The finish date of the leave request period in string format.
 * @returns The block-out period if the leave request period overlaps with a block-out period, otherwise undefined.
 * @throws Error if the start or finish date is invalid.
 */
export function isBlockOutPeriod(startDate: string, finishDate: string) {
	if (!moment(startDate).isValid())
		throw new Error('Invalid Start Date');

	if (!moment(finishDate).isValid())
		throw new Error('Invalid Finish Date');

	if (!startDate || !finishDate)
		return;

	// Block-out period to return if the leave request period overlaps with a block-out period
	let blockOutPeriod;
	
	const $startDate = moment(startDate);
	const $finishDate = moment(finishDate);
	const startDateMonth = $startDate.month() + 1; // + 1 because moment.month() returns 0-11
	const leaveRequestPeriod = moment.range($startDate, $finishDate);

	// Christmas period (December and January)
	if ([12, 1].indexOf(startDateMonth) !== -1) {

		// If the leave request period starts in December, the year of the christmas period is the same as the start date year
		// If the leave request period starts in January, the year of the christmas period is the previous year
		const year = startDateMonth === 1 ? $startDate.year() - 1 : $startDate.year();

		// Last 2 weeks of december and first 2 weeks of january
		const $mondayOfWeek50 = moment().year(year).isoWeek(51).startOf('isoWeek');
		const $sunday4WeeksAfterWeek50 = moment($mondayOfWeek50).isoWeek(51).add(4, 'weeks').endOf('isoWeek');
		const christmasPeriodToBlock = moment.range($mondayOfWeek50, $sunday4WeeksAfterWeek50);

		// Check if the leave request period overlaps with the christmas period
		if (leaveRequestPeriod.overlaps(christmasPeriodToBlock, { adjacent: true }))
			blockOutPeriod = `From Monday ${$mondayOfWeek50.format('DD/MM/YYYY')} to Sunday ${$sunday4WeeksAfterWeek50.format('DD/MM/YYYY')}`;
	}
	// Easter period (March and April)
	else if ([3, 4].indexOf(startDateMonth) !== -1) {
		// last 2 weeks of march and first 2 weeks of april
		const mondayOfWeek13 = moment(startDate).isoWeek(12).startOf('isoWeek');
		const sunday4WeeksAfterWeek13 = moment(mondayOfWeek13).add(4, 'weeks').endOf('isoWeek');
		const easterPeriodToBlock = moment.range([mondayOfWeek13, sunday4WeeksAfterWeek13]);

		// Check if the leave request period overlaps with the easter period
		if (easterPeriodToBlock.overlaps(leaveRequestPeriod, { adjacent: true }))
			blockOutPeriod = `From Monday ${mondayOfWeek13.format('DD/MM/YYYY')} to Sunday ${sunday4WeeksAfterWeek13.format('DD/MM/YYYY')}`;
	}
	

	return blockOutPeriod;
}