import React, { Component } from 'react';
import { CheckboxList } from './CheckboxList';
import componentRequestHandler from '../../../app/api/helpers/componentRequestHandler';
import ListsApi from '../../../app/api/ListsApi';
import { ErrorBox, Loader } from '../uiElements';

export default class PlaceAccessTypes extends Component {
	state = {
		placeAccessTypes: [],
		isLoading: false,
		error: null
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData = () => {
		componentRequestHandler(this, ListsApi.getAccessPointTypesList, 'placeAccessTypes');
	}

	render() {
		const { state, props } = this;

		if (state.isLoading)
			return <Loader small left />;

		if (state.error)
			return <ErrorBox error={state.error} retryFunc={this.fetchData} />;

		return (
			<CheckboxList 
				{ ...props }
				items={state.placeAccessTypes.map(({ id, name }) => ({ text: name, value: id }))}
			/>
		);
	}
}