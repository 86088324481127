import React, { Fragment, ChangeEvent } from 'react';
import { ITaskType } from '../../../../../app/models/Task';
import { Modal, Button, FormControl } from 'react-bootstrap';
import { Loader, ErrorBox } from '../../../../common/uiElements';
import componentRequestHandler from '../../../../../app/api/helpers/componentRequestHandler';
import { IWorkerBasicInfo } from '../../../../../app/models/Worker';
import { IError, IReactSelectReturn } from '../../../../../app/models/Application';
import RunTemplateForm from './RunTemplateForm';
import { RunTemplateFormClass, RunTemplatePOST_PUT } from '../../../../../app/models/Run/RunTemplate';
import { RunCustomerOrderSimple, RunCustomerSimple } from '../../../../../app/models/Run/Run';
import { ItemInterface } from 'react-sortablejs';
import RunTemplateApi from '../../../../../app/api/RunTemplateApi';
import FontAwesome from 'react-fontawesome';
import confirm from '../../../../../app/helpers/confirm';
import $ from 'jquery';

interface IProps {
	runTemplateId?: number;
	onClose: () => void;
	onSaveComplete?: (date: string) => void;
}

interface IState {
	details: RunTemplateFormClass;
	enableEdit: boolean;
	isSaving: boolean;
	isLoading: boolean;
	errorSaving?: IError;
	errorLoading?: IError;
}

class RunTemplateModal extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = { 
			details: new RunTemplateFormClass(),
			enableEdit: false,
			isSaving: false,
			isLoading: false,
			errorSaving: undefined,
			errorLoading: undefined
		};
	}

	componentDidUpdate(prevProps: IProps) {
		if (this.props.runTemplateId !== prevProps.runTemplateId)
			this.fetchDetails();
	}	

	componentDidMount() {
		this.fetchDetails();
	}

	fetchDetails = () => {
		const { runTemplateId } = this.props;
		if (!runTemplateId) return;

		this.setState({ isLoading: true, errorLoading: undefined });
		RunTemplateApi.getDetails(runTemplateId)
			.then(details => this.setState({ details: new RunTemplateFormClass(details) }))
			.catch(error => this.setState({ errorLoading: error }))
			.finally(() => this.setState({ isLoading: false }));
	}

	saveNew = () => {
		const data = new RunTemplatePOST_PUT(this.state.details);
		const promise = () => RunTemplateApi.createRunTemplate(data);
		
		componentRequestHandler(this, promise, undefined, {
			loadingAttrName: 'isSaving',
			errorAttrName: 'errorSaving',
			showSuccessNotification: true
		})
		.then(this.props.onSaveComplete);
	}

	saveChanges = () => {
		const { details } = this.state;
		if (!details.id) 
			return;

		const promise = () => RunTemplateApi.updateRunTemplate(details.id!, new RunTemplatePOST_PUT(details));

		componentRequestHandler(this, promise, undefined, {
			loadingAttrName: 'isSaving',
			errorAttrName: 'errorSaving',
			showSuccessNotification: true
		})
		.then(this.props.onSaveComplete);
	}

	delete = () => {
		const { details } = this.state;
		if (!details.id) 
			return;

		const promise = () => RunTemplateApi.deleteRunTemplate(details.id!);

		componentRequestHandler(this, promise, undefined, {
			loadingAttrName: 'isSaving',
			errorAttrName: 'errorSaving',
			showSuccessNotification: true
		})
		.then(this.props.onSaveComplete);
	}

	changeForm = (newData: object) => {
		return new Promise(resolve => {
			this.setState({
				details: {
					...this.state.details,
					...newData
				}
			}, resolve);
		});
	}

	toggleEnableEdit = () => {
		this.setState({ enableEdit: !this.state.enableEdit });
	}
	
	handleChangeInput = (id: string, value: string | boolean) => {
		this.changeForm({ [id]: value });
	}

	handleChangeWorker = (Worker?: IWorkerBasicInfo) => {
		this.changeForm({
			workerId: Worker?.azureId,
			Worker: Worker?.azureId ? Worker : undefined
		});
	}

	handleChangeTaskType = (e: ChangeEvent<FormControl & HTMLInputElement>, text?: string, TaskType?: ITaskType) => {
		const RunCustomerOrders = this.state.details.RunCustomerOrders || [];
		this.changeForm({ 
			taskTypeId: e.target.value, 
			TaskType, 
			RunCustomers: [new RunCustomerSimple()],
			RunCustomerOrders: [new RunCustomerOrderSimple(RunCustomerOrders.length + 1)] 
		});
	}

	handleChangeStartTime = (startTime: string) => {
		this.changeForm({ startTime });
	}

	handleClickAddNewRunCustomer = () => {
		const RunCustomers = this.state.details.RunCustomers || [];
		RunCustomers.push(new RunCustomerSimple());
		this.changeForm({ RunCustomers });
	}

	handleClickRemoveRunCustomer = (runCustomerIndex: number) => {
		const RunCustomers = this.state.details.RunCustomers;
		if (!RunCustomers) return;

		RunCustomers.splice(runCustomerIndex, 1);
		this.changeForm({ RunCustomers });
	}

	handleChangeRunCustomer = (e: ChangeEvent<FormControl & HTMLInputElement>, runCustomerIndex: number) => {
		const RunCustomers = this.state.details.RunCustomers;
		if (!RunCustomers)
			return;

		const { id, value } = e.target;
		RunCustomers[runCustomerIndex] = {
			...RunCustomers[runCustomerIndex],
			[id]: value
		};

		this.changeForm({ RunCustomers })
		.then(() => {
			if (id === "runTypeId") 
				this.changeForm({ RunCustomerOrders: [new RunCustomerOrderSimple(1)] })
		});
	}

	handleClickAddNewRunCustomerOrder = () => {
		const RunCustomerOrders = this.state.details.RunCustomerOrders || [];
		RunCustomerOrders.push(new RunCustomerOrderSimple(RunCustomerOrders.length + 1));
		this.changeForm({ RunCustomerOrders });
	}

	handleClickRemoveRunCustomerOrder = (runCustomerOrderIndex: number) => {
		const { RunCustomerOrders } = this.state.details;
		if (!RunCustomerOrders) return;

		RunCustomerOrders.splice(runCustomerOrderIndex, 1);
		this.changeForm({ RunCustomerOrders })
		.then(this.reorganizeDeliverySequence);
	}

	handleChangeRunCustomerOrder = (obj: IReactSelectReturn, runCustomerOrderIndex: number) => {
		const { RunCustomerOrders } = this.state.details;
		if (!RunCustomerOrders) return;

		const { id, value } = obj;
		RunCustomerOrders[runCustomerOrderIndex] = {
			...RunCustomerOrders[runCustomerOrderIndex],
			[id]: value
		};

		this.changeForm({ RunCustomerOrders });
	}

	handleChangeSequenceRunCustomerOrder = (RunCustomerOrders: ItemInterface[]) => {
		this.changeForm({ RunCustomerOrders })
			.then(this.reorganizeDeliverySequence);
	}

	reorganizeDeliverySequence = () => {
		const { RunCustomerOrders } = this.state.details;
		RunCustomerOrders?.forEach((order, index) => order.deliverySequence = index + 1);
		this.changeForm({ RunCustomerOrders });
	}

	handleCancelEditMode = () => {
		this.toggleEnableEdit();
		this.fetchDetails();
	}

	handleDelete = () => {
		confirm('Are you sure you want to delete this run from the template?', this.delete);
	}

	handleSave = () => {
		const $form = $('#form');
		if ($form.length > 0 && !$form.valid())
			return;

		if (this.props.runTemplateId)
			this.saveChanges();
		else
			this.saveNew();
	}
	
	renderBodyContent = () => {
		const { state, props } = this;

		if (state.errorLoading)
			return <ErrorBox error={state.errorLoading} retryFunc={this.fetchDetails} />;

		if (state.isSaving || state.isLoading)
			return <Loader isLoading={state.isLoading} isSaving={state.isSaving} />;

		return (
			<Fragment>
				{
					state.errorSaving && (
						<ErrorBox 
							error={state.errorSaving} 
							retryFunc={props.runTemplateId ? this.saveChanges : this.saveNew} 
						/>
					)
				}
				<RunTemplateForm 
					{...state.details}
					// isReadOnly={props.runTemplateId && !state.enableEdit ? true : false}

					formId="form"
					onChangeInput={this.handleChangeInput}
					onChangeTaskType={this.handleChangeTaskType}
					onChangeStartTime={this.handleChangeStartTime}
					onClickAddNewRunCustomer={this.handleClickAddNewRunCustomer}
					onClickRemoveRunCustomer={this.handleClickRemoveRunCustomer}
					onClickAddNewRunCustomerOrder={this.handleClickAddNewRunCustomerOrder}
					onClickRemoveRunCustomerOrder={this.handleClickRemoveRunCustomerOrder}
					onChangeRunCustomer={this.handleChangeRunCustomer}
					onChangeRunCustomerOrder={this.handleChangeRunCustomerOrder}
					onChangeSequenceRunCustomerOrder={this.handleChangeSequenceRunCustomerOrder}
				/>
			</Fragment>
		);
	}

	render() {
		const { state, props } = this;
		
		return (
			<Modal
				show
				id="run-template-modal"
				backdrop="static"
				bsSize="large"
				onHide={props.onClose}
				onEscapeKeyDown={props.onClose}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{ props.runTemplateId ? `Update Run #${props.runTemplateId}` : 'Add New Run' }
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ this.renderBodyContent() }
				</Modal.Body>
				{
					!state.isSaving && !state.isLoading && (
						<Modal.Footer>
							{
								!state.errorLoading && (
									<>
										<Button 
											bsStyle="success"
											className="pull-left"
											bsSize="lg"
											onClick={this.handleSave}
										>
											<FontAwesome name="floppy-o" />&nbsp;&nbsp;
											{ state.details?.id ? 'Save Changes' : 'Save Run' }
										</Button>
										{
											state.details?.id && (
												<Button
													bsStyle="danger"
													onClick={this.handleDelete}
												>
													<FontAwesome name="trash" /> Delete Run Permanently from Template
												</Button>
											)
										}
									</>
								)
							}
							<Button bsSize="lg" onClick={props.onClose}>
								<FontAwesome name="sign-out" /> Close Without Saving
							</Button>
						</Modal.Footer>
					)
				}
			</Modal>
		);
	}
}

export default RunTemplateModal;