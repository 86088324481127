import React, { Component } from 'react';
import ReactSelect from 'react-select';
import { ErrorBox } from '../uiElements';
import componentRequestHandler from '../../../app/api/helpers/componentRequestHandler';
import { IError, IReactSelectOption, IReactSelectProps } from '../../../app/models/Application';
import { IPlacesListItem } from '../../../app/models/Place';
import { isEqual } from 'lodash';
import ListsApi from '../../../app/api/ListsApi';
import { FormControl } from 'react-bootstrap';

interface IProps extends IReactSelectProps {  
	type: 'preStart' | 'deliveryStores' | 'palletJackLiveMonitoring' | 'rosterStartingDepot'
	runTypes?: number[];
	placeCategoryId?: string;
	style?: React.CSSProperties;
}

interface IState {
	originalList: IPlacesListItem[];
	options: IReactSelectOption[]
	isLoading: boolean;
	error?: IError;
}

export class PlacesListNew extends Component<IProps, IState> {
	state = {
		isLoading: false,
		error: undefined,
		originalList: [] as IPlacesListItem[],
		options: [] as IReactSelectOption[]
	};

	componentDidUpdate(prevProps: IProps) {
		if (!isEqual(prevProps.runTypes, this.props.runTypes))
			this.fetchData();
	}	

	componentDidMount() {
		this.fetchData();
	}

	fetchData = () => {
		let promise;

		switch (this.props.type) {
			case 'preStart':
				promise = ListsApi.getPreStartDepots.bind(this);
				break;
			case 'palletJackLiveMonitoring':
				promise = ListsApi.getPalletJackLiveMonitoringDepots.bind(this);
				break;
			case 'rosterStartingDepot':
				promise = ListsApi.getRosterStartingDepots.bind(this);
				break;
			case 'deliveryStores':
				promise = ListsApi.getPlacesByRunType.bind(this, this.props.runTypes);
				break;
			default:
				promise = ListsApi.getPlacesList.bind(this, this.props.placeCategoryId);
		}
		
		componentRequestHandler(this, promise, 'originalList')
			.then(this.handleLoadList)
	}

	getValue = () => 
		this.state.options.find(p => p.value === this.props.value?.toString())

	handleLoadList = (list: IPlacesListItem[]) => {
		this.setState({ 
			options: list.map(p => ({ 
				value: p.id.toString(), 
				label: p.name + (p.storeNumber && p.storeNumber !== "0" ? ` (${p.storeNumber})` : ''),
				fullObject: p  
			}))
		});
		this.props.onLoadList && this.props.onLoadList(list);
	}

	handleChange = (selected?: any) => {
		this.props.onChange && this.props.onChange({ id: this.props.id, ...selected });
	}
	
	render () {
		const { state, props } = this;
		
		if (state.error)
			return <ErrorBox error={state.error} retryFunc={this.fetchData} />;

		return (
			<>
				<ReactSelect  
					isLoading={state.isLoading}
					placeholder={props.placeholder || (state.isLoading ? 'Loading...' : '--- SELECT ---')}
					noResultsText={state.isLoading ? 'Loading...' : 'No results found'}
					options={state.options} 
					value={this.getValue()}
					onChange={this.handleChange}
					style={props.style}
				/>
				<FormControl 
					id={props.id} 
					required={props.required} 
					value={(props.value || '')} 
					type="hidden" 
				/>
			</>
		);
	}
}