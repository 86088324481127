import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Icon from 'react-fontawesome';

import ServicingApi from '../../../app/api/ServicingApi';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';

import { MainContent } from '../../common/layout';
import { FiltersBox, Loader } from '../../common/uiElements';
import { DatePeriodInput, VehicleTypes as VehicleTypesInput } from '../../common/inputs';
import { ServicingCountersSettings } from '../../../app/schemas/ReportsSettings';
import { VehicleTypes } from '../../../app/models/Vehicle';

class ServicingCounters extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vehTypeFilter: [
				VehicleTypes.PALLET_JACK,
				VehicleTypes.RIGID,
				VehicleTypes.TRAILER,
				VehicleTypes.PRIME_MOVER,
				VehicleTypes.B_DOUBLE,
				VehicleTypes.DOLLY
			],
			currentCountersFilter: true,
			isLoading: false,
			file: null
		};
	}
	
	getCurrentCounters = vehTypeFilter => {
		this.setState({ isLoading: true });
		ServicingApi.countersTotalXls(vehTypeFilter)
			.then(() => {
				if (this.unmounted) return;
				
				this.setState({ isLoading: false });
			})
			.catch(error => {
				console.error(error);
				this.setState({ isLoading: false });
			});
	}
	
	getCountersByPeriod = (vehTypeFilter, startTime, endTime) => {
		this.setState({ isLoading: true });
		ServicingApi.countersByDateXls(vehTypeFilter, startTime, endTime)
			.then(() => {
				if (this.unmounted) return;
				this.setState({ isLoading: false });
			})
			.catch(error => {
				console.error(error);
				this.setState({ isLoading: false });
			});
	}

	getTimeOffSet = () => {
		return moment().utcOffset() / 60;
	}

	onSearch = () => {
		// Current Counters
		if (this.state.currentCountersFilter) {
			this.getCurrentCounters(this.state.vehTypeFilter);
		} 
		// By Period
		else {
			var { startTime, endTime } = this.props.filters;
			this.getCountersByPeriod(this.state.vehTypeFilter, startTime, endTime);
		}
		
	}

	onToggleFilterType = () => {
		this.setState({
			currentCountersFilter: !this.state.currentCountersFilter
		});
	}

	render() {
		const s = this.state;
	
		return (
			<MainContent title="Servicing Counters" subtitle="">
				<FiltersBox>
					<Row>
						<Col xs={12}>
							<div ref={ref => this.vehicleTypes = ref}>
								<FormGroup>
									<label>Vehicle Types</label>
									<VehicleTypesInput
										value={s.vehTypeFilter}
										onChange={vehTypeFilter => this.setState({ vehTypeFilter })}
									/>
								</FormGroup>
							</div>
						</Col>
					</Row>
					<Row>
						<Col sm={12} lg={8}>
							<Col xs={6} className="no-padding-left">
								<FormGroup>
									<Button
										block
										bsStyle={s.currentCountersFilter ? 'success' : 'default' }
										onClick={this.onToggleFilterType}
									>
										<Icon name="clock-o" /> Current Counters
									</Button>
								</FormGroup>
							</Col>
							<Col xs={6} className="no-padding-right">
								<FormGroup>
									<Button
										block
										bsStyle={!s.currentCountersFilter ? 'success' : 'default' }
										onClick={this.onToggleFilterType}
									>
										<Icon name="calendar" /> By Period
									</Button>
								</FormGroup>
							</Col>
						</Col>
					</Row>
					<Row>
						{ 
							!s.currentCountersFilter && (
								<Col sm={6} lg={4}>
									<FormGroup>
										<DatePeriodInput 
											startDateInMinutes={ServicingCountersSettings.dateStartMinutes} 
											endDateInMinutes={ServicingCountersSettings.dateFinishMinutes} 
											periodLimitInMinutes={ServicingCountersSettings.periodLimitInMinutes} 
										/>
									</FormGroup>
								</Col>
							)
						}
						<Col sm={6} lg={4}>
							<Button
								block
								disabled={s.isLoading}
								bsStyle="primary"
								onClick={this.onSearch}
							>
								{
									s.isLoading ? (
										<Loader small white />
									) : (
										<span>
											Generate File &nbsp;<Icon name="download" />
										</span>
									)
								}
							</Button>
						</Col>
					</Row>
				</FiltersBox>
			</MainContent>
		);
	}
}


const mapStateToProps = state => ({ filters: state.filters });

export default connect(mapStateToProps)(ServicingCounters);