import React from 'react';
import PropTypes from 'prop-types';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';

import { placeMarkerOptions } from '../options';

const propTypes = {
	markerRef: PropTypes.func,
	labelText: PropTypes.string,
	lat: PropTypes.number.isRequired,
	lng: PropTypes.number.isRequired,
	isActive: PropTypes.bool.isRequired,
	markerColour: PropTypes.oneOf(['green', 'blue', 'grey']),
	labelColour: PropTypes.oneOf(['yellow', 'blue', 'white']),
	draggable: PropTypes.bool,
	onDragEnd: PropTypes.func
};

const defaultProps = {
	labelColour: 'yellow',
	markerColour: 'green'
};

const AccessPointMarker = ({
	markerRef,
	lat,
	lng,
	isActive,
	labelText,
	labelColour,
	markerColour,
	draggable,
	onDragEnd
}) => (
	<MarkerWithLabel
		draggable={draggable}
		ref={markerRef}
		icon={`https://cdn.micway.com.au/tms/img/map_marker_${isActive ? markerColour : 'grey'}.png`}
		labelAnchor={new window.google.maps.Point(100, -5)}
		labelStyle={{
			...placeMarkerOptions,
			color: isActive ? labelColour : 'white',
			opacity: isActive ? 1 : .9
		}}
		position={{ lat: parseFloat(lat), lng: parseFloat(lng) }} 
		zIndex={2}
		onDragEnd={onDragEnd}
	>
		<label>
			{ labelText && <span>{ labelText }<br /></span> }
			{ !isActive && '(Deleted)' }
		</label>
	</MarkerWithLabel>
);

AccessPointMarker.propTypes = propTypes;
AccessPointMarker.defaultProps = defaultProps;

export default AccessPointMarker;
