import React from 'react';
import { getYears } from '../../../app/helpers/dateTimeHelper';
import { Select } from './Select';
import { FormControlProps } from 'react-bootstrap';

interface IProps extends FormControlProps {
	min?: number;
	max?: number;
}

export const Years: React.FC<IProps> = props => {
	const yearsOptions = getYears(props.min, props.max).map((year: number) => ({ id: year, name: year }));
	
	return (
		<Select
			{...props as any}
			data={yearsOptions}
		/>
	);
};