import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shuffle from 'lodash.shuffle';
import Icon from 'react-fontawesome';

import { RadioButtonList } from '../../../../../common/inputs';
import { Form } from '../../../../../common/uiElements';
import { Row, Col, FormGroup } from 'react-bootstrap';

const propTypes = {
	id: PropTypes.string,
	data: PropTypes.object,
	onChange: PropTypes.func,
	readOnly: PropTypes.bool,
	showTitle: PropTypes.bool,
	addGuideSteps: PropTypes.func
};

const defaultProps = {
	id: 'SecurityQuestions',
	readOnly: false,
	data: {},
	showTitle: true
};

const optionItems = [
	{ text: 'Yes', value: true },
	{ text: 'No', value: false }
];

let questions = [
	{ text: 'Have you consumed any alcohol in the last 12 hours?', id: 'hasConsumedAlcohol', rightAnswer: false, isRequired: true, options: optionItems },
	{ text: 'Has your licence been suspended or cancelled?', id: 'hasDriverLicenceIssue', rightAnswer: false, isRequired: true, options: optionItems },
	{ text: 'Have you had a 10 hour break between your last shift and this shift?', rightAnswer: true, id: 'hadBreakBetweenShifts', isRequired: true, options: optionItems },
	{ text: 'Are you impaired or possibly impaired by alcohol, prescribed drugs or recreational drugs?', rightAnswer: false, id: 'isImpairedByDrugs', isRequired: true, options: optionItems },
	{ text: 'Have you worked more than 5 shifts in the last 7 days for Micway or any other company?', id: 'hadMoreThanFiveShifts', rightAnswer: false, isRequired: true, options: optionItems },

	// Not in use anymore
	{ text: 'Has the Daily Vehicle Check Log been signed today?', id: 'isDailyVehicleChecked', rightAnswer: true, isRequired: true, options: optionItems, notInUse: true },
];

const guideSteps = [
	{
		title: 'Step 3 - Fitness for Duty Questions',
		text: 'Each question on this step must be answered truthfully',
		selector: '.pre-start__security-questions',
		position: 'top',
		isLastStep: true
	}
];

//Under COA Legislation making a false declaration can have legal consequences
class SecurityQuestions extends Component {
	UNSAFE_componentWillMount() {
		questions = shuffle(questions);
	}

	componentDidMount() {
		this.props.addGuideSteps && this.props.addGuideSteps(guideSteps);
	}
	
	handleChangeInput = (e, value) => {
		let { data, id, onChange } = this.props;
		var fieldId = e.target.id;
		value = e.target.type === 'checkbox' ? e.target.checked : (typeof value !== 'undefined' ? value : e.target.value);
		
		data = {
			...data,
			[fieldId]: value
		};

		if (onChange)
			onChange(data, id);
	}

	renderValidations = () => {
		var validations = {};
		questions.forEach(({ id }) => validations[id] = 'required');

		return validations;
	}

	render() {
		const p = this.props;

		return (
			<Form className="pre-start__security-questions" validations={this.renderValidations()}>
				{ 
					p.showTitle && (
						<h3 className="title">
							<Icon name="lock" /> Fitness for Duty
						</h3> 
					)
				}
				<Row className="aligned-row">
					{
						questions.map(({ text, id, rightAnswer, options, notInUse }, index) => {
							if (((p.readOnly && p.data[id] == null)  || (notInUse && !p.readOnly)))
								return null;

							return (
								<Col xs={12} sm={12} md={12} lg={12} key={index}>
									<FormGroup>
										<div className={!p.readOnly ? '' : (p.data[id] === rightAnswer ? 'text-success' : 'text-danger')}>
											<label>{text}</label>
											{
												p.readOnly ? (
													<p>{options.find(o => o.value === p.data[id]).text}</p>
												) : (
													<RadioButtonList 
														id={id} 
														value={p.data[id]}
														items={options} 
														onChange={this.handleChangeInput}
													/>
												)
											}
										</div>
									</FormGroup>
								</Col>
							);
						})
					}
				</Row>
			</Form>
		);
	}
}

SecurityQuestions.propTypes = propTypes;
SecurityQuestions.defaultProps = defaultProps;

export default SecurityQuestions;