import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Icon from 'react-fontawesome';
import { Button, FormGroup } from 'react-bootstrap';
import { MainContent } from '../../../common/layout';

// APIs
import WorkerApi from '../../../../app/api/WorkerApi';

// Components
//import WorkersView from './WorkersView';
import WorkersList from './subComponents/WorkersList';
import WorkersListFilter from './subComponents/WorkersListFilter';
import WorkerDetailsModal from './subComponents/WorkerDetailsModal';

// UI Elements
import { ContentBox, ErrorBox, ButtonExport } from '../../../common/uiElements';
import { workersListExportColumns } from '../../../../app/models/Worker';
import componentRequestHandler from '../../../../app/api/helpers/componentRequestHandler';

const propTypes = {
	isDirectory: PropTypes.bool
};

class WorkersContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedWorkerId: null,
			workersList: [],
			totalPages: 0,
			totalRecords: 0,
			page: 0,
			pageSize: 10,
			sorted: [],
			filter: {
				workerName: '',
				currentCompany: '',
				currentRole: '',
				showEmployedOnly: true,
			},
			fields: [],
			showExportModal: false,
			isLoading: false,
			error: undefined,
			isExporting: false,
			errorExporting: undefined
		};

		this.handleSearch = () => {
			this.setState({
				page: 0,
				sorted: []
			}, this.fetchWorkersList);
		};

		this.handleChangeFilter = (filter) => {
			this.setState({
				filter: {
					...this.state.filter,
					...filter
				}
			});
		};
	}    

	componentDidMount() {
		this.fetchWorkersList();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchWorkersList = () => {
		this.setState({ isLoading: true });
		const { filter, page, pageSize, sorted } = this.state;
		WorkerApi.getWorkerList({ ...filter, page, pageSize, sorted })
			.then(data => {
				if (this.unmounted) return;
			
				const { totalRecords, totalPages, records } = data;

				return this.setState({ 
					isLoading: false, 
					workersList: records,
					totalPages,
					totalRecords
				});
			})
			.catch(error => {
				return this.setState({ isLoading: false, error });
			});
	}

	handleChangePage = page => {
		this.setState({ page }, this.fetchWorkersList);
	};

	handleChangePageSize = pageSize => {
		this.setState({ pageSize, page: 0 }, this.fetchWorkersList);
	};

	handleChangeSorted = sorted => {
		this.setState({ sorted }, this.fetchWorkersList);
	};

	handleViewDetails = (azureId) => {
		this.setState({ selectedWorkerId: azureId });
	};

	handleCloseDetails = () => {
		this.setState({ selectedWorkerId: null });
	};

	handleBackToList = () => {
		this.setState({ selectedWorkerId: null });
	};

	handleToggleExportModal = () => {
		this.setState({ showExportModal: !this.state.showExportModal });
	}

	handleChangeFieldsToExport = (fields) => {
		this.setState({ fields });
	}

	handleExport = () => {
		const { filter, fields } = this.state;
		const promise = () => WorkerApi.getWorkerListReportFile({ ...filter, fields });
		componentRequestHandler(this, promise, undefined, {
			loadingAttrName: 'isExporting',
			errorAttrName: 'errorExporting',
		})
		.finally(this.handleToggleExportModal);
	}
	
	render() {
		const { state, props } = this;

		return (
			<MainContent title={props.isDirectory ? 'Directory' : 'Workers'}>
				{ 
					state.selectedWorkerId && (
						<WorkerDetailsModal 
							isPictureChangeable={props.isDirectory}
							isPasswordResetEnabled={props.isDirectory}
							fullDetails={!props.isDirectory}
							show={true} 
							azureId={state.selectedWorkerId}
							onClose={this.handleCloseDetails}
						/> 
					)
				}
				{
					!props.isDirectory && (
						<FormGroup>
							<Link to="/management/workers/new" className="btn btn-block btn-success" >
								<Icon name="user-plus" /> Add new Worker
							</Link>
						</FormGroup>
					)
				}
				<ContentBox color="primary" title="Filters">
					<WorkersListFilter 
						{...state.filter} 
						onChange={this.handleChangeFilter} 
						onPressEnter={this.handleSearch} 
					/>
					<hr />
					<Button 
						bsStyle="primary" 
						disabled={state.isLoading}
						block 
						onClick={this.handleSearch}
					>
						Search <Icon name="search" />
					</Button>
				</ContentBox>
				{
					state.error ? (
						<ErrorBox error={state.error} retryFunc={this.fetchWorkersList} />
					) : (
						<Fragment>
							<ButtonExport 
								showModal={state.showExportModal}
								fields={workersListExportColumns}
								selectedFields={state.fields}
								isLoading={state.isExporting}
								error={state.errorExporting}
								onToggleModal={this.handleToggleExportModal}
								onChangeFields={this.handleChangeFieldsToExport}
								onClickExport={this.handleExport}

							/>
							<WorkersList 
								data={state.workersList}
								page={state.page}
								pageSize={state.pageSize}
								totalPages={state.totalPages}
								sorted={state.sorted}
								isLoading={state.isLoading}
								onPageChange={this.handleChangePage}
								onPageSizeChange={this.handleChangePageSize}
								onSortedChange={this.handleChangeSorted}
								onViewDetails={this.handleViewDetails}
							/>
						</Fragment>
					)
				}
			</MainContent>
		);
	}
}

WorkersContainer.propsTypes = propTypes;

export default WorkersContainer;