import React, { Fragment, ReactNode, useCallback } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { Tooltip } from './Tooltip';

interface IProps {
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	noCol?: boolean;
	label?: string;
	withoutFormGroup?: boolean;
	required?: boolean;
	optional?: boolean;
	tooltip?: string;
	children: ReactNode;
}

const FieldGroup: React.FC<IProps> = ({
	xs, sm, md, lg, noCol, label, withoutFormGroup, 
	required, optional, tooltip, children
}) => {	
	const renderTooltip = useCallback(() => {
		if (!tooltip) return null;

		return <Tooltip text="tooltip" />
	}, [tooltip]);

	const renderContent = useCallback(() => (
		<Fragment>
			<label>{ label }</label> { required ? <b className="text-danger">*</b> : optional ? <i>(Optional)</i> : '' } { renderTooltip() }
			{ children }
		</Fragment>
	), [label, required, optional, children, renderTooltip]);

	const renderContainer = useCallback(() => (
		withoutFormGroup ? (
			renderContent()
		) : (
			<FormGroup>
				{ renderContent() }
			</FormGroup>
		)
	), [withoutFormGroup, renderContent]);

	if (noCol)
		return renderContainer();

	if (!xs && !sm && !md && !lg)
		return (
			<Row>
				<Col xs={12}>
					{ renderContainer() }
				</Col>
			</Row>
		);

	return (
		<Col xs={xs || 12} sm={sm} md={md} lg={lg}>
			{ renderContainer() }
		</Col>
	);
}

export { FieldGroup };
