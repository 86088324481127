/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import Icon from 'react-fontawesome';
import { MessageBoxLink } from './';
import { UserProfilePicture } from '../uiElements';
import { logout } from '../../../app/config/auth.config';

const propTypes = {
	loggedUser: PropTypes.object,
	hideMenu: PropTypes.bool,
	hideSendMessage: PropTypes.bool,
	hideMyProfileButton: PropTypes.bool,
	disableHomeLink: PropTypes.bool
};

const NavBar = props => {
	const { loggedUser } = props;

	function handleToggleFullScreen() {
		const isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null);

		const docElm = document.documentElement;
		if (!isInFullScreen) {
			window.$('body:not(.sidebar-collapse)').addClass('sidebar-collapse');
			if (docElm.requestFullscreen) {
				docElm.requestFullscreen();
			} else if (docElm.mozRequestFullScreen) {
				docElm.mozRequestFullScreen();
			} else if (docElm.webkitRequestFullScreen) {
				docElm.webkitRequestFullScreen();
			} else if (docElm.msRequestFullscreen) {
				docElm.msRequestFullscreen();
			}
		} else {
			window.$('body.sidebar-collapse').removeClass('sidebar-collapse');
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		}
	}

	function handleRefreshPage() {
		window.location.reload(true);
	}

	function handleContactPage() {
		window.open('https://linfrank.sharepoint.com/SitePages/Contact-List.aspx', '_blank');
	}

	return (
		<div>
			<header className="main-header">
				<Link to={props.disableHomeLink ? '' : '/'} className="logo hidden-xs">
					<span className="logo-mini"><b>TMS</b></span>
					<span className="logo-lg"><b>TMS</b> | Micway</span>
				</Link>
				<nav className="navbar navbar-static-top">
					{
						!props.hideMenu && (
							<a className="sidebar-toggle" data-toggle="offcanvas" role="button">
								<span className="sr-only">Toggle navigation</span>
							</a>
						)
					}
					<div className="navbar-custom-menu">
						<ul className="nav navbar-nav">
							<li>
								<a href="#" onClick={handleContactPage} title='Business Contacts List'>
									<Icon name="phone" />
								</a>
							</li>
							<li>
								<a href="#" onClick={handleRefreshPage} title="Refresh Page">
									<Icon name="refresh" />
								</a>
							</li>
							<li>
								<a href="#" onClick={handleToggleFullScreen} title="Full Screen">
									<Icon name="arrows-alt" />
								</a>
							</li>
							{
								!props.hideSendMessage && (
									<li>
										<MessageBoxLink />
									</li>
								)
							}
							<li className="dropdown user user-menu">
								<a href="#" className="dropdown-toggle" data-toggle="dropdown">
									<UserProfilePicture className="user-image" azureId={loggedUser.azureId} />
									<span className="hidden-xs">{ loggedUser.shortName }</span>
								</a>
								<ul className="dropdown-menu">
									<li className="user-header">
										<UserProfilePicture azureId={loggedUser.azureId} />
										<p>
											<b>{ loggedUser.shortName }</b><br/> 
											{ loggedUser.currentRoleName }
										</p>
									</li>
									<li className="user-footer">
										{
											!props.hideMyProfileButton && (
												<div className="pull-left">
													<Link to="/me" className="btn btn-primary btn-flat">My Profile</Link>
												</div>
											)
										}
										<div className="pull-right">
											<a 
												href="#" 
												onClick={logout} className="btn btn-danger btn-flat"
											>
												Sign out
											</a>
										</div>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</nav>
			</header>
		</div>
	);
};

NavBar.propTypes = propTypes;

const mapStateToProps = state => ({ loggedUser: state.user.data });

const NavbarComponent = connect(mapStateToProps)(NavBar);
export { NavbarComponent as Navbar };