import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Modal, Button } from 'react-bootstrap';

import StatusTypes from '../../../app/schemas/signalr/StatusTypes';
import { startConnection } from '../../../stores/redux/signalr';
import changelog from '../../../changelog.json';
import WorkerApi from '../../../app/api/WorkerApi';
import groups from '../../../app/schemas/PermissionGroups';

import { Footer, Navbar, LeftMenu, Notification, QuickPinModal } from '../layout';
import { StatusBars } from './StatusBars';

import packageInfo from '../../../../package.json';
import { setLatestUiVersion } from '../../../stores/redux/app';

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showReleaseModal: false,
			reconnectTimer: 15
		};
	}

	UNSAFE_componentWillMount() {
		this.props.startConnection();
	}

	componentDidMount() {
		window.$(window).trigger('resize');
		const { azureId, lastUiVersionSeen } = this.props.loggedUser;

		// Update the last version seen by user, but only if it's not release environment (to avoid conflict in prod with "lastSeen" in the Worker table)
		if (lastUiVersionSeen !== packageInfo.version && process.env.REACT_APP_ENVIRONMENT !== "release")
			WorkerApi.updateWorkerLastVersionSeen(packageInfo.version, azureId)

		if (changelog[0].showModal)
			this.setState({ showReleaseModal: true });

		document.addEventListener('visibilitychange', () => {
			var windowIsVisible = !document.hidden;
			if (this.props.signalr.status === StatusTypes.FAILED && windowIsVisible)
				this.handleReconnect();
		}, false);
	}

	componentDidUpdate(prevProps) { 
		if (this.props.signalr.status !== prevProps.signalr.status && this.props.signalr.status === StatusTypes.FAILED)
			this.startReconnectTimer();
	} 

	shouldComponentUpdate() {
    if (this.props.signalr.status === StatusTypes.FAILED && this.state.reconnectTimer > 0)
      return false;

    return true;
}
	
	handleReconnect = () => {
		this.clearReconnectTimer();
		this.props.startConnection(true);
	}

	handleCloseReleaseModal = () => {
		this.setState({ showReleaseModal: false });
	}

	startReconnectTimer = () => {
		this.clearReconnectTimer();
		
		this.reconnectTimer = setInterval(() => {
			this.setState({ reconnectTimer: this.state.reconnectTimer - 1 }, () => {
				if (this.state.reconnectTimer === 0) {
					this.clearReconnectTimer();
					this.props.startConnection(true);
				}
			});
		}, 1000);
	}

	clearReconnectTimer = () => {
		clearInterval(this.reconnectTimer);
		this.setState({ reconnectTimer: 15 });
	}

	render() {
		const s = this.state;
		const { signalr, loggedUser } = this.props;

		// Hide the menu if it's the TV user
		const isTvUser = loggedUser.permissionGroupName === groups.TV;
		const isKioskUser = loggedUser.permissionGroupName === groups.KIOSK;

		return (
			<div className="wrapper" style={{overflow: 'unset'}}>
				<ReleasesModal show={s.showReleaseModal} onClose={this.handleCloseReleaseModal}/>
				<Notification />
				<Navbar 
					hideMenu={isTvUser} 
					hideSendMessage={isTvUser || isKioskUser} 
					hideMyProfileButton={isTvUser || isKioskUser} 
					disableHomeLink={isTvUser} />
				{ !isTvUser && <LeftMenu /> }
				<div className={`main-content content-wrapper ${isTvUser ?'full-width' : ''}`}>
					{/* Parent component to house all connection status' and notifications (SignalR, Internet, Outdated UI Version) */}
					<StatusBars 
						signalrStatus={signalr.status} 
						latestUiVersion={this.props.latestUiVersion}
					/>
					{ this.props.children }
				</div>	
				<Footer fullWidth={isTvUser} />
				{ loggedUser.isPinSetupRequired && <QuickPinModal /> }
			</div>
		);
	}
}

const mapDispatchToProps = { startConnection, setLatestUiVersion };
const mapStateToProps = state => ({
	loggedUser: state.user.data,
	signalr: state.signalr,
	latestUiVersion: state.app.latestUiVersion
})


const MainComponent = connect(mapStateToProps, mapDispatchToProps)(Main);
export { MainComponent as Main };

const ReleasesModal = props => (
	<Modal show={props.show} onHide={props.onClose}>
		<Modal.Header>
			<Modal.Title>
				<b>New Version - { changelog[0].version }</b>
			</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			{
				changelog && changelog.length > 0 && (
					<div>
						<h3 style={{ margin: 0 }}>Release Notes</h3>
						<i className="text-muted">* Some of the features may not be available due to your user permissions and restrictions</i>
						<br /><br />
						{
							Object.keys(changelog[0].features).map((key) => {
								return (
									<div className="text-justify" key={key}>
										<label>{ key }</label>
										<ul className="fa-ul">
											{
												changelog[0].features[key].map((feature, index) => {
													return (
														<li style={{ marginBottom: '8px' }} key={index}>
															<Icon name="check-square-o" className="fa-li" />{ feature }
														</li>
													);
												})
											}
										</ul>
										<br />
									</div>
								);
							})
						}
					</div>
				)
			}
			<Link onClick={props.onClose} to="/changelog">
				<i><u>Click here to see the change log history</u></i>
			</Link>
		</Modal.Body>
		<Modal.Footer>
			<Button onClick={props.onClose} block bsStyle="success">Proceed</Button>
		</Modal.Footer>
	</Modal>
);

ReleasesModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired
};