import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Icon from 'react-fontawesome';
import DurationParse from '../../../app/helpers/DurationParse';

import TrackingApi from '../../../app/api/TrackingApi';
import { ErrorBox, Loader } from '../uiElements';

const propTypes = {
	vehFleetNumber: PropTypes.string,
	batteryChargesData: PropTypes.array,
	startTime: PropTypes.number,
	endTime: PropTypes.number,
	showFleet: PropTypes.bool,
	showVoltage: PropTypes.bool,
	fetchWithPeriod: PropTypes.bool,
	onSelectBatteryCharge: PropTypes.func
};

const defaultProps = {
	showFleet: true,
	showVoltage: true,
	fetchWithPeriod: true,
};

class BatteryChargesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			batteryChargesData: null,
			isLoading: false,
			error: null
		};
	}

	UNSAFE_componentWillMount() {
		const { vehFleetNumber, batteryChargesData } = this.props;

		if (vehFleetNumber)
			this.fetchBatteryChargesData(vehFleetNumber);
		else if (batteryChargesData)
			this.setState({ batteryChargesData });
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchBatteryChargesData = vehFleetNumber => {
		this.setState({ isLoading: true, batteryChargesData: null, error: null });

		let startTime, endTime;
		if (this.props.fetchWithPeriod) {
			startTime = this.props.startTime;
			endTime = this.props.startTime;
		}

		TrackingApi.batteryChargesRecentByVeh(vehFleetNumber, startTime, endTime)
			.then(batteryChargesData => {
				if (this.unmounted) return;
		
				return this.setState({ batteryChargesData, isLoading: false });
			})
			.catch(error => {
				console.error(error);
				this.setState({ error, isLoading: false });
			});
	}

	isSmallWindow = () => {
		return window.$(window).width() <= 650;
	}

	onSelectBatteryCharge = (batteryChargeData, index) => {
		if (this.props.onSelectBatteryCharge)
			this.props.onSelectBatteryCharge(batteryChargeData, index);
	}

	render() {
		const s = this.state;
		const p = this.props;

		if (s.error)
			return (
				<ErrorBox 
					error="It wasn't possible to retrieve the data. Try again or contact the TMS Support."
					retryFunc={this.fetchBatteryChargesData} />
			);

		if (s.isLoading) 
			return <Loader text="Loading data..." />;

		return (
			<div className="table-responsive">
				<table className="table table-hover table-bordered text-center text-middle">
					<thead>
						<tr>
							{ p.showFleet && <th rowSpan="2" title="Fleet"><Icon name="microchip" /> Fleet</th> }
							<th colSpan="3"><Icon name="battery-3" /> Charging</th>
							{
								p.showVoltage && (
									<th colSpan="2"><Icon name="warning" /> Voltage</th>
								)
							}
						</tr>
						<tr>
							<th title="Started Charging">Started At</th>
							<th title="Finished Charging">Finished At</th>
							<th title="Total Charging Time">Total Time</th>
							{
								p.showVoltage && (
									<Fragment>
										<th title="Started Voltage">Started At</th>
										<th title="Finished Voltage">Finished At</th>
									</Fragment>
								)
							}
						</tr>
					</thead>
					<tbody>
						{
							s.batteryChargesData && s.batteryChargesData.length > 0 ? (
								s.batteryChargesData.map((batteryChargeData, indexData) => {
									var startedCharging = batteryChargeData.battChargeStartedOnEpoch;
									var finishedCharging = batteryChargeData.battChargeFinishedOnEpoch;
									var totalSecondsCharging = moment.unix(finishedCharging || moment().unix()).diff(moment.unix(startedCharging), 'seconds');
									return (
										<tr className="cursor-pointer" key={Math.random()} onClick={() => { this.onSelectBatteryCharge(batteryChargeData, indexData);}}>
											{p.showFleet && (<td>{batteryChargeData.vehFleetNumber}</td>)}
											<td>{moment.unix(startedCharging).format('DD/MM/YY HH:mm')}</td>
											<td>{!finishedCharging ? 'It\'s on charge...' : moment.unix(finishedCharging).format('DD/MM/YY HH:mm')}</td>
											<td>{(!finishedCharging ? 'Currently ' : '') + DurationParse.toHourMin(totalSecondsCharging, 'short') }</td>
											{
												p.showVoltage && (
													<Fragment>
														<td>{batteryChargeData.battVoltStart / 1000} V</td>
														<td>{batteryChargeData.battVoltFinish / 1000} V</td>
													</Fragment>
												)
											}
										</tr>
									);
								})
							) : (
								<tr>
									<td colSpan="5">Nothing to show.</td>
								</tr>
							)
						}
					</tbody>
				</table>
			</div>
		);
	}
}

BatteryChargesList.propTypes = propTypes;
BatteryChargesList.defaultProps = defaultProps;

const mapStateToProps = state => ({ 
	startTime: state.filters.startTime,
	endTime: state.filters.endTime
});
const BatteryChargesListComponent = connect(mapStateToProps)(BatteryChargesList);
export { BatteryChargesListComponent as BatteryChargesList };