import React, { Fragment } from 'react';
import { Button, FormGroup, Modal } from 'react-bootstrap';
import { CheckboxList } from '../inputs';
import { Loader } from './Loader';
import { IError } from '../../../app/models/Application';

interface IProps {
	showModal: boolean;
	selectedFields: string[];
	fields: { text: string, value: string }[];
	isLoading: boolean;
	error?: IError;
	onToggleModal: () => void;
	onChangeFields: (selectedFields: string[]) => void
	onClickExport: () => void;
}

export const ButtonExport: React.FC<IProps> = props => (
	<Fragment>
		<FormGroup>
			<Button
				bsSize="small"
				bsStyle="success"
				onClick={props.onToggleModal}
			>
				Export XLS
			</Button>
		</FormGroup>
		<Modal
			show={props.showModal}
			onHide={props.onToggleModal}
		>
			<Modal.Header closeButton>
					<Modal.Title>Export List</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{ 
					props.isLoading ? (
						<Loader text="Generating File..." />
					) : (
						<Fragment>
							<label>Select the columns you want to export:</label>
							<CheckboxList 
								allChecked
								inline={false}
								items={props.fields}
								value={props.selectedFields}
								onChange={props.onChangeFields}
							/>
						</Fragment>
					)
				}
			</Modal.Body>
			{
				!props.isLoading && (
					<Modal.Footer>
						<Button	
							block
							bsStyle="success"
							onClick={props.onClickExport}
						>
							Export Now
						</Button>
					</Modal.Footer>
				)
			}
		</Modal>
	</Fragment>
)