import groups from '../app/schemas/PermissionGroups';

import UserPreStarts from '../components/features/myData/UserPreStarts';
import UserProfile from '../components/features/myData/UserProfile';
import UserLeaveRequests from '../components/features/myData/UserLeaveRequests';
import UserRoster from '../components/features/myData/UserRoster';


export default { 
	path: 'me',
	indexRoute: { component: UserProfile },
	menuItem: { title: 'My Profile', icon: 'user' },
	restrict: [groups.KIOSK, groups.TV],
	childRoutes: [
		{ 
			path: '/', 
			menuItem: { title: 'Personal Data', icon: 'id-card' }
		},
		{ 
			path: 'roster', 
			menuItem: { title: 'Availability', icon: 'calendar' },
			component: UserRoster
		},
		{ 
			path: 'leaveRequests', 
			menuItem: { title: 'Leave Management', icon: 'plane' },
			component: UserLeaveRequests
		},
		{ 
			path: 'preStart', 
			menuItem: { title: 'Pre-Starts', icon: 'check-square' },
			component: UserPreStarts
		}
	]
};