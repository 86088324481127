import React from 'react';
import ReactTable, { RowInfo } from 'react-table';
import { IVehicleMaintenanceListDetails } from '../../../../../app/models/VehicleMaintenance';
import { IListComponentProps } from '../../../../../app/models/Application';
import moment from 'moment';
import DurationParse from '../../../../../app/helpers/DurationParse';

const VehicleMaintenanceList: React.FC<IListComponentProps<IVehicleMaintenanceListDetails>> = props => (
	<div id="vehicles-list">
		<ReactTable
			manual
			minRows={0}
			data={props.records}
			loading={props.isLoading}
			page={props.page}
			pages={props.totalPages}
			pageSizeOptions={props.pageSizeOptions}
			pageSize={props.pageSize}
			onPageChange={props.onPageChange}
			onPageSizeChange={props.onPageSizeChange}
			onSortedChange={props.onSortedChange}
			getTdProps={(state: any, rowInfo?: RowInfo) => {
				if (!rowInfo) 
					return {};
					
				const maintenance = rowInfo.original as IVehicleMaintenanceListDetails;
				return {
					onClick: () => props.onSelectRow && props.onSelectRow(maintenance.id, rowInfo.index)
				}
			}}
			columns={[
				{
					Header: 'Fleet Number',
					accessor: 'fleetNumber',
					width: 100
				},
				{
					Header: 'Vehicle Type',
					accessor: 'vehicleTypeName',
					width: 120
				},
				{
					Header: 'Start',
					accessor: 'startTimeLocal',
					Cell: row => moment(row.value).format('DD/MM/YYYY HH:mm'),
					width: 150
				},
				{
					Header: 'Finish',
					accessor: 'finishTimeLocal',
					Cell: row => moment(row.value).format('DD/MM/YYYY HH:mm'),
					width: 150
				},
				{
					Header: 'Service Time',
					id: 'serviceTime',
					sortable: false,
					width: 150,
					accessor: d => d,
					Cell: row => {
						const { startTimeLocal, finishTimeLocal } = row.original as IVehicleMaintenanceListDetails;
						return DurationParse.toDayHour(moment(finishTimeLocal).diff(moment(startTimeLocal), 'seconds'));
					}
				},
				{
					Header: 'Status',
					id: 'status',
					width: 80,
					sortable: false,
					accessor: d => d,
					Cell: row => {
						const { startTimeLocal, finishTimeLocal } = row.original as IVehicleMaintenanceListDetails;
						const _startTime = moment(startTimeLocal);
						const _finishTime = moment(finishTimeLocal);
						const _currentTime = moment();
						
						if (_currentTime >= _startTime && _currentTime < _finishTime)
							return 'In Progress';

						if (_currentTime >= _finishTime)
							return 'Done';						
						
						return 'Up Coming';		
					}
				}
			]}
		/>
	</div>
);

export default VehicleMaintenanceList;