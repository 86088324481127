import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VehiclesApi from '../../../app/api/VehiclesApi';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
import { Loader } from '../uiElements';

const propTypes = {
	damageId: PropTypes.string.isRequired,
	fleetNumber: PropTypes.string.isRequired,
	onLoadImage: PropTypes.func,
};

class VehicleDamagePicture extends Component {
	constructor(props) {
		super(props);
		this.state = {
			image: '',
			isLoading: false
		};
	}

	componentDidMount() {
		this.fetchDamageImage();
	}

	componentDidUpdate(nextProps) {
		if (this.props.damageId !== nextProps.damageId)
			this.fetchDamageImage();
	}

	loadViewerJs = () => {
		if (this.photos)
			this.viewer = new Viewer(this.photos, {
				title: false,
				toolbar: false,
				movable: false,
			}); 
	}

	fetchDamageImage = () => {
		const { damageId, fleetNumber } = this.props;
		this.setState({ isLoading: true });
		return VehiclesApi.getDamagePhoto(fleetNumber, damageId)
			.then(image => {
				if (this.unmounted) return;

				this.setState({ image, isLoading: false });
			})
			.catch(error => {
				this.setState({ isLoading: false });
				console.error(error);
			})
			.then(() => this.props.onLoadImage && this.props.onLoadImage());
	}
    
	render() {

		if (this.state.isLoading)
			return <Loader small />;
			
		return (
			<img 
				height="60" 
				className="img-rounded cursor-pointer image-viewer" 
				src={this.state.image} 
				alt="Vehicle Damage"
			/>
		);
	}
}

VehicleDamagePicture.propTypes = propTypes;

export { VehicleDamagePicture };