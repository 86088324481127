import React, { Fragment } from 'react';
import { Row, PageHeader } from 'react-bootstrap';
import renderLinkButton from './renderLinkButton';
import PermissionGroups from '../../../app/schemas/PermissionGroups';
import { checkUserHasADGroupPermission } from '../../../app/helpers/permissionHelper';

const GeneralDashboard = () => (
	<div>
		<Row>
			{ renderLinkButton('Fleet Tracking', '/tracking/fullMap', 'globe', { xs: 12, sm: 6, md: 3 }, 'info') }
			{ 
				renderLinkButton(
					'Report Incident', 
					'https://www.incidentreporting.com.au/IncidentReporting/Ir.aspx?p=Wmlx/ed67xPKrP9mLAe2yY23cCdzagTQCKpGjLzhEQSRjnTW', 
					'warning', 
					{ xs: 12, sm: 6, md: 3 }, 
					'danger', 
					'2021-01-20',
					true
				) 
			}
		</Row>
		{/* Admin Full */}
		{
			checkUserHasADGroupPermission([
				PermissionGroups.ADMIN, 
				PermissionGroups.ADMIN_STAFF_FULL
			]) && (
				<Fragment>
					<PageHeader>Management</PageHeader>
					<Row>
						{ renderLinkButton('Workers', '/management/workers', 'users', { xs: 6, md: 3 }, 'success' ) }
						{ renderLinkButton('Timesheets', '/payroll/timesheets', 'clock-o', { xs: 6, md: 3 }, 'success') }
						{ renderLinkButton('Leave Requests', '/management/leaveRequests', 'plane', { xs: 6, md: 3 }, 'success') }
						{ renderLinkButton('Companies', '/management/companies', 'institution', { xs: 6, md: 3 }, 'success') }
					</Row>
				</Fragment>
			)
		}

		{/* Mechanics */}
		{
			checkUserHasADGroupPermission([
				PermissionGroups.ADMIN, 
				PermissionGroups.ADMIN_STAFF_FULL,
				PermissionGroups.WASHBAY_ADMIN,
				PermissionGroups.WASHBAY_MECHANICS
			]) && (
				<Fragment>
					<PageHeader>Maintenance</PageHeader>
					<Row>
						{ renderLinkButton('Fleet Management', '/vehicles/management', 'truck', { xs: 6, md: 3 }, 'info') }
						{ renderLinkButton('Fault Reports', '/compliance/faultReports/list', 'exclamation-triangle', { xs: 6, md: 3 }, 'info') }
						{ renderLinkButton('Veh. Damages', '/vehicles/damages', 'fire', { xs: 6, md: 3 }, 'info') }
						{ renderLinkButton('Fleet Services', '/vehicles/services', 'wrench', { xs: 6, md: 3 }, 'info') }
					</Row>
				</Fragment>
			)
		}

		{/* Supervisors & Allocators */}
		{
			checkUserHasADGroupPermission([
				PermissionGroups.ADMIN, 
				PermissionGroups.ADMIN_STAFF_FULL,
				PermissionGroups.ADMIN_STAFF_SIMPLE, 
				PermissionGroups.SUPERVISORS, 
				PermissionGroups.DEPOT_ADMIN, 
				PermissionGroups.KIOSK
			]) && (
				<Fragment>
					<PageHeader>Supervision & Allocation</PageHeader>
					<Row>
						{ renderLinkButton('Pre-Starts Checkout', '/compliance/preStart/checkOut', 'check-square-o', { xs: 6, md: 3 }, 'warning') }
						{
							checkUserHasADGroupPermission([
								PermissionGroups.ADMIN, 
								PermissionGroups.ADMIN_STAFF_FULL,
								PermissionGroups.ADMIN_STAFF_SIMPLE, 
								PermissionGroups.SUPERVISORS, 
								PermissionGroups.DEPOT_ADMIN
							]) && (
								<Fragment>
									{ renderLinkButton('Tasks Roster', '/roster/tasks', 'tasks', { xs: 6, md: 3 }, 'warning') }
									{ renderLinkButton('Staff Roster', '/roster/staff', 'users', { xs: 6, md: 3}, 'warning') }
								</Fragment>
							)
						}
						{ renderLinkButton('Pre-Starts History', '/compliance/preStart/submissions', 'list', { xs: 6, md: 3 }, 'warning') }
					</Row>
				</Fragment>
			)
		}

		{/* Reports */}
		{
			checkUserHasADGroupPermission([
				PermissionGroups.ADMIN, 
				PermissionGroups.ADMIN_STAFF_FULL,
				PermissionGroups.ADMIN_STAFF_SIMPLE, 
				PermissionGroups.WASHBAY_ADMIN,
				PermissionGroups.WASHBAY_MECHANICS,
				PermissionGroups.SUPERVISORS, 
				PermissionGroups.DEPOT_ADMIN, 
				PermissionGroups.KIOSK
			]) && (
				<Fragment>
					<PageHeader>Reports</PageHeader>
					<Row>
						{ renderLinkButton('Journey Report', '/tracking/reports/journey', 'road', { xs: 6, md: 3 }, 'primary') }
						{ renderLinkButton('Veh. by Place', '/tracking/reports/vehiclesByPlace', 'map-signs', { xs: 6, md: 3 }, 'primary') }
						{ renderLinkButton('Missing Vehicles', '/tracking/reports/missingVehicles', 'question-circle', { xs: 6, md: 3 }, 'primary') }
					</Row>
				</Fragment>
			)
		}
	</div>
);

export default GeneralDashboard;