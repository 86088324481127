import React, { ChangeEvent, Fragment } from 'react';
import Icon from 'react-fontawesome';
import TimeField from 'react-simple-timefield';
import { TaskForm } from '../../../../app/models/Task';
import moment from 'moment';
import { Checkbox, VehicleSelectForTask, YesNoRadio } from '../../../common/inputs';
import { FormControl, Row, InputGroup, Col, Button, FormGroup } from 'react-bootstrap';
import { Form, FieldGroup, Callout, UserDetailsBox, Tooltip } from '../../../common/uiElements';
import { PlacesList, RunTypesList, PlacesListNew } from '../../../common/lists';
import { IVehicleBasicInfo, IVehicleDetailsForTask, VehicleTypes } from '../../../../app/models/Vehicle';
import { getVehicleServiceOverlappingTask, getVehicleExceedsStoreMaxPalletCapacity } from '../tasks/tasksHelper';
import { ItemInterface, ReactSortable, Store } from "react-sortablejs";
import { uniq } from 'lodash';
import { IReactSelectReturn } from '../../../../app/models/Application';

interface IProps extends TaskForm {
	formId?: string;
	isEditMode?: boolean;
	isReadOnly?: boolean;
	isChangeDateEnabled?: boolean;
	isChangePlaceEnabled?: boolean;
	hideDate?: boolean;
	hideStartingDepot?: boolean;
	hideTimeFields?: boolean;
	hideRunCustomerFields?: boolean;
	showMainDriver?: boolean;
	onChangeInput?: (id: string, value: string | boolean) => void;
	onChangeVehicle?: (fieldId: string, vehicle?: IVehicleBasicInfo) => void;
	onCheckMainVehicleNotTracked?: (isNotTracked: boolean) => void;
	onCheckTrailer1NotTracked?: (isNotTracked: boolean) => void;
	onCheckTrailer2NotTracked?: (isNotTracked: boolean) => void;
	onChangeStartTime?: (startTime: string) => void;
	onChangeRunCustomer?: (event: ChangeEvent<FormControl & HTMLInputElement>, runCustomerIndex: number) => void;
	onClickAddNewRunCustomer?: () => void;
	onClickRemoveRunCustomer?: (runCustomerIndex: number) => void;
	onChangeRunCustomerOrder?: (obj: IReactSelectReturn, runCustomerOrderIndex: number) => void;
	onClickAddNewRunCustomerOrder?: () => void;
	onClickRemoveRunCustomerOrder?: (runCustomerOrderIndex: number) => void;
	onChangeSequenceRunCustomerOrder?: (RunCustomerOrders: ItemInterface[], sortable: any, store: Store) => void;
}

const validations = {
	date: 'required',
	startTime: 'required',
	startingDepotId: 'required',
	budgetedTime: 'required',
	area: 'required',
	runTypeId: 'required',
	runNumber: 'required',
	runCustomerOrders: 'required'
}

const messages = {
	runCustomerOrders: {
		required: 'You must include at least one Store or Destination'
	}
}

const RunForm: React.FC<IProps> = props => {
	const startDateTime = props.date + ' ' + props.startTime;

	const handleChangeInput = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
		const { id, value, type, checked } = e.target;
		props.onChangeInput && props.onChangeInput(id, type === 'checkbox' ? checked : value);
	}

	const handleCheckMainVehicleNotTracked = (e: ChangeEvent<HTMLInputElement>) => {
		props.onCheckMainVehicleNotTracked && props.onCheckMainVehicleNotTracked(e.target.checked);
	}

	const handleCheckTrailer1NotTracked = (e: ChangeEvent<HTMLInputElement>) => {
		props.onCheckTrailer1NotTracked && props.onCheckTrailer1NotTracked(e.target.checked);
	}

	// const handleCheckTrailer2NotTracked = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
	// 	props.onCheckTrailer2NotTracked && props.onCheckTrailer2NotTracked(e.target.checked);
	// }

	return (
		<Form id={props.formId} validations={validations} messages={messages}>
			<Row>
				{
					!props.hideDate && (
						<Fragment>
							<FieldGroup label="Date" sm={4} required>
								{
									props.isChangeDateEnabled && !props.isReadOnly ? (
										<FormControl
											id="date"
											type="date"
											value={props.date || ''}
											onChange={handleChangeInput}
										/>
									) : (
										<p>{ moment(props.date).format('DD/MM/YYYY') }</p>
									)
								}
							</FieldGroup>
							<FieldGroup label="Day of Week" sm={4}>
								<p>{ moment(props.date).isValid() ? moment(props.date).format('dddd') : '-' }</p>
							</FieldGroup>
						</Fragment>
					)
				}
				{
					!props.hideStartingDepot && (
						<FieldGroup label="Starting Depot" sm={4} required>
							{
								props.isChangePlaceEnabled && !props.isReadOnly ? (
									<PlacesList
										id="startingDepotId"
										type="rosterStartingDepot"
										value={props.startingDepotId || ''}
										onChange={handleChangeInput}
									/>
								) : (
									<p>{ props.startingDepotName }</p>
								)
							}
						</FieldGroup>
					)
				}
			</Row>
			{
				!props.hideTimeFields && (
					<Row>
						<FieldGroup label="Start Time" sm={4} required>
							{
								!props.isReadOnly ? (
									<TimeField
										width={4}
										value={props.startTime || '__:__'} 
										input={<FormControl id="startTime" />} 
										onChange={props.onChangeStartTime}
									/>
								) : (
									<p>{ moment(props.startTimeLocal).format('HH:mm') }</p>
								)
							}
						</FieldGroup>
						<FieldGroup label="Budgeted Time" sm={4} required>
							{
								!props.isReadOnly ? (
									<InputGroup>
										<FormControl
											id="budgetedTime"
											type="number"
											max={20}
											min={0.25}
											value={props.budgetedTime || ''}
											onChange={handleChangeInput}
										/>
										<InputGroup.Addon>
											HOURS
										</InputGroup.Addon>
									</InputGroup>
								) : (
									<p>{ props.budgetedTime } h</p>
								)
							}
						</FieldGroup>
						<FieldGroup label="Finish Time" sm={4}>
							<p>
							{ moment(startDateTime).isValid() && props.budgetedTime ? moment(startDateTime).add(props.budgetedTime, 'hours').format('HH:mm') : '-' }
							</p>
						</FieldGroup>
					</Row>
				)
			}
			{
				props.showMainDriver && (
					<FieldGroup label="Main Driver">
						{
							props.workerId ? (
								<UserDetailsBox azureId={props.workerId} workerData={props.Worker} />
							) : <p><i>No Driver Allocated</i></p>
						}
					</FieldGroup>
				)
			}
			<Row>
				<FieldGroup label="Destination / Area" sm={4} required>
					{/* Can't change area at this stage */}
					{
						!props.isReadOnly && !props.isEditMode ? (
							<FormControl 
								id="area"
								value={props.area || ''}
								onChange={handleChangeInput}
							/>
						) : (
							<p>{ props.area || '-' }</p>
						)
					}
				</FieldGroup>
				<FieldGroup label="Is Pre-Loaded?" sm={4}>
					{
						!props.isReadOnly ? (
							<YesNoRadio 
									id="isPreLoaded"
									value={props.isPreLoaded || false}
									onChange={handleChangeInput} 
								/>
						) : (
							<p>{ props.isPreLoaded ? 'YES' : 'NO' }</p>
						)
					}
				</FieldGroup>
				<FieldGroup label="Budgeted Km" sm={4}>
					{
						!props.isReadOnly ? (
							<InputGroup>
								<FormControl
									id="budgetedKm"
									value={props.budgetedKm || ''}
									onChange={handleChangeInput}
								/>
								<InputGroup.Addon>
									KM
								</InputGroup.Addon>
							</InputGroup>
						) : (
							<p>{ props.budgetedKm ? props.budgetedKm + ' KM' : '-' }</p>
						)
					}
				</FieldGroup>
			</Row>
			<Row>
				<FieldGroup label="Main Vehicle" sm={4}>
					{
						props.isReadOnly ? (
							<p>{ props.mainVehFleetNumber || (props.isMainVehicleNotTracked ? <i>Not Tracked By Micway</i> : <i>None</i>) }</p>
						) : (
							<>
								{
									props.subContractorAbn !== undefined && (
										<FormGroup>
											<Checkbox 
												id="isMainVehicleNotTracked"
												text="Not Tracked" 
												checked={props.isMainVehicleNotTracked || false} 
												onChange={handleCheckMainVehicleNotTracked}
											>
												&nbsp;
												<Tooltip 
													text="If checked, the 'Main Vehicle' will not be tracked by Micway for this task and it won't be marked as 'No Vehicle Assigned' in the system and reports."
												/>
											</Checkbox>
										</FormGroup>
									)
								}
								{
									!props.isMainVehicleNotTracked && (
										<>
											<VehicleSelectForTask
												id="mainVehFleetNumber"
												checkAvailabilityDate={props.date + ' ' + props.startTime}
												value={props.mainVehFleetNumber || ''}
												vehTypeFilter={[VehicleTypes.PRIME_MOVER, VehicleTypes.RIGID]} 
												onChange={vehicle => props.onChangeVehicle && props.onChangeVehicle('mainVehFleetNumber', vehicle)}
											/>
											{ props.date && props.startTime && props.budgetedTime && renderVehicleMaintenanceAlert(props.date + ' ' + props.startTime, parseFloat(props.budgetedTime), props.MainVehicle) }
										</>
									)
								}
							</>
						)
					}
				</FieldGroup>
				{
					(!props.MainVehicle || props.MainVehicle.typeId === VehicleTypes.PRIME_MOVER) && (
						<FieldGroup label="Trailer 1" sm={4}>
							{
								props.isReadOnly ? (
									<p>{ props.trailer1FleetNumber || (props.isTrailer1NotTracked ? <i>Not Tracked By Micway</i> : <i>None</i>) }</p>
								) : (
									<>
										{
											props.subContractorAbn !== undefined && (
												<FormGroup>
													<Checkbox 
														id="isTrailer1NotTracked"
														text="Not Tracked" 
														checked={props.isTrailer1NotTracked || false} 
														onChange={handleCheckTrailer1NotTracked}
													>
														&nbsp;
														<Tooltip 
															text="If checked, the 'Trailer 1' will not be tracked by Micway for this task and it won't be marked as 'No Vehicle Assigned' in the system and reports."
														/>
													</Checkbox>
												</FormGroup>
											)
										}
										{
											!props.isTrailer1NotTracked && (
												<>
													<VehicleSelectForTask
														id="trailer1FleetNumber"
														checkAvailabilityDate={props.date + ' ' + props.startTime}
														value={props.trailer1FleetNumber || ''}
														excludeFleetNumber={props.trailer2FleetNumber ? [props.trailer2FleetNumber] : []}
														vehTypeFilter={[VehicleTypes.TRAILER, VehicleTypes.B_DOUBLE]} 
														onChange={vehicle => props.onChangeVehicle && props.onChangeVehicle('trailer1FleetNumber', vehicle)}
													/>
													{ props.date && props.startTime && props.budgetedTime && renderVehicleMaintenanceAlert(props.date + ' ' + props.startTime, parseFloat(props.budgetedTime), props.Trailer1) }
												</>
											)
										}
									</>
								)
							}
						</FieldGroup>
					)
				}
				{
					props.Trailer1 && props.Trailer1.typeId === VehicleTypes.B_DOUBLE && (
						<FieldGroup label="Trailer 2" sm={4}>
							{
								props.isReadOnly ? (
									<p>{ props.trailer2FleetNumber || <i>No 2nd Trailer Allocated</i> }</p>
								) : (
									<>
										<VehicleSelectForTask
											id="trailer2FleetNumber"
											checkAvailabilityDate={props.date + ' ' + props.startTime}
											value={props.trailer2FleetNumber || ''}
											vehTypeFilter={[VehicleTypes.TRAILER, VehicleTypes.B_DOUBLE]}
											excludeFleetNumber={props.trailer1FleetNumber ? [props.trailer1FleetNumber] : []}
											onChange={vehicle => props.onChangeVehicle && props.onChangeVehicle('trailer2FleetNumber', vehicle)}
										/>
										{ props.date && props.startTime && props.budgetedTime && renderVehicleMaintenanceAlert(props.date + ' ' + props.startTime, parseFloat(props.budgetedTime), props.Trailer2) }
									</>
								)
							}
						</FieldGroup>
					)
				}
			</Row>
			{
				!props.hideRunCustomerFields && (
					<Fragment>
						<h3 className="title">Run Number</h3>
						{
							props.isReadOnly || props.isEditMode ? (
								<b style={{ fontSize: '25px' }}>
									{ props.RunCustomers?.map(({ runTypeAcronym, runNumber }) => runTypeAcronym! + runNumber!).join(', ') }
								</b>
							) : (
								props.RunCustomers?.map((runCustomer, index) => (
									<Row key={index}>
										<Col xs={5}>
											<FormGroup>
												<RunTypesList 
													id="runTypeId"
													firstOptionText="--- RUN TYPE ---"
													taskTypeId={props.taskTypeId}
													value={runCustomer.runTypeId}
													bsSize="small"
													className="required"
													onChange={(e: any) => props.onChangeRunCustomer && props.onChangeRunCustomer(e, index)}
												/>
											</FormGroup>
										</Col>
										<Col xs={5}>
											<FormGroup>
												<FormControl 
													id="runNumber"
													type="number"
													value={runCustomer.runNumber}
													placeholder="Run Number"
													className="required"
													bsSize="small"
													onChange={(e: any) => props.onChangeRunCustomer && props.onChangeRunCustomer(e, index)}
												/>
											</FormGroup>
										</Col>
										<Col xs={2}>
											{ 
												index > 0 && (
													<Button
														block
														bsStyle="danger"
														bsSize="small"
														onClick={() => props.onClickRemoveRunCustomer && props.onClickRemoveRunCustomer(index)}
													>
														<Icon name="times" />
													</Button>
												)
											}
										</Col>
									</Row>
								))
							)
						}
						{
							!props.isReadOnly && !props.isEditMode && props.TaskType?.canHaveMultipleRuns && (
								<FormGroup>
									<Button 
											bsSize="small"
											bsStyle="success"
											onClick={props.onClickAddNewRunCustomer}
										>
											<Icon name="plus" /> Add Another Customer
									</Button>
								</FormGroup>
							)
						}
						<h3 className="title">Delivery Sequence</h3>
						{
							!props.RunCustomers || props.RunCustomers.filter(p => p.runTypeId).length === 0 ? (
								<Callout text="Select at least one Run Type to proceed" color="info" />
							) : (
								<>
									<Row>
										<Col xs={2}>
											{
												props.RunCustomerOrders?.map((order, index) => (
													<Fragment key={index}>
														<FormGroup className="sortable-list-index">
															{ index + 1 }
														</FormGroup>
														{
															getVehicleExceedsStoreMaxPalletCapacity(order.maxPalletCapacity, props.MainVehicle, props.Trailer1, props.Trailer2) && (
																<div style={{ height: 116 }} />
															)
														}
													</Fragment>
												))
											}
										</Col>
										<Col xs={10}>
											<ReactSortable className="sortable-list" list={props.RunCustomerOrders as any || []} setList={props.onChangeSequenceRunCustomerOrder}>
												{
													props.RunCustomerOrders?.map((order, index) => {
														const vehicleExceededStoreMaxPalletCapacity = getVehicleExceedsStoreMaxPalletCapacity(order.maxPalletCapacity, props.MainVehicle, props.Trailer1, props.Trailer2)
														return (
															<FormGroup key={index}>
																<InputGroup>
																	{
																		!props.isReadOnly && (
																			<InputGroup.Addon>
																				<Icon name="arrows" />
																			</InputGroup.Addon>
																		)
																	}
																	{
																		order.id ? (
																			<FormControl 
																				readOnly
																				value={order.placeName + (order.storeNumber ? ` (${order.storeNumber})` : '')}
																			/>
																		) : (
																			<PlacesListNew 
																				id="placeId"
																				type="deliveryStores"
																				runTypes={props.RunCustomers ? uniq(props.RunCustomers?.map(p => p.runTypeId!)) : undefined}
																				placeholder="Select Store or Destination..."
																				value={order.placeId}
																				onChange={obj => props.onChangeRunCustomerOrder && props.onChangeRunCustomerOrder(obj, index)}
																			/>
																		)
																	}
																	{
																		order.id && (
																			<InputGroup.Addon className="hidden-xs">
																				{
																					order.openWindow && order.closeWindow ? (
																						`${moment(order.openWindow).format('HH:mm')} - ${moment(order.closeWindow).format('HH:mm')}`
																					) : (
																						'Unknown'
																					)
																				}
																			</InputGroup.Addon>
																		)
																	}
																	{
																		!props.isReadOnly && props.RunCustomerOrders && props.RunCustomerOrders.length > 1 && (
																			<InputGroup.Button>
																				<Button
																					bsStyle="danger"
																					disabled={props.RunCustomerOrders?.length === 1}
																					onClick={() => props.onClickRemoveRunCustomerOrder && props.onClickRemoveRunCustomerOrder(index)}
																				>
																					<Icon name="times" />
																				</Button>
																			</InputGroup.Button>
																		)
																	}
																</InputGroup>
																{
																	vehicleExceededStoreMaxPalletCapacity && (
																		<Callout style={{ marginTop: 3 }} icon="warning" title="Max Pallet Store Capacity">
																			Confirm the Fleet No. <b>'{ vehicleExceededStoreMaxPalletCapacity.fleetNumber }'</b> before despatch as the store <b>'{ order.placeName }'</b> can accommodate a max of <b>'{ order.maxPalletCapacity } pallets'</b>.
																		</Callout>
																	)
																}
															</FormGroup>
														)
													})
												}
											</ReactSortable>
										</Col>
									</Row>
									<FormControl 
										id="runCustomerOrders" 
										type="hidden" 
										value={props.RunCustomerOrders?.find(p => !p.placeId) ? '' : 'OK'} 
									/>
									{
										!props.isCancelled && !props.isReadOnly && (
											<FormGroup>
												<Button 
														bsSize="small"
														bsStyle="success"
														onClick={props.onClickAddNewRunCustomerOrder}
													>
														<Icon name="plus" /> Add Another Store
												</Button>
											</FormGroup>
										)
									}
								</>
							)
						}
					</Fragment>
				)
			}
		</Form>
	)
}

export default RunForm;

function renderVehicleMaintenanceAlert(taskDate: string, taskBudgetedTime: number, Vehicle?: IVehicleDetailsForTask) {
	if (!Vehicle || !Vehicle.MaintenanceServices) 
		return;
	
	const serviceData = getVehicleServiceOverlappingTask(taskDate, taskBudgetedTime, Vehicle?.MaintenanceServices);
	if (!serviceData) 
		return;

	const { startTimeLocal, finishTimeLocal } = serviceData;

	return (
		<Callout title="Vehicle Booked for Service">
			This vehicle is <b>booked for service</b> from <b>{moment(startTimeLocal).format('DD/MM/YY HH:mm')}</b> to <b>{moment(finishTimeLocal).format('DD/MM/YY HH:mm')}</b>.
			Please check its availability.
		</Callout>
	)
}