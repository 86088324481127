/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from 'react-fontawesome';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import helper from '../helper';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import { UserDetailsLink } from '../../../../common/uiElements';

const PreStartList = (props) => {    
	const renderIssuesPopover = (preStart) => {
		const { securityIssues, mechanicalIssues, faultReports, vehicleDamages } = helper.renderAllIssuesObject(preStart);
		return (
			<Popover id="popover-trigger-hover-focus">
				{
					securityIssues && securityIssues.length > 0 && (
						<div>
							<label>Compliance</label>
							<ul className="list">
								{
									securityIssues.map((issue, index) => {
										return <li key={index}>{issue}</li>;
									})
								}
							</ul>          
						</div>
					)
				}
				{
					mechanicalIssues && mechanicalIssues.length > 0 && (
						<div>
							<label>Mechanical</label>
							<ul className="list">
								{
									mechanicalIssues.map((issue, index) => {
										return <li key={index}>{issue.text || issue}</li>;
									})
								}
							</ul>          
						</div>
					)
				}
				{
					faultReports && faultReports.length > 0 && (
						<div>
							<label>Fault Reports</label>
							<ul className="list">
								{
									faultReports.sortBy(f => [f.reportedVehicleId]).map((issue, index) => {
										return <li key={index}>{issue.reportedVehicleId}: {issue.dtoFaultReportTypeName}</li>;
									})
								}
							</ul>          
						</div>
					)
				}
				{
					vehicleDamages && vehicleDamages.length > 0 && (
						<div>
							<label>Vehicle Damages</label>
							<ul className="list">
								{
									(() => {
										const damagesPerVehicle = vehicleDamages.groupBy('fleetNumber');
										return Object.keys(damagesPerVehicle)
											.map((fleetNumber, index) => {
												return <li key={index}>{fleetNumber}: {damagesPerVehicle[fleetNumber].length} photo(s)</li>;
											});
									})()
								}
							</ul>          
						</div>
					)
				}
                
			</Popover>
		);
	};

	return (
		<ReactTable
			className="pre-start-list-table"
			data={props.data}
			minRows={0}
			manual
			loading={props.isLoading}
			defaultPageSize={props.defaultPageSize}
			page={props.page}
			pageSize={props.pageSize}
			pages={props.totalPages}
			sorted={props.sorted}
			onFetchData={props.onFetchData}
			onPageChange={props.onPageChange}
			onPageSizeChange={props.onPageSizeChange}
			onSortedChange={props.onSortedChange}
			getTrProps={(state, rowInfo) => {
				if (!rowInfo) 
					return {};

				const { colorClass } = helper.renderStatus(rowInfo.original);
				return {
					className: colorClass,
					onClick: () => props.onViewDetails && props.onViewDetails(rowInfo.original.id)
				};
			}}
			columns={[
				{
					Header: 'Date',
					accessor: 'inspectedOnLocal',
					Cell: row => moment(row.value).format('DD/MM/YYYY'),
					width: 100
				},
				{
					Header: 'Driver',
					id: 'driverName',
					accessor: d => d.WorkerReportedBy,
					Cell: row => (
						<UserDetailsLink azureId={row.value.azureId} showDetailsModal={false}>
							{ row.value.shortName }
						</UserDetailsLink>
					)
				}, 
				{
					Header: 'Vehicle(s)',
					id: 'vehicles',
					accessor: d => d,
					Cell: row => {
						const { mainVehFleetNumber, trailer1VehFleetNumber, trailer2VehFleetNumber, dollyVehFleetNumber } = row.value;
						return (
							<span>
								{ mainVehFleetNumber }
								{ trailer1VehFleetNumber && `, ${trailer1VehFleetNumber}` }
								{ trailer2VehFleetNumber && `, ${trailer2VehFleetNumber}` }
								{ dollyVehFleetNumber && `, ${dollyVehFleetNumber}` }
							</span>
						);
					}
				}, 
				{
					Header: 'Depot',
					id: 'depot',
					accessor: 'dtoDepotName',
				},
				{
					Header: 'Reported',
					accessor: 'inspectedOnLocal',
					Cell: row => moment(row.value).format('DD/MM/YY HH:mm')
				},
				{
					Header: 'Checked Out',
					accessor: 'checkOutOnLocal',
					Cell: row => moment(row.value).isValid() ? moment(row.value).format('DD/MM/YY HH:mm') : '-'
				},
				{
					Header: 'Last Part',
					accessor: 'lastReportedOnLocal',
					Cell: row => moment(row.value).isValid() ? moment(row.value).format('DD/MM/YY HH:mm') : '-'
				},
				{
					Header: 'Acknowledge',
					accessor: 'lastAcknowledgeOnLocal',
					Cell: row => moment(row.value).isValid() ? moment(row.value).format('DD/MM/YY HH:mm') : '-'
				},
				{
					id: 'status',
					Header: 'Status',
					accessor: d => d,
					Cell: row => helper.renderStatus(row.value).statusText
				},
				{
					Header: 'Total Issues',
					id: 'issues',
					accessor: d => d,
					sortable: false,
					Cell: row => {
						const preStart = row.value;
						const totalIssues = helper.getTotalIssues(preStart);
						return (
							totalIssues > 0 ? (
								<OverlayTrigger rootClose trigger={['hover']} placement="top" overlay={renderIssuesPopover(preStart)}>
									<a className="cursor-pointer">  
										<Icon name="search" /> { totalIssues }
									</a>
								</OverlayTrigger>
							) : (
								'None'
							)
						);
					}
				}
			]}
		/>
	);
};

PreStartList.defaultProps = {
	defaultPageSize: 10,
	defaultSorted: []
};

PreStartList.propTypes = {
	data: PropTypes.array.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired,
	sorted: PropTypes.arrayOf(
		PropTypes.shape({
			desc: PropTypes.bool.isRequired,
			id: PropTypes.string.isRequired,
		})
	),
	defaultPageSize: PropTypes.number,
	isLoading: PropTypes.bool.isRequired,
	onPageChange: PropTypes.func,
	onPageSizeChange: PropTypes.func,
	onSortedChange: PropTypes.func,
	onFetchData: PropTypes.func,
	onViewDetails: PropTypes.func
};

export default PreStartList;