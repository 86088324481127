import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from 'react-fontawesome';

const propTypes = { 
	pingTime: PropTypes.number, //trkCollectedOnEpoch
	batteryVoltage: PropTypes.number, 
	roadSpeedLimit: PropTypes.number,
	speed: PropTypes.number,
	tempZone1: PropTypes.number, 
	tempZone2: PropTypes.number, 
	tempZone3: PropTypes.number,
	isDoorOpen: PropTypes.bool, 
	isEngineOn: PropTypes.bool, 
	isFreezerOn: PropTypes.bool
};

const InfoWindowContent = ({ 
	batteryVoltage, 
	isDoorOpen, 
	isEngineOn, 
	isFreezerOn,
	pingTime,
	roadSpeedLimit,
	speed,
	tempZone1, 
	tempZone2, 
	tempZone3
}) => (
	<div>
		<table className="table table-condensed">
			<tbody>
				{ (roadSpeedLimit !== null) && (<tr className="text-danger"><td><b><Icon name="warning" /> Limit:</b></td><td><b>{parseInt(roadSpeedLimit, 10)} Km/h</b></td></tr>)}
				{ (speed !== null && (isEngineOn === null || isEngineOn === true)) && (<tr><td><Icon name="dashboard" /> Speed:</td><td>{parseInt(speed, 10)} Km/h</td></tr>)}
				{ (isEngineOn !== null) && (<tr><td><Icon name="gears" /> Engine:</td><td>{isEngineOn ? 'On' : 'Off'}</td></tr>)}
				{ (isDoorOpen !== null) && (<tr><td><Icon name="key" /> Door:</td><td>{isDoorOpen ? 'Opened' : 'Closed'}</td></tr>)}
				{ (isFreezerOn !== null) && (<tr><td><Icon name="snowflake-o" /> Freezer:</td><td>{isFreezerOn ? 'On' : 'Off'}</td></tr>)}
				{ (tempZone1 !== null) && (<tr><td><Icon name="thermometer-three-quarters" /> Zone 1:</td><td>{tempZone1} ºC</td></tr>)}
				{ (tempZone2 !== null) && (<tr><td><Icon name="thermometer-three-quarters" /> Zone 2:</td><td>{tempZone2} ºC</td></tr>)}
				{ (tempZone3 !== null) && (<tr><td><Icon name="thermometer-three-quarters" /> Zone 3:</td><td>{tempZone3} ºC</td></tr>)}
				{ (batteryVoltage !== null && batteryVoltage !== 0) && (<tr><td><Icon name="battery-3" /> Battery:</td><td>{batteryVoltage / 1000} V</td></tr>)}
				<tr><td><Icon name="clock-o"></Icon> Collected:</td><td>{moment.unix(pingTime).format('DD/MM/YY H:mm:ss')}</td></tr>
			</tbody>
		</table>
	</div>
);

InfoWindowContent.propTypes = propTypes;

export default InfoWindowContent;
