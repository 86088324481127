/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import moment from 'moment';
import toastr from 'toastr';

import { CompanyDocTypesList } from '../../common/lists';
import { Row, Col, FormGroup, FormControl, Button } from 'react-bootstrap';

const defaultData = {
	description: '',
	expiryDate: '',
	number: '',
	typeId: '',
	typeName: '',
	displayName: ''
};

const propTypes = {
	acceptAllFiles: PropTypes.bool,
	files: PropTypes.array,
	maxFileSizeKB: PropTypes.number,
	onAddFile: PropTypes.func.isRequired
};

class MultipleFilesCompanies extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...defaultData,
			showFields: false
		};
	}

	handleAddField = () => {		
		this.props.onAddFile && this.props.onAddFile([]);
		this.setState({
			showFields: true
		});
	}

	handleAddFile = () => {
		var { typeId, typeName, number, expiryDate, description, displayName } = this.state;
		if (this.isFormValid()) {
			window.$(this.filesForm).find('.error').removeClass('error');

			var file = window.$(this.filesForm).find('input[type=file]').prop('files')[0];
			var { files, onAddFile } = this.props;

			files.push({
				description,
				expiryDate,
				number,
				typeId,
				typeName,
				displayName,
				file
			});

			this.setState({ ...defaultData });
			window.$(this.filesForm).find('input[type=file]').val('');
			return onAddFile && onAddFile(files);
		}
	}

	handleChange = e => {
		const { id, value } = e.target;
		return this.setState({
			[id]: value
		});
	}

	handleChangeFile = e => {
		var file = e.target.files[0];
		if (file) {
			return this.setState({
				displayName: e.target.files[0].name
			});	
		}
	}

	handleChangeType = (e, typeName) => {
		return this.setState({
			typeId: e.target.value,
			typeName
		});	
	}

	handleRemove = index => {
		var { files, onAddFile } = this.props;
		files.splice(index, 1);
		return onAddFile && onAddFile(files);
	}

	isFormValid = () => {
		var { maxFileSizeKB } = this.props;
		var valid = true;

		window.$(this.filesForm).find('input.file-required[value=""]').each(function() {
			window.$(this).addClass('error');
			valid = false;
		});
		
		window.$('input[type=file]').each(function() {
			if (window.$(this).prop('files').length === 0) {
				window.$(this).addClass('error');
				valid = false;
			} else if (maxFileSizeKB && window.$(this).prop('files')[0].size > (maxFileSizeKB * 1024)) {
				window.$(this).addClass('error');
				valid = false;
				toastr.error('The file size must be less than ' + (maxFileSizeKB / 1024).toFixed(2) + ' MB');
			}
		});		

		window.$('select > option[value=""]:selected').each(function() {
			window.$(this).parent().addClass('error');
			valid = false;
		});

		return valid;
	}

	render() {
		const s = this.state;
		const p = this.props;

		return (
			<div>
				{
					!s.showFields ? (
						<FormGroup>
							<Button bsStyle="info" onClick={this.handleAddField}>
								<Icon name="plus" />&nbsp; Add Document
							</Button>
						</FormGroup>
					) : (
						<div ref={ref => this.filesForm = ref}>
							<Row>
								<Col sm={6} md={5}>
									<FormGroup>
										<label>Type *</label>
										<CompanyDocTypesList onChange={this.handleChangeType} value={s.typeId}/>
									</FormGroup>
								</Col>
								<Col sm={6} md={4}>
									<FormGroup>
										<label>Expiry Date</label>
										<FormControl 
											id="expiryDate" 
											type="date" 
											value={s.expiryDate}
											onChange={this.handleChange} 
										/>
									</FormGroup>
								</Col>
								<Col sm={6} md={3}>
									<FormGroup>
										<label>File Number</label>
										<FormControl 
											id="number" 
											value={s.number} 
											onChange={this.handleChange} 
										/>
									</FormGroup>
								</Col>
								<Col sm={6} md={6}>
									<FormGroup>
										<label>Description *</label>
										<FormControl 
											id="description" 
											value={s.description} 
											maxLength="40" 
											className="file-required" 
											onChange={this.handleChange} 
										/>
									</FormGroup>
								</Col>
								<Col sm={12} md={6}>
									<FormGroup>
										<label>File *</label>
										<FormControl 
											id="file" 
											type="file" 
											className="file-required" 
											onChange={this.handleChangeFile} 
											accept={ p.acceptAllFiles ? '' : 'fileTypes=".doc, .docx, .pdf, .jpg, .png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, image/jpeg, image/png"'} 
										/>
										<i className="text-muted">
											{p.maxFileSizeKB && ('Max size: ' + (p.maxFileSizeKB / 1014).toFixed(2) + ' MB')}
											{!p.acceptAllFiles && (' (.doc, .docx, .pdf, .jpg, .png)')}
										</i>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<Button block bsStyle="info" onClick={this.handleAddFile}>
										<Icon name="plus" />&nbsp; Add File
									</Button>
								</Col>
							</Row>
							<hr />
							<Row>
								<Col xs={12}>
									{
										p.files.length > 0 ? (
											<div className="table-responsive">
												<table className="table table-condensed table-bordered">
													<thead>
														<tr>
															<th>Type</th>
															<th>Number</th>
															<th>Expiry Date</th>
															<th>File</th>
															<th>Delete</th>
														</tr>
													</thead>
													<tbody>
														{
															p.files.map((file, index) => {
																const { typeName, number, expiryDate, displayName } = file;
																return (
																	<tr key={index}>
																		<td>{typeName.length === 0 ? '-' : typeName}</td>
																		<td>{number.length === 0 ? '-' : number}</td>
																		<td>{expiryDate.length === 0 ? '-' : moment(expiryDate).format('DD/MM/YYYY')}</td>
																		<td>{displayName}</td>
																		<td title="Remove file" width="40px" className="text-center">
																			<a className="text-danger cursor-pointer" onClick={this.handleRemove.bind(this, index)}>
																				<Icon name="trash" size="2x" />
																			</a>
																		</td>
																	</tr>
																);
															})
														}
													</tbody>
												</table>
											</div>
										) : (
											<h5 className="text-center"><i>No file has been uploaded.</i></h5>
										)
									}
								</Col>
							</Row>
						</div>
					)
				}
			</div>
		);
	}
}

MultipleFilesCompanies.propTypes = propTypes;

export { MultipleFilesCompanies };