/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import MainLayout from './_MainLayout';
import { logout } from '../../app/config/auth.config';
import UnauthorizedImage from '../../app/assets/img/unauthorized.png';

class Unauthorized extends Component {
	constructor() {
		super();
		this.state = {
			disconnectTimerInSec: 30
		};
	}

	componentDidMount() {
		setInterval(() => {
			const { disconnectTimerInSec } = this.state;
			if (disconnectTimerInSec > 0)
				return this.setState({ disconnectTimerInSec: disconnectTimerInSec - 1 });

			logout();
		}, 1000);
	}

	render() {
		return (
			<MainLayout
				title="Unauthorized"
				color="red"
				text="Oops! Access denied"
				imageSize={200}
				image={UnauthorizedImage}
			>
				You are not allowed to access the system due to a permission issue. 
				<br/>
				If you believe you should't be seeing this message, please contact <a href="mailto:tmssupport@micway.com.au">tmssupport@micway.com.au</a>. 
				<br/><br/>
				You will be automatically signed out in <b>{ this.state.disconnectTimerInSec } seconds</b> or <a onClick={logout}>Click Here</a> to sign out now.
			</MainLayout>
		);
	}
}

export { Unauthorized };