import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Button, FormControl, FormGroup } from 'react-bootstrap';
import { IRunCustomer } from '../../../../../app/models/Run/Run';
import { ITaskDetail } from '../../../../../app/models/Task';
import { Select, VehicleSelect, YesNoRadio } from '../../../../common/inputs';
import { Callout, ContentBox, ErrorBox, FieldGroup, Form, Loader } from '../../../../common/uiElements';
import { IWorkerBasicInfo } from '../../../../../app/models/Worker';
import FontAwesome from 'react-fontawesome';
import { IError } from '../../../../../app/models/Application';
import moment from 'moment';
import MeApi from '../../../../../app/api/MeApi';
import { IVehicleBasicInfo, VehicleTypes } from '../../../../../app/models/Vehicle';
import $ from'jquery';

interface IProps {
	loggedUser: IWorkerBasicInfo;
	tasks?: ITaskDetail[];
	date?: string;
	isPreStartV2?: boolean;
	onStartPreStart(taskDetails?: ITaskDetailsForPreStart): void;
}

interface ITaskDetailsForPreStart {
	startingDepotId: string
	startingDepotName: string;
	mainVehFleetNumber?: string;
	trailer1FleetNumber?: string;
	trailer2FleetNumber?: string;
	RunCustomers: IRunCustomer[];
}

const validations = {
	selectedTask: 'required',
	mainVehicleFleetNumber: 'required',
	trailer1FleetNumber: 'required',
	trailer2FleetNumber: 'required'
}

const PreStartTasksCheck: React.FC<IProps> = props => {
	const [selectedTask, setSelectedTask] = useState<ITaskDetail | undefined>();
	const [isRunCorrect, setIsRunCorrect] = useState<boolean | undefined>();

	const [MainVehicle, setMainVehicle] = useState<IVehicleBasicInfo | undefined>(undefined);
	const [Trailer1, setTrailer1] = useState<IVehicleBasicInfo | undefined>(undefined);
	const [Trailer2, setTrailer2] = useState<IVehicleBasicInfo | undefined>(undefined);

	const [pendingRunTasks, setPendingRunTasks] = useState<ITaskDetail[] | undefined>(props.tasks)
	const [isLoadingTasks, setIsLoadingTasks] = useState(false);
	const [errorLoadingTasks, setErrorLoadingTasks] = useState<IError | undefined>()

	const fetchPendingTasks = () => {
		setIsLoadingTasks(true);
		setErrorLoadingTasks(undefined);
		setIsRunCorrect(undefined);
		setSelectedTask(undefined);
		setMainVehicle(undefined);
		setTrailer1(undefined);
		setTrailer2(undefined);
		setPendingRunTasks([]);

		const startTime = moment().add(-2, 'hours').format('YYYY-MM-DD HH:mm');
		const finishTime = moment().add(2, 'hours').format('YYYY-MM-DD HH:mm');

		MeApi.getTasks(startTime, finishTime)
			.then(tasks => {
				const pendingRunTasks = tasks.filter(task => task.TaskType?.isRunRequired && task.TaskType?.isDriversLicenceRequired);
				setPendingRunTasks(pendingRunTasks);

				// if there's only one task, then set it by default
				if (pendingRunTasks.length === 1)
					setSelectedTask(pendingRunTasks[0]);
			})
			.catch(setErrorLoadingTasks)
			.finally(() => setIsLoadingTasks(false));
	}

	useEffect(() => {
		if (!props.tasks)
			return fetchPendingTasks();

		if (props.tasks.length === 1)
			setSelectedTask(props.tasks[0]);

	}, [props.tasks]);

	// If the selected run doesn't match with the allocation
	const selectedRunDoesNotMatchAllocation = useMemo(() => selectedTask && selectedTask === undefined, [selectedTask]);

	// IF the task requires a drivers licence which means it needs a Pre-Start
	const isDriverTask = useMemo(() => selectedTask?.TaskType?.isDriversLicenceRequired, [selectedTask]);

	// If there's no vehicle allocated to the run at all
	const noVehicleAllocatedError = useMemo(() => selectedTask && isDriverTask && !selectedTask?.isMainVehicleNotTracked && !selectedTask.isTrailer1NotTracked && !selectedTask.MainVehicle && !selectedTask.Trailer1 && !selectedTask.Trailer2, [selectedTask, isDriverTask]);

	// and If the main vehicle is a prime mover or its not tracked (subbie) and there's no trailer allocated to the run
	const noTrailerAllocatedError = useMemo(() => selectedTask && isDriverTask && ((selectedTask.MainVehicle && selectedTask.MainVehicle?.typeId === VehicleTypes.PRIME_MOVER) || selectedTask.isMainVehicleNotTracked) && !selectedTask.Trailer1 && !selectedTask?.isTrailer1NotTracked, [selectedTask, isDriverTask]);

	// If there's a trailer allocated to the run or the trailer is not tracked (subbie) and there's no prime mover allocated to the run
	const noPrimeMoverAllocatedError = useMemo(() => selectedTask && isDriverTask && !selectedTask.MainVehicle && !selectedTask.isMainVehicleNotTracked && (selectedTask?.Trailer1 || selectedTask?.isTrailer1NotTracked), [selectedTask, isDriverTask]);

	// If there's no issues with the task allocation, then show the vehicle selection
	const showVehicleSelection = useMemo(() => selectedTask && isDriverTask && !noVehicleAllocatedError && !noTrailerAllocatedError && !noPrimeMoverAllocatedError, [selectedTask, isDriverTask, noVehicleAllocatedError, noTrailerAllocatedError, noPrimeMoverAllocatedError]);

	// If the selected main vehicle doesn't match with the one from the task
	const selectedMainVehicleDoesntMatchWithTaskError = useMemo(() => showVehicleSelection && MainVehicle && selectedTask?.MainVehicle && MainVehicle?.fleetNumber !== selectedTask.MainVehicle.fleetNumber, [showVehicleSelection, selectedTask, MainVehicle]);

	// If the selected trailer 1 doesn't match with the one from the task
	const selectedTrailer1DoesntMatchWithTaskError = useMemo(() => showVehicleSelection && Trailer1 && selectedTask?.Trailer1 && Trailer1?.fleetNumber !== selectedTask.Trailer1.fleetNumber, [showVehicleSelection, selectedTask, Trailer1]);

	// If the selected trailer 2 doesn't match with the one from the task
	const selectedTrailer2DoesntMatchWithTaskError = useMemo(() => showVehicleSelection && Trailer2 && selectedTask?.Trailer2 && Trailer2?.fleetNumber !== selectedTask.Trailer2.fleetNumber, [showVehicleSelection, selectedTask, Trailer2]);

	const handleSelectTask = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
		const selectedRunTask = pendingRunTasks?.find(task => task.id.toString() === e.target.value);
		setMainVehicle(undefined);
		setTrailer1(undefined);
		setTrailer2(undefined);
		setSelectedTask(selectedRunTask);
	}

	const handleChangeMainVehicle = (vehicle?: IVehicleBasicInfo) => {
		setMainVehicle(vehicle);
		setTrailer1(undefined);
		setTrailer2(undefined);
	}

	const handleChangeTrailer1 = (vehicle?: IVehicleBasicInfo) => {
		setTrailer1(vehicle);
		setTrailer2(undefined);
	}

	const handleChangeTrailer2 = (vehicle?: IVehicleBasicInfo) => {
		setTrailer2(vehicle);
	}

	const handlePressContinue = () => {
		if (!selectedTask || !$('#pre-start-task-check').valid())
			return;

		const task = { 
			...selectedTask,
			startingDepotId: selectedTask.startingDepotId?.toString() || '',
			startingDepotName: selectedTask.startingDepotName || '',
			mainVehFleetNumber: MainVehicle?.fleetNumber,
			trailer1FleetNumber: Trailer1?.fleetNumber,
			trailer2FleetNumber: Trailer2?.fleetNumber,
			isMainVehicleNotTracked: selectedTask.isMainVehicleNotTracked,
			isTrailer1NotTracked: selectedTask.isTrailer1NotTracked,
			RunCustomers: selectedTask.RunCustomers || []
		};
			
		props.onStartPreStart(task);
	}

	const renderWarningMessage = (title: string, text: string, hideCheckAgainBtn?: boolean) => (
		<>
			<Callout icon="warning" title={title} >
				{text} <br/><br/>
				<h4><b>PLEASE CONTACT YOUR SUPERVISOR IMMEDIATELY!</b></h4>
			</Callout>
			{
				!hideCheckAgainBtn && (
					<Button block bsSize="lg" bsStyle="primary" onClick={fetchPendingTasks}>
						<FontAwesome name="refresh" /> Refresh & Check Again
					</Button>
				)
			}
		</>
	)

	const renderVehicleSelection = () => {
		if ((pendingRunTasks?.length === 1 && !isRunCorrect) || !showVehicleSelection)
			return null;

		return (
			<>
				<FieldGroup label="Main Vehicle" required>
					<VehicleSelect
						id="mainVehicleFleetNumber"
						placeholder="Select the Main Vehicle"
						// If the task has a trailer allocated, the main vehicle must be a prime mover
						vehTypeFilter={selectedTask?.Trailer1 ? [VehicleTypes.PRIME_MOVER] : [VehicleTypes.PRIME_MOVER, VehicleTypes.RIGID]}
						subContractorAbn={selectedTask?.isMainVehicleNotTracked && selectedTask?.SubContractor?.id ? selectedTask?.SubContractor?.id : undefined}
						value={MainVehicle?.fleetNumber || ''}
						onChange={handleChangeMainVehicle}
					/>
				</FieldGroup>
				{
					(
						// If the Main Vehicle is set as NotTracked and the selected Main Vehicle is a Prime Mover, a trailer must be selected
						(selectedTask?.isMainVehicleNotTracked && MainVehicle?.typeId === VehicleTypes.PRIME_MOVER) 
						// If the task has a main vehicle and it's a prime mover the trailer must be selected
						|| (selectedTask?.MainVehicle?.typeId === VehicleTypes.PRIME_MOVER) 
						// If the task has a trailer allocated the trailer must be selected
						|| (selectedTask?.Trailer1)
					)
						&& (
						<FieldGroup label="Trailer 1" required>
							<VehicleSelect
								id="trailer1FleetNumber"
								placeholder="Select the Trailer 1"
								vehTypeFilter={[VehicleTypes.TRAILER, VehicleTypes.B_DOUBLE]}
								subContractorAbn={selectedTask?.isTrailer1NotTracked && selectedTask?.SubContractor?.id ? selectedTask?.SubContractor?.id : undefined}
								excludeFleetNumbers={Trailer2 ? [Trailer2.fleetNumber] : []}
								value={Trailer1?.fleetNumber || ''}
								onChange={handleChangeTrailer1}
							/>
						</FieldGroup>
					)
				}
				{
					selectedTask?.Trailer2 && selectedTask?.Trailer1?.typeId === VehicleTypes.B_DOUBLE && (
						<FieldGroup label="Trailer 2" required>
							<VehicleSelect
								id="trailer2FleetNumber"
								placeholder="Select the Trailer 2"
								vehTypeFilter={[VehicleTypes.TRAILER, VehicleTypes.B_DOUBLE]}
								excludeFleetNumbers={Trailer1 ? [Trailer1.fleetNumber] : []}
								subContractorAbn={selectedTask?.isTrailer2NotTracked && selectedTask?.SubContractor?.id ? selectedTask?.SubContractor?.id : undefined}
								value={Trailer2?.fleetNumber || ''}
								onChange={handleChangeTrailer2}
							/>
						</FieldGroup>
					)
				}
			</>
		)
	}

	const renderStatusContent = () => {
		if ((!selectedTask && pendingRunTasks?.length !== 1) || (isRunCorrect === undefined && pendingRunTasks?.length === 1))
			return null;

		if (selectedRunDoesNotMatchAllocation)
			return renderWarningMessage(
				"Run Doesn't Match",
				"The run you selected doesn't match the one assigned to you. Please double check if you selected the right one. If you believe this is a mistake, contact your supervisor immediately."
			)

		if (selectedTask && pendingRunTasks?.length === 1 && isRunCorrect === false)
			return renderWarningMessage(
				"No Other Run Assigned to You",
				`You don't have any other run assigned to you apart from Run '${selectedTask?.runNumberCombined}' for the next 2 hours.`
			)

		// If there's no main vehicle and its tracked by Micway
		if (!selectedTask?.MainVehicle && selectedTask?.isMainVehicleNotTracked === false) {

			// if the there's a trailer or the trailer is not tracked (subbie) then the task requires a prime mover
			if (noPrimeMoverAllocatedError)
				return renderWarningMessage(
					`No Prime Mover Allocated for this Run`,
					`There is no Prime Mover allocated to the run '${selectedTask?.runNumberCombined}' and a Prime Mover must be allocated to the run in order for you to continue.`
				)
			
			// If it doesn't required a Prime, it will require a truck (Rigid)
			return renderWarningMessage(
				`No Truck Allocated for this Run`,
				`There is no vehicle allocated to the Run '${selectedTask?.runNumberCombined}' and a vehicle must be allocated to the run in order for you to continue.`
			)
		}
			

		// If the main vehicle is a prime mover or its not tracked (subbie) and there's no trailer allocated to the run
		// And the task requires a run, it means its a delivery so it requires a trailer
		if (noTrailerAllocatedError)
			return renderWarningMessage(
				`No Trailer Allocated for this Run`,
				`There is no trailer allocated to the Run '${selectedTask?.runNumberCombined}' and a trailer must be allocated to the Run in order for you to continue.`
			)

		if (selectedMainVehicleDoesntMatchWithTaskError)
			return renderWarningMessage(
				"Main Vehicle is different from Task",
				`The Main Vehicle that you selected '${MainVehicle?.fleetNumber}' doesn't match the one allocated to the Run '${selectedTask?.runNumberCombined}'. Please double check if you selected the right one or, if you believe this is a mistake, contact your supervisor immediately.`
			)

		if (selectedTrailer1DoesntMatchWithTaskError)
			return renderWarningMessage(
				"Trailer 1 is different from Task",
				`The Trailer that you selected '${Trailer1?.fleetNumber}' doesn't match the one allocated to the Run '${selectedTask?.runNumberCombined}'. Please double check if you selected the right one or, if you believe this is a mistake, contact your supervisor immediately.`
			)

		if (selectedTrailer2DoesntMatchWithTaskError)
			return renderWarningMessage(
				"Trailer 2 is different from Task",
				`The Trailer that you selected '${Trailer2?.fleetNumber}' doesn't match the one allocated to the Run '${selectedTask?.runNumberCombined}'. Please double check if you selected the right one or, if you believe this is a mistake, contact your supervisor immediately.`
			)
	
		// if there's no errors with the selected vehicles, then show the proceed button
		if (selectedTask && !selectedMainVehicleDoesntMatchWithTaskError && !selectedTrailer1DoesntMatchWithTaskError && !selectedTrailer2DoesntMatchWithTaskError)
			return (
				<Button block bsSize="large" bsStyle="success" onClick={handlePressContinue}>
					Continue <FontAwesome name="arrow-right" />
				</Button>
			);
	}

	if (isLoadingTasks)
		return <Loader text="Checking your pending tasks..." />

	if (errorLoadingTasks)
		return <ErrorBox error={errorLoadingTasks} retryFunc={fetchPendingTasks} />

	if (!pendingRunTasks || pendingRunTasks.length === 0)
		return renderWarningMessage(
			"No Run Assigned To You",
			"You don't have a run assigned to you in the system for the next 2 hours and you must have one in order to continue."
		)

	return (
		<Form id="pre-start-task-check" validations={validations}>
			<ContentBox>
				{
					pendingRunTasks && pendingRunTasks.length > 1 ? (
						<FieldGroup label="Select the run that you are doing now" required>
							<Select 
								id="selectedTask"
								data={pendingRunTasks.map(task => ({ id: task.id, name: task.runNumberCombined! + (task.area ? ` (${task.area})` : '' )}))}
								value={selectedTask?.id || ''}
								onChange={handleSelectTask}
							/>
						</FieldGroup>
					) : (
						<FormGroup>
							<label>Are you doing Run <u style={{ fontSize: 16 }}>{ selectedTask?.runNumberCombined }</u>?</label>
							<YesNoRadio 
								value={isRunCorrect !== undefined ? isRunCorrect : ''}
								onChange={(e: ChangeEvent<FormControl & HTMLInputElement>) => setIsRunCorrect(e.target.value === 'true')}
							/>
						</FormGroup>
					)
				}
				{ renderVehicleSelection() }
			</ContentBox>
			{ renderStatusContent() }
		</Form>
	)
}
export default PreStartTasksCheck;