import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button, FormGroup } from 'react-bootstrap';
import { ContentBox, Loader, ErrorBox } from '../../../../common/uiElements';
import { YesNoRadio } from '../../../../common/inputs';
import PreStartApi from '../../../../../app/api/PreStartApi';
import PreStartActiveTaskCheck from '../tasksCheck/PreStartTasksCheck';
import FontAwesome from 'react-fontawesome';

export default class PreStartSecondRunQuestion extends Component {
	static propTypes = {
		isPreStartV2: PropTypes.bool,
		tasks: PropTypes.arrayOf(PropTypes.object),
		preStartId: PropTypes.string.isRequired,
		mainVehFleetNumber: PropTypes.string.isRequired,
		trailer1VehFleetNumber: PropTypes.string,
		trailer2VehFleetNumber: PropTypes.string,
		dollyVehFleetNumber: PropTypes.string,
		onClickProceedToLastPart: PropTypes.func,
		onClickAddPayloadsNextRun: PropTypes.func,
	}

	constructor(props) {
		super(props);

		const { mainVehFleetNumber, trailer1VehFleetNumber, trailer2VehFleetNumber, dollyVehFleetNumber } = this.props;

		const fleetsArray = [];
		fleetsArray.push(mainVehFleetNumber);
		trailer1VehFleetNumber && fleetsArray.push(trailer1VehFleetNumber);
		trailer2VehFleetNumber && fleetsArray.push(trailer2VehFleetNumber);
		dollyVehFleetNumber && fleetsArray.push(dollyVehFleetNumber);
		
		const fleets = fleetsArray.join(', ');
		
		this.state = {
			fleets,
			isFinishingShift: '',
			hasEnoughTimeToCompleteTask: '',
			isDoingAnotherRun: '',
			isUsingSameFleets: '',
			showWorkHoursBreachMessage: false,
			isSavingWorkHoursBreach: false,
			errorSavingWorkHoursBreach: null,

			// Tasks
			showTaskSelection: props.isPreStartV2 || props.tasks?.length > 0,
			selectedTask: undefined,
		};
	}


	handleChange = (e, value) => {
		const { isDoingAnotherRun, isUsingSameFleets, hasEnoughTimeToCompleteTask } = this.state;

		const fieldId = e.target.id;
		value = e.target.type === 'checkbox' ? e.target.checked : (typeof value !== 'undefined' ? value : e.target.value);

		const fieldsToReset = ['isFinishingShift', 'isDoingAnotherRun', 'hasEnoughTimeToCompleteTask'];
		this.setState({ 
			isUsingSameFleets: fieldsToReset.indexOf(fieldId) !== -1  ? '' : isUsingSameFleets,
			isDoingAnotherRun: fieldsToReset.indexOf(fieldId) !== -1  ? '' : isDoingAnotherRun,
			hasEnoughTimeToCompleteTask: ['isFinishingShift'].indexOf(fieldId) !== -1  ? '' : hasEnoughTimeToCompleteTask,
			[fieldId]: value 
		});
	}

	handleProceed = () => {
		const { 
			hasEnoughTimeToCompleteTask, isUsingSameFleets, 
			isFinishingShift, showWorkHoursBreachMessage 
		} = this.state;

		if (isUsingSameFleets)
			return this.props.onClickAddPayloadsNextRun();

		if (!isFinishingShift && !hasEnoughTimeToCompleteTask && !showWorkHoursBreachMessage)
			return this.registerWorkHoursBreach();

		return this.props.onClickProceedToLastPart();

	}

	handleStartPreStartFromTask = task => {
		if (!task)
			return this.props.onClickAddPayloadsNextRun();

		const runsForNextTask = task.RunCustomers.map(run => ({ ...run, number: run.runNumber, taskId: task.id }));
		this.props.onClickAddPayloadsNextRun(runsForNextTask);
	}

	registerWorkHoursBreach = () => {
		this.setState({ isSavingWorkHoursBreach: true, errorSavingWorkHoursBreach: null });
		PreStartApi.registerWorkHoursBreach(this.props.preStartId)
			.then(() => {
				this.setState({ 
					isSavingWorkHoursBreach: false,
					showWorkHoursBreachMessage: true 
				});
			})
			.catch(error => {
				console.error(error);
				this.setState({ isSavingWorkHoursBreach: false, errorSavingWorkHoursBreach: error });
			});
	}

	renderButton = () => {
		const { state } = this;
		
		if (state.isSavingWorkHoursBreach)
			return null;
			
		if (state.isFinishingShift
				|| state.hasEnoughTimeToCompleteTask === false 
				|| state.isDoingAnotherRun === false
				|| (state.isDoingAnotherRun && state.isUsingSameFleets !== '' && !state.showTaskSelection)
				|| (state.showTaskSelection && state.isDoingAnotherRun && state.isUsingSameFleets === false)
			)
			return (
				<FormGroup>
					<Button
						block
						bsStyle="success"
						onClick={this.handleProceed}
					>
						{
							state.isUsingSameFleets ? (
								'Add the payloads for the next run'
							) : (
								(state.isFinishingShift === true || state.showWorkHoursBreachMessage || state.isUsingSameFleets === false || state.isDoingAnotherRun === false) ? (
									'Proceed to the last part of the Pre-Start'
								) : (
									'Proceed'
								)
							)
						}
						&nbsp;<FontAwesome name="arrow-right" />
					</Button>
				</FormGroup>
			);
	}

	renderTaskSelection = () => {
		const { props, state } = this;
		
		if ((props.isPreStartV2 || props.tasks?.length > 0) && state.showTaskSelection)
			return (
				<PreStartActiveTaskCheck 
					isPreStartV2={props.isPreStartV2}
					tasks={props.tasks}
					onStartPreStart={this.handleStartPreStartFromTask} 
				/>
			)	
	}

	renderContent = () => {
		const { state } = this;

		if (state.isSavingWorkHoursBreach)
			return <Loader text="Loading. Please wait..." />;

		if (state.errorSavingWorkHoursBreach)
			return <ErrorBox error={state.errorSavingWorkHoursBreach} retryFunc={this.handleProceed}/>;

		if (state.showWorkHoursBreachMessage)
			return (
				<h4 className="text-center">
					<b>Please, complete the last part of your pre-start check and then talk to your supervisor.</b>
					<br /><br />
					Your supervisor has been notified about your working hours breach.
				</h4>
			);
		
		return (
			<Fragment>
				<FormGroup>
					<label>Are you finishing your shift and will not have any other task to be done?</label>
					<YesNoRadio 
						id="isFinishingShift" 
						value={state.isFinishingShift}
						onChange={this.handleChange} 
					/>
				</FormGroup>
				
				{
					state.isFinishingShift === false && (
						<Fragment>
							<FormGroup>
								<label>Do you have enough legal driving hours left to complete your assigned tasks?</label>
								<YesNoRadio 
									id="hasEnoughTimeToCompleteTask" 
									value={state.hasEnoughTimeToCompleteTask}
									onChange={this.handleChange} 
								/>
							</FormGroup>
							{
								state.hasEnoughTimeToCompleteTask === true && (
									<Fragment>
										<FormGroup>
											<label>Are you going to do another run?</label>
											<YesNoRadio 
												id="isDoingAnotherRun" 
												value={state.isDoingAnotherRun}
												onChange={this.handleChange} 
											/>
										</FormGroup>
										{
											state.isDoingAnotherRun === true && (
												<FormGroup>
													<label>Are you going to use the following fleet: { state.fleets }?</label>
													<YesNoRadio 
														id="isUsingSameFleets" 
														value={state.isUsingSameFleets}
														onChange={this.handleChange} 
													/>
												</FormGroup>
											)
										}
									</Fragment>
								)
							}
						</Fragment>
					)
				}
			</Fragment>
		);
	}

	render() {
		return (
			<Fragment>
				<ContentBox>
					{ this.renderContent() }
				</ContentBox>
				{ this.state.isUsingSameFleets === true && this.renderTaskSelection() }
				{ this.renderButton() }
			</Fragment>
		);
	}
}

