/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { Row, Col, FormGroup, FormControl, Button } from 'react-bootstrap';
import omit from 'lodash.omit';
import toastr from 'toastr';

import { Checkbox, SelectInputWithData } from './';

const propTypes = {
	acceptAllFiles: PropTypes.bool,
	files: PropTypes.array,
	fields: PropTypes.array,
	maxFileSizeKB: PropTypes.number,
	onChange: PropTypes.func.isRequired
};

const defaultProps = {
	acceptAllFiles: false,
	files: [],
	fields: [],
	maxFileSizeKB: 0,
	onChange: () => {}
};

class MultipleFiles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			files: []
		};
	}
	
	UNSAFE_componentWillMount() {
		this.setState({ ...this.getDefaultValues() });

		if (this.props.files)
			this.setState({ files: this.props.files });
	}
	
	getDefaultValues = () => {
		const schema = this.props.fields;
		var fields = {};

		for (var i in schema) {
			var defaultValue = '';
			switch(schema[i].type) {
			case 'checkbox':
				defaultValue = schema[i].defaultValue || false;
				break;
			default:
				defaultValue = schema[i].defaultValue || '';
			}

			fields[schema[i].id] = defaultValue;
		}

		return fields;
	}

	handleAddFile = () => {
		window.$(this.filesForm).find('.error').removeClass('error');
		if (this.isFormValid()) {
			var files = this.state.files;
			var fileInput = window.$(this.filesForm).find('input[type=file]').prop('files')[0];
			var fieldsData = omit(this.state, 'files');

			files.push({
				...fieldsData,
				displayName: fileInput.name,
				file: fileInput
			});

			this.setState({ 
				...this.getDefaultValues(), 
				files 
			});
			window.$(this.filesForm).find('input[type=file]').val('');
			return this.onAddFile();
		}
	}

	handleChangeInput = e => {
		var id = e.target.id;
		var value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

		var selectText = {};
		if (e.target.type.indexOf('select') !== -1) {
			var fieldText = id + '_desc';
			selectText[fieldText] = e.target.options[e.target.selectedIndex].text;
		}

		return this.setState({
			[id]: value,
			...selectText
		});
	}

	handleChangeFile = () => {
		if(!this.props.fields || this.props.fields.length === 0)
			this.handleAddFile();
	}

	handleRemove = index => {
		var { files } = this.state;
		files.splice(index, 1);
		this.setState({ files });
		return this.onAddFile();
	}

	onAddFile = () => {
		return this.props.onChange(this.state.files);
	}

	isFormValid = () => {
		const { maxFileSizeKB } = this.props;
		var valid = true;

		window.$(this.filesForm).find('input[type=file]').each(function() {
			if (window.$(this).prop('files').length === 0) {
				window.$(this).addClass('error');
				valid = false;
			} else if (maxFileSizeKB && window.$(this).prop('files')[0].size > (maxFileSizeKB * 1024)) {
				window.$(this).addClass('error');
				valid = false;
				toastr.error('The file size must be less than ' + (maxFileSizeKB / 1024).toFixed(2) + ' MB');
			}
		});
		
		window.$(this.filesForm).find('input.isRequired[value=""]').each(function() {
			window.$(this).addClass('error');
			valid = false;
		});

		window.$(this.filesForm).find('select.isRequired > option[value=""]:selected').each(function() {
			window.$(this).parent().addClass('error');
			valid = false;
		});

		return valid;
	}

	renderInput = field => {
		var { id, type, maxLength, selectType, cssClass = '', isRequired } = field;
		const value = this.state[id];
		cssClass += (isRequired ? ' isRequired' : '');
		switch(field.type) {
		case 'checkbox':
			return <Checkbox id={id} 
				text={value ? 'Yes' : 'No'} 
				checked={value} 
				onChange={this.handleChangeInput} />;
		case 'select':
			return <SelectInputWithData id={id} 
				cssClass={cssClass}
				dataType={selectType}
				onChange={this.handleChangeInput} 
				value={value} />;
		default:
			return <FormControl 
				id={id}
				{...maxLength ? {maxLength} : {}}
				type={type}
				value={value} 
				className={cssClass} 
				onChange={this.handleChangeInput} />;
		}
	}

	render() {
		const s = this.state;
		const p = this.props;
		
		return (
			<div ref={ref => this.filesForm = ref}>
				{
					p.fields && p.fields.length > 0 && (
						<Row>
							{
								p.fields.map((field, index) => (
									<div className={field.cssCol} key={index}>
										<FormGroup>
											<label>{field.description} {field.isRequired ? '*' : ''}</label>
											{ this.renderInput(field, index) }
										</FormGroup>
									</div>
								))
							}
						</Row>
					)	
				}
				<Row>
					<Col xs={12}>
						<FormGroup>
							<label>File</label>
							<FormControl 
								id="file" 
								type="file"
								className="file-required"
								accept={ p.acceptAllFiles ? '' : 'fileTypes=".doc, .docx, .pdf, .jpg, .png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, image/jpeg, image/png"'}
								onChange={this.handleChangeFile}
							/>
							<i className="text-muted">
								{p.maxFileSizeKB && ('Max size: ' + (p.maxFileSizeKB / 1024).toFixed(2) + ' MB')}
								{!p.acceptAllFiles && (' (.doc, .docx, .pdf, .jpg, .png)')}
							</i>
						</FormGroup>
					</Col>
				</Row>
				{
					p.fields && p.fields.length > 0 && (
						<Row>
							<Col xs={12}>
								<Button block bsStyle="info" onClick={this.handleAddFile}>
									<Icon name="plus" />&nbsp; Add File
								</Button>
							</Col>
						</Row>
					)
				}
				<hr />
				<Row>
					<Col xs={12}>
						{
							s.files.length > 0 ? (
								<div className="table-responsive">
									<table className="table table-condensed table-bordered">
										<thead>
											<tr>
												{
													p.fields && p.fields.length > 0 ? p.fields.map((field, index) => {
														if (!field.showOnTable)
															return null;
														
														return <th key={index}>{field.description}</th>;
													}) : (
														<th>Uploaded Files</th>
													)
												}
												<th></th>
											</tr>
										</thead>
										<tbody>
											{
												s.files.map((file, index) => {
													return (
														<tr key={index}>
															{
																p.fields && p.fields.length > 0 ? p.fields.map((field, indexFields) => {
																	if (!field.showOnTable)
																		return null;

																	const value = file[field.id];
																	const display = field.type != 'select' ? (value || '-') : file[field.id + '_desc'];

																	return (<td key={indexFields}>{display}</td>);
																}) : (
																	<td>{file.displayName}</td>
																)
															}
															<td  title="Remove file" width="40px" className="text-center">
																<a className="text-danger cursor-pointer" onClick={this.handleRemove.bind(this, index)}>
																	<Icon name="trash" size="2x" />
																</a>
															</td>
														</tr>
													);
												})
											}
										</tbody>
									</table>
								</div>
							) : (
								<h5 className="text-center"><i>No file has been uploaded yet. Choose a file and click on Add File.</i></h5>
							)
						}
					</Col>
				</Row>
			</div>
		);
	}
}

MultipleFiles.propTypes = propTypes;
MultipleFiles.defaultProps = defaultProps;

export { MultipleFiles };
