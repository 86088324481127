/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ListsApi from '../../../app/api/ListsApi';
import { Select } from '../../common/inputs';
import { ErrorBox } from '../../common/uiElements';

const propTypes = {
	id: PropTypes.string,
	cssClass: PropTypes.string,
	value: PropTypes.string,
	vehTypeID: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	onlyActive: PropTypes.bool,
	onChange: PropTypes.func,
	onSetOptions: PropTypes.func,
};

const defaultProps = {
	id: 'reportedTypeId',
	onlyActive: true
};

class FaultReportTypesList extends Component {
	constructor() {
		super();

		this.state = {
			isLoading: false,
			error: null,
			list: []
		};
	}

	UNSAFE_componentWillMount() {
		return this.fetchData();
	}
	
	componentWillUnmount() {
		return this.unmounted = true;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.vehTypeID !== this.props.vehTypeID)
			this.fetchData();
	}

	fetchData = () => {
		const { vehTypeID, onlyActive } = this.props;
		this.setState({ isLoading: true, error: false, list: [] });
		ListsApi.getFaultReportTypes(vehTypeID, onlyActive)
			.then(list =>  {
				if (this.unmounted) return;
				
				// Transform option strings to array
				list = list.map(type => {
					const { options } = type;
					
					if (options)
						type.options = JSON.parse(options);
					
					return type;
				});

				this.setState({
					isLoading: false,
					list
				});

				if (this.props.value && this.props.onSetOptions) {
					const options = this.getOptions(this.props.value);
					this.props.onSetOptions(options);
				}
			})
			.catch(error => {
				console.error(error);
				this.setState({ error });
			});
	}

	handleChange = e => {
		if (!this.props.onChange)
			return;

		this.props.onChange(e);
		
		const options = this.getOptions(e.target.value);
		this.props.onSetOptions && this.props.onSetOptions(options);
	}

	getOptions = value => {
		const { options } = this.state.list.find(l => l.id == value) || {};
		return options;
	}

	render () {
		const s = this.state;
		
		if (s.error)
			return <ErrorBox error={s.error} retryFunc={this.fetchData} />;
		
		return <Select {...this.props} onChange={this.handleChange} isLoading={s.isLoading} data={s.list} />;
	}
}

FaultReportTypesList.propTypes = propTypes;
FaultReportTypesList.defaultProps = defaultProps;

export { FaultReportTypesList };