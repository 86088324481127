import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

const WorkersList = props => (
	<ReactTable
		data={props.data}
		minRows={0}
		manual
		loading={props.isLoading}
		page={props.page}
		pageSize={props.pageSize}
		pages={props.totalPages}
		sorted={props.sorted}
		onFetchData={props.onFetchData}
		onPageChange={props.onPageChange}
		onPageSizeChange={props.onPageSizeChange}
		onSortedChange={props.onSortedChange}
		getTrProps={(state, rowInfo) => {
			if (!rowInfo) 
				return {};

			return {
				onClick: () => props.onViewDetails && props.onViewDetails(rowInfo.original.azureId)
			}
		}}
		columns={[
			{
				Header: 'Name',
				id: 'fullName',
				accessor: 'fullName',
				minWidth: 150
			}, 
			{
				Header: 'Phone',
				id: 'phone',
				sortable: false,
				accessor: 'phone',
				Cell: row => <a href={`tel:${row.value}`}>{ row.value }</a>
			}, 
			{
				Header: 'Current Company',
				id: 'companyName',
				accessor: d => d.companyName || '-',
				sortable: false
			},
			{
				Header: 'Current Role',
				id: 'currentRole',
				accessor: d => d.roleName || '-',
				sortable: false
			}
		]}
	/>
)

WorkersList.propTypes = {
	data: PropTypes.array.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired,
	sorted: PropTypes.arrayOf(
		PropTypes.shape({
			desc: PropTypes.bool.isRequired,
			id: PropTypes.string.isRequired,
		})
	),
	isLoading: PropTypes.bool.isRequired,
	onPageChange: PropTypes.func,
	onPageSizeChange: PropTypes.func,
	onSortedChange: PropTypes.func,
	onFetchData: PropTypes.func,
	onViewDetails: PropTypes.func
};

export default WorkersList;