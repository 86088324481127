import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import WorkerApi from '../../../../../app/api/WorkerApi';
import { ErrorBox, Loader } from '../../../../common/uiElements';

import WorkerViewDetails from './WorkerViewDetails';
import WorkerViewSimpleDetails from './WorkerViewSimpleDetails';
import WorkerEdit from './WorkerEdit';

const propTypes = {
    azureId: PropTypes.string,
    show: PropTypes.bool,
    fullDetails: PropTypes.bool,
    isPictureChangeable: PropTypes.bool,
    isPasswordResetEnabled: PropTypes.bool,
		hideWorkerLeftReason: PropTypes.bool,
    onClose: PropTypes.func
};

class WorkerDetailsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditMode: false,
            isLoading: false,
            error: null,
            workerData: null
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
    }
    
    UNSAFE_componentWillMount() {
        if (this.props.azureId && !this.props.fullDetails)
            this.fetchWorkerData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.azureId && this.props.azureId !== prevProps.azureId)
            this.fetchWorkerData();
    }

    fetchWorkerData = () => {
        this.setState({ isLoading: true, error: null })
        WorkerApi.getWorkerDetails(this.props.azureId)
        .then(workerData => {
            if (this.unmounted) return;
            
            return this.setState({ 
                isLoading: false,
                workerData
            });
        })
        .catch(error => {
            console.error(error)
            return this.setState({ isLoading: false, error });
        });
    }

    checkIfUserWantToClose = () => {
        return window.confirm("Do you really want to continue? All changes that you have done won't be saved.");
    }

    _handleClose = () => {
        if(!this.state.isEditMode || this.checkIfUserWantToClose())
            return this.props.onClose();
    }

    _handleEditClickBack = () => {
        if(this.checkIfUserWantToClose())
            this._handleToggleEditMode(false)
    }

    _handleToggleEditMode = (isEditMode) => {
        this.setState({ isEditMode });
    }

    render() {
        const s = this.state;
        const p = this.props;

        return (
            <Modal bsSize="lg" show={p.show} onHide={this._handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Worker Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        p.fullDetails ? (
                            s.isEditMode ? (
                                <WorkerEdit 
                                    azureId={this.props.azureId} 
                                    onClickBack={this._handleEditClickBack} 
                                    onSave={this._handleToggleEditMode.bind(this, false)} />
                            ) : (
                                <WorkerViewDetails 
                                    azureId={this.props.azureId} 
                                    showBackButton={false} 
																		hideWorkerLeftReason={p.hideWorkerLeftReason}
                                    onClickEdit={this._handleToggleEditMode.bind(this, true)} />
                            )
                        ) : (
                            s.error ? (
                                <ErrorBox error={s.error} retryFunc={this.fetchWorkerData} />
                            ) : (
                                s.isLoading ? (
                                    <Loader text="Loading data. Please wait..." />
                                ) : (
                                    s.workerData && (
                                        <WorkerViewSimpleDetails 
                                        { ...s.workerData }
																				hideWorkerLeftReason={p.hideWorkerLeftReason}
                                        isPasswordResetEnabled={p.isPasswordResetEnabled}
                                        isPictureChangeable={p.isPictureChangeable} />
                                    )
                                )
                            )
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this._handleClose} bsStyle="default">Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

WorkerDetailsModal.propTypes = propTypes;

export default WorkerDetailsModal;