import React from 'react';
import Select, { ReactSelectProps } from 'react-select';
import { FormControl } from 'react-bootstrap';
import componentRequestHandler from '../../../app/api/helpers/componentRequestHandler';
import ListsApi from '../../../app/api/ListsApi';
import { IError } from '../../../app/models/Application';
import { IRunBasicInfo } from '../../../app/models/Run/Run';
import moment from 'moment';

interface IProps extends Omit<ReactSelectProps, 'onChange'> {
	showArea?: boolean;
	startingDepotId?: string;
	startTimeLocal?: string;
	finishTimeLocal?: string;
	date?: string;
	onChange?: (run?: IRunBasicInfo) => void
}

interface IState {
	runsList: IRunBasicInfo[];
	isLoading: boolean;
	error?: IError;
}

class RunsListSearch extends React.Component<IProps, IState> {
	state = {
		runsList: [],
		isLoading: false,
		error: undefined
	}

	componentDidMount() {
		this.fetchList();
	}

	componentDidUpdate(prevProps: Readonly<IProps>) {
		if (prevProps.startingDepotId !== this.props.startingDepotId || prevProps.date !== this.props.date)
			this.fetchList();
	}
	

	fetchList = () => {
		const { startingDepotId, date, startTimeLocal, finishTimeLocal } = this.props;

		const pStartTimeLocal = startTimeLocal || moment(date).startOf('day').format('YYYY-MM-DD HH:mm');
		const pFinishTimeLocal = finishTimeLocal || moment(date).endOf('day').format('YYYY-MM-DD HH:mm');

		if (moment(pStartTimeLocal) < moment('2000-01-01') && moment(pStartTimeLocal) > moment('9999-12-12')) 
			return;

		const promise = ListsApi.getRunsList.bind(this, pStartTimeLocal, pFinishTimeLocal, startingDepotId ? parseInt(startingDepotId) : undefined);
		componentRequestHandler(this, promise, 'runsList')
	}

	handleChange = (run: IRunBasicInfo) => {
		this.props.onChange && this.props.onChange(run);
	}

	renderListItem = (run: IRunBasicInfo) => {
		const listItem = {
			value: run.id,
			label: run.name,
			...run
		}

		if (this.props.showArea)
			listItem.label = `${run.name} (${run.area})`;

		return listItem;
	}

	render() {
		const { props, state } = this;

		return (
			<div>
				<Select 
					value={props.value}
					placeholder={state.isLoading ? 'Loading...' : props.placeholder || 'Select Run...'}
					noResultsText={!state.isLoading ? props.noResultsText || 'No results were found' : ''}
					disabled={props.disabled}
					options={state.runsList.map(this.renderListItem)}
					isLoading={state.isLoading}
					onChange={this.handleChange as any}
				/>
				<FormControl 
					id={props.id} 
					type="hidden" 
					value={props.value as any || ''}
				/>
			</div>
		)
	}
}

export { RunsListSearch };