import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'react-fontawesome';
import { Button } from 'react-bootstrap';

const propTypes = {
	id: PropTypes.string,
	bordered: PropTypes.bool,
	centeredTitle: PropTypes.bool,
	color: PropTypes.string, 
	cssBodyClass: PropTypes.string,
	cssClass: PropTypes.string,
	height: PropTypes.oneOfType([
		PropTypes.number, 
		PropTypes.string
	]),
	icon: PropTypes.string, 
	windowHeight: PropTypes.number,
	windowMaxHeight: PropTypes.bool,
	maxHeight: PropTypes.number,
	minimize: PropTypes.bool,
	title: PropTypes.any,
	noMarginBottom: PropTypes.bool,
	noPadding: PropTypes.bool,
	solid: PropTypes.bool,
	startMinimized: PropTypes.bool,
	hideOnPrint: PropTypes.bool,
	children: PropTypes.any,
	style: PropTypes.object
};

const defaultProps = {
	color: 'primary', 
	cssBodyClass: '',
	cssClass: '',
	icon: ''
};

const ContentBox = ({
	id,
	bordered,
	centeredTitle,
	color, 
	cssBodyClass,
	cssClass,
	height,
	icon, 
	windowHeight,
	windowMaxHeight,
	maxHeight,
	minimize,
	noMarginBottom,
	noPadding,
	solid,
	startMinimized,
	hideOnPrint,
	title,
	children,
	style
}) => {
	const isSmallWindow = window.innerWidth < 768;
	let windowHeightToFitBox = window.innerHeight - 300;

	const bodyStyle = {};
	if (windowMaxHeight && !isSmallWindow)
		bodyStyle.maxHeight = windowHeightToFitBox;
	else if (windowHeight && !isSmallWindow)
		bodyStyle.height = windowHeightToFitBox;
	else if (height)
		bodyStyle.height = height;

	if (height || maxHeight)
		bodyStyle.overflowY = 'auto';

	return (
		<div id={id} style={style} data-widget="box-widget" className={('box ' + (solid ? 'box-solid' : '')  + ' box-' + color + ' ' + (bordered ? ' box-bordered ' : '') + cssClass +(startMinimized ? ' collapsed-box' : '') + (noMarginBottom ? ' box-no-margin-bottom' : '') + (hideOnPrint ? 'no-print' : '') )}>
			{
				title && (
					<div className={classNames('box-header', 'with-border', { 'text-center': centeredTitle })}>
						<div className="box-title">
							<Icon name={icon} />
							&nbsp;&nbsp;
							{ title }
						</div>
						{minimize && (
							<div className="box-tools pull-right">
								<Button
									className="btn-box-tool"
									data-widget="collapse"
								>
									{
										startMinimized ? (
											<Icon name="plus" />
										) : (
											<Icon name="minus" />
										)
									}
								</Button>
							</div>)}
					</div>
				)
			}
			<div style={bodyStyle} className={classNames('box-body', cssBodyClass, { 'no-padding': noPadding })}>
				{ children }
			</div>
		</div>
	);
};

ContentBox.propTypes = propTypes;
ContentBox.defaultProps = defaultProps;

export { ContentBox };