import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ContentBox } from '../../common/uiElements';
import LineChart from '../../common/charts/LineChart';

const propTypes = {
	breadcrumbs: PropTypes.array,
	responsiveClass: PropTypes.string,
	vehicle: PropTypes.object, 
	onSelectGraphPoint: PropTypes.func,
};

const ZoneTempComponent = ({ breadcrumbs, responsiveClass, vehicle, onSelectGraphPoint }) => {
	if (!breadcrumbs || !vehicle || !breadcrumbs.find(p => p.trkTempZone1 || p.trkTempZone2 || p.trkTempZone3))
		return <div></div>;

	return (
		<div className={('graph-box ' + (responsiveClass ? responsiveClass : ''))}>
			<ContentBox title="Zones Temperatures" icon="thermometer-three-quarters" color="primary" minimize={true} bordered={true}>
				<LineChart data={breadcrumbs} displayLegend={true} onSelectGraphPoint={onSelectGraphPoint} vehicle={vehicle} chartName="zonesTemperatures"/>
			</ContentBox>
		</div>
	);
};

ZoneTempComponent.propTypes = propTypes;
  
const mapStateToProps = state => ({
	breadcrumbs: state.map.breadcrumbs,
	vehicle: state.filters.vehicle
});
  
export default connect(mapStateToProps)(ZoneTempComponent);