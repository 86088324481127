import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SignaturePad from 'signature_pad';
import { Button, FormControl } from 'react-bootstrap';

const propTypes = {
	id: PropTypes.string,
	onChange: PropTypes.func,
	data: PropTypes.object
};

class Signature extends Component {
	constructor() {
		super();
		this.state = {
			value: null
		};
		
		this.signaturePad = null;  
	}

	componentDidMount() {
		this.renderSignaturePad();
		if (this.props.data) {
			this.onChange(this.props.data);
			var reader = new FileReader();
			reader.readAsDataURL(this.props.data);
			reader.onloadend = () => {
				this.signaturePad.fromDataURL(reader.result);
			};
		}
		return window.addEventListener('resize', this.renderSignaturePad);
	}

	onChange = (value) => {
		if (value) {
			const $signature = window.$(this.signature);
			$signature.removeClass('invalid');
			$signature.parent().find('label.error').remove();
		}
		
		this.setState({ value: value ? 'hasFile' : '' });
		if (this.props.onChange)
			this.props.onChange(value);
	}

	dataURLtoFile = dataURL => {
		const byteString = atob(dataURL.split(',')[1]);
		const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);

		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		
		return new Blob([ab], {type: mimeString});
	}

	handleClear = () => {
		this.signaturePad.clear();
		this.onChange(null);
	}

	handleEndSignature = () => {
		let signatureBase64 = this.signaturePad.toDataURL();
		return this.onChange(this.dataURLtoFile(signatureBase64));
	}

	renderSignaturePad = () => {
		var canvas = this.canvas;
		if (canvas) {
			canvas.width = canvas.offsetWidth;
			this.signaturePad = new SignaturePad(canvas, {
				onEnd: this.handleEndSignature
			});

			return this.onChange(null);
		
		}
	}

	render() {  
		return (
			<div ref={ref => this.signature = ref} className="signature-box">
				<canvas ref={ref => this.canvas = ref} style={{width: '100%'}} height="250"></canvas>
				<Button
					bsSize="sm"
					bsStyle="danger"
					className="clear-signature-btn"
					onClick={this.handleClear}
				>
					CLEAR
				</Button>
				<FormControl 
					id={this.props.id} 
					type="hidden" 
					value={this.state.value || ''}
				/>
			</div>
		);
	}
}

Signature.propTypes = propTypes;

export { Signature };