import vehicleEndpoints from './endpoints/vehicleEndpoints';
import httpRequests from './httpRequests';
import { ListRequestOptions, ListReturn } from '../models/Application';
import { IVehicleDetails, IVehicleListDetails, VehiclePOST, VehiclePUT, VehiclesListFilter } from '../models/Vehicle';

export default {
	getVehiclesList(listOptions: ListRequestOptions, filters: VehiclesListFilter): Promise<ListReturn<IVehicleListDetails>> {
		const endpoint = vehicleEndpoints.root;
		return httpRequests.get(endpoint, { filterString: JSON.stringify({ ...listOptions, ...filters }) });
	},

	getVehicleDetails(fleetNumber: string): Promise<IVehicleDetails> {
		const endpoint = vehicleEndpoints.byId(fleetNumber);
		return httpRequests.get(endpoint);
	},

	getVehiclesListOld(onlyActive: boolean, vehTypeFilter?: number[], stateFilter?: string | string[], subContractorAbnFilter?: string) {
		const endpoint = vehicleEndpoints.root + '/Old';
		return httpRequests.get(endpoint, { onlyActive, vehTypeFilter, stateFilter, subContractorAbnFilter });
	},

	getVehicleDetailsOld(fleetNumber: string) {
		const endpoint = vehicleEndpoints.byIdOld(fleetNumber);
		return httpRequests.get(endpoint);
	},

	getVehicleTypes() {
		const endpoint = vehicleEndpoints.types;
		return httpRequests.get(endpoint);
	},

	createVehicle(data: VehiclePOST) {
		const endpoint = vehicleEndpoints.root;
		return httpRequests.post(endpoint, data);
	},

	updateVehicle(fleetNumber: string, data: VehiclePUT) {
		const endpoint = vehicleEndpoints.byId(fleetNumber);
		return httpRequests.put(endpoint, data);
	},

	inactivateVehicle(fleetNumber: string) {
		const endpoint = vehicleEndpoints.byId(fleetNumber);
		return httpRequests.delete(endpoint);
	},

	/**
	 * 
	 * @param vehFleetNumber 
	 */
	getCurrentDriver(vehFleetNumber: string) {
		const endpoint = vehicleEndpoints.currentDriver(vehFleetNumber);
		return httpRequests.get(endpoint);
	},

	/**
     * Get the Fault Reports of the Vehicle
     * @param fleetNumber Vehicle Fleet Number
     */
	getFaultReport(fleetNumber: string) {
		const endpoint = vehicleEndpoints.faultReports(fleetNumber);
		return httpRequests.get(endpoint);
	},

	/**
     * Get the Fault Reports List of many Vehicles at once
     * @param fleetNumbersList An array with Vehicle Fleet Numbers
     */
	getFaultReportsList(fleetNumbersList: string[]) {
		if (!fleetNumbersList)
			throw new Error('fleetNumbersList is required');

		let promisesList = [];
		for (var i = 0; i < fleetNumbersList.length; i++) {
			let fleetNumber = fleetNumbersList[i];
			let promise = this.getFaultReport(fleetNumber);
			promisesList.push(promise);
		}
        
		return httpRequests.getMany(promisesList);
	},

	/**
     * Get the damages of the vehicles
     * @param {string|string[]} fleetNumber Vehicle fleet number
	  * @param {boolean} [onlyOpenedDamages=true] - Just get opened damages
     */
	getVehicleDamages(fleetNumber: string | string[], onlyOpenedDamages: boolean): any {
		if (!fleetNumber)
			throw new Error('fleetNumber is required');

			if (typeof fleetNumber === 'string') {
			const endpoint = vehicleEndpoints.damagesById(fleetNumber);
			return httpRequests.get(endpoint, { onlyOpenedDamages });
		} else {
			let promisesList = [];
			for (var i = 0; i < fleetNumber.length; i++) {
				let promise = this.getVehicleDamages(fleetNumber[i], onlyOpenedDamages);
				promisesList.push(promise);
			}
            
			return httpRequests.getMany(promisesList)
				.then(vehicleDamages => {
					return [].concat.apply([], vehicleDamages);
				});
		}
	},

	/**
   * Get the photo of the vehicle damage
	* @param fleetNumber Vehicle fleet number
	* @param vehDamageId Damage ID
   */
	getDamagePhoto(fleetNumber: string, damageId: number) {
		const endpoint = vehicleEndpoints.damagePhoto(fleetNumber, damageId);
		return httpRequests.getImage(endpoint);
	},

	/**
	* Get Fault Reports list
	*/
	getVehicleDamagesList(filters: string) {
		const endpoint = vehicleEndpoints.damages;
        
		if (filters)
			filters = JSON.stringify(filters);

		return httpRequests.get(endpoint, { filterString: filters });
	},
	
	/**
	 * Set the damage as repaired
	 * @param damageId 
	 * @param fleetNumber 
	 * @param repairedOn 
	 * @param comments 
	 * @param serviceTrackerNumber 
	 */
	setVehicleDamageAsRepaired(damageId: number, fleetNumber: string, repairedOn: string, comments: string, serviceTrackerNumber: string) {
		const endpoint = vehicleEndpoints.damageRepair(fleetNumber, damageId);
		return httpRequests.put(endpoint, { repairedOn, comments, serviceTrackerNumber });
	},

	/**
	 * Update the vehicle odometer
	 * @param odometerValueInMeters 
	 */
	updateVehicleOdometer(fleetNumber: string, odometerValueInMeters: number) {
		const endpoint = vehicleEndpoints.updateOdometer(fleetNumber, odometerValueInMeters);
		return httpRequests.put(endpoint, { });
	},

	updateFreezerHours(fleetNumber: string, freezerHours: number) {
		const endpoint = vehicleEndpoints.updateFreezerHours(fleetNumber, freezerHours);
		return httpRequests.put(endpoint, { });
	}
};