export default {
	/**
	 * 
	 * @param {array} googleAddressComponents 
	 */
	destructGoogleMapsAddress(googleAddressComponents) {
		var address = {};

		for (var i = 0; i < googleAddressComponents.length; i++) {
			switch(googleAddressComponents[i].types[0]) {
			case 'street_number':
				address.address1 = googleAddressComponents[i]['short_name'] + ' ';
				break;
			case 'route':
				address.address1 += googleAddressComponents[i]['short_name'];
				break;
			case 'locality':
				address.city = googleAddressComponents[i]['short_name'];
				break;
			case 'administrative_area_level_1':
				address.state = googleAddressComponents[i]['short_name'];
				break;
			case 'postal_code':
				address.postCode = googleAddressComponents[i]['short_name'];
				break;
			case 'country':
				address.country = googleAddressComponents[i]['long_name'];
				break;
			default:
				break;
			}
		}

		return address;
	}
};