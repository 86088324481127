import React from 'react';
import moment from 'moment';

export default {
    renderTimelineItems(WorkerStatusData, hideWorkerLeftReason) {
        var items = [];

        // Loop through the WorkerStatus (starting from the last)
        for (let i = WorkerStatusData.length - 1; i >= 0; i--) {
            // Getting all variables
            var { WorkerStatusRoles, companyName, startedOn, leftOn, leftReasonName, leftComments, micwayEmployeeNumber } = WorkerStatusData[i];
            let firstRole = WorkerStatusRoles[0] ? WorkerStatusRoles[0].roleName : undefined;
            let firstRoleWorkingStatus = WorkerStatusRoles[0] ? WorkerStatusRoles[0].roleWorkingStatus : undefined;
            
            // If left the company
            if (leftOn)
                items.push({
                    'color': 'red',
                    'icon': 'stop',
                    'date': moment(leftOn).format('DD/MM/YY'),
                    'header': <span>Left <span className="title">{ companyName }</span></span>,
                    'body': hideWorkerLeftReason ? undefined: <span><b>{ leftReasonName }</b>{ leftComments ? ': ' + leftComments : '' }</span>
                });

            // Loop through the Worker Roles (starting from the last)
            for (let j = WorkerStatusRoles.length - 1; j > 0; j--) {
                let startedOnRole = WorkerStatusRoles[j].startedOn;
                let roleName = WorkerStatusRoles[j].roleName;
                let roleWorkingStatus = WorkerStatusRoles[j].roleWorkingStatus;
                items.push({
                    'color': 'yellow',
                    'icon': 'trophy',
                    'date': moment(startedOnRole).format('DD/MM/YY'),
                    'body': <span>Became <b>{ roleName }</b>&nbsp;<span className="text-muted">({ roleWorkingStatus })</span></span>
                })
            }   
            
            // Starting at the company
            items.push({
                'color': 'green',
                'icon': 'play',
                'date': moment(startedOn).format('DD/MM/YY'),
                'header': <span>Started At <span className="title">{ companyName }</span></span>,
                'body': (
							<div>
								Employee Number: <b>{ micwayEmployeeNumber }</b><br />
								{ firstRole ? <span>As <b>{ firstRole }</b>&nbsp;<span className="text-muted">({ firstRoleWorkingStatus })</span></span> : <span className="text-danger">No role was registered yet</span> }
							</div>
						)
            });

        }

        return items;
    }
}