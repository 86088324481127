export default {
	renderAllIssuesObject(preStart) {
		// SECURITY QUESTIONS
		let securityIssues = [];
		preStart.hasConsumedAlcohol && securityIssues.push({ color: 'danger', text: 'Consumed Alcohol'});
		preStart.isImpairedByDrugs && securityIssues.push({ color: 'danger', text: 'Impaired by alcohol, prescribed or recreational drugs'});
		preStart.trailer1TugTestDone === false && securityIssues.push({ color: 'danger', text: `Trailer 1 (${preStart.trailer1VehFleetNumber}) hasn't had its tug test done` });
		preStart.trailer2TugTestDone === false && securityIssues.push({ color: 'danger', text: `Trailer 2 (${preStart.trailer2VehFleetNumber}) hasn't had its tug test done` });
		preStart.isDollyDrawbarsOk === false && securityIssues.push({ color: 'danger', text: `Dolly (${preStart.dollyVehFleetNumber}) hasn't had tow coupling and drawbars check done` });
		preStart.hadMoreThanFiveShifts && securityIssues.push('Had more than 5 shifts in the last 7 days');
		preStart.hasDriverLicenceIssue && securityIssues.push('Driver Licence Issue');
		!preStart.hadBreakBetweenShifts && securityIssues.push('Had not a 10 hours break since last shift');

		// MECHANICAL ISSUES
		let mechanicalIssues = [];

		// oil and water
		!preStart.isTruckOilOk && mechanicalIssues.push('Truck Oil Added');
		!preStart.isTruckWaterOk && mechanicalIssues.push('Truck Water Added');
		preStart.isFridgeOilOk === false && mechanicalIssues.push('Fridge Oil Added');

		// lights
		!preStart.isHeadlightsOk && mechanicalIssues.push('Headlights are damaged');
		!preStart.isBlinkersOk && mechanicalIssues.push('Blinkers are damaged');
		!preStart.isBrakeLightsOk && mechanicalIssues.push('Brake is damaged');
		!preStart.isClearanceLightsOk && mechanicalIssues.push('Clearance Lights are damaged');
		preStart.isPanLightsOk === false && mechanicalIssues.push('Pan Lights are damaged');
		!preStart.isTailLightsOk && mechanicalIssues.push('Tail Lights are damaged');

		// fuel level
		preStart.mainFuelLevel === 0 && mechanicalIssues.push(`Fleet ${preStart.mainVehFleetNumber} fuel level is empty`);
		preStart.trailer1FuelLevel === 0 && mechanicalIssues.push(`Fleet ${preStart.trailer1VehFleetNumber} fuel level is empty`);
		preStart.trailer2FuelLevel === 0 && mechanicalIssues.push(`Fleet ${preStart.trailer2VehFleetNumber} fuel level is empty`);

		// seatbelt
		preStart.isSeatbeltClipOk === false  && mechanicalIssues.push('Seatbelt clip is not working');
		preStart.isSeatbeltRetractOk === false && mechanicalIssues.push('Seatbelt retract is not working');
		preStart.isSeatbeltVisualDamageOk === false && mechanicalIssues.push('Seatbelt has visual damage');

		// tyres
		!preStart.isTyreTreadsOk && mechanicalIssues.push('Tyre Treads are bare');
		!preStart.areTyresInflated && mechanicalIssues.push('Not all tyres are inflated');
		!preStart.areWheelNutsOk && mechanicalIssues.push('Not all wheel nuts are in place');
		preStart.isThereSpareTyre && preStart.isSpareTyreOk === false && mechanicalIssues.push('Spare Tyre is bare');

		// others
		!preStart.isAirTankOk && mechanicalIssues.push('Air Tank is not drained');
		!preStart.isBrakePressureOk && mechanicalIssues.push('Brake pressure needs adjust');
		preStart.isTruckFridgeOperating === false && mechanicalIssues.push('Truck Fridge is not operating');
		!preStart.isWipersOperating && mechanicalIssues.push('Wipers are not operating');
		!preStart.isMirrorsOk && mechanicalIssues.push('Mirrors are damaged');
		!preStart.isWindowsOk && mechanicalIssues.push('Windows are damaged');
		!preStart.adBlueLevel && mechanicalIssues.push('AD Blue Level is empty');
		!preStart.areAccreditationLabelsOk && mechanicalIssues.push('Accreditation Labels are not present');
		!preStart.isDashboardAlarmOk && mechanicalIssues.push('There is an alarm on the dashboard');
		preStart.isThereReverseCamera && preStart.isReverseCameraOk === false && mechanicalIssues.push('Reverse camera is not working');
		!preStart.isReverseAlarmOk && mechanicalIssues.push('Reverse alarm is not working');
		preStart.isFridgeOk === false && mechanicalIssues.push('Fridge is not working');
		preStart.isFridgeTempCorrect === false && mechanicalIssues.push('Fridge Temperature not correctly set');

		return {
			securityIssues,
			mechanicalIssues,
			faultReports: preStart.FaultReports,
			vehicleDamages: preStart.VehicleDamages,
		};
	},
	getTotalIssues(preStart) {
		const { securityIssues, mechanicalIssues } = this.renderAllIssuesObject(preStart);
		return securityIssues.length + mechanicalIssues.length + preStart.FaultReports.length + preStart.VehicleDamages.length;
	},
	isThereAnyProblem(preStartData) {
		const { securityIssues, mechanicalIssues, preStart } = preStartData;

		if (!preStart) 
			return false;

		const { FaultReports, VehicleDamages } = preStart;
        
		// If it is the last part
		if (preStart.lastReportedOnLocal) {
			const faultReportsLastForm = FaultReports.filter(f => f.isLastPreStartReport);
			const vehicleDamagesLastForm = VehicleDamages.filter(f => f.isLastPreStartReport);
			return faultReportsLastForm.length > 0 || vehicleDamagesLastForm.length > 0;
		} 
        
		const faultReportsFirstForm = FaultReports.filter(f => !f.isLastPreStartReport);
		const vehicleDamagesFirstForm = VehicleDamages.filter(f => !f.isLastPreStartReport);
		return securityIssues.length > 0 || mechanicalIssues.length > 0
        || faultReportsFirstForm.length > 0 || vehicleDamagesFirstForm.length > 0;
	},
	isThereProblemFirstPartTimeSpent(firstPartTimeSpentInSec) {
		return firstPartTimeSpentInSec < (10 * 60) || firstPartTimeSpentInSec > (40 * 60);
	},
	isThereProblemLastPartTimeSpent(lastPartTimeSpentInSec) {
		return lastPartTimeSpentInSec < (5 * 60) || lastPartTimeSpentInSec > (20 * 60);
	},
	renderStatus(preStartData) {
		const { checkOutOnLocal, lastReportedOnLocal, lastAcknowledgeOnLocal, checkOutIsApproved } = preStartData;
		let statusColor = 'warning';
		let statusText = 'PENDING APPROVAL';
		let colorClass = 'pending-approval';

		if (lastAcknowledgeOnLocal) {
			statusColor = 'success';
			statusText = 'FINALIZED';
			colorClass = 'finalized';
		} else if (lastReportedOnLocal) {
			statusColor = 'info';
			statusText = 'LAST PART SENT';
			colorClass = 'last-part-sent';
		} else if (checkOutOnLocal) {
			if (!checkOutIsApproved) {
				statusColor = 'danger';
				statusText = 'NOT APPROVED';
				colorClass = 'not-approved';
			} else {
				statusColor = 'info';
				statusText = 'RUN IN PROGRESS';
				colorClass = 'run-in-progress';
			}
		}

		return { statusText, statusColor, colorClass };
	}
};