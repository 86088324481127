import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'react-fontawesome';
import { FormControl } from 'react-bootstrap';
import { VehicleSearch } from '../../../common/inputs';

const propTypes = {
	itemRef: PropTypes.func,
	searchAddressInputRef: PropTypes.func,
	selectedVehicleFleetNumber: PropTypes.string,
	filterBy: PropTypes.oneOf(['vehicle', 'address']),
	filterFleetNumbers: PropTypes.array,
	onChangeFilterType: PropTypes.func,
	onSelectVehicleFilter: PropTypes.func
};

const FilterInput = ({
	itemRef,
	searchAddressInputRef,
	selectedVehicleFleetNumber,
	filterBy,
	filterFleetNumbers,
	onChangeFilterType,
	onSelectVehicleFilter
}) => (
	<div ref={itemRef} className="input-filter-box">
		<VehicleSearch 
			onlyActive
			value={selectedVehicleFleetNumber}
			className={filterBy !== 'vehicle' ? 'hidden' : ''}
			filterFleetNumbers={filterFleetNumbers}
			onChange={onSelectVehicleFilter}
		/>
		<FormControl 
			inputRef={searchAddressInputRef}
			className={`search-input ${filterBy !== 'address' ? 'hidden' : ''}`}
			placeholder="Enter the address..."
		/>
		<div className="search-type-box">
			<Icon 
				name="truck" 
				title="Filter by Vehicle" 
				className={filterBy === 'vehicle' ? 'active' : ''} 
				onClick={onChangeFilterType.bind(this, 'vehicle')}
			/>
			<Icon 
				name="road"
				title="Filter by Address"
				className={filterBy === 'address' ? 'active' : ''}
				onClick={onChangeFilterType.bind(this, 'address')}
			/>
		</div>
	</div>
);

FilterInput.propTypes = propTypes;

export default FilterInput;