/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import TrackingApi from '../../../../app/api/TrackingApi';
import { ContentBox, Callout, Loader } from '../../../common/uiElements';
import { PlacesList } from '../../../common/lists';
import PalletJackBoxList from './PalletJackBoxList';
import ModalPalletJackDetails from './ModalPalletJackDetails';

class PalletMonitoring extends Component {
	constructor(props) {
		super(props);
		this.state = {
			depotId: null,
			selectedPalletFleetNumber: null,
			palletJacksList: [],
			isFetchingData: false
		};
	}

	handleChangeDepot = e => {
		this.setState({ depotId: e.target.value });
	};
	
	handleSelectPalletJack = fleetNumber => {
		this.setState({ selectedPalletFleetNumber: fleetNumber });
	};
	
	handleCloseModal = () => {
		this.setState({ selectedPalletFleetNumber: null });
	};

	fetchData = showLoader => {
		this.setState({ isFetchingData: showLoader });
		TrackingApi.getPalletJackBatteryInfoLive()
			.then(palletJacksList => {
				if (this.unmounted) return;
				this.setState({ palletJacksList });
			})
			.catch(error => {
				console.error(error);
			})
			.then(() => {
				if (this.unmounted) return;
				this.setState({ isFetchingData: false });
			});
	}

	componentWillUnmount() {
		this.unmounted = true;
		clearInterval(this.intervalTimer);
	}

	componentDidMount() {
		this.fetchData(true);
		this.intervalTimer = setInterval(this.fetchData, 60000);
	}

	renderList = palletJacksList => {
		return (
			<PalletJackBoxList 
				palletJacksList={palletJacksList}
				selectedDepotId={this.state.depotId}
				onClickBox={this.handleSelectPalletJack}
			/>
		);
	}
	
	render() {
		const s = this.state;

		const palletsOnCharge = s.palletJacksList.filter(p => p.isCharging && p.depotId == s.depotId);
		const palletsNotOnCharge = s.palletJacksList.filter(p => !p.isCharging && p.depotId == s.depotId);

		const palletsOthersDepots = s.palletJacksList.filter(p => p.depotId != s.depotId).sortBy(p => [p.depotName]);
		const groupedPalletsOthersDepots = palletsOthersDepots.groupBy('depotName');

		return (
			<section className="content panel-live-monitoring">
				<div className="title">
					<span>Pallet Jack - Live Monitoring</span>
					<PlacesList id="depotId" type="palletJackLiveMonitoring" onChange={this.handleChangeDepot} />
				</div>
				{
					s.isFetchingData ? (
						<Loader text="Fetching Pallet Jacks List..."/>
					) : (
						s.depotId ? (
							<Row>
								<Col lg={5} >
									<ContentBox solid bordered centeredTitle color="primary" title="ON CHARGE" icon="plug">
										{ this.renderList(palletsOnCharge) }
									</ContentBox>
								</Col>
								<Col lg={5}>
									<ContentBox solid bordered centeredTitle color="primary" title="NOT ON CHARGE" icon="battery-3">
										{ this.renderList(palletsNotOnCharge) }
									</ContentBox>
								</Col>
								<Col lg={2}>
									<ContentBox solid bordered centeredTitle color="primary" title="ALL OTHERS" icon="plug">
										{
											palletsOthersDepots.length === 0 ? (
												<h4 className="text-center">No Pallet Jacks to show</h4>
											) : (
												Object.keys(groupedPalletsOthersDepots).map((depotName, index) => (
													<div key={index}>
														<h4><b>{ depotName }</b></h4>
														{ this.renderList(groupedPalletsOthersDepots[depotName]) }
													</div>
												))
											)
										}
									</ContentBox>
								</Col>
							</Row>
						) : (
							<Callout 
								color="info" 
								title="Select a depot to see the dashboard" 
							/>
						)
					)
				}
				{
					s.selectedPalletFleetNumber && (
						<ModalPalletJackDetails 
							{ ...s.palletJacksList.find(p => p.fleetNumber === s.selectedPalletFleetNumber) }
							onClose={this.handleCloseModal}
						/>
					)
				}
			</section>
		);
	}
}

const mapStateToProps = state => ({
	palletJackHub: state.signalr.hubs.palletJackHub
});

export default connect(mapStateToProps)(PalletMonitoring);