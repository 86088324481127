import React from 'react';
import ReactTable from 'react-table';
import moment from 'moment-timezone';
import { IStaffRosterListItem } from '../../../../../app/models/StaffRoster';
import './StaffRosterList.scss';
import { ILeaveRequestSimple } from '../../../../../app/models/LeaveRequest';

interface IProps {
	staffRosterList?: IStaffRosterListItem[];
	isLoading?: boolean;
	isReadOnly?: boolean;
	onSelectRoster?: (selectedRosterId: number, rowIndex: number) => void;

	// Table Config
	hideFilters?: boolean;
	hidePagination?: boolean;

	// Show/Hide Columns
	hideID?: boolean;
	hideWeekNumber?: boolean;
	hideDate?: boolean;
	hideBudgetedTime?: boolean;
	hideTaskType?: boolean;
	hideEmployee?: boolean;
	hideStatus?: boolean;
}

const StaffRosterList: React.FC<IProps> = props => (
	<div id="staff-roster-list">
		<ReactTable
			data={props.staffRosterList}
			filterable={!props.hideFilters}
			showPagination={!props.hidePagination}
			minRows={0}
			pageSizeOptions={props.hidePagination ? undefined : [25, 50]}
			loading={props.isLoading}
			defaultPageSize={props.hidePagination ? 9999 : 25}
			collapseOnDataChange={false}
			defaultFilterMethod={(filter, row) => {
				const id =  filter.pivotId || filter.id;
				return row[id] !== undefined ? String(row[id]).toLowerCase().includes(String(filter.value).toLowerCase()) : true
			}}
			getTrProps={(state: any, rowInfo: any) => {
				if (!rowInfo) 
					return {};
				
				const staffRosterData = rowInfo.original as IStaffRosterListItem;
				const { isOvertime, Worker: { LeaveRequest } } = staffRosterData;
				let className = 'bg-success';
				if (LeaveRequest)
					if (LeaveRequest.isApproved)
						className = 'bg-danger';
					else
						className = 'bg-warning';
				else if (isOvertime)
						className = 'bg-warning';

				
				return {
					className,
					onClick: () => props.onSelectRoster && props.onSelectRoster(staffRosterData.id, rowInfo.index)
				}
			}}
			columns={[
				{
					Header: 'Ref ID',
					accessor: 'id',
					width: 60,
					show: !props.hideID
				},
				{
					id: 'week',
					Header: 'Week',
					width: 65,
					accessor: 'startTimeLocal',
					show: !props.hideWeekNumber,
					filterMethod: (filter: any, original: any) => {
						const value = original[filter.id] && moment(original[filter.id]).isoWeek();
						return value !== undefined ? String(value).toLowerCase().includes(String(filter.value).toLowerCase()) : true;
					},
					Cell: row => 'Week ' + moment(row.value).isoWeek()
				},
				{
					id: 'dayOfWeek',
					Header: 'DoW',
					width: 85,
					accessor: 'startTimeLocal',
					filterMethod: (filter: any, original: any) => {
						const value = original[filter.id] && moment(original[filter.id]).format('dddd');
						return value !== undefined ? String(value).toLowerCase().includes(String(filter.value).toLowerCase()) : true;
					},
					Cell: row => moment(row.value).format('dddd')
				},
				{
					id: 'date',
					Header: 'Date',
					width: 80,
					accessor: 'startTimeLocal',
					show: !props.hideDate,
					filterMethod: (filter: any, original: any) => {
						const value = original[filter.id] && moment(original[filter.id]).format('DD/MM/YYYY');
						return value !== undefined ? String(value).toLowerCase().includes(String(filter.value).toLowerCase()) : true;
					},
					Cell: row => moment(row.value).format('DD/MM/YYYY')
				},
				{
					Header: 'Start',
					id: 'startTime',
					accessor: 'startTimeLocal',
					width: 50,
					Cell: row => moment(row.value).format('HH:mm')
				},
				{
					Header: 'Finish',
					id: 'finishTime',
					width: 50,
					Cell: row => {
						const { startTimeLocal, budgetedTime } = row.original as IStaffRosterListItem;
						const startTime = moment(startTimeLocal).format('HH:mm');
						return budgetedTime ? moment(moment(startTimeLocal).format('YYYY-MM-DDT' + startTime)).add(budgetedTime, 'hours').format('HH:mm') : '-';
					}
				},
				{
					Header: 'Hours',
					accessor: 'budgetedTime',
					width: 50,
					show: !props.hideBudgetedTime,
					Cell: row => row.value + ' h'
				},
				{
					Header: 'Starting Place',
					id: 'startingDepotName',
					accessor: 'startingDepotName',
					width: 140,
					Cell: row => row.value || '-'
				},
				{
					Header: 'Main Task',
					accessor: 'mainTaskTypeName',
					show: !props.hideTaskType,
					width: 120
				},
				{
					id: 'employee',
					Header: 'Employee',
					accessor: 'Worker.shortName',
					show: !props.hideEmployee,
					width: 150
				},
				{
					id: 'role',
					Header: 'Role',
					accessor: 'Worker.currentRoleName',
					show: !props.hideEmployee,
					width: 150
				},
				{
					id: 'driversLicense',
					Header: 'License Class',
					accessor: 'Worker.currentLicenceType',
					show: !props.hideEmployee,
					width: 100,
					Cell: row => row.value || 'Not Registered'
				},
				{
					id: 'status',
					Header: 'Status',
					accessor: p => p,
					show: !props.hideStatus,
					Cell: row => {
						const { Worker, isOvertime } = row.value;
						const leaveRequest = Worker.LeaveRequest as ILeaveRequestSimple;
						if (!leaveRequest)
							return (
								<b className={isOvertime ? "text-warning" : "text-success"}>
									Available { isOvertime && ' (Overtime)' }
								</b>
							);

						const { isApproved, startDate, endDate, absenceReasonTypeName } = leaveRequest;
						const status = `${absenceReasonTypeName} from ${moment(startDate).format('DD/MM')} to ${moment(endDate).format('DD/MM')} (${isApproved ? 'Approved' : 'Pending Approval'})`;
						return status;
					}
				},
			]}
		/>
	</div>
);

export default StaffRosterList;