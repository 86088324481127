import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Icon from 'react-fontawesome';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';

import { setZoom, setPosition, setMarkers } from '../../../stores/redux/map';
import TrackingApi from '../../../app/api/TrackingApi';

import { SpeedingReportSettings } from '../../../app/schemas/ReportsSettings';

import GoogleMap from '../../common/GoogleMapOld';
import { SpeedTicketsList } from '../../common/tracking';
import { MainContent } from '../../common/layout';
import { DatePeriodInput, StatesList } from '../../common/inputs';
import { FiltersBox, ContentBox, Loader, ErrorBox } from '../../common/uiElements';

const propTypes = {
	stateFilter: PropTypes.string,
	filters: PropTypes.shape({
		startTime: PropTypes.number,
		endTime: PropTypes.number,
	})
};

class ReportSpeeding extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			speedTicketsData: null
		};

		this.periodRanges = {
			'Last 1 hour': [
				moment().add(-1, 'hours'),
				moment()
			],
			'Last 6 hours': [
				moment().add(-6, 'hours'),
				moment()
			],
			'Last 8 hours': [
				moment().add(-8, 'hours'),
				moment()
			],
			'Last 1 day': [
				moment().add(-1, 'days'),
				moment()
			],
			'Last 2 days': [
				moment().add(-2, 'days'),
				moment()
			]
		};
	}

	getStateFilter = () => {
		return window.$("#stateFilter").find('input:checkbox:checked').map(function(){
			return window.$(this).val();
		}).get();
	}

	onSelectMapDataPoint = (lat, lng) => {
		this.props.setZoom(20);
		this.props.setPosition(lat, lng);

		return window.$('html, body').animate({scrollTop: window.$('#mapBox').offset().top - 50}, 500);
	}

	onSearch = () => {
		this.setState({ isLoading: true, speedTicketsData: null, error: null });
		var { startTime, endTime } = this.props.filters;
		var { stateFilter } = this.props;

		// States Filter
		stateFilter = this.getStateFilter();

		var self = this;
		TrackingApi.speedTicketsByTime(startTime, endTime, stateFilter)
			.then((speedTicketsData) => {
				if (self.unmounted) { return; }
			
				var markers = self.renderMarkersData(speedTicketsData);
				self.props.setMarkers(markers);

				self.props.setZoom(4);
				self.setState({ speedTicketsData, isLoading: false });
			})
			.catch(error => {
				this.setState({ error, isLoading: false });
				console.error(error);
			});
	}

	renderMarkersData = (dataArray) => {
		var markers = [];
		dataArray.forEach(speedTicket => {
			speedTicket.Points.forEach(point => {
				const { trkPositionLat, trkPositionLong } = point;
				if (!trkPositionLat || !trkPositionLong)
					return;
					
				var marker = {};
				marker.vehFleetNumber = speedTicket.trkFleetNumber;
				marker.lat = trkPositionLat;
				marker.lng = trkPositionLong;

				marker.infowindow = {
					trkCollectedOnEpoch: point.trkCollectedOnEpoch,
					trksptRoadSpeedLimit: speedTicket.trksptRoadSpeedLimit,
					trkSpeedKm: point.trkSpeedKm
				};
				markers.push(marker);
			});
		});
		return markers;
	}

	render() {
		const s = this.state;
		const { dateStartMinutes, dateFinishMinutes, periodLimitInMinutes } = SpeedingReportSettings;

		return (
			<MainContent title="Speeding Report" subtitle="">
				<FiltersBox>
					<FormGroup id="stateFilter">
						<label>States:</label>
						<StatesList allChecked={true}/>
					</FormGroup>
					<Row>
						<Col sm={8} md={10}>
							<FormGroup>
								<DatePeriodInput 
									ranges={this.periodRanges}
									showHours={true} 
									startDateInMinutes={dateStartMinutes} 
									endDateInMinutes={dateFinishMinutes} 
									periodLimitInMinutes={periodLimitInMinutes} 
									onChange={this.onChangePeriod}
								/>
							</FormGroup>
						</Col>
						<Col sm={4} md={2}>
							<FormGroup>
								<Button 
									bsStyle="primary"
									onClick={this.onSearch}
								>
									<Icon name="search" /> Search
								</Button>
							</FormGroup>
						</Col>
					</Row>
				</FiltersBox>
				{
					s.error ? (
						<ErrorBox error={s.error} retryFunc={this.onSearch}/>
					) : (
						s.isLoading ? (
							<Loader />
						) : (
							s.speedTicketsData && (
								<Fragment>
									<ContentBox title="Speed Tickets List" icon="dashboard" color="danger" noMarginBottom={true}>
										<SpeedTicketsList 
											showFleetNumber
											showAddress={false} 
											speedTicketsData={s.speedTicketsData}
											onSelectSpeedTicket={this.onSelectMapDataPoint} 
										/>
									</ContentBox>
									{
										s.speedTicketsData.length > 0 && (
											<div id="mapBox">
												<GoogleMap />
											</div>
										)
									}
								</Fragment>
							)
						)
					)
				}
			</MainContent>
		);
	}
}

ReportSpeeding.propTypes = propTypes;

const mapStateToProps = state => ({ filters: state.filters });
const mapDispatchToProps = { setZoom, setPosition, setMarkers };

export default connect(mapStateToProps, mapDispatchToProps)(ReportSpeeding);
