import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from 'react-fontawesome';
import { Callout, Table } from '../../common/uiElements';

const propTypes = {
	vehFleetNumber: PropTypes.string,
	speedTicketsData: PropTypes.array,
	showFleetNumber: PropTypes.bool,
	showAddress: PropTypes.bool,
	onSelectSpeedTicket: PropTypes.func
};

const defaultProps = {
	showAddress: true
};

const SpeedTicketsList = props => {
	const onExpandRow = indexData => {
		window.$('body').find('tr[data-index=' + indexData + ']').toggle();
	};

	const onSelectSpeedTicket = (lat, lng, index) => {
		if (props.onSelectSpeedTicket)
			props.onSelectSpeedTicket(lat, lng, index);
	};
	
	return (
		<div>
			{
				props.speedTicketsData && props.speedTicketsData.length > 0 ? (
					<div id="speedTicketsTable">
						<Table cssClass="table table-hover">
							<thead>
								<tr>
									<th></th>
									<th title="Ticket ID"><Icon name="microchip" /> <span>Ticket ID</span></th>
									{ props.showAddress && <th title="Address"><Icon name="map-marker" /> <span>Address</span></th> }
									{ props.showFleetNumber && <th title="Fleet Number"><Icon name="truck" /> <span>Fleet Number</span></th> }
									<th title="Road Speed Limit"><Icon name="dashboard" /> <span>Road Speed Limit</span></th>
								</tr>
							</thead>
							<tbody>
								{
									props.speedTicketsData.map((speedData, indexData) => {
										return ([
											<tr data-row={indexData} title="Click to see in the map" className="cursor-pointer active" key={speedData.trksptId} onClick={() => onExpandRow(indexData)}>
												<th className="details-control">
													<Icon name="plus-circle" />
												</th>
												<th>{speedData.trksptId}</th>
												{ props.showAddress && (<th>{speedData.Points[0].trkAddress}</th>)}
												{ props.showFleetNumber && (<th>{speedData.trkFleetNumber}</th>) }
												<th>{parseInt(speedData.trksptRoadSpeedLimit, 10)} Km/h</th>
											</tr>,
											speedData.Points.map((point, indexPoint) => {
												return (
													<tr style={{display: 'none'}} key={point.trkspId} data-index={indexData} onClick={() => onSelectSpeedTicket(point.trkPositionLat, point.trkPositionLong, indexPoint)}>
														<td colSpan="5">
															<table className="table table-hover table-condensed no-margin-bottom no-border">
																<tbody>
																	<tr className="cursor-pointer">
																		<td><b>GPS Status:</b> {point.trkUnitGPSStatus}</td>
																		<td><b>Time:</b> {moment.unix(point.trkCollectedOnEpoch).format('DD/MM/YY HH:mm')}</td>
																		{ props.showAddress && (<td width="50%"><b>Address:</b> {point.trkAddress}</td>) }
																		<td><b>Speed:</b> {parseInt(point.trkSpeedKm, 10)} Km/h</td>
																	</tr>
																</tbody>
															</table>
														</td>
														<td style={{display: 'none'}}>
															{speedData.trksptId}
															{parseInt(speedData.trksptRoadSpeedLimit, 10)} Km/h
														</td>
														<td style={{display: 'none'}}></td>
														{ props.showAddress && (<td style={{display: 'none'}}>{speedData.Points[0].trkAddress}</td>)}
														{ props.showFleetNumber && (<td style={{display: 'none'}}>{speedData.trkFleetNumber}</td>) }
													</tr>
												);
											})
											
										]);
									})
								}
							</tbody>
						</Table>
						<hr />
						<Callout color="info" text="This information is based on the select time above. To see the current information, please go to the Full Map link." />
					</div>
				) : (
					<p><i>No data has been found</i></p>
				)
			}
		</div>
	);
};

SpeedTicketsList.propTypes = propTypes;
SpeedTicketsList.defaultProps = defaultProps;

export { SpeedTicketsList };