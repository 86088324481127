import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import WorkerApi from '../../../../../app/api/WorkerApi';
import { ErrorBox, Callout, Loader } from '../../../../common/uiElements';

const propTypes = {
	azureId: PropTypes.string.isRequired
};

class WorkerResetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isResetting: false,
			returnedData: null,
			error: null,
		};
	}
	
	componentWillUnmount() {
		this.unmounted = true;
	}

	handleResetPassword = () => {
		this.setState({ 
			isResetting: true,
			error: null
		}, () => {
			WorkerApi.resetPassword(this.props.azureId)
				.then(returnedData => {
					if (this.unmounted) return;
					this.setState({ returnedData });
				})
				.catch(error => {
					console.error(error);
					if (this.unmounted) return;
					this.setState({ error });
				})
				.then(() => {
					if (this.unmounted) return;
					this.setState({ isResetting: false });
				});
		});
	}
	
	render() {
		const s = this.state;

		if (s.error)
			return <ErrorBox error={s.error} retryFunc={this.handleResetPassword} />;

		if (s.isResetting)
			return <Loader text="Resetting password..." />;

		if (s.returnedData) {
			const { newPassword, sentVia, sentToUser, sentTo } = s.returnedData;

			return ( 
				<Callout color="info">
					{
						newPassword ? (
							<span>The new password is <b>{ newPassword }</b>.</span>
						) : (
							<span>The new password and PIN was sent via <b>{ sentVia }</b> to <b>{ sentToUser } ({sentTo})</b>.</span>
						)
					}
				</Callout>
			);
		}

		return (
			<Button onClick={this.handleResetPassword} block bsStyle="primary">
					Reset Password
			</Button>
		);
	}
}

WorkerResetPassword.propTypes = propTypes;

export default WorkerResetPassword;