import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Row, Col, FormGroup, FormControl } from 'react-bootstrap';
import { Form, UserDetailsLink } from '../../../../common/uiElements';
import { UserSearch, RadioButtonList, VehicleSearch } from '../../../../common/inputs';
import { PlacesList, NonConformanceTypesList } from '../../../../common/lists';
import workerSimpleType from '../../../../../app/propTypes/workerSimpleType';
import { NonConformanceRootCausesList } from '../../../../common/lists/NonConformanceRootCausesList';

const propTypes = {
	nonConformanceTypeId: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]).isRequired,
	rootCauseId: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	rootCauseName: PropTypes.string,
	details: PropTypes.string.isRequired, 
	reportedOn: PropTypes.string.isRequired,
	occurredOn: PropTypes.string.isRequired,
	involvedFleetNumber: PropTypes.string,
	involvedWorkerId: PropTypes.string,
	reportedById: PropTypes.string,
	reportedByExternal: PropTypes.string,
	placeId: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	comments: PropTypes.string,
	witness1: PropTypes.string,
	witness2: PropTypes.string,
	placeName: PropTypes.string,
	nonConformanceTypeName: PropTypes.string,
	WorkerReportedBy: workerSimpleType,
	WorkerInvolvedBy: workerSimpleType,
	
	isEditMode: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	reportedByType: PropTypes.oneOf(['internal', 'external']),
	onChange: PropTypes.func,
	onChangeUser: PropTypes.func,
	onChangeVehicle: PropTypes.func,
	onChangeReportedByType: PropTypes.func,
};

const defaultProps = {
	nonConformanceTypeId: '',
	rootCauseId: '',
	details: '',
	reportedOn: '',
	occurredOn: '',
	involvedFleetNumber: '',
	involvedWorkerId: '',
	reportedById: '',
	reportedByExternal: '',
	placeId: '',
	comments: '',
	witness1: '',
	witness2: '',

	WorkerReportedBy: null,
	nonConformanceTypeName: '',
	isEditMode: false,
	isReadOnly: false,
	reportedByType: 'internal',
	onChange: () => {},
	onChangeUser: () => {},
	onChangeReportedByType: () => {}
};

const validations = {
	nonConformanceTypeId: 'required',
	nonConformanceRootCauseId: 'required',
	details: 'required', 
	reportedOn: 'required',
	occurredOn: 'required',
	reportedById: 'required',
	reportedByExternal: 'required'
};

const NonConformanceForm = props => (
	<Form validations={validations}>
		<Row>
			<Col xs={12} sm={6} md={4}>
				<FormGroup>
					<label>Type *</label>
					{
						props.isReadOnly ? (
							<p>{props.nonConformanceTypeName}</p>
						) : (
							<NonConformanceTypesList 
								id="nonConformanceTypeId"
								value={props.nonConformanceTypeId || ''}
								onChange={props.onChange}
							/>
						)
					}
				</FormGroup>
			</Col>
			<Col xs={12} sm={6} md={4}>
				<FormGroup>
					{
						props.isReadOnly ? (
							<Fragment>
								<label>Reported By</label>
								<p>
									{
										props.reportedById && props.WorkerReportedBy ? (
											<UserDetailsLink azureId={props.reportedById}>
												{props.WorkerReportedBy.shortName}
											</UserDetailsLink>
										) : (
											props.reportedByExternal || '-'
										)
									}
								</p>
							</Fragment>
						) : (
							<Fragment>
								<RadioButtonList 
									textBefore="Reported By *"
									value={props.reportedByType || ''}
									items={[{text: 'Internal', value: 'internal'}, {text: 'External', value: 'external'}]} 
									onChange={props.onChangeReportedByType} 
								/>
								<div style={{marginTop: '-18px'}}>
									{
										props.reportedByType === 'internal' ? (
											<UserSearch 
												id="reportedById"
												value={props.reportedById || ''}
												onChange={user => props.onChangeUser('reportedById', user.value)}
											/>
										) : (
											<FormControl
												id="reportedByExternal" 
												maxLength="100" 
												placeholder="Type who reported this non conformance..."
												value={props.reportedByExternal || ''} 
												onChange={props.onChange} 
											/>
										)
									}
										
								</div>
							</Fragment>
						)
					}
				</FormGroup>
			</Col>
			<Col xs={12} sm={6} md={4}>
				<FormGroup>
					<label>Reported On *</label>
					{
						props.isReadOnly ? (
							<p>{props.reportedOn ? moment(props.reportedOn).format('DD/MM/YYYY') : '-'}</p>
						) : (
							<FormControl 
								id="reportedOn" 
								type="date" 
								value={moment(props.reportedOn).isValid() ? moment(props.reportedOn).format('YYYY-MM-DD') : ''}
								onChange={props.onChange} 
							/>
						)
					}
				</FormGroup>
			</Col>
		</Row>
		<Row>
			<Col xs={12} sm={6} md={3}>
				<FormGroup>
					<label>Occurred On *</label>
					{
						props.isReadOnly ? (
							<p>{props.occurredOn ? moment(props.occurredOn).format('DD/MM/YYYY') : '-'}</p>
						) : (
							<FormControl 
								id="occurredOn" 
								type="date" 
								value={moment(props.occurredOn).isValid() ? moment(props.occurredOn).format('YYYY-MM-DD') : ''}
								onChange={props.onChange} 
							/>
						)
					}
				</FormGroup>
			</Col>
			<Col xs={12} sm={6} md={3}>
				<FormGroup>
					<label>Place</label> (Optional)
					{
						props.isReadOnly ? (
							<p>{props.placeName || '-'}</p>
						) : (
							<PlacesList 
								id="placeId"
								type="preStart"
								value={props.placeId || ''}
								onChange={props.onChange}
							/>
						)
					}
				</FormGroup>
			</Col>
			<Col xs={12} sm={6} md={3}>
				<FormGroup>
					<label>Fleet Involved</label> (Optional)
					{
						props.isReadOnly ? (
							<p>{props.involvedFleetNumber || '-'}</p>
						) : (
							<VehicleSearch 
								onlyActive
								id="involvedFleetNumber"
								value={props.involvedFleetNumber || ''}
								onChange={vehicle => props.onChangeVehicle('involvedFleetNumber', vehicle.vehFleetNumber)}
							/>
						)
					}
				</FormGroup>
			</Col>
			<Col xs={12} sm={6} md={3}>
				<FormGroup>
					<label>Employee Involved</label> (Optional)
					{
						props.isReadOnly ? (
							<p>
								{
									props.involvedWorkerId ? (
										<UserDetailsLink azureId={props.involvedWorkerId}>
											{props.WorkerInvolvedBy.shortName}
										</UserDetailsLink>
									) : '-'
								}
							</p>
						) : (
							<UserSearch 
								id="involvedWorkerId"
								value={props.involvedWorkerId || ''}
								onChange={user => props.onChangeUser('involvedWorkerId', user.value)}
							/>
						)
					}
				</FormGroup>
			</Col>
		</Row>
		<FormGroup>
			<label>Root Cause</label>
			{
				props.isReadOnly ? (
					<p>{props.rootCauseName || '-'}</p>
				) : (
					<NonConformanceRootCausesList 
						id="rootCauseId" 
						value={props.rootCauseId || ''} 
						onChange={props.onChange} 
					/>
				)
			}
		</FormGroup>
		<Row>
			<Col md={6}>
				<FormGroup>
					<label>Details *</label>
					{
						props.isReadOnly ? (
							<p>{props.details}</p>
						) : (
							<FormControl
								id="details" 
								maxLength="1000" 
								componentClass="textarea"
								placeholder="Add as many details as possible..."
								value={props.details || ''} 
								onChange={props.onChange} 
							/>
						)
					}
				</FormGroup>
			</Col>
			<Col md={6}>
				<FormGroup>
					<label>Comments</label> (Optional)
					{
						props.isReadOnly ? (
							<p>{props.comments || '-'}</p>
						) : (
							<FormControl
								id="comments" 
								maxLength="1000" 
								componentClass="textarea"
								value={props.comments || ''} 
								onChange={props.onChange} 
							/>
						)
					}
				</FormGroup>
			</Col>
		</Row>
		<Row>
			<Col xs={6} md={6}>
				<FormGroup>
					<label>Witness 1</label> (Optional)
					{
						props.isReadOnly ? (
							<p>{props.witness1 || '-'}</p>
						) : (
							<FormControl
								id="witness1" 
								maxLength="100"
								value={props.witness1 || ''} 
								onChange={props.onChange} 
							/>
						)
					}
				</FormGroup>
			</Col>
			<Col xs={12} md={6}>
				<FormGroup>
					<label>Witness 2</label> (Optional)
					{
						props.isReadOnly ? (
							<p>{props.witness2 || '-'}</p>
						) : (
							<FormControl
								id="witness2" 
								maxLength="100"
								value={props.witness2 || ''} 
								onChange={props.onChange} 
							/>
						)
					}
				</FormGroup>
			</Col>
		</Row>
	</Form>
);


NonConformanceForm.propTypes = propTypes;
NonConformanceForm.defaultProps = defaultProps;

export default NonConformanceForm;