import React from 'react';

interface IProps {
	text: string;
}

const Title: React.FC<IProps> = props => (
	<h3 className="title">{props.text}</h3>
)

export { Title };