import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';

import { Form } from '../../../../../common/uiElements';
import { RadioButtonList } from '../../../../../common/inputs';
import FaultReportListContainer from '../../FaultReportListContainer';
import VehicleDamagesModal from '../../../../vehicles/VehicleDamagesModal';

const guideSteps = [
	{
		title: 'Step 6 - Fault Reports Raised',
		text: 'All Fault Reports raised are listed below',
		selector: '.pre-start__fault-report-raised',
		position: 'top'
	},
	{
		title: 'Edit',
		text: 'You can make changes if needed',
		selector: '.fault-report__edit-button',
		position: 'top'
	},
	{
		title: 'Delete',
		text: 'You can delete a Fault Report raised in error',
		selector: '.fault-report__delete-button',
		position: 'top'
	},
	{
		title: 'Add more Fault Reports',
		text: 'New Fault Reports can be raised here',
		selector: '.fault-report__add-new-button',
		position: 'top'
	},
	{
		title: 'Any new Damage',
		text: 'Please report any new damage that you have identified',
		selector: '.pre-start__any-new-damage',
		position: 'top'
	},
	{
		title: 'Add Damage Photos',
		text: 'Take photos of any new damage',
		selector: '.pre-start__add-new-damage',
		position: 'top',
		isLastStep: true
	}
];

class ProblemsChecking extends Component {
	static propTypes = {
		faultReportItems: PropTypes.array,
		vehicleDamageItems: PropTypes.object,
		fleetNumbers: PropTypes.arrayOf(PropTypes.string),
		onChange: PropTypes.func.isRequired,
		isReadOnly: PropTypes.bool,
		isGuideMode: PropTypes.bool,
		addGuideSteps: PropTypes.func,
	}

	static defaultProps = {
		vehicleDamageItems: {}
	}

	constructor(props) {
		super(props);

		this.state = {
			showVehicleDamageModal: false,
			selectedVehFleetNumber: null,
			hasNewDamage: this.props.isGuideMode || ''
		};

		this.validations = {
			hasNewDamage: 'required'
		};

		this.handleChangeFaultReport = (FaultReports) => {
			this.props.onChange && this.props.onChange(FaultReports, 'FaultReports');
		};

		this.handleChangeHasNewDamage = (e, value) => {
			value = e.target.type === 'checkbox' ? e.target.checked : (typeof value !== 'undefined' ? value : e.target.value);
			this.setState({ hasNewDamage: value });
		};
	}

	componentDidMount() {
		this.props.addGuideSteps && this.props.addGuideSteps(guideSteps);		
	}

	handleChangeVehicleDamages = (VehicleDamages) => {
		let data = {
			...this.props.vehicleDamageItems,
			...VehicleDamages
		};

		if (this.props.onChange)
			this.props.onChange(data, 'VehicleDamages');
	}

	handleShowModalVehDamage = (selectedVehFleetNumber) => {
		this.setState({
			showVehicleDamageModal: true,
			selectedVehFleetNumber
		});
	}

	handleCloseModalVehDamage = () => {
		this.setState({
			showVehicleDamageModal: false,
			selectedVehFleetNumber: null
		});
	}

	render() {
		return (
			<div className="pre-start__fault-report-raised">
				<Form validations={this.validations}>
					<h3 className="title">
						<Icon name="warning" /> Fault Reports Raised
					</h3>
					<FaultReportListContainer 
						faultReportItems={this.props.faultReportItems}
						fleetNumbers={this.props.fleetNumbers} 
						onChange={this.handleChangeFaultReport}
						fetchData={false}
					/>
					<h3 className="title">
						<Icon name="warning" /> Vehicle Damages
					</h3>
					<Row>
						<Col xs={12}>
							<FormGroup className="pre-start__any-new-damage">
								<label>Is there any new damage to this vehicle that you have seen during your pre start inspection?</label>
								{
									this.props.isReadOnly ? (
										<p>{ this.state.hasNewDamage ? 'Yes' : 'No' }</p>
									) : (
										<RadioButtonList 
											id="hasNewDamage"
											value={this.state.hasNewDamage}
											items={[{text: 'Yes', value: true}, {text: 'No', value: false}]} 
											onChange={this.handleChangeHasNewDamage} 
										/>
									)
								}
							</FormGroup>
						</Col>
					</Row>
					{
						this.state.hasNewDamage && (
							<Row>
								<Col xs={12} className="pre-start__add-new-damage">
									<label>Attach pictures of the damage(s) to the vehicle(s):</label>
									<ul className="list list-inline">
										{
											this.props.fleetNumbers.map((fleet, index) => {
												let damages = this.props.vehicleDamageItems[fleet];
												return (
													<li key={index}>
														<Button
															bsSize="sm"
															bsStyle="info"
															onClick={() => this.handleShowModalVehDamage(fleet)}
														>
															{ `${fleet} (${damages ? damages.length : 0 })` }
														</Button>
													</li>
												);
											})
										}
									</ul>
									<VehicleDamagesModal 
										data={this.props.vehicleDamageItems[this.state.selectedVehFleetNumber]}
										vehFleetNumber={this.state.selectedVehFleetNumber} 
										onChange={this.handleChangeVehicleDamages}
										show={this.state.showVehicleDamageModal}
										onClose={this.handleCloseModalVehDamage}
									/>
								</Col>
							</Row>
						)
					}
				</Form>
			</div>
		);
	}
}

export default ProblemsChecking;