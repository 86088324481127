import { logout } from '../../app/config/auth.config';
import packageInfo from '../../../package.json';
const latestUiVersion = packageInfo.version;

const initialState = {
	autoDisconnectInSec: null,
	latestUiVersion
};

/** 
 * ACTION TYPES
*/
export const SET_AUTO_DISCONNECT = 'app/SET_AUTO_DISCONNECT';
export const SET_LATEST_UI_VERSION = 'app/SET_LATEST_UI_VERSION';

/**
 * ACTIONS
 */
export function setAutoDisconnectTimer(seconds) {
	return {
		type: SET_AUTO_DISCONNECT,
		payload: seconds
	};
}

export function startAutoDisconnectTimer() {
	return (dispatch, getState) => {
		window.timerToDisconnect = setInterval(() => {
			const autoDisconnectInSec = getState().app.autoDisconnectInSec;
			if (autoDisconnectInSec === 0) {
				clearInterval(window.timerToDisconnect);
				sessionStorage.clear();
				logout();
			} else
				dispatch(setAutoDisconnectTimer(autoDisconnectInSec - 1));
		}, 1000);
	};
}

export function setLatestUiVersion(version) {
	return {
		type: SET_LATEST_UI_VERSION,
		payload: version
	};
}

/**
 * REDUCER
 */
export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_AUTO_DISCONNECT:
			sessionStorage.setItem('autoDisconnectInSec', payload)
			return { ...state, autoDisconnectInSec: payload };
		case SET_LATEST_UI_VERSION:
			return { ...state, latestUiVersion: payload };
		default:
			return state;
		}
};