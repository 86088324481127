export const commodityList = [
	{ value: '1907', label: '1907' },
	{ value: '1911', label: '1911' },
	{ value: '1926', label: '1926' },
	{ value: '1947', label: '1947' }
]

export const palletTypeList = [
	{ value: 'CHEP', label: 'CHEP' },
	{ value: 'LOSCAM', label: 'LOSCAM' },
	{ value: 'T3', label: 'T3' }
]

export const palletLocationInTrailer = [
	{ value: 'L', label: 'LEFT' },
	{ value: 'R', label: 'RIGHT' }
]