import axios from 'axios';
import 'moment-timezone';
import fileDownload from 'js-file-download';
import { createPromise, msalApiFetch } from '../utils';
import { IRequestData } from '../../../models/Application';
import { Buffer } from 'buffer';

export function get(endpoint: string, data?: IRequestData) {
	return createPromise('GET', endpoint, data);
}

export function getWithParams(endpoint: string, data: IRequestData, paramsSerializer: (params: object) => string) {
	return createPromise('GET', endpoint, data, paramsSerializer);
}

export function downloadFile(endpoint: string, params?: any) {
	return msalApiFetch(endpoint, { params, responseType: 'blob' })
		.then((response: any) => {
			const contentDisposition = response.headers['content-disposition'];
			if (!contentDisposition)
				throw new Error(`Content disposition not present on response from ${endpoint}`);
			
				
			const fileName = contentDisposition.substring(contentDisposition.indexOf('filename=') + 9).split('"').join('');
			return fileDownload(response.data, fileName);
		});
}

export function getFile(endpoint: string, params?: any) {
	return msalApiFetch(endpoint, { params, responseType: 'blob' })
		.then((response: any) => {
			const contentDisposition = response.headers['content-disposition'];
			if (!contentDisposition)
				throw new Error(`Content disposition not present on response from ${endpoint}`);

			return response.data;
		});
}

export function getAudio(endpoint: string, params?: any) {
	return getFile(endpoint, params)
		.then((audioFile: any) => {
			return new Audio(window.URL.createObjectURL(audioFile));
		});
}

export function getImage(endpoint: string, params?: any) {	
	return msalApiFetch(endpoint, { params, responseType: 'arraybuffer' })
		.then((response: any) => {
			const imgBase64 = Buffer.from(response.data, 'binary').toString('base64');
			return 'data:image/png;base64, ' + imgBase64;
		});
}

export function getMany(promisesList: any[]) {
	return axios.all(promisesList);
}