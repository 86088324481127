import groups from '../app/schemas/PermissionGroups';
import Tasks from '../components/features/roster/tasks';
import StaffRoster from '../components/features/roster/StaffRoster';
// import RosterReplication from '../components/features/roster/replication';
import RunTemplate from '../components/features/roster/Run/RunTemplate';

const authorizeRoot = [groups.DEPOT_ADMIN, groups.SUPERVISORS, groups.ADMIN_STAFF_SIMPLE, groups.ADMIN_STAFF_FULL, groups.WASHBAY_ADMIN];

export default { 
	path: 'roster',
	menuItem: { title: 'Roster', icon: 'calendar-check-o' },
	authorize: authorizeRoot,
	childRoutes: [
		{ 
			path: 'staff', 
			menuItem: { title: 'Staff', icon: 'user' },
			authorize: [groups.DEPOT_ADMIN, groups.SUPERVISORS, groups.ADMIN_STAFF_SIMPLE, groups.ADMIN_STAFF_FULL, groups.WASHBAY_ADMIN],
			component: StaffRoster
		},
		{ 
			path: 'tasks', 
			menuItem: { title: 'Tasks', icon: 'check' },
			authorize: [groups.DEPOT_ADMIN, groups.SUPERVISORS, groups.ADMIN_STAFF_SIMPLE, groups.ADMIN_STAFF_FULL],
			component: Tasks
		},
		// { 
		// 	path: 'replication', 
		// 	menuItem: { title: 'Replication', icon: 'clone' },
		// 	authorize: [groups.ADMIN],
		// 	component: RosterReplication
		// },
		{ 
			path: 'runs',
			menuItem: { title: 'Runs', icon: 'truck' }, 
			authorize: [groups.DEPOT_ADMIN, groups.ADMIN_STAFF_SIMPLE, groups.ADMIN_STAFF_FULL],
			childRoutes: [
				{ 
					path: 'master', 
					menuItem: { title: 'Master Template', icon: 'book' }, 
					component: RunTemplate,
				}
			]
		}
	]
};
