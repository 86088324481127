import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import classNames from 'classnames';
import Icon from 'react-fontawesome';
import { Badge } from 'react-bootstrap';
import './styles.css';
import moment from 'moment';

const propTypes = {
	to: PropTypes.string,
	icon: PropTypes.string,
	text: PropTypes.string,
	size: PropTypes.oneOf(['lg', 'sm']),
	color: PropTypes.oneOf(['default', 'success', 'info', 'warning', 'danger', 'primary']),
	external: PropTypes.bool,
	releaseDate: PropTypes.string
};

const defaultProps = {
	to: '',
	icon: '',
	text: '',
	size: 'lg',
	color: 'info',
	external: false,
	releaseDate: ''
};

const DashboardLinkButton = ({
	to,
	icon,
	text,
	color,
	size,
	external,
	releaseDate
}) => {
	const cssClass = classNames(
		'ui-element',
		'dashboard-link-button',
		'btn',
		'btn-block',
		[`btn-${size}`],
		[`btn-${color}`]
	);

	if (external)
		return (
			<div className="home-link-container">
				<a 
					href={to}
					target="_blank"
					className={cssClass} 
					rel="noopener noreferrer"
				>
						<Icon name={icon} size="2x" /><br/>
						<b>{ text }</b> <Icon name="external-link" style={{ fontSize: '.8em' }} />
						{ releaseDate && moment() <= moment(releaseDate).add(60, 'days') && <Badge>New!</Badge> }
				</a>
			</div>
		);
	
	return (
		<div className="home-link-container">
			<Link 
				to={to}
				className={cssClass}
			>
				<Icon name={icon} size="2x" /><br/>
				<b>{ text }</b>
				{ releaseDate && moment() <= moment(releaseDate).add(60, 'days') && <Badge>New!</Badge> }
			</Link>
		</div>
	);
};

DashboardLinkButton.propTypes = propTypes;
DashboardLinkButton.defaultProps = defaultProps;

export { DashboardLinkButton };