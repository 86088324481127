/* eslint-disable eqeqeq */
/* eslint-disable no-extend-native */

// Array Group By
function groupBy(key) {
	return this.reduce(function(rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
}

if (typeof Object.defineProperty === 'function')
	try{Object.defineProperty(Array.prototype,'groupBy',{value:groupBy}); }catch(e){ console.error(e) }

if (!Array.prototype.groupBy) Array.prototype.groupBy = groupBy;

// Array Sort By
if (typeof Object.defineProperty === 'function') {
	try{Object.defineProperty(Array.prototype,'sortBy',{value:sortBy}); }catch(e){ console.error(e) }
}
if (!Array.prototype.sortBy) Array.prototype.sortBy = sortBy;

function sortBy(f) {
	for (let i=this.length;i;){
		var o = this[--i];
		this[i] = [].concat(f.call(o,o,i),o);
	}

	this.sort(function(a,b){
		for (let i=0,len=a.length;i<len;++i){
			if (a[i]!=b[i]) return a[i]<b[i]?-1:1;
		}
		return 0;
	});
	
	for (let i=this.length;i;){
		this[--i]=this[i][this[i].length-1];
	}
	return this;
}

// IMG to Blob Polyfill
if (!HTMLCanvasElement.prototype.toBlob) {
	Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
		value: function (callback, type, quality) {
			var binStr = atob( this.toDataURL(type, quality).split(',')[1] ),
				len = binStr.length,
				arr = new Uint8Array(len);

			for (var i = 0; i < len; i++ ) {
				arr[i] = binStr.charCodeAt(i);
			}

			callback( new Blob( [arr], {type: type || 'image/png'} ) );
		}
	});
}