import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import Push from 'push.js';
import isMobile from 'ismobilejs';
import Toastr from 'toastr';
import { Notifications } from '../../../app/schemas/signalr/Hubs';
import NotificationActions from '../../../app/schemas/NotificationActions';
import SignalRContainer from '../../common/SignalRContainer';
import silentAudio from '../../../app/assets/audio/silent.mp3';
import infoIconImage from '../../../app/assets/img/info.png';
import { getImage } from '../../../app/api/httpRequests/methods/get';

class Notification extends Component {
	UNSAFE_componentWillMount() {
		window.onclick = () => {
			new Audio(silentAudio).play();
			window.onclick = null;
		};
		
		this.disconnectSignalRHub();
		this.connectToSignalRHub();

		if (!window.Notification)
			console.warn('This browser does not support desktop notifications');
		else if (window.Notification.permission !== 'granted')
			window.Notification.requestPermission();
	}

	connectToSignalRHub = () => {
		this.props.globalNotificationsHub && this.props.globalNotificationsHub.on(Notifications.client.DISPLAY_NOTIFICATION, this.showPopupNotification);
	}

	disconnectSignalRHub = () => {
		this.props.globalNotificationsHub && this.props.globalNotificationsHub.off(Notifications.client.DISPLAY_NOTIFICATION);
	}
	
	showPopupNotification = notification => {
		const { 
			toastr, browser, endpoint,
			title, msg, tOutMilli, audioUrl 
		} = notification;

		const { url, action, winName } = endpoint;

		let audio = null;
		if (audioUrl)
			audio = new Audio(audioUrl);

		if (toastr || isMobile.any || !window.Notification || window.Notification.permission !== 'granted') {
			const type = toastr ? toastr.type : 'info';
			Toastr[type](msg, title, { timeOut: tOutMilli, onclick: this.handleClick.bind(this, url, action, winName) });

			if (audio) 
				audio.play().catch(() => {});

		} else if (browser) {
			const { imgUrl, authReq } = browser;
			const self = this;
			const pushData = {
				body: msg,
				timeout: tOutMilli,
				onClick: function() {
					self.handleClick(url, action, winName);
					this.close();
				}
			};

			if (imgUrl) {
				if (!authReq)
					return this.pushNotification(title, pushData, audio, imgUrl);

				let image = null;
				getImage(imgUrl)
					.then(image => {
						this.pushNotification(title, pushData, audio, image);
					})
					.catch(error => {
						console.warn(error);
						this.pushNotification(title, pushData, audio, image);	
					})

			} else
				this.pushNotification(title, pushData, audio);
		}
	}

	pushNotification(title, pushData, audio, notificationImage = infoIconImage) {
		pushData.icon = notificationImage;
		Push.create(title, pushData);
		if(audio) 
			audio.play().catch(error => console.warn(error));
	}

	handleClick = (url, action, winName) => {
		switch (action) {
		case NotificationActions.GO_TO: 
			return browserHistory.push(url);
		case NotificationActions.NEW_WINDOW: {
			const { protocol, host } = window.location;
			const fullUrl = protocol + '//' + host + url;
			return window.open(fullUrl, winName, null, true);
		}
		default:
			return;
		}
	}

	render() {
		return (
			<SignalRContainer 
				showConnectingLoader={false}
			/>
		);
	}
}

const mapStateToProps = state => ({
	globalNotificationsHub: state.signalr.hubs.globalNotificationsHub
});

const NotificationComponent = connect(mapStateToProps)(Notification);
export { NotificationComponent as Notification };