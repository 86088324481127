import { getRosterUpdateReasonLabel } from "../../components/features/roster/StaffRoster/staffRosterHelper";
import { ITaskType } from "./Task";
import { IWorkerForTaskOrRoster } from "./Worker";
import moment from 'moment-timezone';

export interface IStaffRosterListItem {
	id: number;
	workerId: string;
	startTimeLocal: string;
	budgetedTime: number;
	startingDepotId: number;
	startingDepotName: string;
	mainTaskTypeId: number;
	mainTaskTypeName: string;
	runTypeId?: number;
	runTypeName?: string;
	isCancelled: boolean;
	isOvertime: boolean;
	Worker: IWorkerForTaskOrRoster;
}

export interface IStaffRosterDetails {
  id: number;
  restrictClockIn: boolean;
	restrictClockOut: boolean;
	workerId: string;
	startTimeLocal: string;
	budgetedTime: number;
	startingDepotId: number;
	startingDepotName: string;
	mainTaskTypeId: number;
	mainTaskTypeName: string;
	runTypeId?: number;
	runTypeName?: string;
	isCancelled: boolean;
	isOvertime: boolean;
	Worker: IWorkerForTaskOrRoster;
}

export class StaffRosterForm {
	id?: number;
	date: string;
	startTime: string;
	startTimeLocal: string;
  restrictClockIn: boolean;
	restrictClockOut: boolean;
	workerId: string;
	budgetedTime: string;
	startingDepotId: string;
	startingDepotName?: string;
	mainTaskTypeId: string;
	mainTaskTypeName?: string;
	runTypeId?: string;
	runTypeName?: string;
	isCancelled: boolean;
	isOvertime: boolean;
	Worker?: IWorkerForTaskOrRoster;
	TaskType?: ITaskType;

	constructor(staffRoster?: IStaffRosterDetails) {
		this.id = staffRoster?.id;
		this.restrictClockIn = staffRoster?.restrictClockIn || false;
		this.restrictClockOut = staffRoster?.restrictClockOut || false;
		this.workerId = staffRoster?.workerId || '';
		this.date = staffRoster ? moment(staffRoster?.startTimeLocal).format('YYYY-MM-DD') : '';
		this.startTime = staffRoster ? moment(staffRoster.startTimeLocal).format('HH:mm') : '';
		this.startTimeLocal = staffRoster?.startTimeLocal || '';
		this.budgetedTime = staffRoster ? staffRoster.budgetedTime.toString() : '';
		this.startingDepotId = staffRoster?.startingDepotId.toString() || '';
		this.startingDepotName = staffRoster?.startingDepotName
		this.mainTaskTypeId = staffRoster?.mainTaskTypeId.toString() || '';
		this.mainTaskTypeName = staffRoster?.mainTaskTypeName;
		this.runTypeId = staffRoster?.runTypeId?.toString() || '';
		this.runTypeName = staffRoster?.runTypeName;
		this.isCancelled = staffRoster?.isCancelled || false;
		this.isOvertime = staffRoster?.isOvertime || false;
		this.Worker = staffRoster?.Worker;
	}
}

export class StaffRosterListMainFilter {
	filterByEmployee = false;
	workerId?: string = '';
  year?: number = moment().year();
  week?: number = moment().isoWeek();
  startDate: string = moment().format('YYYY-MM-DD');
	finishDate: string = moment().format('YYYY-MM-DD');
	startingDepotId?: string;
	startingDepotName?: string;
}

export class StaffRosterListFilter {
  hoursRange = '';
  workerId = '';
  workerRoleId = '';
	workingStatus = '';
	[key: string]: any; // eslint-disable-line
}

export class StaffRosterUpdateReasonForm {
	updateReasonCategoryId?: string;
	updateReasonLabel?: string;
	updateReasonId: string;
	updateDescription: string;
	oldValue?: string;
	newValue?: string;

	constructor(updateReasonCategoryId: number, oldValue?: string, newValue?: string) {
		this.updateReasonCategoryId = updateReasonCategoryId.toString();
		this.updateReasonLabel = getRosterUpdateReasonLabel(updateReasonCategoryId);
		this.updateReasonId = '';
		this.updateDescription = '';
		this.oldValue = oldValue;
		this.newValue = newValue;
	}
}

export interface IStaffRosterForTasks {
	id: number;
	workerId: string;
	isCancelled: boolean;
	startTimeLocal: string;
	budgetedTime: number;
	startingDepotId: number;
	startingDepotName: string;
	mainTaskTypeId: number;
	mainTaskTypeName: string;
}


/**
 * HTTP REQUESTS
 */

export class StaffRosterPOST {
	workerId: string;
	startTimeLocal: string;
	budgetedTime: number;
	startingDepotId: number;
	mainTaskTypeId: number;
	isCancelled: boolean;
	isOvertime: boolean;
	runTypeId?: number;
	dontAddTask?: boolean;
	
	constructor(staffRoster: StaffRosterForm, dontAddTask?: boolean) {
		this.workerId = staffRoster.workerId;
		this.startTimeLocal = staffRoster.date + ' ' + staffRoster.startTime;
		this.budgetedTime = parseFloat(staffRoster.budgetedTime);
		this.startingDepotId = parseInt(staffRoster.startingDepotId);
		this.mainTaskTypeId = parseInt(staffRoster.mainTaskTypeId);
		this.runTypeId = staffRoster.runTypeId ? parseInt(staffRoster.runTypeId) : undefined;
		this.isCancelled = staffRoster.isCancelled;
		this.isOvertime = staffRoster.isOvertime;
		this.dontAddTask = dontAddTask;
	}
}

export class StaffRosterPUT {
	workerId: string;
	startTimeLocal: string;
	budgetedTime: number;
	startingDepotId: number;
	mainTaskTypeId: number;
	isCancelled: boolean;
	isOvertime: boolean;
	runTypeId?: number;
	UpdateReasons: IStaffRosterUpdateReasonPUT[];
	
	constructor(staffRoster: StaffRosterForm, UpdateReasons?: StaffRosterUpdateReasonForm[]) {
		this.workerId = staffRoster.workerId;
		this.startTimeLocal = staffRoster.date + ' ' + staffRoster.startTime;
		this.budgetedTime = parseFloat(staffRoster.budgetedTime);
		this.startingDepotId = parseInt(staffRoster.startingDepotId);
		this.mainTaskTypeId = parseInt(staffRoster.mainTaskTypeId);
		this.isCancelled = staffRoster.isCancelled;
		this.isOvertime = staffRoster.isOvertime;
		this.runTypeId = staffRoster.runTypeId ? parseInt(staffRoster.runTypeId) : undefined;
		this.UpdateReasons = UpdateReasons?.map(p => ({
			updateReasonId: parseInt(p.updateReasonId),
			updateDescription: p.updateDescription
		})) || [];
	}
}

export class StaffRosterCANCEL {
	updateReasonId: number;
	updateDescription: string;

	constructor(updateReasonId: string, updateDescription: string) {
		this.updateReasonId = parseInt(updateReasonId);
    this.updateDescription = updateDescription;
	}
}

export interface IStaffRosterUpdateReasonPUT {
	updateReasonId: number;
	updateDescription: string;
}

export class RosterUpdateReasonForm {
	updateReasonCategoryId: string;
	updateReasonLabel?: string;
	updateReasonId: string;
	updateDescription: string;
	oldValue?: string;
	newValue?: string;

	constructor(updateReasonCategoryId: number, oldValue?: string, newValue?: string) {
		this.updateReasonCategoryId = updateReasonCategoryId.toString();
		this.updateReasonLabel = getRosterUpdateReasonLabel(updateReasonCategoryId);
		this.updateReasonId = '';
		this.updateDescription = '';
		this.oldValue = oldValue;
		this.newValue = newValue;
	}
}

export const RosterUpdateReasonCategory = Object.freeze({
	START_TIME: 1,
	BUDGETED_TIME: 2,
	MAIN_TASK: 3,
	STARTING_PLACE: 4,
	CANCELLED: 5
})