import groups from '../app/schemas/PermissionGroups';

// Pre Starts
import PreStartListContainer from '../components/features/compliance/preStartCheck/mainList/PreStartListContainer';
// import PreStartContainer from '../components/features/compliance/preStartCheck/PreStartContainer';
import PreStartV2Container from '../components/features/compliance/preStartCheck/PreStartV2Container';
import PreStartCheckout from '../components/features/compliance/preStartCheck/checkout/CheckoutContainer';
import PreStartViewContainer from '../components/features/compliance/preStartCheck/PreStartViewContainer';

// Fault Reports
import FaultReportContainer from '../components/features/compliance/faultReport/FaultReportContainer';
import FaultReportListContainer from '../components/features/compliance/faultReport/mainList/FaultReportListContainer';
import FaultReportAddNewContainer from '../components/features/compliance/faultReport/FaultReportAddNewContainer';

// Non Conformance
import NonConformanceContainer from '../components/features/compliance/nonConformance/NonConformanceContainer';
import PreStartContainer from '../components/features/compliance/preStartCheck/PreStartContainer';
// import WorkerPermission from '../app/enums/WorkerPermission';

export default {
	path: 'compliance',
	menuItem: { title: 'Compliance', icon: 'check-square' },
	childRoutes: [
		{ 
			path: 'nonConformance',
			menuItem: { title: 'Non Conformance', icon: 'thumbs-down' },
			indexRoute: { component: NonConformanceContainer },
			authorize: [groups.ADMIN_STAFF_FULL, groups.ADMIN_STAFF_SIMPLE, groups.SUPERVISORS, groups.DEPOT_ADMIN],
		},
		{ 
			path: 'preStart',
			menuItem: { title: 'Pre Start', icon: 'play-circle' },
			childRoutes: [
				{ 
					path: 'submissions', 
					menuItem: { title: 'Submissions', icon: 'send' },
					component: PreStartListContainer,
					authorize: [
						groups.ADMIN_STAFF_FULL, groups.ADMIN_STAFF_SIMPLE, 
						groups.WASHBAY_ADMIN, groups.WASHBAY_MECHANICS, groups.DEPOT_ADMIN,
						groups.SUPERVISORS, groups.KIOSK
					]
				},
				{ 
					path: 'new', 
					menuItem: { title: 'Do a Pre-Start', icon: 'check-square-o' }, 
					component: PreStartV2Container,
					restrict: [groups.KIOSK, groups.TV]
				},
				{ 
					path: 'checkList', 
					component: PreStartContainer,
					restrict: [groups.KIOSK, groups.TV]
				},
				{ 
					path: 'checkOut', 
					menuItem: { title: 'Check Out', icon: 'gavel' }, 
					component: PreStartCheckout, 
					authorize: [
						groups.ADMIN_STAFF_FULL, groups.WASHBAY_ADMIN, 
						groups.DEPOT_ADMIN, groups.SUPERVISORS, 
						groups.KIOSK, groups.TV 
					]
				},
				{ 
					path: ':preStartId(/:order)', 
					component: PreStartViewContainer, 
					authorize: [
						groups.ADMIN_STAFF_FULL, groups.ADMIN_STAFF_SIMPLE, 
						groups.WASHBAY_ADMIN, groups.WASHBAY_MECHANICS, groups.DEPOT_ADMIN,
						groups.SUPERVISORS, groups.KIOSK
					]
				},
			]
		},
		{ 
			path: 'faultReports',
			menuItem: { title: 'Fault Reports', icon: 'wrench' },
			restrict: [groups.TV],
			childRoutes: [
				{
					path: 'list', 
					menuItem: { title: 'List', icon: 'list' },
					component: FaultReportListContainer,
					authorize: [
						groups.ADMIN_STAFF_FULL, groups.ADMIN_STAFF_SIMPLE, 
						groups.WASHBAY_ADMIN, groups.WASHBAY_MECHANICS, groups.DEPOT_ADMIN,
						groups.SUPERVISORS, groups.KIOSK
					]
				},
				{ 
					path: 'new', 
					menuItem: { title: 'Add New', icon: 'plus' },
					component: FaultReportAddNewContainer,
					restrict: [groups.KIOSK, groups.TV]
				},
				{ 
					path: ':faultReportId', 
					component: FaultReportContainer,
					authorize: [
						groups.ADMIN_STAFF_FULL, groups.ADMIN_STAFF_SIMPLE, 
						groups.WASHBAY_ADMIN, groups.WASHBAY_MECHANICS, groups.DEPOT_ADMIN,
						groups.SUPERVISORS, groups.KIOSK
					]
				},
			]
		},
	]
};