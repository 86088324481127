import React from 'react';
import PropTypes from 'prop-types';

const MainContent = ({ id, title, subtitle, titleButton, printable, className, children }) => (
	<div id={id} className={className}>
		<section className="content-header">
			<h1>
				{title}
				<small>{subtitle}</small>
			</h1>
			{
				printable && (
					<ol className="breadcrumb">
						{ titleButton && <li>{titleButton}</li> }
					</ol>
				)
			}
		</section>
		<section className="content">
			{ children }
		</section>
		
	</div>
);

MainContent.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	printable: PropTypes.bool,
	titleButton: PropTypes.any,
	className: PropTypes.string,
	children: PropTypes.any
};

MainContent.defaultProps = {
	printable: true
};

export { MainContent };