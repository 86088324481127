/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import cloneObject from 'lodash/cloneDeep';
import { Polygon } from 'react-google-maps';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import Icon from 'react-fontawesome';
import { Row, Col, FormGroup, FormControl, Button, Table, InputGroup } from 'react-bootstrap';
import confirm from '../../../../app/helpers/confirm';

import GeofenceApi from '../../../../app/api/GeofenceApi';

import { geofencePolygonOptions, placeMarkerOptions } from './options';
import { MainContent } from '../../../common/layout';
import { ContentBox, Loader, ErrorBox, Callout } from '../../../common/uiElements';
import { Checkbox } from '../../../common/inputs';
import { PlaceCategoriesList } from '../../../common/lists';

import { GoogleMapContainer } from '../../../common/googleMaps';
import PlaceModal from './PlaceModal';
import componentRequestHandler from '../../../../app/api/helpers/componentRequestHandler';

export default class GeofenceContainer extends Component {
	constructor(props) {
		super(props);
		this.map = null;
		this.selectedPolygon = null;

		this.polygons = [];
		this.placeMarkers = [];
		this.unlinkedPlacesMarkers = [];
		
		this.state = {
			drawingMode: null,
			geofences: [],
			unlinkedPlaces: [],
			hoveredPolygonId: null,
			selectedGeofence: null,
			selectedPlace: null,
			selectedPlaceIndex: null,
			hasBeenEdited: false,
			placeCategoryIdFilter: '',
			showUnmodifiedOnly: false,
			isSavingGeofence: false,
			isAddingNewPlace: false,
			isLoadingPlaces: false,
			isLoadingGeofences: false,
			isLoadingUnlinkedPlaces: false,
			errorLoadingGeofences: null,
			errorLoadingPlaces: null,
			errorLoadingUnlinkedPlaces: null,
			errorSavingGeofence: null,
			errorSavingUnlinkedPlaces: null
		};
	}

	componentDidMount() {
		const interval = window.setInterval(() => {
			if (this.map) {
				const input = document.getElementById('searchInputControl');
				this.autocomplete = new window.google.maps.places.Autocomplete(input);
				this.autocomplete.setComponentRestrictions({ country: 'au' });
				this.autocomplete.addListener('place_changed', this.handleSearchBoxPlacesChanged);

				window.clearInterval(interval);
				this.fetchGeofences();
				//this.fetchUnlinkedPlaces();
			}
		}, 200);
	}

	/**
	 * API
	 */
	fetchGeofences = () => {	
		const bounds =  this.map.getBounds();
		const interval = window.setInterval(() => {
			if (bounds) {
				window.clearInterval(interval);
				const { north, south, east, west } = bounds.toJSON();
				const boundaries = [
					{ lat: north, lng: west },
					{ lat: north, lng: east },
					{ lat: south, lng: east },
					{ lat: south, lng: west },
					{ lat: north, lng: west }
				];
				
				const promise = () => GeofenceApi.getGeofences(boundaries);
				componentRequestHandler(this, promise, 'geofences', {
					loadingAttrName: 'isLoadingGeofences',
					errorAttrName: 'errorLoadingGeofences'
				});
			}

		}, 200);
	};

	fetchUnlinkedPlaces = () => {
		const promise = () => GeofenceApi.getUnlinkedPlaces();
		componentRequestHandler(this, promise, 'unlinkedPlaces', {
			loadingAttrName: 'isLoadingUnlinkedPlaces',
			errorAttrName: 'errorLoadingUnlinkedPlaces'
		});
	}

	updateGeofence = () => {
		this.setState({ 
			isSavingGeofence: true, 
			errorSavingGeofence: null
		});

		const { selectedGeofence } = this.state;
		const polygon = this.getPolygonArray(this.selectedPolygon);
		
		let dataToSend = cloneObject(selectedGeofence);
		dataToSend.polygon = polygon;

		const attachedFiles = [];
		const { Places } = dataToSend;
		if (Places) {
			for (let p = 0; p < Places.length; p++) {
				const place = Places[p];
				const { AccessPoints } = place;
				
				if (AccessPoints) {
					for (let d = 0; d < AccessPoints.length; d++) {
						const accessPoint = AccessPoints[d];
						
						//Adding file
						if (accessPoint.mudMapFile) {
							const file = accessPoint.mudMapFile;
							accessPoint.dtoFileIndex = attachedFiles.length;
							attachedFiles.push(file);
							delete accessPoint.mudMapFile;
						}	
					}
				}
			}
		}

		dataToSend = {
			...dataToSend,
			attachedFiles
		};

		let promise = GeofenceApi.saveNewGeofence.bind(this, dataToSend);
		if (selectedGeofence.id) 
			promise = GeofenceApi.updateGeofence.bind(this, selectedGeofence.id, dataToSend);

		return promise()
			.then(() => {
				this.handleCloseEdit(true);
				selectedGeofence.Places.forEach(p => p.hasBeenEdited = false);
			})
			.catch(error => {
				this.setState({ errorSavingGeofence: error });
				console.error(error);
			})
			.then(() => {
				this.setState({ isSavingGeofence: false });
			});
	}

	deleteGeofence = geofenceId => {
		confirm('Do you really want do delete this Geofence? Everything inside it will be deleted too.', 
			() => {
				this.setState({ isLoadingGeofences: true, errorLoadingGeofences: null });
				return GeofenceApi.deleteGeofence(geofenceId)
					.then(() => {
						this.fetchGeofences();
					})
					.catch(error => {
						console.error(error);
						this.setState({ errorLoadingGeofences: error });
					});
			});
	}

	updateUnlinkedPlace = placeData => {
		this.setState({ isSavingUnlinkedPlace: true });
		return GeofenceApi.updatePlace(placeData.id, placeData)
			.then(() => {
				this.fetchUnlinkedPlaces();
			})
			.catch(error => {
				console.error(error);
				this.setState({ errorSavingUnlinkedPlaces: error });
			})
			.then(() => {
				this.setState({ isSavingUnlinkedPlace: false });
			});
	}

	getPolygonArray = polygon => {
		if (!polygon)
			return;
			
		const path = polygon.getPath();
		const array = path.getArray();

		return array.map(({lat, lng}) => ({
			lat: lat(),
			lng: lng()
		}));
	}

	getPolygonBounds = polygon => {
		if (!polygon)
			return;

		var paths = polygon.getPaths();
		var bounds = new window.google.maps.LatLngBounds();
		paths.forEach(path => {
			var array = path.getArray();
			for(var i = 0; i < array.length; i++)
				bounds.extend(array[i]);
		});

		return bounds;
	}

	checkPlacesInsideGeofence = () => {
		const { selectedGeofence } = this.state;
		const polygon = new window.google.maps.Polygon({
			paths: this.selectedPolygon.getPaths()
		});

		const isInvalid = selectedGeofence.Places.find(({ lat, lng }) => {
			const isWithinGeofence = window.google.maps.geometry.poly.containsLocation(new window.google.maps.LatLng(lat, lng), polygon);
			return !isWithinGeofence;
		});		
	
		return isInvalid;
	}

	checkAccessPointsInsideGeofence = () => {
		const { selectedGeofence } = this.state;
		const polygon = new window.google.maps.Polygon({
			paths: this.selectedPolygon.getPaths()
		});

		let isInvalid = false;

		selectedGeofence.Places.forEach(place => {
			
			const placeName = place.name;
			isInvalid = place.AccessPoints && place.AccessPoints.find(({ name, accessPointLat, accessPointLng }) => {
				const isWithinGeofence = window.google.maps.geometry.poly.containsLocation(new window.google.maps.LatLng(accessPointLat, accessPointLng), polygon);

				if (!isWithinGeofence)
					alert(`The access point '${name}' in the place '${placeName}' must be within the Geofence.`);

				return !isWithinGeofence;
			});		
		});	
	
		return isInvalid;
	}

	/**
	 * HANDLERS
	 */
	handleSearchBoxPlacesChanged = () => {
		const place = this.autocomplete.getPlace();
		const bounds = new window.google.maps.LatLngBounds();
		
		if (!place || !place.geometry)
			return;

		if (place.geometry.viewport)
			bounds.union(place.geometry.viewport);
		else
			bounds.extend(place.geometry.location);

		this.map.fitBounds(bounds);
	}

	//#region Geofences
	handleAddNewGeofence = () => {
		this.setState({
			hasBeenEdited: true,
			placeCategoryIdFilter: '',
			storeNumberFilter: '',
			selectedGeofence: {
				name: '',
				isActive: true,
				polygon: [],
				Places: []
			}
		});
	}

	handleOverlayComplete = event => {
		const { selectedGeofence } = this.state;
		const { overlay } = event;

		const polygon = this.getPolygonArray(event.overlay);

		this.setState({ 
			drawingMode: 'polygon' 
		});
		this.setState({
			drawingMode: null,
			hasBeenEdited: true,
			selectedGeofence: {
				...selectedGeofence,
				polygon
			}
		});		

		if (selectedGeofence.id)
			overlay && overlay.setMap && overlay.setMap(null);
		else {
			this.selectedPolygon && this.selectedPolygon.setMap && this.selectedPolygon.setMap(null);
			this.selectedPolygon = overlay;
		}
	}

	handleClickGeofence = (geofence, fitToPolygon) => {	
		if (this.state.selectedGeofence) {
			if (this.state.selectedGeofence.id === geofence.id)
				return;
			
			if (this.handleCloseEdit(false, geofence.id) === false)
				return;
		}

		const selectedGeofence = geofence;
		this.selectedPolygon = this.polygons[geofence.id];

		window.setTimeout(() => {
			if (fitToPolygon) {
				const bounds = this.getPolygonBounds(this.selectedPolygon);
				this.map.fitBounds(bounds, window.innerWidth / 6);
			}
			
			this.setState({ 
				selectedGeofence,
				hasBeenEdited: false,
				hoveredPolygonId: null,
			}, this.setEditVertexHandler);
		}, 500);
	}

	handleBoundsChanged = () => {
		if (!this.selectedPolygon && !this.state.selectedGeofence) {
			if (this.map.getZoom() < 10)
				return this.setState({ geofences: [] });

			this.fetchGeofences();
		}
	}

	setEditVertexHandler = () => {
		if (!this.selectedPolygon)
			return;

		const polygon = this.selectedPolygon.getPath();
		this.editHandler = polygon.addListener('set_at', this.handleEditVertex);
	}

	handleEditVertex = () => {
		if (!this.state.hasBeenEdited)
			this.setState({
				hasBeenEdited: true
			});
	}

	handleRightClickVertex = e => {
		if (e.vertex == undefined)
			return;
		
		const paths = this.selectedPolygon.getPath();
		if (paths.length <= 3)
			return alert('The Geofence must have at least 3 nodes');

		this.setState({ hasBeenEdited: true });
		paths.removeAt(e.vertex);
	}

	handleSaveGeofence = () => {
		const { selectedGeofence } = this.state;

		if (!selectedGeofence.name)
			return alert('The Geofence name is required');
		else if (selectedGeofence.polygon.length < 3)
			return alert('The Geofence must have at least 3 nodes');
		else if (this.checkAccessPointsInsideGeofence())
			return;
		else if (this.checkPlacesInsideGeofence())
			return confirm('The places outside the geofence will be unlinked. Do you want to continue?', this.updateGeofence);
		
		this.updateGeofence();
	}

	handleCloseEdit = (isSaving, isSelectingNewGeofence) => {
		const confirmMessage = 'Do you really want to cancel? All the data of this geofence will not be saved.';
		if (!isSaving && this.state.hasBeenEdited && !window.confirm(confirmMessage))
			return false;
		
		if (isSelectingNewGeofence)
			return;

		this.fetchGeofences();
		//this.fetchUnlinkedPlaces();
		this.editHandler && this.editHandler.remove();
		this.selectedPolygon && this.selectedPolygon.setMap && this.selectedPolygon.setMap(null);

		this.selectedPolygon = null;
		this.setState({ 
			selectedPlace: null,
			selectedGeofence: null,
			hasBeenEdited: false,
			errorSavingGeofence: null
		});
	}

	handleClearGeofence = () => {
		const { selectedGeofence } = this.state;
		selectedGeofence.polygon = [];
		this.setState({ 
			selectedGeofence,
			hasBeenEdited: true
		});

		if (!selectedGeofence.id)
			this.selectedPolygon && this.selectedPolygon.setMap && this.selectedPolygon.setMap(null);
	}
	//#endregion

	//#region Places
	handleUnlinkedPlacePositionChanged = id => {
		const { unlinkedPlaces } = this.state;
		let place = unlinkedPlaces.find(p => p.id === id);
		if (!place)
			return;

		const position = this.unlinkedPlacesMarkers[id].getPosition();
		place.lat = position.lat();
		place.lng = position.lng();

		this.updateUnlinkedPlace(place);
	}

	handleTogglePlaceStatus = index => {
		const { selectedGeofence } = this.state;
		const place = selectedGeofence.Places[index];
		if (!place)
			return;

		if (place.id)
			place.isActive = !place.isActive;
		else
			selectedGeofence.Places.splice(index, 1);

		this.setState({ selectedGeofence });
	}

	handlePlacePositionChanged = index => {
		const { selectedGeofence } = this.state;
		const place = selectedGeofence.Places[index];
		if (!place)
			return;

		const position = this.placeMarkers[index].getPosition();
		place.lat = position.lat();
		place.lng = position.lng();
	
		this.setState({ 
			selectedGeofence,
			hasBeenEdited: true
		});
	}

	handleSavePlace = savedPlace => {
		const { selectedGeofence, selectedPlaceIndex } = this.state;
		if (typeof selectedPlaceIndex === 'number')
			selectedGeofence.Places[selectedPlaceIndex] = savedPlace;
		else
			selectedGeofence.Places.push(savedPlace);

		this.setState({
			selectedGeofence,
			hasBeenEdited: true,
			isAddingNewPlace: false,
			selectedPlace: null,
			selectedPlaceIndex: null
		});
	}

	handleClosePlaceModal = () => {
		this.setState({ 
			selectedPlace: null, 
			isAddingNewPlace: false,
			selectedPlaceIndex: null
		});
	}

	handleSearchStoreNumber = () => {
		const { storeNumberFilter } = this.state;
		if (!storeNumberFilter)
			return;

		GeofenceApi.getGeofencesByStoreNumber(storeNumberFilter);
		const promise = () => GeofenceApi.getGeofencesByStoreNumber(storeNumberFilter);
		componentRequestHandler(this, promise, 'geofences', {
			loadingAttrName: 'isLoadingGeofences',
			errorAttrName: 'errorLoadingGeofences'
		});
	}
	//#endregion

	/**
	 * RENDERS
	 */
	renderGeofences = () => {
		const { 
			selectedGeofence, hoveredPolygonId, showUnmodifiedOnly,
			placeCategoryIdFilter, errorLoadingGeofences
		} = this.state;

		if (errorLoadingGeofences)
			return;

		return this.state.geofences.map(geofence => {
			const { id, polygon, updated, Places } = geofence;
			const isSelected = selectedGeofence && id === selectedGeofence.id;
			const isHovered = id === hoveredPolygonId;
			let isPlaceFiltered = false;
			
			if (placeCategoryIdFilter)
				isPlaceFiltered = Places && Places.find(p => p.placeCategoryId == placeCategoryIdFilter);
			
			const isGeofenceNotFiltered = ((showUnmodifiedOnly && updated) || (placeCategoryIdFilter && !isPlaceFiltered)) && !isSelected && !isHovered;

			const options = {
				...geofencePolygonOptions,
				fillColor: isSelected || isHovered ? '#00804d' : updated ? '#4db8ff' : '#ffff4d',
				fillOpacity: isGeofenceNotFiltered ? 0.08 : undefined,
				strokeOpacity: isGeofenceNotFiltered ? 0.2 : undefined,
			};

			return (
				<Polygon 
					key={id} 
					ref={ref => this.polygons[id] = ref}
					editable={isSelected}
					draggable={isSelected}
					paths={isSelected ? this.state.selectedGeofence.polygon : polygon} 
					options={options}
					onClick={() => this.handleClickGeofence(geofence)}
					onRightClick={this.handleRightClickVertex}
					onMouseOver={() => this.setState({ hoveredPolygonId: id })}
					onMouseOut={() => this.setState({ hoveredPolygonId: null })}
					zIndex={1}
				/>
			);
		});
	}

	renderGeofencesList = () => {
		const { errorLoadingGeofences, placeCategoryIdFilter , hoveredPolygonId, showUnmodifiedOnly } = this.state;
		if (errorLoadingGeofences)
			return <ErrorBox error={errorLoadingGeofences} retryFunc={this.fetchGeofences} />;

		return (
			<Table condensed>
				<tbody>
					{
						this.state.geofences.map(geofence => {
							const { id, name, updated, Places } = geofence;
							
							let isPlaceFiltered = false;

							if (placeCategoryIdFilter) {
								isPlaceFiltered = Places && Places.find(p => p.placeCategoryId == placeCategoryIdFilter);

								if (!isPlaceFiltered)
									return null;
							}

							if (showUnmodifiedOnly && updated)
								return null;

							return (
								<tr 
									key={id} 
									onMouseOver={() => this.setState({ hoveredPolygonId: id })}
									onMouseOut={() => this.setState({ hoveredPolygonId: null })}
									className={`cursor-pointer ${hoveredPolygonId === id ? 'success' : updated ? 'info' : ''}`}
								>
									<td onClick={() => this.handleClickGeofence(geofence, true)}>
										<u>{ name }</u>
									</td>
									<td width={45}>
										<a 
											className="cursor-pointer text-danger"
											onClick={() => this.deleteGeofence(id)}
										>
											<Icon name="trash" size="2x" />
										</a>
									</td>
								</tr>		
							);
						})
					}
				</tbody>
			</Table>
		);
	}

	renderPlaceMarkers = () => {
		const { selectedGeofence, isLoadingPlaces, errorLoadingPlaces } = this.state;

		if (!selectedGeofence || isLoadingPlaces || errorLoadingPlaces)
			return;

		const { Places } = selectedGeofence;
		return Places.map((place, index) => {
			let { name, lat, lng, isActive } = place;

			lat = parseFloat(lat);
			lng = parseFloat(lng);
			
			return (
				<MarkerWithLabel 
					draggable
					key={index}
					icon={isActive ? undefined : 'https://cdn.micway.com.au/tms/img/map_marker_grey.png'}
					ref={ref => this.placeMarkers[index] = ref}
					labelAnchor={new window.google.maps.Point(100, -5)}
					labelStyle={{ 
						...placeMarkerOptions,
						color: isActive ? 'yellow' : 'white',
						opacity: isActive ? 1 : .9
					}}
					position={{ lat, lng }} 
					zIndex={2}
					onDragEnd={() => this.handlePlacePositionChanged(index)}
				>
					<label>
						{ name }<br />
						{ !isActive && '(Deleted)' }
					</label>
				</MarkerWithLabel>
			);
		});
	}

	renderPlacesList = () => {
		const { selectedGeofence } = this.state;
		const { Places } = selectedGeofence;

		if (!Places || Places.length === 0)
			return <p><i>No Place has been registered yet.</i></p>;

		return (
			<Table condensed>
				<tbody>
					{
						Places.map((place, index) => {
							const { id, name, isActive, hasBeenEdited } = place;
							return (
								<tr key={index} className={!isActive ? 'bg-danger' : !id ? 'bg-success' : hasBeenEdited ? 'bg-info' : ''}>
									<td>
										{ isActive ? name : <del>{name}</del> }&nbsp;
										{ !id ? <i>(New)</i> : hasBeenEdited ? <i>(Edited)</i> : '' }
									</td>
									{
										isActive && selectedGeofence.polygon && selectedGeofence.polygon.length > 0 && (
											<th width={45}>
												<a 
													className="cursor-pointer text-warning" 
													onClick={() => this.setState({ selectedPlace: place, selectedPlaceIndex: index })}
												>
													<Icon name="edit" size="2x" />
												</a>
											</th>
										)	
									}
									<th width={45}>
										<a 
											className={`cursor-pointer ${isActive ? 'text-danger' : 'text-warning'}`} 
											onClick={() => this.handleTogglePlaceStatus(index)}
										>
											<Icon name={ isActive ? 'trash' : 'undo' } size="2x" />
										</a>
									</th>
								</tr>		
							);
						})
					}
				</tbody>
			</Table>
		);
	}

	renderUnlinkedPlacesList = () => {
		const { unlinkedPlaces, errorLoadingUnlinkedPlaces } = this.state;

		if (errorLoadingUnlinkedPlaces)
			return <ErrorBox error={errorLoadingUnlinkedPlaces} retryFunc={this.fetchUnlinkedPlaces} />;

		return (
			<Table hover condensed>
				<tbody>
					{
						unlinkedPlaces.map(({id, name}) => (
							<tr 
								key={id} 
								className="cursor-pointer"
							>
								<td>
									<u>{ name }</u>
								</td>
							</tr>		
						))
					}
				</tbody>
			</Table>
		);
	}

	renderUnlinkedPlaceMarkers = () => {
		const { unlinkedPlaces, errorLoadingUnlinkedPlaces } = this.state;
		if (errorLoadingUnlinkedPlaces)
			return;

		return unlinkedPlaces.map(place => {
			let { id, name, lat, lng } = place;

			lat = parseFloat(lat);
			lng = parseFloat(lng);
			
			return (
				<MarkerWithLabel 
					draggable
					key={id}
					ref={ref => this.unlinkedPlacesMarkers[id] = ref}
					labelAnchor={new window.google.maps.Point(100, -5)}
					labelStyle={{ 
						fontSize: '14px', 
						width: '200px', 
						textAlign: 'center',
						color: 'red',
						opacity: 1,
						textShadow: '1px 1px #000'
					}}
					position={{ lat, lng }} 
					zIndex={2}
					onDragEnd={() => this.handleUnlinkedPlacePositionChanged(id)}
				>
					<label>
						{ name }<br />
						(Unlinked)
					</label>
				</MarkerWithLabel>
			);
		});
	}

	renderFiltersBox = () => {
		const disabled = this.selectedPolygon || this.state.selectedGeofence ? true : false;
		return (
			<ContentBox color="primary">
				<Row>
					<Col sm={4}>
						<label>Search</label>
						<FormControl 
							id="searchInputControl" 
							disabled={disabled}
							placeholder="Type an address or place name..."
						/>
					</Col>
					<Col sm={3}>
						<label>Category</label>
						<PlaceCategoriesList
							value={this.state.placeCategoryIdFilter || ''} 
							disabled={disabled}
							onChange={e => this.setState({ placeCategoryIdFilter: e.target.value })} 
						/>
					</Col>
					<Col sm={3}>
						<label>Store Number</label>
						<InputGroup>
							<FormControl 
								id="storeNumberFilter"
								disabled={disabled}
								onChange={e => this.setState({ storeNumberFilter: e.target.value })}
								onKeyPress={e => e.key === 'Enter' && !disabled && this.handleSearchStoreNumber()}
							/>
							<InputGroup.Button>
								<Button
									disabled={disabled}
									bsStyle="primary"
									onClick={this.handleSearchStoreNumber}
								>
									<Icon name="search" />
								</Button>
							</InputGroup.Button>
						</InputGroup>
						
					</Col>
					<Col sm={2}>
						<label>Unmodified only</label>
						<Checkbox 
							block
							disabled={disabled}
							checked={this.state.showUnmodifiedOnly}
							onChange={e => this.setState({ showUnmodifiedOnly: e.target.checked })} 
						/>
					</Col>
				</Row>
				{
					disabled && (
						<Callout color="info" margin={0} padding="8px 5px">
							Save or Cancel the current selection in order to use the filters
						</Callout>
					)
				}
			</ContentBox>
		);
	}

	render() {
		const s = this.state;

		return (
			<MainContent title="Geofences & Places">
				<Row>
					<Col md={8} lg={9}>
						{ this.renderFiltersBox() }
						<div className="clearfix"></div>
						<div style={{ border: '1px solid #0000004a', boxShadow: '3px 3px 3px #0000004a' }}>
							<GoogleMapContainer 		
								refs={ref => this.map = ref}
								height="70vh"
								defaultZoom={14}
								defaultCenter={new window.google.maps.LatLng(-33.8836337,151.2092983)}
								enableDrawing={s.selectedGeofence ? true : false}
								drawingMode={s.drawingMode}
								onOverlayComplete={this.handleOverlayComplete}
								onDragEnd={this.handleBoundsChanged}
								onZoomChanged={this.handleBoundsChanged}
							>
								{ this.renderGeofences() }
								{ this.renderPlaceMarkers() }
								{/* { this.renderUnlinkedPlaceMarkers() */}
							</GoogleMapContainer>
						</div>
					</Col>
					<Col md={4} lg={3}>
						{
							s.errorSavingGeofence && (
								<ErrorBox error={s.errorSavingGeofence} retryFunc={this.handleSaveGeofence} />
							)
						}
						{
							!s.isSavingGeofence && (
								<FormGroup>
									{
										!s.selectedGeofence ? (
											<Button
												block
												bsSize="lg"
												bsStyle="primary"
												onClick={this.handleAddNewGeofence}
											>
												Add a new Geofence
											</Button>
										) : (
											<FormGroup>
												<Button 
													bsStyle="success" 
													onClick={this.handleSaveGeofence}
												>
													Save
												</Button>
												&nbsp;
												<Button 
													bsStyle="warning"
													onClick={this.handleClearGeofence}
												>
													Clear Geofence
												</Button>
												&nbsp;
												<Button 
													bsStyle="danger"
													onClick={() => this.handleCloseEdit(false)}
												>
													Cancel
												</Button>
											</FormGroup>
										)
									}
								</FormGroup>
							)
						}
						{ 
							!s.isSavingGeofence && s.selectedGeofence !== null && (
								<FormGroup>
									<FormControl 
										bsSize="lg"
										placeholder="Geofence Name"
										value={s.selectedGeofence.name}
										onChange={e => this.setState({ selectedGeofence: { ...s.selectedGeofence, name: e.target.value} })}
									/>
								</FormGroup>
							)
						}
						<ContentBox 
							title={!s.selectedGeofence ? 'Geofences List' : 'Places'} 
							color="primary" 
							height={!s.selectedGeofence ? '70vh' : '64vh'}
						>
							{
								!s.selectedGeofence ? (
									s.isLoadingGeofences ? (
										<Loader text="Loading data..." />
									) : (
										this.renderGeofencesList()
									)
								) : (
									s.isSavingGeofence ? (
										<Loader text="Saving data..." />
									) : (
										<div>
											{ this.renderPlacesList() }
											{
												s.selectedGeofence.polygon && s.selectedGeofence.polygon.length > 0 && (
													<Button 
														bsSize="sm"
														bsStyle="primary"
														onClick={() => this.setState({ isAddingNewPlace: true })}
													>
														Add a new Place
													</Button>
												)
											}
										</div>
									)
								)
							}
						</ContentBox>
						{/* <ContentBox title="Unlinked Places" color="primary" height="23vh">
							{ 
								s.isLoadingUnlinkedPlaces ? (
									<Loader text="Loading data..."/>
								) : (
									this.renderUnlinkedPlacesList() 
								)
							}
						</ContentBox> */}
					</Col>
				</Row>
				{
					(s.selectedPlace || s.isAddingNewPlace) && (
						<PlaceModal 
							show
							placeData={s.selectedPlace}
							geofencePolygonArray={this.getPolygonArray(this.selectedPolygon)}
							geofencePolygonBounds={this.getPolygonBounds(this.selectedPolygon)}
							onSave={this.handleSavePlace}
							onClose={this.handleClosePlaceModal}
						/>
					)
				}
			</MainContent>
		);
	}
}