import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import FaultReportForm from './FaultReport';

const FaultReportModalDetails = props => (
	<Modal show={props.show} onHide={props.onClose}>
		<Modal.Header closeButton>
			<Modal.Title>Fault Report</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<FaultReportForm 
				faultReportData={props.data}
				isReadOnly={true}
				isEditable={props.isEditable} 
			/>
		</Modal.Body>
		<Modal.Footer>
			{ !props.isReadOnly && <Button onClick={props.onSave} bsStyle="success">Save</Button> }
			<Button onClick={props.onClose} bsStyle="default">Close</Button>
		</Modal.Footer>
	</Modal>
);

FaultReportModalDetails.propTypes = {
	/** The data of the Fault Report */
	data: PropTypes.object.isRequired,

	/** If true, shows the Modal */
	show: PropTypes.bool,

	/** If true, hide the save button  */
	isReadOnly: PropTypes.bool,

	/** If true, the hadQuickFix and comments fields can be added */
	isEditable: PropTypes.bool,

	/** Handle modal close event */
	onClose: PropTypes.func,

	/** Handle modal save event  */
	onSave: PropTypes.func
};

export default FaultReportModalDetails;