import React from 'react';
import PropTypes from 'prop-types';
import { DrawingManager } from 'react-google-maps/lib/components/drawing/DrawingManager';
import { GoogleMap, withGoogleMap } from 'react-google-maps';

const propTypes = {
	refs: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	defaultZoom: PropTypes.number,
	defaultCenter: PropTypes.object,
	drawingMode: PropTypes.string,
	enableDrawing: PropTypes.bool,
	onClick: PropTypes.func,
	onDragEnd: PropTypes.func,
	onZoomChanged: PropTypes.func,
	onOverlayComplete: PropTypes.func,
	children: PropTypes.any
};

const defaultProps = {
	defaultZoom: 11
};


const GoogleMapContainer = props => (
	<Map
		{...props}
		containerElement={ <div style={{ height: props.height || 400 }} /> }
		mapElement={ <div style={{ height: '100%' }} /> }
	/>
);

const Map = withGoogleMap(props =>
	<GoogleMap
		ref={props.refs}
		defaultZoom={props.defaultZoom}
		defaultCenter={props.defaultCenter || new window.google.maps.LatLng(-33.8259137,151.1305758)}
		defaultOptions={props.defaultOptions}
		onClick={props.onClick}
		onDragEnd={props.onDragEnd}
		onZoomChanged={props.onZoomChanged}
	>
		{ props.children }
		{
			props.enableDrawing && (
				<DrawingManager
					onOverlayComplete={props.onOverlayComplete}
					drawingMode={props.drawingMode}
					defaultOptions={{
						drawingControl: true,
						drawingControlOptions: {
							position: window.google.maps.ControlPosition.TOP_CENTER,
							drawingModes: ['polygon']
						},
						polygonOptions: {
							fillColor: '#00804d',
							fillOpacity: 0.2,
							strokeColor: '#000000',
							strokeOpacity: 0.9,
							strokeWeight: 2,
							clickable: true,
							editable: true,
							draggable: true,
							zIndex: 1,
						},
					}}
				/>
			)
		}
	</GoogleMap>
);

GoogleMapContainer.propTypes = propTypes;
GoogleMapContainer.defaultProps = defaultProps;

export { GoogleMapContainer };