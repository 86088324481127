import React, { ChangeEvent, Fragment } from 'react';
import Icon from 'react-fontawesome';
import TimeField from 'react-simple-timefield';
import moment from 'moment';
import { YesNoRadio } from '../../../../common/inputs';
import { FormControl, Row, InputGroup, Col, Button, FormGroup } from 'react-bootstrap';
import { Form, FieldGroup, Callout } from '../../../../common/uiElements';
import { PlacesList, RunTypesList, PlacesListNew, TaskTypesList } from '../../../../common/lists';
import { ItemInterface, ReactSortable, Store } from "react-sortablejs";
import { uniq } from 'lodash';
import { IReactSelectReturn } from '../../../../../app/models/Application';
import { RunTemplateFormClass } from '../../../../../app/models/Run/RunTemplate';
import { WeekDays } from '../../../../common/inputs/WeekDays';
import weekDays from '../../../../../app/store/weekDays';
import { ITaskType } from '../../../../../app/models/Task';

interface IProps extends RunTemplateFormClass {
	formId: string;
	isReadOnly?: boolean;
	onChangeInput: (id: string, value: string | boolean) => void;
	onChangeTaskType: (e: ChangeEvent<FormControl & HTMLInputElement>, text?: string, TaskType?: ITaskType) => void;
	onChangeStartTime: (startTime: string) => void;
	onChangeRunCustomer: (event: ChangeEvent<FormControl & HTMLInputElement>, runCustomerIndex: number) => void;
	onClickAddNewRunCustomer: () => void;
	onClickRemoveRunCustomer: (runCustomerIndex: number) => void;
	onChangeRunCustomerOrder: (obj: IReactSelectReturn, runCustomerOrderIndex: number) => void;
	onClickAddNewRunCustomerOrder: () => void;
	onClickRemoveRunCustomerOrder: (runCustomerOrderIndex: number) => void;
	onChangeSequenceRunCustomerOrder: (RunCustomerOrders: ItemInterface[], sortable: any, store: Store) => void;
}

const validations = {
	dayOfWeek: 'required',
	startTime: 'required',
	startingDepotId: 'required',
	budgetedTime: 'required',
	area: 'required',
	runTypeId: 'required',
	runNumber: 'required',
	runCustomerOrders: 'required'
}

const messages = {
	runCustomerOrders: {
		required: 'You must include at least one Store or Destination'
	}
}

const RunTemplateForm: React.FC<IProps> = props => {
	const handleChangeInput = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
		props.onChangeInput && props.onChangeInput(e.target.id, e.target.value);
	}

	return (
		<Form id={props.formId} validations={validations} messages={messages}>
			<Row>
				<Col lg={6}>
					<h3 className="title">Details</h3>
					<Row>
						<FieldGroup label="Day of Week" sm={4}>
							{
								!props.isReadOnly ? (
									<WeekDays 
										id="dayOfWeek"
										value={props.dayOfWeek || ''}
										onChange={handleChangeInput}
									/>
								) : (
									<p>{ weekDays[(parseInt(props.dayOfWeek) - 1)] }</p>
								)
							}
						</FieldGroup>
						<FieldGroup label="Starting Depot" sm={8} required>
							{
								!props.isReadOnly ? (
									<PlacesList
										id="startingDepotId"
										type="rosterStartingDepot"
										value={props.startingDepotId || ''}
										onChange={handleChangeInput}
									/>
								) : (
									<p>{ props.startingDepotName }</p>
								)
							}
						</FieldGroup>
					</Row>
					<Row>
						<FieldGroup label="Task Type" xs={12} required>
							{
								!props.isReadOnly ? (
									<TaskTypesList
										id="taskTypeId"
										isDriversLicenceRequired
										isRunRequired
										value={props.taskTypeId || ''}
										onChange={props.onChangeTaskType}
									/>
								) : (
									<p>{ props.taskTypeName }</p>
								)
							}
						</FieldGroup>
					</Row>
					<Row>
						<FieldGroup label="Despatch Time" sm={4} required>
							{
								!props.isReadOnly ? (
									<TimeField
										width={4}
										value={props.startTime || '__:__'} 
										input={<FormControl id="startTime" />} 
										onChange={props.onChangeStartTime}
									/>
								) : (
									<p>{ moment(props.startTime, 'HH:mm:ss').format('HH:mm') }</p>
								)
							}
						</FieldGroup>
						<FieldGroup label="Budgeted Time" sm={4} required>
							{
								!props.isReadOnly ? (
									<InputGroup>
										<FormControl
											id="budgetedTime"
											type="number"
											max={20}
											min={0.5}
											value={props.budgetedTime || ''}
											onChange={handleChangeInput}
										/>
										<InputGroup.Addon>
											HOURS
										</InputGroup.Addon>
									</InputGroup>
								) : (
									props.budgetedTime ? <p>{ props.budgetedTime } h</p> : <p>-</p>
								)
							}
						</FieldGroup>
						<FieldGroup label="Est. Finish Time" sm={4}>
							<p>
							{ moment(props.startTime, 'HH:mm:ss').isValid() && props.budgetedTime ? moment(props.startTime, 'HH:mm:ss').add(props.budgetedTime, 'hours').format('HH:mm') : '-' }
							</p>
						</FieldGroup>
					</Row>
					<Row>
						<FieldGroup label="Destination / Area" sm={8} required>
							{/* Can't change area at this stage */}
							{
								!props.isReadOnly ? (
									<FormControl 
										id="area"
										value={props.area || ''}
										onChange={handleChangeInput}
									/>
								) : (
									<p>{ props.area || '-' }</p>
								)
							}
						</FieldGroup>
						<FieldGroup label="Is Pre-Loaded?" sm={4} required>
							{
								!props.isReadOnly ? (
									<YesNoRadio 
											id="isPreLoaded"
											value={props.isPreLoaded || false}
											onChange={handleChangeInput} 
										/>
								) : (
									<p>{ props.isPreLoaded ? 'YES' : 'NO' }</p>
								)
							}
						</FieldGroup>
					</Row>
					<Row>
						<FieldGroup label="Budgeted Km" sm={4} optional>
								{
									!props.isReadOnly ? (
										<InputGroup>
											<FormControl
												id="budgetedKm"
												value={props.budgetedKm || ''}
												onChange={handleChangeInput}
											/>
											<InputGroup.Addon>
												KM
											</InputGroup.Addon>
										</InputGroup>
									) : (
										<p>{ props.budgetedKm ? props.budgetedKm + ' KM' : '-' }</p>
									)
								}
							</FieldGroup>
					</Row>
				</Col>
				<Col lg={6}>
				<h3 className="title">Runs</h3>
				{
					!props.taskTypeId ? (
						<Callout color="info" text="Select the Task Type to proceed" />
					) : (
						<>
								{
									props.isReadOnly ? (
										<b style={{ fontSize: '25px' }}>
											{ props.runNumberCombined }
										</b>
									) : (
										props.RunCustomers?.map((runCustomer, index) => (
											<Row key={index}>
												<Col xs={5}>
													<FormGroup>
														<RunTypesList 
															id="runTypeId"
															firstOptionText="--- RUN TYPE ---"
															taskTypeId={props.taskTypeId}
															value={runCustomer.runTypeId}
															bsSize="small"
															className="required"
															onChange={(e: any) => props.onChangeRunCustomer && props.onChangeRunCustomer(e, index)}
														/>
													</FormGroup>
												</Col>
												<Col xs={5}>
													<FormGroup>
														<FormControl 
															id="runNumber"
															type="number"
															value={runCustomer.runNumber}
															placeholder="Run Number"
															className="required"
															bsSize="small"
															onChange={(e: any) => props.onChangeRunCustomer && props.onChangeRunCustomer(e, index)}
														/>
													</FormGroup>
												</Col>
												<Col xs={2}>
													{ 
														index > 0 && (
															<Button
																block
																bsStyle="danger"
																bsSize="small"
																onClick={() => props.onClickRemoveRunCustomer && props.onClickRemoveRunCustomer(index)}
															>
																<Icon name="times" />
															</Button>
														)
													}
												</Col>
											</Row>
										))
									)
								}
								{
									!props.isReadOnly && props.TaskType?.canHaveMultipleRuns && (
										<FormGroup>
											<Button 
													bsSize="small"
													bsStyle="success"
													onClick={props.onClickAddNewRunCustomer}
												>
													<Icon name="plus" /> Add Another Customer
											</Button>
										</FormGroup>
									)
								}
							<h3 className="title">Delivery Sequence</h3>
							{
								!props.RunCustomers.find(p => p.runTypeId) ? (
									<Callout color="info" text="Add at least one Run to be able to select the stores" />
								) : (
									<Fragment>
										<Row>
											<Col xs={2}>
												{
													props.RunCustomerOrders?.map((runCustomer, index) => (
														<FormGroup key={index} className="sortable-list-index">
															{ index + 1 }
														</FormGroup>
													))
												}
											</Col>
											<Col xs={10}>
												<ReactSortable className="sortable-list" list={props.RunCustomerOrders as any || []} setList={props.onChangeSequenceRunCustomerOrder}>
													{
														props.RunCustomerOrders?.map((runCustomerOrder, index) => (
															<FormGroup key={index}>
																{
																	props.isReadOnly ? (
																		<FormControl 
																			readOnly
																			value={`${runCustomerOrder.storeNumber && runCustomerOrder.storeNumber !== "0" ? `(${runCustomerOrder.storeNumber}) ` : ''}${runCustomerOrder.placeName}`}
																		/>
																	) : (
																		<InputGroup>
																			<InputGroup.Addon>
																				<Icon name="arrows" />
																			</InputGroup.Addon>
																			{
																				runCustomerOrder.runTemplateCustomerId ? (
																					<FormControl 
																						readOnly
																						value={runCustomerOrder.placeName}
																					/>
																				) : (
																					<PlacesListNew 
																						id="placeId"
																						type="deliveryStores"
																						runTypes={props.RunCustomers ? uniq(props.RunCustomers?.map(p => parseInt(p.runTypeId!))) : undefined}
																						placeholder="Select Store or Destination..."
																						value={runCustomerOrder.placeId}
																						onChange={obj => props.onChangeRunCustomerOrder && props.onChangeRunCustomerOrder(obj, index)}
																					/>
																				)
																			}
																			{
																				props.RunCustomerOrders.length > 1 && (
																					<InputGroup.Button>
																						<Button
																							bsStyle="danger"
																							onClick={() => props.onClickRemoveRunCustomerOrder && props.onClickRemoveRunCustomerOrder(index)}
																						>
																							<Icon name="times" />
																						</Button>
																					</InputGroup.Button>
																				)
																			}
																			</InputGroup>
																	)
																}
															</FormGroup>
														))
													}
												</ReactSortable>
											</Col>
										</Row>
									</Fragment>
								)
							}
							{
								!props.isReadOnly && (
									<Fragment>
										<FormGroup>
											<FormControl 
												id="runCustomerOrders" 
												type="hidden" 
												value={props.RunCustomerOrders?.find(p => !p.placeId) ? '' : 'OK'} 
											/>
										</FormGroup>
										<FormGroup>
											<Button 
													bsSize="small"
													bsStyle="success"
													onClick={props.onClickAddNewRunCustomerOrder}
												>
													<Icon name="plus" /> Add Another Store
											</Button>
										</FormGroup>
									</Fragment>
								)
							}
						</>
					)
				}
				</Col>
			</Row>
		</Form>
	)
}

export default RunTemplateForm;