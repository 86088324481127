import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';
import { RadioButtonList, VehicleSearch, YesNoRadio } from '../../../common/inputs';
import MassManagement from './firstPart/steps/MassManagement';
import { ContentBox, Form, Loader, DataStatusMessage, ErrorBox } from '../../../common/uiElements';
import PreStartApi from '../../../../app/api/PreStartApi';
import PreStartOverloadModal from './subComponents/PreStartOverloadModal';
import moment from 'moment';

export default class PreStartSecondRun extends Component {
	static propTypes = {
		preStartId: PropTypes.string.isRequired,
		mainVehFleetNumber: PropTypes.string.isRequired,
		PreStartRuns: PropTypes.array,
		depotId: PropTypes.number.isRequired,
		trailer1VehFleetNumber: PropTypes.string,
		trailer2VehFleetNumber: PropTypes.string,
		dollyVehFleetNumber: PropTypes.string,
		palletJackVehFleetNumber: PropTypes.string,
		onClickBack: PropTypes.func
	}

	validations = {
		palletJackVehFleetNumber: 'required',
		palletJackIsIn: 'required',
		isUsingTrolley: 'required',
		trolleyIsIn: 'required',
		isUsingPalletJack: 'required'
	}

	constructor(props) {
		super(props);
		this.state = {
			palletJackIsIn: '',
			trolleyIsIn: '',
			isUsingTrolley: '',
			palletJackVehFleetNumber: this.props.palletJackVehFleetNumber || '',
			PreStartRuns: props.PreStartRuns || [],
			weightCheck: null,
			isSavingRuns: false,
			errorSavingRuns: false,
			isSaved: false,
			isCheckingWeight: false,
			errorCheckingWeight: null,
			processStartedOn: moment().format('YYYY-MM-DD HH:mm:ss'),
			isUsingPalletJack: undefined
		};
	}

	checkWeight = (isNotTypingError = false) => {
		const { 
			preStartId, mainVehFleetNumber, trailer1VehFleetNumber, 
			trailer2VehFleetNumber, depotId, dollyVehFleetNumber
		} = this.props;
		const { palletJackVehFleetNumber, palletJackIsIn, trolleyIsIn, PreStartRuns } = this.state;
		
		this.setState({ isCheckingWeight: true, weightCheck: null, errorCheckingWeight: null });

		const massManagementData = {
			isNotTypingError,
			preStartId,
			PreStartRuns,
			depotId,
			fleet1: trailer1VehFleetNumber || mainVehFleetNumber,
			fleet2: trailer2VehFleetNumber,
			dollyVehFleetNumber,
			palletJackVehFleetNumber,
			trolleyIsIn,
			palletJackIsIn,
		};

		PreStartApi.checkWeight(massManagementData)
			.then(weightCheck => {
				if (this.unmounted) return;
				this.setState({ massManagementData, weightCheck, isCheckingWeight: false });

				if (weightCheck && weightCheck.isNotOverloaded)
					return this.saveRuns();
			})
			.catch(error => {
				console.error(error);
				this.setState({ isCheckingWeight: false, errorCheckingWeight: error });
			});
	}

	saveRuns = () => {
		this.setState({ isSavingRuns: true, errorSavingRuns: null });

		const { PreStartRuns } = this.state;
		PreStartRuns.forEach(p => p.processStartedOn = this.state.processStartedOn);
		PreStartApi.saveSecondRuns(this.props.preStartId, this.state.PreStartRuns)
			.then(() => {
				if (this.unmounted) return;

				this.setState({ isSaved: true });

			})
			.catch(error => {
				console.error(error);
				this.setState({ isSavingRuns: false, errorSavingRuns: error });
			});
	}

	handleChangeInput = (e, value) => {
		const s = this.state;
		const fieldId = e.target.id;

		value = e.target.type === 'checkbox' ? e.target.checked : (typeof value !== 'undefined' ? value : e.target.value);
		
		if (fieldId === 'palletJackVehFleetNumber' && value === '')
			s.palletJackIsIn = '';
		else if (fieldId === 'isUsingTrolley' && value === false)
			s.trolleyIsIn = '';

		this.setState({ [fieldId]: value });
	}

	handleChangeMassManagementData = ({ PreStartRuns }) => {
		this.setState({ PreStartRuns });
	}

	handleChangeUsingPalletJack = e => {
		const isUsingPalletJack = e.target.value === 'true';
		this.setState({ 
			isUsingPalletJack,
			palletJackVehFleetNumber: '',
			palletJackIsIn: ''
		});
	};

	renderFleetsIn = () => {
		const { 
			mainVehFleetNumber,
			trailer1VehFleetNumber,
			trailer2VehFleetNumber,
			dollyVehFleetNumber
		} = this.props;

		const fleets = [
			{ text: trailer1VehFleetNumber || mainVehFleetNumber, value: trailer1VehFleetNumber || mainVehFleetNumber }
		];

		if (dollyVehFleetNumber)
			fleets.push({ text: dollyVehFleetNumber, value: dollyVehFleetNumber });

		if (trailer2VehFleetNumber)
			fleets.push({ text: trailer2VehFleetNumber, value: trailer2VehFleetNumber });

		return fleets;
	}

	renderTrailersIn = () => {
		const { 
			mainVehFleetNumber,
			trailer1VehFleetNumber,
			trailer2VehFleetNumber
		} = this.props;

		const trailers = [
			{ text: trailer1VehFleetNumber || mainVehFleetNumber, value: trailer1VehFleetNumber || mainVehFleetNumber }
		];

		if (trailer2VehFleetNumber)
		trailers.push({ text: trailer2VehFleetNumber, value: trailer2VehFleetNumber });

		return trailers;
	}

	renderContent = () => {
		const { state, props } = this;

		if (state.isSavingRuns)
			return (
				<Fragment>
					<DataStatusMessage 
						successText="You can commence your trip. Drive safe! :)"
						status={state.isSaved ? 'success' : 'saving'} 
					/>
				</Fragment>
			);

		if (state.isCheckingWeight)
			return (
				<ContentBox color="primary">
					<Loader />
					<h4 className="text-center">Checking the weight. Please wait...</h4>
				</ContentBox>
			);
		
		if (state.errorCheckingWeight)
			return <ErrorBox error={state.errorCheckingWeight} retryFunc={this.checkWeight} />;

		if (state.errorSavingRuns)
			return <ErrorBox error={state.errorSavingRuns} retryFunc={this.saveRuns} />;

		return (
			<Form validations={this.validations} ref={ref => this.form = ref} onSubmit={this.checkWeight}>
				{
					state.weightCheck && !state.weightCheck.isNotOverloaded && (
						<PreStartOverloadModal
							massManagementData={state.massManagementData}
							overloadedList={state.weightCheck.overloaded}
							canBeTypingError={state.weightCheck.canBeTypingError}
							onClose={() => this.setState({ weightCheck: null })} 
							onClickIsNotTypingError={() => this.checkWeight(true)}
						/>
					)
				}
				<ContentBox>
					{
						<FormGroup>
							<label>Are you using a Pallet Jack?</label>
							<YesNoRadio 
								id="isUsingPalletJack"
								value={state.isUsingPalletJack}
								onChange={this.handleChangeUsingPalletJack}
							/>
						</FormGroup>
					}
					{
						state.isUsingPalletJack && (
							<FormGroup>
								<label>Pallet Jack for this run *</label>
								<VehicleSearch 
									onlyActive
									withoutSearch
									id="palletJackVehFleetNumber" 
									value={state.palletJackVehFleetNumber}
									vehTypeFilter={1}
									onSelectVehicle={(vehicle, event) => this.handleChangeInput(event) }
								/>
							</FormGroup>
						)
					}
					{
						state.isUsingPalletJack && props.mainVehFleetNumber && (
							<Row className="aligned-row">
								<Col xs={12} md={6}>
									<FormGroup>
										<label>Which trailer is the Pallet Jack {state.palletJackVehFleetNumber} in? *</label>
										<RadioButtonList 
											id="palletJackIsIn" 
											value={state.palletJackIsIn}
											items={this.renderFleetsIn()} 
											onChange={this.handleChangeInput} 
										/>
									</FormGroup>
								</Col>		
							</Row>
						)
					}
					<Row>
						<Col xs={12} md={6}>
							<FormGroup>
								<label>Are you using a trolley? *</label>
								<YesNoRadio 
									id="isUsingTrolley" 
									value={state.isUsingTrolley}
									onChange={this.handleChangeInput} 
								/>
							</FormGroup>
						</Col>
						{
							state.isUsingTrolley && (
								<Col xs={12} md={6}>
									<FormGroup>
										<label>Which fleet is the trolley in? *</label>
										<RadioButtonList 
											id="trolleyIsIn" 
											value={state.trolleyIsIn}
											items={this.renderFleetsIn()} 
											onChange={this.handleChangeInput} 
										/>
									</FormGroup>
								</Col>		
							)
						}
					</Row>
					<MassManagement 
						excludeFormElement
						isPreStartV2={props.isPreStartV2 || state.selectedTask !== undefined}
						data={{ PreStartRuns: state.PreStartRuns }} 
						mainVehFleetNumber={props.mainVehFleetNumber}
						trailer1VehFleetNumber={props.trailer1VehFleetNumber}
						trailer2VehFleetNumber={props.trailer2VehFleetNumber}
						dollyVehFleetNumber={props.dollyVehFleetNumber}
						palletJackVehFleetNumber={state.palletJackVehFleetNumber}
						onChange={this.handleChangeMassManagementData}
					/>
				</ContentBox>
				<Row>
					<Col xs={4}>
						<Button
							bsStyle="warning"
							onClick={props.onClickBack}
						>
							<Icon name="arrow-left" /> Go Back
						</Button>
					</Col>
					<Col xs={8}>
						<Button
							block
							bsStyle="success"
							type="submit"
							className="pull-right"
						>
							<Icon name="send" /> Submit 
						</Button>
					</Col>
				</Row>
			</Form>
		);
	}

	render() {
		return this.renderContent();
	}
}
