const initialState = {
	list: []
};

/** 
 * ACTION TYPES
*/
export const SET_LIST = 'tms-web-ui/vehicle/SET_LIST';
export const SET_DEFAULT = 'tms-web-ui/vehicle/SET_DEFAULT';

/**
 * ACTIONS
 */
export function setVehiclesList(vehiclesList) {
	return {
		type: SET_LIST,
		payload: vehiclesList
	};
}
 
/**
 * REDUCER
 */
export default (state = initialState, { type, payload }) => {
	switch (type) {
	case SET_LIST: 
		return { ...state, list: payload };
	case SET_DEFAULT: 
		return initialState;
	default:
		return state;
	}
};