import React, { Fragment } from 'react';
import { Popover, Overlay, OverlayTrigger } from 'react-bootstrap';
import Icon from 'react-fontawesome';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { IVehicleMaintenance } from '../../../app/models/VehicleMaintenance';

interface IProps {
	vehicleMaintenanceService?: IVehicleMaintenance;
	show?: boolean;
	targetElementId?: string;
}

export const VehicleServicingOverlayTrigger: React.FC<IProps> = props => {
	if (!props.vehicleMaintenanceService)
		return (
			<Fragment>
				{ props.children }
			</Fragment>
		);

	const { startTimeLocal, finishTimeLocal } = props.vehicleMaintenanceService;

	const title = (
		<span className="text-danger">
			<Icon name="warning" />&nbsp;&nbsp;
			<b>
				Vehicle Booked for Service
			</b>
		</span>
	);

	const content = (
		<Popover title={title} id={'tooltip_roster_' + uniqueId()}>
			This vehicle is <b>booked for service</b> from <b>{moment(startTimeLocal).format('DD/MM/YY HH:mm')}</b> to <b>{moment(finishTimeLocal).format('DD/MM/YY HH:mm')}</b>.
			Please check its availability.
		</Popover>
	)
	if (props.show && props.targetElementId)
		return (
			<div>
				{ props.children }
				<Overlay
					show
					placement="top" 
					target={() => document.getElementById(props.targetElementId!)}
				>
					{ content }
				</Overlay>
			</div>
		)

	return (
		<OverlayTrigger
			placement="top" 
			overlay={content}
		>
			{ props.children }
		</OverlayTrigger>
	)
}