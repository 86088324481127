import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import Icon from 'react-fontawesome';

import { Button, Modal, Tab, Nav, NavItem } from 'react-bootstrap';
import NonConformanceApi from '../../../../../app/api/NonConformanceApi';
import NonConformanceForm from './NonConformanceForm';
import { Loader, ErrorBox } from '../../../../common/uiElements';
import CorrectiveActionModal from '../correctiveAction/CorrectiveActionModal';
import CorrectiveActionHistory from '../correctiveAction/CorrectiveActionHistory';
import FontAwesome from 'react-fontawesome';

const propTypes = {
	id: PropTypes.number,
	onSave: PropTypes.func
};

const defaultProps = {
	id: null,
	onSave: () => {}
};

class NonConformanceModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			nonConformanceData: null,
			activeTab: 'nonConformance',
			isLoading: false,
			isSaving: false,
			isEditMode: false,
			errorLoading: null,
			errorSaving: null,
			showCorrectiveActionModal: false
		};
	}

	componentDidMount() {
		if (this.props.id)
			return this.fetchData();
		else
			this.setState({ 
				correctiveActionData: {
					nonConformanceId: this.props.nonConformanceId,
					files: []
				}
			});
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchData = () => {
		this.setState({ nonConformanceData: null, errorLoading: null, isLoading: true });
		NonConformanceApi.getNonConformanceDetails(this.props.id)
			.then(nonConformanceData => {
				if (this.unmounted) return;
				
				this.setState({ 
					nonConformanceData, 
					reportedByType: nonConformanceData.reportedByExternal ? 'external' : 'internal',
					isLoading: false
				});
			})
			.catch(errorLoading => {
				this.setState({ errorLoading, isLoading: false });
			});
	}

	saveData = () => {
		if (this.formContainer && !window.$(this.formContainer).find('form').valid())
			return;

		this.setState({ errorSaving: null, isSaving: true });
		let promise;
		if (this.props.id)
			promise = () => NonConformanceApi.updateNonConformanceData(this.props.id, this.state.nonConformanceData);
		else
			promise = () => NonConformanceApi.saveNonConformanceData(this.state.nonConformanceData);

		promise()
			.then(savedData => {
				toastr.success('The data was successfully saved', 'Success');
				if (this.props.id && !this.unmounted)
					return this.setState({ isEditMode: false, isSaving: false }, this.fetchData);
				else {
					this.props.onSave(savedData.id);
					this.setState({ isEditMode: false, isSaving: false });
				}
			})
			.catch(errorSaving => {
				this.setState({ errorSaving, isSaving: false });
			});
	}

	toggleEditMode = () => {
		this.setState({ 
			isEditMode: !this.state.isEditMode
		}, this.fetchData);
	}

	handleChange = e => {
		const { id, value } = e.target;
		
		this.setState({ 
			nonConformanceData: {
				...this.state.nonConformanceData,
				[id]: value
			}
		});
	}

	handleChangeUser = (fieldId, workerId) => {
		this.setState({ 
			nonConformanceData: {
				...this.state.nonConformanceData,
				[fieldId]: workerId
			}
		});
	}

	handleChangeVehicle = (fieldId, fleetNumber) => {
		this.setState({ 
			nonConformanceData: {
				...this.state.nonConformanceData,
				[fieldId]: fleetNumber
			}
		});
	}

	handleChangeReportedByType = e => {
		this.setState({
			reportedByType: e.target.value,
			nonConformanceData: {
				...this.state.nonConformanceData,
				reportedById: '',
				reportedByExternal: '',
			}
		});
	}

	handleCloseCorrectiveActionModal = () => {
		this.setState({ 
			selectedCorrectiveActionId: null,
			showCorrectiveActionModal: false 
		});
	}

	handleSaveCorrectiveAction = () => {
		this.setState({ 
			showCorrectiveActionModal: false 
		}, this.fetchData);
	}

	handleSeeCorrectiveActionDetails = selectedCorrectiveActionId => {
		this.setState({ 
			selectedCorrectiveActionId,
			showCorrectiveActionModal: true 
		});
	}

	handleChangeTabs = activeTab => {
		this.setState({ activeTab }, () => {
			if (this.state.isEditMode)
				this.toggleEditMode();
		});
	}

	renderNonConformanceForm = () => {
		const { state } = this;

		return (
			<div ref={ref => this.formContainer = ref}>
				<NonConformanceForm
					{...state.nonConformanceData}
					reportedByType={state.reportedByType}
					isReadOnly={this.props.id && !state.isEditMode}
					onChange={this.handleChange}
					onChangeUser={this.handleChangeUser}
					onChangeVehicle={this.handleChangeVehicle}
					onChangeReportedByType={this.handleChangeReportedByType}
				/>
			</div>
		)
	}

	renderContent = () => {
		const { state } = this;

		if (state.isLoading || state.isSaving)
			return <Loader isLoading={state.isLoading} isSaving={state.isSaving} />;

		if (state.errorLoading || state.errorSaving)
			return (
				<ErrorBox 
					error={state.errorLoading || state.errorSaving} 
					retryFunc={state.errorLoading ? this.fetchData : this.saveData } />
			);
		
		if (!state.nonConformanceData?.id)
				return this.renderNonConformanceForm();

		return (
			<Fragment>
				<Tab.Container id="tabNonConformance" activeKey={state.activeTab} onSelect={this.handleChangeTabs}>
					<Fragment>
						<Nav style={{background: '#fff'}} justified bsStyle="pills">
							<NavItem eventKey="nonConformance" title="Non Conformance Details">
								<Icon name="info-circle" /> Non Conformance Details
							</NavItem>
							<NavItem eventKey="correctiveAction" title="Corrective Action History">
								<Icon name="check" /> Corrective Action History
							</NavItem>
						</Nav>
						<br />
						<Tab.Content style={{paddingTop: '20px', marginTop: '10px', 'borderTop': '1px solid #eee'}}>
							<Tab.Pane eventKey="nonConformance">
								{ this.renderNonConformanceForm() }
							</Tab.Pane>
							<Tab.Pane eventKey="correctiveAction">
								<Button 
									block
									bsStyle="success"
									onClick={() => this.setState({ showCorrectiveActionModal: true })}
								>
									<Icon name="plus" /> Add Corrective Action
								</Button>
								<hr />
								<CorrectiveActionHistory 
									correctiveActionsList={state.nonConformanceData.PreStartCorrectiveActions}
									onClickSeeDetails={this.handleSeeCorrectiveActionDetails}
								/>
							</Tab.Pane>
						</Tab.Content>
					</Fragment>
				</Tab.Container>
				{
					this.props.id && state.showCorrectiveActionModal && (
						<CorrectiveActionModal 
							id={state.selectedCorrectiveActionId}
							enableEditMode={state.selectedCorrectiveActionId ? true : false}
							nonConformanceId={this.props.id}
							showRootCauseField={!state.nonConformanceData.rootCauseId}
							occurredOn={state.nonConformanceData.occurredOn}
							onSave={this.handleSaveCorrectiveAction}
							onClose={this.handleCloseCorrectiveActionModal}
						/>
					)
				}
			</Fragment>
		);
	}

	render() {
		const { state, props } = this;

		return (
			<Modal bsSize="lg" show onHide={props.onClose}>
					<Modal.Header closeButton>
						<Modal.Title>
							<b>{ props.id ? `Edit Non Conformance #${props.id}` : 'Add New Non Conformance' }</b>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{ this.renderContent() }
					</Modal.Body>
					<Modal.Footer>
						{
							!state.isLoading && !state.isSaving && (
								<>
									{
										!state.isEditMode && props.id && state.activeTab === "nonConformance" && (
											<Button className="pull-left" bsSize="lg" bsStyle="warning" onClick={this.toggleEditMode}>
												<FontAwesome name="edit" /> Edit Non Conformance
											</Button>
										)
									}
									{
										(state.isEditMode || !props.id) && (
											<Button className="pull-left" bsSize="lg" bsStyle="success" onClick={ this.saveData }>
												<FontAwesome name="floppy-o" /> Save Non Conformance
											</Button>
										)
									}
								</>
							)
						}
						<Button bsSize="lg" onClick={props.onClose}>
							<FontAwesome name="sign-out" /> Close Without Saving
						</Button>
					</Modal.Footer>
				</Modal>
		);
	}
}

NonConformanceModal.propTypes = propTypes;
NonConformanceModal.defaultProps = defaultProps;

export default NonConformanceModal;