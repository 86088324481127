import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

import PreStartViewDetails from './PreStartViewDetails';

const propTypes = {
	preStartId: PropTypes.string,
	data: PropTypes.object,
	show: PropTypes.bool,
	showLastPartTab: PropTypes.bool,
	onClose: PropTypes.func,
};

const PreStartDetailsModal = props => (
	<Modal show={props.show} bsSize="lg" onHide={props.onClose}>
		<Modal.Header closeButton>
			<Modal.Title>Pre-Start  Details</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<PreStartViewDetails 
				preStartId={props.preStartId} 
				data={props.data} 
				showLastPartTab={props.showLastPartTab}
			/>
		</Modal.Body>
		<Modal.Footer>
			<Button bsStyle="danger" onClick={props.onClose}>Close</Button>
		</Modal.Footer>
	</Modal>
);

PreStartDetailsModal.propTypes = propTypes;

export default PreStartDetailsModal;