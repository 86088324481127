import React from 'react';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { ITaskDetail } from '../../../../../app/models/Task';

interface IProps {
	task: ITaskDetail;
}

const TaskListDetailsSimple: React.FC<IProps> = ({ task }) => (
	<Table>
		<thead>
			<tr>
				<th>Ref #</th>
				<th>Type</th>
				<th>Depot</th>
				{ task.runNumber && <th>Run</th>}
				<th>Start</th>
				<th>Finish</th>
				<th>Duration</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>{ task.id }</td>
				<td>{ task.TaskType?.name }</td>
				<td>{ task.startingDepotName }</td>
				{ task.runNumber && <td>{ task.runNumber }</td>}
				<td>{ moment(task.startTimeLocal).format('HH:mm') }</td>
				<td>{ moment(task.startTimeLocal).add(task.budgetedTime, 'hours').format('HH:mm') }</td>
				<td>{ task.budgetedTime } h</td>
			</tr>
		</tbody>
	</Table>
)

export default TaskListDetailsSimple;