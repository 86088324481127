import React, { CSSProperties } from 'react';
import moment from 'moment';
import { Tabs, Tab } from 'react-bootstrap';
import './WeekDaysFilter.scss';
import { getWeekDays } from '../../../../../../app/helpers/dateTimeHelper';

interface IProps {
	date?: string;
	year?: number;
	weekNumber?: number;
	onChange?: Function;
}

const RosterWeekDaysFilter: React.FC<IProps> = ({
	year,
	weekNumber,
	date,
	onChange
}) => {
	if (!year || !weekNumber)
		return null;

	return (
		<Tabs
			id="weekDaysTab"
			className="roster-weekdays-filter"
			justified={window.innerWidth > 1000}
			bsStyle="pills"
			style={{ backgroundColor: 'white' }}
			activeKey={date || ''}
			onSelect={onChange as any}
		>
			{
				getWeekDays(year, weekNumber).map(weekDayDate => {
					const isToday = weekDayDate === moment().format('YYYY-MM-DD');
					const isTomorrow = weekDayDate === moment().add(1, 'days').format('YYYY-MM-DD');
					return (
						<Tab 
							key={weekDayDate} 
							eventKey={weekDayDate} 
							title={
								<span style={{ fontWeight: isToday ? 'bold' : '' } as CSSProperties }>
									{ moment(weekDayDate).format('ddd - DD/MM') }&nbsp;
									{ isToday ? '(Today)' : isTomorrow ? '(Tomorrow)' : '' }
								</span>
							} 
						/>
					);
				})
			}
		</Tabs>
	)
}

export default RosterWeekDaysFilter;
