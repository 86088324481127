/* eslint eqeqeq: 0 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '../inputs';
import { VehicleTypes } from '../../../app/models/Vehicle';

const propTypes = {
	value: PropTypes.arrayOf(PropTypes.number),
	allChecked: PropTypes.bool,
	onChange: PropTypes.func
};

const defaultProps = {
	value: [],
	allChecked: false,
	onChange: () => {}
};

class VehicleTypesComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vehicleTypesList: [
				{ id: VehicleTypes.PALLET_JACK , name: 'Pallet Jack' },
				{ id: VehicleTypes.RIGID , name: 'Rigid' },
				{ id: VehicleTypes.TRAILER , name: 'Trailer' },
				{ id: VehicleTypes.PRIME_MOVER , name: 'Prime Mover' },
				{ id: VehicleTypes.B_DOUBLE , name: 'B-Double' },
				{ id: VehicleTypes.UTE , name: 'UTE' },
				{ id: VehicleTypes.DOLLY , name: 'Dolly' }

			],
			isLoading: false,
			error: false
		};
	}
  
	componentDidMount() {
		const { allChecked, onChange } = this.props;
		if (allChecked && onChange) {
			const value = this.state.vehicleTypesList.map(v => v.id);
			onChange && onChange(value);
		}
	}

	handleChange = (e, value) => {
		const { onChange } = this.props;
		if (!onChange)
			return;

		const newValue = this.props.value;
		const index = this.props.value.indexOf(value);
		if (index !== -1)
			newValue.splice(index, 1);
		else
			newValue.push(value);

		onChange(newValue);
	}

	render() {   
		const s = this.state;
		const p = this.props;
		return (
			<ul className="list-inline">
				{
					s.vehicleTypesList.map(({ name, id }) => (
						<li key={id}>
							<Checkbox 
								value={id} 
								text={name}
								checked={p.value.find(typeId => typeId == id) ? true : false} 
								onChange={this.handleChange}
							/>
						</li>
					))
				}
			</ul>
		);
	}
}

VehicleTypesComponent.propTypes = propTypes;
VehicleTypesComponent.defaultProps = defaultProps;

export { VehicleTypesComponent as VehicleTypes };