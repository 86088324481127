/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setVehicleFilter } from '../../../stores/redux/filter';
import { setVehiclesList } from '../../../stores/redux/vehicle';
import VehiclesApi from '../../../app/api/VehiclesApi';
import { DropDownList } from './';

import { FormControl } from 'react-bootstrap';
import componentRequestHandler from '../../../app/api/helpers/componentRequestHandler';

const propTypes = {
	itemRef: PropTypes.func,
	id: PropTypes.string,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	value: PropTypes.string,
	selectedValue: PropTypes.string,
	firstItems: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired
		})
	),
	fleetNumbersToExclude: PropTypes.array,
	filterFleetNumbers: PropTypes.array,
	onSelectVehicle: PropTypes.func,
	onlyActive: PropTypes.bool,
	withoutSearch: PropTypes.bool,
	bsSize: PropTypes.oneOf(['small', 'large']),	
	vehTypeFilter: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.number),
		PropTypes.arrayOf(PropTypes.string)
	]),
	stateFilter: PropTypes.oneOfType([
		PropTypes.oneOf(['NSW', 'QLD']),
		PropTypes.arrayOf(PropTypes.oneOf(['NSW', 'QLD'])),
	]),
	subContractorAbnFilter: PropTypes.string,
	color: PropTypes.oneOf(['danger', 'success', 'warning', 'primary', 'info', 'default']),
	onChange: PropTypes.func,
	setVehiclesList: PropTypes.func,
	setVehicleFilter: PropTypes.func
};

const defaultProps = {
	fleetNumbersToExclude: [],
	filterFleetNumbers: [],
	filterState: [],
	value: '',
	className: '',
	onlyActive: false
};

class VehicleSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			error: false,
			vehiclesList: []
		};
	}

	componentDidMount() {
		this.getVehiclesList();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	getVehiclesList = () => {
		const { onlyActive, vehTypeFilter, stateFilter, subContractorAbnFilter } = this.props;
		const promise = () => VehiclesApi.getVehiclesListOld(onlyActive, vehTypeFilter, stateFilter, subContractorAbnFilter);
		componentRequestHandler(this, promise, 'vehiclesList')
			.then(data => this.props.setVehiclesList(data));
	}

	onSelectVehicle = (fleetNumber, e) => {
		const { vehiclesList } = this.state;
		const { onChange, onSelectVehicle, setVehicleFilter } = this.props;
		const vehicle = window.$.grep(vehiclesList, p => p.vehFleetNumber == fleetNumber)[0];
		
		if (vehicle && this.select) {
			const $select = window.$(this.select);
			$select.find('.Select-control').removeClass('invalid');
			$select.find('label.error').remove();
		}

		onSelectVehicle && onSelectVehicle(vehicle || {}, e);
		onChange && onChange(vehicle || {}, e);
		setVehicleFilter(vehicle);
	}

	handleChange = (e) => {
		this.onSelectVehicle(e.target.value, e);
	}

	render() {
		var { isLoading, error, vehiclesList } = this.state;
		var { id, disabled = false, firstItems, fleetNumbersToExclude, value, 
			selectedValue, withoutSearch, className, filterFleetNumbers, color, bsSize
		} = this.props;
		var essentialsVehicleList = [];
		
		if (vehiclesList && vehiclesList.length > 0) {
			essentialsVehicleList = vehiclesList.map(data => {
				var vehicleData = {};
				vehicleData.value = data.vehFleetNumber;
				vehicleData.label = data.vehFleetNumber + (data.vehRego ? (' - ' + data.vehRego) : '');
				return vehicleData;
			});
		}

		if (filterFleetNumbers && filterFleetNumbers.length > 0)
			essentialsVehicleList = essentialsVehicleList.filter(v => filterFleetNumbers.indexOf(v.value) !== -1);

		var renderText = () => {
			if (isLoading)
				return 'Loading vehicles. Please wait...';
			else if (error)
				return 'It wasn\'t possible to retrieve the vehicles.';
		};

		if (withoutSearch) {
			return (
				<FormControl 
					id={id} 
					value={value} 
					componentClass="select"
					disabled={disabled}
					bsSize={bsSize}
					className={color ? `form-control-${color}` : ''}
					onChange={this.handleChange}
				>
					{
						isLoading || error ? (
							<option>{renderText()}</option>
						) : [
							firstItems ? (
								firstItems.map(({ value, text }) => {
									return <option key={value} value={value}>{text}</option>;
								})
							) : (
								<option value="" key={-1}>--- SELECT ---</option>
							),
							essentialsVehicleList.map(({value, label}) => {
								if (fleetNumbersToExclude.indexOf(value) === -1)
									return <option key={value} value={value}>{label}</option>;
								else
									return null;
							})
						]
					}
				</FormControl>
			);
		}

		return (
			<div ref={ref => {
				this.select = ref;
				this.props.itemRef && this.props.itemRef(ref);
			}}>
				<DropDownList 
					value={value} 
					placeholder="Select the vehicle"
					disabled={disabled} 
					noResultsText={renderText()} 
					selectedValue={selectedValue} 
					data={essentialsVehicleList}
					onChange={this.onSelectVehicle}
					className={className}
				/>
				<FormControl 
					id={id}
					type="hidden" 
					value={value || ''} 
				/>
			</div>
		);
	}
}

VehicleSearch.propTypes = propTypes;
VehicleSearch.defaultProps = defaultProps;

const mapStateToProps = state => ({ vehiclesList: state.vehiclesList });
const mapDispatchToProps = { setVehicleFilter, setVehiclesList };

const VehicleSearchComponent = connect(mapStateToProps, mapDispatchToProps)(VehicleSearch);

export { VehicleSearchComponent as VehicleSearch };