import React from 'react';
import ReactTable from 'react-table';
import moment from 'moment-timezone';
import { IRunTemplateListItem } from '../../../../../../app/models/Run/RunTemplate';
import { IListComponentProps } from '../../../../../../app/models/Application';
import weekDays from '../../../../../../app/store/weekDays';
import { RunNumberCombinedColored } from '../../../../../common/uiElements';

const RunTemplateList: React.FC<IListComponentProps<IRunTemplateListItem>> = props => (
	<div id="run-template-list">
		<ReactTable
			filterable
			minRows={0}
			collapseOnDataChange={false}
			defaultPageSize={10000}
			loading={props.isLoading}
			data={props.records}
			defaultFilterMethod={(filter, row) => {
				const id =  filter.pivotId || filter.id;
				return row[id] !== undefined ? String(row[id]).toLowerCase().includes(String(filter.value).toLowerCase()) : true
			}}
			getTrProps={(state: any, rowInfo: any) => {
				if (!rowInfo) 
					return {};
				
				const runTemplate = rowInfo.original as IRunTemplateListItem;

				return {
					onClick: () => props.onSelectRow && props.onSelectRow(runTemplate.id, rowInfo.index)
				}
			}}
			columns={[
				{
					Header: 'Ref ID',
					accessor: 'id',
					width: 70
				},
				{
					Header: 'DoW',
					width: 80,
					accessor: 'dayOfWeek',
					Cell: row => weekDays[row.value - 1]
				},
				{
					Header: 'Task Type',
					accessor: 'taskTypeName',
					width: 80
				},
				{
					Header: 'Run Number',
					accessor: 'runNumberCombined',
					width: 125,
					Cell: row => {
						const { runNumberCombined } = row.original as IRunTemplateListItem;
						return <RunNumberCombinedColored runNumberCombined={runNumberCombined} />
					}
				},
				{
					Header: 'Start Time',
					accessor: 'startTime',
					width: 85,
					Cell: row => moment(row.value, 'HH:mm:ss').format('HH:mm')
				},
				{
					Header: 'Finish Time',
					id: 'finishTime',
					width: 85,
					filterMethod: (filter: any, original: any) => {
						const { startTime, budgetedTime } = original as IRunTemplateListItem;
						const value = moment(moment('1900-01-01T' + startTime)).add(budgetedTime, 'hours').format('HH:mm');
						return value !== undefined ? String(value).toLowerCase().includes(String(filter.value).toLowerCase()) : true;
					},
					Cell: row => {
						const { startTime, budgetedTime } = row.original as IRunTemplateListItem;
						return moment(moment('1900-01-01T' + startTime)).add(budgetedTime, 'hours').format('HH:mm');
					}
				},
				{
					Header: 'B. Time',
					accessor: 'budgetedTime',
					width: 60,
					Cell: row => {
						const budgetedTime = row.value;
						const color = budgetedTime > 10 ? 'danger' : budgetedTime > 6 ? 'warning' : 'success';
						return budgetedTime? <b className={`text-${color}`}>{ budgetedTime + ' h' }</b> : '-';
					}
				},
				{
					Header: 'Area',
					accessor: 'area'
				}
			]}
		/>
	</div>
);

export default RunTemplateList;