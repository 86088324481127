import store from '../../stores/redux';
import groups from '../../app/schemas/PermissionGroups';
var userData = store.getState().user.data;

export function checkUserHasADGroupPermission(permissionToCheck: string | string[]) {	
	if (Array.isArray(permissionToCheck))
		return permissionToCheck.find(group => group === userData.permissionGroupName);

	return userData.permissionGroupName === permissionToCheck;
}

export function allowFeature(permissionId?: number) {
	return permissionId && (userData.WorkerPermissionIds && userData.WorkerPermissionIds.indexOf(permissionId) !== -1);
}

export function isDriver() {
	return [groups.DRIVERS, groups.DRIVERS_EXTERNAL].indexOf(userData.permissionGroupName) !== -1;
}

export function isLoader() {
	return [groups.DEPOT_LOADERS].indexOf(userData.permissionGroupName) !== -1;
}