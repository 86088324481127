/* eslint-disable eqeqeq */

import React from 'react';
import { string, number, bool, func, arrayOf, shape, oneOfType } from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import PalletJackBox from './PalletJackBox';

const propTypes = {
	palletJacksList: arrayOf(
		shape({
			fleetNumber: string.isRequired,
			isCharging: bool.isRequired,
			isBatteryLow: bool.isRequired,
			currentVoltage: number,
			startedChargingOnLocal: string,
			last24HoursTotalCharges: number.isRequired,
			last24HoursChargingTimeInMin: number.isRequired,
			lastPingOnLocal: string.isRequired,
			lastChargeTotalTimeInMin: number
		})
	).isRequired,
	selectedDepotId: oneOfType([string, number]).isRequired,
	onClickBox: func.isRequired
};

const PalletJackBoxList = ({
	palletJacksList,
	selectedDepotId,
	onClickBox
}) => (
	<Row className="panel-live-monitoring-container">
		{
			palletJacksList.length === 0 ? (
				<h3 className="text-center">No Pallet Jacks to show</h3>
			) : (
				palletJacksList.map(palletJack => {
					// If it's the same depot that is selected
					if (palletJack.depotId == selectedDepotId)
						return (
							<Col key={palletJack.fleetNumber} xs={12} md={6}>
								<PalletJackBox 
									{ ...palletJack } 
									onClick={onClickBox} 
								/>
							</Col>            
						);

					return (
						<Col key={palletJack.fleetNumber} xs={6} sm={4}>
							<PalletJackBox 
								{ ...palletJack } 
								isSimplified 
								onClick={onClickBox} 
							/>
						</Col>
					);
				})
			)
		}
	</Row>
);

PalletJackBoxList.propTypes = propTypes;

export default PalletJackBoxList;
