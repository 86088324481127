/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

const CompaniesList = props => (
	<ReactTable
		data={props.data}
		minRows={0}
		manual
		loading={props.isLoading}
		page={props.page}
		pageSize={props.pageSize}
		pages={props.totalPages}
		sorted={props.sorted}
		onFetchData={props.onFetchData}
		onPageChange={props.onPageChange}
		onPageSizeChange={props.onPageSizeChange}
		onSortedChange={props.onSortedChange}
		getTrProps={(state, rowInfo) => {
			if (!rowInfo) 
				return {};

			return {
				onClick: () => props.onViewDetails && props.onViewDetails(rowInfo.original.abn)
			}
		}}
		columns={[
			{
				Header: 'Name',
				id: 'companyName',
				accessor: 'companyName'
			}, 
			{
				Header: 'ABN',
				id: 'abn',
				accessor: 'abn',
			}, 
			{
				Header: 'Phone',
				id: 'phone',
				accessor: 'phone1',
				sortable: false,
				Cell: row => row.value ? <a href={`tel:${row.value}`}>{row.value}</a> : '-'
			},
			{
				Header: 'Is Sub-Contractor',
				accessor: 'isSubContractor',
				Cell: row => row.value ? 'YES' : 'NO'
			}, 
		]}
	/>
);

CompaniesList.propTypes = {
	data: PropTypes.array.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired,
	sorted: PropTypes.arrayOf(
		PropTypes.shape({
			desc: PropTypes.bool.isRequired,
			id: PropTypes.string.isRequired,
		})
	),
	isLoading: PropTypes.bool.isRequired,
	onPageChange: PropTypes.func,
	onPageSizeChange: PropTypes.func,
	onSortedChange: PropTypes.func,
	onFetchData: PropTypes.func,
	onViewDetails: PropTypes.func
};

export default CompaniesList;