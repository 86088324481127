import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col, FormGroup, FormControl } from 'react-bootstrap';

import WorkerApi from '../../../../../app/api/WorkerApi';
import WorkerDriverLicenceSchema from '../../../../../app/schemas/worker/WorkerDriverLicenceSchema.json';
import driverLicenceConditions from '../../../../../app/store/DriverLicenceConditions.json';
import { ImageWithCropping, SelectInputWithData, Checkbox } from '../../../../common/inputs';
import { Form, Loader } from '../../../../common/uiElements';

const propTypes = {
	id: PropTypes.string,
	data: PropTypes.object,
	onChange: PropTypes.func,
	readOnly: PropTypes.bool
};

const defaultProps = {
	id: 'WorkerDriverLicenceDetails',
	readOnly: false,
	data: WorkerDriverLicenceSchema
};

const validations = {
	cardFileFront: 'required',
	cardFileBack: 'required',
	number: 'required',
	type: 'required',
	state: 'required',
	expiryDate: 'required'
};

class WorkerDriverLicenceDetails extends Component {
	constructor() {
		super();
		this.state = {
			isLoadingFrontImage: false,
			isLoadingBackImage: false,
			frontImage: null,
			backImage: null,
		};
	}

	UNSAFE_componentWillMount() {
		this.fetchImages();
	}

	fetchImages = () => {
		const { data } = this.props;
		if (!data)
			return;

		const { workerId, id, cardFileBack, cardFileFront } = data;

		if (cardFileFront) {
			this.setState({ isLoadingFrontImage: true })
			WorkerApi.getWorkerDriverLicenceImage(workerId, id, 'front')
				.then(frontImage => this.setState({ frontImage, isLoadingFrontImage: false }))
				.catch(error => {
					console.error(error);
					this.setState({ isLoadingFrontImage: false });
				});
		}

		if (cardFileBack) {
			this.setState({ isLoadingBackImage: true })
			WorkerApi.getWorkerDriverLicenceImage(workerId, id, 'back')
				.then(backImage => this.setState({ backImage, isLoadingBackImage: false }))
				.catch(error => {
					console.error(error);
					this.setState({ isLoadingBackImage: false });
				});
		}
	}

	handleChangeCheckbox = (e, value) => {
		var { conditionsArray } = this.props.data;
		conditionsArray = JSON.parse(conditionsArray);

		if (e.target.checked)
			conditionsArray.push(value);
		else
			conditionsArray.splice(conditionsArray.indexOf(value), 1);
		

		var { data } = this.props;
		data['conditionsArray'] = JSON.stringify(conditionsArray);
		return this.props.onChange(data);
	}

	handleChangeInput = (e) => {
		var { data } = this.props;
		data[e.target.id] = e.target.value;
		return this.props.onChange(data);
	}

	handleChangePicture = (result) => {
		if (!result)
			return;
			
		let id = Object.keys(result);
		var { data } = this.props;
		data[id] = result[id];
		this.props.onChange(data);
	}

	render() {
		const s = this.state;
		var { readOnly, data } = this.props;
		
		if (!data) return <div></div>;

		var azureId = data.workerId;

		return (
			<Form validations={validations}>
				<Col xs={12}>
					<Row>
						<Col sm={6}>
							<FormGroup>
								<label>Number *</label>
								{
									readOnly ? (
										<p>{ data.number }</p>
									) : (
										<FormControl 
											id="number"
											value={data.number}
											onChange={this.handleChangeInput}
										/>
									)
								}
							</FormGroup>
						</Col>
						<Col sm={6}>
							<FormGroup>
								<label>Type *</label>
								{
									readOnly ? (
										<p>{ data.type }</p>
									) : (
										<SelectInputWithData id="type" dataType="driverLicenceTypes" value={data.type} onChange={this.handleChangeInput} />
									)
								}
							</FormGroup>
						</Col>
						<Col sm={6}>
							<FormGroup>
								<label>Expiry Date *</label>
								{
									readOnly ? (
										<p>{ moment(data.expiryDate).format('DD/MM/YYYY') }</p>
									) : (
										<FormControl 
											id="expiryDate"
											type="date"
											value={data.expiryDate !== '' ? moment(data.expiryDate).format('YYYY-MM-DD') : ''}
											onChange={this.handleChangeInput}
										/>
									)
								}
							</FormGroup>
						</Col>
						<Col sm={6}>
							<FormGroup>
								<label>State *</label>
								{
									readOnly ? (
										<p>{ data.state }</p>
									) : (
										<SelectInputWithData id="state" dataType="states" value={data.state} onChange={this.handleChangeInput} />
									)
								}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col sm={6}>
							<FormGroup>
								<label>Conditions</label>
								{
									readOnly ? (
										<p>
											{
												data.conditionsArray.length === 0 ? 'None' : (
													JSON.parse(data.conditionsArray).join(', ')
												)
											}
										</p>
									) : (
										<ul className="list-inline">
											{
												driverLicenceConditions.map((item, index) => {
													return (<li key={index}><Checkbox value={item} checked={(data.conditionsArray.indexOf(item) !== -1)} text={item} onChange={this.handleChangeCheckbox} /></li>);
												})
											}
										</ul>
									)
								}
							</FormGroup>
						</Col>
						<Col sm={6}>
							<FormGroup>
								<label>Conditions Description</label>
								{
									readOnly ? (
										<p>{ data.conditionsDescription || '-' }</p>
									) : (
										<FormControl 
											id="conditionsDescription"
											value={data.conditionsDescription}
											onChange={this.handleChangeInput}
										/>
									)
								}
							</FormGroup>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col sm={6}>
							<FormGroup>
								{
									data.id || readOnly ? (
										<div>
											<label>Front Image</label>
											{
												s.isLoadingFrontImage ? ( 
													<Loader />
												) : (
													<p>
														<img 
															className="img-responsive img-thumbnail" 
															style={{maxHeight: '350px'}} 
															src={s.frontImage} 
															alt="front"
														/>
													</p>
												)
											}
										</div>
									) : (
										s.isLoadingFrontImage ? ( 
											<Loader />
										) : (
											<ImageWithCropping 
												id="cardFileFront" 
												title="Front Image *" 
												aspectRatio={ 144 / 91 }
												resultSize={{ width: 1152, height: 728 }} 
												imgUrl={typeof data.cardFileFront === 'string' && azureId ? s.frontImage : ''}
												file={data.cardFileFront !== null && typeof data.cardFileFront === 'object' ? data.cardFileFront : null}
												onChange={ this.handleChangePicture }
												showPreview={true}
											/>
										)
									)
								}
							</FormGroup>
						</Col>
						<Col sm={6}>
							<FormGroup>
								{
									data.id || readOnly ? (
										<div>
											<label>Back Image</label>
											{
												s.isLoadingFrontImage ? ( 
													<Loader />
												) : (
													<p>
														<img 
															className="img-responsive img-thumbnail" 
															style={{maxHeight: '350px'}} 
															src={s.backImage}
															alt="back"
														/>
													</p>
												)
											}
										</div>
									) : (
										s.isLoadingFrontImage ? ( 
											<Loader />
										) : (
											<ImageWithCropping 
												title="Back Image *" 
												id="cardFileBack" 
												aspectRatio={ 144 / 91 } 
												resultSize={{ width: 1152, height: 728 }}
												imgUrl={typeof data.cardFileBack === 'string' && azureId ? s.backImage : ''} 
												file={data.cardFileBack !== null && typeof data.cardFileBack === 'object' ? data.cardFileBack : null}
												onChange={ this.handleChangePicture }
												showPreview={true}
											/>
										)
									)
								}
							</FormGroup>
						</Col>
					</Row>
				</Col>
			</Form>
		);
	}
}

WorkerDriverLicenceDetails.propTypes = propTypes;
WorkerDriverLicenceDetails.defaultProps = defaultProps;

export default WorkerDriverLicenceDetails;