import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

import FaultReport from './FaultReport';

// Schema
import faultReportSchema from '../../../../../app/schemas/faultReport/faultReportSchema.json';

const guideSteps = [
	{
		title: 'Equipment',
		text: 'Select the vehicle or the equipment',
		selector: '.fault-report__vehicle',
		position: 'bottom',
		isFixed: true
	},
	{
		title: 'Type',
		text: 'Select the report type from the dropdown list',
		selector: '.fault-report__type',
		position: 'bottom',
		isFixed: true
	},
	{
		title: 'Description',
		text: 'Describe in detail the problem. <b>Be specific.</b>',
		selector: '.fault-report__description',
		position: 'bottom',
		isFixed: true
	},
	{
		title: 'Photos',
		text: 'If possible, take photos of the problem',
		selector: '.fault-report__photos',
		position: 'top',
		isFixed: true,
		isLastStep: true
	}
];

class FaultReportModalAddNew extends Component {
	static propTypes = {
	/** If true, shows the Modal */
		show: PropTypes.bool,

		/** Fault report object */
		faultReportData: PropTypes.object,

		/** List of vehicles to show in dropdown list */
		fleetNumbers: PropTypes.array,

		/** If true, hide the save button  */
		isReadOnly: PropTypes.bool,

		/** If true, the hadQuickFix and comments fields can be added */
		isEditable: PropTypes.bool,

		/** Handle modal close event */
		onClose: PropTypes.func,

		/** Handle modal save event  */
		onSave: PropTypes.func,

		addGuideSteps: PropTypes.func
	}

	constructor() {
		super();
		this.state = {
			faultReport: { ...faultReportSchema },
			isLoadingPicture: false
		};

		this.handleChangeFaultReport = (field, value) => {
			let { faultReport } = this.state;

			if (field === 'reportedVehicleId') {
				faultReport.reportedTypeId = '';
				faultReport.reportedTypeOption = '';
			} else if (field === 'reportedTypeId') {
				faultReport.reportedTypeOption = '';
			}

			this.setState({
				faultReport: {
					...faultReport,
					[field]: value
				}
			});
		};

		this.handleClose = () => {
			if (this.state.isLoadingPicture) return;

			if (this.props.isReadOnly || window.confirm('Are you sure that you want to close? The data won\'t be saved')) {
				this.setState({ faultReport: { ...faultReportSchema, FaultReportPhotos: [] } });
				this.props.onClose();
			}

		};

		this.handleSave = () => {
			if (window.$(this.toValidate).find('form').valid()) {
				this.setState({ faultReport: { ...faultReportSchema, FaultReportPhotos: [] } });
				return this.props.onSave(this.state.faultReport);
			}
		};
	}

	componentDidMount() {
		setTimeout(() => {
			if (this.props.addGuideSteps) {
				this.setState({
					faultReport: {
						reportedVehicleId: '1000'
					}
				});
				this.props.addGuideSteps(guideSteps);
			}
		}, 500);
	}

	render() {
		return (
			<Modal show={this.props.show} onHide={this.handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Fault Report</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div ref={ref => this.toValidate = ref}>
						<FaultReport 
							fleetNumbers={this.props.fleetNumbers}
							faultReportData={this.state.faultReport}
							isReadOnly={this.props.isReadOnly}
							isEditable={this.props.isEditable}
							onChange={this.handleChangeFaultReport}
							onLoadPicture={(isLoadingPicture) => { this.setState({ isLoadingPicture }); }}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					{ !this.props.isReadOnly && <Button disabled={this.state.isLoadingPicture} onClick={this.handleSave} bsStyle="success">Save</Button> }
					<Button disabled={this.state.isLoadingPicture} onClick={this.handleClose} bsStyle="default">Close</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default FaultReportModalAddNew;