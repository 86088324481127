import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import toastr from 'toastr';

import WorkerApi from '../../../app/api/WorkerApi';
import { ImageWithCropping } from '../inputs';
import NoPhotoImg from '../../../app/assets/img/user-no-photo.png';
import { ErrorBox } from '../../common/uiElements';


const propTypes = {
	azureId: PropTypes.string.isRequired,
	className: PropTypes.string,
	divClassName: PropTypes.string,
	isPictureChangeable: PropTypes.bool,
	userPhotoBase64: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	onLoadPhoto: PropTypes.func
};

class UserProfilePicture extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userPhotoBase64: this.props.userPhotoBase64,
			isLoadingImg: false,
			key: uuid()
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.azureId !== this.props.azureId)
			this.fetchUserPhoto();
	}	

	componentDidMount() {
		if (!this.props.userPhotoBase64)
			this.fetchUserPhoto();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchUserPhoto = () => {
		const { azureId, width, height} = this.props;
		this.setState({ isLoadingImg: true });
		WorkerApi.getWorkerProfilePhoto(azureId, width, height)
			.then(userPhotoBase64 => {				
				if (this.unmounted) return;

				this.setState({
					userPhotoBase64,
					isLoadingImg: false
				});

				this.props.onLoadPhoto && this.props.onLoadPhoto(userPhotoBase64);
			})
			.catch(error => {
				console.error(error);
			});
	}

	handleChangeProfilePicture = ({ profilePicture }) => {
		this.setState({ isLoadingImg: true, error: null });

		WorkerApi.saveWorkerProfilePicture(this.props.azureId, profilePicture)
			.then(() => {
				toastr.success('The image was successfully saved', 'Success');
				this.fetchUserPhoto();
			})
			.catch(error => {
				console.error(error);
				this.setState({ isLoadingImg: false, error });
			});
	}

	render() {
		const s = this.state;
		const p = this.props;

		return (
			<div key={s.key} className={classNames('text-center', p.divClassName)}>
				{
					s.error && (
						<ErrorBox error={s.error} />
					)
				}
				<img 
					className={classNames('img-circle', p.className, {'hidden': !this.state.isLoadingImg})} 
					src={NoPhotoImg} 
					height={p.height}
					alt="User"
				/>
				{
					s.userPhotoBase64 && (
						<img 
							className={classNames('img-circle', p.className, {'hidden': this.state.isLoadingImg})} 
							src={`${s.userPhotoBase64}`} 
							alt="User"
							height={p.height}
							onLoad={() => this.setState({ isLoadingImg: false })} 
							onError={() => this.setState({ isLoadingImg: true })} 
						/>
					)
				}
				{
					p.isPictureChangeable && (
						<ImageWithCropping 
							id="profilePicture" 
							showClickableElement
							clickableElementText="Change Photo"
							aspectRatio={ 1 / 1 }
							resultSize={{ width: 300, height: 300 }}
							onChange={ this.handleChangeProfilePicture }
						/>
					)
				}
			</div>
		);
	}
}

UserProfilePicture.propTypes = propTypes;

export { UserProfilePicture };