import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import packageInfo from '../../../../package.json';

const propTypes = {
	fullWidth: PropTypes.bool
};

const Footer = props => {
	return (
		<footer className={`main-footer ${props.fullWidth ? 'full-width' : ''}`}>
			<div className="pull-right">
				<b>Ver.</b> <Link title="See changelog" to="/changelog">{ packageInfo.version }</Link>
			</div>
			<strong>Copyright © 2016-{ new Date().getFullYear() } <a rel="noopener noreferrer" href="https://www.micway.com.au" target="_blank">Micway Transport</a>.</strong>
			<span className="hidden-xs">&nbsp;All rights reserved.</span>
		</footer>
	);
};

Footer.propTypes = propTypes;

export { Footer };