import React, { useState } from "react";
import { IPallet } from "../../../../../../app/models/Pallet";
import { Col, FormControl, FormGroup, InputGroup, Row } from "react-bootstrap";
import { PlacesListNew } from "../../../../../common/lists";
import { DropDownList } from "../../../../../common/inputs";
import { Form } from "../../../../../common/uiElements";
import FontAwesome from "react-fontawesome";
import { commodityList, palletLocationInTrailer, palletTypeList } from "../dataForLists";

interface IProps {
	pallets: IPallet[]
	isReadOnly?: boolean;
	onChangePallets: (pallets: IPallet[]) => void;
	// onClickEditPallet: () => void;
	onClickDeletePallet: (pallet: IPallet) => void;
}

const PalletReorderList: React.FC<IProps> = props => {
	const [palletIdsEditEnabled, setPalletIdsEditEnabled] = useState<string[]>([])

	const handleSetPalletEdit = (palletId: string) => {
		setPalletIdsEditEnabled([ ...palletIdsEditEnabled, palletId ])
		// props.onClickEditPallet();
	}

	const handleDeletePallet = (pallet: IPallet) => {
		setPalletIdsEditEnabled([ ...palletIdsEditEnabled.filter(id => id !== pallet.palletId) ]);
		props.onClickDeletePallet(pallet);
		// props.onClickEditPallet();
	}

	/**
	 * Renders a single pallet for the Pallet Reorder component when editing the Pallet Map
	 * @param pallet 
	 * @returns 
	 */
	const renderPalletTile = (pallet: IPallet) => {
		const isEditEnabled = palletIdsEditEnabled.includes(pallet.palletId);

		return (
			<FormGroup key={pallet.palletId} style={{ border: '1px solid black', boxShadow: '1px 4px 4px #ccc' }}>
				{/* Icon to display movability | ReactSortable */}
				{/* 
					<InputGroup.Addon>
						<Icon name="arrows" />
					</InputGroup.Addon> 
				*/}

				{/* Pallet ID */}
				<div style={{ position: 'relative' }}>
					<InputGroup.Addon className={pallet.isDeleted ? "bg-red" : isEditEnabled ? "bg-yellow" : "bg-blue"} style={{ border: 'none' }}>
						{ 
							!props.isReadOnly && !isEditEnabled && !pallet.isDeleted && (
								<FontAwesome 
									name="edit" 
									style={{  position: 'absolute', left: 7, top: 5, fontSize: 22 }}
									className="cursor-pointer" 
									onClick={() => handleSetPalletEdit(pallet.palletId)} 
								/>
							) 
						}
						{
							!props.isReadOnly && (
								<FontAwesome 
									name={pallet.isDeleted ? 'undo' : 'trash'}
									style={{  position: 'absolute', right: 7, top: 3, fontSize: 22 }}
									className="cursor-pointer" 
									onClick={() => handleDeletePallet(pallet)} 
								/>
							)
						}
						<span className="text-bold" style={{ fontSize: 18 }}>
							{ pallet.palletId } 
							{ 
								!props.isReadOnly && (isEditEnabled || pallet.isDeleted) && (
									<span className="text-italic" style={{ position: 'absolute', left: 5 }}>
										{ pallet.isDeleted ? "(Deleted)" : "(Editing)"}
									</span> 
								)
							}
						</span>
					</InputGroup.Addon>
				</div>

					{/* Drop down list of appropriate stores (TODO: Cache this so it doesnt load for every pallet) */}
					<InputGroup>
						<InputGroup.Addon>
							<div style={{ width: '80px', fontWeight: 'bold' }}>Store</div>
						</InputGroup.Addon>
						{
							isEditEnabled ? (
								<PlacesListNew
									required
									id={`store_` + pallet.palletId}
									type="deliveryStores"
									placeholder={pallet.store?.name || "--- SELECT STORE ---"}
									value={ pallet.store?.id && pallet.store?.id !== -1 ? pallet.store.id.toString() : '' }
									style={ pallet.isDeleted ? {backgroundColor: '#ffe8e8'} : { backgroundColor: '#ffffc7' } }
									onChange={ (place: any) => {
										pallet.store = {
											id: place?.value ? parseInt(place.value) : -1,
											name: (place.fullObject as any)?.name || '',
											storeNumber: (place.fullObject as any)?.storeNumber || '',
										}
										props.onChangePallets && props.onChangePallets([...props.pallets]);
									}}
								/>
							) : (
								<FormControl
									defaultValue={ !pallet.store ? 'Unknown Store' : (pallet.store.storeNumber ? `${pallet.store.storeNumber} - ` : '') + pallet.store.name }  
									style={ pallet.isDeleted ? { backgroundColor: '#ffe8e8' } : undefined}
								/>
							)
						}
					</InputGroup>

					{/* Commodity */}
					<InputGroup>
						<InputGroup.Addon>
						<div style={{ width: '80px', fontWeight: 'bold' }}>Commodity</div>
						</InputGroup.Addon>
						{
							isEditEnabled ? (
								<DropDownList
									required
									id={`commodity_` + pallet.palletId}
									placeholder="--- SELECT COMMODITY ---"
									value={ pallet.commodity }
									data={ commodityList }
									style={{ backgroundColor: '#ffffc7'}}
									onChange={ (commodity: string) => {
										pallet.commodity = commodity;
										props.onChangePallets && props.onChangePallets([...props.pallets]);
									}}
								/>
							) : (
								<FormControl
									defaultValue={ pallet.commodity }  
									style={ pallet.isDeleted ? {backgroundColor: '#ffe8e8'} : undefined}
								/>
							)
						}
					</InputGroup>

					{/* Pallet location */}
					<InputGroup>
						<InputGroup.Addon>
						<div style={{ width: '80px', fontWeight: 'bold' }}>Location</div>
						</InputGroup.Addon>
						{
							isEditEnabled ? (
								<DropDownList
									required
									id={`locationInTrailer_` + pallet.palletId}
									placeholder="--- SELECT SIDE ---"
									value={ pallet.locationInTrailer || ""}
									data={ palletLocationInTrailer }
									style={{ backgroundColor: '#ffffc7' }}
									onChange={ (locationInTrailer?: string) => {
										pallet.locationInTrailer = locationInTrailer || "";
										props.onChangePallets && props.onChangePallets([...props.pallets]);
									}}
								/>
							) : (
								<FormControl
									defaultValue={ pallet.locationInTrailer === "L" ? "LEFT" : "RIGHT" }  
									style={ pallet.isDeleted ? {backgroundColor: '#ffe8e8'} : undefined}
								/>
							)
						}
					</InputGroup>

				{/* Pallet type */}
				<div style={{ border: `1px solid ${pallet.palletType === 'CHEP' ? 'blue' : pallet.palletType === 'LOSCAM' ? 'red' : pallet.palletType === 'T3' ? 'green' : '#ccc'}` }}>
				<InputGroup>
					<InputGroup.Addon>
						<div style={{ width: '80px', fontWeight: 'bold' }}>Type</div>
					</InputGroup.Addon>
					{
						isEditEnabled ? (
							<DropDownList
								required
								id={`palletType_` + pallet.palletId}
								placeholder="--- SELECT PALLET TYPE ---"
								value={ pallet.palletType }
								data={ palletTypeList }
								style={ pallet.isDeleted ? {backgroundColor: '#ffe8e8'} : { backgroundColor: '#ffffc7' } }
								onChange={ (palletType: string) => {
									pallet.palletType = palletType;
									props.onChangePallets && props.onChangePallets([...props.pallets]);
								}}
							/>
						) : (
							<FormControl
								defaultValue={pallet.palletType}  
								style={ pallet.isDeleted ? { backgroundColor: '#ffe8e8' } : undefined}
							/>
						)
					}
				</InputGroup>
				</div>
			</FormGroup>
		)
	}

	const palletsLeftSide = props.pallets.filter(pallet => pallet.locationInTrailer === "L");
	const palletsRightSide = props.pallets.filter(pallet => pallet.locationInTrailer === "R");

	return (
		<Form id="pallet-reorder-form">
			<Row>
				<Col sm={6}>
					<h3 className="title text-center">LEFT SIDE ({ palletsLeftSide.length })</h3>
					{ palletsLeftSide.map(renderPalletTile) }
				</Col>
				<Col sm={6}>
					<h3 className="title text-center">RIGHT SIDE ({ palletsRightSide.length })</h3>
					{ palletsRightSide.map(renderPalletTile) }
				</Col>
			</Row>
		</Form>
	)
}

export default PalletReorderList;


/**
 * Pallet Drag & Drop Component - WIP UNUSED | ReactSortable
 * 
 * Sortable component used to drag & drop pallets between left and right sides of the trailer
 * @param props
 */
// const PalletReorder: React.FC<IProps> = props => {

// 	return (
// 		<Fragment>
// 			<h3 className="title">Reorder Pallets</h3>
// 			<Row>
// 				<Col xs={6}>
// 					<Title>Left Side</Title>
// 					<ReactSortable 
// 						id="left"
// 						className="sortable-list" 
// 						group={{ name: 'palletMap', pull: true, put: true }} 
// 						list={ props.pallets as any }
// 						setList={ props.onChangePallets as any }
// 						onChange={ (pallet) => {
// 							// console.log(props.pallets.findIndex(p => p.palletId === props.pallets.filter(p => p.locationInTrailer === "L")[pallet.oldIndex as number].palletId))
// 							console.log(`L newIndex:`,pallet.newIndex)
// 						}}
// 						onEnd={ (pallet) => {
// 							let palletIndex = 0;
// 							// Pallet comes from the same side (left), don't change side
// 							if(pallet.from.id === pallet.to.id) {
// 								palletIndex = props.pallets.findIndex(p => p.palletId === props.pallets.filter(p => p.locationInTrailer === "L")[pallet.oldIndex as number].palletId);
// 								return;
// 							}
// 							palletIndex = props.pallets.findIndex(p => p.palletId === props.pallets.filter(p => p.locationInTrailer === "R")[pallet.oldIndex as number].palletId);
							
// 							// Switch sides of the selected pallet from LEFT to RIGHT
// 							props.pallets[palletIndex].locationInTrailer = "R";
// 							// props.pallets.filter(p => p.locationInTrailer === "L")[pallet.oldIndex as number].locationInTrailer = "R";
// 						}}
// 					>
// 						{
// 							props.pallets.filter(pallet => pallet.locationInTrailer === "L").map((pallet, index) => {
// 								return (
// 									palletTile(pallet, index)
// 								)
// 							})
// 						}
// 					</ReactSortable>
// 				</Col>
// 				<Col xs={6}>
// 					<Title>Right Side</Title>
// 					<ReactSortable 
// 						id="right"
// 						className="sortable-list" 
// 						group={{ name: 'palletMap', pull: true, put: true }}
// 						list={ props.pallets as any } 
// 						setList={ props.onChangePallets as any }
// 						onChange={ (pallet) => {
// 							// console.log(props.pallets.findIndex(p => p.palletId === props.pallets.filter(p => p.locationInTrailer === "R")[pallet.oldIndex as number].palletId))
// 							console.log(`R newIndex:`,pallet.newIndex)
// 						}}
// 						onEnd={ (pallet) => {
// 							// Pallet comes from same side (right), don't change side
// 							if(pallet.from.id === pallet.to.id)
// 								return;
							
// 							// Switch sides of the selected pallet from RIGHT to LEFT
// 							props.pallets.filter(p => p.locationInTrailer === "R")[pallet.oldIndex as number].locationInTrailer = "L";
// 						}}
// 					>
// 					{
// 						props.pallets.filter(pallet => pallet.locationInTrailer === "R").map((pallet, index) => {
// 							return (
// 								palletTile(pallet, index)
// 							)
// 						})
// 					}
// 					</ReactSortable>
// 				</Col>
// 			</Row>
// 		</Fragment>
// 	);
// }