import React from 'react'

interface IProps {
	runNumberCombined: string;
}

export const RunNumberCombinedColored: React.FC<IProps> = ({
	runNumberCombined
}) => {
	return (
		<span title={runNumberCombined}>
			{
				runNumberCombined.split(',').map((number, index) => (
					<span key={number}>
						{ index > 0 && ', '	}
						<b className={number.includes("FS") ? 'text-orange' : number.includes("WW") ? 'text-success' : 'text-info'}>
							{ number }
						</b>
					</span>
				))
			}
		</span>
	)
}