import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import { HoursRange } from '../../../../app/models/Application';
import { extendMoment } from 'moment-range';
import { IStaffRosterListItem, RosterUpdateReasonCategory, RosterUpdateReasonForm, StaffRosterForm, StaffRosterListFilter } from '../../../../app/models/StaffRoster';

const Moment = require('moment');
const moment = extendMoment(Moment);

export function filterStaffRosterList(staffRosterList: IStaffRosterListItem[], listFilter: StaffRosterListFilter, startingDepotId?: string, startDate?: string, finishDate?: string) {
	const { workerId, workerRoleId, hoursRange } = listFilter;

	if (startDate && finishDate)
		staffRosterList = staffRosterList
		.filter(p => 
			moment(p.startTimeLocal).isSameOrAfter(moment(`${startDate} 00:00`))  
			&& moment(p.startTimeLocal).isSameOrBefore(moment(`${finishDate} 23:59`))
		);

	if (hoursRange) {
		const [startHour, finishHour] = hoursRange.split('-');
		staffRosterList = staffRosterList
			.filter(p => moment(p.startTimeLocal).isSameOrAfter(moment(`${moment(p.startTimeLocal).format('YYYY-MM-DD')} ${startHour}`)) && 
			moment(p.startTimeLocal).isSameOrBefore(moment(`${moment(p.startTimeLocal).format('YYYY-MM-DD')} ${finishHour}`)));
	}

	if (startingDepotId)
		staffRosterList = filter(staffRosterList, p => parseInt(startingDepotId) === p.startingDepotId);

	if (workerId)
		staffRosterList = filter(staffRosterList, ['workerId', workerId]);
	
	if (workerRoleId)
		staffRosterList = filter(staffRosterList, p => parseInt(workerRoleId) === p.Worker?.currentRoleId);

	return orderBy(staffRosterList, [a => moment(a.startTimeLocal), 'Worker.shortName']);
}

export function totalRostersByHourRange(staffRosterList: IStaffRosterListItem[], hoursRange: HoursRange) {
	const [startHour, finishHour] = hoursRange.split('-');
	return staffRosterList.filter(p => moment(p.startTimeLocal).isSameOrAfter(moment(`${moment(p.startTimeLocal).format('YYYY-MM-DD')} ${startHour}`)) && 
		moment(p.startTimeLocal).isSameOrBefore(moment(`${moment(p.startTimeLocal).format('YYYY-MM-DD')} ${finishHour}`))).length;
}


/**
 * Returns the update reasons that should be completed based on the changes on the roster
 * @param originalRoster Roster before changes
 * @param updatedRoster  Roster after changes
 */
export function getRosterUpdateReasonCategories(originalRoster: StaffRosterForm, updatedRoster: StaffRosterForm): RosterUpdateReasonForm[] {
	const updateReasonCategories: RosterUpdateReasonForm[] = [];

	// CANCELLED
	// If it's cancelled it will only return the cancelled reason as any other change won't be made
	if (originalRoster.isCancelled !== updatedRoster.isCancelled) {
		updateReasonCategories.push(new RosterUpdateReasonForm(RosterUpdateReasonCategory.CANCELLED));
		return updateReasonCategories;
	}

	// START TIME
	if (originalRoster.startTime !== updatedRoster.startTime)
		updateReasonCategories.push(new RosterUpdateReasonForm(RosterUpdateReasonCategory.START_TIME, originalRoster.startTime, updatedRoster.startTime));

	// BUDGETED TIME
	if (originalRoster.budgetedTime !== updatedRoster.budgetedTime) 
		updateReasonCategories.push(new RosterUpdateReasonForm(RosterUpdateReasonCategory.BUDGETED_TIME, originalRoster.budgetedTime, updatedRoster.budgetedTime));

	// MAIN TASK
	if (originalRoster.mainTaskTypeName !== updatedRoster.mainTaskTypeName) 
		updateReasonCategories.push(new RosterUpdateReasonForm(RosterUpdateReasonCategory.MAIN_TASK, originalRoster.mainTaskTypeName, updatedRoster.mainTaskTypeName));

	// STARTING PLACE
	if (originalRoster.startingDepotName !== updatedRoster.startingDepotName) 
		updateReasonCategories.push(new RosterUpdateReasonForm(RosterUpdateReasonCategory.STARTING_PLACE, originalRoster.startingDepotName, updatedRoster.startingDepotName));

	return updateReasonCategories;
}

/**
 * Returns the correct label for the update reason form based on what was changed 
 */
export function getRosterUpdateReasonLabel(rosterUpdateReasonCategoryId: number) {
	switch (rosterUpdateReasonCategoryId) {
		case RosterUpdateReasonCategory.CANCELLED:
			return 'Reason for Cancelling this shift';
		case RosterUpdateReasonCategory.START_TIME:
			return 'Reason for changing the Start Time';
		case RosterUpdateReasonCategory.BUDGETED_TIME:
			return 'Reason for changing the Budgeted Time';
		case RosterUpdateReasonCategory.MAIN_TASK:
			return 'Reason for changing the Main Task';
		case RosterUpdateReasonCategory.STARTING_PLACE:
			return 'Reason for changing the Starting Place';
		default:
			return 'Reason for this change';
	}
}