import moment from "moment";
import { WorkerListItemWithExtraData } from "../Worker";


// FILTER FOR LIST
export class SmsMessageBulkListFilter {
	startDate = moment().add('days', -7).format('YYYY-MM-DD');
	finishDate = moment().format('YYYY-MM-DD');
	subject = '';
	isCompleted?: boolean;
}

// LIST ITEM
export interface ISmsMessageBulkListItem {
  id: number;
  subject: string;
  message: string;
  scheduledToSendAt?: Date;
  sentAt?: Date;
  createdOn: Date;
  createdById: string;
  createdByName: string;
  isCompleted: boolean;
  isDeleted: boolean;
  totalRecipients: number;
  totalSent: number;
  totalDelivered: number;
  totalFailed: number;
}

// DETAILS
export interface ISmsMessageBulkDetails {
  id: number;
  subject: string;
  message: string;
  scheduledToSendAt?: Date;
  sentAt?: Date;
  createdById: string;
  createdByName: string;
  createdOn: Date;
  isCompleted: boolean;
  isDeleted: boolean;
  totalRecipients: number;
  totalSent: number;
  totalDelivered: number;
  totalFailed: number;

  SmsMessageBulkWorkers: ISmsMessageBulkWorkerListItem[];
}

// SMS WORKER LIST ITEM
export interface ISmsMessageBulkWorkerListItem {
	smsMessageBulkId: number,
	workerId: string,
	workerName: string,
	mobileNumber: string,
	attempts: number,
	lastSentAt?: Date,
	awsMessageId: string,
	delivered: boolean | null,
	error: string | null
}


/** FORM */
export class SmsMessageBulkFormClass {
	id?: number;
  subject: string;
  message: string;
  scheduledToSendAt: string;
  isDeleted: boolean;
  Workers: WorkerListItemWithExtraData[];
	WorkersReadOnly: ISmsMessageBulkWorkerListItem[];
	
	// Non editable
	sentAt?: Date;
	createdOn?: Date;
	createdById: string;
  createdByName: string;
	isCompleted: boolean;
	totalRecipients: number;
	totalSent: number;
	totalDelivered: number;
	totalFailed: number;

	constructor(data?: ISmsMessageBulkDetails) {
		this.id = data?.id;
		this.subject = data?.subject || '';
		this.message = data?.message || '';
		this.scheduledToSendAt = data?.scheduledToSendAt ? moment(data?.scheduledToSendAt).toString() : '';
		this.isDeleted = data?.isDeleted || false;

		// convert to WorkerListItemWithExtraData from ISmsMessageBulkWorkerListItem
		this.WorkersReadOnly = data?.SmsMessageBulkWorkers || [];
		this.Workers = data?.SmsMessageBulkWorkers?.map(worker => ({
			id: worker.workerId,
			name: worker.workerName,
			mobileNumber: worker.mobileNumber
		})) || [];

		// Non editable
		this.sentAt = data?.sentAt;
		this.createdOn = data?.createdOn;
		this.createdById = data?.createdById || 'Unknown';
		this.createdByName = data?.createdByName || 'Unknown';
		this.isCompleted = data?.isCompleted || false;
		this.totalRecipients = data?.totalRecipients || 0;
		this.totalSent = data?.totalSent || 0;
		this.totalDelivered = data?.totalDelivered || 0;
		this.totalFailed = data?.totalFailed || 0;
	}
}

/**
 * HTTP REQUESTS
 */

export class SmsMessageBulkPOST_PUT {
	subject: string;
  message: string;
  scheduledToRunAt?: string;
  workerIds: string[];

	constructor(data: SmsMessageBulkFormClass) {
		this.subject = data.subject;
		this.message = data.message;
		this.scheduledToRunAt = data.scheduledToSendAt ? moment(data.scheduledToSendAt).format('YYYY-MM-DD HH:mm') : undefined;
		this.workerIds = data.Workers.map(worker => worker.id.toString());
	}
}


export enum SmsMessageStatus {
	FAILED = 1,
	PENDING = 2,
	COMPLETED = 3
}