/* eslint-disable no-undef */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import alert from '../../../../../../app/helpers/alert';
import AccessPointDetailsForm from './AccessPointDetailsForm';
import GeofenceApi from '../../../../../../app/api/GeofenceApi';

export default class AccessPointDetailsContainer extends Component {
	static propTypes = {
		formRef: PropTypes.func,
		data: PropTypes.object,
		geofencePolygonArray: PropTypes.array.isRequired,
		geofencePolygonBounds: PropTypes.object.isRequired,
		placeCategoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		show: PropTypes.bool,
		onSubmit:  PropTypes.func,
		onClose: PropTypes.func
	}

	constructor(props) {
		super(props);
		
		this.polygonCenter = this.props.geofencePolygonBounds && this.props.geofencePolygonBounds.getCenter();
		this.geocoder = window.google && new window.google.maps.Geocoder();

		this.state = {
			currentMudMapPath: '',
			isDownloadingMudMap: false,
			data: {
				riskRatingTypeId: 0,
				accessPointAddress: '',
				accessPointLat: this.polygonCenter && this.polygonCenter.lat(),
				accessPointLng: this.polygonCenter && this.polygonCenter.lng(),
				storeRoomLat: this.polygonCenter && this.polygonCenter.lat(),
				storeRoomLng: this.polygonCenter && this.polygonCenter.lng(),
				comments: '',
				heightRestrictionInMeters: '',
				lengthRestrictionInPallets: '',
				isActive: true,
				mudMapFile: null,
				hasBeenEdited: false
			}
		};	
	}

	componentDidMount() {
		const { data } = this.props;

		if (data)
			this.setState({ data });
	}

	setAccessPointFullAddress = () => {
		let { accessPointLat, accessPointLng } = this.state.data;
		
		this.geocoder.geocode({location: { lat: accessPointLat, lng: accessPointLng }}, (results, status) => {
			if (status === 'OK' && results[0]) {

				const accessPointAddress = results[0].formatted_address;
				this.setState({ 
					data: {
						...this.state.data,
						accessPointAddress
					}
				});	
			}
		});
	}

	checkMarkerInsideGeofence = (lat, lng) => {
		const polygon = new window.google.maps.Polygon({
			paths: this.props.geofencePolygonArray
		});

		const markerLatLng = new window.google.maps.LatLng(lat, lng);
		const isWithinGeofence = google.maps.geometry.poly.containsLocation(markerLatLng, polygon);
		return isWithinGeofence;
	}

	handleChangeInput = e => {
		const { data } = this.state;
		let { id, value, checked, type } = e.target;
		
		value = type === 'checkbox' ? checked : value;
		this.setState({ 
			data: {
				...data,
				[id]: value
			}
		});
	}

	handleChangePlaceAccessTypes = AccessTypesIds => {
		const { data } = this.state;
		this.setState({ 
			data: {
				...data,
				AccessTypesIds
			}
		});
	}

	handleChangeMudMapFile = e => {
		var file = e.target.files[0];
		if (file) {
			const { data } = this.state;
			this.setState({ 
				data: {
					...data,
					mudMapFile: e.target.files[0]
				}
			});
		}
	}

	handleChangeMarkerPosition = type => {
		const { data } = this.state;

		if (!data)
			return;

		let { 
			accessPointLat, accessPointLng, 
			storeRoomLat, storeRoomLng 
		} = data;
		
		if (type === 'accessPoint') {
			const _accessPointLocation = this.accessPointMarker.getPosition();
			accessPointLat = _accessPointLocation.lat();
			accessPointLng = _accessPointLocation.lng();

			if (!this.checkMarkerInsideGeofence(accessPointLat, accessPointLng)) {
				alert('The Access Point must be within the Geofence', null, 'danger');
				return this.forceUpdate();
			}

		} else {
			const _storeRoomLocation = this.storeRoomMarker.getPosition();
			storeRoomLat = _storeRoomLocation.lat();
			storeRoomLng = _storeRoomLocation.lng();

			if (!this.checkMarkerInsideGeofence(storeRoomLat, storeRoomLng)) {
				alert('The Store Room must be within the Geofence', null, 'danger');
				return this.forceUpdate();
			}
		}

		this.setState({ 
			data: {
				...data,
				accessPointLat,
				accessPointLng,
				storeRoomLat,
				storeRoomLng,
			}
		}, () => type === 'accessPoint' && this.setAccessPointFullAddress());
	}

	handleChangeRiskRating = riskRatingTypeId => {
		const { data } = this.state;
		this.setState({ 
			data: {
				...data,
				riskRatingTypeId
			}
		});
	}

	handleDownloadMudMap = () => {
		this.setState({ isDownloadingMudMap: true });
		GeofenceApi.getMudMapFile(this.state.data.id)
			.catch(error => {
				console.error(error);
				toastr.error('The file could not be retrieved', 'Something went wrong');
			})
			.then(() => {
				this.setState({ isDownloadingMudMap: false });
			});
	}

	handleDeleteMudMapFile = () => {
		const { data } = this.state;
		this.setState({
			currentMudMapPath: data.mudMapPath,
			data: {
				...data,
				mudMapPath: null
			}
		});
	}

	handleUndoMudMapFile = () => {
		const { data, currentMudMapPath } = this.state;
		this.setState({
			currentMudMapPath: null,
			data: {
				...data,
				mudMapPath: currentMudMapPath
			}
		});
	}

	render() {
		const { state, props } = this;
		return (
			<AccessPointDetailsForm 
				{...state.data}
				formRef={props.formRef}
				accessPointMarkerRef={ref => this.accessPointMarker = ref}
				storeRoomMarkerRef={ref => this.storeRoomMarker = ref}
				isDownloadingMudMap={state.isDownloadingMudMap}
				geofencePolygonArray={props.geofencePolygonArray}
				geofencePolygonBounds={props.geofencePolygonBounds}
				onChangeInput={this.handleChangeInput}
				onChangePlaceAccessTypes={this.handleChangePlaceAccessTypes}
				onChangeMarkerPosition={this.handleChangeMarkerPosition}
				onChangeRiskRating={this.handleChangeRiskRating}
				onChangeMudMapFile={this.handleChangeMudMapFile}
				onDownloadMudMapFile={this.handleDownloadMudMap}
				onDeleteMudMapFile={this.handleDeleteMudMapFile}
				onUndoMudMapFile={this.handleUndoMudMapFile}
				onSubmit={props.onSubmit}
			/>
		);
	}
}