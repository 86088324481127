import groups from '../app/schemas/PermissionGroups';

import BundyClockReport from '../components/features/payroll/BundyClockReport';
import WorkerTimeSheetContainer from '../components/features/payroll/workerTimeSheet/WorkerTimeSheetContainer'

export default { 
	path: 'payroll',
	menuItem: { title: 'Payroll', icon: 'dollar' },
	authorize: [groups.ADMIN_STAFF_FULL],
	childRoutes: [
		{ 
			path: 'reports', 
			menuItem: { title: 'Reports', icon: 'line-chart' },
			component: BundyClockReport
		},
		{ 
			path: 'timesheets', 
			menuItem: { title: 'Timesheets', icon: 'clock-o' },
			component: WorkerTimeSheetContainer
		}
	]
};