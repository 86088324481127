import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTable from 'react-table';
import Icon from 'react-fontawesome';
import helper from './helper';

import { Checkbox } from '../../../common/inputs';

const propTypes = {
	data: PropTypes.array.isRequired,

	/** When click to edit the row */
	onClickEdit: PropTypes.func,

	/** When click to change the a status */
	onChangeStatus: PropTypes.func,

	pageSize: PropTypes.number
};

const defaultProps = {
	pageSize: 20
};

const WorkerTimeSheetList = props => {
	const handleEditTimeSheet = timeSheetData => { 
		return props.onClickEdit && props.onClickEdit(timeSheetData);
	};

	const handleChangeStatus = (timeSheetData, e) => {
		props.onChangeStatus && props.onChangeStatus(timeSheetData, e);
	};

	const columns = [
		// {
		// 	Header: '',
		// 	headerClassName: 'column-title',
		// 	columns: [
		// 		{
		// 			Header: '',
		// 			headerClassName: 'text-center',
		// 			id: 'edit',
		// 			width: 40,
		// 			accessor: d => d,
		// 			Cell: row => (
		// 				<span className="edit" onClick={() => handleEditTimeSheet(row.value)}>
		// 					<Icon name="edit" size="2x" />
		// 				</span>
		// 			)
		// 		}
		// 	]
		// },
		{
			Header: 'EMPLOYEE DETAILS',
			headerClassName: 'column-title',
			columns: [
				{
					Header: 'Company',
					accessor: 'company'
				}, 
				{
					Header: 'Employee Number',
					accessor: 'employeeNumber'
				},
				{
					Header: 'First Name',
					accessor: 'firstName',
				},
				{
					Header: 'Last Name',
					accessor: 'lastName',
					headerClassName: 'split-line',
					className: 'split-line',
				}
			]
		},
		{
			Header: 'ACTUAL TIME',
			headerClassName: 'column-title',
			columns: [
				{
					Header: 'Day',
					accessor: 'startTimeLocal',
					width: 40,
					Cell: row => moment(row.value).isValid() ? moment(row.value).format('ddd') : '-',
					getProps: (state, rowInfo) => ({
						className: rowInfo.original.hasHRAddedDataManually ? 'orange' : ''
					})
				},
				{
					Header: 'TimeZone',
					accessor: 'startTimeZoneNameIana',
					width: 100,
					Cell: row => row.value ? row.value.replace('/', ' ') : '-',
					getProps: (state, rowInfo) => ({
						className: rowInfo.original.hasHRAddedDataManually ? 'orange' : ''
					})
				},
				{
					Header: 'Start',
					accessor: 'startTimeLocal',
					width: 70,
					Cell: row => moment(row.value).isValid() ? moment(row.value).format('DD/MM/YY HH:mm') : '-',
					getProps: (state, rowInfo) => ({
						className: rowInfo.original.hasHRAddedDataManually ? 'orange' : ''
					})
				},
				{
					Header: 'TimeZone',
					accessor: 'finishTimeZoneNameIana',
					width: 100,
					Cell: row => row.value ? row.value.replace('/', ' ') : '-',
					getProps: (state, rowInfo) => {
						const { hasHRAddedDataManually, hasEmployeeAddedFinishTime } = rowInfo.original;
                        
						let className = '';
						if (hasEmployeeAddedFinishTime)
							className = 'red';
						else if (hasHRAddedDataManually)
							className = 'orange';

						return { className };
					}
				},
				{
					Header: 'Finish',
					accessor: 'finishTimeLocal',
					width: 70,
					Cell: row => moment(row.value).isValid() ? moment(row.value).format('DD/MM/YY HH:mm') : '-',
					getProps: (state, rowInfo) => {
						const { hasHRAddedDataManually, hasEmployeeAddedFinishTime } = rowInfo.original;
                        
						let className = '';
						if (hasEmployeeAddedFinishTime)
							className = 'red';
						else if (hasHRAddedDataManually)
							className = 'orange';

						return { className };
					}
				},
				{
					Header: 'Total',
					id: 'totalActualHours',
					headerClassName: 'split-line',
					className: 'split-line',
					width: 50,
					accessor: d => d,
					Cell: row => helper.calculateTotalTime(row.value.startTime, row.value.finishTime),
					getProps: (state, rowInfo) => ({
						className: rowInfo.original.hasHRAddedDataManually ? 'orange' : ''
					})
				},
			]
		},
		{
			Header: 'ADJUSTED TIME',
			headerClassName: 'column-title',
			columns: [
				{
					Header: 'Day',
					accessor: 'adjustedStartTimeLocal',
					width: 40,
					Cell: row => moment(row.value).isValid() ? moment(row.value).format('ddd') : '-'
				},
				{
					Header: 'Start',
					accessor: 'adjustedStartTimeLocal',
					width: 70,
					Cell: row => moment(row.value).isValid() ? moment(row.value).format('DD/MM/YY HH:mm') : '-'
				},
				{
					Header: 'Finish',
					accessor: 'adjustedFinishTimeLocal',
					width: 70,
					Cell: row => moment(row.value).isValid() ? moment(row.value).format('DD/MM/YY HH:mm') : '-'
				},
				{
					Header: 'Total',
					id: 'totalAdjustedHours',
					headerClassName: 'split-line',
					className: 'split-line',
					width: 50,
					accessor: d => d,
					Cell: row => {
						const { adjustedStartTimeLocal, startTimeZoneNameIana, adjustedFinishTimeLocal, finishTimeZoneNameIana } = row.value;
						if (!adjustedStartTimeLocal || !adjustedFinishTimeLocal)
							return '-';

						return  helper.calculateTotalTime(
							moment.tz(adjustedStartTimeLocal, startTimeZoneNameIana).utc(), 
							moment.tz(adjustedFinishTimeLocal, finishTimeZoneNameIana).utc()
						);
					}
				},
			]
		},
		{
			Header: 'STATUS',
			headerClassName: 'column-title',
			columns: [
				{
					Header: <Icon name="eye-slash" />,
					id: 'isHidden',
					accessor: d => d,
					width: 35,
					headerClassName: 'text-center',
					Cell: row => (
						<div onClick={(e) => e.stopPropagation()}>
							<Checkbox 
								id="isHidden"
								checked={row.value.isHidden} 
								onChange={e => handleChangeStatus(row.value, e)} />
						</div>
					)
				},
				{
					Header: <Icon name="check" />,
					id: 'isChecked',
					accessor: d => d,
					width: 35,
					headerClassName: 'text-center',
					Cell: row => (
						<div onClick={(e) => e.stopPropagation()}>
							<Checkbox 
								id="isChecked"
								checked={row.value.isChecked} 
								onChange={e => handleChangeStatus(row.value, e)} />
						</div>
					)
				}
			]
		}
	];

	return (
		<div className="worker-timesheet-list">
			<ReactTable
				getTrProps={(state, rowInfo) => {
					const { isChecked, isHidden } = rowInfo.original;

					let className = '';
					if (isChecked)
						className = 'green';
					else if (isHidden)
						className = 'grey';

					return { 
						className,
						onClick: () => handleEditTimeSheet(rowInfo.original)
					};
				}}
				className="-highlight"
				data={props.data}
				columns={columns}
				minRows={0}
				pageSize={props.pageSize}
			/>
		</div>
	);
};

WorkerTimeSheetList.propTypes = propTypes;
WorkerTimeSheetList.defaultProps = defaultProps;

export default WorkerTimeSheetList;