/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import MainLayout from './_MainLayout';
import { logout } from '../../app/config/auth.config';

class LoginError extends Component {
	constructor() {
		super();
		this.state = {
			disconnectTimerInSec: 45
		};
	}

	componentDidMount() {
		setInterval(() => {
			const { disconnectTimerInSec } = this.state;
			if (disconnectTimerInSec > 0)
				return this.setState({ disconnectTimerInSec: disconnectTimerInSec - 1 });

			logout();
		}, 1000);
	}

	render() {
		return (
			<MainLayout
				color="red"
			>
				<h3>Something wrong happened during the authentication process.</h3>
				<label>Please follow the steps below and try again: </label>
				<ul className="list text-left">
					<li>Check if the date and time of your device are correct and if it{"'"}s on automatic mode</li>
					<li>Clean your browser{"'"}s cache</li>
					<li>If you are using Safari, try to use Google Chrome</li>
					<li>If the error persists, contact IT support on 0423 548 722 immediately</li>
				</ul>
				<br />
				<b>You will be Signed Out in { this.state.disconnectTimerInSec } second(s).
				Or <a onClick={logout}>Click Here</a> to sign out now.</b>
			</MainLayout>
		);
	}
}

export { LoginError };