import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { unregister } from './registerServiceWorker';
import AppContainer from './components/AppContainer';
import store from './stores/redux';
import toastr from 'toastr';
import { redirectRequest, msalInstance } from './app/config/auth.config';
import { InteractionType } from '@azure/msal-browser';

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'admin-lte/dist/css/AdminLTE.min.css';
import 'admin-lte/dist/css/skins/skin-blue.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'animate.css/animate.min.css';
import 'toastr/build/toastr.min.css';
import './app/assets/css/dataTables.bootstrap.min.css';
import 'react-select/dist/react-select.min.css';
import './app/helpers/JsMethodsHelper';
import 'icheck/skins/square/blue.css';
import './app/styles/app.css';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';

// Load Google Maps
const googleMapsScript = document.createElement('script');
googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?v=3.54&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=geometry,drawing,places&language=en&callback=Function.prototype`;
document.head.appendChild(googleMapsScript);

window.$ = require('jquery');
window.jQuery = window.$;

require('signalr');
require('bootstrap');
require('admin-lte');
require('jquery-slimscroll');
require('jquery-validation');
require('./app/helpers/customValidation');
require('./app/assets/scripts/buttons.html5.min.js');
require('./app/assets/scripts/buttons.print.min.js');
require('./app/assets/scripts/datetime-moment.js');
require('icheck');

window._isMobile = window.innerWidth < 768;

// Toastr Options
toastr.options = {
	closeButton: true,
	positionClass: 'toast-bottom-right',
	preventDuplicates: true,
	progressBar: true,
	timeOut: '7000'
};

// Unregister service worker
unregister()

/** USED TO CREATE NEW COMPONENTS WITHOUT THE NEED OF LOADING THE ENTIRE APP */
const enableComponentsCreationMode = false;
/** ===================================================================== */

if (process.env.REACT_APP_ENVIRONMENT === 'production' || !enableComponentsCreationMode)
	ReactDOM.render((
		<MsalProvider instance={msalInstance}>
			<MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={redirectRequest}>
				<Provider store={store}>
					<AppContainer /> 
				</Provider>
			</MsalAuthenticationTemplate>
		</MsalProvider>
	), document.getElementById('root'));
else {
	const interval = setInterval(() => {
		if (window.google && window.google.maps) {
			clearInterval(interval);

			//***************************************************************************
			//** Import your components inside this IF using REQUIRE instead of IMPORT **
			//***************************************************************************
			// const Component = require('./components/features/roster/runs/RunContainer').default;
			
			// **************************************************************************
			// **************************************************************************

			// runWithAdal(authContext, () => {
			// 	ReactDOM.render((
			// 		<Provider store={store}>
			// 			<div style={{ margin: '15px' }}>
			// 				<Component />
			// 			</div>
			// 		</Provider>
			// 	), document.getElementById('root'));
			// });
		}
	}, 500);
}


