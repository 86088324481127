/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTable from 'react-table';
import Icon from 'react-fontawesome';
import 'react-table/react-table.css';
//import './style.scss';

const FaultReportList = props => {
	const handleSeeFaultReportDetails = (faultReportId) => { 
		return props.onViewFaultReportDetails && props.onViewFaultReportDetails(faultReportId);
	};

	const columns = [
		// {
		// 	Header: '',
		// 	accessor: 'id',
		// 	className: 'text-center',
		// 	sortable: false,
		// 	width: 60,
		// 	Cell: row => {
		// 		const faultReportId = row.value;
		// 		return (
		// 			<a className="cursor-pointer" onClick={() => { handleSeeFaultReportDetails(faultReportId); }}>
		// 				<Icon name="search" size="2x" />
		// 			</a>
		// 		);
		// 	}
		// },
		{
			Header: '#',
			width: 60,
			accessor: 'faultReportNumber'
		},
		{
			Header: 'Tracker No.',
			width: 80,
			accessor: 'serviceTrackerNumber',
			Cell: row => row.value || '-'
		},
		{
			Header: 'Vehicle',
			width: 100,
			accessor: 'reportedVehicleId'
		},
		{
			Header: 'Type',
			accessor: 'dtoFaultReportTypeName'
		},
		{
			Header: 'Opened On',
			accessor: 'reportedOnLocal',
			Cell: row => moment(row.value).format('DD/MM/YY HH:mm')
		},
		{
			Header: 'Last Update On',
			accessor: 'dtoLastUpdateOnLocal',
			Cell: row => moment(row.value).format('DD/MM/YY HH:mm')
		},
		{
			Header: 'Status',
			accessor: 'dtoStatusName'
		},
		{
			Header: 'Info',
			accessor: 'dtoStatusInfo',
			sortable: false,
			Cell: row =>row.value || '-',
		},
		{
			Header: 'Quick Fix',
			accessor: 'hadQuickFix',
			sortable: false,
			width: 70,
			Cell: row => row.value ? <Icon name="check" /> :  '-',
		}
	];

	return (
		<div className="fault-reports-list">
			<ReactTable
				// getTrProps={(state, rowInfo) => {
				//     const { colorClass } = helper.renderStatus(rowInfo.original);
				//     return {
				//         className: colorClass
				//     }
				// }}
				data={props.data}
				columns={columns}
				minRows={0}
				manual
				loading={props.isLoading}
				defaultPageSize={props.defaultPageSize}
				page={props.page}
				pageSize={props.pageSize}
				pages={props.totalPages}
				sorted={props.sorted}
				onFetchData={props.onFetchData}
				onPageChange={props.onPageChange}
				onPageSizeChange={props.onPageSizeChange}
				onSortedChange={props.onSortedChange}
				collapseOnDataChange={false}
				getTrProps={(state, rowInfo) => {
					return {
						onClick: () => { handleSeeFaultReportDetails(rowInfo.original.id) }
					}
				}}
			/>
		</div>
	);
};

FaultReportList.propTypes = {
	data: PropTypes.array.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired,
	sorted: PropTypes.arrayOf(
		PropTypes.shape({
			desc: PropTypes.bool.isRequired,
			id: PropTypes.string.isRequired,
		})
	),
	defaultPageSize: PropTypes.number,
	isLoading: PropTypes.bool.isRequired,
	onPageChange: PropTypes.func,
	onPageSizeChange: PropTypes.func,
	onSortedChange: PropTypes.func,
	onFetchData: PropTypes.func,
	onFaultReportViewDetails: PropTypes.func
};

export default FaultReportList;