import React from 'react';
import { MainContent } from '../../../common/layout';
import { FormGroup, Button } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { IError, ListRequestOptions, ListReturn } from '../../../../app/models/Application';
import { ErrorBox } from '../../../common/uiElements';
import CommunicationApi from '../../../../app/api/CommunicationApi';
import { ISmsMessageBulkListItem, SmsMessageBulkListFilter } from '../../../../app/models/Communication/SmsMessageBulkModel';
import SmsListFilter from './list/SmsListFilter';
import { SortingRule } from 'react-table';
import SmsList from './list';
import SmsModal from './details/SmsModal';

interface IState {
	listData: ListReturn<ISmsMessageBulkListItem>;
	listFilter: SmsMessageBulkListFilter,
	listOptions: ListRequestOptions,
	selectedSmsMessageBulkId?: number;
	selectedRowIndex?: number;
	showModal: boolean;
	isLoading: boolean;
	errorLoading?: IError;
}

class SmsContainer extends React.Component<{}, IState> {
	unmounted = false;
	state = {
		listData: new ListReturn<ISmsMessageBulkListItem>(),
		listFilter: new SmsMessageBulkListFilter(),
		listOptions: new ListRequestOptions(),
		selectedSmsMessageBulkId: undefined,
		selectedRowIndex: undefined,
		showModal: false,
		isLoading: false,
		errorLoading: undefined
	}

	componentDidMount() {
		this.fetchList();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchList = () => {
		this.setState({ isLoading: true, errorLoading: undefined });
		const { listFilter, listOptions } = this.state;
		CommunicationApi.getSmsMessagesBulkList(listFilter, listOptions)
		.then(listData => {
			if (this.unmounted) return;
			this.setState({ listData });
		})
		.catch(error => {
			if (this.unmounted) return;
			this.setState({ errorLoading: error });
		})
		.finally(() => {
			if (this.unmounted) return;
			this.setState({ isLoading: false });
		});
	}

	showModal = () => {
		this.setState({ showModal: true });
	}

	closeModal = () => {
		this.setState({ 
			showModal: false,
			selectedSmsMessageBulkId: undefined
		});
	}

	handleChangePeriodFilter = (startDate: string, finishDate: string) => {
		this.setState({
			listFilter: {
				...this.state.listFilter,
				startDate,
				finishDate
			}
		}, this.fetchList);
	}

	handleChangeSubjectFilter = (subject: string) => {
		this.setState({
			listFilter: {
				...this.state.listFilter,
				subject
			}
		});
	}

	handleChangeStatusFilter = (isCompleted?: boolean) => {
		this.setState({
			listFilter: {
				...this.state.listFilter,
				isCompleted
			}
		}, this.fetchList);
	}

	handleChangePage = (page: number) => {
		this.setState({ 
			listOptions: { 
				...this.state.listOptions, 
				page 
			}
		}, this.fetchList);
	}

	handleChangePageSize = (pageSize: number) => {
		const { listOptions } = this.state;
		this.setState({ 
			listOptions: { 
				...listOptions, 
				pageSize,
				page: 0
			}
		}, this.fetchList);
	}

	handleChangeSorted = (sorted: SortingRule[]) => {
		this.setState({ 
			listOptions: { 
				...this.state.listOptions, 
				sorted 
			}
		}, this.fetchList);
	}	

	handleSelectRow = (selectedSmsMessageBulkId: number) => {
		this.setState({ selectedSmsMessageBulkId: selectedSmsMessageBulkId }, this.showModal);
	}

	handleSaveComplete = (smsMessageBulkId?: number) => {
		const { selectedSmsMessageBulkId } = this.state;
		this.setState({ selectedSmsMessageBulkId: selectedSmsMessageBulkId || smsMessageBulkId }, this.fetchList);
	}

	render() {
		const { state } = this;

		return (
			<MainContent title="Communication - SMS">
				{
					state.showModal && (
						<SmsModal 
							smsMessageBulkId={state.selectedSmsMessageBulkId}
							onClose={this.closeModal}
							onCompleteSaving={this.handleSaveComplete}
						/>
					)
				}
				<FormGroup>
					<Button
						block
						bsStyle="success"
						onClick={this.showModal}
					>
						<FontAwesome name="plus" /> Send New Message
					</Button>
				</FormGroup>
				<SmsListFilter 
					{...state.listFilter}
					onChangePeriod={this.handleChangePeriodFilter}
					onChangeStatus={this.handleChangeStatusFilter}
					onChangeSubject={this.handleChangeSubjectFilter}
					onPressEnter={this.fetchList}
				/>
				<FormGroup>
					<Button block bsStyle="info" onClick={this.fetchList}>
						<FontAwesome name="refresh" /> Refresh
					</Button>
				</FormGroup>
				{
					state.errorLoading ? (
						<ErrorBox error={state.errorLoading} retryFunc={this.fetchList} />
					) : (
						<SmsList
							{...state.listData}
							page={state.listOptions.page}
							pageSize={state.listOptions.pageSize}
							onSortedChange={this.handleChangeSorted}
							onPageChange={this.handleChangePage}
							onPageSizeChange={this.handleChangePageSize}
							isLoading={state.isLoading}
							onSelectRow={this.handleSelectRow}
						/>
					)
				}
			</MainContent>
		);
	}
}

export default SmsContainer;