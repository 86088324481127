import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const propTypes = {
	afterCloseFn: PropTypes.func,
	afterOpenFn: PropTypes.func,
	afterSaveFn: PropTypes.func,
	beforeOpenFn: PropTypes.func,
	beforeSaveFn: PropTypes.func,
	closeButtonColor: PropTypes.string,
	closeButtonText: PropTypes.string,
	entryElement: PropTypes.element,
	exitElement: PropTypes.element,
	footerButtons: PropTypes.array,
	isOpen: PropTypes.bool,
	saveButtonColor: PropTypes.string,
	saveButtonText: PropTypes.string,
	showCloseButton: PropTypes.bool,
	showSaveButton: PropTypes.bool,
	size: PropTypes.string,
	tabIndex: PropTypes.number,
	title: PropTypes.string,
};

class Modal extends Component {
	constructor() {
		super();

		this.state = {
			isOpen: false
		};

		this.closeModal = this.closeModal.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.openModal = this.openModal.bind(this);
	}

	closeModal() {
		var { afterCloseFn } = this.props;
		this.setState({ isOpen: false });

		if (afterCloseFn)
			afterCloseFn();
	}

	handleSave() {
		var { afterSaveFn, beforeSaveFn } = this.props;
		if (beforeSaveFn && !beforeSaveFn())
			return false;

		if (afterSaveFn)
			afterSaveFn();

		this.closeModal();
	}

	openModal() {
		var { beforeOpenFn, afterOpenFn } = this.props;

		if (beforeOpenFn)
			beforeOpenFn();

		this.setState({ isOpen: true });

		if (afterOpenFn)
			afterOpenFn();
	}

	render() {
		var s = this.state;

		var { 
			closeButtonColor = 'danger',
			closeButtonText = 'Close',
			saveButtonColor = 'success',
			saveButtonText = 'Save',
			entryElement,
			size = '',
			title,
			showCloseButton,
			showSaveButton
		} = this.props;

		switch(size) {
		case 'large':
			size = 'modal-lg';
			break;
		case 'small':
			size = 'modal-sm';
			break;
		default:
			break;
		}

		return (
			<div>
				{ React.cloneElement(entryElement, { onClick: this.openModal }) }
				{
					s.isOpen && (
						<div>
							<div className={('modal-dialog ' + size)} role="document">
								<div className="modal-content">
									<div className="modal-header">
										<Button
											className="close"
											onClick={this.closeModal}
											aria-label="Close"
										>
											<span aria-hidden="true">&times;</span>
										</Button>
										<h4 className="modal-title">{ title }</h4>
									</div>
									<div className="modal-body">
										{ this.props.children }
									</div>
									<div className="modal-footer">
										{ showSaveButton && (<Button bsStyle={saveButtonColor} onClick={this.handleSave}>{ saveButtonText }</Button>) }
										{ showCloseButton && (<Button bsStyle={closeButtonColor} onClick={this.closeModal}>{ closeButtonText }</Button>) }
									</div>
								</div>
							</div>
							<div onClick={this.closeModal} className="full-overlay"></div>
						</div>
					)
				}
			</div>
		);
	}
}

Modal.propTypes = propTypes;

export { Modal };
