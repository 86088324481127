import moment from "moment";
import { ISmsMessageBulkListItem, SmsMessageBulkFormClass } from "../../../../app/models/Communication/SmsMessageBulkModel";

export const getSmsStatus = (data: SmsMessageBulkFormClass | ISmsMessageBulkListItem) => {
	let color = 'primary';
	let text = `Processing (${Math.round((data.totalDelivered + data.totalFailed) / data.totalRecipients * 100)}%)`;

	if (data.isDeleted) {
		text = 'Deleted';
		color = 'danger';
	}
	else if (data.isCompleted) {
		const successfulPercentage = Math.round((data.totalDelivered / data.totalSent) * 100);
		text = `Completed (${successfulPercentage}% delivered)`;
		color = 'success';

		// if more than 50% failed, then show as red
		if (data.totalFailed > 0 && data.totalFailed / data.totalSent >= 0.5)
			color = 'danger';
	}
	else if (data.scheduledToSendAt && moment.utc(data.scheduledToSendAt).isAfter(moment.utc())) {
		text = 'Scheduled';
		color = 'warning';
	}

	return { colorClass: color, text}
}