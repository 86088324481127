/* eslint-disable react/prop-types */
import React from 'react';
import { ConfirmModal } from '../../components/common/layout';
import { confirmAlert } from 'react-confirm-alert';

/**
 * @param {String|Object} content 
 * @param {Function=} proceed
 * @param {String=} textColor warning, danger, success, info, primary
 */
export default (content, proceed, textColor) => {
	confirmAlert({
		customUI: (({ onClose }) => (
			<ConfirmModal
				proceedOnly
				textColor={textColor}
				proceed={() => { proceed && proceed(); onClose(); }}
			>
				{ content }
			</ConfirmModal>
		))
	});
};