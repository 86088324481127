import React from 'react';

interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
	block?: boolean;
	text?: string;
	bold?: boolean;
	noMargin?: boolean;
	onChange: (e: React.ChangeEvent<HTMLInputElement>, value?: string | number | string[] | undefined) => void;
}

export const Checkbox: React.FC<IProps> = props => {

	return (
		<label className="checkbox-custom" style={{ display: props.block ? 'block' : '' }}>
			<input 
				type="checkbox"
				id={props.id} 
				value={props.value} 
				checked={props.checked} 
				disabled={props.disabled}
				onChange={e => props.onChange(e, props.value)} 
			/>
			{ props.text && <span>{ props.bold ? <b>{props.text}</b> : props.text }</span> }
			{ props.children }
		</label>
	)
}