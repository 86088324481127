import React from 'react';
import { Row, FormControl } from 'react-bootstrap';
import { DatePeriodInput, RadioButtonList } from '../../../../common/inputs';
import { ContentBox, FieldGroup } from '../../../../common/uiElements';
import moment, { Moment } from 'moment';
import { SmsMessageBulkListFilter } from '../../../../../app/models/Communication/SmsMessageBulkModel';

interface IProps extends SmsMessageBulkListFilter {
	onChangePeriod: (startDate: string, finishDate: string) => void;
	onChangeSubject: (subject: string) => void;
	onChangeStatus: (isCompleted?: boolean) => void;
	onPressEnter: () => void;
}

const SmsListFilter: React.FC<IProps> = props => {	
	const handleChangeFilterStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.onChangeStatus(e.target.value === 'true' ? true : e.target.value === 'false' ? false : undefined)
	};

	const handleChangeSubject = (e: React.ChangeEvent<HTMLInputElement & FormControl>) => {
		props.onChangeSubject(e.target.value);
	};

	const handleChangePeriod = (pStartDate: Moment, pFinishDate: Moment) => {
		const startDate = pStartDate.format('YYYY-MM-DD');
		const finishDate = pFinishDate.format('YYYY-MM-DD');
		props.onChangePeriod(startDate, finishDate);
	};

	return (
		<ContentBox title="Filter">
			<Row>
				<FieldGroup label="Creation Date" sm={12} md={3} lg={2}>
					<DatePeriodInput 
						noRedux
						showDefaultRanges
						showHours={false}
						format="DD/MM/YYYY"
						startDate={moment(props.startDate)}
						endDate={moment(props.finishDate)}
						onChange={handleChangePeriod}
					/>
				</FieldGroup>
				<FieldGroup label="Subject" sm={12} md={4} lg={2}>
					<FormControl 
						value={props.subject}
						onChange={handleChangeSubject}
						onKeyPress={e => e.key === 'Enter' && props.onPressEnter()}
					/>
				</FieldGroup>
				<FieldGroup label="Status" sm={12} md={4}>
					<RadioButtonList 
						value={props.isCompleted === undefined ? '' : props.isCompleted.toString()}
						items={[
							{ text: 'All', value: '' },
							{ text: 'Completed', value: 'true' },
							{ text: 'Processing', value: 'false' }
						]}
						onChange={handleChangeFilterStatus}
					/>
				</FieldGroup>
			</Row>
		</ContentBox>
	);
}

export default SmsListFilter;