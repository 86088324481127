import React, { Fragment, ReactNode } from 'react';
import Icon from 'react-fontawesome';


interface IProps {
	title?: string;
	italicTitle?: boolean;
	text?: string;
	color?: 'danger' | 'info' | 'warning' | 'success';
	icon?: string;
	children?: ReactNode;
	margin?: string | number;
	padding?: string | number;
	height?: string | number;
	style?: React.CSSProperties;
}

const Callout: React.FC<IProps> = ({ title, text, color = 'danger', icon, children, margin, padding, height, style, italicTitle }) => {
	return (
		<div 
			className={('callout callout-' + color)}
			style={{ ...style, margin, padding, height }}
		>
			{ 
				title && (
					<h4 style={{ marginBottom: !text ? 0 : 10 }} className={italicTitle ? 'text-italic' : ''}>
						{ icon && <Fragment><Icon name={icon} />&nbsp;&nbsp;</Fragment> }{ title }
					</h4>
				) 
			}
			{ text && <p>{ text }</p> }
			{ children && children }
		</div>
	);
};

export { Callout };