import React from 'react';
import weekDays from '../../../app/store/weekDays';
import { FormControl, FormControlProps } from 'react-bootstrap';

const WeekDays = (props: FormControlProps) => (
	<FormControl
		{...props}
		componentClass="select"
	>
		<option value="">--- SELECT ---</option>
		{
			weekDays.map((weekDay, index) => (
				<option key={weekDay} value={index + 1}>
					{weekDay}
				</option>
			))
		}
	</FormControl>
);

export { WeekDays };