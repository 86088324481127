import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { Modal, Row, Col, FormGroup, FormControl, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

import { SupervisorsList } from '../lists';
import { Callout, Form } from '../uiElements';

const propTypes = {
	show: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

class QuickPinNeededModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			supervisorsList: [],
			wrongQuickPin: false,
			supervisorId:  ''
		};

		this.pinValidations = {
			supervisor: 'required'
		};

		this.handleOnLoadSupervisorsList = (supervisorsList) => {
			this.setState({ supervisorsList });
		};

		this.handleChangeSupervisor = (e) => {
			const { value } = e.target;
		
			localStorage.setItem('quickPinLastSupervisorId', value);
			this.setState({
				supervisorId: value
			});
		};

		/**
	* Submit Quick PIN and Save
	*/
		this.handleSubmit = () => {
			if (window.$(this.pinForm).find('form').valid()) {
				let quickPin = this.quickPin.value;
				let { supervisorId } = this.state;

				if (!this.isQuickPinCorrect(supervisorId, quickPin))
					return this.setState({ wrongQuickPin: true });
				
				this.setState({ 
					wrongQuickPin: false
				}, () => this.props.onSubmit(supervisorId));
			}
		};

		/**
	* When closes the modal
	*/
		this.handleClose = () => {
			this.setState({ 
				supervisorId: '', 
				wrongQuickPin: false 
			}, this.props.onClose);
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const lastSupervisorId = localStorage.getItem('quickPinLastSupervisorId');
		if (lastSupervisorId && (!prevState.supervisorId || prevState.supervisorId !== lastSupervisorId))
			this.setState({ supervisorId: lastSupervisorId });
	}

	/**
	 * Check if the Quick PIN is correct
	 * @param {string} supervisorId Supervisor ID
	 * @param {string} quickPin PIN Number
	 */
	isQuickPinCorrect = (supervisorId, quickPin) => {
		const supervisor = this.state.supervisorsList.find(s => s.id === supervisorId);
		if (!supervisor)
			throw new Error('The Supervisor wasn\'t found on the list passed in the props');
		else
			return supervisor.quickPin === quickPin;
	}

	render() {
		const s = this.state;
		const p = this.props;

		return (
			<Modal show={p.show} bsSize="small" onHide={this.handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>
							Quick PIN is required
							&nbsp;
						<OverlayTrigger 
							placement="bottom" 
							overlay={
								<Tooltip id="tooltip">
											Supervisors need to have a quick PIN for some tasks when they are using a shared account.<br /><br />
											If you are a supervisor and your name is not on the list below, you probably do not have a Quick PIN registered.<br/>
											Sign In to your account, you will be asked to register it, 
											if not, go to your profile page and click on the "Set my Quick PIN" button.
								</Tooltip>
							}
						>
							<Icon name="question-circle" />
						</OverlayTrigger>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<div ref={ref => this.pinForm = ref}>
							<Form validations={this.pinValidations} onSubmit={this.handleSubmit}>
								<Row>
									<Col xs={12}>
										<FormGroup>
											<label>Who is the responsible for this action?</label>
											<SupervisorsList 
												id="supervisor"
												value={this.state.supervisorId}
												onLoadList={this.handleOnLoadSupervisorsList}
												onChange={this.handleChangeSupervisor}
											/>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col xs={6}>
										<FormGroup>
											<FormControl 
												inputRef={ref => this.quickPin = ref} 
												autoFocus={s.supervisorId ? true : false}
												id="quickPin"
												type="password" 
												maxLength="4"
												placeholder="Quick PIN"
											/>
										</FormGroup>	
									</Col>
									<Col xs={6}>
										<FormGroup>
											<Button 
												block
												type="submit"
												bsStyle="success"
											>
													Submit
											</Button>
										</FormGroup>	
									</Col>
								</Row>
								{
									s.wrongQuickPin && (
										<Callout text="The Quick PIN is incorrect" />
									)
								}
							</Form>                                
						</div>
					</div>
				</Modal.Body>
			</Modal>
		);
	}
}

QuickPinNeededModal.propTypes = propTypes;

export { QuickPinNeededModal };