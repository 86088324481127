/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import { Link } from 'react-router';
import omit from 'lodash.omit';
import Icon from 'react-fontawesome';

import WorkerDefaultData from '../../../../app/schemas/worker/WorkerDefaultData.json';
import WorkerApi from '../../../../app/api/WorkerApi';
import { MainContent } from '../../../common/layout';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';

// Forms
import WorkerStatus from './forms/WorkerStatus';
import WorkerDetails from './forms/WorkerDetails';
import WorkerSuperFunds from './forms/WorkerSuperFunds';
import WorkerDocuments from './forms/WorkerDocuments';
import WorkerDriverLicences from './forms/WorkerDriverLicences';

import { MultipleValuesInput } from '../../../common/inputs';
import { ContentBox, Callout, Loader } from '../../../common/uiElements';
import WorkerAvailability from './forms/WorkerAvailability';
import { WorkerAvailabilityPUT_POST } from '../../../../app/models/Worker';

// Helper to get the first data from components
var dataHelper = [];

class WorkersNew extends Component {
	constructor() {
		super();
		const defaultData = JSON.parse(JSON.stringify(WorkerDefaultData));
		
		this.state = {
			activeStep: 0,
			completedSteps: [],
			currentStep: 0,
			data: defaultData,
			endData: {
				emailsToSend: [],
				username: '',
				password: '',
				dataSentSuccessfully: false
			},
			error: null,
			emailError: null,
			isSaved: false,
			isSaving: false,
			isSendingEmail: false,
			skippedSteps: []
		};
	}
	
	componentDidMount() {
		var newData = {};
		for (var i in dataHelper) {
			var id = dataHelper[i].id;
			var itemData = dataHelper[i].data;

			newData[id] = itemData;
		}
		var { data  } = this.state;
		return this.setState({ 
			data: { 
				...data,
				...newData 
			} });
	}

	handleChangeData = (newData, id) => {
		let { data } = this.state;
		if (id === 'WorkerDetails')
			this.setState({ 
				data: { 
					...data,
					...newData
				} 
			});	
		else
			this.setState({ 
				data: { 
					...data,
					[id]: newData
				} 
			});	
	}

	handleChangeWorkerAvailability = (fieldId, value) => {
		var { data } = this.state;
		this.setState({ 
			data: { 
				...data,
				WorkerAvailability: {
					...data.WorkerAvailability,
					[fieldId]: value
				}
			}
		});
	}

	handleAvailabilityClearDayOfWeekData = (dayOfWeek) => {
		const { data } = this.state;
		let workerAvailabilityNewData = {};
		if (dayOfWeek === 1) workerAvailabilityNewData = { mondayStartTime: '', mondayOrdinaryHours: '', mondayStartingPlaceId: '', isMondayOvertime: false };
		if (dayOfWeek === 2) workerAvailabilityNewData = { tuesdayStartTime: '', tuesdayOrdinaryHours: '', tuesdayStartingPlaceId: '', isTuesdayOvertime: false };
		if (dayOfWeek === 3) workerAvailabilityNewData = { wednesdayStartTime: '', wednesdayOrdinaryHours: '', wednesdayStartingPlaceId: '', isWednesdayOvertime: false };
		if (dayOfWeek === 4) workerAvailabilityNewData = { thursdayStartTime: '', thursdayOrdinaryHours: '', thursdayStartingPlaceId: '', isThursdayOvertime: false };
		if (dayOfWeek === 5) workerAvailabilityNewData = { fridayStartTime: '', fridayOrdinaryHours: '', fridayStartingPlaceId: '', isFridayOvertime: false };
		if (dayOfWeek === 6) workerAvailabilityNewData = { saturdayStartTime: '', saturdayOrdinaryHours: '', saturdayStartingPlaceId: '', isSaturdayOvertime: false };
		if (dayOfWeek === 7) workerAvailabilityNewData = { sundayStartTime: '', sundayOrdinaryHours: '', sundayStartingPlaceId: '', isSundayOvertime: false };

		this.setState({ 
			data: { 
				...data,
				WorkerAvailability: {
					...data.WorkerAvailability,
					...workerAvailabilityNewData
				}
			}
		});
	}

	isCurrentStep = (step) => {
		return this.state.currentStep == step;
	}

	isStepCompleted = (step) => {
		return this.state.completedSteps.indexOf(step) !== -1;
	}

	isStepSkipped = (step) => {
		return this.state.skippedSteps.indexOf(step) !== -1;
	}

	isActiveStep = (step) => {
		return this.state.activeStep == step;
	}

	isValid = () => {
		window.$('.wizard-steps li.selected').removeClass('invalid');
		var { activeStep } = this.state;

		if (window.$('#tfn').hasClass('error'))
			return false;

		var form = window.$('form')[activeStep];
		
		if (!form || typeof form == 'undefined')
			return true;

		var valid = window.$(form).valid();

		if (!valid) {
			window.$('.wizard-steps li.active').addClass('invalid');
		}

		return valid;
	}

	checkFileNotAdded = () => {
		let documentsForm = this.documents;
		let addFileButton = window.$(documentsForm).find('button');
		let fileNotAdded = false;

		if (!window.$(documentsForm).hasClass('continueWithoutFiles') && window.$(documentsForm).find('input').length > 0) {
			fileNotAdded = window.$(documentsForm).find('input[value!=""]:not(input[type=file]), select > option[value!=""]:selected').length > 0 || window.$(documentsForm).find('input[type=file]')[0].files.length > 0;
			
			if (fileNotAdded){
				var popoverContent = 'You filled a file data but didn\'t add it to the files list. If you want to add the file, click on this button or just continue</a>';
				window.$(addFileButton).popover({container: 'body', content: popoverContent, trigger: 'hover', placement: 'bottom', 'html': true}).popover('show');
				window.$(documentsForm).addClass('continueWithoutFiles');
				

				window.$(addFileButton).on('hidden.bs.popover', function () {
					setTimeout(function () {
						window.$(addFileButton).popover('destroy');
					}, 100);
				});
			}
		} else {
			window.$(addFileButton).popover('destroy');
			window.$(documentsForm).removeClass('continueWithoutFiles');
		}

		return fileNotAdded;
	}

	onChangeStep = (step) => {		
		var { currentStep, activeStep } = this.state;

		if (step > activeStep) {
			if (this.isValid() && step <= currentStep)
				this.setState({ activeStep: step });
		} else if ((step < activeStep) || this.isValid()) {
			this.setState({ activeStep: step });
		}			
	}

	onSkipStep = () => {
		var { currentStep, activeStep, completedSteps, skippedSteps } = this.state;

		if (!this.isStepSkipped(activeStep)) {
			skippedSteps.push(activeStep);
			var index = completedSteps.indexOf(activeStep);
			if (index != -1)
				completedSteps.splice(index, 1);
		}

		if (currentStep == activeStep)
			this.setState({ currentStep: currentStep + 1 });

		this.setState({
			activeStep: activeStep + 1,
			skippedSteps
		});

		return this.onStepChanged();
	}

	onNextStep = () => {
		let isDocumentsStep = window.$(this.documents).parent().hasClass('active');
		if (isDocumentsStep && this.checkFileNotAdded()) {
			return;
		}

		if(this.isValid()) {
			var { completedSteps, currentStep, activeStep, skippedSteps } = this.state;

			if (!this.isStepCompleted(activeStep)) {
				completedSteps.push(activeStep);
				var index = skippedSteps.indexOf(activeStep);
				if (index != -1)
					skippedSteps.splice(index, 1);
			}

			if (currentStep == activeStep)
				this.setState({ currentStep: currentStep + 1 });

			this.setState({
				activeStep: activeStep + 1,
				completedSteps
			});

			return this.onStepChanged();
		}
	}

	onPreviousStep = () => {
		return this.setState({ activeStep: this.state.activeStep -1 });
	}

	onStepChanged = () => {
		var { activeStep } = this.state;
		var form = window.$('form')[activeStep];
		
		if (!form || window.$(form).validate)
			return;

		const $validate = window.$('form')[activeStep].validate;
		return $validate && $validate().resetForm();
	}

	handleAddEmailToSend = (emailsToSend) => {
		return this.setState({ 
			endData: {
				...this.state.endData,
				emailsToSend 
			}
		});
	}

	onSave = () => {
		let userData = window.$.extend(true, {}, this.state.data);
		
		// Skipped Steps
		let skippedSteps = this.state.skippedSteps;

		// Worker Details without other objects
		let WorkerDetails = { ...omit(userData, ['photo', 'addedDocuments', 'WorkerDocuments', 'WorkerDriverLicences', 'WorkerStatus', 'WorkerSuperFunds', 'WorkerAvailability']) };

		// Super Funds and Worker Status
		var { WorkerSuperFunds, WorkerStatus } = userData;

		const WorkerAvailability = new WorkerAvailabilityPUT_POST(userData.WorkerAvailability);
		
		// Documents
		let WorkerDocuments = userData.WorkerDocuments;

		// Driver Licences
		let WorkerDriverLicences = userData.WorkerDriverLicences;
		
		// Attached files to send through the API
		let attachedFiles = [];

		// Check if there is photo
		if (userData.photo) {
			WorkerDetails.photoFileIndex = attachedFiles.length;
			attachedFiles.push(userData.photo);
		}

		// Check if the Documents step (3) was skipped and there are documents
		if (skippedSteps.indexOf(3) === -1) {
			// Check if there are documents to add
			for (let i = 0; i < WorkerDocuments.length; i++) {
				let documentObject = WorkerDocuments[i];
				
				documentObject.dtoFileIndex = attachedFiles.length;
				attachedFiles.push(documentObject.file);

				delete documentObject.file;
			}
		}

		// Check if the Driver Licence step (4) was skipped and there are documents
		for (let i = 0; i < WorkerDriverLicences.length; i++) {
			let driverLicenceObject = WorkerDriverLicences[i];
			
			// Front File
			if (typeof driverLicenceObject.cardFileFront === 'object') {
				driverLicenceObject.dtoFrontFileIndex = attachedFiles.length;

				// Add the front file to the attacheds files
				attachedFiles.push(driverLicenceObject.cardFileFront);

				//Delete the front file from the object details
				delete driverLicenceObject.cardFileFront;
			}

			// Back File
			if (typeof driverLicenceObject.cardFileBack === 'object') {
				driverLicenceObject.dtoBackFileIndex = attachedFiles.length;

				// Add the back file to the attacheds files
				attachedFiles.push(driverLicenceObject.cardFileBack);

				//Delete the back file from the object details
				delete driverLicenceObject.cardFileBack;
			}
		}

		var dataToSend = { 
			...WorkerDetails,
			WorkerAvailability,
			WorkerSuperFunds,
			WorkerDocuments,
			WorkerDriverLicences,
			WorkerStatus,
			attachedFiles
		};

		this.setState({ isSaving: true, error: null });
		WorkerApi.saveWorkerData(dataToSend)
			.then(response => {
				if (this.unmounted) return;

				var { endData } = this.state;
				return this.setState({ 
					isSaved: true, 
					endData: { 
						...endData,
						...response.extraData
					}
				});
			})
			.catch(error => {
				return this.setState({ isSaving: false, error });
			});

	}

	onSendEmail = () => {
		const { emailsToSend, username, password } = this.state.endData;

		if (emailsToSend.length === 0)
			return false;
			
		let dataToSend = {
			emails: emailsToSend,
			username,
			password
		};

		this.setState({ isSendingEmail: true, emailError: null });
		WorkerApi.sendUserAccountByEmail(dataToSend)
			.then(successfullySent => {
				if (this.unmounted) return;

				if (successfullySent)
					return this.setState({ isEmailSent: true });
				else
					return this.setState({ isSendingEmail: false, emailError: 'It was not possible to send the email.' });	
			
			})
			.catch(error => {
				return this.setState({ isSendingEmail: false, emailError: error });
			});
	}

	renderStepClass = (step) => {
		var cssClass = '';

		if (this.isActiveStep(step))
			cssClass += 'active ';

		if (this.isCurrentStep(step))
			cssClass += 'current';
		else if (this.isStepCompleted(step))
			cssClass += 'completed';
		else if (this.isStepSkipped(step))
			cssClass += 'skipped';
		
		return cssClass;
	}

	render() {
		var s = this.state;
		let steps = [ 
		{ 
			id: 'role',
			title: 'Role', 
			icon: 'tasks', 
			notSkippable: true, 
			content: (
				<ContentBox title="Role" color="primary">
					<WorkerStatus id="WorkerStatus" data={s.data.WorkerStatus} onChange={this.handleChangeData} />
				</ContentBox>
			)
		}, 
		{ 
			id: 'availability',
			title: 'Availability', 
			icon: 'clock-o', 
			notSkippable: true, 
			content: (
				<ContentBox title="Availability" color="primary">
					<WorkerAvailability 
						{ ...s.data.WorkerAvailability } 
						id="WorkerAvailability" 
						onChange={this.handleChangeWorkerAvailability}
						onClearDayOfWeekData={this.handleAvailabilityClearDayOfWeekData}
						onCheckMonFri={this.handleCheckWorkerAvailabilityMonFri}  
					/>
				</ContentBox>
			)
		},
		{ 
			id: 'details',
			title: 'Details', 
			icon: 'user', 
			notSkippable: true, 
			content: (
				<ContentBox title="Details" color="primary">
					<WorkerDetails id="WorkerDetails" data={omit(s.data, ['WorkerDocuments', 'WorkerDriverLicences', 'WorkerStatus', 'WorkerSuperFunds'])} 
						onChange={this.handleChangeData} />
				</ContentBox>
			)
		}, {
			id: 'superFund',
			title: 'Super Fund', 
			icon: 'usd', 
			content: (
				<ContentBox title="SuperFund Details" color="primary">
					<WorkerSuperFunds id="WorkerSuperFunds" data={s.data.WorkerSuperFunds} onChange={this.handleChangeData} />
				</ContentBox>
			)
		}, {
			id: 'documents',
			title: 'Documents', 
			icon: 'file', 
			content: (
				<ContentBox title="Documents" color="primary">
					<WorkerDocuments id="WorkerDocuments" data={s.data.WorkerDocuments} onChange={this.handleChangeData} />
				</ContentBox>
			)
		}, {
			id: 'driverLicence',
			title: 'Driver\'s Licence', 
			icon: 'drivers-license', 
			content: (
				<ContentBox title="Driver Licence" color="primary">
					<WorkerDriverLicences id="WorkerDriverLicences" data={s.data.WorkerDriverLicences} onChange={this.handleChangeData} />
				</ContentBox>
			)
		}];

		return (
			<MainContent title="Add New Worker" subtitle="">
				<Row>
					<Col xs={12} sm={4} lg={3}>
						<FormGroup>
							<Link className="btn btn-block btn-danger" to="/management/workers">
								<Icon name="arrow-left" /> Back to Workers List
							</Link>
						</FormGroup>
					</Col>
				</Row>
				{
					s.error && (
						<Callout title={s.error.message}>
							{
								s.error.list && s.error.list.map((err, index) => {
									return (<span key={index}>{err}<br/></span>);
								})
							}
							<a onClick={this.onSave}><b><u>Click here to try again</u></b></a>
						</Callout>
					)
				}
				{
					!s.isSaving ? (
						<Row>
							<Col md={4} lg={3}>
								<ul className="wizard-steps">
									{
										steps.map((step, index) => {
											return (
												<li key={index} className={this.renderStepClass(index)} title={step.title} onClick={() => this.onChangeStep(index)}>
													<a><Icon name={step.icon} size="2x" /> <span className="hidden-xs hidden-sm">{step.title}</span></a>
												</li>
											);
										})
									}
									<li className={this.renderStepClass(steps.length)} title="Finish" onClick={() => this.onChangeStep(steps.length)}>
										<a><Icon name="check" size="2x" /> <span className="hidden-xs hidden-sm">Finish</span></a>
									</li>
								</ul>
							</Col>
							<Col md={8} lg={9}>

								{
									steps.map((step, index) => {
										return (
											<div key={index} className={('wizard-content ' + this.renderStepClass(index))}>
												<div ref={step.id}>
													{ step.content }
												</div>
												<ul className="wizard-buttons">
													{ 
														!step.notSkippable && (
															<li>
																<Button bsStyle="default" onClick={this.onSkipStep}>
																	Skip <Icon name="angle-double-right" />
																</Button>
															</li>
														) 
													}
													{ 
														index > 0 && (
															<li>
																<Button bsStyle="warning" onClick={this.onPreviousStep}>
																	<Icon name="arrow-left" /> Previous
																</Button>
															</li>
														) 
													}
													{ 
														index < steps.length && (
															<li>
																<Button bsStyle="success" onClick={this.onNextStep}>
																	Next <Icon name="arrow-right" />
																</Button>
															</li>
														) 
													}
												</ul>
											</div>
										);
									})
								}
								<div className={('wizard-content ' + this.renderStepClass(steps.length))}>
									<ContentBox title="Finish" color="primary">								
										<h3>Complete the registration clicking on the button below:</h3>
										<Button block bsSize="lg" bsStyle="success" onClick={this.onSave}>
											<Icon name="save" /> Finish & Save
										</Button>
									</ContentBox>
									<ul className="wizard-buttons">
										<li>
											<Button bsStyle="warning" onClick={this.onPreviousStep}>
												<Icon name="arrow-left" /> Previous
											</Button>
										</li>
									</ul>
								</div>
							</Col>
						</Row>
					) : (
						s.isSaved ? (
							<div>
								<ContentBox title="Success" color="success">
									<Col xs={12}>
										<h4 className="text-center text-success">
											<Icon name="check-circle" size="3x" />
										</h4>
										<h4 className="text-center text-success">
											The user was successfully created.
										</h4>
									</Col>
								</ContentBox>
								{
									s.endData.dataSentSuccessfully ? (
										<Callout color="info">
											<b>The Login and Password were already sent to the worker{"'"}s email address (<u>{ s.data.email }</u>) and/or his mobile number.</b>
										</Callout>
									) : (
										<div>
											<Callout>
												<b>{"The Login and password could not be sent to the worker's email address or mobile phone."}</b>
											</Callout>
											<ContentBox title="Login Details" color="primary">
												<Row>
													<Col xs={12}>
														<h4><u>Please, give the Login and Password below to the worker:</u></h4>
													</Col>
													<Col xs={12} sm={6} md={5} lg={3}>
														<FormGroup>
															<label>Username</label>
															<p>{ s.endData.username } </p>
														</FormGroup>
													</Col>
													<Col xs={12} sm={6} md={5} lg={3}>
														<FormGroup>
															<label>Password</label>
															<p>{ s.endData.password }</p>
														</FormGroup>
													</Col>
												</Row>
												<hr />
												{
													s.isSendingEmail ? (
														s.isEmailSent ? (
															<div>
																<h4 className="text-center text-success">
																	<Icon name="check-circle" size="3x" />
																</h4>
																<h4 className="text-center text-success">
																	The message was successfully sent.
																</h4>
															</div>
														) : (
															<div>
																<h3 className="text-center">Sending message...</h3>
																<Loader />
															</div>
														)
													) : (
														<Row>
															<Col xs={12}>
																<FormGroup>
																	<h4><u>Also, you can send the access information by email:</u></h4>
																	<label>Emails list:</label>
																	<MultipleValuesInput type="email" color="primary" placeholder="Type and hit enter" 
																		onChange={this.handleAddEmailToSend} valuesList={s.endData.emailsToSend}/>
																</FormGroup>
															</Col>
															<Col xs={12}>
																<Button 
																	block 
																	bsStyle="success" 
																	disabled={s.endData.emailsToSend.length === 0} 
																	onClick={this.onSendEmail}
																>
																	Send <Icon name="send" />
																</Button>
															</Col>
															<div>
																{
																	s.emailError && s.emailError.list && (
																		<Callout title={s.emailError.message}>
																			{
																				s.emailError.list.map((err, index) => (
																					<span key={index}>{err}<br/></span>
																				))
																			}
																		</Callout>
																	)
																}
															</div>
														</Row>
													)
												}
											</ContentBox>
										</div>
									)
								}
							</div>
						) : (
							<ContentBox title="Saving..." color="primary">
								<h3 className="text-center">We are saving the data. Please wait...</h3>
								<Loader />
								<br />
							</ContentBox>
						)
					)
				}
			</MainContent>
		);
	}
}

export default WorkersNew;