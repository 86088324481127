import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import './DayOfWeekFilter.scss';
import weekDays from '../../../../../../app/store/weekDays';

interface IProps {
	dayOfWeek?: number;
	onChange?: (dayOfWeek: number) => void;
}

const RunTemplateDayOfWeekFilter: React.FC<IProps> = props => (
	<Tabs
		id="weekDaysTab"
		className="run-template-day-of-week-filter "
		justified={window.innerWidth > 1000}
		bsStyle="pills"
		style={{ backgroundColor: 'white' }}
		activeKey={props.dayOfWeek}
		onSelect={props.onChange as any}
	>
		{
			weekDays.map((dayOfWeek, index) => {
				return (
					<Tab 
						key={dayOfWeek} 
						eventKey={index + 1} 
						title={dayOfWeek} 
					/>
				);
			})
		}
	</Tabs>
)

export default RunTemplateDayOfWeekFilter;
