import React from 'react';
import Select, { ReactSelectProps } from 'react-select';
import { FormControl } from 'react-bootstrap';
import componentRequestHandler from '../../../app/api/helpers/componentRequestHandler';
import ListsApi from '../../../app/api/ListsApi';
import { IError } from '../../../app/models/Application';
import { ICompanyListItem } from '../../../app/models/List';

interface IProps extends Omit<ReactSelectProps, 'onChange'> {
	onlySubContractors?: boolean;
	onlyActive?: boolean;
	hideSubFromName?: boolean;
	onChange?: (value?: string, label?: string) => void
}

interface IState {
	list: ICompanyListItem[];
	isLoading: boolean;
	error?: IError;
}

class CompanySearch extends React.Component<IProps, IState> {
	state = {
		list: [] as ICompanyListItem[],
		isLoading: false,
		error: undefined
	}

	componentDidMount() {
		this.fetchList();
	}

	componentDidUpdate(prevProps: Readonly<IProps>) {
		if (prevProps.onlySubContractors !== this.props.onlySubContractors)
			this.fetchList();
	}	

	fetchList = () => {
		const { onlyActive, onlySubContractors } = this.props;
		const promise = () => ListsApi.getCompanies(onlyActive, onlySubContractors);
		componentRequestHandler(this, promise, 'list')
	}

	handleChange = (selectedOption?: { value: string, label: string }) => {
		this.props.onChange && this.props.onChange(selectedOption?.value, selectedOption?.label);
	}

	render() {
		const { props, state } = this;

		return (
			<div>
				<Select 
					value={props.value}
					placeholder={state.isLoading ? 'Loading...' : props.placeholder || 'Search Company...'}
					noResultsText={!state.isLoading ? props.noResultsText || 'No results were found' : ''}
					disabled={props.disabled}
					options={state.list.map(p => ({ value: p.id, label: props.hideSubFromName ? p.name.replace('SUB - ', '') : p.name }))}
					isLoading={state.isLoading}
					onChange={this.handleChange as any}
				/>
				<FormControl 
					id={props.id} 
					type="hidden" 
					value={props.value as string || ''}
				/>
			</div>
		)
	}
}

export { CompanySearch };