import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormControl } from 'react-bootstrap';
import { setPeriodFilter } from '../../../stores/redux/filter';

import 'daterangepicker';
import 'daterangepicker/daterangepicker.css';

class DatePeriodInput extends Component {
	static propTypes = {
		endDate: PropTypes.any,
		endDateInMinutes: PropTypes.number,
		format: PropTypes.string,
		h24: PropTypes.bool,
		hideInput: PropTypes.bool,
		periodLimitInHours: PropTypes.string,
		ranges: PropTypes.object,
		showHours: PropTypes.bool,
		showDefaultRanges: PropTypes.bool,
		showFutureDefaultRanges: PropTypes.bool,
		startDateInMinutes: PropTypes.number,
		startDate: PropTypes.any,
		alwaysShowCalendars: PropTypes.bool,
		parentEl: PropTypes.string,
		openDirection: PropTypes.oneOf(['left', 'right', 'center']),
		dropsDirection: PropTypes.oneOf(['down', 'up']),
		periodLimitInMinutes: PropTypes.number,
		minDate: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.object
		]),
		maxDate: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.object
		]),
		noRedux: PropTypes.bool, // Avoid redux to mess up with the props when it doesn't come from redux
		onChange: PropTypes.func
	}

	static defaultProps = {
		alwaysShowCalendars: true,
		startDateInMinutes: -1,
		endDateInMinutes: 0,
		format: 'DD/MM/YYYY HH:mm',
		h24: true,
		openDirection: 'center',
		dropsDirection: 'down',
		parentEl: 'body'
	}

	constructor(props) {
		super(props);
		this.isMobile = window.innerWidth < 768;
	}

	getOptions = () => {
		const { 
			alwaysShowCalendars,
			endDate,
			endDateInMinutes,
			format,
			h24,  
			openDirection,
			dropsDirection,
			parentEl,
			periodLimitInMinutes,
			ranges,
			showHours, 
			showDefaultRanges,
			showFutureDefaultRanges,
			startDate,
			startDateInMinutes,
			minDate,
			maxDate
		} = this.props;

		let defaultRanges = {
			'Today': [
				moment().hour(0).minute(0).second(0),
				moment().hour(23).minute(59).second(59)
			],
			'Last 24 hours': [
				moment().hour(0).minute(0).second(0).add(-1, 'days'),
				moment().hour(23).minute(59).second(59)
			],
			'Last 7 days': [
				moment().hour(0).minute(0).second(0).add(-7, 'days'),
				moment().hour(23).minute(59).second(59)
			],
			'Last 30 days': [
				moment().hour(0).minute(0).second(0).add(-30, 'days'),
				moment().hour(23).minute(59).second(59)
			],
		};

		if (showFutureDefaultRanges)
			defaultRanges = {
				...defaultRanges,
				'Next 7 Days': [
					moment().hour(0).minute(0).second(0),
					moment().hour(23).minute(59).second(59).add(7, 'days')
				],
				'Next 14 days': [
					moment().hour(0).minute(0).second(0),
					moment().hour(23).minute(59).second(59).add(14, 'days')
				],
				'Next 30 days': [
					moment().hour(0).minute(0).second(0),
					moment().hour(23).minute(59).second(59).add(30, 'days')
				],
			};

		const options = {
			autoApply: true,
			alwaysShowCalendars,
			opens: openDirection,
			drops: dropsDirection,
			parentEl,
			locale: {
				format: format
			},
			dateLimit: (periodLimitInMinutes ? ({ minutes: periodLimitInMinutes}) : null),
			startDate: startDate || moment().add(startDateInMinutes, 'm'),
			endDate: endDate || moment().add(endDateInMinutes, 'm'),
			minDate,
			maxDate,
			timePicker: showHours,
			timePicker24Hour: h24,
			linkedCalendars: !this.isMobile,
			ranges: ranges || (showDefaultRanges && defaultRanges),
		};
		
		return options;
	}

	componentDidMount() {
		const options = this.getOptions();
		var { 
			startDateInMinutes, 
			endDateInMinutes,
			startDate,
			endDate,
			hideInput,
			noRedux,
			onChange,
			setPeriodFilter
		} = this.props;
		
		const startTime = startDate || moment().add(startDateInMinutes, 'm');
		const endTime = endDate || moment().add(endDateInMinutes, 'm');
		
		// Timeout to avoid redux overriding the date
		if (!noRedux)
			window.setTimeout(() => {
				setPeriodFilter(startTime, endTime);
				onChange && onChange(startTime, endTime);
			}, 500);

		window.$(this.periodInput)
			.daterangepicker(options)
			.on('apply.daterangepicker, hide.daterangepicker', (ev, picker) => {
				var startTime = moment(picker.startDate);
				var endTime = moment(picker.endDate);
				setPeriodFilter(startTime, endTime);

				this.props.onChange && this.props.onChange(startTime, endTime);
			})
		
		if(this.isMobile) {
			window.$(this.periodInput).on('showCalendar.daterangepicker', () => {
				window.$('#calendarInput').blur();
			});
		}

		if (hideInput)
			window.$('.daterangepicker').addClass('hide-input');
			
	}

	render() {   
		const { style, disabled } = this.props;
		const inputProps = { style, disabled };
		return (
			<FormControl 
				{...inputProps} 
				id="calendarInput" 
				inputRef={ref => this.periodInput = ref} 
			/>
		);
	}
}

const mapDispatchToProps = { setPeriodFilter };

const DatePeriodInputComponent = connect(null, mapDispatchToProps)(DatePeriodInput);

export { DatePeriodInputComponent as DatePeriodInput };