/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';

export class TextReadMore extends React.Component {
	static propTypes = {
		text: PropTypes.string.isRequired,
		maxLength: PropTypes.number.isRequired
	}

	static defaultProps = {
		maxLength: 100
	}

	state = {
		showAllText: false
	}

	toggleShowAllText = () => {
		this.setState({ showAllText: !this.state.showAllText });
	}

	renderText = () => {
		const { text, maxLength } = this.props;
		if (text.length <= maxLength)
			return text;
			
		if (this.state.showAllText)
			return (
				<React.Fragment>
					{ this.props.text }
					<a onClick={this.toggleShowAllText}> Show Less</a>
				</React.Fragment>
			);

		return (
			<React.Fragment>
				{ this.props.text.substring(0, maxLength) }... <a onClick={this.toggleShowAllText}>Read More</a>
			</React.Fragment>
		);
	}

	render() {
		return (
			<span>
				{ this.renderText() }
			</span>
		);
	}
 }