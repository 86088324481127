import faultReportEndpoints from './endpoints/faultReportEndpoints';
import httpRequests from './httpRequests';

export default {
	/**
     * Get Fault Reports list
	  * @param filters
	  * @param restrictAzureId
     */
	getFaultReportsList(filters: string, restrictAzureId: boolean) {
		let endpoint = faultReportEndpoints.root;

		if (filters)
			filters = JSON.stringify(filters);

		if (restrictAzureId)
			endpoint += '?restrictAzureId=' + restrictAzureId;

		return httpRequests.get(endpoint, { filterString: filters });
	},

	/**
     * Retrieve the Fault Report data
     * @param faultReportId 
     */
	getFaultReport(faultReportId: number) {
		const endpoint = faultReportEndpoints.byId(faultReportId);
		return httpRequests.get(endpoint);
	},
    
	getFaultReportsFile(filters: string) {
		const endpoint = faultReportEndpoints.exportReport;
		return httpRequests.downloadFile(endpoint, { filterString: JSON.stringify(filters) });
	},
	/**
	 * Get the URL of the photo
	 * @param faultReportId 
	 * @param fileId File ID
	 */
	getFaultReportPhoto(faultReportId: number, fileId: number) {
		const endpoint = faultReportEndpoints.photoFile(faultReportId, fileId);
		return httpRequests.getImage(endpoint);
	},

	/**
	 * Save a new Fault Report
	 * @param data Fault Report data object
	 */
	saveFaultReport(data: object) {
		const endpoint = faultReportEndpoints.root;
		return httpRequests.postWithFiles(endpoint, data);
	},

	/**
	 * Save the Fault Report History
	 * @param faultReportId 
	 * @param data Fault Report History data
	 */
	saveFaultReportHistory(faultReportId: number, data: object) {
		const endpoint = faultReportEndpoints.history(faultReportId);
		return httpRequests.post(endpoint, data);
	},

	/**
	 * Save the service tracker number of the Fault Report
	 * @param faultReportId 
	 * @param serviceTrackerNumber Service Track Number
	 */
	updateServiceTrackerNumber(faultReportId: number, serviceTrackerNumber: string) {
		const endpoint = faultReportEndpoints.serviceTrackerNumber(faultReportId);
		return httpRequests.put(endpoint, { serviceTrackerNumber });
	},

	/**
	 * Save the comments of the Fault Report
	 * @param faultReportId 
	 * @param statusComments Comments
	 */
	updateStatusComments(faultReportId: number, statusComments: string) {
		const endpoint = faultReportEndpoints.statusComment(faultReportId);
		return httpRequests.put(endpoint, { statusComments });
	},

	/**
	 * Save the QuickFix status of the Fault Report
	 * @param faultReportId 
	 * @param hadQuickFix
	 */
	updateQuickFix(faultReportId: number, hadQuickFix: boolean) {
		const endpoint = faultReportEndpoints.quickFix(faultReportId);
		return httpRequests.put(endpoint, { hadQuickFix });
	}
};