import moment from 'moment';
import React from 'react';
import { Table } from 'react-bootstrap';
import { IVehicleRegoHistory } from '../../../../../app/models/Vehicle';

interface IProps  {
	vehicleRegoHistory: IVehicleRegoHistory[];
}

const VehicleRegoHistory: React.FC<IProps> = props =>  {
	const regoHistory = props.vehicleRegoHistory?.filter(p => p.cancelledOn);

	return (	
		<Table condensed>
			<thead>
					<tr>
						<th>Number</th>
						<th>State</th>
						<th>Start Date</th>
						<th>Cancellation Date</th>
						<th>Reason for Cancelling</th>
					</tr>
			</thead>
			<tbody>
				{
					regoHistory.map(rego => (
						<tr key={rego.id}>
							<td>{ rego.regoNumber }</td>
							<td>{ rego.stateName }</td>
							<td>{ moment(rego.startedOn).format('DD/MM/YYYY') }</td>
							<td>{ moment(rego.cancelledOn).format('DD/MM/YYYY') }</td>
							<td>{ rego.cancelDescription || 'None' }</td>
						</tr>
					))
				}
			</tbody>
		</Table>
	)
}

export default VehicleRegoHistory;