import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';

import { Checkbox, YesNoRadio } from '../../../../../common/inputs';
import { Form } from '../../../../../common/uiElements';
import { Row, Col } from 'react-bootstrap';

const propTypes = {
	id: PropTypes.string,
	data: PropTypes.object,
	onChange: PropTypes.func,
	readOnly: PropTypes.bool,
	showTitle: PropTypes.bool,
	addGuideSteps: PropTypes.func
};

const defaultProps = {
	id: 'VehicleItemsCheck',
	readOnly: false,
	data: {},
	showTitle: true
};

const questions = [
	{ text: 'Accreditation Labels attached and legible', id: 'areAccreditationLabelsOk', defaultValue: false, isRequired: true },
	{ text: 'Is there a Mobile Phone? (if not or it isn\'t working report it immediately)', id: 'isMobilePhoneOk', defaultValue: false, isRequired: false },
	{ text: 'Loscam Pallet Book', id: 'hasLoscamPalletBook', defaultValue: false, isRequired: false },
	{ text: 'Fire Extinguisher', id: 'hasFireExtinguisher', defaultValue: false, isRequired: false },
	{ text: 'Load Bars', id: 'hasLoadBars', defaultValue: false, isRequired: false },
	{ text: '2 x Freezer Mats', id: 'has2FreezerMats', defaultValue: false, isRequired: false },
	{ text: 'Oil Bottle', id: 'hasOilBottle', defaultValue: false, isRequired: false },
	{ text: 'Pallet Jack Key', id: 'hasPalletJackKey', defaultValue: false, isRequired: false },
	{ text: 'Chep Pallet Book', id: 'hasChepPalletBook', defaultValue: false, isRequired: false },
	{ text: 'Reversing Camera (if it isn\'t operating report it immediately)', id: 'hasReversingCamera', defaultValue: false, isRequired: false },
	{ text: '3 x Warning Triangles', id: 'has3WarningTriangles', defaultValue: false, isRequired: false },
	{ text: 'First Aid Kit', id: 'hasFirstAidKit', defaultValue: false, isRequired: false },
	{ text: '3 x Boards', id: 'has3Boards', defaultValue: false, isRequired: false },
	{ text: 'Is the cabin interior cleaned?', id: 'isCabinCleaned', defaultValue: false, isRequired: false },
	{ text: 'Coolant Bottle', id: 'hasCoolantBottle', defaultValue: false, isRequired: false },
	{ text: 'All gauges operating and displaying correct information', id: 'areGaugesOk', defaultValue: false, isRequired: false },
	{ text: 'Truck Folder containing Runsheets and all other forms', id: 'isTruckFolderOk', defaultValue: false, isRequired: false },
	{ text: 'Toll Tag (attached to the windscreen)', id: 'hasTollTag', defaultValue: false, isRequired: false },
];

const guideSteps = [
	{
		title: 'Step 5 - Items Check',
		text: 'Please complete the check list',
		selector: '.pre-start__items-check',
		position: 'top',
		isLastStep: true
	}
];

class VehicleItemsCheck extends Component {
	componentDidMount() {
		this.props.addGuideSteps && this.props.addGuideSteps(guideSteps);
	}

	handleChangeInput = (e, value) => {
		let { data, id, onChange } = this.props;
		var fieldId = e.target.id;
		value = e.target.type === 'checkbox' ? e.target.checked : (typeof value !== 'undefined' ? value : e.target.value);
		data = {
			...data,
			[fieldId]: value
		};
		if (onChange)
			onChange(data, id);
	}

	renderValidations = () => {
		var validations = {};
		questions.forEach(({ id, isRequired }) => validations[id] = { required: isRequired });

		return validations;
	}

	render() {
		const p = this.props;
		return (
			<div className="pre-start__items-check">
				<Form validations={this.renderValidations()}>
					{ 
						p.showTitle && (
							<div>
								<h3 className="title"><Icon name="check" /> Vehicle Items Checklist</h3>
								<h5 className="title-obs">Please check if the following items are present and in good working order (if applicable)</h5>
							</div>
						)
					}
					<Row className="aligned-row">
						{
							questions.map(({ text, id }, index) => {
								return (
									<Col xs={12} sm={12} md={6} lg={6} key={index}>
										{
											p.readOnly ? (
												<p className={(id === 'areAccreditationLabelsOk' && !p.data[id]) ? 'text-danger' : ''}>
													<b><Icon name={p.data[id] ? 'check' : 'remove'} /></b>
													&nbsp;{text}
												</p>
											) : (
												id === 'areAccreditationLabelsOk' ? (
													<>
														<label>{text}</label>
														<YesNoRadio
															id={id}
															value={p.data[id]}	
															onChange={this.handleChangeInput}
														/>
													</>
												) : (
													<Checkbox
														id={id}
														text={text}
														checked={p.data[id]}
														onChange={this.handleChangeInput}
													/>
												)
											)
										}
									</Col>
								);
							})
						}
					</Row>
				</Form>
			</div>
		);
	}
}

VehicleItemsCheck.propTypes = propTypes;
VehicleItemsCheck.defaultProps = defaultProps;

export default VehicleItemsCheck;