/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import VehiclesApi from '../../../../app/api/VehiclesApi';
import { Row, Col } from 'react-bootstrap';
import { UserProfilePicture, ErrorBox, Loader, UserDetailsLink } from '../../../common/uiElements';
import componentRequestHandler from '../../../../app/api/helpers/componentRequestHandler';

const propTypes = {
	vehFleetNumber: PropTypes.string
};

class VehicleDriverDetails extends Component {
	constructor() {
		super();
		this.state = {
			currentDriver: null,
			isLoading: false,
			error: null
		};
	}

	componentDidMount() {
		if (this.props.vehFleetNumber)
			this.fetchCurrentVehicleDriver();
	}

	fetchCurrentVehicleDriver = () => {
		const promise = () => VehiclesApi.getCurrentDriver(this.props.vehFleetNumber);
		componentRequestHandler(this, promise, 'currentDriver');
	}

	renderContent = () => {
		const { state } = this;
		
		if (state.isLoading)
			return <Loader text="Loading current driver..." />;
		
		if (state.error)
			return <ErrorBox error={state.error} retryFunc={this.fetchCurrentVehicleDriver} />;

		if (!state.currentDriver)
			return <i><b><u>No driver is assigned to this vehicle</u></b></i>;
				
				
		return (
			<Fragment>
				<Row>
					<Col xs={4}>
						<UserProfilePicture 
							className="driver-img"
							azureId={state.currentDriver.azureId}
						/>
					</Col>
					<Col xs={8}>
						<b>DRIVER DETAILS</b><br/>
						<UserDetailsLink azureId={state.currentDriver.azureId}>
							{ state.currentDriver.shortName }
						</UserDetailsLink>
						<br />
						{ 
							state.currentDriver.phoneNumber ? <a href={`tel:${state.currentDriver.phoneNumber}`}>{ state.currentDriver.phoneNumber }</a> : '' 
						}
					</Col>
				</Row>
				<br />
				<Row>
					<Col xs={6}>
						<label>Run Number</label>
						<p>{ state.currentDriver.runNumber || '-' }</p>
					</Col>
					<Col xs={6}>
						<label>Inspected On</label>
						<p>
							<a target="_blank" rel="noopener noreferrer" href={`/compliance/preStart/${state.currentDriver.preStartId}`} >
								<u>{ moment(state.currentDriver.inspectedOnLocal).format('DD/MM/YY HH:mm' )}</u>
							</a>
						</p>
					</Col>
				</Row>
			</Fragment>
		);
	}

	render() {
		return (
			<div className="vehicle-driver-details">
				{ this.renderContent() }
			</div>
		);
	}
}

VehicleDriverDetails.propTypes = propTypes;

export default VehicleDriverDetails;