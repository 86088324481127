import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from './';
import States from '../../../app/store/States.json';

const propTypes = {
  allChecked: PropTypes.bool
}

const StatesList = props => (
  <div>
    <ul className="list-inline">
      {
        States.map(state => (
          <li key={state}>
            <Checkbox checked={props.allChecked} value={state} text={state} />
          </li>
        ))
      }
    </ul>
  </div>
);

StatesList.propTypes = propTypes;

export { StatesList };