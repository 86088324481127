import React from 'react';
import PropTypes from 'prop-types';

import WorkerSuperFundSchema from '../../../../../app/schemas/worker/WorkerSuperFundSchema.json';
import { SuperFundCompaniesList } from '../../../../common/lists';
import { MultipleFieldsWithModal } from '../../../../common/inputs';
import { FormControl } from 'react-bootstrap';

const validations = {
	superFundCompanyId: 'required',
	account: 'required',
	accountName: 'required'
};

const propTypes = {
	id: PropTypes.string,
	data: PropTypes.array,
	editMode: PropTypes.bool,
	onChange: PropTypes.func,
	readOnly: PropTypes.bool,

	/** If true, the user won't be able to edit any already added data  */
	isEditable: PropTypes.bool,
	
	/** If true, the user won't be able to delete any already added data  */
	isRemovable: PropTypes.bool,

	isActivatable: PropTypes.bool,
};

const defaultProps = {
	id: 'WorkerSuperFunds',
	editMode: false,
	readOnly: false,
	data: []
};

const fieldsSchema = [{
	id: 'superFundCompanyId',
	label: 'Company',
	input: <SuperFundCompaniesList />,
	type: 'select',
	dtoFieldName: 'companyName',
	colsClass: 'col-sm-12',
	showOnTable: true,
	isRequired: true
}, {
	id: 'account',
	label: 'Account Number',
	input: <FormControl maxLength="50" />,
	colsClass: 'col-sm-6',
	showOnTable: true,
	isRequired: true
}, {
	id: 'accountName',
	label: 'Account Name',
	input: <FormControl maxLength="50" />,
	colsClass: 'col-sm-6',
	showOnTable: true,
	isRequired: true
}];

const WorkerSuperFunds = ({ isEditable, isRemovable, isActivatable, editMode, readOnly, onChange, data }) => {
	const handleChange = data => {
		onChange && onChange(data, 'WorkerSuperFunds');
	};

	return (
		<div>
			<MultipleFieldsWithModal 
				title="Super Fund" 
				data={data}
				dataSchema={WorkerSuperFundSchema} 
				validations={validations}
				fieldsSchema={fieldsSchema} 
				editMode={editMode}
				readOnly={readOnly}
				isEditable={isEditable}
				isRemovable={isRemovable}
				isActivatable={isActivatable}
				onChange={handleChange}
			/>
		</div>
	);
};

WorkerSuperFunds.propTypes = propTypes;
WorkerSuperFunds.defaultProps = defaultProps;

export default WorkerSuperFunds;