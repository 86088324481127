import { range } from 'lodash';
import moment from 'moment';

export function getWeekDays(year: number, weekNumber: number) {
	return range(0, 7).map(i => moment().year(year).isoWeek(weekNumber).startOf('isoWeek').add(i, 'days').format('YYYY-MM-DD'));
}

export function getTotalWeeksInYear(year: number) {
	const totalWeeksInYear = moment().year(year).isoWeeksInYear();
	const weeksArray = range(1, totalWeeksInYear + 1);

	return weeksArray.map(week => ({ 
		week,
		startDate: getStartDateFromYearWeekNumber(year, week),
		endDate: getEndDateFromYearWeekNumber(year, week) 
	}));
}

export function getYears(startYear: number = 2016, endYear = moment().year() + 1) {
	return range(startYear, endYear + 1).map(year => year);
}

export function getStartDateFromYearWeekNumber(year: number, week: number) {
	return moment().year(year).isoWeek(week).startOf('isoWeek');
}

export function getEndDateFromYearWeekNumber(year: number, week: number) {
	const startDate = getStartDateFromYearWeekNumber(year, week);
	return moment(startDate).add(6, 'days');
}