/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { Callout } from '.';
import { IError } from '../../../app/models/Application';

interface IProps {
	error?: IError;
	retryFunc?: () => void;
}

export const ErrorBox: React.FC<IProps> = ({ error, retryFunc }) => {
	let errorDescription = (error && error.message) || (typeof error === 'string' && error) || 'An unexpected error has occurred.';
	return (
		<Callout>
			<p>
				<b>{errorDescription}</b>
			</p>
			{
				error && error.errorsList && (
					<ul className="list">
						{ 
							error.errorsList.map((error, index) => (
								<li key={index}>
									{error.Property}: {error.Message || error}
								</li>
							)) 
						}
					</ul>
				)
			}
			{ retryFunc && <a onClick={retryFunc}><b>Click here to try again</b></a> }
		</Callout>
	);
};