import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import weekDays from '../../../app/store/weekDays';
import { Callout } from '../uiElements';

const propTypes = {
	overlappedWindows: PropTypes.arrayOf(
		PropTypes.shape({
			dayOfWeek: PropTypes.number.isRequired,
			newStartTime: PropTypes.string.isRequired,
			newEndTime: PropTypes.string.isRequired,
			existingStartTime: PropTypes.string.isRequired,
			existingEndTime: PropTypes.string.isRequired
		})
	).isRequired
};

const WindowsOverlappedDetails = props => (
	<div>
		<Callout 
			icon="warning"
			title="Overlapped Windows" 
			text="There are overlapped windows. Please revise the windows and try again."
		/>
		<br />
		<Table condensed striped>
			<thead>
				<tr>
					<th width="110">DoW</th>
					<th>Existing Start</th>
					<th>Existing End</th>
					<th>Overlapping Start</th>
					<th>Overlapping End</th>
				</tr>
			</thead>
			<tbody>
				{
					props.overlappedWindows.map((o, i) => (
						<tr key={i}>
							<td>{ weekDays[o.dayOfWeek] }</td>
							<td>{ o.existingStartTime }</td>
							<td>{ o.existingEndTime }</td>
							<td>{ o.newStartTime }</td>
							<td>{ o.newEndTime }</td>
						</tr>
					))
				}
			</tbody>
		</Table>
	</div>
);

WindowsOverlappedDetails.propTypes = propTypes;

export { WindowsOverlappedDetails };
