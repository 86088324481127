import { shape, string } from 'prop-types';

export default shape({
	azureId: string.isRequired,
	firstName: string.isRequired,
	surname: string.isRequired,
	shortName: string.isRequired,
	fullName: string.isRequired,
	
	email: string,
	mobile: string
});