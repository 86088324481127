/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import toastr from 'toastr';
import Icon from 'react-fontawesome';

import { Row, Col, FormGroup, FormControl, Button, Modal } from 'react-bootstrap';
import FaultReportApi from '../../../../../app/api/FaultReportApi';
import faultReportStatus from '../../../../../app/schemas/FaultReportStatus';
import { Form, Loader, ErrorBox, TimelineContainer, UserDetailsLink } from '../../../../common/uiElements';
import { UserSearch } from '../../../../common/inputs';

const propTypes = {
	data: PropTypes.shape({
		reportedOnLocal: PropTypes.string.isRequired,
		FaultReportHistories: PropTypes.arrayOf(
			PropTypes.shape({
				status: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.number
				]),
				actionOn: PropTypes.string,
				repairedById: PropTypes.string,
				WorkerRepairedBy: PropTypes.object,
				closedOffOn: PropTypes.string,
				closedOffById: PropTypes.string,
				WorkerClosedOffBy: PropTypes.object,
				authorisedById: PropTypes.string,
				WorkerAuthorisedBy: PropTypes.object,
				actionById: PropTypes.string,
				actionByName: PropTypes.string,
				actionByEmail: PropTypes.string,
				periodFrom: PropTypes.string,
				periodTo: PropTypes.string,
			})
		)
	}).isRequired,
	onChange: PropTypes.func
};

class FaultReportHistory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newActionData: {},
			showNewActionModal: false,
			isSaving: false,
			error: null
		};
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	_handleChangeActionTaken = e => {
		let { newActionData } = this.state;
		const { id, value } = e.target;
		if (id === 'status')
			newActionData = { 
				authorisedById: newActionData.authorisedById,
				status: value 
			};
		else
			newActionData = { ...newActionData, [id]: value };

		this.setState({ newActionData });
	}

	_handleChangeActionBy = (user, id) => {
		let { newActionData } = this.state;
		if (id === 'authorisedById') {
			newActionData = { status: newActionData.status }; //Clean data when changes the authorised by
		}
		else if (id === 'actionBy') {
			id = 'actionById';
			newActionData.actionById = null;
			newActionData.actionByName = null;
			if (user && user.value === user.label)
				id = 'actionByName'; // If it's not an employee
		}

		this.setState({
			newActionData: {
				...newActionData,
				[id]: user && user.value,
			}
		});
	}

	_handleOpenNewActionModal = () => {
		this.setState({ showNewActionModal: true });
	}

	_handleCloseNewActionModal = () => {
		this.setState({ 
			newActionData: {}, 
			showNewActionModal: false,
			isSaving: false,
			error: null
		});
	}

	_handleSaveNewAction = () => {
		if (!this.toValidate || window.$(this.toValidate).find('form').valid()) {
			this.setState({ isSaving: true, error: null });

			FaultReportApi.saveFaultReportHistory(this.props.data.id, this.state.newActionData)
				.then(() => {
					toastr.success('The data was successfully saved', 'Success');
					return this.props.onSave && this.props.onSave();
				})
				.catch(error => {
					console.error(error);
					this.setState({ isSaving: false, error });
				});
		}
	}

	renderTimeLineItem = (historyItem) => {
		const { 
			status, WorkerAuthorisedBy, 
			WorkerActionBy, actionByName, actionOn,
			WorkerClosedOffBy, closedOffOn,
			periodFrom, periodTo
		} = historyItem;
		
		const { 
			FIXED, NO_REPAIRS_REQUIRED, MONITORED,DEFERRED 
		} = faultReportStatus;

		const actionBy = actionByName ? <b>{ actionByName }</b> : (
			<UserDetailsLink azureId={WorkerActionBy.azureId}>
				{ WorkerActionBy.shortName }
			</UserDetailsLink>
		);

		const authorisedByListItem = (
			<li>
				<b>Authorised</b> by&nbsp;
				<UserDetailsLink azureId={WorkerAuthorisedBy.azureId}>
					{ WorkerAuthorisedBy.shortName }
				</UserDetailsLink>
			</li>
		);

		if (status == FIXED || status == NO_REPAIRS_REQUIRED)
			return {
				color: 'green',
				icon: 'check',
				header: (
					<span>
						<b>{ status == FIXED ? 'Fixed' : 'No repairs required'}</b>
							&nbsp;on <u>{ moment(actionOn).format('DD/MM/YYYY') }</u>
					</span>
				),
				body: (
					<ul className="list list-unstyled">
						<li>
							<b>Signed Off</b> by&nbsp;
							{ actionBy }
						</li>
						{ authorisedByListItem }
						<li>
							<b>Closed Off</b> by&nbsp;
							<UserDetailsLink azureId={WorkerClosedOffBy.azureId}>
								{ WorkerClosedOffBy.shortName }
							</UserDetailsLink>
									&nbsp;on <u>{ moment(closedOffOn).format('DD/MM/YYYY') }</u>
						</li>
					</ul>
				)
			};
		else if (status == MONITORED) 
			return {
				color: 'orange',
				icon: 'eye',
				header: (
					<span>
						<b>Monitored</b>
							&nbsp;from <u>{ moment(periodFrom).format('DD/MM/YYYY') }</u>
							&nbsp;to <u>{ moment(periodTo).format('DD/MM/YYYY') }</u>
					</span>
				),
				body: (
					<ul className="list list-unstyled">
						<li>
									By&nbsp;
							{ actionBy }
						</li>
						{ authorisedByListItem }
					</ul>
				)
			};
		else if (status == DEFERRED) 
			return {
				color: 'orange',
				icon: 'forward',
				header: <span><b>Deferred</b> until <u>{ moment(periodTo).format('DD/MM/YYYY') }</u></span>,
				body: (
					<ul className="list list-unstyled">
						<li>
									By&nbsp;
							{ actionBy }
						</li>
						{ authorisedByListItem }
					</ul>
				)
			};
	}
	
	renderTimeLine = () => {
		const { data } = this.props;
		const timeLineArray = [];

		if (data.FaultReportHistories)
			for (let i = data.FaultReportHistories.length - 1; i >= 0; i--) {
				const timeLineItem = this.renderTimeLineItem(data.FaultReportHistories[i]);
				timeLineArray.push(timeLineItem);
			}

		timeLineArray.push({
			color: 'red',
			icon: 'plus',
			header: <span><b>Opened</b> on <u>{ moment(data.reportedOnLocal).format('DD/MM/YYYY HH:mm') }</u></span>,
			body: (
				<span>
						By&nbsp;
					<UserDetailsLink azureId={data.WorkerReportedBy.azureId}>
						{ data.WorkerReportedBy.shortName }
					</UserDetailsLink>
				</span>
			)
		});

		return timeLineArray;
	}

	render() {
		const s = this.state;
		const p = this.props;
		const isClosed = p.data.FaultReportHistories.find(d => d.closedOffOn);

		return (
			<div>
				{
					!isClosed && (
						<div>
							<FormGroup>
								<Button 
									block 
									bsStyle="success"
									onClick={this._handleOpenNewActionModal}>
									<Icon name="play" /> A new action has been taken
								</Button>
							</FormGroup>
							<Modal show={s.showNewActionModal} onHide={this._handleCloseNewActionModal}>
								<Modal.Header closeButton>
									<Modal.Title>New Action Taken</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									{
										s.isSaving ? (
											<Loader isSaving />
										) : (
											<div>
												{ 
													s.error ? (
														<ErrorBox error={s.error} retryFunc={this._handleSaveNewAction}/> 
													) : (
														<div ref={ref => this.toValidate = ref}>
															<FaultReportHistoryForm 
																{ ...s.newActionData }
																reportedOnLocal={p.data.reportedOnLocal}
																onChange={this._handleChangeActionTaken} 
																onChangeUser={this._handleChangeActionBy}
															/>
														</div>
													)
												}
											</div>
										)
									}
								</Modal.Body>
								<Modal.Footer>
									<Button disabled={s.isSaving} className="pull-left" onClick={this._handleSaveNewAction} bsStyle="success">Save Action</Button>
									<Button disabled={s.isSaving} onClick={this._handleCloseNewActionModal} bsStyle="danger">Cancel</Button>
								</Modal.Footer>
							</Modal>
							<hr />
						</div>
					)
				}
				<TimelineContainer items={ this.renderTimeLine() } />
			</div>
		);
	}
}

FaultReportHistory.propTypes = propTypes;

export default FaultReportHistory;

const FaultReportHistoryForm = props => {
	const datesValidation = {
		required: true,
		date: true,
		greaterOrEqualThan: '#reportedOnLocal'
	};
	const validations = {
		authorisedById: 'required',
		status: 'required',
		actionBy: 'required',
		closedOffById: 'required',
		actionOn: datesValidation,
		closedOffOn: datesValidation,
		periodFrom: datesValidation,
		periodTo: datesValidation
	};

	const reportedOnLocalMessage = `Must be greater or equal ${moment(props.reportedOnLocal).format('DD/MM/YYYY')}`;
	const validationMessages = {
		actionOn: { greaterOrEqualThan: reportedOnLocalMessage },
		closedOffOn: { greaterOrEqualThan: reportedOnLocalMessage },
		periodFrom: { greaterOrEqualThan: reportedOnLocalMessage },
		periodTo: { greaterOrEqualThan: reportedOnLocalMessage },
        
	};

	return (
		<Form validations={validations} messages={validationMessages}>
			<FormControl id="reportedOnLocal" type="hidden" value={props.reportedOnLocal} />
			<Row>
				<Col sm={6}>
					<FormGroup>
						<label>Authorised By *</label>
						<UserSearch 
							id="authorisedById"
							value={props.authorisedById || ''}
							onChange={props.onChangeUser} />
					</FormGroup>
				</Col>
				<Col sm={6}>
					<FormGroup>
						<label>Action taken *</label>
						<FormControl 
							id="status" 
							componentClass="select"
							value={props.status}
							onChange={props.onChange}>
							<option value="">--- SELECT ---</option>
							<option value={faultReportStatus.NO_REPAIRS_REQUIRED}>No repairs required</option>
							<option value={faultReportStatus.FIXED}>Fixed</option>
							<option value={faultReportStatus.MONITORED}>Monitored</option>
							<option value={faultReportStatus.DEFERRED}>Deferred</option>
						</FormControl>
					</FormGroup>
				</Col>
			</Row>
			{
				props.authorisedById && props.status && (
					<div>
						<Row>
							<Col sm={6}>
								<FormGroup>
									<label>Action By *</label>
									<UserSearch 
										id="actionBy"
										value={props.actionById || props.actionByName || ''}
										isCreatable={true}
										onChange={props.onChangeUser} />
								</FormGroup>
							</Col>
							{
								(props.status == faultReportStatus.FIXED || props.status == faultReportStatus.NO_REPAIRS_REQUIRED) && (
									<Col sm={6}>
										<FormGroup>
											<label>Action On *</label>
											<FormControl 
												id="actionOn" 
												type="date"
												value={props.actionOn || ''}
												onChange={props.onChange} />
										</FormGroup>
									</Col>
								)
							}
						</Row>
						{
							(props.status == faultReportStatus.MONITORED || props.status == faultReportStatus.DEFERRED) && (
								<Row>
									{
										props.status == faultReportStatus.MONITORED && (
											<Col sm={6}>
												<FormGroup>
													<label>From *</label>
													<FormControl 
														id="periodFrom" 
														type="date"
														value={props.periodFrom || ''}
														onChange={props.onChange} />
												</FormGroup>
											</Col>
										)
									}
									<Col sm={6}>
										<FormGroup>
											<label>Until *</label>
											<FormControl 
												id="periodTo" 
												type="date"
												value={props.periodTo || ''}
												onChange={props.onChange} />
										</FormGroup>
									</Col>
								</Row>
							)
						}
						{
							(props.status == faultReportStatus.FIXED || props.status == faultReportStatus.NO_REPAIRS_REQUIRED) && (
								<Row>
									<Col sm={6}>
										<FormGroup>
											<label>Closed Off By *</label>
											<UserSearch 
												id="closedOffById"
												value={props.closedOffById || ''}
												onChange={props.onChangeUser} />
										</FormGroup>
									</Col>
									<Col sm={6}>
										<FormGroup>
											<label>Closed Off On *</label>
											<FormControl 
												id="closedOffOn" 
												type="date"
												value={props.closedOffOn || ''}
												onChange={props.onChange} />
										</FormGroup>
									</Col>
								</Row>
							)
						}
					</div>
				)
			}
		</Form>
	);
};

FaultReportHistoryForm.propTypes = {
	reportedOnLocal: PropTypes.string.isRequired,
	status: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	]),
	actionById: PropTypes.string,
	actionByName: PropTypes.string,
	actionByEmail: PropTypes.string,
	periodFrom: PropTypes.string,
	periodTo: PropTypes.string,
	onChange: PropTypes.func,
	onChangeUser: PropTypes.func
};

FaultReportHistoryForm.defaultProps = {
	status: ''
};