import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Icon from 'react-fontawesome';
import { IPalletLoadListItem } from '../../../../app/models/Pallet';

interface IProps {
	data: IPalletLoadListItem[];
}

const PalletLoadPanelSummary: React.FC<IProps> = props => {
	const { data } = props;
	
	const renderBox = (title: string, color: string, icon: string, text: string | number) => (
		<Col sm={4}>
			<div className="info-box">
				<span className={`info-box-icon bg-${color}`}>
					<Icon name={icon} />
				</span>
				<div className="info-box-content" style={{ position: 'relative' }}>
					<b style={{ fontSize: 55 }}>{ text }</b>
					<span style={{ fontSize: 27, position: 'absolute', right: 40, top: 25 }}>{ title }</span>
				</div>
			</div>
		</Col>
	)

	const totalInProgress = data.filter(p => !p.loadCancelledOn && !p.loadFinishedOn).length;
	const totalCompleted = data.filter(p => p.loadFinishedOn).length;
	const totalPaused = data.filter(p => p.isPaused).length;
	
	return (
		<Row>
			{ renderBox('IN PROGRESS', 'blue', 'refresh', totalInProgress) }
			{ renderBox('PAUSED', 'red', 'pause', totalPaused) }
			{ renderBox('COMPLETED', 'green', 'check', totalCompleted) }
			{/* {
				renderBox('AVG. LOAD TIME', 'red', 'clock-o', Math.round(data.filter(p => p.loadFinishedOn).map(p => 
					moment.duration(moment(p.loadFinishedOn!).diff(moment(p.loadStartedOn))).asMinutes()
				).reduce((a, b) => a + b, 0) / data.length) + ' min')
			} */}
		</Row>
	)
}

export default PalletLoadPanelSummary;