import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';

import { Button, Modal } from 'react-bootstrap';
import CorrectiveActionApi from '../../../../../app/api/CorrectiveActionApi';
import CorrectiveActionForm from './CorrectiveActionForm';
import { Loader, ErrorBox } from '../../../../common/uiElements';
import confirm from '../../../../../app/helpers/confirm';
import FontAwesome from 'react-fontawesome';

const propTypes = {
	nonConformanceId: PropTypes.number.isRequired,
	id: PropTypes.number,
	enableEditMode: PropTypes.bool,
	onSave: PropTypes.func,
	showRootCauseField: PropTypes.bool, // Will show the root cause field only if it is the first corrective action of the non conformance
	occurredOn: PropTypes.string, // will be used to validate the action taken
	onClose: PropTypes.func
};

const defaultProps = {
	nonConformanceId: null,
	showRootCauseField: true,
	id: null,
	enableEditMode: false,
	onSave: () => {}
};

class CorrectiveActionModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			correctiveActionData: null,
			isLoading: false,
			isSaving: false,
			isEditMode: false,
			errorLoading: null,
			errorSaving: null
		};
	}

	componentDidMount() {
		if (this.props.id)
			return this.fetchData();
		else
			this.setState({ 
				correctiveActionData: {
					nonConformanceId: this.props.nonConformanceId,
					files: []
				}
			});
	}
	
	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchData = () => {
		this.setState({ correctiveActionData: null, errorLoading: undefined, isLoading: true });
		CorrectiveActionApi.getCorrectiveActionDetails(this.props.id)
			.then(correctiveActionData => {
				if (this.unmounted) return;

				this.setState({ correctiveActionData, isLoading: false });
			})
			.catch(errorLoading => {
				this.setState({ errorLoading, isLoading: false });
			});
	}

	submitForm = () => {		
		if (this.formContainer && !window.$(this.formContainer).find('form').valid())
			return;
		
		confirm(
			'Do you want to close this case?',
			() => this.saveData(true),
			() => this.saveData(false)
		);
	}

	saveData = isClosed => {
		this.setState({ errorSaving: null, isSaving: true });
		let promise;
		const { correctiveActionData } = this.state;
		correctiveActionData.isClosed = isClosed;
		if (this.props.id)
			promise = () => CorrectiveActionApi.updateCorrectiveActionData(this.props.id, correctiveActionData);
		else
			promise = () => CorrectiveActionApi.saveCorrectiveActionData(correctiveActionData); 
		
		promise()
			.then(() => {
				toastr.success('The data was successfully saved', 'Success');
				if (this.props.id && !this.unmounted)
					return this.setState({ isEditMode: false, isSaving: false }, this.fetchData);
				else
					this.props.onSave();
			})
			.catch(errorSaving => {
				this.setState({ errorSaving, isSaving: false });
			});
	}

	toggleEditMode = () => {
		this.setState({ isEditMode: !this.state.isEditMode });
	}

	handleChange = e => {
		const { id, value } = e.target;
		
		this.setState({ 
			correctiveActionData: {
				...this.state.correctiveActionData,
				[id]: value
			}
		});
	}

	handleChangeUser = (fieldId, workerId) => {
		this.setState({ 
			correctiveActionData: {
				...this.state.correctiveActionData,
				[fieldId]: workerId
			}
		});
	}

	handleAddFiles = files => {
		this.setState({
			correctiveActionData: {
				...this.state.correctiveActionData,
				files,
				attachedFiles: files.map(p => p.file)
			}
		});
	}

	renderContent = () => {
		const { state, props } = this;

		if (state.isLoading || state.isSaving)
			return <Loader isLoading={state.isLoading} isSaving={state.isSaving} />;

		if (state.errorLoading || state.errorSaving)
			return (
				<ErrorBox 
					error={state.errorLoading || state.errorSaving} 
					retryFunc={state.errorLoading ? this.fetchData : this.submitForm } />
			);

		return (
			<Fragment>
				{
					state.correctiveActionData && (
						<div ref={ref => this.formContainer = ref}>
							<CorrectiveActionForm
								{...state.correctiveActionData}
								showRootCauseField={props.showRootCauseField}
								occurredOn={props.occurredOn}
								isReadOnly={props.id && !state.isEditMode}
								onChange={this.handleChange}
								onChangeUser={this.handleChangeUser}
								onAddFiles={this.handleAddFiles}
							/>
						</div>
					)
				}
			</Fragment>
		);
	}

	render() {
		const { state, props } = this;
		return (
			<Modal bsSize="lg" show onHide={this.props.onClose}>
				<Modal.Header closeButton>
					<Modal.Title>Corrective Action</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ this.renderContent() }
				</Modal.Body>
				<Modal.Footer>
						{
							!state.isLoading && !state.isSaving && (
								<>
									{
										props.enableEditMode && !state.isEditMode && props.id && (
											<Button className="pull-left" bsSize="lg" bsStyle="warning" onClick={this.toggleEditMode}>
												<FontAwesome name="edit" /> Edit Correct Action
											</Button>
										)
									}
									{
										(state.isEditMode || !props.id) && (
											<Button className="pull-left" bsSize="lg" bsStyle="success" onClick={ this.submitForm }>
												<FontAwesome name="floppy-o" /> Save Corrective Action
											</Button>
										)
									}
								</>
							)
						}
					<Button bsSize="lg" onClick={props.onClose}>
						<FontAwesome name="sign-out" /> Close Without Saving
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}
}

CorrectiveActionModal.propTypes = propTypes;
CorrectiveActionModal.defaultProps = defaultProps;

export default CorrectiveActionModal;