/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, browserHistory } from 'react-router';
import { Row, Col, FormGroup, Button, FormControl } from 'react-bootstrap';
import moment from 'moment';
import omit from 'lodash.omit';
import toastr from 'toastr';
import Icon from 'react-fontawesome';

import WorkerApi from '../../../../../app/api/WorkerApi';
import MeApi from '../../../../../app/api/MeApi';
import { QuickPinModal } from '../../../../common/layout';
import { ErrorBox, Form, Loader } from '../../../../common/uiElements';

// Forms
import WorkerDetails from '../forms/WorkerDetails';
import WorkerDocuments from '../forms/WorkerDocuments';
import WorkerDriverLicences from '../forms/WorkerDriverLicences';
import WorkerSuperFunds from '../forms/WorkerSuperFunds';
import WorkerStatus from '../forms/WorkerStatus';
import WorkerDocumentsList from './WorkerDocumentsList';
import WorkerAvailability from '../forms/WorkerAvailability';
import { WorkerAvailabilityForm, WorkerAvailabilityPUT_POST, WorkerCovidTestForm } from '../../../../../app/models/Worker';
// import WorkerCovidTests from './WorkerCovidTests';

const propTypes = {
	/** The user's Azure ID to fetch his data to edit */
	azureId: PropTypes.string.isRequired,

	/** If true, it locks some editable data that the user can't change by himself */
	isLoggedUser: PropTypes.bool,

	onSave: PropTypes.func,
	onClickBack: PropTypes.func,

};

class WorkerEdit extends Component {
	constructor() {
		super();
		this.state = {
			error: false,
			errorMethod: null,
			isLoading: false,
			isSaving: false,
			savedMessage: '',
			showArchivedFiles: false,
			userData: undefined,
			workerCurrentTFN: null,
			showQuickPinModal: false
		};
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	UNSAFE_componentWillMount() {
		this.fetchWorkerData();
	}

	fetchWorkerData = () => {
		const { azureId, isLoggedUser } = this.props;
			
		if (azureId) {
			this.setState({ isLoading: true, error: false, errorMethod: null });
			let promise = WorkerApi.getWorkerDetails.bind(this, azureId, true, true);
			if (isLoggedUser)
				promise = MeApi.getUserDetails;

			promise()
				.then(userData => {
					if (this.unmounted) return;

					if (!userData.WorkerAvailability)
						userData.WorkerAvailability = new WorkerAvailabilityForm();
					
					if (userData.WorkerCovidTests)
						userData.WorkerCovidTests.forEach(covidTest => covidTest = new WorkerCovidTestForm(covidTest));
					
					return this.setState({
						userData: {
							addedDocuments: [],
							...userData
						},
						workerCurrentTFN: userData.tfn,
						isLoading: false
					});
				})
				.catch(error => {
					console.error(error);
					return this.setState({
						isLoading: false, 
						error,
						errorMethod: this.fetchWorkerData
					});
				});
		}
	}

	handleChangeData = (newData, id) => {
		var { userData } = this.state;
		if (id == 'WorkerDetails')
			this.setState({ 
				userData: { 
					...userData,
					...newData
				} 
			});	
		else
			this.setState({ 
				userData: { 
					...userData,
					[id]: newData
				} 
			});	
	}

	handleChangeWorkerAvailability = (fieldId, value) => {
		var { userData } = this.state;
		this.setState({ 
			userData: { 
				...userData,
				WorkerAvailability: {
					...userData.WorkerAvailability,
					[fieldId]: value
				}
			}
		});
	}

	handleAvailabilityClearDayOfWeekData = (dayOfWeek) => {
		const { userData } = this.state;
		let workerAvailabilityNewData = {};
		if (dayOfWeek === 1) workerAvailabilityNewData = { mondayStartTime: '', mondayOrdinaryHours: '', mondayStartingPlaceId: '', isMondayOvertime: false };
		if (dayOfWeek === 2) workerAvailabilityNewData = { tuesdayStartTime: '', tuesdayOrdinaryHours: '', tuesdayStartingPlaceId: '', isTuesdayOvertime: false };
		if (dayOfWeek === 3) workerAvailabilityNewData = { wednesdayStartTime: '', wednesdayOrdinaryHours: '', wednesdayStartingPlaceId: '', isWednesdayOvertime: false };
		if (dayOfWeek === 4) workerAvailabilityNewData = { thursdayStartTime: '', thursdayOrdinaryHours: '', thursdayStartingPlaceId: '', isThursdayOvertime: false };
		if (dayOfWeek === 5) workerAvailabilityNewData = { fridayStartTime: '', fridayOrdinaryHours: '', fridayStartingPlaceId: '', isFridayOvertime: false };
		if (dayOfWeek === 6) workerAvailabilityNewData = { saturdayStartTime: '', saturdayOrdinaryHours: '', saturdayStartingPlaceId: '', isSaturdayOvertime: false };
		if (dayOfWeek === 7) workerAvailabilityNewData = { sundayStartTime: '', sundayOrdinaryHours: '', sundayStartingPlaceId: '', isSundayOvertime: false };

		this.setState({ 
			userData: { 
				...userData,
				WorkerAvailability: {
					...userData.WorkerAvailability,
					...workerAvailabilityNewData
				}
			}
		});
	}

	handleClearWorkerAvailabilityRotate = () => {
		const { userData } = this.state;
		this.setState({ 
			userData: { 
				...userData,
				WorkerAvailability: {
					...userData.WorkerAvailability,
					ordinaryStartingPlaceId: '',
					ordinaryStartTime: '',
					ordinaryHours: '',
					ordinaryTotalDaysOn: '',
					ordinaryTotalDaysOff: '',
					rotateWithWorkerId: ''
				}
			}
		});
	}

	handleChangeCovidTests = (WorkerCovidTests) => {
		const { userData } = this.state;
		this.setState({ 
			userData: { 
				...userData,
				WorkerCovidTests

			}
		});
	}

	handleInactivateWorker = () => {
		const { azureId } = this.state.userData;

		if (azureId && window.confirm('Do you really want to inactivate this Worker?')) {
			this.setState({ isSaving: true, error: false });
			WorkerApi.inactivateWorker(azureId)
				.then(() => {
					if (this.unmounted) return;	

					toastr.success('The Worker was successfully inactivated.', 'Success');
					browserHistory.push('/management/workers');
				})
				.catch((error) => {
					this.setState({ 
						isSaving: false, error, 
						errorMethod: this.handleInactivateWorker 
					});	
				});
		}
	}

	handleToggleDeletedDocument = (documentId) => {
		const documentObject = this.state.userData.WorkerDocuments.find(d => d.id === documentId);
		documentObject.isDeleted = !documentObject.isDeleted;

		if (documentObject.isDeleted)
			toastr.info('It will only be removed in fact after saving', 'File was removed');

		return this.handleChangeData({ WorkerDocuments });
	}

	handleChangeEditedDocumentInput = (e, documentId) => {
		var value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

		const documentObject = this.state.userData.WorkerDocuments.find(d => d.id === documentId);
		documentObject[e.target.id] = value;

		return this.handleChangeData({ WorkerDocuments });
	}

	handleOnSave = () => {
		var valid =  true;

		// Validate all main tabs
		window.$('#workerAvailability, #details, #superFund, #documents').each(function() {
			var form = window.$(this).find('form');
			if(form.length > 0 && !form.valid()) {
				window.$(window.$('.tab-content')[0]).find('> .tab-pane').removeClass('active');
				window.$(window.$('.nav-tabs')[0]).find('li').removeClass('active');
				window.$(this).addClass('active');
				window.$('.nav.nav-tabs a[href*=' + this.id + ']').parent('li').addClass('active');
				window.$(window.$(this).find('.error')[0]).focus();
				valid = false;
				return false;
			}
		});

		if (!valid)
			return false;

		//Validate Edited Documents Tab
		var $editDocumentsForm = window.$('#editDocuments').find('#description');
		if ($editDocumentsForm.length > 0 && !$editDocumentsForm.valid()) {
			window.$('#editDocuments #description-error').remove();
			window.$(window.$('.tab-content')[0]).find('> .tab-pane').removeClass('active');
			window.$(window.$('.nav-tabs')[0]).find('li').removeClass('active');

			window.$('#documents').addClass('active');
			window.$('.nav.nav-tabs a[href*=documents]').parent('li').addClass('active');

			window.$(window.$('#editDocuments').find('input.error')[0]).focus();
			return false;
		}

		var { azureId } = this.state.userData;
		if (azureId) {
			var dataToSend = this.renderDataToSend();

			this.setState({ isSaving: true, error: false, errorMethod: null });
			const promise = () => {
				if (this.props.isLoggedUser) 
					return MeApi.updateUserDetails(dataToSend);
				
				return WorkerApi.updateWorkerData(dataToSend, azureId);				
			};

			promise()
				.then(() => {
					toastr.success('The data was successfully saved', 'Success!');
					if (this.unmounted) return;	

					if (this.props.isLoggedUser)
						return this.setState({ isSaving: false }, this.fetchWorkerData);
					
					if(this.props.onSave)
						return this.props.onSave();

					return browserHistory.push('/management/workers/' + azureId);
				})
				.catch(error => {
					return this.setState({ 
						isSaving: false, 
						error,
						errorMethod: this.handleOnSave
					});
				});
		}

	}

	renderDataToSend = () => {
		let userData = window.$.extend(true, {}, this.state.userData);

		// Worker Details without other objects
		let WorkerDetails = { ...omit(userData, ['photo', 'addedDocuments', 'WorkerDocuments', 'WorkerDriverLicences', 'WorkerStatus', 'WorkerSuperFunds', 'WorkerAvailability', 'WorkerCovidTests']) };

		// Super Funds and Worker Status
		var { WorkerSuperFunds, WorkerStatus } = userData;

		// Worker Availability
		const WorkerAvailability = new WorkerAvailabilityPUT_POST(userData.WorkerAvailability);
		
		// Documents
		let WorkerDocuments = userData.WorkerDocuments || [];

		// Covid Tests
		let WorkerCovidTests = userData.WorkerCovidTests || [];
		
		// Added Documents
		let addedDocuments = userData.addedDocuments || [];

		// Driver Licences
		let WorkerDriverLicences = userData.WorkerDriverLicences;
		
		// Attached files to send through the API
		let attachedFiles = [];
		
		// Check if there is photo
		if (userData.photo) {
			WorkerDetails.photoFileIndex = attachedFiles.length;
			attachedFiles.push(userData.photo);
		}

		// Check if there are documents to add
		for (let i = 0; i < addedDocuments.length; i++) {
			let documentObject = addedDocuments[i];
			
			documentObject.dtoFileIndex = attachedFiles.length;
			attachedFiles.push(documentObject.file);

			delete documentObject.file;
			WorkerDocuments.push(documentObject);
		}

		// Check if there are covid tests to add
		for (let i = 0; i < WorkerCovidTests.length; i++) {
			let covidTest = WorkerCovidTests[i];
			if (covidTest.testResultFile) {
				covidTest.dtoFileIndex = attachedFiles.length;
				attachedFiles.push(covidTest.testResultFile);
				delete covidTest.testResultFile;
			}
		}

		// Driver Licences
		for (let i = 0; i < WorkerDriverLicences.length; i++) {		
			let driverLicenceObject = WorkerDriverLicences[i];
			// Front File
			if (typeof driverLicenceObject.cardFileFront == 'object') {
				driverLicenceObject.dtoFrontFileIndex = attachedFiles.length;

				// Add the front file to the attacheds files
				attachedFiles.push(driverLicenceObject.cardFileFront);

				//Delete the front file from the object details
				delete driverLicenceObject.cardFileFront;
			}

			// Back File
			if (typeof driverLicenceObject.cardFileBack == 'object') {
				driverLicenceObject.dtoBackFileIndex = attachedFiles.length;

				// Add the back file to the attacheds files
				attachedFiles.push(driverLicenceObject.cardFileBack);

				//Delete the back file from the object details
				delete driverLicenceObject.cardFileBack;
			}
		}

		return { 
			...WorkerDetails,
			WorkerAvailability,
			WorkerSuperFunds,
			WorkerDocuments,
			WorkerDriverLicences,
			WorkerStatus,
			WorkerCovidTests,
			attachedFiles
		};	
	}

	render() {
		const s = this.state;
		const p = this.props;
		
		var filesArray = [];
		if (!s.isLoading && s.userData && s.userData.WorkerDocuments) {
			s.userData.WorkerDocuments.forEach(function(doc) {
				var typeId = doc.typeId;
				var typeName = doc.typeName;
				var isAlreadyAdded = filesArray.map(function(x) {return x.typeId; }).indexOf(typeId) !== -1;
				
				if (!isAlreadyAdded)
					filesArray.push({ typeId, typeName, files: [] });

				var typeIdIndex = filesArray.map(function(x) {return x.typeId; }).indexOf(typeId);
				filesArray[typeIdIndex].files.push(doc);
			});
		}				
		
		return (
			<div>
				{
					!s.isSaving && (
						<Row>
							{
								!p.isLoggedUser && (
									<Col xs={4} sm={3} md={2}>
										{
											p.onClickBack ? (
												<Button block bsStyle="danger" onClick={p.onClickBack}>
													<Icon name="arrow-left" /> Back
												</Button>
											) : (
												<Link className="btn btn-block btn-danger" to={'/management/workers/' + p.azureId}>
													<Icon name="arrow-left" /> Back
												</Link>
											)
										}
									</Col>
								)
							}
							{
								!s.isLoading && !s.error && (
									<Col xs={6} sm={4} md={3}>
										<Button block bsStyle="success" type="submit" onClick={this.handleOnSave}>
											Save Changes
										</Button>
									</Col>
								)
							}
							{
								!s.isLoading && p.isLoggedUser && (
									<Col xs={6} sm={4} md={3}>
										<Button bsStyle="info" block onClick={() => this.setState({ showQuickPinModal: true })}>
												Set Quick PIN
										</Button>
										{
											s.showQuickPinModal && (
												<QuickPinModal onClose={() => this.setState({ showQuickPinModal: false })} />
											)
										}
									</Col>
								)
							}
						</Row>
					)	
				}
				<br />
				{
					s.isLoading ? (
						<Loader text="Loading data. Please wait..." />
					) : (
						<div>
							{
								s.error && <ErrorBox error={s.error} retryFunc={s.errorMethod} />
							}
							{
								s.userData && (
									s.isSaving ? (
										<Loader text="Saving data. Please wait..." />
									) : (
										<div>
											<Row>
												<Col xs={12}>
													<div className="nav-tabs-custom">
														<ul className="nav nav-tabs">
															<li className="active">
																<a href="#details" data-toggle="tab" aria-expanded="true">
																	<Icon name="user" /> <span className="hidden-sm hidden-xs">Details</span>
																</a>
															</li>
															{
																!p.isLoggedUser && (
																	<li>
																		<a href="#workerAvailability" data-toggle="tab" aria-expanded="true">
																			<Icon name="clock-o" /> <span className="hidden-sm hidden-xs">Availability</span>
																		</a>
																	</li>
																)
															}
															<li>
																<a href="#roles" data-toggle="tab" aria-expanded="true">
																	<Icon name="tasks" /> <span className="hidden-sm hidden-xs">Roles</span>
																</a>
															</li>
															<li>
																<a href="#superFund" data-toggle="tab" aria-expanded="false">
																	<Icon name="usd" /> <span className="hidden-sm hidden-xs">SuperFund</span>
																</a>
															</li>
															<li>
																<a href="#documents" data-toggle="tab" aria-expanded="false">
																	<Icon name="file" /> <span className="hidden-sm hidden-xs">Documents</span>
																</a>
															</li>
															<li>
																<a href="#driverLicence" data-toggle="tab" aria-expanded="false">
																	<Icon name="drivers-license" /> <span className="hidden-sm hidden-xs">Driver Licence</span>
																</a>
															</li>
															{/* Covid Test were removed as per Ally request as it's no longer needed */}
															{/* {
																!p.isLoggedUser && (
																	<li>
																		<a href="#covidTests" data-toggle="tab" aria-expanded="false">
																			<Icon name="heartbeat" /> <span className="hidden-sm hidden-xs">COVID Tests</span>
																		</a>
																	</li>
																)
															} */}
														</ul>
														<div className="tab-content">
															<div className="tab-pane active" id="details">
																<WorkerDetails 
																	id="WorkerDetails" 
																	workerCurrentTFN={s.workerCurrentTFN} 
																	editMode={true} 
																	isLoggedUser={p.isLoggedUser}
																	data={ s.userData } 
																	onChange={this.handleChangeData}  />
															</div>
															{
																!p.isLoggedUser && (
																	<div className="tab-pane" id="workerAvailability">
																		<WorkerAvailability 
																			{ ...s.userData.WorkerAvailability } 
																			id="WorkerAvailability" 
																			isReadOnly={p.isLoggedUser}
																			onChange={this.handleChangeWorkerAvailability}
																			onClearDayOfWeekData={this.handleAvailabilityClearDayOfWeekData}
																			onClearRotate={this.handleClearWorkerAvailabilityRotate}
																		/>
																	</div>
																)
															}
															<div className="tab-pane" id="roles">
																<WorkerStatus editMode={true} isLoggedUser={p.isLoggedUser} data={ s.userData.WorkerStatus } />
															</div>
															<div className="tab-pane" id="superFund">
																<Row>
																	<Col xs={12}>
																		<WorkerSuperFunds id="WorkerSuperFunds" 
																			editMode={true} 
																			data={s.userData.WorkerSuperFunds} 
																			onChange={this.handleChangeData}
																			isEditable={!p.isLoggedUser}
																			isRemovable={!p.isLoggedUser}
																			isActivatable={!p.isLoggedUser}
																		/>
																	</Col>
																</Row>
															</div>
															<div className="tab-pane" id="documents">
																<Row>
																	<Col xs={12}>
																		{
																			p.isLoggedUser ? (
																				<WorkerDocumentsList
																					azureId={p.azureId}
																					documentsList={s.userData.WorkerDocuments}
																				/>
																			) : (
																				<div>
																					<h3 className="title">Add Documents</h3>
																					<WorkerDocuments id="addedDocuments" 
																						data={s.userData.addedDocuments} 
																						onChange={this.handleChangeData} 
																					/>
																					{
																						s.userData.WorkerDocuments && s.userData.WorkerDocuments.length > 0 && (
																							<div>
																								<h3 className="title">Edit Documents</h3>
																								<div className="nav-tabs-custom">
																									<ul className="nav nav-tabs">
																										{
																											filesArray.map((tab, index) => {
																												return (
																													<li className={index === 0 ? 'active' : ''} key={index}>
																														<a href={('#filesTab_' + tab.typeId)} data-toggle="tab" aria-expanded="true">{tab.typeName} ({tab.files.length})</a>
																													</li>
																												);
																											})
																										}
																									</ul>
																									<div id="editDocuments" className="tab-content">
																										{
																											filesArray.map((tab, index) => {
																												return (
																													<div className={('tab-pane ' + (index === 0 ? 'active' : ''))} id={`filesTab_${tab.typeId}`} key={index}>
																														<Form validations={{}}>
																															<div className="table-responsive">
																																<table className="table table-bordered table-condensed">
																																	<thead>
																																		<tr>
																																			<th>Type</th>
																																			<th>Description *</th>
																																			<th>Number</th>
																																			<th>Expiry Date</th>
																																			{ !p.isLoggedUser && <th>Archived</th> }
																																			{ !p.isLoggedUser && <th>Delete</th> }
																																		</tr>
																																	</thead>
																																	<tbody>
																																		{
																																			tab.files.map((file, indexFile) => {
																																				var { id, typeName, description, number, expiryDate, isArchived } = file;
																																				if (p.isLoggedUser)
																																					return (
																																						<tr key={indexFile}>
																																							<td>{ typeName }</td>
																																							<td>{ description }</td>
																																							<td>{ number != '' ? number : '-' }</td>
																																							<td>{ moment(expiryDate).isValid() ? moment(expiryDate).format('DD/MM/YYYY') : '-' }</td>
																																							<td></td>
																																							<td></td>
																																						</tr>
																																					);
					
																																				return (
																																					file.isDeleted ? (
																																						<tr key={indexFile}>
																																							<td><strike>{ typeName }</strike></td>
																																							<td><strike>{ description }</strike></td>
																																							<td><strike>{ number != '' ? number : '-' }</strike></td>
																																							<td><strike>{ moment(expiryDate).isValid() ? moment(expiryDate).format('DD/MM/YYYY') : '-' }</strike></td>
																																							<td width={40} className="text-center text-middle"><strike>{ isArchived ? 'Yes' : 'No' }</strike></td>
																																							<td width={40} className="text-center text-middle">
																																								<a className="text-success cursor-pointer" onClick={() => { this.handleToggleDeletedDocument(id);}}>
																																									<Icon name="undo" size="2x" title="Undo deletion" />
																																								</a>
																																							</td>
																																						</tr>
																																					) : (
																																						<tr key={indexFile}>
																																							<td>{ typeName }</td>
																																							<td><FormControl id="description" className="required" value={description} onChange={(event) => { this.handleChangeEditedDocumentInput(event, id);}} /></td>
																																							<td><FormControl id="number" className="required" value={number} onChange={(event) => { this.handleChangeEditedDocumentInput(event, id);}} /></td>
																																							<td>
																																								<FormControl 
																																									id="expiryDate" 
																																									type="date"
																																									value={moment(expiryDate).isValid() ? moment(expiryDate).format('YYYY-MM-DD') : ''} 
																																									onChange={event => this.handleChangeEditedDocumentInput(event, id)} 
																																								/>
																																							</td>
																																							<td width={40} className="text-center text-middle">
																																								<input id="isArchived" type="checkbox" checked={isArchived} onChange={event => this.handleChangeEditedDocumentInput(event, id)} />
																																							</td>
																																							<td width={40} className="text-center text-middle">
																																								<a className="text-danger cursor-pointer" onClick={() => this.handleToggleDeletedDocument(id)}>
																																									<Icon name="trash" size="2x" />
																																								</a>
																																							</td>
																																						</tr>
																																					)
																																				);
																																			})
																																		}
																																	</tbody>
																																</table>
																															</div>
																														</Form>
																													</div>
																												);
																											})
																										}
																									</div>
																								</div>
																							</div>
																						)
																					}
																				</div>
																			)
																		}
																	</Col>
																</Row>
															</div>
															<div className="tab-pane" id="driverLicence">
																<Row>
																	<Col xs={12}>
																		<WorkerDriverLicences 
																			id="WorkerDriverLicences" 
																			editMode={true} 
																			workerId={p.azureId} 
																			data={s.userData.WorkerDriverLicences} 
																			onChange={this.handleChangeData} 
																			isEditable={!p.isLoggedUser}
																			isRemovable={!p.isLoggedUser}
																			isActivatable={!p.isLoggedUser}
																		/>
																	</Col>
																</Row>
															</div>
															{/* <div className="tab-pane" id="covidTests">
																<Row>
																	<Col xs={12}>
																		<WorkerCovidTests 
																			id="WorkerCovidTests"
																			workerId={p.azureId} 
																			WorkerCovidTests={s.userData.WorkerCovidTests} 
																			onChange={data => this.handleChangeData(data, 'WorkerCovidTests')} 
																		/>
																	</Col>
																</Row>
															</div> */}
														</div>
													</div>
												</Col>	        
											</Row>
											<Row>
												<Col xs={12}>
													<FormGroup className="text-right">
														<Button bsSize="lg" bsStyle="success" type="submit" onClick={this.handleOnSave}>
															Save Changes
														</Button>
													</FormGroup>
												</Col>
											</Row>
										</div>
									)
								)
							}
						</div>
					)
				}
			</div>
		);
	}
}

WorkerEdit.propTypes = propTypes;

export default WorkerEdit;