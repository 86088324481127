import React, { useEffect, useState } from 'react';
import ListsApi from '../../../app/api/ListsApi';
import ListContainer from './_ListContainer';
import { v4 as uuid } from 'uuid';

export const RunTypesList = props => {
	// Used to update the list in case props change
	const [componentKey, setComponentKey] = useState();
	
	const { onlyActive, taskTypeId } = props;
	useEffect(() => {
		setComponentKey(uuid());
	}, [onlyActive, taskTypeId]);

	if (!componentKey)
		return null;

	return (
		<ListContainer
			key={componentKey} 
			{...props}
			promise={() => ListsApi.getRunTypes(props.onlyActive, props.taskTypeId)}
		/>
	)
};