import React, { Component } from 'react';
import moment from 'moment';
import toastr from 'toastr';
import Icon from 'react-fontawesome';
import { connect } from 'react-redux';
import { Row, Col, FormGroup, Button, InputGroup, FormControl } from 'react-bootstrap';

import { setCircleLocation, setCircleRadius, setBreadcrumbs } from '../../../stores/redux/map';
import TrackingApi from '../../../app/api/TrackingApi';

import { MainContent } from '../../common/layout';
import { ContentBox, Loader } from '../../common/uiElements';
import { DatePeriodInput, DropDownList, RangeSlider, VehicleTypes as VehicleTypesInput } from '../../common/inputs';
import GoogleMapVehiclesByPlace from '../../common/GoogleMapVehiclesByPlace';

import { VehiclesByPlaceSettings } from '../../../app/schemas/ReportsSettings';
import { VehicleTypes } from '../../../app/models/Vehicle';

class VehiclesByPlace extends Component {
	constructor() {
		super();
		this.state = {
			data: null,
			isLoading: false,
			selectedFleetNumber: null,
			vehTypeFilter: [
				VehicleTypes.PALLET_JACK,
				VehicleTypes.RIGID,
				VehicleTypes.TRAILER,
				VehicleTypes.PRIME_MOVER,
				VehicleTypes.B_DOUBLE,
				VehicleTypes.DOLLY
			],
		};

		this.periodRanges = {
			'Last 1 hour': [
				moment().add(-1, 'hours'),
				moment()
			],
			'Last 6 hours': [
				moment().add(-6, 'hours'),
				moment()
			],
			'Last 8 hours': [
				moment().add(-8, 'hours'),
				moment()
			],
			'Last 1 day': [
				moment().add(-1, 'days'),
				moment()
			],
			'Last 2 days': [
				moment().add(-2, 'days'),
				moment()
			]
		};
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	UNSAFE_componentWillMount() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position)=> {
				let lat = position.coords.latitude;
				let lng = position.coords.longitude;
				this.props.setCircleLocation(lat, lng);
			});
		}
	}

	clearSearch = () => {
		var { range } = this.props.filters;
		this.setState({ selectedFleetNumber: null, data: null });
		this.props.setBreadcrumbs(null);
		this.handleUpdateRange(range);
	}

	handleUpdateRange = (range) => {
		var { lat, lng } = this.props.filters.location;
		this.props.setCircleRadius(range);
		this.props.setCircleLocation(lat, lng);
	}

	onSearch = () => {
		this.clearSearch();

		// Location
		var { location, range, startTime, endTime } = this.props.filters;
		var lat = location.lat;
		var lng = location.lng;

		this.props.setCircleLocation(lat, lng);
		this.props.setCircleRadius(range);

		this.setState({ isLoading: true });
		TrackingApi.vehiclesByPlace(lat, lng, range, startTime, endTime, this.state.vehTypeFilter)
			.then(data => {
				if (this.unmounted) return;

				this.setState({ isLoading: false, data });
				toastr.success('Your search has returned ' + data.length + ' vehicles.', 'Success!');
				window.$('html, body').animate({scrollTop: window.$('#divMap').offset().top - 50}, 500);
			})
			.catch((error) => {
				this.setState({ isLoading: false });
				console.error(error);
			});
	}

	fetchBreadcrumbs = () => {
		if (!this.state.selectedFleetNumber)
			return;
		this.setState({ isLoading: true });

		var { startTime, endTime } = this.props.filters;
		TrackingApi.breadcrumbsByVehRange(this.state.selectedFleetNumber, startTime, endTime)
			.then(breadcrumbs => {
				if (this.unmounted) return;
			
				this.setState({ isLoading: false});
				this.props.setBreadcrumbs(breadcrumbs);
				toastr.success('Your search has returned ' + breadcrumbs.length + ' breadcrumbs.', 'Success!');
			})
			.catch(error => {
				this.setState({ isLoading: false});
				console.error(error);
			});
	}

	handleSelectVehicle = vehFleetNumber => {
		this.props.setBreadcrumbs(null);
		this.setState({ 
			selectedFleetNumber: vehFleetNumber 
		}, () => vehFleetNumber && this.fetchBreadcrumbs());
	}

	render() {
		const s = this.state;

		let vehiclesList = [];
		const { 
			rangeMetersStartValue, rangeMetersMin, rangeMetersMax, rangeMetersPips,
			dateStartMinutes, dateFinishMinutes, periodLimitInMinutes
		} = VehiclesByPlaceSettings;

		if (s.data && s.data.length > 0) {
			vehiclesList = s.data.map(data => {
				var vehicleData = {};
				vehicleData.value = data.vehFleetNumber;
				vehicleData.label = data.vehFleetNumber + (data.vehRego ? (' - ' + data.vehRego) : '');
				return vehicleData;
			});
		}

		return (
			<MainContent title="Vehicles By Place">
				{ s.isLoading && <Loader overlay /> }					
				<ContentBox title="Additional Filters" icon="filter" color="warning" minimize={true} startMinimized={true}>
					<Row>
						<Col xs={12} lg={5}>
							<FormGroup id="vehicleTypes">
								<label>Vehicle Types</label>
								<VehicleTypesInput
									value={s.vehTypeFilter}
									onChange={vehTypeFilter => this.setState({ vehTypeFilter })}
								/>
							</FormGroup>
						</Col>
						<Col xs={12} lg={7}>
							<FormGroup>
								<label>Range (in meters)</label>
								<RangeSlider 
									inputField
									start={rangeMetersStartValue} 
									min={rangeMetersMin} 
									max={rangeMetersMax} 
									pips={rangeMetersPips} 
									postfix="m" 
									onUpdate={this.handleUpdateRange} 
								/>
							</FormGroup>
						</Col>
					</Row>
				</ContentBox>
				<ContentBox title="Map" icon="map" color="primary">
					<Row>
						<Col sm={6}>
							<FormGroup>
								<InputGroup>
									<InputGroup.Addon>
										<Icon name="map-marker" />
									</InputGroup.Addon>
									<FormControl
										className="address-input"
										placeholder="Enter the Address" 
										onChange={this.clearSearch}
									/>
								</InputGroup>
							</FormGroup>
						</Col>
						<Col sm={6}>
							<FormGroup>
								<DatePeriodInput 
									ranges={this.periodRanges}
									openDirection="left"
									showHours={true}
									startDateInMinutes={dateStartMinutes}
									endDateInMinutes={dateFinishMinutes}
									periodLimitInMinutes={periodLimitInMinutes}
									onChange={this.clearSearch}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							<FormGroup>
								<Button 
									block
									bsStyle="primary"
									disabled={s.isLoading}
									onClick={this.onSearch}
								>
									<Icon name="search" /> Search
								</Button>
							</FormGroup>
						</Col>
					</Row>
					<div id="divMap">
						<Row>
							<Col xs={12}>
								<GoogleMapVehiclesByPlace 
									height="57vh" 
									onDropMarker={this.clearSearch}
									onChangePlace={this.clearSearch}
								/>
								<DropDownList 
									onChange={this.handleSelectVehicle} 
									placeholder={vehiclesList && vehiclesList.length > 0 ? '--- Select a vehicle ---' : 'No vehicles found'}
									className={`map-control top-left-control ${!s.data || s.data.length === 0 ? 'hidden' : ''}`}
									value={s.selectedFleetNumber}
									data={vehiclesList} 
								/>
							</Col>
						</Row>
					</div>
				</ContentBox>
			</MainContent>
		);
	}
}

const mapStateToProps = state => ({ filters: state.filters });
const mapDispatchToProps = { setBreadcrumbs, setCircleLocation, setCircleRadius };

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesByPlace);