import React from 'react';
import PropTypes from 'prop-types';

import { MainContent } from '../../../common/layout';
import WorkerViewDetails from './subComponents/WorkerViewDetails';

const WorkersViewContainer = props => (
    <MainContent title="Worker Details">
        <WorkerViewDetails azureId={props.params.azureId} />
    </MainContent>
);

WorkersViewContainer.propTypes = {
    params: PropTypes.shape({
        azureId: PropTypes.string.isRequired
    }).isRequired
};

export default WorkersViewContainer;