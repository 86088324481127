import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { setZoom, setPosition } from '../../../stores/redux/map';
import { ExceptionsReportSettings } from '../../../app/schemas/ReportsSettings';

import { ExceptionsList } from '../../common/tracking';
import { MainContent } from '../../common/layout';

import { DatePeriodInput } from '../../common/inputs';
import { FiltersBox, ContentBox } from '../../common/uiElements';
import { Row, Col, FormGroup } from 'react-bootstrap';

class ReportExceptions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			timestamp: moment()
		};

		this.periodRanges = {
			'Last 1 hour': [
				moment().add(-1, 'hours'),
				moment()
			],
			'Last 6 hours': [
				moment().add(-6, 'hours'),
				moment()
			],
			'Last 8 hours': [
				moment().add(-8, 'hours'),
				moment()
			],
			'Last 1 day': [
				moment().add(-1, 'days'),
				moment()
			],
			'Last 2 days': [
				moment().add(-2, 'days'),
				moment()
			]
		};
	}
	
	onSelectMapDataPoint = vehicleData => {
		var { lat, lng } = vehicleData;

		this.props.setZoom(19);
		this.props.setPosition(lat, lng);
	}

	onChangePeriod = () => {
		return this.setState({ timestamp: moment() });
	}

	render() {
		return (
			<div>
				<MainContent title="Exceptions" subtitle="">
					<FiltersBox>
						<Row>
							<Col xs={12}>
								<FormGroup>
									<label>Period:</label>
									<DatePeriodInput 
										ranges={this.periodRanges}
										showHours={true} 
										startDateInMinutes={ExceptionsReportSettings.dateStartMinutes} 
										endDateInMinutes={ExceptionsReportSettings.dateFinishMinutes} 
										periodLimitInMinutes={ExceptionsReportSettings.periodLimitInMinutes} 
										onChange={this.onChangePeriod}
									/>
								</FormGroup>
							</Col>
						</Row>
					</FiltersBox>
					<div key={this.state.timestamp}>
						<ContentBox title="Exceptions List" icon="warning" color="warning">
							<ExceptionsList onSelectException={this.onSelectMapDataPoint}/>
						</ContentBox>
					</div>
				</MainContent>
			</div>
		);
	}
}

const mapDispatchToProps = { setZoom, setPosition };

export default connect(null, mapDispatchToProps)(ReportExceptions);
