/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import isMobile from 'ismobilejs';

import { setLocationFilter } from '../../stores/redux/filter';
import { setBreadcrumbs } from '../../stores/redux/map';
import InfoWindowContent from './InfoWindowContent';

//Global Vars
var breadcrumbsData = [];
var mapMarkerBreadcrumbs = [];

var circleDrawed = null;
var geocoder = null;
var infowindow = null;
var map = null;
var marker = null;
var searchBox = null;

const propTypes = {
	lat: PropTypes.number,
	lng: PropTypes.number,
	height: PropTypes.string,
	radiusMeters: PropTypes.number,
	typeControl: PropTypes.bool
};

const defaultProps = {
	lat: -33.7555692, // Micway Position
	lng: 150.90469859999996, // Micway Position
	height: '70vh',
	radiusMeters: 0,
	typeControl: false
};

class GoogleMapVehiclesByPlace extends Component{
	setUserCurrentPosition = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position)=> {
				var pos = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};

				map.setCenter(pos);
				marker.setMap(null);
				this.drawDragMarker(pos);
				this.fillAddressInput(pos);
				this.props.setLocationFilter(pos.lat, pos.lng);
			});
		}
	}

	componentDidMount() {
		map = this.initMap();
		const { 
			lat,
			lng,
		} = this.props;

		this.drawDragMarker({lat, lng});
		this.fillAddressInput({lat, lng});


		window.$('.map-control').each(function() {
			var element = window.$(this)[0];
			var cssClasses = window.$(element).attr('class');
			if (cssClasses.indexOf('top-left-control') !== -1)
				map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(element);
		});

		var markers = [];

		var addressInput = window.$('.address-input')[0];
		if (addressInput) {
			searchBox = new window.google.maps.places.SearchBox(addressInput);

			// Bias the SearchBox results towards current map's viewport.
			map.addListener('bounds_changed', function() {
				searchBox.setBounds(map.getBounds());
			});

		
			// Listen for the event fired when the user selects a prediction and retrieve
			// more details for that place.
			searchBox.addListener('places_changed', () => {
				var places = searchBox.getPlaces();

				if (places.length === 0) {
					return;
				}
				marker.setMap(null);
				// Clear out the old markers.
				markers.forEach(function(marker) {
					marker.setMap(null);
				});
				markers = [];

				// For each place, get the icon, name and location.
				const bounds = new window.google.maps.LatLngBounds();

				if (places && places.length > 0) {
				// Get just the first place result
					const place = places[0];

					if (!place.geometry) {
						console.error('Returned place contains no geometry');
						return;
					}

					this.drawDragMarker(place.geometry.location);
					this.props.setLocationFilter(place.geometry.location.lat(), place.geometry.location.lng());

					this.props.onChangePlace && this.props.onChangePlace();

					if (place.geometry.viewport) {
						bounds.union(place.geometry.viewport);
					} else {
						bounds.extend(place.geometry.location);
					}
					map.fitBounds(bounds);
				}
			});
		}

		window.google.maps.event.addListener(map, 'dblclick', e => { 
			const lat = e.latLng.lat();
			const lng = e.latLng.lng();
			marker.setMap(null);
			this.fillAddressInput({lat, lng});
			this.onDropMarker(lat, lng);
			this.drawDragMarker({lat, lng});
		});

		this.setUserCurrentPosition();
		infowindow = new window.google.maps.InfoWindow({ maxWidth: 250 });
	}

	drawDragMarker = (position) => {
		marker = new window.google.maps.Marker({
			position: position,
			map: map,
			draggable: true,
			title: 'Drag me!'
		});

		const self = this;
		window.google.maps.event.addListener(marker, 'dragend', function(){
			const lat = this.getPosition().lat();
			const lng = this.getPosition().lng();
			self.fillAddressInput({lat, lng});
			self.onDropMarker(lat, lng);
		});
	}

	createInfoWindow = (data) => {
		if (data && data !== undefined) {
			var content = document.createElement('div');
			ReactDOM.render(<InfoWindowContent 
				batteryVoltage={data.trkVehicleBattVolt} 
				isDoorOpen={data.trkIsDoorOpen} 
				isEngineOn={data.trkIsEngineOn} 
				isFreezerOn={data.trkIsFreezer}
				pingTime={data.trkCollectedOnEpoch}
				roadSpeedLimit={data.trksptRoadSpeedLimit}
				speed={data.trkSpeedKm}
				tempZone1={data.trkTempZone1}
				tempZone2={data.trkTempZone2} 
				tempZone3={data.trkTempZone3}
			/>, content);

			return content;
		} else {
			return '';
		}    
	}

	onDropMarker = (lat, lng) => {
		this.fillAddressInput({lat, lng});
		this.props.setBreadcrumbs(undefined);
		this.props.setLocationFilter(lat, lng);

		if (this.props.onDropMarker)
			this.props.onDropMarker(lat, lng);
	}

	initMap = () => {
		geocoder = new window.google.maps.Geocoder();
		const { lat, lng, typeControl, map } = this.props;

		return window.map = new window.google.maps.Map(this.mapElement,
			{
				center: { lat, lng },
				gestureHandling: isMobile.any ? 'cooperative' : 'greedy',
				mapTypeControl: typeControl,
				disableDoubleClickZoom: true,
				zoom: map.zoom,

				// Set the Zoom option to the left bottom
				zoomControlOptions: {
					position: window.google.maps.ControlPosition.RIGHT_BOTTOM
				},

				// Set the Street view options to the left
				streetViewControlOptions: {
					position: window.google.maps.ControlPosition.RIGHT_BOTTOM
				}

			});
	}

	drawCircle = (lat, lng, radiusMeters) => {
		if (circleDrawed)
			circleDrawed.setMap(null);

		circleDrawed = new window.google.maps.Circle({
			strokeColor: '#3c8dbc',
			strokeOpacity: 0.6,
			strokeWeight: 2,
			fillColor: '#3c8dbc',
			fillOpacity: 0.15,
			map: map,
			center: { 
				lat: parseFloat(lat), 
				lng: parseFloat(lng) 
			},
			radius: parseFloat(radiusMeters)
		});
	}

	drawBreadcrumbs = (data) => {
		var self = this;

		if (mapMarkerBreadcrumbs.length > 0)
			this.clearBreadcrumbs();

		if (typeof data != 'undefined') {
			breadcrumbsData = data;

			// Load each point from the json and add to the map
			for (var index in data) {
				// Check if the lat & long is not invalid
				if (data[index] && data[index].trkPositionLat !== null && data[index].trkPositionLong !== null && data[index].trkPositionLat !== 0 && data[index].trkPositionLong !== 0) {

				// The color of the point is based on the speed or if the truck is parked
					var iconColor = 'black';

					if (data[index].trkIsEngineOn === false) {
						iconColor = 'black';
					} else {
						if (data[index].trksptIsOverSpeedLimit) {
							iconColor = '#A30B00';
						}
						else if (data[index].trkSpeedKm <= 20) {
							iconColor = '#0C5200';
						}
						else if (data[index].trkSpeedKm <= 50) {
							iconColor = '#0091B2';
						}
						else if (data[index].trkSpeedKm <= 80) {
							iconColor = '#0064B2';
						}
						else if (data[index].trkSpeedKm <= 95) {
							iconColor = '#0046B2';
						}
						else {
							iconColor = '#A30B00';
						} 
					}

					// Create the icon
					mapMarkerBreadcrumbs[index] = new window.google.maps.Marker({
						map:        map,
						icon:       {
							path:           window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
							scale:          1.8,
							strokeColor:    iconColor,
							rotation: parseInt(data[index].trkGpsHeading, 10)
						},
						infowindow,
						position: new window.google.maps.LatLng(data[index].trkPositionLat, data[index].trkPositionLong),
						zIndex:     parseInt(index, 10)
					});
					
					// eslint-disable-next-line 
					window.google.maps.event.addListener(mapMarkerBreadcrumbs[index], 'click', function() {
						infowindow && infowindow.close();

						var i = this.zIndex;
						var infowindowContent = self.createInfoWindow(breadcrumbsData[i]);  

						infowindow.setContent(infowindowContent);
						this.infowindow.open(map, this);
					});

				}
			}
		}
	}

	clearBreadcrumbs = () => {
		mapMarkerBreadcrumbs.forEach(function arrayElements(element) {
			element.setMap(null);
		});

		mapMarkerBreadcrumbs = [];
	}

	fillAddressInput = (pos) => {
		geocoder.geocode({
			latLng: pos
		}, function(responses) {
			if (responses && responses.length > 0) {
				window.$('.address-input').val(responses[0].formatted_address);
			} else {
				window.$('.address-input').val('Cannot determine address at this location.');
			}
		});
	}

	render() {   
		const p = this.props;
		const { breadcrumbs, circle } = p.map;

		if (breadcrumbs)
			this.drawBreadcrumbs(breadcrumbs);
		else if(mapMarkerBreadcrumbs.length > 0)
			this.clearBreadcrumbs();

		if (circle) 
			this.drawCircle(circle.lat, circle.lng, circle.radiusMeters);

		return (
			<div className="google-map">
				<div 
					ref={ref => this.mapElement = ref}
					style={{height: p.height}}
				></div>
			</div>
		);
	}
}

GoogleMapVehiclesByPlace.defaultProps = defaultProps;
GoogleMapVehiclesByPlace.propTypes = propTypes;

const mapStateToProps = state => ({ map: state.map });
const mapDispatchToProps = { setLocationFilter, setBreadcrumbs };

export default connect(mapStateToProps, mapDispatchToProps)(GoogleMapVehiclesByPlace);