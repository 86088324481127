import groups from '../app/schemas/PermissionGroups';
import SmsContainer from '../components/features/communication/sms';

export default { 
	path: 'communication',
	menuItem: { title: 'Communication', icon: 'envelope' },
	authorize: [groups.ADMIN_STAFF_FULL],
	childRoutes: [
		{ 
			path: 'SMS', 
			menuItem: { title: 'SMS', icon: 'commenting' },
			component: SmsContainer
		}
	]
};