import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Icon from 'react-fontawesome';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';

import { setDefaultMap, setBreadcrumbs, setMarkers, setZoom, setPosition  } from '../../../stores/redux/map';
import TrackingApi from '../../../app/api/TrackingApi';
import { JourneyStepsReportSettings } from '../../../app/schemas/ReportsSettings';
import { MainContent } from '../../common/layout';
import { Callout, ContentBox, FiltersBox, Loader, ErrorBox } from '../../common/uiElements';

import GoogleMap from '../../common/GoogleMapOld';
import { JourneyStepsList } from '../../common/tracking';
import { DatePeriodInput, VehicleSearch } from '../../common/inputs';

class ReportJourneySteps extends Component {
	constructor() {
		super();
		this.state = {
			selectedVehicle: undefined,
			selectedStep: undefined,
			startTime: moment().utc().unix(),
			finishTime: moment().utc().unix(),
			showList: false,
			showMap: false,
			isLoadingMap: false
		};

		this.periodRanges = {
			'Last 6 hours': [
				moment().add(-6, 'hours'),
				moment()
			],
			'Last 8 hours': [
				moment().add(-8, 'hours'),
				moment()
			],
			'Last 24 hours': [
				moment().add(-1, 'days'),
				moment()
			],
			'Last 3 days': [
				moment().add(-3, 'days'),
				moment()
			],
			'Last 7 days': [
				moment().add(-7, 'days'),
				moment()
			]
		};
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	handleChangeVehicle = (vehicle) => {
		return this.setState({
			selectedVehicle: vehicle,
			showList: false
		});
	}

	handleChangeDatePeriod = (startTime, finishTime) => {
		return this.setState({ 
			startTime: moment(startTime).utc().unix(),
			finishTime: moment(finishTime).utc().unix(),
			showList: false
		});
	}

	handleSelectStep = (step) => {        
		var { selectedVehicle } = this.state;
		var { StartTimeEpoch, FinishTimeEpoch } = step;

		this.setState({ 
			selectedStep: step, 
			showMap: true, 
			isLoadingMap: true,
			error: null
		}, this.props.setDefaultMap);

		TrackingApi.breadcrumbsByVehPeriod(selectedVehicle.vehFleetNumber, StartTimeEpoch, FinishTimeEpoch)
			.then(breadcrumbs => {
				if (this.unmounted) return;
			
				if (step.isMoving) {
					this.props.setBreadcrumbs(breadcrumbs);
				}                
				else {
					var { PositionStartLat, PositionStartLng } = step;
					const marker = {
						...selectedVehicle,
						lat: PositionStartLat,
						lng: PositionStartLng,
						infowindow: {
							...breadcrumbs[0]
						}
					};

					this.props.setMarkers([marker]);
					this.props.setZoom(18);
					this.props.setPosition(PositionStartLat, PositionStartLng);
				}

				this.setState({ isLoadingMap: false });
				return window.$('html, body').animate({scrollTop: window.$("#mapBox").offset().top - 50}, 500);
			})
			.catch(error => {
				console.error(error);
				if (this.unmounted) return;
				this.setState({ error, isLoadingMap: false });
			});
	}

	handleSearch = () => {
		var { startTime, selectedVehicle } = this.state;
		if (startTime && selectedVehicle)
			return this.setState({ 
				error: null,
				showList: false,
				selectedStep: null,
				showMap: false
			}, () => this.setState({ showList: true }));
	}

	render() {
		const s = this.state;
		const { dateStartMinutes, dateFinishMinutes, periodLimitInMinutes } = JourneyStepsReportSettings;

		return (
			<MainContent title="Journey Steps" subtitle="">
				<FiltersBox>
					<Row>
						<Col md={5}>
							<FormGroup>
								<VehicleSearch 
									value={s.selectedVehicle ? s.selectedVehicle.vehFleetNumber : ''}
									onChange={this.handleChangeVehicle} 
								/>
							</FormGroup>
						</Col>
						<Col md={5}>
							<FormGroup>
								<DatePeriodInput 
									showHours
									ranges={this.periodRanges}
									openDirection="left"
									startDateInMinutes={dateStartMinutes} 
									endDateInMinutes={dateFinishMinutes} 
									periodLimitInMinutes={periodLimitInMinutes} 
									onChange={this.handleChangeDatePeriod} 
								/>
							</FormGroup>
						</Col>
						<Col md={2}>
							<FormGroup>
								<Button
									block
									bsStyle="primary" 
									disabled={s.selectedVehicle ? false : true} 
									onClick={this.handleSearch} 
								>
									Search <Icon name="search"/>
								</Button>
							</FormGroup>
						</Col>
					</Row>
				</FiltersBox>
				{
					s.error ? (
						<ErrorBox error={s.error} retryFunc={this.handleSearch} />
					) : (
						s.showList && (
							<Fragment>
								<ContentBox title="Steps List" icon="list-ol" color="primary">
									<JourneyStepsList 
										startTime={s.startTime}
										finishTime={s.finishTime} 
										vehFleetNumber={s.selectedVehicle.vehFleetNumber} 
										onSelectStep={this.handleSelectStep}
									/>
								</ContentBox>
								{ 
									!s.selectedStep && (
										<Callout color="info" text="Select a step on the list above too see it on the map" /> 
									)
								}
								{
									s.showMap && (
										<div id="mapBox">
											<ContentBox title="Map" icon="map" color="primary">
												{
													s.isLoadingMap ? (
														<Loader />
													) : (
														<GoogleMap height="50vh"/>
													)
												}
											</ContentBox>
										</div>
									)
								}
							</Fragment>
						)
					)
				}
			</MainContent>
		);
	}
}

const mapDispatchToProps = { setDefaultMap, setBreadcrumbs, setMarkers, setZoom, setPosition  };

export default connect(null, mapDispatchToProps)(ReportJourneySteps);