import companyEndpoints from './endpoints/companyEndpoints';
import httpRequests from './httpRequests';
import { ListRequestOptions } from '../models/Application';

// TODO Create Interface Model for Company 
export default {
		/**
	 * Get the Worker List
	 */
	getCompaniesList(filters: ListRequestOptions) {
		const endpoint = companyEndpoints.root;
		let filterString;
		if (filters)
		filterString = JSON.stringify(filters);

		return httpRequests.get(endpoint, { filterString });
	},

	/**
	 * Gets a company details
	 * @param abn
	 * @param includeArchivedDocuments
	 */
	getCompany(abn: string, includeArchivedDocuments: boolean) {
		const endpoint = companyEndpoints.byAbn(abn);
		return httpRequests.get(endpoint, { includeArchivedDocuments });
	},

	/**
	 * Download a document
	 * @param {string} azureId Worker Azure ID 
	 * @param {string} fileId File ID
	 */
	getCompanyDocument(abn: string, fileId: number) {
		const endpoint = companyEndpoints.companyDocument(abn, fileId);
		return httpRequests.downloadFile(endpoint);
	},

	/**
	 * 
	 * @param data Company's data
	 * @param abn 
	 */
	saveCompanyData(data: object, abn: string) {
		const endpoint = abn ? companyEndpoints.byAbn(abn) : companyEndpoints.root;
		
		if (abn)// If it's an Update
			return httpRequests.putWithFiles(endpoint, data);
		else
			return httpRequests.postWithFiles(endpoint, data);
	},

	/**
	 * Inactivate a company
	 * @param abn 
	 */
	inactivateCompany(abn: string) {
		const endpoint = companyEndpoints.byAbn(abn);
		return httpRequests.delete(endpoint);
	},

	/**
	 * Check if a company exists based on its ABN
	 * @param abn 
	 */
	checkCompanyExists(abn: string) {
		const endpoint = companyEndpoints.checkCompanyExists(abn);
		return httpRequests.get(endpoint);
	}
}