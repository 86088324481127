/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormControl } from 'react-bootstrap';

const propTypes = {
	required: PropTypes.bool,
	data: PropTypes.array,
	value: PropTypes.string,
	selectedValue: PropTypes.string,
	placeholder: PropTypes.string,
	noResultsText: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	style: PropTypes.object
};

const defaultProps = {
	className: '',
	defaultText: 'Select an item...',
	noResultsText: 'No results found',
	placeholder: 'Select an item...'
};

class DropDownList extends Component {
	componentDidMount() {
		const { data, value, selectedValue } = this.props;
    
		if (!value && selectedValue) {
			const valueIsInData = window.$.grep(data, p => p.value == selectedValue).length > 0;
      
			if (valueIsInData)
				this.props.onChange(selectedValue);
		}
	}

	handleChange = e => {
		if (e && e.value !== null)
			return this.props.onChange(e.value);

		this.props.onChange(null);
	}

	render() {  
		const { props } = this;

		return (
			<>
				<Select
					autoBlur
					cache={false}
					className={props.className}
					disabled={props.disabled}
					name={props.id}
					value={props.value || props.selectedValue}
					options={props.data}
					onChange={this.handleChange}
					placeholder={props.placeholder}
					noResultsText={props.noResultsText}
					style={props.style}
				/>
				<FormControl 
					id={props.id} 
					required={props.required} 
					value={(props.value || props.selectedValue ? 'OK' : '')} 
					type="hidden" 
				/>
			</>
		);
	}
}

DropDownList.propTypes = propTypes;
DropDownList.defaultProps = defaultProps;

export { DropDownList };