import React from 'react';
import ReactTable, { RowInfo, Column } from 'react-table';
import { IVehicleListDetails } from '../../../../../app/models/Vehicle';
import { IListComponentProps } from '../../../../../app/models/Application';
import { convertMetersToKm, convertSecondsToHours } from '../../../../../app/helpers/numbersHelper';

const VehiclesList: React.FC<IListComponentProps<IVehicleListDetails>> = props => (
	<div id="vehicles-list">
		<ReactTable
			manual
			minRows={0}
			data={props.records}
			loading={props.isLoading}
			page={props.page}
			pages={props.totalPages}
			pageSizeOptions={props.pageSizeOptions}
			pageSize={props.pageSize}
			onPageChange={props.onPageChange}
			onPageSizeChange={props.onPageSizeChange}
			onSortedChange={props.onSortedChange}
			getTdProps={(state: any, rowInfo?: RowInfo, column?: Column) => {
				if (!rowInfo || column?.id === 'trackingUnitPhoneNumber')
					return {};

				const vehicle = rowInfo.original as IVehicleListDetails;
				return {
					onClick: () => props.onSelectRow && props.onSelectRow(vehicle.fleetNumber, rowInfo.index)
				}
			}}
			columns={[
				{
					Header: 'Fleet Number',
					accessor: 'fleetNumber',
					width: 110
				},
				{
					Header: 'Type',
					accessor: 'typeName',
					width: 100
				},
				{
					Header: 'REGO',
					accessor: 'regoNumber',
					width: 80,
					Cell: row => row.value || '-'
				},
				{
					Header: 'REGO State',
					accessor: 'regoState',
					width: 90,
					Cell: row => row.value || '-'
				},
				{
					Header: 'Working State',
					accessor: 'workingState',
					width: 100,
					Cell: row => row.value || '-'
				},
				{
					Header: 'Make',
					accessor: 'makeName',
					width: 100
				},
				{
					Header: 'Year',
					accessor: 'modelYear',
					width: 65,
					Cell: row => row.value || '-'
				},
				{
					Header: 'Odometer',
					accessor: 'odometerTotalMeter',
					width: 100,
					Cell: row => row.value ? convertMetersToKm(row.value, true) : '-'
				},
				{
					Header: 'Freezer On',
					accessor: 'freezerOnTotalSec',
					width: 100,
					Cell: row => row.value ? convertSecondsToHours(row.value, true) : '-'
				},
				{
					Header: 'Tracking ID',
					accessor: 'trackingUnitId',
					width: 100,
					sortable: false,
					Cell: row => row.value || '-'
				},
				{
					Header: 'Tracking Phone Number',
					accessor: 'trackingUnitPhoneNumber',
					width: 170,
					sortable: false,
					Cell: row => {
						if (!row.value) 
							return '-';

						return (
							<a href={`tel:${row.value}`}>
								{ row.value }
							</a>
						)
					}
				},
				{
					Header: 'Status',
					accessor: 'isActive',
					Cell: row => row.value ? (
						<b className="text-success">
							Active
						</b>
					) : (
						<b className="text-danger">
							Inactive
						</b>
					)
				},
			]}
		/>
	</div>
);

export default VehiclesList;