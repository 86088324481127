import React, { ChangeEvent } from 'react';
import Icon from 'react-fontawesome';
import { MainContent } from '../../../common/layout';
import { SortingRule} from 'react-table';
import VehiclesListFilter from './list/VehiclesListFilter';
import { ContentBox, ErrorBox } from '../../../common/uiElements';
import { IVehicleListDetails, VehiclesListFilter as VehiclesListFilterClass } from '../../../../app/models/Vehicle';
import { IError, ListRequestOptions, ListReturn } from '../../../../app/models/Application';
import VehiclesList from './list/VehiclesList';
import componentRequestHandler from '../../../../app/api/helpers/componentRequestHandler';
import VehiclesApi from '../../../../app/api/VehiclesApi';
import VehicleDetailsModal from './details/VehicleDetailsModal';
import { Button, FormGroup, FormControl } from 'react-bootstrap';

interface IState {
	filter: VehiclesListFilterClass;
	listRequestOptions: ListRequestOptions;
	listReturn: ListReturn<IVehicleListDetails>
	showModal: boolean,
	selectedFleetNumber?: string;
	isLoading: boolean;
	error?: IError;
}

class VehiclesManagement extends React.Component<{}, IState> {
	state = {
		filter: new VehiclesListFilterClass(),
		listReturn: new ListReturn<IVehicleListDetails>(),
		listRequestOptions: new ListRequestOptions(),
		showModal: false,
		selectedFleetNumber: undefined,
		isLoading: false,
		error: undefined
	}

	componentDidMount() {
		this.fetchList();
	}

	fetchList = () => {
		const { filter, listRequestOptions } = this.state;
		const promise = () => VehiclesApi.getVehiclesList(listRequestOptions, filter);
		componentRequestHandler(this, promise, 'listReturn');
	}

	changeFilter = (fieldId: string, value: any) => {
		this.setState({
			listRequestOptions: new ListRequestOptions(),
			filter: {
				...this.state.filter,
				[fieldId]: value
			}
		})
	}

	changeListOption = (fieldId: string, value: any) => {
		this.setState({
			listRequestOptions: {
				...this.state.listRequestOptions,
				[fieldId]: value
			}
		}, this.fetchList)
	}

	handleChangePage = (page: number) => {
		this.changeListOption('page', page);
	};

	handleChangePageSize = (pageSize: number) => {
		this.changeListOption('pageSize', pageSize);
		this.changeListOption('page', 0);
	};

	handleChangeSorted = (sorted: SortingRule[]) => {
		this.changeListOption('sorted', sorted);
	};

	handleChangeVehicle = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
		this.changeFilter('fleetNumber', e.target.value);
	}

	handleChangeStatus = (isActive?: boolean) => {
		this.changeFilter('isActive', isActive);
	}

	handleChangeVehicleTypes = (vehicleTypeIds: number[]) => {
		this.changeFilter('vehicleTypeIds', vehicleTypeIds);
	}

	handleSelectVehicle = (selectedFleetNumber?: string) => {
		this.setState({ 
			showModal: true,
			selectedFleetNumber 
		})
	}

	handleCloseDetailsModal = () => {
		this.setState({ 
			showModal: false,
			selectedFleetNumber: undefined 
		})
	}

	handleCompleteSaving = (fleetNumber: string) => {
		this.handleSelectVehicle(fleetNumber);
	}

	handleCompleteInactivation = () => {
		this.setState({ 
			showModal: false,
			selectedFleetNumber: undefined 
		}, this.fetchList)
	}

	render() {
		const { state } = this;
		
		return (
			<MainContent title="Vehicles Management">
				{
					state.showModal && (
						<VehicleDetailsModal 
							fleetNumber={state.selectedFleetNumber}
							onClose={this.handleCloseDetailsModal}
							onCompleteSaving={this.handleCompleteSaving}
							onCompleteInactivation={this.handleCompleteInactivation}
						/>
					)
				}
				<FormGroup>
					<Button
						block
						bsStyle="success"
						onClick={() => this.handleSelectVehicle()}
					>
						<Icon name="plus" /> Add New Vehicle
					</Button>
				</FormGroup>
				<ContentBox title="Filters">
					<VehiclesListFilter 
						{...state.filter}
						onChangeVehicle={this.handleChangeVehicle}
						onChangeVehicleType={this.handleChangeVehicleTypes}
						onChangeStatus={this.handleChangeStatus}
						onPressEnter={this.fetchList}
					/>
				</ContentBox>
				<FormGroup>
					<Button
						block
						bsStyle="info"
						onClick={this.fetchList}
					>
						<Icon name="search" /> Search
					</Button>
				</FormGroup>
				{
					state.error ? (
						<ErrorBox 
							error={state.error} 
							retryFunc={this.fetchList}
						/>
					) : (
						<VehiclesList 
							{...state.listRequestOptions}
							{...state.listReturn}
							isLoading={state.isLoading}
							onPageChange={this.handleChangePage}
							onPageSizeChange={this.handleChangePageSize}
							onSortedChange={this.handleChangeSorted}
							onSelectRow={this.handleSelectVehicle}
						/>
					)
				}
			</MainContent>
		)
	}
}

export default VehiclesManagement;