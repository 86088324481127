/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import ReactTable from 'react-table';
import { Checkbox } from '../../../../common/inputs';
import { WorkerListItemWithExtraData } from '../../../../../app/models/Worker';

interface IProps {
	selectedWorkerIds?: string[];
	workersList: WorkerListItemWithExtraData[];
	hideCheckBoxes?: boolean;
	hideFilters?: boolean;
	hideCurrentRole?: boolean;
	onSelectWorker?: (workerId: string) => void;
	onSelectAll?: () => void;
}

const WorkersListForSelection: React.FC<IProps> = props => {
	return (
		<ReactTable
			pageSize={999999}
			filterable={!props.hideFilters}
			showPagination={false}
			resizable={false}
			data={props.workersList}
			minRows={0}
			defaultFilterMethod={(filter, row) => {
				const id = filter.pivotId || filter.id;
				return row[id] !== undefined ? String(row[id]).toLowerCase().indexOf(filter.value.toLowerCase()) !== -1 : true
			}}
			getTrProps={(state: any, rowInfo: any) => {
				if (!rowInfo || props.hideCheckBoxes) 
					return {};

				return {
					onClick: () => props.onSelectWorker && props.onSelectWorker(rowInfo.original.id)
				}
			}}
			columns={[
				{
					className: 'text-center',
					sortable: false,
					filterable: false,
					width: 33,
					accessor: 'id',
					headerClassName: 'text-center',
					show: !props.hideCheckBoxes,
					Header: () => <Checkbox noMargin checked={props.selectedWorkerIds && props.selectedWorkerIds.length === props.workersList.length} onChange={() => props.onSelectAll && props.onSelectAll()} />,
					Cell: (row: any) => <Checkbox noMargin checked={props.selectedWorkerIds && props.selectedWorkerIds.find(id => id === row.value) !== undefined} onChange={() => props.onSelectWorker && props.onSelectWorker(row.value)} />
				},
				{
					Header: 'Name',
					accessor: 'name'
				}, 
				{
					Header: 'Mobile Number',
					accessor: 'mobileNumber'
				}, 
				{
					Header: 'Current Role',
					accessor: 'currentRoleName',
					show: !props.hideCurrentRole
				}
			]}
		/>
	)
}

export default WorkersListForSelection;