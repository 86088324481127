import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-timezone';
import { Row, Col, FormGroup, FormControl } from 'react-bootstrap';
import helper from './helper';

import { Form } from '../../../common/uiElements';
import { UserSearch } from '../../../common/inputs';
import { AbsenceReasonsList, AustraliaTimeZoneNamesList } from '../../../common/lists';

const propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		workerId: PropTypes.string,
		company: PropTypes.string,
		employeeNumber: PropTypes.string,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		startTimeZoneNameIana: PropTypes.string,
		finishTimeZoneNameIana: PropTypes.string,
		startTimeLocal: PropTypes.string,
		finishTimeLocal: PropTypes.string,
		adjustedStartTimeLocal: PropTypes.string,
		adjustedFinishTimeLocal: PropTypes.string,
		comments: PropTypes.string,
		absenceReasonId: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string
		]),
		AbsenceReason: PropTypes.shape({
			name: PropTypes.string
		})
	}),
	hasFinishTime: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	onChange: PropTypes.func,
	onChangeWorker: PropTypes.func,
};

const WorkerTimeSheet = props => {
	const validations = {
		workerId: 'required',
		startTimeLocal: 'required',
		startTimeZoneNameIana: 'required',
		finishTimeZoneNameIana: 'required',
		finishTimeLocal: 'required',
		checkFinishTime: {
			greaterOrEqualThan: '#checkStartTime'
		},
		adjustedStartTimeLocal: 'required',
		adjustedFinishTimeLocal: 'required',
		checkAdjustedFinishTime: {
			greaterOrEqualThan: '#checkAdjustedStartTime'
		}
	};

	const messages = {
		checkFinishTime: {
			greaterOrEqualThan: 'The total worked hours is equal or lower than 0. Check the dates and the timezone.'
		},
		checkAdjustedFinishTime: {
			greaterOrEqualThan: 'The total worked hours is equal or lower than 0. Check the dates and the timezone.'
		},
	};

	if (props.data.id) {
		if (!props.data.adjustedStartTimeLocal)
			props.data.adjustedStartTimeLocal = props.data.startTimeLocal;
    
		if (!props.data.adjustedFinishTimeLocal)
			props.data.adjustedFinishTimeLocal = props.data.finishTimeLocal;
	}

	return (
		<Form validations={validations} messages={messages}>
			{
				props.data.id ? (
					<Row>
						<Col sm={5}>
							<FormGroup>
								<label>Company</label>
								<p>{ props.data.company }</p>
							</FormGroup>
						</Col>
						<Col sm={3}>
							<FormGroup>
								<label>Employee Number</label>
								<p>{ props.data.employeeNumber }</p>
							</FormGroup>
						</Col>
						<Col sm={4}>
							<FormGroup>
								<label>Employee Name</label>
								<p>{ `${props.data.firstName} ${props.data.lastName}` }</p>
							</FormGroup>
						</Col>
					</Row>
				) : (
					<Row>
						<Col xs={12}>
							<FormGroup>
								<label>Employee *</label>
								<UserSearch 
									id="workerId"
									value={props.data.workerId || ''}
									onChange={props.onChangeWorker} 
								/>
							</FormGroup>
						</Col>
					</Row>
				)
			}
			<h3 className="title">Actual Time</h3>
			<Row>
				<Col sm={5}>
					<FormGroup>
						<label>Start *</label>
						{
							props.data.id ? (
								<p>
									{ moment(props.data.startTimeLocal).format('DD/MM/YYYY HH:mm') } <br />
									{ props.data.startTimeZoneNameIana && <i>({ props.data.startTimeZoneNameIana })</i> }
								</p>
							) : (
								<div>
									<FormControl 
										id="startTimeLocal"
										type="datetime-local" 
										value={props.data.startTimeLocal ? moment(props.data.startTimeLocal).format('YYYY-MM-DDTHH:mm') : ''} 
										onChange={props.onChange}
									/>
									<AustraliaTimeZoneNamesList 
										id="startTimeZoneNameIana"
										value={props.data.startTimeZoneNameIana}
										onChange={props.onChange}
									/>
								</div>
							)
						}
					</FormGroup>
				</Col>
				<Col sm={5}>
					<FormGroup>
						<label>Finish *</label>
						{
							props.data.id && props.hasFinishTime ? (
								<p>
									{ moment(props.data.finishTimeLocal).format('DD/MM/YYYY HH:mm') } <br />
									{ props.data.finishTimeZoneNameIana && <i>({ props.data.finishTimeZoneNameIana })</i> }
								</p>
							) : (
								<div>
									<FormControl 
										id="finishTimeLocal"
										type="datetime-local" 
										value={props.data.finishTimeLocal ? moment(props.data.finishTimeLocal).format('YYYY-MM-DDTHH:mm') : ''} 
										onChange={props.onChange}
									/>
									<AustraliaTimeZoneNamesList 
										id="finishTimeZoneNameIana"
										value={props.data.finishTimeZoneNameIana}
										onChange={props.onChange}
									/>
								</div>
							)
						}
					</FormGroup>
				</Col>
				<Col sm={2}>
					<FormGroup>
						<label>Total</label>
						<p>
							{ 
								helper.calculateTotalTime(
									moment.tz(props.data.startTimeLocal, props.data.startTimeZoneNameIana).utc(), 
									moment.tz(props.data.finishTimeLocal, props.data.finishTimeZoneNameIana).utc()
								) 
							}
						</p>
					</FormGroup>
				</Col>
			</Row>
			<FormControl
				id="checkStartTime" 
				type="hidden" 
				value={moment.tz(props.data.startTimeLocal, props.data.startTimeZoneNameIana).utc()} 
			/>
			<FormControl 
				id="checkFinishTime" 
				type="hidden" 
				value={moment.tz(props.data.finishTimeLocal, props.data.finishTimeZoneNameIana).utc()} 
			/>
			{
				props.data.id && props.data.AbsenceReason && (
					<Row>
						<Col xs={12}>
							<FormGroup>
								<label>Absence Reason</label>
								<p>{ props.data.AbsenceReason.name }</p>
							</FormGroup>
						</Col>
					</Row>    
				)
			}
			{
				!props.data.id && (
					<FormGroup>
						<label>Absence Reason</label>
						<AbsenceReasonsList 
							id="absenceReasonId"
							value={props.data.absenceReasonId || ''}
							onChange={props.onChange} 
						/>
					</FormGroup>
				)
			}
			{
				props.data.id && (
					<div>
						<h3 className="title">Adjusted Time</h3>
						<Row>
							<Col sm={5}>
								<FormGroup>
									<label>Start</label>
									{
										props.isReadOnly ? (
											<p>{ props.data.adjustedStartTimeLocal ? moment(props.data.adjustedStartTimeLocal).format('DD/MM/YYYY HH:mm') : '-' }</p>
										) : (
											<FormControl 
												id="adjustedStartTimeLocal"
												type="datetime-local" 
												value={moment(props.data.adjustedStartTimeLocal).format('YYYY-MM-DDTHH:mm')} 
												onChange={props.onChange}
											/>
										)
									}
								</FormGroup>
							</Col>
							<Col sm={5}>
								<FormGroup>
									<label>Finish</label>
									{
										props.isReadOnly || !props.data.finishTimeLocal || !props.data.finishTimeZoneNameIana ? (
											<p>{ props.data.adjustedFinishTimeLocal ? moment(props.data.adjustedFinishTimeLocal).format('DD/MM/YYYY HH:mm') : '-' }</p>
										) : (
											<FormControl 
												id="adjustedFinishTimeLocal"
												type="datetime-local" 
												value={moment(props.data.adjustedFinishTimeLocal).format('YYYY-MM-DDTHH:mm')} 
												onChange={props.onChange}
											/>
										)
									}
								</FormGroup>
							</Col>
							<Col sm={2}>
								<FormGroup>
									<label>Total</label>
									<p>
										{ 
											helper.calculateTotalTime(
												moment.tz(props.data.adjustedStartTimeLocal, props.data.startTimeZoneNameIana).utc(), 
												moment.tz(props.data.adjustedFinishTimeLocal, props.data.finishTimeZoneNameIana).utc()
											)
										}
									</p>
								</FormGroup>
							</Col>
						</Row>
						<FormControl 
							id="checkAdjustedStartTime" 
							type="hidden" 
							value={moment.tz(props.data.adjustedStartTimeLocal, props.data.startTimeZoneNameIana).utc()} 
						/>
						<FormControl 
							id="checkAdjustedFinishTime" 
							type="hidden" 
							value={moment.tz(props.data.adjustedFinishTimeLocal, props.data.finishTimeZoneNameIana).utc()} 
						/>
					</div>
				)
			}
			<h3 className="title">Comments</h3>
			<FormGroup>
				<FormControl 
					id="comments"
					componentClass="textarea" 
					placeholder="Leave comments here..."
					style={{ resize: 'none' }}
					value={props.data.comments || ''}
					onChange={props.onChange}
				/>
			</FormGroup>
		</Form>
	);
};

WorkerTimeSheet.propTypes = propTypes;

export default WorkerTimeSheet;