import React, { ChangeEvent, Fragment } from 'react';
import { PlacesList } from '../../../../../common/lists';
import { WeeksOfYear, Years, UserSearch } from '../../../../../common/inputs';
import { FieldGroup, ContentBox } from '../../../../../common/uiElements';
import { Row, FormControl, Button, Col, InputGroup } from 'react-bootstrap';
import { StaffRosterListMainFilter } from '../../../../../../app/models/StaffRoster';
import Icon from 'react-fontawesome';
import { IReactSelectOption } from '../../../../../../app/models/Application';
import { getEndDateFromYearWeekNumber, getStartDateFromYearWeekNumber } from '../../../../../../app/helpers/dateTimeHelper';

interface IProps extends StaffRosterListMainFilter {
	hideDepot?: boolean;
	hideFilterBy?: boolean;
	yearReadOnly?: boolean;
	weekReadOnly?: boolean;
	weekNumbersToDisable?: number[];
	yearToDisable?: number[];
	onChangeYear?: (year: number) => void;
	onChangeWeek?: (week: number) => void;
	onChangeDepot?: (depotId?: string, depotName?: string) => void;
	onToggleFilterBy?: () => void;
	onChangeWorker?: (worker?: IReactSelectOption) => void;
	onChangeStartFinishDate?: (startDate: string, finishDate: string) => void;
}

const StaffRosterMainFilter: React.FC<IProps> = props => {
	function handleChangeYear(e: ChangeEvent<FormControl & HTMLInputElement>) {
		const year = parseInt(e.target.value);
		props.onChangeYear && props.onChangeYear(year);
	}

	function handleChangeWeek(e: ChangeEvent<FormControl & HTMLInputElement>) {
		props.onChangeWeek && props.onChangeWeek(parseInt(e.target.value));
	}

	function handleChangeDepot(e: ChangeEvent<FormControl & HTMLInputElement>, depotName: string) {
		props.onChangeDepot && props.onChangeDepot(e.target.value, depotName);
	}

	function handleChangeStartDate(e: ChangeEvent<FormControl & HTMLInputElement>) {
		props.onChangeStartFinishDate && props.onChangeStartFinishDate(e.target.value, props.finishDate);
	}

	function handleChangeFinishDate(e: ChangeEvent<FormControl & HTMLInputElement>) {
		props.onChangeStartFinishDate && props.onChangeStartFinishDate(props.startDate, e.target.value);
	}

	return (
		<Fragment>
			{
				!props.hideFilterBy && (
					<Fragment>
						<Row>
							<Col xs={6}>
								<Button
									block
									bsStyle={!props.filterByEmployee ? 'danger' : 'default'}
									onClick={props.onToggleFilterBy}
								>
									<Icon name="calendar" /> Filter By Week
								</Button>
							</Col>
							<Col xs={6}>
								<Button
									block
									bsStyle={props.filterByEmployee ? 'warning' : 'default'}
									onClick={props.onToggleFilterBy}
								>
									<Icon name="user" /> Filter By Employee
								</Button>
							</Col>
						</Row>
						<br />
					</Fragment>
				)
			}
			<ContentBox>
				{
					props.filterByEmployee ? (
						<Row>
							<FieldGroup label="Employee" md={4} lg={3}>
								<UserSearch
									id="workerId"
									showLicenceClass
									value={props.workerId}
									onChange={props.onChangeWorker}
								/>
							</FieldGroup>
							<FieldGroup label="Date Period" md={8} lg={6}>
								<InputGroup>
									<InputGroup.Addon>From</InputGroup.Addon>
									<FormControl 
										id="startDate" 
										type="date" 
										value={props.startDate} 
										onChange={handleChangeStartDate}
									/>
									<InputGroup.Addon>To</InputGroup.Addon>
									<FormControl 
										id="finishDate" 
										type="date" 
										value={props.finishDate} 
										onChange={handleChangeFinishDate}
									/>
								</InputGroup>
							</FieldGroup>
						</Row>
					) : (
						<Row>
							<FieldGroup label="Year" sm={props.hideDepot ? 6 : 4}>
								{
									props.yearReadOnly ? (
										<p>{ props.year }</p>
									) : (
										<Years
											value={props.year}
											onChange={handleChangeYear}
										/>
									)
								}
							</FieldGroup>
							<FieldGroup label="Week" sm={props.hideDepot ? 6 : 4}>
								{
									props.weekReadOnly ? (
										<p>
											Week {props.week} -&nbsp;
											{ getStartDateFromYearWeekNumber(props.year!, props.week!).format('DD/MM') } to&nbsp;
											{ getEndDateFromYearWeekNumber(props.year!, props.week!).format('DD/MM') }
										</p>
									) : (
										<WeeksOfYear 
											year={props.year!}
											value={props.week || ''}
											weekNumbersToDisable={props.weekNumbersToDisable}
											onChange={handleChangeWeek}
										/>
									)
								}
							</FieldGroup>
							{
								!props.hideDepot && (
									<FieldGroup withoutFormGroup label="Depot" sm={4}>
										<PlacesList
											showAllOption
											type="rosterStartingDepot"
											value={props.startingDepotId}
											onChange={handleChangeDepot}
										/>
									</FieldGroup>
								)
							}
						</Row>
					)
				}
			</ContentBox>
		</Fragment>
	);
}

export default StaffRosterMainFilter;
