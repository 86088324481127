/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap';

const propTypes = {
	id: PropTypes.string,
	value: PropTypes.number,
	onChange: PropTypes.func
};

const defaultProps = {
	id: 'riskRating',
	value: 0,
	onChange: () => {}
};

const RiskRating = props => {
	const renderButton = (text, colour, value) => (
		<a 
			className={`btn btn-${!props.value || value !== props.value ? 'default' : colour}`}
			value={value} 
			onClick={() => props.onChange(value, props.id)}
		>
			{ text }
		</a>
	);

	return (
		<ButtonGroup justified>
			{ renderButton('(1) Green', 'success', 1) }
			{ renderButton('(2) Amber', 'warning', 2) }
			{ renderButton('(3) Red', 'danger', 3) }
		</ButtonGroup>
	);
};

RiskRating.propTypes = propTypes;
RiskRating.defaultProps = defaultProps;

export default RiskRating;
