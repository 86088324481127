/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { Table } from 'react-bootstrap';

const propTypes = {
	accessPointsList: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			hasBeenEdited: PropTypes.bool,
			isActive: PropTypes.bool
		})
	).isRequired,
	onClickEditAccessPoint: PropTypes.func,
	onToggleAccessPointStatus: PropTypes.func
};

const AccessPointsList = ({
	accessPointsList,
	onClickEditAccessPoint,
	onToggleAccessPointStatus
}) => {
	if (accessPointsList.length === 0)
		return <p><i>No Access Point has been registered yet.</i></p>;

	return (
		<Table>
			<thead>
				<tr>
					<th>Reference</th>
				</tr>
			</thead>
			<tbody>
				{
					accessPointsList.map(({ id, hasBeenEdited, isActive }, index) => (
							<tr key={index} className={!isActive ? 'bg-danger' : !id ? 'bg-success' : hasBeenEdited ? 'bg-info' : ''}>
								<td>
									Access Point { index + 1 } &nbsp;
									{
										(!id || hasBeenEdited || !isActive) && (
											<i>
												({ !id ? 'New' : isActive ? 'Edited' : 'Deleted' })
											</i>
										)
									}
								</td>
								<td width={45}> 
									{
										isActive && (
											<a 
												className="text-warning" 
												onClick={() => onClickEditAccessPoint(index)}
											>
												<Icon name="edit" size="2x" />
											</a>
										)
									}						
								</td>						
								<td width={45}>
									<a 
										className={isActive ? 'text-danger' : 'text-warning'}
										onClick={() => onToggleAccessPointStatus(index)}
									>
										<Icon name={ isActive ? 'trash' : 'undo' } size="2x" />
									</a>
								</td>
							</tr>		
						)
					)
				}
			</tbody>
		</Table>
	);
};

AccessPointsList.propTypes = propTypes;

export default AccessPointsList;
