/* eslint-disable no-throw-literal */

import axios from 'axios';
import omit from 'lodash.omit';
import moment from 'moment';
import 'moment-timezone';
import toastr from 'toastr';
import { RequestMethods, IRequestData, RequestObjectDebug } from '../../models/Application';
import alert from '../../helpers/alert';
import packageInfo from '../../../../package.json';

import { acquireAccessToken, msalInstance, redirectRequest } from '../../config/auth.config';
import { logError } from '../../helpers/logger';
import { cachedEndpoints } from '../endpoints/_cachedEndpoints';
import { setLatestUiVersion } from '../../../stores/redux/app';

const currentUiVersion = packageInfo.version;
export const protocol = process.env.REACT_APP_API_PROTOCOL;
export const host = process.env.REACT_APP_API_HOST;
export const prefix = process.env.REACT_APP_API_PREFIX;
export const apiRootUrl = `${protocol}://${host}/${prefix}`;

export async function msalApiFetch(endpoint: string, options: any) {
	options.headers = {
		Authorization: "Bearer " + await acquireAccessToken(),
		userTimeZone: moment.tz.guess()
	}

	return axios(apiRootUrl + endpoint, options);
}

/**
 * Create the promise to call the TMS API
 * @param method The request method (GET, POST, PUT, DELETE, etc.)
 */
export function createPromise(method: RequestMethods, endpoint: string, data?: IRequestData, paramsSerializer?: (params: object) => string) {
	let dataBeforeConvertToForm = data;
	if (data && data.attachedFiles && method !== 'GET')
		data = createDataWithFiles(data);

	// Caching data from the allowed endpoints
	if (cachedEndpoints.includes(endpoint)) {
		const cachedData = localStorage.getItem(endpoint);
			if(cachedData) {
				const parsedData = JSON.parse(cachedData);
				// Only return the data if the expiry date is after the current date
				if (moment(parsedData.expiry).isAfter(moment())) {
					return Promise.resolve(parsedData.record);
				} else {
					localStorage.removeItem(endpoint);
				}
			}
		}

	const requestOptions = {
		method,
		data,
		params: method === 'GET' && data,
		paramsSerializer,
		validateStatus: () => true // Used to handle all errors coming from the API instead of letting axios handle it
	};

	let requestObjectDebug = new RequestObjectDebug(method);
	requestObjectDebug.request = { 
		data: dataBeforeConvertToForm,
		endpoint
	} 
	
	return msalApiFetch(endpoint, requestOptions)
		.then(res => {
			requestObjectDebug.response = { ...res };
			const reduxStore = require('../../../stores/redux/index').default;

			// Check if the latest UI version is different from the current one
			const latestUiVersion = res.headers['x-tms-ui-version'];
			if (latestUiVersion && latestUiVersion !== currentUiVersion)
				reduxStore.dispatch(setLatestUiVersion(latestUiVersion));

			if (process.env.REACT_APP_ENVIRONMENT !== 'production')
				console.info(requestOptions.method, endpoint, requestObjectDebug);
			
			if (res.status < 300) {
				// If the endpoint is in the cachedEndpoints array, store the data in the local storage
				if (cachedEndpoints.includes(endpoint)) {
					// Store the data in the local storage with 1 hour expiry
					const cachedData = {
						record: res.data.dataObject,
						expiry: moment().add(1, 'hours').format()
					}
					localStorage.setItem(endpoint, JSON.stringify(cachedData));
				}
				return res.data.dataObject;
			}
			else {
				throw res.data;
			}
		})
		.catch(error => {
			const responseStatus = requestObjectDebug.response?.status;

			if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
				console.error(error);
				console.error(endpoint, requestObjectDebug);
			}

			toastr.error('Try again or contact TMS Support if the error persists.', 'Sorry, something went wrong :(');

			// Object with all possible information about the error
			const errorData = {
				message: 'Something went wrong',
				details: '',
				errorsList: []
			};

			// If it's a request error that is not handled by the API Error Handler
			if (error.Message) {
				errorData.message = error.Message as string;
				errorData.details = error.MessageDetail as string;
			} else if (error.msg) {
				errorData.message = error.msg;
				errorData.details = error.message;
			}
			else if (error.errorMessage) { // Cross-Site Tracking Error
				errorData.message = error.errorMessage;
				errorData.details = error.errorMessage;
			} else {
				errorData.message = error.message;
				errorData.details = error.message;
				errorData.errorsList = error.errorsObject;
			}
			
			// If there's a token renewal problem refresh the page so the user can sign in again
			const tokenRenewIssue = errorData.message && errorData.message.toLowerCase().indexOf('token renewal') !== -1;

			// If there's a problem with sign in it's more likely to be the cross-site tracking option in the browsers
			const crossSiteTrackingIssue = errorData.message && errorData.message.indexOf('AADSTS50058') !== -1;

			// If there's a problem with the acquire new token using IFrame, it should redirect the user to interact
			const acquireTokenIFrameIssue = errorData.message && errorData.message.indexOf('AADSTS50199') !== -1;
			
			// authProvider.acquireTokenRedirect(authenticationParameters);
			if (tokenRenewIssue || crossSiteTrackingIssue || acquireTokenIFrameIssue || (responseStatus >= 400 && responseStatus < 500)) {
				const appInsightsObject = {
					fromPath: window.location.pathname,
					method,
					endpoint,
					errorMessage: errorData.message,
					details: errorData.details || JSON.stringify(error),
					errorsList: errorData.errorsList ? JSON.stringify(errorData.errorsList) : 'None',
					dataSent: dataBeforeConvertToForm ? JSON.stringify(dataBeforeConvertToForm) : 'None'
				};
					
				logError(new Error(errorData.message), 'msalApiFetch', appInsightsObject);

				if (crossSiteTrackingIssue)
					alert('PLEASE READ THE FOLLOWING INSTRUCTIONS CAREFULLY!!', undefined, 'danger');

				if (tokenRenewIssue)
					return msalInstance.loginRedirect(redirectRequest);

				if (acquireTokenIFrameIssue)
					return msalInstance.acquireTokenRedirect(redirectRequest);
			}

			throw errorData;
		});
	}


/**
 * Create the data with the files and returns a "FormData" type object
 * @param {object} data The data with the files
 */
export function createDataWithFiles(data: IRequestData) {
	var formData = new FormData();

	// Add all data to the object 'form' except the attached files object
	formData.append('form', JSON.stringify(omit(data, 'attachedFiles')));

	if (data.attachedFiles && data.attachedFiles.length > 0) {
		// Adding all attached files
		for (var i = 0; i < data.attachedFiles.length; i++) {
			formData.append(i.toString(), data.attachedFiles[i] as any);
		}
	}
	
	return formData;
}


// HERE
// export async function msalGetToken(authProvider: MsalAuthProvider) {
// 	try {
// 		const accessTokenResponse = await authProvider.acquireTokenSilent(authenticationParameters);
// 		return accessTokenResponse.accessToken;
// 	}	
// 	catch (error) {
//     if (error.errorMessage.indexOf("interaction_required") !== -1)
// 			return authProvider.acquireTokenRedirect(authenticationParameters);

// 		throw error;
// 	}
// }
