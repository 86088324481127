import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Row, Col, FormGroup, FormControl, InputGroup } from 'react-bootstrap';
import { RadioButtonList, DatePeriodInput, UserSearch } from '../../../common/inputs';
import { Form, UserDetailsLink } from '../../../common/uiElements';
import { AbsenceReasonsList } from '../../../common/lists';

const propTypes = {
	formRef: PropTypes.func,
	id: PropTypes.number,
	typeId: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	typeName: PropTypes.string,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	withPay: PropTypes.bool,
	comments: PropTypes.string,
	isActive: PropTypes.bool,
	isApproved: PropTypes.bool,
	requestedById: PropTypes.string,
	requestedOnLocal: PropTypes.string,
	revisedOnLocal: PropTypes.string,
	commentsReviewer: PropTypes.string,
	isReadOnly: PropTypes.bool,
	isReviewEnabled: PropTypes.bool,
	hideEmployee: PropTypes.bool,
	WorkerRequestedBy: PropTypes.shape({
		azureId: PropTypes.string.isRequired,
		shortName: PropTypes.string.isRequired
	}),
	WorkerRevisedBy: PropTypes.shape({
		azureId: PropTypes.string.isRequired,
		shortName: PropTypes.string.isRequired
	}),
	onChange: PropTypes.func,
	onSubmitRequest: PropTypes.func,
	onCancel: PropTypes.func
};

const LeaveRequestDetails = ({
	formRef,
	id,
	typeId,
	typeName,
	startDate,
	endDate,
	withPay,
	comments,
	isActive,
	isApproved,
	requestedById,
	requestedOnLocal,
	revisedOnLocal,
	commentsReviewer,
	isReadOnly,
	isReviewEnabled,
	hideEmployee,
	WorkerRequestedBy,
	WorkerRevisedBy,
	onChange,
	onSubmitRequest,
	onCancel
}) => {
	const handleChange = e => {
		let { id, value, type } = e.target;
		value = type !== 'radio' ? value : value === 'true' ? true : false;
		onChange && onChange({ [id]: value });
	};

	const handleChangePeriod = (startDate, endDate) => {
		startDate = moment(startDate).format('YYYY-MM-DD');
		endDate = moment(endDate).format('YYYY-MM-DD');
		onChange && onChange({ startDate, endDate });
	};

	const handleChangeUser = user => {
		onChange && onChange({ requestedById: user.value });
	}

	const renderStatus = () => {
		const currentDate = moment();
		const statusColor = !isActive ? 'text-muted' : isApproved == null ? 'text-info' : isApproved ? 'text-success' : 'text-danger';
		let statusText = !isActive ? 'Cancelled' : isApproved == null ? 'Pending' : isApproved ? 'Approved' : 'Denied';

		if (isApproved)
			if (currentDate > moment(endDate))
				statusText = 'Taken';
			else if (currentDate > moment(startDate))
				statusText = 'Taking';

		return (
			<p>
				<b className={statusColor}>
					<u>{ statusText }</u>
				</b>
			</p>
		);
	};

	const formattedStartDate = moment(startDate).format('DD/MM/YYYY');
	const formattedEndDate = moment(endDate).format('DD/MM/YYYY');

	return (
		<Form 
			refs={formRef}
			onSubmit={onSubmitRequest}
			validations={{
				requestedById: 'required',
				requestedOnLocal: 'required',
				typeId: 'required',
				withPay: 'required',
				isApproved: 'required'
			}}
		>	
			{
				isActive && (moment(endDate) >= moment() || typeof isApproved !== 'boolean') && (
					<Fragment>
						<Button
							block
							bsStyle="danger"
							onClick={onCancel}
						>
							Cancel Request
						</Button>
						<hr />
					</Fragment>
				)
			}
			<Row>
				{
					id && (
						<Col xs={hideEmployee ? 6 : 12}>
							<FormGroup>
								<label>Status</label>
								{ renderStatus() }
							</FormGroup>
						</Col>
					)
				}
				{
					!hideEmployee && (
						<Col xs={6}>
							<FormGroup>
								<label>Requested By</label>
								{
									id || isReadOnly ? (
										<p>
											<UserDetailsLink azureId={WorkerRequestedBy.azureId}>
												{ WorkerRequestedBy.shortName }
											</UserDetailsLink>
										</p>
									) : (
										<UserSearch 
											id="requestedById"
											value={requestedById || ''}
											onChange={handleChangeUser} 
										/>
									)
								}
							</FormGroup>
						</Col>
					)
				}
				<Col xs={6}>
					<FormGroup>
						<label>Requested On</label>
						{
							id || isReadOnly || hideEmployee ? (
								<p>{ moment(requestedOnLocal).format('DD/MM/YYYY') }</p>
							) : (
								<FormControl
									id="requestedOnLocal"
									type="date" 
									value={moment(requestedOnLocal).format('YYYY-MM-DD')}
									onChange={handleChange} 
								/>
							)
						}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col sm={6}>
					<FormGroup>
						<label>Leave Type *</label>
						{
							isReadOnly ? (
								<p>{ typeName }</p>
							) : (
								<AbsenceReasonsList 
									id="typeId"
									value={typeId || ''}
									onChange={handleChange}
								/>
							)
						}
					</FormGroup>
				</Col>
				<Col sm={6}>
					<FormGroup>
						<label>Payment Method *</label>
						{
							isReadOnly ? (
								<p>{ withPay ? 'Paid' : 'Unpaid' }</p>
							) : (
								<RadioButtonList 
									id="withPay" 
									value={withPay} 
									items={[{ value: true, text: 'Paid' }, { value: false, text: 'Unpaid' }]}
									onChange={handleChange} 
								/>
							)
						}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12} sm={6}>
					<FormGroup>
						<label>Leave Period *</label>
						{
							isReadOnly ? (
								<p>
									{ formattedStartDate } - { formattedEndDate }
								</p>
							) : (
								<Fragment>
									<InputGroup>
										<DatePeriodInput 
											hideInput
											noRedux
											dropsDirection="down"
											parentEl="#testModal"
											readOnly
											startDate={formattedStartDate}
											endDate={formattedEndDate}
											minDate={new Date (moment().add(-6, 'months'))} 
											maxDate={new Date (moment().add(2, 'years'))} 
											format="DD/MM/YYYY"
											onChange={handleChangePeriod}
										/>
										<InputGroup.Addon>
											{ moment(endDate).diff(moment(startDate), 'days') + 1 } day(s)
										</InputGroup.Addon>
									</InputGroup>
								</Fragment>
							)
						}
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<FormGroup>
						<label>Additional Information & Comments</label>
						{
							isReadOnly || isReviewEnabled ? (
								<p>{ comments || '-' }</p>
							) : (
								<FormControl
									id="comments"
									componentClass="textarea"
									rows={4}
									maxLength={250}
									value={comments}
									onChange={handleChange}
								/>
							)
						}
					</FormGroup>
				</Col>
			</Row>
			{
				isReviewEnabled && (
					<Fragment>
						<h3 className="title">Review</h3>
						<Row>
							<Col xs={6}>
								<FormGroup>
									<label>Status</label>
									{
										isReadOnly || !isReviewEnabled ? (
											renderStatus()
										) : (
											<RadioButtonList 
												id="isApproved" 
												items={[{ text: 'Approved', value: true }, { text: 'Denied', value: false }]} 
												onChange={handleChange} 
												value={isApproved}
											/>
										)
									}
								</FormGroup>
							</Col>
						</Row>
						{
							revisedOnLocal && (
								<Row>
									<Col xs={6}>
										<FormGroup>
											<label>Revised On</label>
											<p>{ moment(revisedOnLocal).format('DD/MM/YYYY HH:mm') }</p>
										</FormGroup>
									</Col>
									<Col xs={6}>
										<FormGroup>
											<label>Revised By</label>
											<p>
												{
													hideEmployee ? (
														WorkerRevisedBy.shortName
													) : (
														<UserDetailsLink azureId={WorkerRevisedBy.azureId}>
															{ WorkerRevisedBy.shortName }
														</UserDetailsLink>
													)
												}
											</p>
										</FormGroup>
									</Col>
								</Row>
							)
						}
						<Row>
							<Col xs={12}>
								<FormGroup>
									<label>Comments</label>
									{
										isReadOnly || !isReviewEnabled ? (
											<p>{ commentsReviewer || '-' }</p>
										) : (
											<FormControl
												id="commentsReviewer"
												componentClass="textarea"
												rows={4}
												maxLength={250}
												value={commentsReviewer || ''}
												onChange={handleChange}
											/>
										)
									}
								</FormGroup>
							</Col>
						</Row>
					</Fragment>
				)
			}
		</Form>
	);
};

LeaveRequestDetails.propTypes = propTypes;

export default LeaveRequestDetails;
