import React from 'react';
import PropTypes from 'prop-types';
import ListsApi from '../../../app/api/ListsApi';
import ListContainer from './_ListContainer';

export const PlaceSubCategoriesList = props => (
	<ListContainer 
		key={props.placeCategoryId}	
		{...props}
		promise={() => ListsApi.getPlaceSubCategoriesList(props.placeCategoryId)}
	/>
);

PlaceSubCategoriesList.propTypes = {
	placeCategoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};