import React, { Component } from 'react';
import moment from 'moment';
import Icon from 'react-fontawesome';
import { Button, FormGroup } from 'react-bootstrap';
import { MainContent } from '../../../common/layout';

// APIs
import NonConformanceApi from '../../../../app/api/NonConformanceApi';

// Components
import NonConformanceList from './list/NonConformanceList';
import NonConformanceListFilter from './list/NonConformanceListFilter';

// UI Elements
import { ContentBox, ErrorBox } from '../../../common/uiElements';
import NonConformanceModal from './form/NonConformanceModal';

class NonConformanceContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedNonConformanceId: null,
			nonConformanceList: [],
			totalPages: 0,
			totalRecords: 0,
			page: 0,
			pageSize: 10,
			sorted: [],
			filter: {
				startTimeLocal: moment().hour(0).minute(0).second(0).format('YYYY-MM-DD HH:mm'),
				endTimeLocal: moment().hour(23).minute(59).second(59).format('YYYY-MM-DD HH:mm'),
				filterBy: 'all',
				actionTakenById: '',
				reportedById: '',
				involvedFleetNumber: '',
				nonConformanceTypeId: '',
				isOpen: true,
				isClosed: false
			},
			isLoading: true,
			showModal: false,
			error: null
		};
		
	}    

	componentDidMount() {
		this.fetchNonConformanceList();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchNonConformanceList = () => {
		this.setState({ isLoading: true, error: null });
		const { page, pageSize, sorted } = this.state;
		const filter = { ...this.state.filter };
		if (filter.filterBy === 'all') {
			delete filter.startTimeLocal;
			delete filter.endTimeLocal;
		}
		NonConformanceApi.getNonConformanceList({ ...filter, page, pageSize, sorted })
			.then(data => {
				if (this.unmounted) return;
			
				const { totalRecords, totalPages, records } = data;

				return this.setState({ 
					isLoading: false, 
					nonConformanceList: records,
					totalPages,
					totalRecords
				});
			})
			.catch(error => {
				return this.setState({ isLoading: false, error });
			});
	}

	handleSearch = () => {
		this.setState({
			page: 0,
			sorted: []
		}, this.fetchNonConformanceList);
	};

	handleChangeFilter = (filter) => {
		this.setState({
			filter: {
				...this.state.filter,
				...filter
			}
		});
	};

	handleChangePage = page => {
		this.setState({ page }, this.fetchNonConformanceList);
	};

	handleChangePageSize = pageSize => {
		this.setState({ pageSize, page: 0 }, this.fetchNonConformanceList);
	};

	handleChangeSorted = sorted => {
		this.setState({ sorted }, this.fetchNonConformanceList);
	};

	handleViewDetails = selectedNonConformanceId => {
		this.setState({ 
			showModal: true,
			selectedNonConformanceId
		});
	};

	handleCloseModal = () => {
		this.setState({ 
			showModal: false,
			selectedNonConformanceId: null
		});
	};

	handleSave = savedDataId => {
		this.setState({ selectedNonConformanceId: savedDataId });
	}

	handleAddNewItem = () => {
		this.setState({ showModal: true });
	}
	
	render() {
		const { state } = this;

		return (
			<MainContent title="Non Conformance">
				<FormGroup>
					<Button 
						block
						bsStyle="success"
						bsSize="lg"
						onClick={this.handleAddNewItem}
					>
						<Icon name="plus" /> Add New Non Conformance
					</Button>
				</FormGroup>
				<ContentBox color="primary" title="Filters">
					<NonConformanceListFilter 
						{...state.filter} 
						onChange={this.handleChangeFilter}
					/>
					<hr />
					<Button 
						bsStyle="primary" 
						disabled={state.isLoading}
						block 
						onClick={this.handleSearch}
					>
							Search <Icon name="search" />
					</Button>
				</ContentBox>
				{
					state.error ? (
						<ErrorBox error={state.error} retryFunc={this.fetchNonConformanceList} />
					) : (
						<NonConformanceList 
							data={state.nonConformanceList}
							page={state.page}
							pageSize={state.pageSize}
							totalPages={state.totalPages}
							sorted={state.sorted}
							isLoading={state.isLoading}
							onPageChange={this.handleChangePage}
							onPageSizeChange={this.handleChangePageSize}
							onSortedChange={this.handleChangeSorted}
							onViewDetails={this.handleViewDetails}
						/>
					)
				}
				{
					state.showModal && (
						<NonConformanceModal 
							id={state.selectedNonConformanceId}
							onSave={this.handleSave}
							onClose={this.handleCloseModal}
						/>
					)
				}
			</MainContent>
		);
	}
}

export default NonConformanceContainer;