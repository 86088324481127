import React from 'react';
import { connect } from 'react-redux';

import { MainContent } from '../../common/layout';
import WorkerEdit from '../management/workers/subComponents/WorkerEdit';

const UserProfile = ({loggedUser}) => (
    <MainContent title="My Profile">
        <WorkerEdit 
            isLoggedUser
            azureId={loggedUser.azureId}
        />
    </MainContent>
)
const mapStateToProps = state => ({ loggedUser: state.user.data });

export default connect(mapStateToProps)(UserProfile);
