/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PhoneError from '../../app/assets/img/phone-error.png';
import MainLayout from './_MainLayout';

import { Callout } from '../common/uiElements';

const IOSVersionOutOfDate = () => (
	<MainLayout
		title="Version Out of Date"
		color="red"
		text="iOS versions older than iOS 13 are not supported."
		image={PhoneError}
	>
		<Callout>
			Please update to the latest version to view the TMS.<br/>
			<a href="https://support.apple.com/en-au/guide/iphone/iph3e504502/13.0/ios/13.0" target="_blank" rel="noreferrer">Instructions can be found here.</a>
			<br/><br/>
			If the error persists, please contact the TMS support.
		</Callout>
	</MainLayout>
);

export { IOSVersionOutOfDate };