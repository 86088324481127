import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Icon from 'react-fontawesome';

import { setVehicleFilter } from '../../../stores/redux/filter';
import TrackingApi from '../../../app/api/TrackingApi';
import { MainContent } from '../../common/layout';

import { BatteryChargesList } from '../../common/tracking';
import { ErrorBox, ContentBox, Callout, FiltersBox, Loader } from '../../common/uiElements';
import { VehicleSearch } from '../../common/inputs';
import LineChart from '../../common/charts/LineChart';

class ReportPalletJackCharges extends Component {
	constructor(props) {
		super(props);
		this.state = {
			batteryChargesData: null,
			batteryIsCharging: false,
			batteryIsLow: false,
			batteryVoltagesData: null,
			error: false,
			isLoading: false,
			timestamp: moment(),
			vehFleetNumber: null
		};
	}

	UNSAFE_componentWillMount() {
		var { vehFleetNumber } = this.props.params;
		if (vehFleetNumber) {
			this.setState({ vehFleetNumber }, this.fetchBatteryChargesData);
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchBatteryChargesData = () => {
		if (!this.state.vehFleetNumber)
			return;
		
		this.setState({ error: false, isLoading: true });
		TrackingApi.batteryChargesReportByPj(this.state.vehFleetNumber)
			.then(data => {
				if (this.unmounted) return;
			
				const { 
					BatteryCharges, BatteryVoltages, isVehBatteryCharging, 
					isVehBatteryLow, VehicleDetails 
				} = data;

				this.setState({ 
					batteryChargesData: BatteryCharges,
					batteryIsCharging: isVehBatteryCharging,
					batteryIsLow: isVehBatteryLow,
					batteryVoltagesData: BatteryVoltages,
					error: null,  
					isLoading: false 
				});
				this.props.setVehicleFilter(VehicleDetails);
			})
			.catch(error => {
				console.error(error);
				this.setState({ error, isLoading: false });
			});
	}

	onSelectVehicle = vehicle => {
		if (vehicle) {
			return this.setState({ 
				vehFleetNumber: vehicle.vehFleetNumber, 
				timestamp: moment() 
			}, this.fetchBatteryChargesData);
		}
		
		return this.setState({ vehFleetNumber: null });
	}

	render() {
		var { 
			batteryChargesData, 
			batteryVoltagesData, 
			batteryIsCharging, 
			batteryIsLow, 
			isLoading, 
			error, 
			vehFleetNumber 
		} = this.state;

		var { vehicle } = this.props.filters;
		
		return (
			<MainContent title="Pallet Jack - Battery Charges" subtitle="">
				<FiltersBox>
					<VehicleSearch disabled={isLoading} vehTypeFilter={1} onSelectVehicle={this.onSelectVehicle} selectedValue={vehFleetNumber}/>
				</FiltersBox>
				{
					isLoading ? (
						<Loader />
					) : (
						error ?  (
							<ErrorBox 
								error="It wasn't possible to retrieve the data. Try again or contact the TMS Support."
								retryFunc={this.fetchBatteryChargesData}
							/>
						) : (
							<div>
								{
									vehicle && batteryChargesData ? (
										<div>
											{
												!vehicle.vehSetHasVehicleBattCharger ? (
													<Callout title="No data" text="This vehicle doesn't record battery charges." color="info"/>
												) : (
													<div>
														<ContentBox title="Current Info" icon="battery-3" color="success" minimize={true}>
															<table className="table">
																<thead>
																	<tr>
																		<th>Charging</th>
																		<th>Charge Status</th>
																	</tr>
																</thead>
																<thead>
																	<tr>
																		<td>
																			{
																				batteryIsCharging ? (
																					<b><Icon name="circle" className="text-success" /> Yes</b>
																				) : (
																					<b><Icon name="circle" className="text-danger" /> No</b>
																				)
																			}
																		</td>
																		<td>
																			{
																				batteryIsLow ? (
																					<b><Icon name="circle" className="text-danger" /> Low</b>
																				) : (
																					<b><Icon name="circle" className="text-success" /> OK</b>
																				)
																			}
																		</td>
																	</tr>
																</thead>
															</table>
														</ContentBox>
														{
															batteryIsCharging && batteryIsLow && (
																<Callout title="Low Charge" text="The battery was low before attaching it to the charger, so give more time to charge the battery properly." color="warning"/>
															)
														}
														<ContentBox title="Charges List" icon="filter" color="primary" minimize={true}>
															<BatteryChargesList showFleet={false} batteryChargesData={batteryChargesData}/>
														</ContentBox>
														{
															vehicle.vehSetHasVehicleBattVoltSen && (
																<ContentBox title="Voltage Graph" icon="filter" color="danger" minimize={true}>
																	<LineChart chartName="batteryVoltage" data={batteryVoltagesData}/>
																</ContentBox>
															)
														}
													</div>
												)
											}
										</div>
									) : (
										this.props.params.vehFleetNumber && (
											<Callout title="Vehicle not found" text="The vehicle was not found." color="warning"/>
										)
									)
								}	
							</div>
						)
					)
				}
			</MainContent>
		);
	}
}

const mapStateToProps = state => ({ filters: state.filters });
const mapDispatchToProps = { setVehicleFilter };

export default connect(mapStateToProps, mapDispatchToProps)(ReportPalletJackCharges);