import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
	currentValue: PropTypes.number,
	color: PropTypes.oneOf(['primary', 'success', 'warning', 'danger']),
	noMargin: PropTypes.bool,
	height: PropTypes.number,
	title: PropTypes.string,
};

const defaultProps = {
	color: 'primary',
	currentValue: 100,
};

const ProgressBar = ({ color, currentValue, noMargin, height, title }) => (
	<div title={title} className="progress" style={{ margin: noMargin ? 0 : undefined, height }}>
		<div className={`progress-bar progress-bar-${color} progress-bar-striped active`} style={{width: `${currentValue}%`}}>
			<span className="sr-only">{currentValue}% Complete (success)</span>
		</div>
	</div>
);

ProgressBar.propTypes = propTypes;
ProgressBar.defaultProps = defaultProps;

export { ProgressBar };