export default {
	root: '/Tracking',
	exceptionsByTime: '/Tracking/ExceptionsByTime',
	lastPing: '/Tracking/LastPing',
	palletJackBatteryInfoLive: '/Tracking/PalletJackBatteryInfoLive',
	palletJackLowBattery: '/Tracking/PalletJackLowBattery',
	speedTicketsByTime: '/Tracking/SpeedTicketsByTime',
	vehiclesByPlace: '/Tracking/VehiclesByPlace',
	vehiclesMissingNow: '/Tracking/VehiclesMissingNow',
	batteryChargesRecentByVeh: (fleetNumber: string) => `/Tracking/BatteryChargesRecentByVeh/${fleetNumber}`,
	batteryChargesReportByPj: (fleetNumber: string) => `/Tracking/BatteryChargesReportByPj/${fleetNumber}`,
	breadcrumbsByVeh: (fleetNumber: string) => `/Tracking/BreadcrumbsByVeh/${fleetNumber}`,
	fullJourneyReport: (fleetNumber: string) => `/Tracking/FullJourneyReport/${fleetNumber}`,
	journeyStepsByVeh: (fleetNumber: string) => `/Tracking/JourneyStepsByVeh/${fleetNumber}`,
	parkedTimeByVeh: (fleetNumber: string) => `/Tracking/ParkedTimeByVeh/${fleetNumber}`
};