import React, { Component } from 'react';
import moment from 'moment';
import Icon from 'react-fontawesome';

import { MainContent } from '../../common/layout';
import { PalletJackLowBatteriesList } from '../../common/tracking';
import { ContentBox } from '../../common/uiElements';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';

class ReportPalletJackLowBatteries extends Component {
	constructor(props) {
		super(props);
		this.state = {
			timestamp: moment()
		}
	}
	
	render() {
		const s = this.state;

		return (
			<div>
				<MainContent title="Pallet Jack - Low Batteries" subtitle="">
					<Row>
						<Col xs={12}>
							<FormGroup>
								<Button
									block
									bsStyle="primary"
									bsSize="lg"
									onClick={() => this.setState({ timestamp: moment() })}
								>
									<Icon name="refresh" />
									Refresh Pallet Jacks
								</Button>
							</FormGroup>
						</Col>
					</Row>
					<Row key={s.timestamp}>
						<Col xs={12}>
							<ContentBox title="Pallet Jacks with Low Battery" icon="cube" color="primary">
								<PalletJackLowBatteriesList  />
							</ContentBox>
						</Col>
					</Row>
				</MainContent>
			</div>
		);
	}
}

export default ReportPalletJackLowBatteries;

