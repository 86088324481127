import groups from '../app/schemas/PermissionGroups';

import PalletJackMonitoring from '../components/features/panels/PalletJackMonitoring';
import PreStartCheckout from '../components/features/panels/PreStartCheckout';
import PalletLoad from '../components/features/panels/PalletLoad';

export default { 
	path: 'panels',
	menuItem: { title: 'Panels', icon: 'television' },
	authorize: [
		groups.ADMIN_STAFF_FULL, groups.ADMIN_STAFF_SIMPLE, 
		groups.WASHBAY_ADMIN, groups.WASHBAY_MECHANICS, groups.DEPOT_ADMIN,
		groups.SUPERVISORS, groups.KIOSK, groups.TV
	],
	childRoutes: [
		{ 
			path: 'palletJackBatteries', 
			menuItem: { title: 'Pallet Jack Batteries', icon: 'plug' },
			component: PalletJackMonitoring
		},
		{ 
			path: 'preStartsCheckOut', 
			menuItem: { title: 'Pre-Starts Check Out', icon: 'check-square' },
			component: PreStartCheckout,
		},
		{ 
			path: 'palletLoadTracking', 
			menuItem: { title: 'Pallet Load', icon: 'archive' },
			component: PalletLoad,
		}
	]
};