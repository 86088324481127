import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, InputGroup } from 'react-bootstrap';

import { DatePeriodInput, UserSearch, Checkbox, Select, YearsAndWeekDays } from '../../../common/inputs';
import { AbsenceReasonsList } from '../../../common/lists';
import { FieldGroup } from '../../../common/uiElements';

const propTypes = {
	filterBy: PropTypes.oneOf(['', 'intersection', 'requestDate', 'revisedDate', 'startDate', 'endDate']),
	week: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	workerId: PropTypes.string,
	typeId: PropTypes.string,
	withPay: PropTypes.string,
	status: PropTypes.oneOf(['', 'pending', 'approved', 'denied', 'cancelled']),
	isRevised: PropTypes.string,
	isActive: PropTypes.bool,
	hideAlreadyTaken: PropTypes.bool,
	hideEmployee: PropTypes.bool,
	hideType: PropTypes.bool,
	hideStatus: PropTypes.bool,
	hideRevised: PropTypes.bool,
	hideWithPay: PropTypes.bool,
	onChange: PropTypes.func
};

const defaultProps = {
	filterBy: '',
	status: '',
	isRevised: '',
	hideAlreadyTaken: true
};

const yesNoOptions = [
	{ text: 'Yes', value: true },
	{ text: 'No', value: false }
];

const statusOptions = [
	{ text: 'Pending', value: 'pending' },
	{ text: 'Approved', value: 'approved' },
	{ text: 'Denied', value: 'denied' },
	{ text: 'Cancelled', value: 'cancelled' }
];

const filterTypes = [
	{ text: 'By Week', value: 'intersection' },
	{ text: 'By Request Date', value: 'requestDate' },
	{ text: 'By Revision Date', value: 'revisedDate' },
	{ text: 'By Start Date', value: 'startDate' },
	{ text: 'By End Date', value: 'endDate' },
];

const LeaveRequestListFilter = ({
	filterBy,
	week,
	year,
	startDate,
	endDate,
	workerId,
	typeId,
	withPay,
	isRevised,
	status,
	hideAlreadyTaken,
	hideEmployee,
	hideType,
	hideStatus,
	hideRevised,
	hideWithPay,
	onChange
}) => {
	const handleChange = e => {
		const { id, type, checked, value } = e.target;
		var val = type === 'checkbox' ? checked : value;
		onChange && onChange({ [id]: val });
	};

	const handleChangeWorker = user => {
		onChange && onChange({ 'workerId': user.value });
	};

	const handleChangePeriod = (startDate, endDate) => {
		startDate = startDate.format('YYYY-MM-DD');
		endDate = endDate.format('YYYY-MM-DD');
		onChange && onChange({ startDate, endDate });
	};

	const handleChangeWeek = ({ week, year, startDate, endDate }) => {
		onChange && onChange({ week, year, startDate, endDate });
	};

	return (
		<>
			<Row>
				<FieldGroup label="Period" md={12} lg={6}>
					<InputGroup style={{ width: '100%' }}>
						<Select 
							showAllOption
							id="filterBy"
							data={filterTypes}
							value={filterBy || ''}
							onChange={handleChange} 
						/>
						<InputGroup.Button style={{ width: 0 }}></InputGroup.Button>
						{
							filterBy && (
								filterBy !== 'intersection' ? (
									<DatePeriodInput 
										noRedux
										showDefaultRanges
										showFutureDefaultRanges
										startDate={moment(startDate)}
										endDate={moment(endDate)}
										showHours={false}
										format="DD/MM/YYYY"
										onChange={handleChangePeriod}
									/>
								) : (
									<div>
										<YearsAndWeekDays 
											week={week} 
											year={year} 
											maxYear={moment().year() + 3}
											onChange={handleChangeWeek} 
										/>
									</div>
								)
							)
						}
					</InputGroup>
				</FieldGroup>
				{
					!hideEmployee && (
						<FieldGroup label="Employee" sm={6} md={6} lg={3}>
							<UserSearch 
								id="workerId"
								value={workerId || ''}
								onlyActive={false}
								onChange={handleChangeWorker} 
							/>
						</FieldGroup>
					)
				}
				{
					!hideType && (
						<FieldGroup label="Type" sm={6} md={6} lg={3}>
							<AbsenceReasonsList 
								showAllOption
								id="typeId" 
								value={typeId}
								onChange={handleChange} 
							/>
						</FieldGroup>
					)
				}
			</Row>
			<Row>
				{
					!hideStatus && (
						<FieldGroup label="Status" sm={6} lg={4}>
							<Select 
								showAllOption
								id="status"
								data={statusOptions}
								value={status}
								onChange={handleChange}
							/>
						</FieldGroup>
					)
				}
				{
					!hideRevised && (
						<FieldGroup label="Is Revised" sm={6} lg={3}>
							<Select 
								showAllOption
								id="isRevised"
								data={yesNoOptions}
								value={isRevised}
								onChange={handleChange} 
							/>
						</FieldGroup>
					)
				}
				{
					!hideWithPay && (
						<FieldGroup label="With Pay" sm={6} lg={3}>
							<Select 
								showAllOption
								id="withPay"
								data={yesNoOptions}
								value={withPay}
								onChange={handleChange} 
							/>
						</FieldGroup>
					)
				}
				<FieldGroup label="Hide Already Taken" sm={6} lg={2}>
					<Checkbox
						block
						id="hideAlreadyTaken"
						onChange={handleChange}
						checked={hideAlreadyTaken}
					/>
				</FieldGroup>
			</Row>
		</>
	);
};

LeaveRequestListFilter.propTypes = propTypes;
LeaveRequestListFilter.defaultProps = defaultProps;

export default LeaveRequestListFilter;