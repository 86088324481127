import React, { useState, Fragment } from 'react';
import { Form, FieldGroup, Callout } from '../../../../common/uiElements';
import { Checkbox, RadioButtonList, UserSearch } from '../../../../common/inputs';
import { FormControl, InputGroup, Row, Table } from 'react-bootstrap';
import { WorkerContractType, WorkerShiftType, WeekDayType } from '../../../../../app/models/CustomTypes';
import { WorkerAvailabilityForm } from '../../../../../app/models/Worker';
import TimeField from 'react-simple-timefield';
import { TaskTypesList, PlacesList, RunTypesList } from '../../../../common/lists';
import moment from 'moment';
import { IReactSelectOption } from '../../../../../app/models/Application';

interface IProps extends WorkerAvailabilityForm {
	id: string;
	isReadOnly?: boolean;
	isLoggedUser: boolean;
	onChange?: (id: string, value: any) => void;
	onClearDayOfWeekData?: (dayOfWeek: WeekDayType) => void;
	onClearRotate: () => void;
}
// Validation Schemas for editing the form
const ordinaryHoursDefaultValidation = { required: true, number: true, min: 1, max: 14 }
const startTimeDefaultValidation = { required: true , time: true}

const validations = {
	mainTaskTypeId: 'required',
	ordinaryTotalDaysOn: { number: true, required: true, min: 1, max: 7 },
	ordinaryTotalDaysOff: { number: true, required: true, min: 1, max: 7 },
	rotateWithWorkerId: 'required',
	ordinaryStartTime: 'required',
	ordinaryHours: ordinaryHoursDefaultValidation,
	ordinaryStartingPlaceId: 'required',

	selectedDaysOfWeek: 'required',

	mondayStartTime: startTimeDefaultValidation,
	tuesdayStartTime: startTimeDefaultValidation,
	wednesdayStartTime: startTimeDefaultValidation,
	thursdayStartTime: startTimeDefaultValidation,
	fridayStartTime: startTimeDefaultValidation,
	saturdayStartTime: startTimeDefaultValidation,
	sundayStartTime: startTimeDefaultValidation,

	mondayStartingPlaceId: 'required',
	tuesdayStartingPlaceId: 'required',
	wednesdayStartingPlaceId: 'required',
	thursdayStartingPlaceId: 'required',
	fridayStartingPlaceId: 'required',
	saturdayStartingPlaceId: 'required',
	sundayStartingPlaceId: 'required',

	mondayOrdinaryHours: ordinaryHoursDefaultValidation,
	tuesdayOrdinaryHours: ordinaryHoursDefaultValidation,
	wednesdayOrdinaryHours: ordinaryHoursDefaultValidation,
	thursdayOrdinaryHours: ordinaryHoursDefaultValidation,
	fridayOrdinaryHours: ordinaryHoursDefaultValidation,
	saturdayOrdinaryHours: ordinaryHoursDefaultValidation,
	sundayOrdinaryHours: ordinaryHoursDefaultValidation,
	
};

const validationMessages = {
	selectedDaysOfWeek: {
		required: 'At least one day of the week is required'
	},
};

const WorkerAvailability: React.FC<IProps> = props => {
	const [shiftType, setShiftType] = useState<WorkerShiftType>(props.ordinaryHours ? 'rotate' : 'normal');
	const [showMonday, setShowMonday] = useState(props.mondayStartTime ? true : false);
	const [showTuesday, setShowTuesday] = useState(props.tuesdayStartTime ? true : false);
	const [showWednesday, setShowWednesday] = useState(props.wednesdayStartTime ? true : false);
	const [showThursday, setShowThursday] = useState(props.thursdayStartTime ? true : false);
	const [showFriday, setShowFriday] = useState(props.fridayStartTime ? true : false);
	const [showSaturday, setShowSaturday] = useState(props.saturdayStartTime ? true : false);
	const [showSunday, setShowSunday] = useState(props.sundayStartTime ? true : false);

	const checkAtLeastOneWeekDayIsSelected = () => {
		return showMonday || showTuesday || showWednesday || showThursday || showFriday || showSaturday || showSunday;
	};

	const getTotalOrdinaryHours = () => {
		if (shiftType === 'rotate' && props.ordinaryHours && props.ordinaryTotalDaysOn)
			return parseFloat(props.ordinaryHours) * parseInt(props.ordinaryTotalDaysOn);
		
		let totalHours = 0;
		if (props.mondayOrdinaryHours && !props.isMondayOvertime) totalHours += parseFloat(props.mondayOrdinaryHours);
		if (props.tuesdayOrdinaryHours && !props.isTuesdayOvertime) totalHours += parseFloat(props.tuesdayOrdinaryHours);
		if (props.wednesdayOrdinaryHours && !props.isWednesdayOvertime) totalHours += parseFloat(props.wednesdayOrdinaryHours);
		if (props.thursdayOrdinaryHours && !props.isThursdayOvertime) totalHours += parseFloat(props.thursdayOrdinaryHours);
		if (props.fridayOrdinaryHours && !props.isFridayOvertime) totalHours += parseFloat(props.fridayOrdinaryHours);
		if (props.saturdayOrdinaryHours && !props.isSaturdayOvertime) totalHours += parseFloat(props.saturdayOrdinaryHours);
		if (props.sundayOrdinaryHours && !props.isSundayOvertime) totalHours += parseFloat(props.sundayOrdinaryHours);

		return totalHours;
	};

	const getTotalOvertimeHours = () => {
		let totalHours = 0;
		if (props.mondayOrdinaryHours && props.isMondayOvertime) totalHours += parseFloat(props.mondayOrdinaryHours);
		if (props.tuesdayOrdinaryHours && props.isTuesdayOvertime) totalHours += parseFloat(props.tuesdayOrdinaryHours);
		if (props.wednesdayOrdinaryHours && props.isWednesdayOvertime) totalHours += parseFloat(props.wednesdayOrdinaryHours);
		if (props.thursdayOrdinaryHours && props.isThursdayOvertime) totalHours += parseFloat(props.thursdayOrdinaryHours);
		if (props.fridayOrdinaryHours && props.isFridayOvertime) totalHours += parseFloat(props.fridayOrdinaryHours);
		if (props.saturdayOrdinaryHours && props.isSaturdayOvertime) totalHours += parseFloat(props.saturdayOrdinaryHours);
		if (props.sundayOrdinaryHours && props.isSundayOvertime) totalHours += parseFloat(props.sundayOrdinaryHours);

		return totalHours;
	};

	const handleCheckDayOfWeek = (dayOfWeek: WeekDayType, checked: boolean) => {
		if (dayOfWeek === 1) setShowMonday(checked);
		if (dayOfWeek === 2) setShowTuesday(checked);
		if (dayOfWeek === 3) setShowWednesday(checked);
		if (dayOfWeek === 4) setShowThursday(checked);
		if (dayOfWeek === 5) setShowFriday(checked);
		if (dayOfWeek === 6) setShowSaturday(checked);
		if (dayOfWeek === 7) setShowSunday(checked);
	
		props.onClearDayOfWeekData && props.onClearDayOfWeekData(dayOfWeek);
	};

	const handleChangeShiftType = (e: any, value: WorkerShiftType) => {
		setShiftType(value);

		if (value === 'normal')
			props.onClearRotate && props.onClearRotate();

	};

	const handleChangeWeekDay = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { id, checked } = e.target;
		props.onChange && props.onChange(id, checked);
	};

	const handleChangeContractType = (e: any, value: WorkerContractType) => {
		props.onChange && props.onChange('isCasual', value === 'casual');
	};

	const handleChangeInput = (e: React.ChangeEvent<FormControl & HTMLInputElement>) => {
		const { id, value, checked, type } = e.target;
		props.onChange && props.onChange(id, type === 'checkbox' ? checked : value);
	};

	const handleChangeStartTime = (id: string, startTime: string) => {
		props.onChange && props.onChange(id, startTime);
	};
	
	const handleChangeWorkerToRotateWith = (worker?: IReactSelectOption) => {
		props.onChange && props.onChange('rotateWithWorkerId', worker?.value || '');
	};

	const renderDayOffCell = () => (
		<th className="bg-danger" rowSpan={5} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
			<h3>DAY OFF</h3>
		</th>
	);

	const renderDayOfWeekCheckBoxCell = (dayOfWeek: WeekDayType, id: string, text: string, checked: boolean, startTime: string, isOvertime: boolean) => (
		<th className={`text-center ${ props.isReadOnly ? startTime ? isOvertime ? 'bg-warning' : 'bg-success' : 'bg-danger' : '' }`} style={{ textAlign: 'center', width: '12.8%' }}>
			{
				props.isReadOnly ? (
					text
				) : (
					<Checkbox
						noMargin
						bold
						id={id}
						checked={checked}
						text={text}
						onChange={() => handleCheckDayOfWeek(dayOfWeek, !checked)}
					/>
				)
			}
		</th>
	);

	const renderStartTimeCell = (id: string, value: string, isOvertime: boolean) => (
		<td className={`text-center ${ isOvertime ? 'bg-warning' : 'bg-success' }`}>
			{
				props.isReadOnly ? (
					moment(value, 'HH:mm:ss').format('HH:mm')
				) : (
					<TimeField
						width={4}
						value={value || '__:__'} 
						input={<FormControl bsSize="small" id={id} />} 
						onChange={(value: string) => handleChangeStartTime(id, value)}
					/>
				)
			}
		</td>
	);

	const renderOrdinaryHoursCell = (id: string, value: string, isOvertime: boolean) => (
		<td className={`text-center ${ isOvertime ? 'bg-warning' : 'bg-success' }`}>
			{
				props.isReadOnly ? (
					value + ' h'
				) : (
					<FormControl
						id={id}
						bsSize="small"
						maxLength={5}
						placeholder="Ordinary Hours"
						value={value || ''}
						onChange={handleChangeInput}
					/>
				)
			}
		</td>
	);

	const renderStartingDepotCell = (id: string, value: string, readOnlyValue: string, isOvertime: boolean) => (
		<td className={`text-center ${ isOvertime ? 'bg-warning' : 'bg-success' }`}>
			{
				props.isReadOnly ? readOnlyValue : (
					<PlacesList
						id={id}
						firstOptionText="--- Starting Place ---"
						type="rosterStartingDepot"
						bsSize="small"
						value={value || ''}
						onChange={handleChangeInput}
					/>
				)
			}
		</td>
	);

	const renderIsOvertimeCheckBoxCell = (id: string, checked: boolean) => (
		<td className={`text-center bg-${ checked ? 'warning' : 'success' }`}>
			{
				props.isReadOnly ? (
					checked ? <b className="text-danger">YES</b> : 'NO'
				) : (
					<Checkbox
						noMargin
						id={id}
						checked={checked}
						text={checked ? 'YES' : 'NO'}
						onChange={handleChangeWeekDay}
					/>
				)
			}
		</td>
	);

	const renderRunTypeCell = (id: string, value: string, readOnlyValue: string, isOvertime: boolean) => (
		<td className={`text-center ${ isOvertime ? 'bg-warning' : 'bg-success' }`}>
			{
				props.isReadOnly ? readOnlyValue : (
					<RunTypesList
						id={id}
						firstOptionText="--- Run Type ---"
						bsSize="small"
						value={value || ''}
						onChange={handleChangeInput}
					/>
				)
			}
		</td>
	);

	if (!props)
		return <Callout color="info" text="No Data to be displayed" />;
		

	const totalOrdinaryHours = getTotalOrdinaryHours();
	const totalOvertimeHours = getTotalOvertimeHours();
	const totalHours = totalOrdinaryHours + totalOvertimeHours;

	return (
		<Form validations={validations} messages={validationMessages}>
			<Row>
				<FieldGroup label="Main Task Type" required xs={6} md={3}>
					{
						props.isReadOnly ? (
							<p>{ props.mainTaskTypeName || '-' }</p>
						) : (
							<TaskTypesList 
								id="mainTaskTypeId"
								value={props.mainTaskTypeId || ''}
								onChange={handleChangeInput}
							/>
						)
					}
				</FieldGroup>
			</Row>
			<FieldGroup label="Contract Type" required>
				{
					props.isReadOnly ? (
						<p>{ props.isCasual ? 'Casual' : 'Permanent' }</p>
					) : (
						<RadioButtonList 
							items={[
								{ value: 'permanent', text: 'Permanent' },
								{ value: 'casual', text: 'Casual' },
							]}
							value={props.isCasual ? 'casual' : 'permanent'}
							onChange={handleChangeContractType}
						/>
					)
				}
			</FieldGroup>
			<FieldGroup label="Shift Type" required>
				{
					props.isReadOnly ? (
						<p>{ props.rotateWithWorkerId ? 'Rotate' : 'Normal' }</p>
					) : (
						<RadioButtonList 
							items={[
								{ value: 'normal', text: 'Normal' },
								{ value: 'rotate', text: 'Rotate' },
							]}
							value={shiftType}
							onChange={handleChangeShiftType}
						/>
					)
				}
			</FieldGroup>
			{
				shiftType === 'normal' ? (
					<Fragment>
						<input id="selectedDaysOfWeek" type="hidden" value={checkAtLeastOneWeekDayIsSelected() ? 'OK' : ''} />
						<Table className="table-black-border" bordered>
							<thead>
								<tr>
									<th></th>
									{ renderDayOfWeekCheckBoxCell(1, 'monday', 'Monday', showMonday,  props.mondayStartTime,  props.isMondayOvertime) }
									{ renderDayOfWeekCheckBoxCell(2, 'tuesday', 'Tuesday', showTuesday, props.tuesdayStartTime, props.isTuesdayOvertime) }
									{ renderDayOfWeekCheckBoxCell(3, 'wednesday', 'Wednesday', showWednesday, props.wednesdayStartTime, props.isWednesdayOvertime) }
									{ renderDayOfWeekCheckBoxCell(4, 'thursday', 'Thursday', showThursday, props.thursdayStartTime, props.isThursdayOvertime) }
									{ renderDayOfWeekCheckBoxCell(5, 'friday', 'Friday', showFriday, props.fridayStartTime, props.isFridayOvertime) }
									{ renderDayOfWeekCheckBoxCell(6, 'saturday', 'Saturday', showSaturday, props.saturdayStartTime, props.isSaturdayOvertime) }
									{ renderDayOfWeekCheckBoxCell(7, 'sunday', 'Sunday', showSunday, props.sundayStartTime, props.isSundayOvertime) }
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>Start Time</th>
									{ showMonday ? renderStartTimeCell('mondayStartTime', props.mondayStartTime, props.isMondayOvertime) : renderDayOffCell()  }
									{ showTuesday ? renderStartTimeCell('tuesdayStartTime', props.tuesdayStartTime, props.isTuesdayOvertime) : renderDayOffCell() }
									{ showWednesday ? renderStartTimeCell('wednesdayStartTime', props.wednesdayStartTime, props.isWednesdayOvertime) : renderDayOffCell() }
									{ showThursday ? renderStartTimeCell('thursdayStartTime', props.thursdayStartTime, props.isThursdayOvertime) : renderDayOffCell() }
									{ showFriday ? renderStartTimeCell('fridayStartTime', props.fridayStartTime, props.isFridayOvertime) : renderDayOffCell() }
									{ showSaturday ? renderStartTimeCell('saturdayStartTime', props.saturdayStartTime, props.isSaturdayOvertime) : renderDayOffCell() }
									{ showSunday ? renderStartTimeCell('sundayStartTime', props.sundayStartTime, props.isSundayOvertime) : renderDayOffCell() }
								</tr>
								<tr>
									<th>Ordinary Hours</th>
									{ showMonday && renderOrdinaryHoursCell('mondayOrdinaryHours', props.mondayOrdinaryHours, props.isMondayOvertime)  }
									{ showTuesday && renderOrdinaryHoursCell('tuesdayOrdinaryHours', props.tuesdayOrdinaryHours, props.isTuesdayOvertime) }
									{ showWednesday && renderOrdinaryHoursCell('wednesdayOrdinaryHours', props.wednesdayOrdinaryHours, props.isWednesdayOvertime) }
									{ showThursday && renderOrdinaryHoursCell('thursdayOrdinaryHours', props.thursdayOrdinaryHours, props.isThursdayOvertime) }
									{ showFriday && renderOrdinaryHoursCell('fridayOrdinaryHours', props.fridayOrdinaryHours, props.isFridayOvertime) }
									{ showSaturday && renderOrdinaryHoursCell('saturdayOrdinaryHours', props.saturdayOrdinaryHours, props.isSaturdayOvertime) }
									{ showSunday && renderOrdinaryHoursCell('sundayOrdinaryHours', props.sundayOrdinaryHours, props.isSundayOvertime) }
								</tr>
								<tr>
									<th>Starting Place</th>
									{ showMonday && renderStartingDepotCell('mondayStartingPlaceId', props.mondayStartingPlaceId, props.mondayStartingPlaceName, props.isMondayOvertime)  }
									{ showTuesday && renderStartingDepotCell('tuesdayStartingPlaceId', props.tuesdayStartingPlaceId, props.tuesdayStartingPlaceName, props.isTuesdayOvertime) }
									{ showWednesday && renderStartingDepotCell('wednesdayStartingPlaceId', props.wednesdayStartingPlaceId, props.wednesdayStartingPlaceName, props.isWednesdayOvertime) }
									{ showThursday && renderStartingDepotCell('thursdayStartingPlaceId', props.thursdayStartingPlaceId, props.thursdayStartingPlaceName, props.isThursdayOvertime) }
									{ showFriday && renderStartingDepotCell('fridayStartingPlaceId', props.fridayStartingPlaceId, props.fridayStartingPlaceName, props.isFridayOvertime) }
									{ showSaturday && renderStartingDepotCell('saturdayStartingPlaceId', props.saturdayStartingPlaceId, props.saturdayStartingPlaceName, props.isSaturdayOvertime) }
									{ showSunday && renderStartingDepotCell('sundayStartingPlaceId', props.sundayStartingPlaceId, props.sundayStartingPlaceName, props.isSundayOvertime) }
								</tr>
								<tr>
									<th>Is Overtime?</th>
									{ showMonday && renderIsOvertimeCheckBoxCell('isMondayOvertime', props.isMondayOvertime)  }
									{ showTuesday && renderIsOvertimeCheckBoxCell('isTuesdayOvertime', props.isTuesdayOvertime) }
									{ showWednesday &&renderIsOvertimeCheckBoxCell('isWednesdayOvertime', props.isWednesdayOvertime) }
									{ showThursday && renderIsOvertimeCheckBoxCell('isThursdayOvertime', props.isThursdayOvertime) }
									{ showFriday && renderIsOvertimeCheckBoxCell('isFridayOvertime', props.isFridayOvertime) }
									{ showSaturday && renderIsOvertimeCheckBoxCell('isSaturdayOvertime', props.isSaturdayOvertime) }
									{ showSunday && renderIsOvertimeCheckBoxCell('isSundayOvertime', props.isSundayOvertime) }
								</tr>
								<tr>
									<th>Run Type</th>
									{ showMonday && renderRunTypeCell('mondayRunTypeId', props.mondayRunTypeId || '', props.mondayRunTypeName || '-', props.isMondayOvertime)  }
									{ showTuesday && renderRunTypeCell('tuesdayRunTypeId', props.tuesdayRunTypeId || '', props.tuesdayRunTypeName || '-', props.isTuesdayOvertime) }
									{ showWednesday && renderRunTypeCell('wednesdayRunTypeId', props.wednesdayRunTypeId || '', props.wednesdayRunTypeName || '-', props.isWednesdayOvertime) }
									{ showThursday && renderRunTypeCell('thursdayRunTypeId', props.thursdayRunTypeId || '', props.thursdayRunTypeName || '-', props.isThursdayOvertime) }
									{ showFriday && renderRunTypeCell('fridayRunTypeId', props.fridayRunTypeId || '', props.fridayRunTypeName || '-', props.isFridayOvertime) }
									{ showSaturday && renderRunTypeCell('saturdayRunTypeId', props.saturdayRunTypeId || '', props.saturdayRunTypeName || '-', props.isSaturdayOvertime) }
									{ showSunday && renderRunTypeCell('sundayRunTypeId', props.sundayRunTypeId || '', props.sundayRunTypeName || '-', props.isSundayOvertime) }
								</tr>
							</tbody>
							<tfoot>
								<tr style={{ backgroundColor: '#f7f7f7' }}>
									<th>TOTAL</th>
									<th className="text-center" colSpan={3}>
										{ totalHours ? totalHours.toFixed(1).replace(/\.0$/, '') + ' hours' : '-' }
									</th>
									<th>ORDINARY</th>
									<th className="text-center text-success" colSpan={1}>
										{ totalOrdinaryHours ? totalOrdinaryHours.toFixed(1).replace(/\.0$/, '') + ' hours' : '-'  }
									</th>
									<th>OVERTIME</th>
									<th className="text-center text-danger" colSpan={1}>
										{ totalOvertimeHours ? totalOvertimeHours.toFixed(1).replace(/\.0$/, '') + ' hours' : '-'  }
									</th>
								</tr>
							</tfoot>
						</Table>
					</Fragment>
				) : (
					<Fragment>
						<Row>
							<FieldGroup label="Ordinary Starting Place" required xs={12} md={6} lg={4}>
								{
									props.isReadOnly ? (
										<p>{ props.ordinaryStartingPlaceName }</p>
									) : (
										<PlacesList
											id="ordinaryStartingPlaceId"
											firstOptionText="--- Starting Place ---"
											type="rosterStartingDepot"
											value={props.ordinaryStartingPlaceId || ''}
											onChange={handleChangeInput}
										/>
									)
								}
							</FieldGroup>
						</Row>
						<Row>
							<FieldGroup label="Ordinary Start Time" required xs={6} md={4} lg={3}>
								{
									props.isReadOnly ? (
										<p>{ moment(props.ordinaryStartTime, 'HH:mm:ss').format('HH:mm') }</p>
									) : (
										<TimeField
											width={4}
											value={props.ordinaryStartTime || '__:__'} 
											input={<FormControl id="ordinaryStartTime" />} 
											onChange={(value: string) => handleChangeStartTime('ordinaryStartTime', value)}
										/>
									)
								}
							</FieldGroup>
							<FieldGroup label="Ordinary Hours" required xs={6} md={4} lg={3}>
								{
									props.isReadOnly ? (
										<p>{ props.ordinaryHours + ' hours' || '-'}</p>
									) : (
										<InputGroup>
											<FormControl 
												id="ordinaryHours"
												maxLength={5}
												value={props.ordinaryHours || ''}
												onChange={handleChangeInput}
											/>
											<InputGroup.Addon>hours</InputGroup.Addon>
										</InputGroup>
									)
								}
							</FieldGroup>
						</Row>
						<Row>
							<FieldGroup label="Total Days On" required xs={6} md={4} lg={3}>
								{
									props.isReadOnly ? (
										<p>{ props.ordinaryTotalDaysOn + ' days' || '-'}</p>
									) : (
										<InputGroup>
											<FormControl 
												id="ordinaryTotalDaysOn"
												maxLength={1}
												value={props.ordinaryTotalDaysOn || ''}
												onChange={handleChangeInput}
											/>
											<InputGroup.Addon>days</InputGroup.Addon>
										</InputGroup>
									)
								}
							</FieldGroup>
							<FieldGroup label="Total Days Off" required xs={6} md={4} lg={3}>
								{
									props.isReadOnly ? (
										<p>{ props.ordinaryTotalDaysOff + ' days' || '-'}</p>
									) : (
										<InputGroup>
											<FormControl 
												id="ordinaryTotalDaysOff"
												type="number" 
												min={1}
												value={props.ordinaryTotalDaysOff || ''}
												onChange={handleChangeInput}
											/>
											<InputGroup.Addon>days</InputGroup.Addon>
										</InputGroup>
									)
								}
							</FieldGroup>
						</Row>
						<Row>
							<FieldGroup label="Rotate With" required md={8} lg={5}>
								{
									props.isReadOnly ? (
										<p>{ props.workerToRotateWith?.shortName }</p>
									) : (
										<UserSearch
											id="rotateWithWorkerId" 
											value={props.rotateWithWorkerId || ''} 
											onChange={handleChangeWorkerToRotateWith} 
										/>
									)
								}
							</FieldGroup>
						</Row>
						<Row>
							<FieldGroup label="Ordinary Run Type" optional md={8} lg={5}>
								{
									props.isReadOnly ? (
										<p>{ props.ordinaryRunTypeName || '-' }</p>
									) : (
										<RunTypesList
											id="ordinaryRunTypeId"
											value={props.ordinaryRunTypeId || ''}
											onChange={handleChangeInput}
										/>
									)
								}
							</FieldGroup>
						</Row>
					</Fragment>
				)
			}
			<Row>
				<FieldGroup label="Comments" optional md={8} lg={5}>
					{
						props.isReadOnly ? (
							<p>{ props.comments || '-' }</p>
						) : (
							<FormControl
								id="comments" 
								maxLength={1000} 
								componentClass="textarea"
								placeholder="Any relevant comments..."
								value={props.comments || ''} 
								onChange={handleChangeInput} 
							/>
						)
					}
				</FieldGroup>
			</Row>
		</Form>
	);
};

export default WorkerAvailability;