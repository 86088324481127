/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Icon from 'react-fontawesome';
import { Row, Col, FormGroup, FormControl } from 'react-bootstrap';
import { Form } from '../../../../common/uiElements';
import { UserSearch, MultipleFiles } from '../../../../common/inputs';
import correctiveActionType from '../../../../../app/propTypes/correctiveActionType';
import CorrectiveActionApi from '../../../../../app/api/CorrectiveActionApi';
import { NonConformanceRootCausesList } from '../../../../common/lists/NonConformanceRootCausesList';

const propTypes = {
	...correctiveActionType,
	files: PropTypes.arrayOf(PropTypes.object),
	showRootCauseField: PropTypes.bool, // Will show the root cause field only if it is the first corrective action of the non conformance
	occurredOn: PropTypes.string, // will be used to validate the action taken
	isEditMode: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	onChange: PropTypes.func,
	onChangeUser: PropTypes.func,
	onAddFiles: PropTypes.func
};

const defaultProps = {
	actionTakenById: '',
	actionTakenOn: '',
	closedOutOn: '',
	nonConformanceRootCauseId: '',
	shortTermAction: '',
	longTermAction: '',
	preventativeAction: '',
	comments: '',
	estimatedCost: '',
	actualCost: '',
	WorkerActionTakenBy: null,
	showRootCauseField: true,
	isEditMode: false,
	isReadOnly: false,
	onChange: () => {},
	onChangeUser: () => {}
};

const validations = {
	actionTakenById: 'required',
	actionTakenOn: {
		required: true,
		greaterOrEqualThan: '#occurredOn'
	},
	shortTermAction: 'required',
	nonConformanceRootCauseId: 'required',
	preventativeAction: 'required'
};

const CorrectiveActionForm = props => (
	<Form validations={validations}>
		<FormControl id="occurredOn" type="hidden" value={props.occurredOn || ''} />
		<Row>
			<Col sm={6}>
				<FormGroup>
					<label>Action Taken By *</label>
					{
						props.isReadOnly ? (
							<p>{props.WorkerActionTakenBy?.shortName}</p>
						) : (
							<UserSearch 
								id="actionTakenById"
								value={props.actionTakenById}
								onChange={user => props.onChangeUser('actionTakenById', user.value)}
							/>
						)
					}
				</FormGroup>
			</Col>
			<Col sm={6}>
				<FormGroup>
					<label>Action Taken On *</label>
					{
						props.isReadOnly ? (
							<p>{props.actionTakenOn ? moment.utc(props.actionTakenOn).local().format('DD/MM/YYYY') : '-'}</p>
						) : (
							<FormControl 
								id="actionTakenOn" 
								type="date" 
								value={moment(props.actionTakenOn).isValid() ? moment(props.actionTakenOn).format('YYYY-MM-DD') : ''}
								onChange={props.onChange} 
							/>
						)
					}
				</FormGroup>
			</Col>
		</Row>
		<Row>
			{
				props.showRootCauseField && (
				<Col sm={6}>
					<FormGroup>
						<label>Root Cause *</label>
						{
							props.isReadOnly ? (
								<p>{props.nonConformanceRootCauseId}</p>
							) : (
								<NonConformanceRootCausesList 
									id="nonConformanceRootCauseId" 
									value={props.nonConformanceRootCauseId} 
									onChange={props.onChange} 
								/>
							)
						}
					</FormGroup>
				</Col>
				)
			}
			<Col xs={6}>
				<FormGroup>
					<label>Preventative Action *</label>
					{
						props.isReadOnly ? (
							<p>{props.preventativeAction}</p>
						) : (
							<FormControl
								id="preventativeAction" 
								maxLength="1000" 
								componentClass="textarea"
								value={props.preventativeAction} 
								onChange={props.onChange} 
							/>
						)
					}
				</FormGroup>
			</Col>
		</Row>
		<Row>
			<Col sm={6}>
				<FormGroup>
					<label>Short Term Action *</label>
					{
						props.isReadOnly ? (
							<p>{props.shortTermAction}</p>
						) : (
							<FormControl
								id="shortTermAction" 
								maxLength="1000" 
								componentClass="textarea"
								value={props.shortTermAction} 
								onChange={props.onChange} 
							/>
						)
					}
				</FormGroup>
			</Col>
			<Col xs={6}>
				<FormGroup>
					<label>Long Term Action</label> (Optional)
					{
						props.isReadOnly ? (
							<p>{props.longTermAction || '-'}</p>
						) : (
							<FormControl
								id="longTermAction" 
								maxLength="1000" 
								componentClass="textarea"
								value={props.longTermAction || ''} 
								onChange={props.onChange} 
							/>
						)
					}
				</FormGroup>
			</Col>
		</Row>
		<FormGroup>
			<label>Comments</label> (Optional)
			{
				props.isReadOnly ? (
					<p>{props.comments || '-'}</p>
				) : (
					<FormControl
						id="comments" 
						maxLength="1000" 
						componentClass="textarea"
						placeholder="Add any relevant comment..."
						value={props.comments} 
						onChange={props.onChange} 
					/>
				)
			}
		</FormGroup>
		<Row>
			<Col xs={12} sm={6}>
				<FormGroup>
					<label>Estimated Cost</label> (Optional)
					{
						props.isReadOnly ? (
							<p>{props.estimatedCost || '-'}</p>
						) : (
							<FormControl
								id="estimatedCost" 
								type="number"
								value={props.estimatedCost} 
								onChange={props.onChange} 
							/>
						)
					}
				</FormGroup>
			</Col>
			<Col xs={12} sm={6}>
				<FormGroup>
					<label>Actual Cost</label> (Optional)
					{
						props.isReadOnly ? (
							<p>{props.actualCost || '-'}</p>
						) : (
							<FormControl
								id="actualCost" 
								type="number"
								value={props.actualCost} 
								onChange={props.onChange} 
							/>
						)
					}
				</FormGroup>
			</Col>
		</Row>
		<Row>
			<Col xs={12} sm={6}>
				<FormGroup>
				{
						props.isReadOnly ? (
							<Fragment>
								<label>Attachments</label><br />
								{
									props.PreStartCorrectiveActionDocuments?.map((doc, index) => (
										<ul key={index}>
											<li>
												<a title="Download File" onClick={() => CorrectiveActionApi.getDocument(props.id, doc.id)}>
													<Icon name="file-o" /> { doc.fileName }
												</a>
											</li>
										</ul>
									))
								}
							</Fragment>
						) : (
							<MultipleFiles 
								files={props.files}
								onChange={props.onAddFiles}
							/>
						)
					}
				</FormGroup>
			</Col>
		</Row>
	</Form>
);


CorrectiveActionForm.propTypes = propTypes;
CorrectiveActionForm.defaultProps = defaultProps;

export default CorrectiveActionForm;