import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, FormControl } from 'react-bootstrap';
import moment from 'moment';
import Icon from 'react-fontawesome';

import WorkerDetailsSchema from '../../../../../app/schemas/worker/WorkerDetailsSchema.json';
import WorkerApi from '../../../../../app/api/WorkerApi';
import { AzurePermissionGroupsList } from '../../../../common/lists';
import { Form } from '../../../../common/uiElements';
import { MultipleInputs, SelectInputWithData, ImageWithCropping } from '../../../../common/inputs';
import WorkerAddressHists from './WorkerAddressHists';


const propTypes = {
	id: PropTypes.string,
	data: PropTypes.object,
	onChange: PropTypes.func,
	editMode: PropTypes.bool,
	readOnly: PropTypes.bool,

	/** Worker's current TFN to don't check if it exists in case of edit mode */
	workerCurrentTFN: PropTypes.string,

	/** If true, it locks some editable data that the user can't change by himself */
	isLoggedUser: PropTypes.bool
};

const defaultProps = {
	id: 'WorkerDetails',
	data: WorkerDetailsSchema
};

const validations = {
	firstName: 'required',
	surname: 'required',
	email: { required: true, email: true },
	nationality: 'required',
	dob: 'required',
	permissionGroupId: 'required',
	address1: 'required',
	city: 'required',
	state: 'required',
	postCode: 'required',
	country: 'required'
};

const contactsSchema = [{ 
	id: 'phoneNumber', 
	description: 'Number *', 
	type: 'tel', 
	cssClass:'phone required', 
	cssCol: 'col-sm-5 col-lg-4' 
}
];

const emergencyContactsSchema = [{ 
	id: 'name', 
	description: 'Name', 
	maxLength: '50',
	type: 'text',
	cssClass: 'required',
	cssCol: 'col-sm-7' 
}, {
	id: 'relationship', 
	description: 'Relationship', 
	type: 'select', 
	selectType: 'relationships',
	cssClass: 'required',
	cssCol: 'col-sm-5'
}, { 
	id: 'phone1', 
	description: 'Phone 1', 
	type: 'text',
	cssClass: 'phone required',
	cssCol: 'col-sm-6'
}, {
	id: 'phone2', 
	description: 'Phone 2', 
	type: 'tel',
	cssClass: 'phone',
	cssCol: 'col-sm-6'
}
];

class WorkerDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userPhoto: ''
		};
	}

	UNSAFE_componentWillMount() {
		if (this.props.data.azureId)
			this.setUserPhoto();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	setUserPhoto = () => {
		if (!this.props.data.azureId)
			return;

		WorkerApi.getWorkerProfilePhoto(this.props.data.azureId)
			.then(imgBase64 => {
				if (this.unmounted) return;

				this.setState({ userPhoto: imgBase64 });
			})
			.catch(error => {
				console.error(error);
			});
	}

	checkTfnExists = (e) => {
		let element = e.target;
		let { tfn } = this.props.data;
		if (tfn) {
			let tfnToCheck = tfn.split(' ').join('');
			window.$(element).removeClass('error');
			window.$(element).parent().parent().find('.popover').hide();
			if (tfnToCheck.length === 9 && tfnToCheck !== this.props.workerCurrentTFN) {
				WorkerApi.checkTfnExists(tfnToCheck)
					.then((tfnExists) => {
						if (this.unmounted) return;

						if (tfnExists)
							window.$(element).focus().addClass('error')
								.popover({content: 'This TFN number is already in use. Try a different one.', placement: 'top', trigger: 'manual', html: true})
								.popover('show');
						else
							window.$(element).removeClass('error').addClass('valid');
					});
			}
		}
	}

	handleOnChange = (value, idField) => {
		if (this.props.onChange) {
			var { id, data } = this.props;
			if (!idField)
				data = { ...data, ...value };
			else
				data[idField] = value;
			
			return this.props.onChange(data, id);
		}
	}

	handleChangeInput = (e) => {
		var id = e.target.id;
		var value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

		return this.handleOnChange(value, id);
	}

	handleChangeMultipleInputs = (data) => {
		return this.handleOnChange(data);
	}

	handleRemovePhoto = () => {
		window.$('#photo').val('');
		this.handleOnChange(null, 'photo');
		this.handleOnChange(null, 'photoPath');
		this.handleOnChange(null, 'profilePictureBase64');
	}

	handleChangePhoto = ({photo}) => {
		if (FileReader && photo) {
			var fr = new FileReader();
			fr.readAsDataURL(photo);
			fr.onload = () => {
				this.handleOnChange(fr.result, 'profilePictureBase64');
			};
			return this.handleOnChange(photo, 'photo');
		}
	}

	render() {
		const p = this.props;
		const s = this.state;

		return (
			<Form validations={validations}>
				<div className="form-block">
					<h3>Personal Info</h3>
					<div className="form-body">
						<Row>
							<Col sm={6} lg={4}>
								<FormGroup>
									<label>First Name *</label>
									{
										p.readOnly || p.isLoggedUser ? (
											<p className="form-text">{ p.data.firstName }</p>
										) : (
											<FormControl
												id="firstName" 
												maxLength="50" 
												value={p.data.firstName} 
												onChange={this.handleChangeInput} 
											/>
										)
									}
								</FormGroup>
							</Col>
							<Col sm={6} lg={4}>
								<FormGroup>
									<label>Surname *</label>
									{
										p.readOnly || p.isLoggedUser ? (
											<p className="form-text">{ p.data.surname || p.isLoggedUser }</p>
										) : (
											<FormControl
												id="surname" 
												maxLength="50" 
												value={p.data.surname} 
												onChange={this.handleChangeInput} 
											/>
										)
									}
								</FormGroup>
							</Col>
							<Col sm={5} lg={4}>
								<FormGroup>
									<label>Date of Birth *</label>
									{
										p.readOnly || p.isLoggedUser ? (
											<p className="form-text">{ p.data.dob ? moment(p.data.dob).format('DD/MM/YYYY') : '-' }</p>
										) : (
											<FormControl
												id="dob" 
												type="date" 
												value={moment(p.data.dob).isValid() ? moment(p.data.dob).format('YYYY-MM-DD') : ''} 
												onChange={this.handleChangeInput} 
											/>
										)
									}
								</FormGroup>
							</Col>
							<Col sm={7} lg={4}>
								<FormGroup>
									<label>Personal E-mail *</label>
									{
										p.readOnly ? (
											<p className="form-text">{ p.data.email }</p>
										) : (
											<FormControl
												id="email" 
												type="email" 
												maxLength="100"
												value={p.data.email || ''} 
												onChange={this.handleChangeInput} 
											/>
										)
									}
								</FormGroup>
							</Col>
							<Col sm={5} lg={3}>
								<FormGroup>
									<label>Nationality *</label>
									{
										p.readOnly || p.isLoggedUser ? (
											<p className="form-text">{ p.data.nationality }</p>
										) : (
											<SelectInputWithData 
												id="nationality" 
												dataType="nationalities" 
												value={p.data.nationality} 
												onChange={this.handleChangeInput} 
											/>
										)
									}
								</FormGroup>
							</Col>
							{
								((!p.editMode || !p.workerCurrentTFN) && !p.readOnly)&& (
									<Col sm={4} lg={3}>
										<FormGroup>
											<label>TFN</label>
											<FormControl
												id="tfn" 
												type="tel" 
												maxLength="100"
												className="tfn"
												value={p.data.tfn || ''} 
												onChange={this.handleChangeInput} 
												onBlur={this.checkTfnExists}
											/>
										</FormGroup>
									</Col>
								)
							}
						</Row>
						{
							!p.readOnly && !p.isLoggedUser && (
								<Row>
									<Col xs={12}>
										<FormGroup>
											<div className="media">
												{
													// Check if the user's browser support the FileReader
													<div className="media-left ">
														{
															p.data.profilePictureBase64 || p.data.photoPath ? (
																<div className="text-center">
																	<img width="100" className="media-object" src={p.data.profilePictureBase64 || s.userPhoto} alt="Worker" />
																	<u className="cursor-pointer text-primary" onClick={this.handleRemovePhoto}>Remove Picture</u>
																</div>
															) : (
																<Icon name="camera" />
															)
														}
													</div>
												}
												{
													!p.isLoggedUser && (
														<div className="media-body">
															<ImageWithCropping 
																id="photo" 
																title="Photo"
																aspectRatio={ 1 / 1 }
																resultSize={{width: 1024, height: 1024}}
																onChange={ this.handleChangePhoto }
															/>
														</div>
													)
												}
											</div>
										</FormGroup>
									</Col>
								</Row>
							)
						}
					</div>
				</div>
				<div className="form-block">
					<h3>Access Details</h3>
					<div className="form-body">
						{
							p.data.azureEmail && (p.readOnly || p.editMode) && (
								<Row>
									<Col xs={12} sm={9} lg={6}>
										<FormGroup>
											<label>Username</label>
											<p className="form-text">
												{ p.data.azureEmail || '-' }
											</p>
										</FormGroup>
									</Col>
								</Row>
							)
						}
						{
							!p.isLoggedUser && (
								<Row>
									<Col xs={12} sm={9} lg={6}>
										<FormGroup>
											<label>Permission Group *</label>
											{
												p.readOnly ? (
													<p className="form-text">
														{ p.data.permissionGroupName ? p.data.permissionGroupName.replace('TMSAccess', '') : '-'}
													</p>
												) : (
													<AzurePermissionGroupsList 
														id="permissionGroupId"
														value={p.data.permissionGroupId} 
														onChange={this.handleChangeInput}
													/>	
												)
											}
										</FormGroup>
									</Col>
								</Row>
							)
						}
					</div>
				</div>
				<div className="form-block">
					<h3>Address</h3>
					<div className="form-body">
						<WorkerAddressHists data={p.data.WorkerAddressHists} 
							isEditable={!p.isLoggedUser}
							isRemovable={!p.isLoggedUser}
							editMode={p.editMode} 
							readOnly={p.readOnly} 
							onChange={data => this.handleOnChange(data, 'WorkerAddressHists')}
						/>
					</div>
				</div>
				<div className="form-block">
					<h3>Contacts</h3>
					<div className="form-body">
						{
							p.readOnly ? (
								p.data.WorkerPhones.length === 0 ? (
									<i>No contact was registered</i>
								) : (
									<Row>
										{
											p.data.WorkerPhones.map((phone, index) => {
												return (
													<Col sm={4} key={index}>
														<FormGroup>
															<label>
																<Icon name={phone.isMobile ? 'mobile' : 'phone'} /> {phone.isMobile ? 'Mobile' : 'Landline'}
															</label>
															<p className="form-text">
																<a href={`tel:${phone.phoneNumber}`}>
																	{ phone.phoneNumber }
																</a>
															</p>
														</FormGroup>
													</Col>
												);
											})
										}
									</Row>
								)
							) : (	
								<MultipleInputs id="WorkerPhones"
									schema={contactsSchema} 
									data={p.data.WorkerPhones} 
									startWithInput={true} 
									onChange={this.handleChangeMultipleInputs}
								/>
							)
						}
					</div>
				</div>
				<div className="form-block">
					<h3>Emergency Contacts</h3>
					<div className="form-body">
						{
							p.readOnly ? (
								p.data.WorkerEmergencyContacts.length === 0 ? (
									<p className="form-text"><i>No emergency contact was registered.</i></p>
								) : (
									p.data.WorkerEmergencyContacts.map((contact, index) => {
										return (
											<Row key={index}>
												<Col sm={3}>
													<FormGroup>
														<label>Name</label>
														<p className="form-text">
															{ contact.name }
														</p>
													</FormGroup>
												</Col>
												<Col sm={3}>
													<FormGroup>
														<label>Relationship</label>
														<p className="form-text">
															{ contact.relationship }
														</p>
													</FormGroup>
												</Col>
												<Col sm={3}>
													<FormGroup>
														<label>Phone 1</label>
														<p className="form-text">
															<a href={`tel:${ contact.phone1 }`}>
																{ contact.phone1 }
															</a>
														</p>
													</FormGroup>
												</Col>
												<Col sm={3}>
													<FormGroup>
														<label>Phone 2</label>
														<p className="form-text">
															<a href={`tel:${ contact.phone2 }`}>
																{ contact.phone2 }
															</a>
														</p>
													</FormGroup>
												</Col>
											</Row>
										);
									})
								)
							) : (	
								<MultipleInputs id="WorkerEmergencyContacts" 
									schema={emergencyContactsSchema} 
									data={p.data.WorkerEmergencyContacts} 
									startWithInput={false} 
									onChange={this.handleChangeMultipleInputs}
								/>
							)
						}
					</div>
				</div>
			</Form>
		);
	}
}

WorkerDetails.propTypes = propTypes;
WorkerDetails.defaultProps = defaultProps;

export default WorkerDetails;