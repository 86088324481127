/* eslint eqeqeq: 0 */
/* eslint radix: 0 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { TrafficLayer } from 'react-google-maps';
import { MAP } from 'react-google-maps/lib/constants';
import TrackingApi from '../../../../app/api/TrackingApi';

import InfoBox from './InfoBox';
import VehicleDetailsBox from './VehicleDetailsBox';
import FilterInput from './FilterInput';
import FilterAndSettings from './FilterAndSettings';
import VehicleMarker from './VehicleMarker';
import { GoogleMapContainer } from '../../../common/googleMaps';
import './style.css';

import { ButtonToolbar } from 'react-bootstrap';
import Icon from 'react-fontawesome';
import { VehicleBreadcrumbsList } from '../../../common/tracking';
import { VehicleTypes } from '../../../../app/models/Vehicle';
import PalletJackStatusTypes from '../../../../app/schemas/vehicles/PalletJackStatusTypes';
import cookies from '../../../../app/helpers/cookies';

class FullMap extends Component {
	constructor() {
		super();
		this.state = {
			lastPingsList: [],
			lastPingsListFiltered: [],

			selectedVehicleFleetNumber: '',
			selectedVehicleLastPingData: null,
			
			height: 0,

			autoFitEnabled: false,
			breadcrumbsEnabled: false,

			// Filters
			stateFilter: '',
			vehTypeFilter: [
				VehicleTypes.PALLET_JACK,
				VehicleTypes.RIGID,
				VehicleTypes.TRAILER,
				VehicleTypes.PRIME_MOVER,
				VehicleTypes.B_DOUBLE,
				VehicleTypes.UTE,
				VehicleTypes.DOLLY
			],
			palletJackStatusFilter: [
				PalletJackStatusTypes.ON_CHARGE,
				PalletJackStatusTypes.LOW_BATTERY,
				PalletJackStatusTypes.BATTERY_OK
			],
			
			// Settings
			filterBy: 'vehicle',
			enableTrafficLayer: true,
			refreshTimeInSec: 60,
			lastPingTimeLimitInMin: 60,

			isResizing: true,
			isLoading: false,
			mapIsLoaded: false,
			showFiltersBox: false,
			showSettingsBox: false
		};
	}

	setMapLoaderInterval = () => {
		this.timerMapLoader = setInterval(() => {
			if (this.map) {
				clearInterval(this.timerMapLoader);

				if (this.unmounted) return;
				this.setState({ mapIsLoaded: true }, this.handleMapLoaded);
			}
		});
	}

	componentWillUnmount() {
		this.clearAllIntervals();
		
		this.unmounted = true;
		
		if (document.addEventListener)
		{
			document.removeEventListener('webkitfullscreenchange', this.handleResize);
			document.removeEventListener('mozfullscreenchange', this.handleResize);
			document.removeEventListener('fullscreenchange', this.handleResize);
			document.removeEventListener('MSFullscreenChange', this.handleResize);
		}
	}

	componentDidMount() {
		this.setMapLoaderInterval();

		this.handleResize();
		if (document.addEventListener)
		{
			document.addEventListener('webkitfullscreenchange', this.handleResize);
			document.addEventListener('mozfullscreenchange', this.handleResize);
			document.addEventListener('fullscreenchange', this.handleResize);
			document.addEventListener('MSFullscreenChange', this.handleResize);
		}

		this.setupAutoCompleteInput();	
	}

	clearAllIntervals = () => {
		clearInterval(this.timerMapLoader);
		clearInterval(this.autoCompleteInterval);
		clearInterval(this.refreshInterval);
	}

	fetchVehiclesLastPing = () => {
		this.setState({ isLoading: true });

		TrackingApi.lastPings(this.state.lastPingTimeLimitInMin)
			.then(lastPingsList => {
				if (this.unmounted) return;
				
				lastPingsList = lastPingsList.filter(p => p.trkPositionLat && p.trkPositionLong);
				
				this.setRefreshInterval();
				this.setState({
					lastPingsList,
					isLoading: false
				}, this.setFilteredList);
			})
			.catch(error => {
				console.error(error);
			});
	}

	setUserFiltersSettings = () => {
		const { 
			stateFilter, vehTypeFilter, palletJackStatusFilter,
			enableTrafficLayer, refreshTimeInSec, lastPingTimeLimitInMin
		} = this.state;

		const settings = {
			stateFilter,
			vehTypeFilter,
			palletJackStatusFilter,
			enableTrafficLayer,
			refreshTimeInSec,
			lastPingTimeLimitInMin
		};

		cookies.set('fullMapSettings', settings);
	}

	setFilteredList = () => {
		const { 
			lastPingsList, vehTypeFilter, palletJackStatusFilter, 
			stateFilter, selectedVehicleFleetNumber
		} = this.state;

		const lastPingsListFiltered = lastPingsList.filter(ping => {
			// Vehicle Type
			if (vehTypeFilter.indexOf(parseInt(ping.vehTypeID)) === -1)
				return false;

			// State
			if (stateFilter && stateFilter.indexOf(ping.vehState) === -1)
				return false;

			// Pallet Jack Status
			if (ping.vehTypeID == VehicleTypes.PALLET_JACK && palletJackStatusFilter.length > 0) {
				// Charging
				if (ping.vehSetHasVehicleBattCharger && ping.trkUnitIsExternalPowerOff) {
					if (palletJackStatusFilter.indexOf(PalletJackStatusTypes.ON_CHARGE) === -1)
						return false;
				}
				
				// Low Battery
				else if (ping.vehLowBatteryEventId) {
					if (palletJackStatusFilter.indexOf(PalletJackStatusTypes.LOW_BATTERY) === -1)
						return false;
				}

				// Battery OK
				else if (palletJackStatusFilter.indexOf(PalletJackStatusTypes.BATTERY_OK) === -1)
					return false;
			}

			return true;
		});

		const selectedVehicleLastPingData = lastPingsListFiltered.find(v => v.vehFleetNumber == selectedVehicleFleetNumber);

		this.setState({ 
			selectedVehicleFleetNumber: selectedVehicleLastPingData ? selectedVehicleFleetNumber : '',
			selectedVehicleLastPingData,
			lastPingsListFiltered 
		}, this.autoFitVehicle);		
	}

	setupAutoCompleteInput = () => {
		this.autoCompleteInterval = window.setInterval(() => {
			if (this.map && !this.unmounted) {
				this.autoComplete = new window.google.maps.places.Autocomplete(this.searchAddressInput);
				this.autoComplete.setComponentRestrictions({ country: 'au' });
				this.autoComplete.addListener('place_changed', this.handleSearchBoxPlacesChanged);
	
				clearInterval(this.autoCompleteInterval);
			}
		}, 500);
	}

	closeVehicleInfoWindow = () => {
		this.handleSelectVehicleFilter('');
	}

	setRefreshInterval = () => {
		this.infoBox.setRefreshInterval(this.state.refreshTimeInSec);
	}

	setHeight = () => {
		const height = window.$(this.wrapper).parent().height() - 1;
		this.setState({ isResizing: false, height });
	}

	autoFitVehicle = () => {
		const { selectedVehicleLastPingData, autoFitEnabled } = this.state;
		if (autoFitEnabled && selectedVehicleLastPingData)
			this.panToSelectedVehicle();
	}

	panToSelectedVehicle = disableZoom => {
		const { selectedVehicleLastPingData } = this.state;
		if (!selectedVehicleLastPingData)
			return;

		const { trkPositionLat, trkPositionLong } = selectedVehicleLastPingData;
		if (!trkPositionLat || !trkPositionLong)
			return console.warn(`Vehicle does not have a valid location: Lat: ${trkPositionLat} - Lng: ${trkPositionLong}`);

		this.map.context[MAP].panTo({
			lat: trkPositionLat, 
			lng: trkPositionLong
		});

		if (!disableZoom)
			this.map.context[MAP].setZoom(20);
	}

	handleClickVehicle = vehFleetNumber => {
		this.handleSelectVehicleFilter({ vehFleetNumber }, true);
	}

	handleSearchBoxPlacesChanged = () => {
		this.closeVehicleInfoWindow();
		
		const place = this.autoComplete.getPlace();
		if (!place || !place.geometry)
			return;
			
		this.map.fitBounds(place.geometry.viewport);
	}

	handleResize = () => {
		this.setState({ height: 0, isResizing: true });
		setTimeout(this.setHeight, 200);
	}

	handleMapLoaded = () => {
		// User Settings
		const userSettings = cookies.get('fullMapSettings');
		
		if (userSettings)
			this.setState({ ...userSettings }, this.fetchVehiclesLastPing);
		else
			this.fetchVehiclesLastPing();

		this.map.context[MAP].controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(this.infoBoxUI);
		this.map.context[MAP].controls[window.google.maps.ControlPosition.TOP_LEFT].push(this.searchBox);
		this.map.context[MAP].controls[window.google.maps.ControlPosition.LEFT_TOP].push(this.infoWindowBox);
		this.map.context[MAP].controls[window.google.maps.ControlPosition.TOP_RIGHT].push(this.settingsBox);
		this.map.context[MAP].controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(this.rightMenu);
		this.setRefreshInterval();
	}

	handleChangeFilter = (type, value) => {
		this.setState({ [type]: value }, () => {
			this.setUserFiltersSettings();
			
			switch(type) {
			case 'refreshTimeInSec':
				return this.setRefreshInterval();
			case 'lastPingTimeLimitInMin':
				return this.fetchVehiclesLastPing();
			default:
				return this.setFilteredList();
			}
		});
	}

	handleChangeFilterType = filterBy => {
		this.setState({ filterBy });
	}

	handleSelectVehicleFilter = ({ vehFleetNumber }, disableZoom) => {
		const selectedVehicleLastPingData = this.state.lastPingsListFiltered.find(l => l.vehFleetNumber === vehFleetNumber);

		if (this.state.selectedVehicleFleetNumber !== vehFleetNumber) {
			this.setState({
				autoFitEnabled: false,
				breadcrumbsEnabled: false,
			});
		}
		if (!selectedVehicleLastPingData)
			return this.setState({ selectedVehicleFleetNumber: null });

		this.setState({
			selectedVehicleFleetNumber: vehFleetNumber,
			selectedVehicleLastPingData,
		}, this.panToSelectedVehicle.bind(this, disableZoom));
	}

	handleClickShowFilters = () => {
		this.setState({
			showFiltersBox: !this.state.showFiltersBox,
			showSettingsBox: false
		});
	}

	handleClickShowSettings = () => {
		this.setState({
			showSettingsBox: !this.state.showSettingsBox,
			showFiltersBox: false
		});
	}

	handleEnableTrafficLayer = () => {
		this.setState({ enableTrafficLayer: !this.state.enableTrafficLayer }, this.setUserFiltersSettings);
	}

	handleEnableAutoFit = () => {
		const autoFitEnabled = !this.state.autoFitEnabled;

		this.setState({ autoFitEnabled });
		if (autoFitEnabled)
			this.panToSelectedVehicle();
	}		

	handleEnableBreadcrumbs = () => {
		this.setState({ breadcrumbsEnabled: !this.state.breadcrumbsEnabled });
	}

	renderVehiclesMarkers = () => {
		const { lastPingsListFiltered } = this.state;

		return lastPingsListFiltered.map((ping, index) => {
			const { trkPositionLat, trkPositionLong } = ping;
			if (!trkPositionLat || !trkPositionLong)
				return null;

			return (
				<VehicleMarker
					key={index}
					position={{
						lat: ping.trkPositionLat,
						lng: ping.trkPositionLong
					}}
					onClick={this.handleClickVehicle.bind(this, ping.vehFleetNumber)}
					{ ...ping }
				/>
			);
		});
	}

	render() {
		const { state } = this;
		
		return (
			<div ref={ref => this.wrapper = ref} className="full-map">
				{	
					!state.isResizing && (
						<GoogleMapContainer
							refs={ref => this.map = ref}
							height={state.height}
							center={state.currentLocation}
							defaultOptions={{
								fullscreenControl: false,
								zoomControl: false,
								gestureHandling: 'greedy',
								streetViewControl: true,
								streetViewControlOptions: {
									index: 2,
									position: window.google.maps.ControlPosition.RIGHT_BOTTOM
								},
								mapTypeControl: true,
								mapTypeControlOptions: {
									index: 1,
									position: window.google.maps.ControlPosition.RIGHT_BOTTOM
								}
							}}
						>
							{ state.enableTrafficLayer && <TrafficLayer autoUpdate /> }
							<div ref={ref => this.infoWindowBox = ref} className="info-window-box">
								{
									state.selectedVehicleFleetNumber && (
										<Fragment>
											<VehicleDetailsBox 
												key={state.selectedVehicleFleetNumber}
												{ ...state.selectedVehicleLastPingData }
												breadcrumbsEnabled={state.breadcrumbsEnabled}
												autoFitEnabled={state.autoFitEnabled}
												onCloseClick={this.closeVehicleInfoWindow}
												onEnableAutoFit={this.handleEnableAutoFit}
												onEnableBreadcrumbs={this.handleEnableBreadcrumbs}
											/>
											{ 
												state.breadcrumbsEnabled && (
													<VehicleBreadcrumbsList
														map={this.map}
														key={state.selectedVehicleLastPingData.id}
														vehFleetNumber={state.selectedVehicleFleetNumber}  
													/>
												)
											}
										</Fragment>
									)
								}
							</div>
							<MarkerClusterer
								maxZoom={18}
								gridSize={50}
							>
								{ this.renderVehiclesMarkers() }
							</MarkerClusterer>
							<InfoBox
								ref={ref => this.infoBox = ref}
								itemRef={ref => this.infoBoxUI = ref} 
								loading={state.isLoading}
								totalVehicles={state.lastPingsListFiltered ? state.lastPingsListFiltered.length : 0}
								onShouldRefresh={this.fetchVehiclesLastPing}
							/>
							<FilterInput 
								itemRef={ref => this.searchBox = ref}
								searchAddressInputRef={ref => this.searchAddressInput = ref}
								selectedVehicleFleetNumber={state.selectedVehicleFleetNumber}
								filterBy={state.filterBy}
								filterFleetNumbers={state.lastPingsListFiltered.map(l => l.vehFleetNumber)}
								onChangeFilterType={this.handleChangeFilterType}
								onSelectVehicleFilter={this.handleSelectVehicleFilter}
							/>
							<div ref={ref => this.settingsBox = ref} className="settings-buttons-box">
								<ButtonToolbar>
									<button 
										title="Filters" 
										className={state.showSettingsBox ? 'fade' : ''}
										onClick={this.handleClickShowFilters}
									>
										<Icon name="filter" />
									</button>
									<button 
										title="Settings"
										className={state.showFiltersBox ? 'fade' : ''}
										onClick={this.handleClickShowSettings}
									>
										<Icon name="gear" />
									</button>
								</ButtonToolbar>
							</div>
							<div ref={ref => this.rightMenu = ref} className="right-menu-box">
								<FilterAndSettings
									showFiltersBox={state.showFiltersBox}
									showSettingsBox={state.showSettingsBox}
									vehTypeFilter={state.vehTypeFilter}
									palletJackStatusFilter={state.palletJackStatusFilter}
									stateFilter={state.stateFilter}
									enableTrafficLayer={state.enableTrafficLayer}
									lastPingTimeLimitInMin={state.lastPingTimeLimitInMin}
									refreshTimeInSec={state.refreshTimeInSec}
									onChangeFilter={(value, type) => this.handleChangeFilter(type, value,  this.setFilteredList)}
									onEnableTrafficLayer={this.handleEnableTrafficLayer}
									onForceRefresh={this.fetchVehiclesLastPing}
								/>
							</div>
						</GoogleMapContainer>
					)
				}
			</div>
		);
	}
}

const mapStateToProps = state => ({ loggedUser: state.user.data });

export default connect(mapStateToProps)(FullMap);