import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import Icon from 'react-fontawesome';

import TrackingApi from '../../../app/api/TrackingApi';
import { Callout, Table, Loader } from '../../common/uiElements';

const propTypes = {
	exceptionsData: PropTypes.array,
	filters: PropTypes.shape({
		startTime: PropTypes.number,
		endTime: PropTypes.number,
	}),
	onSelectException: PropTypes.func
};

class ExceptionsList extends Component {
	constructor() {
		super();
		this.state = {
			isLoading: true,
			exceptionsData: null,
			exceptionsDataError: false
		};
	}

	componentDidMount() {
		const { exceptionsData } = this.props;

		if (exceptionsData)
			return this.setState({ exceptionsData, isLoading: false });
		else
			return this.fetchExceptionsData();
	
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchExceptionsData = () => {
		this.setState({ exceptionsData: null, exceptionsDataError: null });
		const { startTime, endTime } = this.props.filters;

		TrackingApi.exceptionsByTime(startTime, endTime)
			.then((exceptionsData) => {
				if (this.unmounted) { return; }
		
				this.setState({ exceptionsData, isLoading: false });
			})
			.catch(error => {
				console.error(error);
				this.setState({ exceptionsDataError: true, isLoading: false });
			});
	}

	onSelectException = (lat, lng, index) => {
		if (this.props.onSelectException)
			this.props.onSelectException(lat, lng, index);
	}

	render() {
		var { isLoading, exceptionsData, exceptionsDataError } = this.state;

		if (isLoading)
			return <Loader />;

		return (
			<div>
				{
					exceptionsData ? (
						<div id="exceptionsDataTable">
							<Table cssClass="table table-hover">
								<thead>
									<tr>
										<th title="Device ID"><Icon name="microchip" /> Device ID</th>
										<th title="Fleet Number"><Icon name="truck" /> Fleet Number</th>
										<th title="Error"><Icon name="times-circle" /> Error</th>
										<th title="Description"><Icon name="times-circle" /> Description</th>
										<th title="Start Time"><Icon name="clock-o" /> Start</th>
										<th title="End Time"><Icon name="clock-o" /> End</th>
									</tr>
								</thead>
								<tbody>
									{
										exceptionsData.length > 0 && (
											exceptionsData.map((exceptionData, indexData) => {
												var startTime = exceptionData.trkevStartedCollectedOnEpoch;
												var endTime = exceptionData.trkevFinishedCollectedOnEpoch;
												return (
													<tr key={indexData}>
														<td>{exceptionData.deviceId}</td>
														<td>{exceptionData.trkFleetNumber}</td>
														<td className="text-danger">{exceptionData.trkevName}</td>
														<td className="text-danger">{exceptionData.trkevMessage || '-' }</td>
														{
															startTime ? (
																<td className="cursor-pointer" onClick={() => { this.onSelectException(exceptionData.trkevStartedPositionLat, exceptionData.trkevStartedPositionLong, indexData); }}>
																	{moment.unix(startTime).format('DD/MM/YY HH:mm')}
																</td>
															) : (
																<td>-</td>
															)
														}
														{
															endTime ? (
																<td className="cursor-pointer" onClick={() => { this.onSelectException(exceptionData.trkevFinishedPositionLat, exceptionData.trkevFinishedPositionLong, indexData); }}>
																	{moment.unix(endTime).format('DD/MM/YY HH:mm')}
																</td>
															) : (
																<td>-</td>
															)
														}
													</tr>
												);
											})
											
										)
									}
								</tbody>
							</Table>
							<Callout color="info" text="This information is based on the select time above. To see the current information, please go to the Full Map link." />
						</div>
					) : (
						!exceptionsDataError ? (
							<Loader />
						) : (
							<div className="text-danger">It wasn't possible to retrieve the data. Try again or contact the TMS Support.</div>
						)
					)
				}
			</div>
		);
	}
}

ExceptionsList.propTypes = propTypes;

const mapStateToPros = state => ({ filters: state.filters });
const ExceptionsListComponent = connect(mapStateToPros)(ExceptionsList);
export { ExceptionsListComponent as ExceptionsList };