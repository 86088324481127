import React from 'react';
import Icon from 'react-fontawesome';
import changelog from '../../../changelog.json';
import { MainContent } from './';
import { ContentBox } from '../uiElements';

const ChangeLog = () => (
	<MainContent title="Change Log History">
		<p><i>* Some of the features may not be available due to your user permissions and restrictions</i></p>
		{
			changelog && changelog.length > 0 && (
				changelog.map((release, index) => {
					const title = <b>Version { release.version } - { release.date } { index === 0 && '(Latest)' }</b>;
					return (
						<ContentBox color="primary" title={title} key={index} startMinimized={index !== 0} minimize>
							{
								Object.keys(release.features).map((key) => {
									return (
										<div className="text-justify" key={key}>
											<label>{ key }</label>
											<ul className="fa-ul">
												{
													release.features[key].map((feature, index) => {
														return (
															<li style={{ marginBottom: '8px' }} key={index}>
																<Icon name="check-square-o" className="fa-li" /> { feature }
															</li>
														);
													})
												}
											</ul>
											<br />
										</div>
									);
								})
							}
						</ContentBox>
					);
				})
			)
		}
	</MainContent>
);

export { ChangeLog };