import toastr from 'toastr';
import { Notifications, PreStart } from '../../app/schemas/signalr/Hubs';
import StatusTypes from '../../app/schemas/signalr/StatusTypes';
import { AppInsights } from 'applicationinsights-js';

const protocol = process.env.REACT_APP_API_PROTOCOL;
const host = process.env.REACT_APP_API_HOST;
const API_ENV = `${protocol}://${host}`;


const initialState = { 
	status: StatusTypes.DISCONNECTED,
	connection: null,
	hubs: {}
};

// Subscribe on the hubs with empty methods to initialize them
function initializeHubs(hubs) {
	hubs.globalNotificationsHub.on(Notifications.client.DISPLAY_NOTIFICATION, () => {});

	hubs.preStartHub.on(PreStart.client.SUPERVISOR_REFRESH_SCREEN, () => {});
	hubs.preStartHub.on(PreStart.client.SUPERVISOR_REFRESH_BOX_LOCKER_STATUS, () => {});
}

/** 
 * ACTION TYPES
*/
export const SET_CONNECTION = 'tms-web-ui/signalr/SET_CONNECTION';
export const SET_STATUS = 'tms-web-ui/signalr/SET_STATUS';
export const SET_HUBS = 'tms-web-ui/signalr/SET_HUBS';

/**
 * ACTIONS
 */
export function startConnection(isReconnecting) {
	return (dispatch, getState) => {
		if (isReconnecting) {
			dispatch(setStatus(StatusTypes.RECONNECTING));
			AppInsights.trackEvent('SignalR is trying to reconnect');
			console.info('SignalR is reconnecting...');
		}
		else
			dispatch(setStatus(StatusTypes.CONNECTING));

		const { azureId } = getState().user.data;
		const connection = window.$.hubConnection(API_ENV);
		connection.qs = { 
			actionById: azureId
		};
		const hubs = {
			globalNotificationsHub: connection.createHubProxy(Notifications.hubName),
			preStartHub: connection.createHubProxy(PreStart.hubName)
		};

		dispatch(setHubs(hubs));
		dispatch(setConnection(connection));

		initializeHubs(hubs);
		connection.start()
			.done(() => { 
				AppInsights.trackEvent('SignalR connection is established', { connectionId: connection.id });
				console.info('SignalR connection is established');
				dispatch(setStatus(StatusTypes.CONNECTED));
			})

		connection.reconnecting(() => {
			AppInsights.trackEvent('SignalR is reconnecting');
			console.info('SignalR is reconnecting...');
			dispatch(setStatus(StatusTypes.RECONNECTING));
		});

		connection.reconnected(() => {
			AppInsights.trackEvent('SignalR connection was reestablished', { connectionId: connection.id });
			console.info('SignalR connection was reestablished');
			dispatch(setStatus(StatusTypes.CONNECTED));
		});

		connection.error(error => {
			console.error('Unable to connect to SignalR:', error);
			AppInsights.trackException(error, 'SignalR connection error');
			dispatch(setStatus(StatusTypes.FAILED));
		});

		connection.connectionSlow(() => {
			AppInsights.trackEvent('SignalR connection is slow');
			toastr.info('Your connection seems to be slow', 'Slow Connection');
		});
	};
}

export function setConnection(connection) {
	return {
		type: SET_CONNECTION,
		payload: connection
	};
}

export function setStatus(status) {
	return {
		type: SET_STATUS,
		payload: status
	};
}

export function setHubs(hubs) {
	return {
		type: SET_HUBS,
		payload: hubs
	};
}
 
/**
 * REDUCER
 */
export default (state = initialState, { type, payload }) => {
	switch (type) {
	case SET_STATUS: 
		return { ...state, status: payload };
	case SET_CONNECTION:
		return { ...state, connection: payload };
	case SET_HUBS:
		return { ...state, hubs: payload };
	default:
		return state;
	}
};