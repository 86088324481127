/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import GroupBy from 'lodash.groupby';
import { Form } from '../../../../../common/uiElements';
import { Row, Col, FormGroup, FormControl, InputGroup, Button } from 'react-bootstrap';
import httpRequests from '../../../../../../app/api/httpRequests';
import VehiclesApi from '../../../../../../app/api/VehiclesApi';
import Axios from 'axios';
import { RunTypesList } from '../../../../../common/lists';

const propTypes = {
	id: PropTypes.string,
	isCheckingWeight: PropTypes.bool,
	excludeFormElement: PropTypes.bool,
	mainVehFleetNumber: PropTypes.string,
	trailer1VehFleetNumber: PropTypes.string,
	trailer2VehFleetNumber: PropTypes.string,
	palletJackVehFleetNumber: PropTypes.string,
	dollyVehFleetNumber: PropTypes.string,
	data: PropTypes.object,
	processStartedOn: PropTypes.string,
	onChange: PropTypes.func,
	readOnly: PropTypes.bool,
	showTitle: PropTypes.bool,
	isGuideMode: PropTypes.bool,
	addGuideSteps: PropTypes.func,
	isPreStartV2: PropTypes.bool
};

const defaultProps = {
	id: 'MassManagement',
	readOnly: false,
	showTitle: true,
	excludeFormElement: false,
	mainVehFleetNumber: '',
	fleet1MaxPayload: null,
	fleet2MaxPayload: null,
	trailer1VehFleetNumber: '',
	trailer2VehFleetNumber: '',
	palletJackVehFleetNumber: '',
	dollyVehFleetNumber: '',
	data: { PreStartRuns: [] }
};

class MassManagement extends Component {
	constructor(props) {
		super(props);

		const { data, readOnly } = this.props;
		let PreStartRunsGroupBy = [];
		if (readOnly && data && data.PreStartRuns)
			PreStartRunsGroupBy = GroupBy(this.props.data.PreStartRuns, p => p.runOrder); 

		if (!readOnly && (!data || !data.PreStartRuns || data.PreStartRuns.length === 0)) {
			this.handleAddNewRun();
		}

		this.state = {
			PreStartRunsGroupBy
		};
	}

	componentDidMount() {
		const { data, readOnly, mainVehFleetNumber, trailer1VehFleetNumber, trailer2VehFleetNumber } = this.props;

		if (!readOnly && mainVehFleetNumber) {
			const promisesArray = [];

			if (!trailer1VehFleetNumber && mainVehFleetNumber) 
				promisesArray.push(VehiclesApi.getVehicleDetailsOld(mainVehFleetNumber));
			else {
				if (trailer1VehFleetNumber) 
					promisesArray.push(VehiclesApi.getVehicleDetailsOld(trailer1VehFleetNumber));
	
				if (trailer2VehFleetNumber) 
					promisesArray.push(VehiclesApi.getVehicleDetailsOld(trailer2VehFleetNumber));

			}
			
			httpRequests.getMany(promisesArray)
				.then(Axios.spread((fleet1, fleet2) => {
					data.fleet1MaxPayload = fleet1.vehGvm - fleet1.vehTare;
					data.fleet2MaxPayload = fleet2 && fleet2.vehGvm - fleet2.vehTare;
					
					let { id, onChange } = this.props;
					onChange && onChange(data, id);
				}));
		}
	}

	handleChangeRunNumber = (value, index) => {
		let { id, data, onChange } = this.props;
		
		data.PreStartRuns[index].number = value;

		onChange && onChange(data, id);
	}

	handleChangeRunType = (value, index) => {
		let { id, data, onChange } = this.props;
		
		data.PreStartRuns[index].runTypeId = value;

		onChange && onChange(data, id);
	}

	handleChangeWeight = (value, fieldId, index) => {
		if (isNaN(value))
			return;

		let { id, data, onChange } = this.props;
		
		data.PreStartRuns[index][fieldId] = value;

		onChange && onChange(data, id);
	}

	handleAddNewRun = () => {
		let { id, data, processStartedOn, onChange } = this.props;
		if (!data.PreStartRuns)
			data.PreStartRuns = [];
		
		data.PreStartRuns.push({
			number: '',
			runTypeId: '',
			fleet1PayloadInKg: '',
			fleet2PayloadInKg: '',
			processStartedOn
		});
		
		data = { ...data };
		onChange && onChange(data, id);
	}

	handleRemoveRun = index => {
		let { id, data, onChange } = this.props;
		
		data.PreStartRuns.splice(index, 1);

		onChange && onChange(data, id);
	}

	handleChangeInTrailer = e => {
		const fieldId = e.target.id;
		const value = e.target.value;

		let { id, data, onChange } = this.props;
		
		data[fieldId] = value;

		onChange && onChange(data, id);
	}

	handleChangeRadio = e => {
		const fieldId = e.target.id;
		const value = e.target.value;

		let { id, data, onChange } = this.props;
		
		data[fieldId] = value == 'true';

		onChange && onChange(data, id);
	}

	renderPreLoadedData = value => (
		<p style={{ fontSize: '22px', marginTop: '-7px' }}>
			<b>{ value }</b>
		</p>
	)

	renderReadOnly = () => {
		const { PreStartRunsGroupBy } = this.state;
		const runsOrderNumbers = Object.keys(PreStartRunsGroupBy);
		
		if (runsOrderNumbers && runsOrderNumbers.length === 0)
			return (
				<p>
					<i>No runs have been registered.</i>
				</p>
			);

		return runsOrderNumbers.map((runOrder, index) => (
			<Fragment key={runOrder}>
				{ index > 0 && <hr /> }
				{ runsOrderNumbers.length > 1 && <h3>Run {runOrder}</h3>}
				{ this.renderRow(PreStartRunsGroupBy[runOrder]) }
			</Fragment>
		));
	}

	renderRow = runsArray => {
		const { props } = this;
		
		if (runsArray && runsArray.length === 0)
			return (
				<p>
					<i>No runs have been registered.</i>
				</p>
			);
		
		return runsArray.map(({ number, runTypeId, runTypeName, runTypeAcronym, fleet1PayloadInKg, fleet2PayloadInKg }, index) => (
			<Fragment key={index}>
				{ index > 0 && <hr className="visible-xs"/> }
				<Row>
					<Col xs={6} sm={3}>
						<FormGroup>
							<label>Run Type *</label>
							{
								props.readOnly ? (
									<p>{ runTypeName || '-' }</p>
								) : (
									props.isPreStartV2 ? (
										this.renderPreLoadedData(runTypeName)
									) : (
										<RunTypesList 
											id={`runType_${index}`}
											value={runTypeId}
											className="required"
											onChange={e => this.handleChangeRunType(e.target.value, index)}
										/>
									)
								)
							}
						</FormGroup>
					</Col>
					<Col xs={6} sm={3}>
						<FormGroup>
							<label>Run Number *</label>
							{
								props.readOnly ? (
									<p>{  runTypeAcronym + number || '-' }</p>
								) : (
									props.isPreStartV2 ? (
										this.renderPreLoadedData(runTypeAcronym + number)
									) : (
										<FormControl 
											id={`number_${index}`}
											value={number}
											className="required"
											maxLength="10"
											onChange={e => this.handleChangeRunNumber(e.target.value, index)}
										/>
									)
								)
							}
						</FormGroup>
					</Col>
					<Col xs={6} sm={3}>
						<FormGroup>
							<label>{ props.trailer1VehFleetNumber || props.mainVehFleetNumber } Payload *</label>
							{
								props.readOnly ? (
									<p>{ fleet1PayloadInKg ? `${fleet1PayloadInKg } Kg` : '-' }</p>
								) : (
									<InputGroup>
										<FormControl 
											id={`fleet1PayloadInKg_${index}`}
											type="tel"
											className="required"
											value={fleet1PayloadInKg || ''}
											placeholder="Payload"
											maxLength="5"
											onChange={e => this.handleChangeWeight(e.target.value, 'fleet1PayloadInKg', index)}
										/>
										<InputGroup.Addon>KG</InputGroup.Addon>
									</InputGroup>
								)
							}
						</FormGroup>
					</Col>
					{
						props.trailer2VehFleetNumber && (
							<Col xs={6} sm={3}>
								<FormGroup>
									<label>{ props.trailer2VehFleetNumber } Payload *</label>
									{
										props.readOnly ? (
											<p>{ fleet2PayloadInKg ? `${fleet2PayloadInKg } Kg` : '-' }</p>
										) : (
											<InputGroup>
												<FormControl 
													id={`fleet2PayloadInKg_${index}`}
													type="tel"
													className="required"
													value={fleet2PayloadInKg || ''}
													placeholder="Payload"
													maxLength="5"
													onChange={e => this.handleChangeWeight(e.target.value, 'fleet2PayloadInKg', index)}
												/>
												<InputGroup.Addon>KG</InputGroup.Addon>
											</InputGroup>
										)
									}
								</FormGroup>
							</Col>
						)
					}
				</Row>
			</Fragment>
		));
	}

	renderContent = () => {
		const { props } = this;

		return (
			<Fragment>
				{ 
					props.showTitle && (
						<h3 className="title">
							<Icon name="balance-scale" /> Mass Management
						</h3> 
					)
				}
				{ props.readOnly ? this.renderReadOnly() : this.renderRow(props.data.PreStartRuns) }
				{
					!props.readOnly && !props.isPreStartV2 && (
						<FormGroup>
							<Button
								bsStyle="primary"
								onClick={this.handleAddNewRun}
							>
								<Icon name="plus"/> Add Run
							</Button>
						</FormGroup>
					)
				}
			</Fragment>
		)
	}

	render() {
		const p = this.props;

		if (p.excludeFormElement)
			return this.renderContent(); 

		return (
			<Form>
				{ this.renderContent() }
			</Form>
		);
	}
}

MassManagement.propTypes = propTypes;
MassManagement.defaultProps = defaultProps;

export default MassManagement;