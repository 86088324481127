import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Icon from 'react-fontawesome';

import { Button } from 'react-bootstrap';
import { MainContent } from '../../../../common/layout';
import './style.css';

// APIs
import PreStartApi from '../../../../../app/api/PreStartApi';

// Components
import PreStartList from './PreStartList';
import PreStartListFilter from './PreStartListFilter';
import PreStartDetailsModal from '../subComponents/PreStartDetailsModal';

// UI Elements
import { ContentBox, ErrorBox } from '../../../../common/uiElements';
import MeApi from '../../../../../app/api/MeApi';

class PreStartListContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedPreStartId: null,
			preStartsList: [],
			totalPages: 0,
			totalRecords: 0,
			page: 0,
			pageSize: 10,
			sorted: [],
			filter: {
				startTimeLocal: moment().add(-30, 'days').hour(0).minute(0).second(0).format('YYYY-MM-DD HH:mm'),
				endTimeLocal: moment().hour(23).minute(59).second(59).format('YYYY-MM-DD HH:mm'),
				driverName: '',
				vehicle: '',
				depot: '',
				status: '',
				hasFaultReport: false,
				hasVehicleDamage: false,
				hasMechanicalIssues: false,
				hasComplianceIssue: false,
			},
			isLoading: false,
			error: null
		};

		this.handleSearch = () => {
			this.setState({
				page: 0,
				sorted: []
			}, this.fetchData);
		};

		this.handleChangeFilter = (filter) => {
			this.setState({
				filter: {
					...this.state.filter,
					...filter
				}
			});
		};

		this.handleChangePage = page => {
			this.setState({ page }, this.fetchData);
		};

		this.handleChangePageSize = pageSize => {
			this.setState({ pageSize, page: 0 }, this.fetchData);
		};

		this.handleChangeSorted = sorted => {
			this.setState({ sorted }, this.fetchData);
		};

		this.handleViewDetails = selectedPreStartId => {
			this.setState({ selectedPreStartId });
		};

		this.handleCloseDetailsModal = () => {
			this.setState({ selectedPreStartId: null });
		};
	}   

	componentWillUnmount() {
		this.unmounted = true;
	}

	UNSAFE_componentWillMount() {
		this.fetchData();   
	}    

	fetchData = () => {
		this.setState({ isLoading: true, error: null });
		const { filter, page, pageSize, sorted } = this.state;
		const filterParam = { ...filter, page: page + 1, pageSize, sorted };

		let promise = PreStartApi.getPreStartsList.bind(this, filterParam);
		if (this.props.isLoggedUser)
			promise = MeApi.getPreStartsList.bind(this, filterParam);
		
		promise()
			.then(data => {
				if (this.unmounted) return;
			
				const { totalRecords, totalPages, records } = data;

				return this.setState({ 
					isLoading: false, 
					preStartsList: records,
					totalPages,
					totalRecords
				});
			})
			.catch(error => {
				return this.setState({ isLoading: false, error });
			});
	}
	
	render() {
		const s = this.state;
		const p = this.props;
		
		return (
			<MainContent title="Pre-Start - Submissions">
				<div>
					<ContentBox color="primary" title="Filters">
						<PreStartListFilter 
							hideDriverName={p.isLoggedUser}
							{...s.filter} 
							onChange={this.handleChangeFilter} 
						/>
						<Button 
							bsStyle="primary" 
							disabled={s.isLoading}
							block onClick={this.handleSearch}
						>
									Search <Icon name="search" />
						</Button>
					</ContentBox>
					{
						s.error ? (
							<ErrorBox error={s.error} retryFunc={this.fetchData} />
						) : (
							<PreStartList 
								data={s.preStartsList}
								page={s.page}
								pageSize={s.pageSize}
								totalPages={s.totalPages}
								sorted={s.sorted}
								isLoading={s.isLoading}
								onPageChange={this.handleChangePage}
								onPageSizeChange={this.handleChangePageSize}
								onSortedChange={this.handleChangeSorted}
								onViewDetails={this.handleViewDetails}
							/>
						)
					}
				</div>
				<PreStartDetailsModal 
					show={s.selectedPreStartId ? true : false} 
					preStartId={s.selectedPreStartId} 
					onClose={this.handleCloseDetailsModal}/>
			</MainContent>
		);
	}
}

PreStartListContainer.propTypes = {
	isLoggedUser: PropTypes.bool
};

const mapStateToProps = (state) => {
	return { loggedUserId: state.user.data.azureId };
};

export default connect(mapStateToProps)(PreStartListContainer);