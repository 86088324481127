import { IResponsePOST, IResponsePUT, IResponseDELETE, ListReturn } from './../models/Application';
import { SmsMessageBulkListFilter, ISmsMessageBulkListItem, ISmsMessageBulkDetails, SmsMessageBulkPOST_PUT } from './../models/Communication/SmsMessageBulkModel';
import communicationEndpoints from './endpoints/communicationEndpoints';
import httpRequests from './httpRequests';
import { ListRequestOptions } from '../models/Application';

export default {

	/**
	 * Gets a list of SmsMessageBulk items based on the filters and options provided
	 * @param filters filters for the list request
	 * @param options options for pagination, sorting, etc.
	 */
	getSmsMessagesBulkList(filters: SmsMessageBulkListFilter, options: ListRequestOptions):Promise<ListReturn<ISmsMessageBulkListItem>> {
		const endpoint = communicationEndpoints.sms.bulkMessageRoot;
		return httpRequests.get(endpoint, { ...filters, ...options});
	},

	/**
	 * Gets a single SmsMessageBulk item based on the ID provided
	 * @param id ID of the SmsMessageBulk item to get details for
	 */
	getSmsMessagesBulkDetails(id: number):Promise<ISmsMessageBulkDetails> {
		const endpoint = communicationEndpoints.sms.bulkMessageById(id);
		return httpRequests.get(endpoint);
	},

	/**
	 * Adds a new SmsMessageBulk item
	 * @param data data for the new SmsMessageBulk item
	 */
	addSmsMessagesBulk(data: SmsMessageBulkPOST_PUT): Promise<IResponsePOST> {
		const endpoint = communicationEndpoints.sms.bulkMessageRoot;
		return httpRequests.post(endpoint, data) as Promise<IResponsePOST>;
	},

	/**
	 * Updates an existing SmsMessageBulk item
	 * @param id ID of the SmsMessageBulk item to update
	 * @param data data to update the SmsMessageBulk item with
	 */
	updateSmsMessagesBulk(id: number, data: SmsMessageBulkPOST_PUT):Promise<IResponsePUT> {
		const endpoint = communicationEndpoints.sms.bulkMessageById(id);
		return httpRequests.put(endpoint, data);
	},

	/**
	 * Soft deletes an existing SmsMessageBulk item (sets isDeleted to true)
	 * @param id ID of the SmsMessageBulk item to delete
	 */
	deleteSmsMessagesBulk(id: number):Promise<IResponseDELETE> {
		const endpoint = communicationEndpoints.sms.bulkMessageById(id);
		return httpRequests.delete(endpoint);
	}
}