import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { Radio } from '.';
import { FormGroup } from 'react-bootstrap';

interface IProps {
	id?: string;
	displayVertical?: boolean;
	items: {
		text: string;
		value: any;
	}[];
	value: any;
	textBefore?: string;
	doNotRenderInsideFormGroup?: boolean;
	onChange: (e: React.ChangeEvent<HTMLInputElement>, value?: any) => void;
}


const RadioButtonList: React.FC<IProps> = props => {
	const componentId = useMemo(() => uuid(), []);
		
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, value: any) => {
		const component = document.getElementById(componentId);
		if (!component || !props.onChange)
			return;

		component.getElementsByClassName('error')[0]?.remove();
		component.getElementsByTagName('label')[0]?.classList.remove('invalid');

		if (value === 'true') value = true;
		else if (value === 'false') value = false;

		props.onChange(e, value);
	};

	const renderContent = () => {
		return (
			<div className={'radio-list' + (props.displayVertical ? ' radio-list-vertical' : '')}>
				{ props.textBefore && <b>{ props.textBefore }&nbsp;&nbsp;</b> }
				{
					props.items.map((item, index) => {
						const { text, value } = item;
						return (
							<Radio 
								id={props.id || 'radio_list_' + componentId} 
								key={index} 
								checked={props.value !== null && props.value !== undefined && value?.toString() === props.value?.toString() } 
								text={text} 
								value={value} 
								onChange={handleChange} />
						);
					})
				}
			</div>
		);
	};

	if (props.doNotRenderInsideFormGroup)
		return (
			<div id={componentId}>
				{ renderContent() }
			</div>
		);

	return (
		<FormGroup id={componentId}>
			{ renderContent() }
		</FormGroup>
	);
};

export { RadioButtonList };