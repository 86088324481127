import moment from 'moment';
import React, { ChangeEvent, useState } from 'react';
import { Badge, Button, ButtonGroup, Col, FormControl, FormGroup, HelpBlock, Row } from 'react-bootstrap';
import { Callout, FieldGroup, Form } from '../../../../common/uiElements';
import { SmsMessageBulkFormClass } from '../../../../../app/models/Communication/SmsMessageBulkModel';
import FontAwesome from 'react-fontawesome';
import WorkersListForSelection from '../workersListSelection/WorkersListForSelection';

interface IProps extends SmsMessageBulkFormClass {
	isReadOnly?: boolean;
	onChange: (id: string, value?: string) => void;
	onClickAddOrEditRecipients?: () => void;
}

const validations = {
	sendNow: 'required',
	scheduledDate: 'required',
	scheduledTime: 'required',
	subject: 'required',
	message: 'required',
	workerIds: 'required',
	scheduledToSendAt: {
		greaterOrEqualThan: moment().format('YYYY-MM-DDTHH:mm')
	},
}

const validationMessages = {
	workerIds: {
		required: 'At least one recipient is required'
	},
	scheduledToSendAt: {
		greaterOrEqualThan: 'Scheduled value must be in the future'
	}
}

// AWS SNS Pricing: https://aws.amazon.com/sns/sms-pricing/
// Per Message 0.0345 USD (last updated 11/12/2023)
// USD to AUD exchange rate: 1.5 (last updated 11/12/2023)
const awsCostPerMessageInAUD = 0.05;

const SmsDetailsForm: React.FC<IProps> = props => {
	const [scheduledDate, setScheduledDate] = useState(props.scheduledToSendAt ? moment.utc(props.scheduledToSendAt).local().format('YYYY-MM-DD') : '');
	const [scheduledTime, setScheduledTime] = useState(props.scheduledToSendAt ? moment.utc(props.scheduledToSendAt).local().format('HH:mm') : '');
	const [sendNow, setSendNow] = useState<boolean | undefined>(props.id ? props.scheduledToSendAt ? false : true : undefined);

	const handleChangeInput = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
		const { id, value } = e.target;
		props.onChange && props.onChange(id, value);
	} 

	const handleChangeInputDate = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
		const { value } = e.target;
		const dateTimeValue = moment(`${value} ${scheduledTime}`);

		if(dateTimeValue.isValid())
			props.onChange('scheduledToSendAt', dateTimeValue.format('YYYY-MM-DDTHH:mm'));
		else
			props.onChange('scheduledToSendAt', undefined);

		setScheduledDate(value);
	}

	const handleChangeInputTime = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
		const { value } = e.target;
		const dateTimeValue = moment(`${scheduledDate} ${value}`);

		if(dateTimeValue.isValid())
			props.onChange('scheduledToSendAt', dateTimeValue.format('YYYY-MM-DDTHH:mm'));
		else
			props.onChange('scheduledToSendAt', undefined);

		setScheduledTime(value);
	}

	const handleChangeSendNow = (sendNow: boolean) => {
		props.onChange('scheduledToSendAt', '');
		setSendNow(sendNow);
	}

	return (	
		<Form 
			id="sms-bulk-form" 
			validations={validations}
			messages={validationMessages}
		>
			{
				props.isReadOnly && (
					<>
						<Row>
							<FieldGroup label="Created By" sm={3} xs={6}>
								<p>{props.createdByName}</p>
							</FieldGroup>
							<FieldGroup label="Created At" sm={3} xs={6}>
								<p>{moment.utc(props.createdOn).local().format('DD/MM/YYYY HH:mm')}</p>
							</FieldGroup>
							<FieldGroup label="Scheduled Time" sm={3} xs={6}>
								<p>
									{ props.scheduledToSendAt ? moment.utc(props.scheduledToSendAt).local().format('DD/MM/YYYY HH:mm') : '-'}
								</p>
							</FieldGroup>
							<FieldGroup label="Sent At" sm={3} xs={6}>
								<p>{props.sentAt ? moment.utc(props.sentAt).local().format('DD/MM/YYYY HH:mm') : '-'}</p>
							</FieldGroup>
						</Row>
						<hr />
					</>
				)
			}
			<Row>
				<Col sm={6}>
					<FieldGroup label="Subject" required noCol>
						{
							props.isReadOnly ? (
								<p>{props.subject}</p>
							) : (
								<>
									<FormControl 
										id="subject" 
										maxLength={50} 
										value={props.subject}
										placeholder="Subject of the message..."
										onChange={handleChangeInput}
									/>
									<HelpBlock><i>The recipients won't see the subject anywhere in the message</i></HelpBlock>
								</>
							)
						}
					</FieldGroup>
					<FieldGroup label="Message" required noCol>
						{
							props.isReadOnly ? (
								<p>{props.message}</p>
							) : (
								<>
									<FormControl
										id="message" 
										componentClass="textarea" 
										placeholder="Type your message here..."
										value={props.message} 
										style={{ resize: 'none' }}
										rows={6} 
										maxLength={300} 
										onChange={handleChangeInput}
									/>
									<p style={{ fontSize: '14px' }} className={`pull-right ${props.message.length > 160 ? 'text-danger' : 'text-success'}`}>
										<b>{props.message.length} / 300</b>
									</p>
									{
										props.message.length > 160 && (
											<>
												<br /><br />
												<Callout icon="warning" title="Long Message Detected" color="warning">
													The message seems to be quite long. If possible, please try reducing the message length to less than 160 characters to reduce the cost.
												</Callout>
											</>
										)
									}
								</>
							)
						}
					</FieldGroup>
					<FieldGroup label="Estimated Cost" noCol>
						<p>
							<Badge style={{ fontSize: '20px' }} className="primary">
								{(Math.ceil(props.message.length / 160) * props.Workers.length * awsCostPerMessageInAUD).toFixed(2)} AUD
							</Badge>
							<HelpBlock>
								<i>Ceiling (Message Length / 160) * Total Recipients * {awsCostPerMessageInAUD.toFixed(2)} </i>
							</HelpBlock>
						</p>
					</FieldGroup>
					{
						!props.isReadOnly && (
							<>
								<FieldGroup label="When to send?" required noCol>
									<FormGroup>
										<ButtonGroup>
											<Button bsStyle={sendNow == true ? 'primary': 'default'} onClick={() => handleChangeSendNow(true)}>
												<FontAwesome name="paper-plane" /> Send Now
											</Button>
											<Button bsStyle={sendNow == false ? 'primary': 'default'} onClick={() => handleChangeSendNow(false)}>
												<FontAwesome name="clock-o" /> Schedule
											</Button>
										</ButtonGroup>
									</FormGroup>
									<FormControl 
										id="sendNow"
										type="hidden"
										value={sendNow !== undefined ? 'OK' : ''}
									/>
								</FieldGroup>
								{
									sendNow === false && (
										<>
											<Row>
												<FieldGroup label="Scheduled Time" required sm={6} md={4}>
													<FormControl
														id="scheduledDate"
														type="date"
														value={scheduledDate}
														onChange={handleChangeInputDate}
													/>
												</FieldGroup>
												<FieldGroup label="Scheduled Time" required sm={6} md={4}>
													<FormControl
														id="scheduledTime"
														type="time"
														value={scheduledTime}
														onChange={handleChangeInputTime}
													/>
												</FieldGroup>
											</Row>
											{
												scheduledDate && scheduledTime && (
													<FormControl 
														id="scheduledToSendAt"
														type="hidden"
														value={props.scheduledToSendAt || ''}
													/>
												)
											}
										</>
									)
								}
							</>
						)
					}
				</Col>
				{
					!props.isReadOnly && (
						<Col sm={6}>
							<FieldGroup label="Recipients" noCol>
								{
									props.Workers.length > 0 && (
										<>
											<div style={{ maxHeight: '500px', overflow:'auto' }}>
												<WorkersListForSelection 
													hideCheckBoxes
													hideFilters
													hideCurrentRole
													workersList={props.Workers}
												/>
											</div>
											<h4 className="pull-right">
												<b>Total Selected: {props.Workers.length}</b>
											</h4>
										</>
									)
								}
								{
									!props.isReadOnly && (
										<>
											<br />
											<Button bsStyle="primary" onClick={props.onClickAddOrEditRecipients}>
												{
													props.Workers.length === 0 ? (
														<>
															<FontAwesome name="plus" /> Add Recipients
														</>
													) : (
														<>
															<FontAwesome name="edit" /> Edit Recipients
														</>
													)
												}
											</Button>
										</>
									)
								}
							</FieldGroup>
							<FormControl 
								id="workerIds"
								type="hidden"
								value={props.Workers.length > 0 ? 'OK' : ''}
							/>
						</Col>
					)
				}
			</Row>
		</Form>
	)
}

export default SmsDetailsForm;