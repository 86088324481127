/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import confirm from '../../../../../../app/helpers/confirm';
import { Row, Col, FormGroup, Modal, InputGroup, FormControl, Button } from 'react-bootstrap';

import FaultReportModalAddNew from '../../../faultReport/subComponents/FaultReportModalAddNew';

import { RadioButtonList } from '../../../../../common/inputs';
import { Form } from '../../../../../common/uiElements';
import { VehicleTypes } from '../../../../../../app/models/Vehicle';

const propTypes = {
	id: PropTypes.string,
	data: PropTypes.object,
	faultReportItems: PropTypes.array,
	mainVehTypeID: PropTypes.number,
	isThereTrailer: PropTypes.bool,
	readOnly: PropTypes.bool,
	onChange: PropTypes.func,
	addGuideSteps: PropTypes.func,
	onSaveFaultReport: PropTypes.func,
};

const defaultProps = {
	id: 'VehicleMechanicalCheck',
	readOnly: false,
	data: {},
	faultReportItems: []
};

const radioItemsYesNo = [{ text: 'Yes', value: true }, { text: 'No', value: false }];
const radioItemsNoYes = [{ text: 'No', value: true }, { text: 'Yes', value: false }];
const radioItemsAdded = [{ text: 'OK', value: true }, { text: 'Added', value: false }];
const radioItemsBare = [{ text: 'OK', value: true }, { text: 'Bare', value: false }];
const radioItemsDamage = [{ text: 'OK', value: true }, { text: 'Damaged', value: false }];
const radioItemsDrained = [{ text: 'Drained', value: true }, { text: 'Not Drained', value: false }];
const radioItemsNeedsAdjust = [{ text: 'OK', value: true }, { text: 'Needs Adjust', value: false }];
const radioItemsOperation = [{ text: 'Operating', value: true }, { text: 'Not Operating', value: false }];

const oilWaterQuestions = [
	{ text: 'Truck Oil', id: 'isTruckOilOk', defaultValue: '', isRequired: true, options: radioItemsAdded, addedLitersFieldId: 'addedTruckOilLiters' },
	{ text: 'Truck Water', id: 'isTruckWaterOk', defaultValue: '', isRequired: true, options: radioItemsAdded, addedLitersFieldId: 'addedTruckWaterLiters' },
	{ text: 'Fridge Oil', id: 'isFridgeOilOk', defaultValue: '', isRequired: true, options: radioItemsAdded, addedLitersFieldId: 'addedFridgeOilLiters', hideWhenJustPrimeMover: true },
];

const lightQuestions = [
	{ text: 'Headlights', id: 'isHeadlightsOk', defaultValue: '', isRequired: true, options: radioItemsDamage },
	{ text: 'Blinkers', id: 'isBlinkersOk', defaultValue: '', isRequired: true, options: radioItemsDamage },
	{ text: 'Brake Lights', id: 'isBrakeLightsOk', defaultValue: '', isRequired: true, options: radioItemsDamage },
	{ text: 'Clearance Lights', id: 'isClearanceLightsOk', defaultValue: '', isRequired: true, options: radioItemsDamage },
	{ text: 'PanLights', id: 'isPanLightsOk', defaultValue: '', isRequired: true, options: radioItemsDamage, hideWhenJustPrimeMover: true },
	{ text: 'TailLights', id: 'isTailLightsOk', defaultValue: '', isRequired: true, options: radioItemsDamage }
];

const tyreQuestions = [
	{ text: 'Are tyres inflated?', id: 'areTyresInflated', defaultValue: '', isRequired: true, options: radioItemsYesNo },
	{ text: 'Tyre Treads', id: 'isTyreTreadsOk', defaultValue: '', isRequired: true, options: radioItemsBare },
	{ text: 'Are all wheel nuts in place?', id: 'areWheelNutsOk', defaultValue: '', isRequired: true, options: radioItemsYesNo },
	{ text: 'Is there a Spare Tyre?', id: 'isThereSpareTyre', defaultValue: '', isRequired: true, options: radioItemsYesNo },
	{ text: 'Spare Tyre Conditions', id: 'isSpareTyreOk', defaultValue: '', isRequired: true, options: radioItemsBare }
];

const seatbeltQuestions = [
	{ text: 'Is the Seatbelt clip working?', id: 'isSeatbeltClipOk', defaultValue: '', isRequired: true, options: radioItemsYesNo },
	{ text: 'Is the Seatbelt retract working?', id: 'isSeatbeltRetractOk', defaultValue: '', isRequired: true, options: radioItemsYesNo },
	{ text: 'Is there any visual damage to the Seatbelt?', id: 'isSeatbeltVisualDamageOk', defaultValue: '', isRequired: true, options: radioItemsNoYes }
];

const otherQuestions = [
	{ text: 'Air Tank', id: 'isAirTankOk', defaultValue: '', isRequired: true, options: radioItemsDrained },
	{ text: 'Brake Pressure', id: 'isBrakePressureOk', defaultValue: '', isRequired: true, options: radioItemsNeedsAdjust, className: 'pre-start__brake-pressure' },
	{ text: 'Truck Fridge', id: 'isTruckFridgeOperating', defaultValue: '', isRequired: true, options: radioItemsOperation, hideWhenJustPrimeMover: true },
	{ text: 'Wipers', id: 'isWipersOperating', defaultValue: '', isRequired: true, options: radioItemsOperation },
	{ text: 'Mirrors', id: 'isMirrorsOk', defaultValue: '', isRequired: true, options: radioItemsDamage },
	{ text: 'Windows', id: 'isWindowsOk', defaultValue: '', isRequired: true, options: radioItemsDamage },
	{ text: 'Is there any dashboard alarm present?', id: 'isDashboardAlarmOk', defaultValue: '', isRequired: true, options: radioItemsNoYes },
	{ text: 'Can you hear the audible reverse alarms when the truck is placed into the reverse gear?', id: 'isReverseAlarmOk', defaultValue: '', isRequired: true, options: radioItemsYesNo },
	{ text: 'Is there a Reverse Camera fitted to the vehicle?', id: 'isThereReverseCamera', defaultValue: '', isRequired: true, options: radioItemsYesNo },
	{ text: 'Is reverse camera working?', id: 'isReverseCameraOk', defaultValue: '', isRequired: true, options: radioItemsYesNo },
	{ text: 'Is the truck fridge working?', id: 'isFridgeOk', defaultValue: '', isRequired: true, options: radioItemsYesNo, hideWhenJustPrimeMover: true },
	{ text: 'Is the fridge set at the right temperature for this load?', id: 'isFridgeTempCorrect', defaultValue: '', isRequired: true, options: radioItemsYesNo, hideWhenJustPrimeMover: true }
];

const allQuestions = [
	{ 
		title: 'Oil and Water',
		icon: 'tint',
		questions: oilWaterQuestions,
		className: 'pre-start__oil-water-questions'
	},
	{ 
		title: 'Light',
		icon: 'lightbulb-o',
		questions: lightQuestions,
		className: 'pre-start__light-questions'
	},
	{ 
		title: 'Tyres',
		icon: 'circle-o-notch',
		questions: tyreQuestions,
		className: 'pre-start__tyre-questions'
	},
	{
		title: 'Seatbelt',
		icon: 'unlink',
		questions: seatbeltQuestions,
		className: 'pre-start__seatbelt-questions'
	},
	{ 
		title: 'Others',
		icon: 'wrench',
		questions: otherQuestions,
		className: 'pre-start__other-questions'
	},
];

const guideSteps = [
	{
		title: 'Step 4 - Mechanical Questions',
		text: 'Reporting mechanical issues is critical for maintaining roadworthiness of our vehicles',
		selector: '.pre-start__mechanical-questions',
		position: 'top'
	},
	{
		title: 'Reporting Problems',
		text: 'If you identify a problem with the vehicle, you must report it',
		selector: '.pre-start__brake-pressure',
		position: 'top',
		showFaultReportModal: true
	}
];

class VehicleMechanicalCheck extends Component {
	constructor() {
		super();

		this.state = {
			showModalFaultReport: false,
			addedLitersFieldId: null
		};

		this.handleCloseModalFaultReport = () => {
			this.setState({ showModalFaultReport: false });
		};

		this.handleChangeInput = (e, value) => {
			var fieldId = e.target.id;
			value = e.target.type === 'checkbox' ? e.target.checked : (typeof value !== 'undefined' ? value : e.target.value);

			var { data, id } = this.props;
			
			if ((typeof data[fieldId] === 'undefined' || data[fieldId] === true) && value === false 
			&& fieldId !== 'isThereSpareTyre' && fieldId !== 'isThereReverseCamera') {
				if (fieldId === 'isTruckOilOk' || fieldId === 'isTruckWaterOk'
				|| fieldId === 'isFridgeOilOk') {
					const { addedLitersFieldId } = oilWaterQuestions.find(p => p.id === fieldId);
					this.setState({ addedLitersFieldId });
				} else {
					confirm('Do you want to add a new Fault Report for this event?', () => this.setState({ showModalFaultReport: true }));    
				}
			} else if (fieldId === 'isTruckOilOk' || fieldId === 'isTruckWaterOk'
			|| fieldId === 'isFridgeOilOk') {
				const { addedLitersFieldId } = oilWaterQuestions.find(p => p.id === fieldId);
				data[addedLitersFieldId] = undefined;
			}

			if (fieldId === 'isThereSpareTyre')
				data.isSpareTyreOk = '';

			if (fieldId === 'isThereReverseCamera')
				data.isReverseCameraOk = '';

			data[fieldId] = value;
							
			if (this.props.onChange)
				this.props.onChange(data, id);
		};

		this._handleSubmitAddedLiters = e => {
			e.preventDefault();
			const { addedLitersFieldId } = this.state;
			const value = document.getElementById(addedLitersFieldId).value;
			
			let { data } = this.props;
			data[addedLitersFieldId] = value;
							
			if (this.props.onChange)
				this.props.onChange(data, this.props.id);

			this.setState({ addedLitersFieldId: null }, () => {
				confirm('Do you want to add a new Fault Report for this event?', () => this.setState({ showModalFaultReport: true }));    
			});
		};

		this._handleCloseAddedLitersModal = () => {
			const { addedLitersFieldId } = this.state;
			const field = oilWaterQuestions.find(p => p.addedLitersFieldId === addedLitersFieldId);

			let { data } = this.props;
			if (field && !data[addedLitersFieldId])
				delete data[field.id];
							
			if (this.props.onChange)
				this.props.onChange(data, this.props.id);

			this.setState({ addedLitersFieldId: null });
		};

		this.handleSaveFaultReport = (faultReport) => {
			let { faultReportItems } = this.props;
			faultReportItems.push(faultReport);
			this.handleCloseModalFaultReport();
			if (this.props.onSaveFaultReport)
				return this.props.onSaveFaultReport(faultReportItems, 'FaultReports');
		};
	}

	componentDidMount() {
		this.props.addGuideSteps && this.props.addGuideSteps(guideSteps);
	}

	renderValidations = () => {
		var validations = {};
		var questions = [].concat(oilWaterQuestions, lightQuestions, tyreQuestions, otherQuestions, seatbeltQuestions,);
		questions.forEach(({ id }) => validations[id] = 'required');

		return validations;
	}

	render() {		
		const s = this.state;
		const p = this.props;

		return (
			<div className="pre-start__mechanical-questions">
				<Form validations={this.renderValidations()}>
					{
						allQuestions.map((question, index) => {
							let { title, icon, questions, className } = question;
							return (
								<div className={className} key={index}>
									<h3 className="title">
										<Icon name={icon}/> {title}
									</h3>
									<Row className="aligned-row">
										{
											questions.map(({ text, id, options, className, hideWhenJustPrimeMover, addedLitersFieldId }, i) => {
												if (id === 'isSpareTyreOk' && p.data.isThereSpareTyre !== true)
													return null;

												if (id === 'isReverseCameraOk' && p.data.isThereReverseCamera !== true)
													return null;
												
												// If it's just the prime mover
												if (hideWhenJustPrimeMover && (!p.isThereTrailer && p.mainVehTypeID === VehicleTypes.PRIME_MOVER))
													return null;

												const readOnlyObject = options.find(o => o.value === p.data[id]);
												
												if (p.readOnly && !readOnlyObject)
													return null;

												return (
													<Fragment key={i}>
														<Col xs={6} sm={3} md={3} lg={3} className={!p.readOnly && id === 'isFridgeTempCorrect' ? 'bg-light-amber' : ''}>
															<FormGroup className={className || ''}>
																<div className={!p.readOnly ? '' : !readOnlyObject ? 'text-muted' : (id === 'isThereSpareTyre' || id === 'isThereReverseCamera' || p.data[id] ? 'text-success' : 'text-danger')}>
																	<label>{text}</label>
																	{
																		p.readOnly ? (
																			<p>
																				{ readOnlyObject.text || '-' }
																				{ 
																					addedLitersFieldId && p.data[addedLitersFieldId] ? (
																						` - ${p.data[addedLitersFieldId]} litre(s)`
																					) : ''
																				}
																			</p>
																		) : (
																			<div>
																				<RadioButtonList 
																					id={id} 
																					items={options} 
																					value={p.data[id]}
																					displayVertical={true} 
																					onChange={this.handleChangeInput}
																				/>
																				{
																					addedLitersFieldId && p.data[addedLitersFieldId] && (
																						<a onClick={() => this.setState({ addedLitersFieldId })}>
																							<u>{ p.data[addedLitersFieldId] } litre(s)</u>
																						</a>
																					)
																				}
																			</div>
																		)
																	}
																</div>
															</FormGroup>
														</Col>
														{
															!p.readOnly && id === 'isFridgeTempCorrect' ? (
																<Col xs={12} sm={3} md={3} lg={3} className="bg-light-amber">
																	<ul className="list-unstyled">
																		<li><b>Mixed Load</b></li>
																		<ul>
																			<li>Zone 1: -20°C</li>
																			<li>Zone 2: +2°C</li>
																			<li>Zone 3: -20°C (Freezer) or +2°C (Chiller)</li>
																		</ul>
																		<li><b>All Freezer</b></li>
																		<ul>
																			<li>Zones 1, 2: -20°C</li>
																			<li>Zone 3: Off</li>
																		</ul>
																		<li><b>All Chiller</b></li>
																		<ul>
																			<li>Zones 1, 2: +2°C</li>
																			<li>Zone 3: Off</li>
																		</ul>
																	</ul>
																</Col>
															) : null
														}
													</Fragment>
												);
											})
										}
									</Row>
								</div>
							);
						})
					}
				</Form>
				<FaultReportModalAddNew 
					fleetNumbers={p.fleetNumbers}
					onSave={this.handleSaveFaultReport}
					onClose={this.handleCloseModalFaultReport}
					show={this.state.showModalFaultReport}
				/>
				<Modal bsSize="sm" show={s.addedLitersFieldId ? true : false} onHide={this._handleCloseAddedLitersModal}>
					<form onSubmit={this._handleSubmitAddedLiters}>
						<Modal.Header closeButton>
							<Modal.Title>Liters Added</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<InputGroup>
								<FormControl 
									id={s.addedLitersFieldId} 
									type="number"
									step="0.1"
									placeholder="e.g. 1.5"
									required
									autoFocus
								/>
								<InputGroup.Addon>liter(s)</InputGroup.Addon>
							</InputGroup>
							<i className="text-muted">
									Type the total litres that you added to the engine. 
									This field accepts a decimal number.
							</i>
						</Modal.Body>
						<Modal.Footer>
							<Button type="submit" className="pull-left" bsStyle="success">Save</Button>
							<Button onClick={this._handleCloseAddedLitersModal} bsStyle="danger">Cancel</Button>
						</Modal.Footer>
					</form>
				</Modal>
			</div>
		);
	}
}

VehicleMechanicalCheck.propTypes = propTypes;
VehicleMechanicalCheck.defaultProps = defaultProps;

export default VehicleMechanicalCheck;