import { convertMetersToKm, convertSecondsToHours } from "../helpers/numbersHelper";
import { IVehicleMaintenance } from "./VehicleMaintenance";

export interface IVehicleBasicInfo {
	fleetNumber: string;
	typeId: number;
	typeName: string;
	workingState: string;
	gvm: number;
	tare: number;
	regoState?: string;
	regoNumber?: string;
	maxPallets?: number;
	axles?: number;
	vin?: string;
}

export interface IVehicleDetailsForTask extends IVehicleBasicInfo {
	MaintenanceServices?: IVehicleMaintenance[];
}

export interface IVehicleListDetails {
	fleetNumber: string;
	typeName: string;
	modelYear: number;
	makeName: string;
	isActive: boolean;
	workingState: string;
	regoNumber?: string;
	regoState?: string;
	odometerTotalMeter?: number;
	freezerOnTotalSec?: number;
	trackingUnitId?: string;
	trackingUnitPhoneNumber?: string;
}

export interface IVehicleDetails {
	fleetNumber: string;
	workingState: string; 
	workingStateId: number; 
	typeId: number;
	typeName: string;
	makeId: number;
	makeName: string;
	gvm: number;
	tare: number;
	isActive: boolean;
	vin: string;
	regoNumber?: string;
	regoState?: string;
	regoStateId?: number;
	odometerTotalMeter?: number;
	freezerOnTotalSec?: number;
	modelYear?: number;
	axles?: number;
	maxPallets?: number;
	numberTempZones?: number;
	subContractorAbn?: string;
	subContractorName?: string;
	hasTailgate: boolean;
	trackingUnitId?: string;
	trackingUnitPhoneNumber?: string;
	trackingSettingsId?: number;
	trackingSettingsName?: string;

	VehicleRegoHistory?: IVehicleRegoHistory[];
	VehicleMaintenanceHistory?: IVehicleMaintenance[];
}

export class VehicleDetailsForm {
	fleetNumber: string;
	workingState: string; 
	workingStateId: string;
	typeId: string;
	typeName: string;
	makeId: string;
	makeName: string;
	gvm: string
	tare: string;
	isActive: boolean;
	vin: string;
	regoNumber: string;
	regoState: string;
	regoStateId: string;
	odometerKm: string;
	freezerHours: string;
	modelYear: string;
	axles: string;
	maxPallets: string;
	numberTempZones: string;
	subContractorAbn: string;
	subContractorName: string;
	hasTailgate?: boolean;
	trackingUnitId: string;
	trackingUnitPhoneNumber: string;
	trackingSettingsId?: string;
	trackingSettingsName?: string;
	VehicleRegoNew?: IVehicleRegoNew;
	VehicleRegoHistory?: IVehicleRegoHistory[];
	VehicleMaintenanceHistory?: IVehicleMaintenance[];

	constructor(details?: IVehicleDetails) {
		this.fleetNumber = details?.fleetNumber || '';
		this.workingState = details?.workingState || '';
		this.workingStateId = details?.workingStateId?.toString() || '';
		this.typeId = details?.typeId.toString() || '';
		this.typeName = details?.typeName || '';
		this.makeId = details?.makeId?.toString() || ''
		this.makeName = details?.makeName || '';
		this.gvm = details?.gvm?.toString() || '';
		this.tare = details?.tare?.toString() || '';
		this.isActive = details?.isActive === false ? false : true;
		this.vin = details?.vin || '';
		this.regoNumber = details?.regoNumber || '';
		this.regoState = details?.regoState || '';
		this.regoStateId = details?.regoStateId?.toString() || '';
		this.odometerKm = details?.odometerTotalMeter ? convertMetersToKm(details.odometerTotalMeter).toString() : '';
		this.freezerHours = details?.freezerOnTotalSec ? convertSecondsToHours(details.freezerOnTotalSec).toString() : '';
		this.modelYear = details?.modelYear?.toString() || '';
		this.axles = details?.axles?.toString() || '';
		this.maxPallets = details?.maxPallets?.toString() || '';
		this.numberTempZones = details?.numberTempZones?.toString() || '';
		this.subContractorAbn = details?.subContractorAbn || '';
		this.subContractorName = details?.subContractorName || '';
		this.hasTailgate = details?.hasTailgate;
		this.trackingUnitId = details?.trackingUnitId || '';
		this.trackingUnitPhoneNumber = details?.trackingUnitPhoneNumber || '';
		this.trackingSettingsId = details?.trackingSettingsId?.toString() || '';
		this.trackingSettingsName = details?.trackingSettingsName || '';
		this.VehicleRegoHistory = details?.VehicleRegoHistory || [];
		this.VehicleMaintenanceHistory = details?.VehicleMaintenanceHistory || [];
	}
}

export class VehiclePOST {
	fleetNumber: string;
	workingStateId: number; 
	typeId: number;
	makeId: number;
	gvm?: number
	tare?: number;
	vin: string;
	regoNumber?: string;
	regoStateId?: number;
	odometerTotalMeter?: number;
	freezerOnTotalSec?: number;
	modelYear?: number;
	axles?: number;
	maxPallets?: number;
	hasTailgate: boolean;
	numberTempZones?: number;
	subContractorAbn?: string;
	trackingUnitId?: string;
	trackingSettingsId?: number;
	RegoForEdition?: IVehicleRegoNew;
	

	constructor(vehicle: VehicleDetailsForm) {
		this.fleetNumber = vehicle.fleetNumber;
		this.workingStateId = parseInt(vehicle.workingStateId); 
		this.typeId = parseInt(vehicle.typeId);
		this.makeId = parseInt(vehicle.makeId);
		this.gvm = vehicle.gvm ? parseInt(vehicle.gvm) : undefined;
		this.tare = vehicle.tare ? parseInt(vehicle.tare) : undefined;
		this.vin = vehicle.vin;
		this.regoNumber = vehicle.regoNumber;
		this.regoStateId = vehicle.regoStateId ? parseInt(vehicle.regoStateId) : undefined;
		this.odometerTotalMeter = vehicle.odometerKm ? parseInt(vehicle.odometerKm) * 1000 : 0;
		this.freezerOnTotalSec = vehicle.freezerHours ? parseInt(vehicle.freezerHours) * 60 * 60 : 0;
		this.modelYear = vehicle.modelYear ? parseInt(vehicle.modelYear) : undefined;
		this.axles = vehicle.axles ? parseInt(vehicle.axles) : undefined;
		this.maxPallets = vehicle.maxPallets ? parseInt(vehicle.maxPallets) : undefined;
		this.numberTempZones = vehicle.numberTempZones ? parseInt(vehicle.numberTempZones) : undefined;
		this.hasTailgate = vehicle.hasTailgate || false;
		this.subContractorAbn = vehicle.subContractorAbn;
		this.trackingUnitId = vehicle.trackingUnitId;
		this.trackingSettingsId = vehicle.trackingSettingsId ? parseInt(vehicle.trackingSettingsId) : undefined;
	}
}

export class VehiclePUT extends VehiclePOST {
	RegoForEdition?: IVehicleRegoNew;
	constructor(vehicle: VehicleDetailsForm) {
		super(vehicle);
		this.RegoForEdition = vehicle.VehicleRegoNew;
	}
}

export interface IVehicleRegoNew {
	regoNumber: string;
	regoStateId: number;
	newRegoStartDate: string;
	oldRegoCancelReason: string;
}

export interface IVehicleRegoHistory {
	id: number;
	regoNumber: string;
	stateId: number;
	stateName: string;
	startedOn: string;
	cancelledOn: string;
	cancelDescription: string;
}

export class VehiclesListFilter {
	fleetNumber = '';
	isActive? = true;
	vehicleTypeIds: number[] = [];
}

export enum VehicleTypes {
	PALLET_JACK = 1,
	RIGID = 2,
	TRAILER = 3,
	PRIME_MOVER = 4,
	B_DOUBLE = 5,
	UTE = 6,
	DOLLY = 7
}

export interface IVehicle {
	vehFleetNumber: string;
	vehGvm: number;
	vehIsActive: boolean;
	vehMakeModelName: string;
	vehMakeName: string;
	vehState: string;
	vehTare: number;
	vehTypeID: number;
	vehTypeName: string;
	vehModelYear: number;
	vehVin?: string;
	vehDeviceId?: string;
	vehDevicePhoneId?: string;
	vehDeviceSimcardNumber?: string;
	vehEngineOnTotalSec?: number;
	vehEventLostConnId?: number;
	vehFreezerOnTotalSec?: number;
	vehLastPing?: Date | string;
	vehLastPingEpoch?: number;
	vehLastPingId?: number
	vehLowBatteryEventId?: number;
	vehMaxPallets?: number;
	vehNumberTempZones?: number;
	vehOdometerTotalMeter?: number;
	vehRego?: string;
	vehSetHasDoorCounter: boolean;
	vehSetHasDoorSen: boolean;
	vehSetHasEngineOnCounter: boolean;
	vehSetHasEngineSen: boolean;
	vehSetHasEventBattDisconnectedCheck: boolean;
	vehSetHasEventDoorOpenOutGeo: boolean;
	vehSetHasEventFirstPingCheck: boolean;
	vehSetHasEventLostConn: boolean;
	vehSetHasEventTemp1Error: boolean;
	vehSetHasEventTemp2Error: boolean;
	vehSetHasEventTemp3Error: boolean;
	vehSetHasEventVehLowBatt: boolean;
	vehSetHasFreezerOnCounter: boolean;
	vehSetHasFreezerSen: boolean;
	vehSetHasGeofence: boolean;
	vehSetHasOdometerCounter: boolean;
	vehSetHasSpeedCheck: boolean;
	vehSetHasTempZone1Sen: boolean;
	vehSetHasTempZone2Sen: boolean;
	vehSetHasTempZone3Sen: boolean;
	vehSetHasVehicleBattCharger: boolean;
	vehSetHasVehicleBattVoltSen: boolean;
	vehCanAttachDolly: boolean;
}