import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormControl } from 'react-bootstrap';

const propTypes = {
	name: PropTypes.string,
	id: PropTypes.string,
	cssClass: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	bsSize: PropTypes.oneOf(['small', 'large']),
	noDataOptionText: PropTypes.string,
	onChange: PropTypes.func,
    
	/**
     * Shows the Loading text
     */
	isLoading: PropTypes.bool,

	/**
     * The options data
     */
	data: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.string
			]),
			value: PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.string,
				PropTypes.bool,
			]),
			text: PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.string
			]),
			name: PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.string
			]),
			className: PropTypes.string,
			disabled: PropTypes.bool
		})
	).isRequired,

	/**
     * Shows the first option as "All"
     */
	showAllOption: PropTypes.bool,
	noValueSelectedDisabled: PropTypes.bool,
	noValueSelectedText: PropTypes.string,
	firstOptionText: PropTypes.string
};

const Select = props => {
	const handleChange = (e) => {
		const text = e.target.options[e.target.selectedIndex].text;
		const object = props.data?.find(p => p.id && p.id.toString() === e.target.value);
		if (props.onChange)
			return props.onChange(e, text, object);
	};
	
	return (
		<FormControl 
			name={props.name}
			id={props.id} 
			componentClass="select"
			bsSize={props.bsSize}
			className={classNames(props.cssClass || props.className)} 
			disabled={props.disabled}
			value={props.value} 
			required={props.required}
			onChange={handleChange}
		>
			{
				props.isLoading ? (
					<option value="">Loading...</option>
				) : (
					props.data && props.data.length > 0 ? ([
						<option key={-1} value="">{props.showAllOption ? 'All' : props.firstOptionText || props.placeholder || '--- SELECT ---'}</option>,
						props.data.map(({ id, value, name, text, className, disabled }, index) => (
							<option disabled={disabled} key={index} value={id || value} className={className}>
								{name || text || id}
							</option>
						))
					]) : (
						<option value="">{ props.noDataOptionText || 'No data was found' }</option>
					)
				)
			}
		</FormControl>
	);
};

Select.propTypes = propTypes;

export { Select };