export const geofencePolygonOptions = {
	fillColor: '#00804d',
	fillOpacity: 0.2,
	strokeColor: '#000000',
	strokeOpacity: 0.9,
	strokeWeight: 1
};

export const placeMarkerOptions = {
	fontWeight: 'bold', 
	fontSize: '14px', 
	width: '200px', 
	textAlign: 'center',
	color: 'yellow',
	textShadow: '1px 1px #000'
};