import React from 'react';
import { Link } from 'react-router';
import MainLayout from './_MainLayout';
import { MainContent } from '../common/layout';
import NotFoundImage from '../../app/assets/img/notFound.png';

const NotFound = () => (
	<MainContent>
		<MainLayout
			showLogo={false}
			title="Page not Found"
			color="yellow"
			text="Oops! Page not found :("
			imageSize={200}
			image={NotFoundImage}
		>
			We could not find the page you were looking for.<br/>
			<Link to="/">Click Here</Link> to return to the initial page.
		</MainLayout>
	</MainContent>
)

export { NotFound };