import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import cloneObject from 'lodash/cloneDeep';
import { Button, FormGroup, ButtonToolbar } from 'react-bootstrap';
import confirm from '../../../../../../app/helpers/confirm';
import { thereAreOverlappedWindows, isWindowInvalid } from '../../../../../../app/helpers/timeWindowsHelper';
import weekDays from '../../../../../../app/store/weekDays';

import WindowsTable from './AccessWindowTable';
import AccessWindowModalForm from './AccessWindowModalForm';

export default class AccessWindow extends Component {
	static propTypes = {
		accessWindowsList: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number,
				dayOfWeek: PropTypes.number.isRequired,
				startTime: PropTypes.string.isRequired,
				endTime: PropTypes.string.isRequired,
				isAttended: PropTypes.bool.isRequired,

				/** Temporary ID that is used to help the comparison of the windows */
				tempId: PropTypes.string.isRequired
			})
		).isRequired,
		isReadOnly: PropTypes.bool,
		onChange: PropTypes.func,
		onClickReplicateButton: PropTypes.func
	}

	state = {
		showAddNewWindowModal: false,
		enableEditMode: false,
		accessWindowsBeingEdited: []
	};

	handleAddNewWindow = () => {
		this.setState({ showAddNewWindowModal: true });
	}

	handleSaveNewWindow = newAccessWindows => {
		// Check overlaps
		if (thereAreOverlappedWindows(newAccessWindows, this.props.accessWindowsList))
			return;

		const accessWindowsList = [
			...this.props.accessWindowsList,
			...newAccessWindows
		];

		this.setState({ showAddNewWindowModal: false });
		this.props.onChange && this.props.onChange(accessWindowsList);
	}

	handleSaveChanges = () => {
		let { accessWindowsBeingEdited } = this.state;

		// Check overlaps
		if (thereAreOverlappedWindows(accessWindowsBeingEdited, this.props.accessWindowsList, true))
			return;
		
		// Check invalid windows
		let anyInvalidWindow = false;
		accessWindowsBeingEdited = accessWindowsBeingEdited.map(accessWindow => {
			const invalidWindowMessage = isWindowInvalid(accessWindow.startTime, accessWindow.endTime);
			if (invalidWindowMessage) {
				anyInvalidWindow = true;
				accessWindow.invalidMessage = invalidWindowMessage;
			} else {
				accessWindow.invalidMessage = null;
			} 

			return accessWindow;
		});

		if (anyInvalidWindow)
			return this.setState({ accessWindowsBeingEdited });

			
		const accessWindowsList = cloneObject(accessWindowsBeingEdited);
		this.props.onChange && this.props.onChange(accessWindowsList);

		this.setState({ 
			accessWindowsBeingEdited: [],
			enableEditMode: false
		});
	}

	handleRemoveWindow = pTempId => {
		const { accessWindowsList } = this.props;
		const indexToRemove = accessWindowsList.findIndex(p => p.tempId === pTempId);
		const windowToRemove = accessWindowsList[indexToRemove];
		if (!windowToRemove)	
			throw new Error('Window not found in the array');

		const { startTime, endTime, dayOfWeek } = windowToRemove;
		confirm(
			(
				<h4 className="text-center">
					The window <b>{weekDays[dayOfWeek - 1]} {startTime} - {endTime}</b> will be deleted. Do you wish to continue?
				</h4>
			),
			() => {
				if (windowToRemove.id)
					windowToRemove.isDeleted = true;
				else
					accessWindowsList.splice(indexToRemove, 1);

				this.props.onChange && this.props.onChange(accessWindowsList);
			}
		);
	}

	handleChangeWindow = (pTempId, pEvent) => {
		const { accessWindowsBeingEdited } = this.state;
		const { id, value, type, checked } = pEvent.target;
		const windowToChange = accessWindowsBeingEdited.find(p => p.tempId === pTempId);
		windowToChange[id] = type === 'checkbox' ? checked : value;
		this.setState({ accessWindowsBeingEdited });
	}

	handleToggleEditMode = () => {
		const enableEditMode = !this.state.enableEditMode;
		this.setState({ 
			enableEditMode,
			accessWindowsBeingEdited: enableEditMode ? cloneObject(this.props.accessWindowsList) : []
		});
	}

	handleClearAllWindows = () => {
		confirm(
			'All windows will be deleted! Do you wish to continue?',
			() => {
				const accessWindowsList = this.props.accessWindowsList.filter(window => window.id).map(window => { 
					window.isDeleted = true;
					return window;
				});

				this.props.onChange && this.props.onChange(accessWindowsList);
			}
		);
	}

	renderActionButton = (text, icon, colour, onClick, className) => {
		return (
			<Button 
				bsStyle={colour}
				onClick={onClick}
				className={className}
			>
				<Icon name={icon} /> {text}
			</Button>
		);
	}

	render() {
		const { state, props } = this;

		return (
			<div className="time-windows">
				{
					!props.isReadOnly && (
						<React.Fragment>
							<AccessWindowModalForm 
								show={state.showAddNewWindowModal}
								onCancel={() => this.setState({ showAddNewWindowModal: false })}
								onSave={this.handleSaveNewWindow}
							/>
							<FormGroup>
								<ButtonToolbar>
									{
										state.enableEditMode ? (
											<Fragment>
												{ this.renderActionButton('Cancel', 'arrow-left', 'danger', this.handleToggleEditMode) }
												{ this.renderActionButton('Save Changes', 'floppy-o', 'success', this.handleSaveChanges) }
											</Fragment>
										) : (
											<Fragment>
												{
													props.accessWindowsList && props.accessWindowsList.length > 0 && (
														this.renderActionButton('Edit Windows', 'edit', 'warning', this.handleToggleEditMode)
													)
												}
												{ this.renderActionButton('Add New Window', 'plus', 'success', this.handleAddNewWindow) }
												{ 
													props.accessWindowsList && props.accessWindowsList.length > 0 && props.showReplicationButton && (
														this.renderActionButton('Replicate Window', 'files-o', 'info', props.onClickReplicateButton)
													)
												}
												{ this.renderActionButton('Clear All Windows', 'trash', 'danger', this.handleClearAllWindows, 'pull-right') }
											</Fragment>
										)
									}
									
								</ButtonToolbar>
							</FormGroup>
						</React.Fragment>
					)
				}
				<WindowsTable
					isReadOnly={props.isReadOnly}
					enableEditMode={state.enableEditMode}
					accessWindowsList={state.enableEditMode ? state.accessWindowsBeingEdited : props.accessWindowsList}
					onClickRemove={this.handleRemoveWindow}
					onChange={this.handleChangeWindow}
				/>
			</div>
		);
	}
}