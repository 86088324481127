const initialState = {
	breadcrumbs: null,
	circle: null,
	markers: null,
	zoom: 12,
	position: null
};

/** 
 * ACTION TYPES
*/
export const SET_DEFAULT = 'tms-web-ui/map/SET_DEFAULT';
export const SET_BREADCRUMBS = 'tms-web-ui/map/SET_BREADCRUMBS';
export const SET_CIRCLE_LOCATION = 'tms-web-ui/map/SET_CIRCLE_LOCATION';
export const SET_CIRCLE_RADIUS = 'tms-web-ui/map/SET_CIRCLE_RADIUS';
export const SET_MARKERS = 'tms-web-ui/map/SET_MARKERS';
export const SET_POSITION = 'tms-web-ui/map/SET_POSITION';
export const SET_ZOOM = 'tms-web-ui/map/SET_ZOOM';

/**
 * ACTIONS
 */
export function setDefaultMap() {
	return {
		type: SET_DEFAULT
	};
}
 
export function setBreadcrumbs(breadcrumbs) {
	return {
		type: SET_BREADCRUMBS,
		payload: breadcrumbs
	};
}

export function setCircleLocation(lat, lng) {
	return {
		type: SET_CIRCLE_LOCATION,
		payload: { lat, lng }
	};
}

export function setCircleRadius(radiusMeters) {
	return {
		type: SET_CIRCLE_RADIUS,
		payload: radiusMeters
	};
}

export function setMarkers(markersData) {
	return {
		type: SET_MARKERS,
		payload: markersData
	};
}

export function setPosition(lat, lng) {
	return {
		type: SET_POSITION,
		payload: { lat, lng }
	};
}

export function setZoom(zoom) {
	return {
		type: SET_ZOOM,
		payload: zoom
	};
}

/**
 * REDUCER
 */
export default (state = initialState, { type, payload }) => {
	switch (type) {
	case SET_BREADCRUMBS:
		return { ...state, breadcrumbs: payload };
	case SET_CIRCLE_LOCATION:
		return {
			...state,
			circle: {
				...state.circle,
				lat: payload.lat,
				lng: payload.lng
			}
		};
	case SET_CIRCLE_RADIUS:
		return {
			...state,
			circle: {
				...state.circle,
				radiusMeters: payload,
			}
		};
	case SET_MARKERS:
		return { ...state, markers: payload };
	case SET_POSITION:
		return {
			...state,
			position: {
				lat: payload.lat,
				lng: payload.lng
			}
		};
	case SET_ZOOM:
		return { ...state, zoom: payload };
	case SET_DEFAULT:
		return {  ...initialState };
	default:
		return state;
	}
};