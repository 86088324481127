import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import DurationParse from '../../../app/helpers/DurationParse';

//APIs
import TrackingApi from '../../../app/api/TrackingApi';

// Components
import { ErrorBox, Table, Loader } from '../../common/uiElements';

const propTypes = {
	data: PropTypes.array,
	startTime: PropTypes.number.isRequired,
	finishTime: PropTypes.number.isRequired,
	vehFleetNumber: PropTypes.string.isRequired,
	onSelectStep: PropTypes.func
};

class JourneyStepsList extends Component {
	constructor(props) {
		super();
		this.state = {
			isLoading: false,
			error: null,
			data: props.data || null,
			totals: null,
			selectedStepIndex: null
		};
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	UNSAFE_componentWillMount() {
		if (!this.props.data)
			return this.fetchData();
	}
		
	fetchData = () => {
		var { vehFleetNumber, startTime, finishTime } = this.props;
		this.setState({ isLoading: true, error: null });
		return TrackingApi.journeyStepsByVeh(vehFleetNumber, startTime, finishTime)
			.then((data) => {
				if (this.unmounted) return;

				let totals;
				let totalDistanceInMeters = 0;
				let totalDriveTimeInSeconds = 0;
				let totalParkedTimeInSeconds = 0;
	
				if (data && data.length > 0) {
					for (var i = 0; i < data.length; i++) {
						const { 
							isMoving,
							JourneyOdometerDiffMeters, 
							JourneyTimeSpanSeconds
						} = data[i];
						
						if (isMoving) {
							totalDistanceInMeters += JourneyOdometerDiffMeters;
							totalDriveTimeInSeconds += JourneyTimeSpanSeconds;
						} else {
							totalParkedTimeInSeconds += JourneyTimeSpanSeconds;
						}
					}
					totals = {
						totalDistanceInMeters, 
						totalDriveTimeInSeconds,
						totalParkedTimeInSeconds
					};
				}

				return this.setState({ 
					data, 
					totals,
					isLoading: false 
				});
			})
			.catch((error) => {
				console.error(error);
				return this.setState({ error, isLoading: false });
			});
	}

	handleSelectStep = (step, index) => {
		if (this.props.onSelectStep)
			this.props.onSelectStep(step, index);

		return this.setState({ selectedStepIndex: index });
	}

	renderPrintHeader = () => {
		var { vehFleetNumber, startTime, finishTime } = this.props;
		return (
			<table className="table table-bordered table-condensed">
				<thead>
					<tr>
						<th>Fleet Number</th>
						<th>Start</th>
						<th>Finish</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{ vehFleetNumber }</td>
						<td>{ moment.unix(startTime).format('DD/MM/YYYY HH:mm') }</td>
						<td>{ moment.unix(finishTime).format('DD/MM/YYYY HH:mm') }</td>
					</tr>
				</tbody>
			</table>
		);
	}

	render() {
		const s = this.state;

		if (s.isLoading)
			return <Loader />;
		
		if (s.error)
			return <ErrorBox error={s.error} retryFunc={this.fetchData} />;

		return (
			<div>
				<Table 
					lengthChange
					printable 
					printTitle="Journey Steps Report"
					printHeader={this.renderPrintHeader()} 
					pageLength={5} 
					cssClass="table-hover"
					sortColumnIndex={0}
				>
					<thead>
						{
							s.totals && (
								<tr style={{ backgroundColor: '#f7e13e', color: '#000000', textAlign: 'center'}}>
									<td colSpan="2" width="33%"><b>Total Distance:</b> { (s.totals.totalDistanceInMeters / 1000).toFixed(1)} Km</td>
									<td colSpan="2" width="33%"><b>Total Drive Time:</b> { DurationParse.toHourMin(s.totals.totalDriveTimeInSeconds, 'short') }</td>
									<td colSpan="2" width="33%"><b>Total Parked Time:</b> { DurationParse.toHourMin(s.totals.totalParkedTimeInSeconds, 'short') }</td>
								</tr>
							)
						}
						<tr>
							<th>Started On</th>
							<th>Finished On</th>
							<th>Distance</th>
							<th>Time</th>
							<th>Address</th>
							<th>Places</th>
						</tr>
					</thead>
					<tbody>
						{
							s.data && s.data.length > 0 && (
								s.data.map((step, index) => {
									return (
										<tr onClick={() => { this.handleSelectStep(step, index); }}
											key={index} className={classNames('cursor-pointer', {'active': s.selectedStepIndex === index}, {'text-danger': !step.isMoving})}
										>
											<td>{moment.unix(step.StartTimeEpoch).format('DD/MM/YY HH:mm')}</td>
											<td>{moment.unix(step.FinishTimeEpoch).format('DD/MM/YY HH:mm')}</td>
											{
												step.isMoving ? (
													<td>{(step.JourneyOdometerDiffMeters / 1000).toFixed(1)} Km</td>
												) : (
													<td>Parked</td>
												)
											}
											<td>{DurationParse.toHourMin(step.JourneyTimeSpanSeconds, 'short')}</td>
											<td>{step.JourneyAddress}</td>
											<td>
												{
													step.JourneyPlaces.map((place, indexPlace) => {
														return `${indexPlace > 0 ? ', ' : ''}${place}`;
													})
												}
											</td>
										</tr>
									);
								})
							)
						}
					</tbody>
				</Table>
			</div>
		);
	}
}

JourneyStepsList.propTypes = propTypes;

export { JourneyStepsList };