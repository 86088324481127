import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-google-maps';

const propTypes = {
	label: PropTypes.shape({
		text: PropTypes.string,
		fontSize: PropTypes.number
	}),
	icon: PropTypes.shape({
		url: PropTypes.string,
		size: PropTypes.object
	}),
	labelOrigin: PropTypes.object
};

const GoogleMarker = props => {
	let markerProps = { ...props };

	if(markerProps.label && !markerProps.label.fontSize) {
		const labelText = markerProps.label.text || markerProps.label; 
		markerProps.label = { text: labelText, fontSize: '12px', fontWeight: 'bold' };
	}
    
	if (markerProps.icon && !markerProps.labelOrigin)
		markerProps.icon = { 
			url: markerProps.icon.url || 'http://cdn.micway.com.au/tms/img/map_marker.png', 
			labelOrigin: new window.google.maps.Point(20, 45), 
			scaledSize: markerProps.icon.size || new window.google.maps.Size(35, 35)
		};

	return <Marker { ...markerProps } />;
};

GoogleMarker.propTypes = propTypes;

export { GoogleMarker };