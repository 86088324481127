/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import helper from './helper';
import Item from './item';
import './style.css';

import { FormControl } from 'react-bootstrap';

const propTypes = {
	id: PropTypes.string,
	onChange: PropTypes.func,

	/** Called when image is being loaded */
	onLoadPicture: PropTypes.func,
    
	maxPictures: PropTypes.number,
	pictures: PropTypes.arrayOf(
		PropTypes.shape({
			file: PropTypes.object.isRequired,
			dataURL: PropTypes.string.isRequired
		}).isRequired
	),
	quality: PropTypes.number
};

const defaultProps = {
	quality: 0.8,
	pictures: []
};

class MultiplePicturesInput extends Component {
	constructor(props) {
		super();

		this.state = {
			pictures: props.pictures,
			isLoading: false
		};
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

    onChange = () => {
			var { id, onChange } = this.props;
			if(onChange) 
				return onChange(this.state.pictures, id);
		}

	onAddFile = (e) => {
		let file = e.target.files[0];
		if (this.props.onLoadPicture) this.props.onLoadPicture(true);
		
		this.setState({ isLoading: true }, () => {
			setTimeout(() => {
				helper.changeImageQuality(file, this.props.quality, (resizedFile) => {
					let reader = new FileReader();
					reader.readAsDataURL(resizedFile);
					reader.onload = () => {
						let dataURL = reader.result;
						let pictures = this.state.pictures;
						pictures.push({
							dataURL
						});

						pictures[pictures.length - 1].file = resizedFile;
						if (this.props.onLoadPicture) this.props.onLoadPicture(false);
						this.setState({
							isLoading: false,
							pictures
						}, this.onChange);
					};
				});
			}, 700);
		});
	}

	onRemoveFile = (index) => {
		let pictures = this.state.pictures;
		pictures.splice(index, 1);
		return this.setState({ 
			pictures 
		}, this.onChange);
	}

	onClickAdd = () => {
		this.input.value = '';
		this.input.click();
	}

	render() {
		let s = this.state;
		return (
			<div className="mpi-main mpi-danger">
				<ul className="list list-inline">
					{
						s.pictures.map((picture, index) => {
							let { dataURL } = picture;
							let props = { index, onRemoveFile: this.onRemoveFile, dataURL };
							return (
								<Item key={index} {...props} />
							);
						})
					}
					<li>
						{
							s.isLoading ? (
								<Icon spin name="refresh" size="3x" />
							) : (
								(!this.props.maxPictures || s.pictures.length < this.props.maxPictures) && (
									<div>
										<div className="hidden">
											<FormControl
												inputRef={ref => this.input = ref} 
												id="fileInput" 
												name="fileInput" 
												type="file"
												accept="image/*"
												onChange={this.onAddFile}
											/>
										</div>
										<a className="mpi-add-btn" onClick={this.onClickAdd}>
											<Icon name="plus" />
										</a>
									</div>
								)
							)
						}
					</li>
				</ul>
			</div>
		);
	}
}

MultiplePicturesInput.propTypes = propTypes;
MultiplePicturesInput.defaultProps = defaultProps;

export { MultiplePicturesInput };