/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import moment from 'moment';

import { Button, FormGroup } from 'react-bootstrap';
import { Callout, UserDetailsLink, TimelineContainer } from '../../../../common/uiElements';
import correctiveActionType from '../../../../../app/propTypes/correctiveActionType';
import CorrectiveActionApi from '../../../../../app/api/CorrectiveActionApi';

const propTypes = {
	correctiveActionsList: PropTypes.arrayOf(
		PropTypes.shape(correctiveActionType)
	),
	onClickSeeDetails: PropTypes.func
};

const defaultProps = {
	correctiveActionsList: [],
	onClickSeeDetails: () => {}
};

const CorrectiveActionHistory = props => {
	function renderTimeLine() {
		const timeLineArray = [];

		for (let i = props.correctiveActionsList.length - 1; i >= 0; i--) {
			const {
				id,
				actionTakenById,
				actionTakenOn,
				isClosed,
				shortTermAction,
				preventativeAction,
				PreStartCorrectiveActionDocuments,
				WorkerActionTakenBy
			} = props.correctiveActionsList[i];

			timeLineArray.push({
				color: isClosed ? 'green' : 'yellow',
				icon: isClosed ? 'check' : 'thumbs-up',
				header: (
					<b>
						{ isClosed && 'Closed on ' }
						<u>{ moment.utc(actionTakenOn).local().format('DD/MM/YYYY') }</u>
						&nbsp;by&nbsp;
						<UserDetailsLink azureId={actionTakenById}>
							{ WorkerActionTakenBy.shortName }
						</UserDetailsLink>
					</b>
				),
				body: (
					<Fragment>
						<div>
							<b>Short Term Action:</b> { shortTermAction } <br />
							<b>Preventative Action:</b> { preventativeAction }
						</div>
						{
							PreStartCorrectiveActionDocuments.length > 0 && (
								<div>
									<b>Attachments: </b>&nbsp;
									{
										PreStartCorrectiveActionDocuments.map((doc, index) => (
											<Fragment key={index}>
												{ index > 0 && <span>,&nbsp;&nbsp;</span> }
												<a title="Download File" onClick={() => CorrectiveActionApi.getDocument(id, doc.id)}>
													<Icon name="file-o" /> { doc.fileName }
												</a>
											</Fragment>
										))
									}
								</div>
							)
						}
						<FormGroup>
							<Button 
								bsSize="small"
								bsStyle="primary"
								onClick={() => props.onClickSeeDetails(id)}
							>
								<Icon name="search"/> See Details
							</Button>
						</FormGroup>
					</Fragment>
				)
			});
		}

		return timeLineArray;
	}

	return (
		<Fragment>
			{
				(!props.correctiveActionsList || props.correctiveActionsList.length === 0) ? (
					<Callout color="info">
						<b>No Corrective Action was registered yet</b>
					</Callout>
				) : (
					<TimelineContainer items={ renderTimeLine() } />
				)
			}
		</Fragment>
	);
};


CorrectiveActionHistory.propTypes = propTypes;
CorrectiveActionHistory.defaultProps = defaultProps;

export default CorrectiveActionHistory;