import moment from 'moment';

// CUSTOM VALIDATIONS
window.$.validator.addMethod('tfn', function(value, element) {
	return this.optional(element) || value.replace(/\s/g,'').length === 9;
}, 'This TFN is invalid');

window.$.validator.addMethod('abn', function(value, element) {
	return this.optional(element) || value.replace(/\s/g,'').length === 11;
}, 'This ABN is invalid');

window.$.validator.addMethod('username', function(value) {
	var pattern = /^[^<>()[\]\\,;:%*+#^~={}'`/\s@"$&!@]+$/;
	return pattern.test(value);
}, 'This username is invalid');

window.$.validator.addMethod('employeeNumber', function(value) {
	if (!value) return true;
	var pattern = /\b[a-zA-Z][0-9]{5}\b/;
	return pattern.test(value);
}, 'Must start with a capital letter + 5 digits');

window.$.validator.addMethod('required', function(value) {
	return value && value !== '__:__';
}, 'This field is required');

window.$.validator.addMethod('greaterOrEqualThan', function(value, element, params) {
	let startValue = window.$(params)?.val() || params;

	if (!startValue) 
		return true;
	
	if (!/Invalid|NaN/.test(new Date(value))) {

		// Check it should consider the time too
		startValue = value.length > 10 ? startValue : moment(startValue).format('YYYY-MM-DD');

		return new Date(value) >= new Date(startValue);
	}
	
	return (isNaN(value) && isNaN(startValue))
        || (Number(value) >= Number(startValue)); 
}, params => {
	let date = window.$(params)?.val() || params;
	date = moment(date).isValid() ? moment(date).format('DD/MM/YYYY') : date;
		return 'Must be greater or equal to ' + date;
});

window.$.validator.methods.email = function(value) {
	var pattern = /^[^<>()[\]\\,;:%*+#^~={}'`/\s@"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/;
	return !value || pattern.test(value);
};

window.$.validator.addMethod('min', function (value, el, param) {
	if (!value || isNaN(value)) 
		return true;

	return parseFloat(value) >= param;
}, params => `Value must be greater or equal to ${params.toString()}`);

window.$.validator.addMethod('max', function (value, el, param) {
	if (!value || isNaN(value)) 
		return true;

	return parseFloat(value) <= param;
}, params => `Value must be lower or equal to ${params.toString()}`);

window.$.validator.addClassRules('required', {
	required: true
});

// 'time' method to validate time string is in the format of "HH:mm"
window.$.validator.addMethod('time', function(value, element) {
	var param = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
	// Guard case for optional elements
	if (this.optional(element)) {
		return true;
	}
	// Test and return if the param string matches the regex
	return param.test(value);
}, 'Please enter a valid time');