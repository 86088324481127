import React, { Component } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import Icon from 'react-fontawesome';

import { Row, Col, Tab, Nav, NavItem, FormGroup, FormControl } from 'react-bootstrap';
import FaultReportApi from '../../../../../app/api/FaultReportApi';
import { ErrorBox, ContentBox, Loader } from '../../../../common/uiElements';
import FaultReport from './FaultReport';
import FaultReportDetails from './FaultReportDetails';
import FaultReportHistory from './FaultReportHistory';

const propTypes = {
	faultReportId: PropTypes.string.isRequired,
	onSaveNewHistory: PropTypes.func
};

class FaultReportDetailsContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: 'generalInfo',
			data: null,
			serviceTrackerNumber: null,
			error: null,
			errorServiceTrackerNumber: null,
			isLoading: false,
			isServiceTrackerEditMode: false,
			isSavingServiceTrackerNumber: false
		};

		this.tabs = {
			GENERAL_INFO: 'generalInfo',
			HISTORY: 'history',
		};
	}
    
	componentWillUnmount() {
		this.unmounted = true;
	}
    
	UNSAFE_componentWillMount() {
		this.fetchFaultReportData();
	}

	_handleChange = e => {
		const { id, value } = e.target;
		this.setState({
			[id]: value
		});
	}

	_handleSubmitServiceTrackerNumber = e => {
		e.preventDefault();
		const { serviceTrackerNumber, data } = this.state;

		if (serviceTrackerNumber === data.serviceTrackerNumber)
			return;

		this.setState({ isSavingServiceTrackerNumber: true, errorServiceTrackerNumber: null });
		FaultReportApi.updateServiceTrackerNumber(data.id, serviceTrackerNumber)
			.then(() => {
				toastr.success('The data was successfully saved', 'Success!');
				if (this.unmounted) return;

				this.setState({
					isSavingServiceTrackerNumber: false,
					data: {
						...data,
						serviceTrackerNumber: this.state.serviceTrackerNumber
					}
				});
			})
			.catch(error => {
				console.error(error);
				this.setState({ 
					isSavingServiceTrackerNumber: false, 
					errorServiceTrackerNumber: error 
				});
			});
	}

	_handleSaveNewHistory = () => {
		this.setState({ activeTab: this.tabs.HISTORY });
		this.fetchFaultReportData();
		this.props.onSaveNewHistory && this.props.onSaveNewHistory();
	}

	fetchFaultReportData = () => {
		this.setState({ isLoading: true, error: null });

		const { faultReportId } = this.props;
		FaultReportApi.getFaultReport(faultReportId)
			.then(data => {
				if (this.unmounted) return;

				this.setState({
					isLoading: false,
					data,
					serviceTrackerNumber: data.serviceTrackerNumber,
					statusComments: data.statusComments
				});

			})
			.catch(error => {
				console.error(error);
				this.setState({ isLoading: false, error });
			});
	}
	
	render() {
		const s = this.state;

		if (s.isLoading)
			return <Loader text="Loading data. Please wait... "/>;

		if (s.error)
			return <ErrorBox error={s.error} retryFunc={this.fetchFaultReportData}/>;

		return (
			<Tab.Container id="faultReportDetails" activeKey={s.activeTab} onSelect={activeTab => this.setState({ activeTab })}>
				<div>
					<Nav style={{background: '#fff'}} justified bsStyle="pills">
						<NavItem eventKey="generalInfo">
							<Icon name="info-circle" /> General
						</NavItem>
						<NavItem eventKey="history">
							<Icon name="calendar" /> History
						</NavItem>
					</Nav>
					<Tab.Content style={{paddingTop: '10px'}}>
						<Tab.Pane eventKey="generalInfo">
							<ContentBox bordered title="Main Details" color="primary">
								<Row>
									<Col sm={3} lg={2}>
										<FormGroup>
											<label>Service Tracker Number</label>
											{
												s.errorServiceTrackerNumber ? (
													<ErrorBox error={s.errorServiceTrackerNumber} retryFunc={this._handleSubmitServiceTrackerNumber} />
												) : (
													<form onSubmit={this._handleSubmitServiceTrackerNumber}>
														<FormControl 
															id="serviceTrackerNumber"
															disabled={s.isSavingServiceTrackerNumber}
															value={s.serviceTrackerNumber || ''}
															maxLength={20}
															onBlur={this._handleSubmitServiceTrackerNumber}
															onChange={this._handleChange}
														/>
													</form>
												)
											}
										</FormGroup>
									</Col>
								</Row>
								<hr />
								<FaultReport 
									faultReportData={s.data} 
									isReadOnly
									isEditable
									showAllFields
								/>
							</ContentBox>
							<ContentBox bordered title="Action Details" color="primary">
								<FaultReportDetails { ...s.data } />
							</ContentBox>
						</Tab.Pane>
						<Tab.Pane eventKey="history">
							<ContentBox bordered color="primary">
								<FaultReportHistory data={s.data} onSave={this._handleSaveNewHistory}/>
							</ContentBox>
						</Tab.Pane>
					</Tab.Content>
				</div>
			</Tab.Container>
		);
	}
}

FaultReportDetailsContainer.propTypes = propTypes;

export default FaultReportDetailsContainer;