import React, { Fragment } from 'react';
import { IWorkerBasicInfo } from '../../../app/models/Worker';
import { Popover, Overlay, OverlayTrigger } from 'react-bootstrap';
import { uniqueId } from 'lodash';

interface IProps {
	worker?: IWorkerBasicInfo;
	show?: boolean;
	targetElementId?: string;
}

export const WorkerDriversLicenceOverlayTrigger: React.FC<IProps> = props => {
	if (!props.worker)
		return (
			<Fragment>
				{ props.children }
			</Fragment>
		);

	const title = (
		<b className="text-danger">
			{ props.worker.currentLicenceType ? 'Licence Not Valid' : 'No Licence Registered' }
		</b>
	);

	const content = (
		<Popover title={title} id={'tooltip_roster_' + uniqueId()}>
			{
				!props.worker.currentLicenceType ? (
					<Fragment>
						<strong>{ props.worker.firstName } </strong>
						does not have a valid driver licence registered in the system. It was not possible to verify if the licence can be used to drive the current fleet combination
					</Fragment>
				) : (
					<Fragment>
						<strong>{ props.worker.firstName }'s </strong>
						registered driver licence is
						<strong> { props.worker.currentLicenceType } </strong>
						and can't be used to drive the current fleet combination
					</Fragment>
				)
			}
		</Popover>
	)
	if (props.show && props.targetElementId)
		return (
			<div>
				{ props.children }
				<Overlay
					show
					placement="top" 
					target={() => document.getElementById(props.targetElementId!)}
				>
					{ content }
				</Overlay>
			</div>
		)

	return (
		<OverlayTrigger
			placement="top" 
			overlay={content}
		>
			{ props.children }
		</OverlayTrigger>
	)
}