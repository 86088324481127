/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ListsApi from '../../../app/api/ListsApi';
import { ErrorBox } from '../uiElements';

const propTypes = {
	id: PropTypes.string,
	cssClass: PropTypes.string,
	onlyActive: PropTypes.bool
};

const defaultProps = {
	cssClass: '',
	onlyActive: true
};

class WorkerDocTypesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			isLoading: false,
			error: false,
			value: ''
		};
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	UNSAFE_componentWillMount() {
		this.fetchData();
	}

	componentDidUpdate() {
		var currentValue = this.state.value;
		var nextValue = this.props.value;
		if (currentValue != nextValue)
			this.setState({ value: nextValue });
	}
  
	fetchData = () => {
		this.setState({ isLoading: true, error: null });
		ListsApi.getWorkerDocTypes(this.props.onlyActive)
			.then(data => {
				if (this.unmounted) return;

				this.setState({ data, isLoading: false, error: null });
			})
			.catch(error => {
				console.error(error);
				this.setState({ error, isLoading: false });
			});
	}

	handleChange = e => {
		var value = e.target.value;
		this.setState({ value }, this.props.onChange(e));
	}

	render() {
		const s = this.state;
		const p = this.props;

		if (s.error) {
			return (
				<ErrorBox 
					error="Sorry, we are unable to retrieve the data list." 
					retryFunc={this.fetchData}
				/>
			);
		}

		return (
			<select id={p.id} className={('form-control ' + p.cssClass)} value={s.value} onChange={this.handleChange}>
				{
					s.isLoading ? (
						<option value="">Loading...</option>
					) : (
						s.data && s.data.length > 0 ? ([
							<option key={-1} value="">--- SELECT ---</option>,
							s.data.map((item, index) => (
								<option key={index} value={item.id}>{item.name}</option>
							))
						]) : (
							<option key={-1} value="">No data was found</option>
						)
					)
				}
			</select>
		);
	}
}

WorkerDocTypesList.propTypes = propTypes;
WorkerDocTypesList.defaultProps = defaultProps;


export { WorkerDocTypesList };