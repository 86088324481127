/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import moment from 'moment';
import classNames from 'classnames';
import omit from 'lodash.omit';
import toastr from 'toastr';
import Icon from 'react-fontawesome';

import CompanyApi from '../../../../app/api/CompanyApi';

import { CompleteAddressInput, MultipleFilesCompanies, YesNoRadio } from '../../../common/inputs';
import { ContentBox, ErrorBox, Loader, Form, FieldGroup } from '../../../common/uiElements';
import { Row, Col, FormGroup, FormControl, Button } from 'react-bootstrap';

const validations = {
	abn: { required: true, abn: true },
	companyName: 'required',
	commencementDate: { required: true, date: true },
	isSubContractor: 'required',
	phone1: 'required',
	address1: 'required',
	city: 'required',
	state: 'required',
	postCode: 'required',
	country: 'required'
};

class CompanyForm extends Component {
	constructor() {
		super();

		this.state = {
			data: { 
				abn: '',
				companyName: '',
				commencementDate: '',
				phone1: '',
				phone2: '',
				fax: '',
				address1: '',
				address2: '',
				city: '',
				state: '',
				postCode: '',
				country: '',
				isSubContractor: false,
				addedFiles: [],
				CompanyDocuments: []
			},
			error: false,
			errorMethod: null,
			errorOnLoadCompanyData: false,
			isConfirmingInactivating: false,
			isInactivating: false,
			isInactivated: false,
			isDeleted: false,
			isLoading: false,
			isSaving: false
		};
	}

	componentDidMount() {
		this.fetchCompanyData();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchCompanyData = () => {
		if (!this.props.abn)
			return;

		this.setState({ 
			isLoading: true, 
			error: false, 
			errorMethod: null, 
			errorOnLoadCompanyData: false 
		});

		CompanyApi.getCompany(this.props.abn, true)
			.then(companyData => {
				if (this.unmounted) return;
				
				this.setState({
					data: { ...this.state.data, ...companyData },
					isLoading: false
				});
			})
			.catch(error => {
				this.setState({
					isLoading: false,
					error,
					errorMethod: this.fetchCompanyData,
					errorOnLoadCompanyData: true
				});
			});
	}

	checkCompanyExists = (abn) => {
		abn = abn.replace(/\s/g,'');
		if (abn.length === 11)
			CompanyApi.checkCompanyExists(abn)
				.then((companyExists) => {
					if (this.unmounted) { return; }

					if (companyExists)
						return window.$('#abn').popover({content: 'This ABN already exists', placement: 'top', trigger: 'manual'}).popover('show').addClass('error');
					else
						return window.$('#abn').popover('hide').removeClass('error');
				});
	}

	checkFileNotAdded = () => {
		var fileNotAdded = false;

		if (!window.$(this.filesForm).hasClass('continueWithoutFiles') && window.$(this.filesForm).find('input').length > 0) {
			fileNotAdded = window.$(this.filesForm).find('input[value!=""]:not(input[type=file]), select > option[value!=""]:selected').length > 0 || window.$(this.filesForm).find('input[type=file]')[0].files.length > 0;
			
			if (fileNotAdded) {
				var popoverContent = 'You filled a file data but didn\'t add it to the files list. If you want to add the file, click on this button or <a id=\'linkContinueSaving\' href=\'#\'>Click Here to continue saving without the file</a>';
				window.$(this.filesForm).find('button').popover({content: popoverContent, placement: 'bottom', trigger: 'manual', 'html': true}).popover('show');
				var self = this;
				window.$(document).on('click', '#linkContinueSaving', function() {
					window.$(this.filesForm).addClass('continueWithoutFiles');
					self.onSubmit(true);
				});
			}
			else 
				window.$(this.filesForm).find('button').popover('hide');
		}

		return fileNotAdded;
	}

	handleAddFile = (files) => {
		return this.handleDataChange({ addedFiles: files });
	}

	handleChangeInput = (e) => {
		var value = e.target.value;

		// Check ABN
		if (e.target.id === 'abn')
			this.checkCompanyExists(value);

		return this.handleDataChange({
			[e.target.id]: value
		});
	}

	handleChangeCheckBox = e => {
		this.handleDataChange({ [e.target.id]: e.target.checked });
	}

	handleChangeFileEditInput = (e, fileId) => {
		let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
		let fieldId = e.target.id; 

		var { CompanyDocuments } = this.state.data;

		// On documents array, find the doc with the 'fileId' and change the value of the 'fieldId'
		CompanyDocuments.filter(x => x.id == fileId)[0][fieldId] = value;

		return this.handleDataChange({ CompanyDocuments });
	}

	onConfirmInactivateCompany = () => {
		if (this.props.abn) {
			this.setState({ isInactivating: true, error: false, errorMethod: null, isConfirmingInactivating: false });

			CompanyApi.inactivateCompany(this.props.abn)
			.then(() => {
				if (this.unmounted) { return; }

				toastr.success('The company was successfully incativated.', 'Success');
				this.props.onInactivate && this.props.onInactivate();
				browserHistory.push('/management/companies');
			})
			.catch((error) => {
				return this.setState({ isInactivating: false, error, errorMethod: this.onConfirmInactivateCompany });
			});
		}
	}

	handleChangeAddress = (completeAddress) => {
		return this.handleDataChange(completeAddress);
	}

	handleDataChange = (newData) => {
		return this.setState({
			error: false,
			data: {
				...this.state.data,
				...newData
			}
		});
	}

	handleInactivateCompany = () => {
		return this.setState({
			isConfirmingInactivating: true
		});
	}

	handleToggleFileDeletedStatus = (fileId) => {
		var { CompanyDocuments } = this.state.data;
		
		// On documents array, find the doc with the 'fileId' and toggle status 'isDeleted';
		let isDeleted = CompanyDocuments.filter(x => x.id == fileId)[0].isDeleted;
		CompanyDocuments.filter(x => x.id == fileId)[0].isDeleted = !isDeleted;

		return this.handleDataChange({ CompanyDocuments });
	}

	onSubmit = () => {
		if (this.checkFileNotAdded())
			return;

		if (window.$(this.form).find('form').valid()) {
			this.setState({ isSaving: true, error: false, errorMethod: null });

			let data = window.$.extend(true, {}, this.state.data);

			//Add new files to the object
			let { addedFiles } = data;
			let qtyAddedFiles = 0;
			let attachedFiles = [];
			for (var i in addedFiles) {
				var documentDetails = { ...omit(addedFiles[i], 'file') };
				documentDetails.dtoFileIndex = qtyAddedFiles++;
				data.CompanyDocuments.push(documentDetails);
				
				attachedFiles.push(addedFiles[i].file);
			}

			delete data.addedFiles; //remove useless object
			data.attachedFiles = attachedFiles; //add the new object with all new attached files
			
			CompanyApi.saveCompanyData(data, this.props.abn)
				.then(() => {
					toastr.success('The data was successfully saved', 'Success!');
					if (this.unmounted) return;

					if (this.props.abn)
						this.setState({ isSaving: false }, this.props.onSave)
					else
						return browserHistory.push('/management/companies');
				})
				.catch(error => {
					console.error(error);
					this.setState({ isSaving: false, error, errorMethod: this.onSubmit});
				});
		}
	}

	render() {
		var s = this.state;
		var p = this.props;

		var filesArray = [];
		if (s.data.CompanyDocuments) {
			s.data.CompanyDocuments.forEach(function(doc) {
				var typeId = doc.typeId;
				var typeName = doc.typeName;

				var isAlreadyAdded = filesArray.map(x => x.typeId).indexOf(typeId) !== -1;
				
				if (!isAlreadyAdded)
					filesArray.push({ typeId, typeName, files: [] });

				var typeIdIndex = filesArray.map(x => x.typeId).indexOf(typeId);
				filesArray[typeIdIndex].files.push(doc);
			});
		}

		var { address1, address2, city, state, postCode, country } = s.data;
		var address = {
			address1,
			address2,
			city,
			state,
			postCode,
			country
		};

		return (
			<div>
				{
					!s.isSaving && !s.isInactivating && (
						<Row>
							<Col xs={4} sm={3} md={2}>
								<FormGroup>
									{
										p.onClickBack ? (
											<Button block bsStyle="danger" onClick={p.onClickBack}>
												<Icon name="arrow-left" /> Back
											</Button>
										) : (
											<Link className="btn btn-block btn-danger" to="/management/companies">
												<Icon name="arrow-left" /> Back
											</Link>
										)
									}
								</FormGroup>
							</Col>
							<Col xs={8} sm={4} md={3}>
								<FormGroup>
									<Button
										block
										type="button"
										bsStyle="success"
										onClick={this.onSubmit}
									>
										<Icon name="save" /> Save
									</Button>
								</FormGroup>
							</Col>
						</Row>
					)
				}
				{
					s.error && <ErrorBox error={s.error} retryFunc={s.errorMethod} />
				}
				{
					!s.errorOnLoadCompanyData && (
						s.isLoading || s.isSaving ? (
							<Loader isLoading={s.isLoading} isSaving={s.isSaving} />
						) : (
							<div>
								{
									s.isConfirmingInactivating ? (
										<ContentBox title="Confirm" icon="warning" color="primary">
											<h3 className="text-center">Do you really want to incativate this company?</h3>
											<br />
											<Row>
												<Col xs={6} sm={4}>
													<Button
														block
														bsStyle="success"
														bsSize="lg"
														onClick={this.onConfirmInactivateCompany}
													>
														<Icon name="check" /> Yes
													</Button>
												</Col>
												<Col xs={6} sm={8}>
													<Button
														block
														bsStyle="danger"
														bsSize="lg"
														onClick={() => this.setState({ isConfirmingInactivating: false, error: false })}
													>
														<Icon name="remove" /> No
													</Button>
												</Col>
											</Row>
										</ContentBox>
									) : (
										s.isInactivating ? (
											<ContentBox title="Inactivating..." color="primary">
												<h3 className="text-center">Inactivating. Please wait...</h3>
												<Loader />
											</ContentBox>
										) : (
											<div ref={ref => this.form = ref}>
												<Form data={{...s.data}} validations={validations}>
													<ContentBox title="About" color="primary">
														<Row>
															<FieldGroup xs={12} sm={6} md={3} label="Is Sub-Contractor?" required>
																<YesNoRadio 
																	value={s.data.isSubContractor || false}
																	onChange={e => this.handleDataChange({ isSubContractor: e.target.value === 'true' })}
																/>
															</FieldGroup>
															<Col xs={12} sm={6} md={3}>
																<FormGroup>
																	<label>Company Name *</label>
																	<FormControl 
																		id="companyName" 
																		value={s.data.companyName} 
																		onChange={this.handleChangeInput}
																	/>
																</FormGroup>
															</Col>
															<Col xs={12} sm={6} md={3}>
																<FormGroup>
																	<label>ABN *</label>
																	<FormControl 
																		id="abn" 
																		value={s.data.abn} 
																		disabled={p.isEditMode} 
																		className="abn"
																		onChange={this.handleChangeInput} 
																	/>
																</FormGroup>
															</Col>
															<Col xs={12} sm={6} md={3}>
																<FormGroup>
																	<label>Commencement Date *</label>
																	<FormControl 
																		id="commencementDate" 
																		type="date" 
																		value={s.data.commencementDate ? moment(s.data.commencementDate).format('YYYY-MM-DD') : ''} 
																		onChange={this.handleChangeInput} 
																	/>
																</FormGroup>
															</Col>
														</Row>
													</ContentBox>
													<ContentBox title="Contact" color="primary">
														<Row>
															<Col sm={4}>
																<FormGroup>
																	<label>Phone 1 *</label>
																	<FormControl 
																		id="phone1" 
																		type="tel" 
																		value={s.data.phone1} 
																		className="phone" 
																		onChange={this.handleChangeInput}
																	/>
																</FormGroup>
															</Col>
															<Col sm={4}>
																<FormGroup>
																	<label>Phone 2</label>
																	<FormControl
																		id="phone2" 
																		type="tel" 
																		value={s.data.phone2} 
																		className="phone" 
																		onChange={this.handleChangeInput} 
																	/>
																</FormGroup>
															</Col>
															<Col sm={4}>
																<FormGroup>
																	<label>FAX</label>
																	<FormControl 
																		id="fax" 
																		type="tel" 
																		value={s.data.fax} 
																		className="phone" 
																		onChange={this.handleChangeInput} 
																	/>
																</FormGroup>
															</Col>
														</Row>
													</ContentBox>
													<ContentBox title="Address" color="primary">
														<CompleteAddressInput address={address} onChange={this.handleChangeAddress}/>
													</ContentBox>
													<ContentBox title="Documents" color="primary">
														<div ref={ref => this.filesForm = ref}>
															<MultipleFilesCompanies files={s.data.addedFiles} maxFileSizeKB={6144} onAddFile={this.handleAddFile}/>
														</div>
													</ContentBox>
													{
														s.data.CompanyDocuments && s.data.CompanyDocuments.length > 0 && (
															<ContentBox title="Edit Uploaded Files" color="success" bordered={true}>
																<div className="nav-tabs-custom">
																	<ul className="nav nav-tabs">
																		{
																			filesArray.map((tab, index) => {
																				return (
																					<li className={index === 0 ? 'active' : ''} key={index}>
																						<a href={('#' + tab.typeId)} data-toggle="tab" aria-expanded="true">{tab.typeName} ({tab.files.length})</a>
																					</li>
																				);
																			})
																		}
																	</ul>
																	<div className="tab-content">
																		{
																			filesArray.map((tab, index) => {
																				return (
																					<div className={('tab-pane ' + (index === 0 ? 'active' : ''))} id={tab.typeId} key={index}>
																						<div className="table-responsive">
																							<table className="table table-bordered table-condensed">
																								<thead>
																									<tr>
																										<th>Type</th>
																										<th>Description</th>
																										<th>Number</th>
																										<th>Expiry Date</th>
																										<th>Archived</th>
																										<th>Delete</th>
																									</tr>
																								</thead>
																								<tbody>
																									{
																										tab.files.map((file, indexFile) => {
																											var {
																												id,
																												typeName,
																												expiryDate,
																												description,
																												number,
																												isArchived,
																												isDeleted
																											} = file;

																											return (
																												<tr key={indexFile} className={classNames({'deleted': isDeleted})}>
																													<td>{ typeName }</td>
																													<td><FormControl id="description" disabled={isDeleted} value={description} onChange={(event) => { this.handleChangeFileEditInput(event, id); }} /></td>
																													<td><FormControl id="number" disabled={isDeleted} value={number} onChange={(event) => { this.handleChangeFileEditInput(event, id); }} /></td>
																													<td>
																														<FormControl 
																															id="expiryDate" 
																															type="date" 
																															disabled={isDeleted} 
																															value={expiryDate ? moment(expiryDate).format('YYYY-MM-DD') : ''} 
																															onChange={(event) => { this.handleChangeFileEditInput(event, id); }}
																														/>
																													</td>
																													<td width="40" className="text-center text-middle">
																														<input id="isArchived" disabled={isDeleted} checked={isArchived} type="checkbox" onChange={(event) => { this.handleChangeFileEditInput(event, id); }} />
																													</td>
																													<td width="40" className="text-center text-middle">
																														{
																															isDeleted ? (
																																<a className="text-success cursor-pointer" title="Undo delete" onClick={() => { this.handleToggleFileDeletedStatus(id);}}>
																																	<Icon name="undo" size="2x" />
																																</a>
																															) : (
																																<a className="text-danger cursor-pointer" title="Delete file" onClick={() => { this.handleToggleFileDeletedStatus(id);}}>
																																	<Icon name="trash" size="2x" />
																																</a>
																															)
																														}
																													</td>
																												</tr>
																											);
																										})
																									}
																								</tbody>
																							</table>
																						</div>
																					</div>
																				);
																			})
																		}
																	</div>
																</div>
															</ContentBox>
														) 
													}
													<Row>
														<Col sm={p.isEditMode ? 8 : 12}>
															<FormGroup>
																<Button
																	block
																	type="button"
																	bsStyle="success"
																	bsSize="lg"
																	onClick={this.onSubmit}
																>
																	<Icon name="save" /> Save
																</Button>
															</FormGroup>
														</Col>
														<Col sm={4}>
															<FormGroup>
																{
																	p.isEditMode && (
																		<Button
																			block
																			bsStyle="danger"
																			bsSize="lg"
																			onClick={this.handleInactivateCompany}
																		>
																			<Icon name="power-off" /> Inactivate Company
																		</Button>
																	)
																}
															</FormGroup>
														</Col>
													</Row>
												</Form>
											</div>
										)
									)
								}
							</div>
						) 
					)
				}
			</div>
		);
	}
}

export default CompanyForm;