import React from 'react';
import moment from 'moment';
import ReactTable, { RowInfo } from 'react-table';
import { IListComponentProps } from '../../../../../app/models/Application';
import { ISmsMessageBulkListItem } from '../../../../../app/models/Communication/SmsMessageBulkModel';
import { getSmsStatus } from '../smsHelper';

const SmsList: React.FC<IListComponentProps<ISmsMessageBulkListItem>> = props => (
	<div id="sms-bulk-list-table">
		<ReactTable
			manual
			minRows={0}
			data={props.records}
			loading={props.isLoading}
			page={props.page}
			pages={props.totalPages}
			pageSizeOptions={props.pageSizeOptions}
			pageSize={props.pageSize}
			onPageChange={props.onPageChange}
			onPageSizeChange={props.onPageSizeChange}
			onSortedChange={props.onSortedChange}
			getTrProps={(state: any, rowInfo?: RowInfo) => {
				if (!rowInfo)
					return {};

				const data = rowInfo.original as ISmsMessageBulkListItem;
				const status = getSmsStatus(data);

				return { className: status.colorClass };
			}}
			getTdProps={(state: any, rowInfo?: RowInfo) => {
				if (!rowInfo)
					return {};

				const data = rowInfo.original as ISmsMessageBulkListItem;
				return {
					onClick: () => props.onSelectRow && props.onSelectRow(data.id, rowInfo.index)
				}
			}}
			columns={[
				{
					Header: 'ID',
					accessor: 'id',
					sortable: false,
					width: 50
				},
				{
					Header: 'Subject',
					accessor: 'subject',
					sortable: false,
					width: 150
				},
				{
					Header: 'Message',
					accessor: 'message',
					width: 300,
					sortable: false,
					Cell: row => <span title={row.value}>{row.value}</span>
				},
				{
					Header: 'Created By',
					accessor: 'createdByName',
					width: 150
				},
				{
					Header: 'Created At',
					accessor: 'createdOn',
					width: 130,
					Cell: row => moment.utc(row.value).local().format('DD/MM/YYYY HH:mm'),
				},
				{
					Header: 'Scheduled Time',
					accessor: 'scheduledToSendAt',
					width: 130,
					Cell: row => row.value ? moment.utc(row.value).local().format('DD/MM/YYYY HH:mm') : '-',
				},
				{
					Header: 'Sent At',
					accessor: 'sentAt',
					width: 130,
					Cell: row => row.value ? moment.utc(row.value).local().format('DD/MM/YYYY HH:mm') : '-',
				},
				{
					Header: 'Recipients',
					accessor: 'totalRecipients',
					className: 'text-bold',
					sortable: false,
					width: 80
				},
				{
					Header: 'Sent',
					accessor: 'totalSent',
					className: 'text-primary text-bold',
					sortable: false,
					width: 80
				},
				{
					Header: 'Delivered',
					accessor: 'totalDelivered',
					sortable: false,
					className: 'text-success text-bold',
					width: 80
				},
				{
					Header: 'Failed',
					accessor: 'totalFailed',
					className: 'text-danger text-bold',
					sortable: false,
					width: 80
				},
				{
					Header: 'Status',
					id: 'isCompleted',
					accessor: p => p,
					Cell: row => {
						const data = row.original as ISmsMessageBulkListItem;
						const status = getSmsStatus(data);
						
						return (
							<b className={`text-${status.colorClass}`}>
								{status.text}
							</b>
						)
					}
				},
			]}
		/>
	</div>
);

export default SmsList;