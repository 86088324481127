import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from 'react-fontawesome';

import { Row, Col, FormGroup, Tab, Nav, NavItem } from 'react-bootstrap';
import driverLicenceTypes from '../../../../../app/store/DriverLicenceTypes.json';
import { ContentBox, UserProfilePicture } from '../../../../common/uiElements';

import WorkerStatus from '../forms/WorkerStatus';
import WorkerResetPassword from '../forms/WorkerResetPassword';
import WorkerSendPin from '../forms/WorkerSendPin';

const propTypes = {
	azureId: PropTypes.string,
	firstName: PropTypes.string,
	surname: PropTypes.string,
	dob: PropTypes.string,
	email: PropTypes.string,
	azureEmail: PropTypes.string,
	shortName: PropTypes.string,
	currentCompanyName: PropTypes.string,
	currentLicenceType: PropTypes.string,
	currentRoleName: PropTypes.string,
	isPictureChangeable: PropTypes.bool,
	isPasswordResetEnabled: PropTypes.bool,
	WorkerPhones: PropTypes.array,
	WorkerEmergencyContacts: PropTypes.array,
	WorkerStatus: PropTypes.array
};

const WorkerViewSimpleDetails = props => {
	const renderCurrentDriverLicenceDescription = () => {
		const driverLicenceType = driverLicenceTypes.find(d => d.value === props.currentLicenceType);
		return driverLicenceType ? driverLicenceType.text : '-';
	};

	return (
		<div>
			<Row>
				<Col sm={3}>
					<ContentBox color="primary">
						<h3 className="profile-username text-center">{ props.shortName }</h3>
						<UserProfilePicture 
							disableCache 
							azureId={props.azureId}
							isPictureChangeable={props.isPictureChangeable && props.azureEmail} 
							className="profile-user-img img-responsive" 
						/>
						<h5 className="text-muted text-center">
							<label>Current Role</label>
							<p>{ props.currentRoleName || '-' }</p>
						</h5>
						<br className="visible-xs" />
					</ContentBox>
					{
						props.azureEmail && (
							<ContentBox hideOnPrint color="primary" title="Authentication">
								<FormGroup>
									<WorkerSendPin azureId={props.azureId} />
								</FormGroup>
								{ 
									props.isPasswordResetEnabled && (
										<FormGroup>
											<WorkerResetPassword azureId={props.azureId} /> 
										</FormGroup>
									)
								}
							</ContentBox>
						)
					}
				</Col>
				<Col sm={9}>
					<Tab.Container id="workerDetails" defaultActiveKey="general" className="nav-tabs-custom">
						<div>
							<Nav bsStyle="tabs">
								<NavItem eventKey="general">
									<Icon name="user" />
									<span className="hidden-xs">&nbsp;General</span>
								</NavItem>
								<NavItem eventKey="contacts">
									<Icon name="phone" />
									<span className="hidden-xs">&nbsp;Contacts</span>
								</NavItem>
								<NavItem eventKey="status">
									<Icon name="tasks" />
									<span className="hidden-xs">&nbsp;Roles History</span>
								</NavItem>
							</Nav>
							<Tab.Content animation={false}>
								<Tab.Pane eventKey="general">
									<Row>
										<Col sm={6}>
											<FormGroup>
												<label>First Name</label>
												<p>{ props.firstName }</p>
											</FormGroup>
										</Col>
										<Col sm={6}>
											<FormGroup>
												<label>Last Name</label>
												<p>{ props.surname }</p>
											</FormGroup>
										</Col>
										<Col sm={6}>
											<FormGroup>
												<label>Date of Birth</label>
												<p>{ moment(props.dob).format('DD/MM/YYYY') }</p>
											</FormGroup>
										</Col>
										<Col sm={6}>
											<FormGroup>
												<label>Login</label>
												<p>{ props.azureEmail || '-' }</p>
											</FormGroup>
										</Col>
										<Col sm={6}>
											<FormGroup>
												<label>Email</label>
												<p><a href={`mailto:${props.email}`}>{ props.email || '-' }</a></p>
											</FormGroup>
										</Col>
										<Col sm={6}>
											<FormGroup>
												<label>Phone</label>
												<p>
													{
														props.WorkerPhones.length === 0 ? '-' : (
															<a href={`tel:${props.WorkerPhones[0].phoneNumber}`}>{ props.WorkerPhones[0].phoneNumber }</a>
														)
													}
												</p>
											</FormGroup>
										</Col>
										<Col sm={6}>
											<FormGroup>
												<label>Current Company</label>
												<p>{ props.currentCompanyName || '-' }</p>
											</FormGroup>
										</Col>
										<Col sm={6}>
											<FormGroup>
												<label>Current Licence Type</label>
												<p>{ renderCurrentDriverLicenceDescription() }</p>
											</FormGroup>
										</Col>
									</Row>
								</Tab.Pane>
								<Tab.Pane eventKey="contacts">
									<Row>
										{
											props.WorkerPhones.map((phone, index) => {
												return (
													<Col key={index} sm={4}>
														<FormGroup>
															<label>
																<Icon name={phone.isMobile ? 'mobile' : 'phone'} /> {phone.isMobile ? 'Mobile' : 'Landline'}
															</label>
															<p>
																<a href={`tel:${phone.phoneNumber}`}>{ phone.phoneNumber }</a>
															</p>
														</FormGroup>
													</Col>
												);
											})
										}
									</Row>
									<h3 className="title">Emergency Contacts</h3>
									{
										!props.WorkerEmergencyContacts || props.WorkerEmergencyContacts.length === 0 ? (
											<p><i>No emergency contact has been registered.</i></p>
										) : (
											props.WorkerEmergencyContacts.map((contact, index) => {
												return (
													<Row key={index}>
														<Col sm={3}>
															<FormGroup>
																<label>Name</label>
																<p>{ contact.name }</p>
															</FormGroup>
														</Col>
														<Col sm={3}>
															<FormGroup>
																<label>Relationship</label>
																<p>{ contact.relationship }</p>
															</FormGroup>
														</Col>
														<Col sm={3}>
															<FormGroup>
																<label>Phone 1</label>
																<p>{ contact.phone1 }</p>
															</FormGroup>
														</Col>
														<Col sm={3}>
															<FormGroup>
																<label>Phone 2</label>
																<p>{ contact.phone2 || '-' }</p>
															</FormGroup>
														</Col>
													</Row>
												);
											})
										)
									}
								</Tab.Pane>
								<Tab.Pane eventKey="status">
									<Row>
										<Col xs={12}>
											<WorkerStatus hideWorkerLeftReason={props.hideWorkerLeftReason} readOnly={true} data={ props.WorkerStatus } />
										</Col>
									</Row>
								</Tab.Pane>
							</Tab.Content>
						</div>
					</Tab.Container>
				</Col>
			</Row>
		</div>
	);
};

WorkerViewSimpleDetails.propTypes = propTypes;

export default WorkerViewSimpleDetails;