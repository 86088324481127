import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col, FormGroup } from 'react-bootstrap';

import { DatePeriodInput, VehicleSearch, RadioButtonList } from '../../../../common/inputs';

const propTypes = {
	filterBy: PropTypes.string,
	startTime: PropTypes.string,
	endTime: PropTypes.string,
	vehicle: PropTypes.string,
	isFixed: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.string,
	]),
	onChange: PropTypes.func,
	onPressEnter: PropTypes.func
};

const VehicleDamagesListFilter = props => {
	const handleChange = (e) => {
		const { id, type, checked, value } = e.target;
		var val = type === 'checkbox' ? checked : value;
		props.onChange && props.onChange({ [id]: val });
	};

	const handleChangeVehicle = ({ vehFleetNumber }) => {
		props.onChange && props.onChange({ vehicle: vehFleetNumber || '' });
	};

	const handleChangePeriod = (startTime, endTime) => {
		startTime = startTime.format('YYYY-MM-DD');
		endTime = endTime.format('YYYY-MM-DD');
		props.onChange && props.onChange({ startTime, endTime });
	};

	const statusOptions = [
		{ text: 'All', value: '' },
		{ text: 'Open', value: false },
		{ text: 'Fixed', value: true }
	];

	return (
		<div>
			<Row>
				<Col sm={6} md={4} lg={3}>
					<FormGroup>
						<RadioButtonList 
							id="filterBy"
							value={props.filterBy}
							items={[
								{ text: 'By Open Date', value: 'openDate' },
								{ text: 'All', value: 'all' }
							]} 
							onChange={handleChange} 
						/>
						<div className={props.filterBy !== 'openDate' ? 'hidden' : undefined} style={{marginTop: '-17px'}}>
							<DatePeriodInput 
								startDate={moment(props.startTime)}
								endDate={moment(props.endTime)}
								showDefaultRanges={true} 
								showHours={false}
								format="DD/MM/YYYY"
								onChange={handleChangePeriod} />
						</div>
					</FormGroup>
				</Col>
				<Col sm={6} md={3} lg={3}>
					<FormGroup>
						<label>Vehicle</label>
						<VehicleSearch id="vehicle" value={props.vehicle} onChange={handleChangeVehicle} />
					</FormGroup>
				</Col>
				<Col sm={12} md={5} lg={6}>
					<FormGroup>
						<label>Status</label>
						<RadioButtonList id="isFixed" items={statusOptions} onChange={handleChange} value={props.isFixed} />
					</FormGroup>
				</Col>
			</Row>
		</div>
	);
};

VehicleDamagesListFilter.propTypes = propTypes;

export default VehicleDamagesListFilter;