import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';

import { Row, Col, Button, FormGroup, FormControl } from 'react-bootstrap';
import PreStartApi from '../../../../../app/api/PreStartApi';

import { RadioButtonList } from '../../../../common/inputs';
import { Form, Loader, ErrorBox } from '../../../../common/uiElements';

const propTypes = {
	/** Azure Id of the person that is approving */
	whoIsApprovingId: PropTypes.string,

	data: PropTypes.object,

	/** When the user submit the checkout */
	onSubmitCheckout: PropTypes.func
};

class CheckoutApproval extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkOutComments: '',
			checkOutIsApproved: '',
			lastAcknowledgeComments: '',
			isSaving: false,
			error: null
		};

		this.approvalValidations = {
			checkOutIsApproved: 'required',
			checkOutComments: 'required'
		};
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	submitCheckout = () => {
		const isLastPreStartReport = this.props.data.lastReportedOnLocal ? true : false;
		
		let form = window.$(this.approvalForm).find('form');
		if (form && form.valid && !form.valid())
			return;

		const preStartId = this.props.data.id;
		const checkOutById = this.props.whoIsApprovingId;

		const lastAcknowledgeById = checkOutById;
		const { checkOutComments, checkOutIsApproved, lastAcknowledgeComments } = this.state;
	
		this.setState({ isSaving: true, error: null });
		let savePromise;
		if(isLastPreStartReport)
			savePromise = PreStartApi.acknowledgePreStart(preStartId, lastAcknowledgeById, lastAcknowledgeComments);
		else
			savePromise = PreStartApi.checkoutPreStart(preStartId, checkOutById, checkOutIsApproved, checkOutComments);

		savePromise.then(() => {
			return this.props.onSubmitCheckout();
		})
			.catch(error => {
				console.error(error);
				this.setState({ error, isSaving: false });
			});
	}

	render() {
		let s = this.state;
		const isLastPreStartReport = this.props.data.lastReportedOnLocal ? true : false;

		if (s.error)
			return <ErrorBox error={s.error} retryFunc={this.submitCheckout} />;
		
		if (s.isSaving)
			return (
				<div className="text-center">
					<h4>Saving the data. Please wait...</h4>
					<Loader />
				</div>
			);

		return (
			<div ref={ref => this.approvalForm = ref}>
				<h3 className="box-title">{ isLastPreStartReport ? 'FINISHING' : 'APPROVAL' }</h3>
				{
					isLastPreStartReport ? (
						<Form validations={this.approvalValidations}>
							<Row>
								<Col xs={12}>
									<FormGroup>
										<label>Comment</label>
										<FormControl
											id="lastAcknowledgeComments"
											onChange={(e) => { this.setState({ lastAcknowledgeComments: e.target.value }); }}
											value={s.lastAcknowledgeComments}
											placeholder="Type your commentary here..."
											componentClass="textarea"
											rows={4}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<FormGroup>
										<Button bsStyle="success" block onClick={this.submitCheckout}>
											<Icon name="send" /> Submit
										</Button>
									</FormGroup>
								</Col>
							</Row>
						</Form>
					) : (
						<Form validations={this.approvalValidations}>
							<Row>
								<Col xs={12}>
									<FormGroup>
										<label>Is this Pre-Start approved? *</label>
										<RadioButtonList 
											id="checkOutIsApproved" 
											value={s.checkOutIsApproved}
											items={[{ text: 'Yes', value: true }, { text: 'No', value: false }]} 
											onChange={(e, value) => { this.setState({ checkOutIsApproved: value }); }}
										/>
									</FormGroup>
											
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<FormGroup>
										<label>Comment</label>
										<FormControl
											id="checkOutComments"
											onChange={(e) => { this.setState({ checkOutComments: e.target.value }); }}
											value={s.checkOutComments}
											placeholder="Type your commentary here..."
											componentClass="textarea"
											rows={4}
											className={s.checkOutIsApproved ? 'ignoreValidation' : undefined}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<FormGroup>
										<Button bsStyle="success" block onClick={this.submitCheckout}>
											<Icon name="send" /> Submit
										</Button>
									</FormGroup>
								</Col>
							</Row>
						</Form>
					)
				}
			</div>
		);
	}
}

CheckoutApproval.propTypes = propTypes;

export default CheckoutApproval;