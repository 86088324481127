import React, { Fragment } from 'react';
import { MainContent } from '../../../common/layout';
import StaffRosterList from './list';
import StaffRosterListFilter from './list/filters/ListFilter';
import StaffRosterMainFilter from './list/filters/MainFilter';
import StaffRosterWeekDaysFilter from './list/filters/WeekDaysFilter';
import { Button, FormGroup } from 'react-bootstrap';
import Icon from 'react-fontawesome';
import { Callout, ErrorBox } from '../../../common/uiElements';
import componentRequestHandler from '../../../../app/api/helpers/componentRequestHandler';
import RosterApi from '../../../../app/api/RosterApi';
import { StaffRosterListMainFilter, StaffRosterListFilter as StaffRosterListFilterClass, IStaffRosterListItem } from '../../../../app/models/StaffRoster';
import { IError, IReactSelectOption } from '../../../../app/models/Application';
import StaffRosterModal from './StaffRosterModal';
import { filterStaffRosterList } from './staffRosterHelper';
import moment from 'moment';

interface IState {
	staffRosterList: IStaffRosterListItem[];
	filteredStaffRosterList: IStaffRosterListItem[];
	selectedRosterId?: number;
	selectedRowIndex?: number;
	mainFilter: StaffRosterListMainFilter;
	listFilter: StaffRosterListFilterClass;
	selectedPlaceName: string;
	showRosterModal: boolean;
	showUpdateReasonModal: boolean;
	isLoading: boolean;
	error?: IError;
}

class StaffRoster extends React.Component<{}, IState> {
	constructor(props: any) {
		super(props);
		this.state = {
			staffRosterList: [],
			filteredStaffRosterList: [],
			mainFilter: new StaffRosterListMainFilter(),
			listFilter: new StaffRosterListFilterClass(),
			selectedPlaceName: '',
			showRosterModal: false,
			showUpdateReasonModal: false,
			isLoading: false,
			error: undefined
		};
	}

	componentDidMount() {
		this.fetchStaffRosterList();
	}

	fetchStaffRosterList = () => {
		const { startDate, finishDate, startingDepotId, filterByEmployee, workerId } = this.state.mainFilter;

		if ((!startDate || !finishDate) || (filterByEmployee && !workerId))
			return this.clearStaffRosterList();

		const startingDepotIdParam = startingDepotId ? parseInt(startingDepotId) : undefined;
		const startDateTime = `${startDate} 00:00`;
		const finishDateTime = `${finishDate} 23:59`;

		const promise = () => RosterApi.getRosterList(startDateTime, finishDateTime, true, startingDepotIdParam, workerId);
		componentRequestHandler(this, promise, 'staffRosterList')
			.then(this.filterList);
	}

	filterList = () => {
		const { staffRosterList, listFilter, mainFilter } = this.state;
		const filteredStaffRosterList = filterStaffRosterList(staffRosterList, listFilter, mainFilter.startingDepotId, mainFilter.startDate, mainFilter.finishDate);
		this.setState({ filteredStaffRosterList });
	}

	clearStaffRosterList = () => {
		this.setState({
			staffRosterList: [],
			filteredStaffRosterList: []
		});
	}

	clearListFilter = () => {
		this.setState({ 
			listFilter: new StaffRosterListFilterClass()
		}, this.filterList);
	}

	showRosterModal = () => {
		this.setState({ showRosterModal: true });
	}

	closeRosterModal = () => {
		this.setState({ 
			showRosterModal: false,
			selectedRosterId: undefined
		});
	}

	closeUpdateReasonModal = () => {
		this.setState({ showUpdateReasonModal: false });
	}

	showUpdateReasonModal = () => {
		this.setState({ 
			showUpdateReasonModal: false
		});
	}

	handleChangeYear = (year: number) => {
		this.setState({
			mainFilter: {
				...this.state.mainFilter,
				year,
				week: moment().year() !== year ? 1 : moment().isoWeek(),
				startDate: '',
				finishDate: ''
			},
		}, this.clearStaffRosterList);
	}

	handleChangeWeek = (week: number) => {
		this.setState({
			mainFilter: {
				...this.state.mainFilter,
				week,
				startDate: '',
				finishDate: ''
			},
		}, this.clearStaffRosterList);
	}

	handleChangeDepot = (startingDepotId?: string, startingDepotName?: string) => {
		this.setState({
			mainFilter: {
				...this.state.mainFilter,
				startingDepotName,
				startingDepotId
			}
		}, this.filterList);
	}

	handleToggleFilterBy = () => {
		this.setState({
			mainFilter: {
				...new StaffRosterListMainFilter(),
				filterByEmployee: !this.state.mainFilter.filterByEmployee
			}
		}, () => {
			if (this.state.mainFilter.filterByEmployee)
				this.clearStaffRosterList();
			else
				this.fetchStaffRosterList()
		});
	}

	handleChangeWorkerFilter = (worker?: IReactSelectOption) => {
		this.setState({
			mainFilter: {
				...this.state.mainFilter,
				workerId: worker?.value
			}
		}, () => { 
			if(worker)
				this.fetchStaffRosterList();
			else
				this.clearStaffRosterList();
		});
	}

	handleChangeStartFinishDate = (startDate: string, finishDate: string) => {
		this.setState({ 
			mainFilter: {
				...this.state.mainFilter,
				startDate,
				finishDate
			}
		});
	}

	handleChangeWeekDayDate = (date: string) => {
		this.setState({
			mainFilter: {
				...this.state.mainFilter,
				startDate: date,
				finishDate: date
			},
			listFilter: new StaffRosterListFilterClass()
		}, this.fetchStaffRosterList);
	}

	handleChangeListFilter = (key: string, value: number | string) => {
		this.setState({
			listFilter: {
				...this.state.listFilter,
				[key]: value
			}
		}, this.filterList);
	}

	handleClearListFilter = () => {
		this.setState({ 
			listFilter: new StaffRosterListFilterClass()
		}, this.filterList);
	}

	handleSelectRoster = (selectedRosterId: number) => {
		this.setState({
			selectedRosterId
		}, this.showRosterModal);
	}
	
	handleAddNewRoster = () => {
		this.setState({ 
			selectedRowIndex: undefined, 
			selectedRosterId: undefined 
		}, this.showRosterModal);
	}

	handleSaveComplete = (date: string) => {
		this.setState({
			showRosterModal: false,
			mainFilter: {
				...this.state.mainFilter,
				startDate: date,
				finishDate: date
			}
		}, this.fetchStaffRosterList);
	}
	
	render() {
		const { state } = this;
		const { 
			year, week, startingDepotId, startDate,
			filterByEmployee, workerId
		} = state.mainFilter;

		return (
			<MainContent title="Staff Roster" className="staff-roster">
				{
					state.showRosterModal && (
						<StaffRosterModal 
							show
							rosterId={state.selectedRosterId}
							initialDate={startDate}
							initialStartingDepotId={startingDepotId}
							onClose={this.closeRosterModal}
							onSaveComplete={this.handleSaveComplete}
						/>
					)
				}
				<FormGroup>
					<Button
						block
						bsStyle="success"
						onClick={this.handleAddNewRoster}
					>
						<Icon name="plus" /> Add New Roster
					</Button>
				</FormGroup>
				<hr />
				<StaffRosterMainFilter
					{...state.mainFilter}
					onChangeYear={this.handleChangeYear}
					onChangeWeek={this.handleChangeWeek}
					onChangeDepot={this.handleChangeDepot}
					onToggleFilterBy={this.handleToggleFilterBy}
					onChangeWorker={this.handleChangeWorkerFilter}
					onChangeStartFinishDate={this.handleChangeStartFinishDate}
				/>
				{
					filterByEmployee ? null : (
						(!filterByEmployee && (!year || !week)) ? (
							<Callout icon="info-circle" color="info" title="Select the Year and Week" />
						) : (
							<FormGroup>
								<StaffRosterWeekDaysFilter 
									date={startDate}
									year={year}
									weekNumber={week}
									onChange={this.handleChangeWeekDayDate}
								/>
							</FormGroup>
						)
					)
				}
				{
					!filterByEmployee && !startDate ? (
						week && year ? <Callout icon="info-circle" color="info" title="Select the day of the week" /> : null
					) : (
						filterByEmployee && !workerId ? (
							<Callout icon="info-circle" color="info" title="Select the employee" />
						) : (
							<Fragment>
								{
									!filterByEmployee && (
										<StaffRosterListFilter 
											{ ...state.listFilter }
											staffRosterList={state.staffRosterList}
											onChange={this.handleChangeListFilter}
											onClear={this.clearListFilter}
										/>
									)
								}
								<FormGroup>
									<Button
										block
										bsStyle="info"
										onClick={this.fetchStaffRosterList}
									>
										{
											filterByEmployee ? (
												<Fragment>
													<Icon name="search" /> Search
												</Fragment>
											) : (
												<Fragment>
													<Icon name="refresh" /> Refresh
												</Fragment>
											)
										}
									</Button>
								</FormGroup>
								{
									state.error ? (
										<ErrorBox error={state.error} retryFunc={this.fetchStaffRosterList} />
									)	: (
										<StaffRosterList 
											staffRosterList={state.filteredStaffRosterList}
											isLoading={state.isLoading}
											onSelectRoster={this.handleSelectRoster}
										/>
									)
								}
							</Fragment>
						)
					)
				}
			</MainContent>
		);
	}
}

export default StaffRoster;