import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { ErrorBox, Loader } from '../../../../common/uiElements';
import WorkersListForSelection from './WorkersListForSelection';
import ListsApi from '../../../../../app/api/ListsApi';
import { WorkerListItemWithExtraData } from '../../../../../app/models/Worker';

interface IProps {
	selectedWorkerIds: string[];
	onClose: () => void;
	onSave: (selectedWorkersList: WorkerListItemWithExtraData[]) => void;
}

const WorkersListSelectionModal: React.FC<IProps> = props => {
	const [workersList, setWorkersList] = useState<WorkerListItemWithExtraData[]>([]);
	const [selectedWorkersList, setSelectedWorkersList] = useState<WorkerListItemWithExtraData[]>([]);
	const [onlyDrivers, setOnlyDrivers] = useState<boolean | undefined>(undefined);
	const [isLoading, setIsLoading] = useState(false);
	const [errorLoading, setErrorLoading] = useState(undefined);

	const fetchData = useCallback(() => {
		setIsLoading(true);
		setErrorLoading(undefined);
		
		ListsApi.getWorkersListWithExtraData()
		.then(workersList =>{
			setWorkersList(workersList);

			// If some workers are selected, select them
			const selectedWorkers = workersList.filter(worker => props.selectedWorkerIds.find(id => id === worker.id) !== undefined);
			setSelectedWorkersList(selectedWorkers);
		})
		.catch(setErrorLoading)
		.finally(() => setIsLoading(false));
	}, [props.selectedWorkerIds])

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const handleSelectAll = () => {
		if (selectedWorkersList.length === workersList.length)
			setSelectedWorkersList([]);
		else
			setSelectedWorkersList(workersList);
	}

	const handleSelectWorker = (selectedWorkerId: string) => {
		if (selectedWorkersList.find(worker => worker.id === selectedWorkerId) !== undefined)
			return setSelectedWorkersList(selectedWorkersList.filter(worker => worker.id !== selectedWorkerId));
		
		const selectedWorker = workersList.find(worker => worker.id === selectedWorkerId);

		if (selectedWorker)
			setSelectedWorkersList([...selectedWorkersList, selectedWorker]);
	}

	const handleSelectOnlyDrivers = (onlyDrivers: boolean) => {
		setOnlyDrivers(onlyDrivers);

		if (!onlyDrivers)
			return setSelectedWorkersList(workersList);

		const drivers = workersList.filter(worker => worker.currentRoleName?.toLowerCase().indexOf('driver') !== -1);
		setSelectedWorkersList(drivers);
	}

	const handleSave = () => {
		props.onSave(selectedWorkersList);
	}

	return (
		<Modal
			show
			id="sms-bulk-worker-selection-modal"
			bsSize="lg"
			backdrop="static"
			onHide={props.onClose}
			onEscapeKeyDown={props.onClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					Select Workers
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{
					errorLoading ? (
						<ErrorBox retryFunc={fetchData} error={errorLoading} />
					) : (
						isLoading ? (
							<Loader isLoading />
						) : (
							<>
								<Row>
									<Col sm={6}>
										<Button block bsStyle={onlyDrivers != undefined && !onlyDrivers ? 'success' : 'default'} onClick={() => handleSelectOnlyDrivers(false)}>
											All Workers
										</Button>
									</Col>
									<Col sm={6}>
										<Button block bsStyle={onlyDrivers != undefined && onlyDrivers ? 'success' : 'default'} onClick={() => handleSelectOnlyDrivers(true)}>
											Only Drivers
										</Button>
									</Col>
								</Row>
								<br />
								<div style={{ maxHeight: '50vh', overflow:'auto' }}>
									<WorkersListForSelection
										selectedWorkerIds={selectedWorkersList.map(worker => worker.id)} 
										workersList={workersList}
										onSelectAll={handleSelectAll}
										onSelectWorker={handleSelectWorker}
									/>
								</div>
								<h4>
									<b>Total Selected: {selectedWorkersList.length} / {workersList.length}</b>
								</h4>
							</>
						)
					)
				}
			</Modal.Body>
					<Modal.Footer>
						{
							!isLoading && (
								<Button style={{ width: 200 }} bsStyle="success" className="pull-left" onClick={handleSave}>
									Save
								</Button>
							)
						}
						<Button onClick={props.onClose}>
							Cancel
						</Button>
					</Modal.Footer>
		</Modal>
	)
}

export default WorkersListSelectionModal;