import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { IPallet, PalletDetailsForm as PalletDetailsFormClass } from '../../../../../../app/models/Pallet';
import FontAwesome from 'react-fontawesome';
import PalletDetailsForm from './PalletDetailsForm';
import $ from'jquery';

interface IProps {
	pallet: IPallet;
	palletsList?: IPallet[];
	isReadOnly?: boolean;
	onClose: () => void;
	onSave: (pallet: PalletDetailsFormClass) => void;
}

const PalletDetailsModal: React.FC<IProps> = (props) => {
	const [pallet, setPallet] = useState(props.pallet);

	const handleChangeInput = (id: string, value?: string) => {
		setPallet({ ...pallet, [id]: value });
	}

	const handleChangeStore = (store: any) => {
		setPallet({ ...pallet, store });
	}

	const handleChangeConsolidationType = (wasPalletTimberKept: boolean) => {
		setPallet({ ...pallet, wasPalletTimberKept });
	}

	const handleSaveConsolidatedPallet = (consolidatedPallet: IPallet) => {
		const { isUpstackedWith } = pallet;
		if (!isUpstackedWith) return;
		
		const palletIndex = isUpstackedWith.findIndex(p => p.palletId === consolidatedPallet.palletId);

		if (palletIndex === -1)
			return;

		isUpstackedWith[palletIndex] = { ...consolidatedPallet };
		setPallet({ ...pallet, isUpstackedWith });
	}

	const handleSave = () => {
		if (!$('#pallet-details-form').valid())
			return;

		props.onSave(pallet);
	}

	return (
		<>
			<Modal
				show
				id="pallet-details-modal"
				onHide={props.onClose}
				onEscapeKeyDown={props.onClose}
			>
				<Modal.Header closeButton>
					<Modal.Title> Pallet Details</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<PalletDetailsForm
						isConsolidatedPallet={pallet.isUpstacked && !pallet.isUpstackedWith}
						isReadOnly={props.isReadOnly}
						pallet={pallet}
						palletsList={props.palletsList}
						onChangeInput={handleChangeInput}
						onChangeStore={handleChangeStore}
						onChangeConsolidationType={handleChangeConsolidationType}
						onSaveConsolidatedPallet={handleSaveConsolidatedPallet}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button 
						bsStyle="success"
						className="pull-left"
						bsSize="lg"
						onClick={handleSave}
					>
						<FontAwesome name="check" /> Save Changes
					</Button>
					<Button bsSize="lg" onClick={props.onClose}>
						<FontAwesome name="sign-out"/> Close Without Saving
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default PalletDetailsModal;