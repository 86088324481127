import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';

import { Modal, Button } from 'react-bootstrap';
import VehicleIcons from '../../../../app/schemas/vehicles/VehicleIcons';
import { Callout } from '../../../common/uiElements';
import { BatteryChargesList } from '../../../common/tracking';
import { GoogleMapContainer, GoogleMarker } from '../../../common/googleMaps';

const propTypes = {
	fleetNumber: PropTypes.string, 
	lastPingLatitude: PropTypes.number, 
	lastPingLongitude: PropTypes.number,
	onClose: PropTypes.func
};

class ModalPalletJackDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inactiveTimer: 60
		};
	}

	componentWillUnmount() {
		this.clearInactiveTimer();
	}
	
	UNSAFE_componentWillMount() {
		if (this.props.onClose)
			this.startInactiveTimer();
	}

	startInactiveTimer = () => {
		this.clearInactiveTimer();
		this.inactiveTimer = setInterval(() => {
			this.setState({ inactiveTimer: this.state.inactiveTimer - 1 });
			if (this.state.inactiveTimer === 0) {
				this.clearInactiveTimer();
				this.props.onClose();
			}
		}, 1000);    
	}

	clearInactiveTimer = () => {
		this.setState({ inactiveTimer: 60 });
		window.clearInterval(this.inactiveTimer);
	}
	
	render() {
		const s = this.state;
		const p = this.props;

		const palletJackMapMarker = (p.lastPingLatitude && p.lastPingLongitude) && { 
			position: { lat: p.lastPingLatitude, lng: p.lastPingLongitude  },
			label: {
				text: p.fleetNumber
			},
			icon: {
				url: VehicleIcons.PALLET_JACK
			}
		};

		return (
			<Modal bsSize="lg" show={p.fleetNumber ? true : false} onHide={p.onClose}>
				<Modal.Header closeButton>
					<Modal.Title>
							Pallet Jack Details
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						p.fleetNumber && (
							<div onClick={this.startInactiveTimer}>
								{
									s.inactiveTimer < 15 && (
										<Callout>This box will close automatically in <b>{s.inactiveTimer} seconds</b>.</Callout>
									)
								}
								<h3 className="title">
									<Icon fixedWidth name="map-marker" /> Last Ping Location
								</h3>
								{
									palletJackMapMarker ? (
										<GoogleMapContainer
											defaultZoom={15}
											defaultCenter={palletJackMapMarker.position}
											height="230px"
										>
											{
												<GoogleMarker { ...palletJackMapMarker } />
											}
										</GoogleMapContainer>
									) : (
										<h3>It was not possible to retrieve the last ping location</h3>
									)
								}
								<h3 className="title">
									<Icon fixedWidth name="plug" /> Last 6 charges
								</h3>
								<BatteryChargesList vehFleetNumber={p.fleetNumber} showFleet={false} fetchWithPeriod={false} />
							</div>
						)
					}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={p.onClose} bsStyle="default">Close</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

ModalPalletJackDetails.propTypes = propTypes;

export default ModalPalletJackDetails;