import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';

import { Signature } from '../../../../../common/inputs';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';

const propTypes = {
	id: PropTypes.string,
	data: PropTypes.object,
	onChange: PropTypes.func,
	readOnly: PropTypes.bool,
	onCommenceTrip: PropTypes.func,
	addGuideSteps: PropTypes.func
};

const defaultProps = {
	id: 'TermsAndAgreement',
	readOnly: false,
	data: {
		signatureFile: null
	}
};

const guideSteps = [
	{
		title: 'Final Step - Terms & Agreement',
		text: 'You are required to make a declaration at this stage',
		selector: '.pre-start__terms-and-agreement',
		position: 'top'
	},
	{
		title: 'Signature',
		text: 'You must sign here',
		selector: '.pre-start__signature',
		position: 'top'
	},
	{
		title: 'Commence the trip',
		text: 'Once you have completed, you will be able to Submit your Pre-Start',
		selector: '.pre-start__commence-trip',
		position: 'top',
		goToWaitingApproval: true
	}
];

class TermsAndAgreement extends Component {
	componentDidMount() {
		this.props.addGuideSteps && this.props.addGuideSteps(guideSteps);
	}
	
	handleChangeInput = (e, value) => {
		var fieldId = e.target.id;
		value = e.target.type === 'checkbox' ? e.target.checked : (typeof value !== 'undefined' ? value : e.target.value);

		let data = {
			...this.props.data,
			[fieldId]: value
		};

		if (this.props.onChange)
			this.props.onChange(data, this.props.id);
	}

	handleChangeSignature = (signatureFile) => {
		if (signatureFile)
			window.$('#signatureError').hide();

		let data = {
			...this.props.data,
			signatureFile
		};

		if (this.props.onChange)
			this.props.onChange(data, this.props.id);
	}

	handleCommenceTrip = () => {
		if (!this.props.data.signatureFile)
			return window.$('#signatureError').show();
		else
			return this.props.onCommenceTrip();
	}

	render() {
		return (
			<div className="pre-start__terms-and-agreement">
				<h3 className="title">
					<Icon name="check-square-o" /> Terms & Agreement
				</h3>
				<Row>
					<Col xs={12} className="pre-start__terms">
						<h4>
							<b>
								As a professional driver I am aware of my responsibilities to drive within 
								all relevant road rules and to adhere to all Micway Transport Policies & Procedures. I agree that I will;
							</b>
						</h4>
						<ul className="list">
							<li>Adhere to all road rules</li>
							<li>Comply with all speed limits</li>
							<li>Wear a seatbelt at all times</li>
							<li>Use truck mounted hands free telephones only</li>
							<li>No smoking in any company vehicle</li>
							<li>Rest required breaks in accordance with legal requirements to avoid fatigue</li>
							<li>Not allow any unauthorised passengers in any vehicles</li>
							<li>I am not suffering from any medical condition that may affect my capacity to work</li>
							<li>Count and check the pallets as they are unloaded from my vehicle to ensure the pallets I deliver match the corresponding pallet transfer docket(s)</li>
						</ul>
						<p><u><b>The content of this checklist is true and correct to the best of my knowledge. I am fit for duty. I have completed a 
						prestart visual inspection and I have not identified any safety issues.</b></u></p>
					</Col>
				</Row>
				<div className="pre-start__signature">
					<h3 className="title">
						<Icon name="pencil" /> Signature
					</h3>
					<Row>
						<Col xs={12}>
							<Signature 
								data={this.props.data.signatureFile}
								onChange={this.handleChangeSignature} 
							/>
							<h4 className="text-center" id="signatureError" style={{display: 'none'}}>
								<label className="text-danger">
									<b>You must provide your signature</b>
								</label>
							</h4>
						</Col>
					</Row>
				</div>
				<hr />
				<Row>
					<Col xs={12}>
						<FormGroup className="pre-start__commence-trip">
							<Button
								block
								bsStyle="success"
								bsSize="lg"
								onClick={this.handleCommenceTrip}
							>
								Agree & Submit
								<Icon name="Play" />
							</Button>
						</FormGroup>
					</Col>
				</Row>
			</div>
		);
	}
}

TermsAndAgreement.propTypes = propTypes;
TermsAndAgreement.defaultProps = defaultProps;

export default TermsAndAgreement;