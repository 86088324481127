import StringMask from 'string-mask';

export function convertMetersToKm(value: number, addMask?: boolean) {
	const kms = Math.floor(value / 1000);

	if (addMask)
		return numberMask(kms) + ' Km';

	return kms;
}

export function convertSecondsToHours(value: number, addMask?: boolean) {
	const hours = Math.floor(value / 60 / 60);

	if (addMask) 
		return numberMask(hours) + (hours === 1 ? ' hour' : ' hours');

	return hours;
}

export function numberMask(value: number) {
	return StringMask.apply(value.toFixed(0), '###,###,###', { reverse: true });
}

export function convertFullPhoneNumberToMobile(value: string) {
	return value ? StringMask.apply(value.replace('+61', ''), '0000 000 000', { reverse: true }) : ''
}

/**
 * Compares two versions as strings and returns true if the current version is out of date. 
 * @param current The current UI version the user is on
 * @param latest The most up to date UI version as per the API
 * @returns `true` if the `current` version is out of date 
 */
export function versionOutdated(current: string, latest: string){
	if (current == undefined) return true;
	
	const currentCompare = current.split('.').map(x => x.padStart(2, '0')).join('');
	const latestCompare = latest.split('.').map(x => x.padStart(2, '0')).join('');
	return currentCompare < latestCompare;
}