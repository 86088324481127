import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal, Table } from 'react-bootstrap';
import { QuickPinNeededModal } from '../../../../common/layout';

import { Form, Callout, UserDetailsLink } from '../../../../common/uiElements';

const propTypes = {
	show: PropTypes.bool,
	preStartsToApprove: PropTypes.array,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,

};

const defaultInactiveTimeSeconds = 80;

class CheckoutApproveAllModal extends Component {
	constructor() {
		super();
		this.state = {
			showQuickPinModal: false,
			inactiveTimer: defaultInactiveTimeSeconds
		};

		this.handleCloseQuickPinModal = () => {          
			this.setState({ showQuickPinModal: false });
		};

		this.handleSubmitQuickPin = (azureId) => {
			this.onSubmitApproval(azureId);   
			this.setState({ showQuickPinModal: false });
		};

		this.handleSubmit = () => {
			if (window.$(this.modal).find('form').valid()) {
				let { loggedUser } = this.props;
				if (loggedUser.isElevationRequired)
					this.setState({ showQuickPinModal: true });
				else
					this.onSubmitApproval(loggedUser.azureId);
			}
                
		};
	}

	componentDidMount() {
		this.startInactiveTimer();
	}

	componentWillUnmount() {
		this.clearInactiveTimer();
	}

	startInactiveTimer = () => {
		this.clearInactiveTimer();
		this.inactiveTimer = setInterval(() => {
			this.setState({ inactiveTimer: this.state.inactiveTimer - 1});
			if (this.state.inactiveTimer === 0) {
				this.props.onClose();
			}
		}, 1000);
	}

	clearInactiveTimer = () => {
		this.setState({ inactiveTimer: defaultInactiveTimeSeconds });
		window.clearInterval(this.inactiveTimer);
	}

	onSubmitApproval = azureId => {
		if (this.props.onSubmit)
			this.props.onSubmit(azureId, this.props.preStartsToApprove);
	}

	render() {
		const s = this.state;
		const p = this.props;

		return (
			<div>
				<Modal show={p.show} onHide={this.props.onClose}>
					<Modal.Header closeButton>
						<Modal.Title>
							Approval
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div ref={ref => this.modal = ref} onClick={this.startInactiveTimer}>
							{
								s.inactiveTimer < 20 && (
									<Callout>You seem to be inactive. This box will close in <b>{s.inactiveTimer} seconds</b>.</Callout>
								)
							}
							<Form> 
								<Table>
									<thead>
										<tr>
											<th>Driver</th>
											<th>Vehicle(s)</th>
											<th>Run(s)</th>
										</tr>
									</thead>
									<tbody>
										{
											p.preStartsToApprove.map((preStart, index) => (
												<tr key={index}>
													<td>
														<UserDetailsLink showDetailsModal={false} azureId={preStart.WorkerReportedBy.azureId}>
															{ preStart.WorkerReportedBy.shortName }
														</UserDetailsLink>
													</td>
													<td>
														{ preStart.mainVehFleetNumber }
														{ preStart.trailer1VehFleetNumber && `, ${preStart.trailer1VehFleetNumber}` }
														{ preStart.dollyVehFleetNumber && `, ${preStart.dollyVehFleetNumber}` }
														{ preStart.trailer2VehFleetNumber && `, ${preStart.trailer2VehFleetNumber}` }
													</td>
													<td>
														{ 
															!preStart.PreStartRuns || preStart.PreStartRuns.length === 0 ? '-' : (
																preStart.PreStartRuns.map(({ number }, index) => (
																	`${index > 0 ? ' ,' : ''} ${number}`
																))
															) 
														}
													</td>
												</tr>
											))
										}
									</tbody>
								</Table>	
							</Form>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button block bsStyle="success" bsSize="lg" onClick={this.handleSubmit}>Submit</Button>
					</Modal.Footer>
				</Modal>
				<QuickPinNeededModal 
					show={s.showQuickPinModal} 
					onClose={this.handleCloseQuickPinModal}
					onSubmit={this.handleSubmitQuickPin}
				/>
			</div>
		);
	}
}

CheckoutApproveAllModal.propTypes = propTypes;

const mapStateToProps = state => {
	return {
		loggedUser: state.user.data
	};
};

export default connect(mapStateToProps)(CheckoutApproveAllModal);