import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import classNames from 'classnames';

const defaultProps = {
	text: 'Do you really wish to continue?',
	proceedText: 'Yes',
	cancelText: 'No',
	proceedOnly: false,
	proceed: () => {}
};

const propTypes = {
	children: PropTypes.any,
	textColour: PropTypes.oneOf(['success', 'info', 'primary', 'warning', 'danger']),
	text: PropTypes.string,
	proceedOnly: PropTypes.bool,
	proceedText: PropTypes.string,
	cancelText: PropTypes.string,
	proceed: PropTypes.func,
	cancel: PropTypes.func
};

const ConfirmModal = props => (
	<Modal show>
		<Modal.Body className={classNames({ [`text-${props.textColour}`]: props.textColour ? true : false })}>
			{ typeof props.children === 'object' ? props.children : <h4 className="text-center"><b>{ props.children || props.text }</b></h4> }
		</Modal.Body>
		<Modal.Footer>
			{
				(props.proceedOnly || !props.cancel) ? (
					<Button block bsStyle="success" onClick={props.proceed}>{props.proceedText || 'OK'}</Button>
				) : (
					<Row>
						<Col xs={6}>
							<Button block bsStyle="success" onClick={props.proceed}>{ props.proceedText }</Button>
						</Col>
						<Col xs={6}>
							<Button block bsStyle="danger" onClick={props.cancel}>{ props.cancelText }</Button>
						</Col>
					</Row>
				)
			}
		</Modal.Footer>
	</Modal>
);

ConfirmModal.defaultProps = defaultProps;
ConfirmModal.propTypes = propTypes;

export { ConfirmModal };