/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Callout, UserDetailsLink } from '../../../../common/uiElements';

const NonConformanceList = props => {    

	if (!props.data || props.data.length === 0)
		return (
			<Callout title="No data" color="info" icon="info-circle">
				No data has been found. Change the filters and try again. 
			</Callout>
		)

	const handleSeeDetails = nonConformanceId => { 
		return props.onViewDetails && props.onViewDetails(nonConformanceId);
	};

	return (
		<ReactTable
			getTrProps={(state, rowInfo) => ({ 
				onClick: () => handleSeeDetails(rowInfo.id),
				className: rowInfo.original.closedOutOnLocal ? 'green' : 'red' })}
			data={props.data}
			minRows={0}
			manual
			loading={props.isLoading}
			page={props.page}
			pageSize={props.pageSize}
			pages={props.totalPages}
			sorted={props.sorted}
			onFetchData={props.onFetchData}
			onPageChange={props.onPageChange}
			onPageSizeChange={props.onPageSizeChange}
			onSortedChange={props.onSortedChange}
			columns={[
				// {
				// 	Header: '',
				// 	className: 'text-center',
				// 	sortable: false,
				// 	width: 60,
				// 	accessor: 'id',
				// 	Cell: row => {
				// 		const nonConformanceId = row.value;
				// 		return (
				// 			<a className="cursor-pointer" onClick={() => handleSeeDetails(nonConformanceId)}>
				// 				<Icon name="search" size="2x" />
				// 			</a>
				// 		);
				// 	}
				// },
				{
					Header: 'Ref #',
					accessor: 'id',
					width: 60
				}, 
				{
					Header: 'Involved Worker ',
					id: 'involvedWorker',
					accessor: 'WorkerInvolvedBy',
					Cell: row => {
						if (!row.value) return '-';
		
						const { azureId, shortName } = row.value;
						return (
							<UserDetailsLink azureId={azureId}>
								{ shortName }
							</UserDetailsLink>
						);
					}
				}, 
				{
					Header: 'Reported By',
					id: 'reportedBy',
					accessor: p => p,
					Cell: row => {
						const { reportedByExternal, WorkerReportedBy } = row.value;
						if (reportedByExternal) 
							return reportedByExternal;
		
						const { azureId, shortName } = WorkerReportedBy;
						return (
							<UserDetailsLink azureId={azureId}>
								{ shortName }
							</UserDetailsLink>
						);
					}
				}, 
				{
					Header: 'Type',
					id: 'nonConformanceType',
					accessor: 'nonConformanceTypeName',
					width: 230
				}, 
				{
					Header: 'Involved Fleet',
					id: 'involvedFleetNumber',
					accessor: 'involvedFleetNumber',
					Cell: row => row.value || '-'
				},
				{
					Header: 'Reported On',
					id: 'reportedOn',
					accessor: 'reportedOnLocal',
					Cell: row => moment(row.value).format('DD/MM/YYYY')
				},
				{
					Header: 'Occurred On',
					id: 'occurredOn',
					accessor: 'occurredOnLocal',
					Cell: row => moment(row.value).format('DD/MM/YYYY')
				}, 
				{
					Header: 'Closed Out On',
					id: 'closedOutOn',
					accessor: 'closedOutOnLocal',
					Cell: row => row.value ? moment(row.value).format('DD/MM/YYYY') : '-'
				},
				{
					Header: 'Status',
					id: 'status',
					accessor: 'closedOutOnLocal',
					sortable: false,
					Cell: row => row.value ? 'Closed' : 'Open'
				}
			]}
		/>
	);
};

NonConformanceList.propTypes = {
	data: PropTypes.array.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired,
	sorted: PropTypes.arrayOf(
		PropTypes.shape({
			desc: PropTypes.bool.isRequired,
			id: PropTypes.string.isRequired,
		})
	),
	isLoading: PropTypes.bool.isRequired,
	onPageChange: PropTypes.func,
	onPageSizeChange: PropTypes.func,
	onSortedChange: PropTypes.func,
	onFetchData: PropTypes.func,
	onViewDetails: PropTypes.func
};

export default NonConformanceList;