import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import 'chartjs-plugin-zoom';

import GraphData from '../../../app/helpers/GraphData';
import GraphOptions from '../../../app/helpers/GraphOptions';

const propTypes = {
	data: PropTypes.array,
	vehicle: PropTypes.object,
	postfix: PropTypes.string,
	chartName: PropTypes.string.isRequired,
	height: PropTypes.number,
	onSelectGraphPoint: PropTypes.func,
	displayLegend: PropTypes.bool
};

const defaultProps = {
	height: 200,
	displayLegend: true
};
class LineChart extends Component {
	componentDidMount() {
		this.renderGraph();
	}

	componentDidUpdate() {
		this.renderGraph();
	}

renderGraph = () => {
	const { chartName, data, displayLegend, vehicle } = this.props;
	const graphData = GraphData.render(chartName, data, vehicle);

	if (data) {
		const ctx = this.lineChartElement.getContext('2d');
		this.chart = new Chart(ctx, {
			type: 'line',
			data: {
				datasets: graphData
			},
			options: {
				spanGaps: true,
				maintainAspectRatio: false,
				legend: {
					display: displayLegend || false
				},
				scales: {
					yAxes: [{
						ticks: GraphOptions.ticks(chartName)
					}],
					xAxes: [{
						type: 'time',
						ticks: {
							minRotation: 30
						},
						time: {
							displayFormats: {
								minute: 'HH:mm:ss'
							},
							unit: false,
							tooltipFormat: 'HH:mm:ss'
						}
					}]
				},
				tooltips: GraphOptions.tooltips(chartName),
				pan: {
					enabled: false
				},
				zoom: {
					enabled: true,
					mode: 'x'
				}
			}
		});
	}
}

handleSelect = event => {
	if (this.props.onSelectGraphPoint) {
		const activePoints = this.chart.getElementsAtEvent(event);

		if(activePoints.length > 0)
		{
			const clickedElementIndex = activePoints[0]['_index'];

			const label = this.chart.data.labels[clickedElementIndex];   
			const value = this.chart.data.datasets[0].data[clickedElementIndex];
		
			this.props.onSelectGraphPoint(clickedElementIndex, value, label);
		}
	}
	
}

render() {
	return (
		<div className="chart-container">
			<canvas 
				ref={ref => this.lineChartElement = ref} 
				height={this.props.height} 
				onClick={this.handleSelect} 
			/>
		</div>
	);
}
}

LineChart.propTypes = propTypes;
LineChart.defaultProps = defaultProps;

export default LineChart;