import { WorkerListItemWithExtraData } from './../models/Worker';
import listEndpoints from './endpoints/listEndpoints';
import httpRequests from './httpRequests';
import { IVehicleBasicInfo } from '../models/Vehicle';
import { ITaskType } from '../models/Task';
import { IRunBasicInfo } from '../models/Run/Run';
import { IPlacesListItem } from '../models/Place';

export default {

	getCompanies(onlyActive?: boolean, onlySubContractors?: boolean) {
		const endpoint = listEndpoints.companies;
		return httpRequests.get(endpoint, { onlyActive, onlySubContractors });
	},

	getCompanyDocTypes(onlyActive?: boolean) {
		const endpoint = listEndpoints.companyDocTypes;
		return httpRequests.get(endpoint, { onlyActive });
	},
	
	getFaultReportTypes(vehTypeId: number, onlyActive?: boolean) {
		const endpoint = listEndpoints.faultReportTypes;
		return httpRequests.get(endpoint, { vehTypeId, onlyActive });
	},
	
	getPreStartDepots(onlyActive?: boolean) {
		const endpoint = listEndpoints.preStartDepots;
		return httpRequests.get(endpoint, { onlyActive });
	},

	getPalletJackLiveMonitoringDepots(onlyActive?: boolean) {
		const endpoint = listEndpoints.palletJackLiveMonitoringDepots;
		return httpRequests.get(endpoint, { onlyActive });
	},
	
	getSuperFundCompanies(onlyActive?: boolean) {
		const endpoint = listEndpoints.superFundCompanies;
		return httpRequests.get(endpoint, { onlyActive });
	},

	getRunTypes(onlyActive?: boolean, taskTypeId?: number) {
		const endpoint = listEndpoints.runTypes;
		return httpRequests.get(endpoint, { onlyActive, taskTypeId });
	},

	/**
	 * Gets the Supervisors List with their Quick PIN Numbers
	 */
	getPreStartSupervisors() {
		const endpoint = listEndpoints.preStartSupervisors;
		return httpRequests.get(endpoint);
	},

	/**
	 * Gets the Workers List
	 */
	getWorkersList(includeExtraData = false, onlyActive = true) {
		const endpoint = listEndpoints.workers;
		return httpRequests.get(endpoint, { onlyActive, includeExtraData });
	},

	/**
	 * Gets the Workers List with Extra Data
	 */
	getWorkersListWithExtraData(onlyActive = true):Promise<WorkerListItemWithExtraData[]> {
		const endpoint = listEndpoints.workers;
		return httpRequests.get(endpoint, { onlyActive, includeExtraData: true });
	},

	/**
	 * Gets the Workers List with a lot of data to be used in the tasks
	 */
	getWorkersListForTasks(onlyActive?: boolean, companyAbn?: string, availabilityDate?: string, rosterDate?: string) {
		const endpoint = listEndpoints.workersForTask;
		return httpRequests.get(endpoint, { onlyActive, companyAbn, availabilityDate, rosterDate });
	},

	/**
	 * Gets the Permission Groups from Azure
	 */
	getAzurePermissionGroups() {
		const endpoint = listEndpoints.azurePermissionGroups;
		return httpRequests.get(endpoint);
	},

	/**
	 * Gets the Delivery Point Types
	 */
	getAccessPointTypesList(onlyActive?: boolean) {
		const endpoint = listEndpoints.accessPointTypes;
		return httpRequests.get(endpoint, { onlyActive });
	},

	/**
	 * Gets the Absence reasons
	 */
	getAbsenceReasonsList(onlyActive?: boolean) {
		const endpoint = listEndpoints.absenceReasons;
		return httpRequests.get(endpoint, { onlyActive });
	},

	getWorkerDocTypes(onlyActive?: boolean) {
		const endpoint = listEndpoints.workerDocTypes;		
		return httpRequests.get(endpoint, { onlyActive });	
	},
	getWorkerStatusLeftReasons(onlyActive?: boolean) {
		const endpoint = listEndpoints.workerStatusLeftReasons;
		return httpRequests.get(endpoint, { onlyActive });
	},
	getWorkerStatusRoleNames(onlyActive?: boolean) {
		const endpoint = listEndpoints.workerStatusRoleNames;
		return httpRequests.get(endpoint, { onlyActive });
	},

	/**
	 * FAULT REPORT USERS BY
	 */
	getFaultReportAuthorisedBy() {
		const endpoint = listEndpoints.faultReportAuthorisedBy;
		return httpRequests.get(endpoint);
	},

	getFaultReportActionBy() {
		const endpoint = listEndpoints.faultReportActionBy;
		return httpRequests.get(endpoint);
	},

	getFaultReportClosedOffBy() {
		const endpoint = listEndpoints.faultReportClosedOffBy;
		return httpRequests.get(endpoint);
	},
	/** ******************** */


	/**
	 * Gets the Non Conformance Types
	 */
	getNonConformanceTypesList(onlyActive?: boolean) {
		const endpoint = listEndpoints.nonConformanceTypes;
		return httpRequests.get(endpoint, { onlyActive });
	},

	/**
	 * Gets the Place Categories List
	 */
	getPlacesList(placeCategoryId?: string) {
		// eslint-disable-next-line
		// return new Promise(resolve => resolve(placesMockup.filter(d => !placeCategoryId || placeCategoryId == d.placeCategoryId)));
		const endpoint = listEndpoints.placeCategories;
		return httpRequests.get(endpoint);
	},

	/**
	 * Gets the Place Categories List
	 */
	getPlaceCategoriesList() {
		const endpoint = listEndpoints.placeCategories;
		return httpRequests.get(endpoint);
	},

	/**
	 * Gets the Place Categories List
	 * @param placeCategoryId Will bring only the sub categories of the passed Place Category
	 */
	getPlaceSubCategoriesList(placeCategoryId?: number) {
		const endpoint = placeCategoryId ? listEndpoints.placeSubCategoriesWithCategoryId(placeCategoryId) : listEndpoints.placeSubCategories;
		return httpRequests.get(endpoint);
	},

	/**
	 * Gets the Commodity Types List
	 */
	getCommodityTypesList() {
		const endpoint = listEndpoints.commodityTypes;
		return httpRequests.get(endpoint);
	},

	/**
	 * Gets the Commodity Types Links List based on the place category
	 * It's the link between a Category and a Commodity
	 * E.g Freezer for Woolworths will have a different DC number, so it will be shown as for example "Freezer (1989)"
	 * @param placeCategoryId Will bring the commodities with DC numbers if it has one
	 */
	getCommodityTypesLinksList(placeCategoryId: number) {
		const endpoint = listEndpoints.commodityTypesLinksWithCategoryId(placeCategoryId);
		return httpRequests.get(endpoint);
	},

	/**
	 * Gets the Curfew Types List
	 */
	getCurfewTypesList() {
		const endpoint = listEndpoints.curfewTypes;
		return httpRequests.get(endpoint);
	},

	/**
	 * Gets the depots which the employees can start their shifts
	 */
	getRosterStartingDepots(onlyActive?: boolean) {
		const endpoint = listEndpoints.rosterStartingDepots;
		return httpRequests.get(endpoint, { onlyActive });
	},

	/**
	 * Gets the reasons for changing a roster
	 */
	getRosterUpdateReasons(rosterUpdateReasonCategoryId?: number, onlyActive?: boolean) {
		const endpoint = listEndpoints.rosterUpdateReasons;
		return httpRequests.get(endpoint, { rosterUpdateReasonCategoryId, onlyActive });
	},

	/**
	 * Gets the categories for the roster change reasons
	 */
	getTaskUpdateReasonCategories(onlyActive?: boolean) {
		const endpoint = listEndpoints.taskUpdateReasonCategories;
		return httpRequests.get(endpoint, { onlyActive });
	},

	/**
	 * Gets the reasons for changing a task
	 */
	getTaskUpdateReasons(taskUpdateReasonCategoryId?: number, onlyActive?: boolean) {
		const endpoint = listEndpoints.taskUpdateReasons;
		return httpRequests.get(endpoint, { taskUpdateReasonCategoryId, onlyActive });
	},

	/**
	 * Gets the Root Causes for the Non Conformance
	 */
	getNonConformanceRootCausesList(onlyActive?: boolean) {
		const endpoint = listEndpoints.nonConformanceRootCauses;
		return httpRequests.get(endpoint, { onlyActive });
	},

	getVehiclesList(onlyActive?: boolean, vehTypeFilter?: number[], stateFilter?: string | string[], subContractorAbn?: string): Promise<IVehicleBasicInfo[]> {
		const endpoint = listEndpoints.vehicles;
		return httpRequests.get(endpoint, { onlyActive, vehTypeFilter, stateFilter, subContractorAbn });
	},

	getVehiclesForTaskList(onlyActive?: boolean, vehTypeFilter?: number[], stateFilter?: string | string[]): Promise<IVehicleBasicInfo[]> {
		const endpoint = listEndpoints.vehiclesForTask;
		return httpRequests.get(endpoint, { onlyActive, vehTypeFilter, stateFilter });
	},

	getTaskTypesList(onlyActive?: boolean, isDriversLicenceRequired?: boolean, isRunRequired?: boolean): Promise<ITaskType[]> {
		const endpoint = listEndpoints.taskTypes;
		return httpRequests.get(endpoint, { onlyActive, isDriversLicenceRequired, isRunRequired});
	},

	getRunsList(startTimeLocal: string, finishTimeLocal: string, startingDepotId?: number): Promise<IRunBasicInfo[]> {
		const endpoint = listEndpoints.runs;
		return httpRequests.get(endpoint, { startTimeLocal, finishTimeLocal, startingDepotId });
	},
	
	getAustralianStates() {
		const endpoint = listEndpoints.australianStates;
		return httpRequests.get(endpoint);
	},

	getVehicleTypes() {
		const endpoint = listEndpoints.vehicleTypes;
		return httpRequests.get(endpoint);
	},

	getVehicleMakes() {
		const endpoint = listEndpoints.vehicleMakes;
		return httpRequests.get(endpoint);
	},
	
	getVehicleTrackingSettings() {
		const endpoint = listEndpoints.vehicleTrackingSettings;
		return httpRequests.get(endpoint);
	},

	getPlacesByRunType(runTypes?: number[]): Promise<IPlacesListItem> {
		const endpoint = listEndpoints.placeByRunType;
		return httpRequests.get(endpoint, { runTypes });
	},
};