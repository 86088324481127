import React, { ChangeEvent } from 'react';
import { Row, FormControl } from 'react-bootstrap';
import { RadioButtonList, VehicleTypes } from '../../../../common/inputs';
import { FieldGroup } from '../../../../common/uiElements';
import {  VehiclesListFilter as VehiclesListFilterClass } from '../../../../../app/models/Vehicle';

interface IProps extends VehiclesListFilterClass {
	onChangeVehicle?: (e: ChangeEvent<FormControl & HTMLInputElement>) => void;
	onChangeVehicleType?: (vehicleTypes: number[]) => void;
	onChangeStatus?: (isActive?: boolean) => void;
	onPressEnter?: () => void;
}

const VehiclesListFilter: React.FC<IProps> = props => {
	const statusValue = props.isActive === undefined ? '' : props.isActive ? 'active' : 'inactive';
	
	const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.onChangeStatus && props.onChangeStatus(e.target.value === 'active' ? true : e.target.value === 'inactive' ? false : undefined)
	};

	const handlePressKey = (e: React.KeyboardEvent<FormControl>) => {
		if (e.key === 'Enter')
			props.onPressEnter && props.onPressEnter();
	};

	return (
		<Row>
			<FieldGroup label="Fleet Number / REGO" sm={6} lg={2}>
				<FormControl 
					value={props.fleetNumber || ''}
					onChange={props.onChangeVehicle}
					onKeyPress={handlePressKey}
				/>
			</FieldGroup>
			<FieldGroup label="Status" sm={6} lg={2}>
				<RadioButtonList 
					value={statusValue}
					items={[
						{ text: 'Active', value: 'active' },
						{ text: 'Inactive', value: 'inactive' },
						{ text: 'All', value: '' }
					]}
					onChange={handleChangeStatus}
				/>
			</FieldGroup>
			<FieldGroup label="Vehicle Type" sm={12} lg={8}>
				<VehicleTypes
					value={props.vehicleTypeIds || []}
					onChange={props.onChangeVehicleType}
				/>
			</FieldGroup>
		</Row>
	);
}

export default VehiclesListFilter;