import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTable from 'react-table';
import { Callout, UserDetailsLink } from '../../../common/uiElements';

const propTypes = {
	data: PropTypes.array.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired,
	sorted: PropTypes.arrayOf(
		PropTypes.shape({
			desc: PropTypes.bool.isRequired,
			id: PropTypes.string.isRequired,
		})
	),
	defaultPageSize: PropTypes.number,
	isLoading: PropTypes.bool.isRequired,
	hideEmployee: PropTypes.bool,
	showSimpleList: PropTypes.bool,
	onPageChange: PropTypes.func,
	onPageSizeChange: PropTypes.func,
	onSortedChange: PropTypes.func,
	onFetchData: PropTypes.func,
	onViewDetails: PropTypes.func
};

const defaultProps = {
	defaultPageSize: 10,
	defaultSorted: []
};

const LeaveRequestList = ({
	data,
	page,
	pageSize,
	totalPages,
	defaultPageSize,
	sorted,
	isLoading,
	hideEmployee,
	showSimpleList,
	onFetchData,
	onPageChange,
	onPageSizeChange,
	onSortedChange,
	onViewDetails
}) => {
	const renderStatus = data => {
		const { isActive, isApproved, startDate, endDate } = data;
		const currentDate = moment();
		const statusColor = !isActive ? 'text-muted' : isApproved == null ? 'text-info' : isApproved ? 'text-success' : 'text-danger';
		let statusText = !isActive ? 'Cancelled' : isApproved == null ? 'Pending' : isApproved ? 'Approved' : 'Denied';

		if (isApproved)
			if (currentDate > moment(endDate))
				statusText = 'Taken';
			else if (currentDate > moment(startDate))
				statusText = 'Taking';
				
		return <b className={statusColor}>{ statusText }</b>;
	};

	const statusColumn = {
		Header: 'Status',
		id: 'status',
		width: 80,
		sortable: false,
		accessor: d => d,
		Cell: ({ value }) => renderStatus(value)
	};

	if (data.length === 0)
		return (
			<Callout color="info" icon="info-circle" title="No Leave Requests">
				You don't have any leave request yet. Submit your first leave request using the button above.
			</Callout>
		);

	return (
		<ReactTable
			getTrProps={(state, rowInfo) => {
				if (!rowInfo) 
					return {};

				const { isApproved, isActive } = rowInfo.original;

				let className = '';
				if (!isActive)
					className = 'grey';
				else if (isApproved == null)
					className = 'blue';
				else if (isApproved === true)
					className = 'green';
				else if (isApproved === false)
					className = 'red';

				return { 
					className,
					onClick: () => onViewDetails && onViewDetails(rowInfo.original)
				};
			}}
			className="-highlight"
			data={data}
			minRows={0}
			manual
			loading={isLoading}
			defaultPageSize={defaultPageSize}
			page={page}
			pageSize={pageSize}
			pages={totalPages}
			sorted={sorted}
			onFetchData={onFetchData}
			onPageChange={onPageChange}
			onPageSizeChange={onPageSizeChange}
			onSortedChange={onSortedChange}
			columns={[
				{
					...statusColumn,
					show: showSimpleList,
				},
				{
					Header: 'Employee',
					id: 'createdBy',
					show: !hideEmployee,
					accessor: d => d.WorkerRequestedBy,				
					Cell: ({ value: { azureId, shortName } }) => (
						<UserDetailsLink azureId={azureId} showDetailsModal={false}>
							{ shortName }
						</UserDetailsLink>
					)
				},
				{
					Header: 'Current Role',
					id: 'role',
					show: !hideEmployee,
					accessor: d => d.WorkerRequestedBy.currentRoleName || '-'
				}, 
				{
					Header: 'Current Contract',
					id: 'contract',
					show: !hideEmployee,
					accessor: d => d.WorkerRequestedBy.currentRoleStatus || '-'
				}, 
				{
					Header: 'Leave Type',
					accessor: 'typeName'
				}, 
				{
					Header: 'Requested On',
					accessor: 'requestedOnLocal',
					id: 'requestedOn',
					width: 110,
					Cell: ({ value }) => moment(value).format('DD/MM/YYYY')
				},
				{
					Header: 'Revised On',
					accessor: 'revisedOnLocal',
					id: 'revisedOn',
					width: 110,
					show: !showSimpleList,
					Cell: ({ value }) => value ? moment(value).format('DD/MM/YY HH:mm') : '-'
				},
				{
					Header: 'Start Date',
					accessor: 'startDate',
					width: 90,
					show: !showSimpleList,
					Cell: ({ value }) => moment(value).format('DD/MM/YYYY')
				},
				{
					Header: 'End Date',
					accessor: 'endDate',
					width: 90,
					show: !showSimpleList,
					Cell: ({ value }) => moment(value).format('DD/MM/YYYY')
				},
				{
					id: 'total',
					Header: 'Total',
					width: 90,
					sortable: false,
					show: !showSimpleList,
					accessor: d => d,
					Cell: ({ value: { startDate, endDate } }) => `${ moment(endDate).diff(moment(startDate), 'days') + 1 } day(s)`
				},
				{
					Header: 'Payment Method',
					accessor: 'withPay',
					show: !showSimpleList,
					Cell: ({ value }) => value ? 'Paid' : 'Unpaid'
				},
				{
					...statusColumn,
					show: !showSimpleList,
				}
			]}
		/>
	);
};

LeaveRequestList.propTypes = propTypes;
LeaveRequestList.defaultProps = defaultProps;

export default LeaveRequestList;
