import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button, FormGroup, FormControl, Row, Col } from 'react-bootstrap';
import toastr from 'toastr';

import WorkerApi from '../../../app/api/WorkerApi';

// UI Elements
import { Form, ErrorBox, Loader } from '../uiElements';

class QuickPinModal extends Component {
	static propTypes = {
		onClose: PropTypes.func
	}

	constructor(props) {
		super(props);
		this.state = {
			quickPin: '',
			quickPinCheck: '',
			showModal: true,
			isSaving: false,
			error: null
		};

		this.validations = {
			quickPin: { 
				required: true,
				minlength: 4,
				maxlength: 4
			},
			quickPinCheck: { 
				required: true,
				equalTo: '#quickPin',
				minlength: 4,
				maxlength: 4
			},
		};

		this.handleChangePin = (e) => {
			this.setState({ [e.target.id]: e.target.value });
		};

		this.handleCloseModal = () => {
			if (this.props.onClose)
				this.props.onClose();
		};

		this.handleSave = () => {
			if (window.$(this.quickPinForm).find('form').valid()) {
				let { azureId } = this.props.loggedUser;
				let { quickPin } = this.state;
					
				this.setState({ isSaving: true, error: null });
				WorkerApi.saveWorkerQuickPin(azureId, quickPin)
					.then(() => {
						toastr.success('Quick PIN successfully saved', 'Success');
						
						if (!this.unmounted)
							
							if (this.props.onClose)
								return this.props.onClose();
						
						this.setState({ showModal: false });
					})
					.catch((error) => {
						console.error(error);
						this.setState({ isSaving: false, error });
					});

			}
		};
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	render() {
		return (
			<Modal show={this.state.showModal} bsSize="small" onHide={this.handleCloseModal}>
				<Modal.Header closeButton={this.props.onClose ? true : false}>
					<Modal.Title>Define your quick PIN</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						this.state.error && (
							<ErrorBox error={this.state.error} retryFunc={this.handleSave}/>
						)
					}
					{
						this.state.isSaving ? (
							<div className="text-center">
								<Loader />
								<h4>Saving...</h4>
							</div>
						) : (
							<div>
								<p className="text-center">Supervisors need to have a quick PIN for tasks when they are using a shared account.</p>
								<hr />
								<div ref={ref => this.quickPinForm = ref}>
									<Form validations={this.validations}>
										<Row>
											<Col xs={6}>
												<FormGroup>
													<label>Quick PIN</label>
													<FormControl 
														id="quickPin" 
														type="password" 
														maxLength="4"
														placeholder="4 digits"
														value={this.state.quickPin}
														onChange={this.handleChangePin}
													/>
												</FormGroup>	
											</Col>
											<Col xs={6}>
												<FormGroup>
													<label>Type again</label>
													<FormControl 
														id="quickPinCheck"
														type="password" 
														maxLength="4"
														placeholder="4 digits"
														value={this.state.quickPinCheck}
														onChange={this.handleChangePin}
													/>
												</FormGroup>	
											</Col>
										</Row>
									</Form>
								</div>
							</div>
						)
					}
				</Modal.Body>
				<Modal.Footer>
					<Button className="pull-left" bsStyle="success" onClick={this.handleSave}>Save</Button>
					<Button bsStyle="danger" onClick={this.handleCloseModal}>Cancel</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

const mapStateToProps = state => ({ loggedUser: state.user.data });

const QuickPinModalComponent = connect(mapStateToProps)(QuickPinModal);
export { QuickPinModalComponent as QuickPinModal };