import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../../app/assets/img/logo.png';

const propTypes = {
	showLogo: PropTypes.bool, 
	title: PropTypes.string,
	text: PropTypes.string,
	color: PropTypes.string,
	image: PropTypes.string,
	imageSize: PropTypes.number,
	children: PropTypes.any
};

const defaultProps = {
	showLogo: true,
	title: '',
	text: '',
	color: 'yellow',
	imageSize: 150
};

const MainLayout = ({
	showLogo,
	title,
	text,
	color,
	image,
	imageSize,
	children
}) => {	
	return (
		<div className="error-page text-center">
			{ showLogo && <img className="logo img-responsive" src={Logo} alt="Micway logo" /> }
			{ title && <h1 className={`text-${color}`}>{ title }</h1> }
			{ image && <img height={imageSize} src={image} alt="info" /> }
			{ text && <h3>{ text }</h3> }
			<div className="message-content">
				{ children }
			</div>
		</div>
	);
};

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

export default MainLayout;