import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { MultipleFiles } from '../../../../common/inputs';
import { Form } from '../../../../common/uiElements';

const propTypes = {
	id: PropTypes.string,
	data: PropTypes.array,
	onChange: PropTypes.func
};

const defaultProps = {
	id: 'WorkerDocuments',
	data: []
};

const fileFields = [{ 
	id: 'typeId', 
	defaultValue: '',
	description: 'Type', 
	type: 'select',
	selectType: 'workerDocTypesList',
	isRequired: true,
	cssClass:'', 
	cssCol: 'col-sm-6',
	showOnTable: true
}, { 
	id: 'description', 
	defaultValue: '',
	description: 'Description', 
	maxLength: '50',
	type: 'text', 
	isRequired: true,
	cssClass:'', 
	cssCol: 'col-sm-6',
	showOnTable: true
}, { 
	id: 'number', 
	defaultValue: '',
	description: 'Number', 
	maxLength: '50',
	type: 'text', 
	isRequired: false,
	cssClass:'', 
	cssCol: 'col-sm-6',
	showOnTable: true
}, { 
	id: 'expiryDate', 
	defaultValue: '',
	description: 'Expiry Date', 
	maxLength: '50',
	type: 'date', 
	isRequired: false,
	cssClass:'', 
	cssCol: 'col-sm-6',
	showOnTable: true
}
];

class WorkerDocuments extends Component {
	handleChange = (files) => {
		var { id, onChange} = this.props;
		if (onChange)
			return onChange(files, id);
	}

	render() {
		var { data } = this.props;

		return (
			<Form>
				<div className="col-sm-12">
					<MultipleFiles files={data} fields={fileFields} onChange={this.handleChange}/>
				</div>
			</Form>
		);
	}
}

WorkerDocuments.propTypes = propTypes;
WorkerDocuments.defaultProps = defaultProps;

export default WorkerDocuments;