import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

// API
import VehiclesApi from '../../../../app/api/VehiclesApi';

// Schema
import faultReportSchema from '../../../../app/schemas/faultReport/faultReportSchema.json';

import FaultReport from '../faultReport/subComponents/FaultReport';
import FaultReportList from '../faultReport/subComponents/FaultReportList';

// UI Elements
import { ErrorBox, Loader } from '../../../common/uiElements';

class FaultReportListContainer extends Component {
	static propTypes = {
	/** Fault report array */
		faultReportItems: PropTypes.arrayOf(
			PropTypes.shape({
				dtoFaultReportTypeName: PropTypes.string.isRequired,
				reportedVehicleId: PropTypes.string.isRequired,
				reportedDescription: PropTypes.string.isRequired,
				FaultReportPhotos: PropTypes.array
			})
		),

		/** Array of fleet numbers to fetch their fault reports data from API */
		fleetNumbers: PropTypes.arrayOf(
			PropTypes.string
		),

		/** If true, fetch data of the fault report list */
		fetchData: PropTypes.bool,

		/** Called when a fault report data changes */
		onChange: PropTypes.func,

		/** If true, nothing can be edited or added */
		isReadOnly: PropTypes.bool,
	}

	static defaultProps = {
		faultReportItems: [],
		fetchData: true
	}
	
	constructor() {
		super();
		this.state = {
			isLoading: false,
			isLoadingPicture: false,
			showModal: false,
			isFaultReportReadOnly: false,
			selectedFaultReport: null
		};

		this.handleChangeFaultReport = (field, value) => {
			let { selectedFaultReport } = this.state;
			
			if (field === 'reportedVehicleId') {
				selectedFaultReport.reportedTypeId = '';
				selectedFaultReport.reportedTypeOption = '';
			} else if (field === 'reportedTypeId') {
				selectedFaultReport.reportedTypeOption = '';
			}

			this.setState({
				selectedFaultReport: {
					...selectedFaultReport,
					[field]: value
				}
			});
		};   

		this.handleAddNew = () => {
			return this.setState({ 
				showModal: true,
				isFaultReportReadOnly: false,
				selectedFaultReport: {
					...faultReportSchema,
					FaultReportPhotos: []
				}
			});
		};

		this.handleCloseModal = () => {
			if (this.state.isLoadingPicture) return;

			if (this.props.isReadOnly || this.state.isFaultReportReadOnly || window.confirm('Are you sure that you want to close? The data won\'t be saved')) {
				return this.setState({ 
					showModal: false,
					selectedFaultReport: null
				});
			}
		};

		this.handleEdit = (indexFaultReport) => {
			let selectedFaultReport = window.$.extend(true, {}, this.props.faultReportItems[indexFaultReport]);
			return this.setState({ 
				showModal: true,
				isFaultReportReadOnly: false,
				selectedFaultReport
			});
		};

		this.handleView = (indexFaultReport) => {
			let selectedFaultReport = this.props.faultReportItems[indexFaultReport];
			return this.setState({ 
				showModal: true,
				isFaultReportReadOnly: true,
				selectedFaultReport
			});
		};

		this.handleDelete = (indexFaultReport) => {
			let { faultReportItems } = this.props;
			faultReportItems.splice(indexFaultReport, 1);
			if (this.props.onChange)
				return this.props.onChange(faultReportItems);
		};

		this.handleSave = () => {
			if (window.$(this.toValidate).find('form').valid()) {
				let { faultReportItems } = this.props;
				const faultReport = this.state.selectedFaultReport;

				if (faultReport.index >= 0) {
					let index = faultReport.index;
					faultReportItems[index] = faultReport;
				}
				else
					faultReportItems.push(faultReport);

				this.setState({ 
					showModal: false,
					selectedFaultReport: null
				}, () => {
					this.props.onChange && this.props.onChange(faultReportItems);
				});
			}
		};
		
	}

	componentWillUnmount() {
		return this.unmounted = true;
	}

	UNSAFE_componentWillMount() {
		const { faultReportItems, fetchData, fleetNumbers } = this.props;
		if (faultReportItems.length === 0 && fetchData && fleetNumbers)
			return this.fetchFaultReports();
	}

	fetchFaultReports = () => {
		this.setState({ isLoading: true, error: false });
		VehiclesApi.getFaultReportsList(this.props.fleetNumbers)
			.then(faultReports => {
				if (this.unmounted) return;

				let mergedFaultReports = [].concat.apply([], faultReports);
				this.setState({ isLoading: false });
				if (this.props.onChange)
					return this.props.onChange(mergedFaultReports);
			})
			.catch(error => {
				console.error(error);
				return this.setState({ isLoading: false, error });
			});
	}

	render() {
		let s = this.state;
		let p = this.props;
		
		if (s.error)
			return <ErrorBox error={s.error} retryFunc={this.fetchFaultReports} />;
		else if (s.isLoading)
			return <Loader />;

		return (
			<div>
				<FaultReportList 
					faultReportItems={p.faultReportItems} 
					isReadOnly={p.isReadOnly}
					onView={this.handleView}
					onEdit={this.handleEdit}
					onDelete={this.handleDelete}
				/>
				{
					!p.isReadOnly && (
						<Button 
							className="fault-report__add-new-button"
							bsStyle="primary" 
							block={true}
							onClick={this.handleAddNew}
						>
									Add New Fault Report
						</Button>
					)
				}

				<Modal show={s.showModal} onHide={this.handleCloseModal}>
					<Modal.Header closeButton>
						<Modal.Title>Fault Report</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div ref={ref => this.toValidate = ref}>
							<FaultReport 
								fleetNumbers={p.fleetNumbers}
								faultReportData={s.selectedFaultReport || {}}
								isReadOnly={s.isFaultReportReadOnly}
								onChange={this.handleChangeFaultReport}
								onLoadPicture={(isLoadingPicture) => { this.setState({ isLoadingPicture }); }}
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						{ !s.isFaultReportReadOnly && <Button disabled={s.isLoadingPicture} onClick={this.handleSave} bsStyle="success">Save</Button> }
						<Button disabled={s.isLoadingPicture} onClick={this.handleCloseModal} bsStyle="default">Close</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

export default FaultReportListContainer;