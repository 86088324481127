import React from 'react';
import { Modal, Button, Badge, ProgressBar, Tabs, Tab } from 'react-bootstrap';
import { Loader, ErrorBox, Callout } from '../../../../common/uiElements';
import componentRequestHandler from '../../../../../app/api/helpers/componentRequestHandler';
import { IError } from '../../../../../app/models/Application';
import { ISmsMessageBulkDetails, SmsMessageBulkFormClass, SmsMessageBulkPOST_PUT } from '../../../../../app/models/Communication/SmsMessageBulkModel';
import CommunicationApi from '../../../../../app/api/CommunicationApi';
import $ from'jquery';
import SmsDetailsForm from './SmsDetailsForm';
import WorkersListSelectionModal from '../workersListSelection/WorkersListSelectionModal';
import { WorkerListItemWithExtraData } from '../../../../../app/models/Worker';
import confirm from '../../../../../app/helpers/confirm';
import { getSmsStatus } from '../smsHelper';
import SmsMessagesDetailsList from './SmsMessagesDetailsList';

interface IProps {
	smsMessageBulkId?: number;
	onClose: () => void;
	onCompleteSaving: (smsMessageBulkId?: number) => void;
}

interface IState {
	details: SmsMessageBulkFormClass;
	editMode: boolean;
	isSaving: boolean;
	isLoading: boolean;
	isDeleting: boolean;
	errorSaving?: IError;
	errorLoading?: IError;
	errorDeleting?: IError;
	workersList: WorkerListItemWithExtraData[];
	showWorkersListSelectionModal: boolean;
}

class SmsModal extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = { 
			details: new SmsMessageBulkFormClass(),
			editMode: false,
			isLoading: false,
			isSaving: false,
			isDeleting: false,
			errorLoading: undefined,
			errorSaving: undefined,
			errorDeleting: undefined,
			workersList: [],
			showWorkersListSelectionModal: false
		}
	}

	componentDidUpdate(prevProps: IProps) {
		if (this.props.smsMessageBulkId !== prevProps.smsMessageBulkId)
			this.fetchDetails();
	}	

	componentDidMount() {
		this.fetchDetails();
	}

	fetchDetails = () => {
		if (!this.props.smsMessageBulkId)
			return;

		this.setState({ errorLoading: undefined, isLoading: true });
		CommunicationApi.getSmsMessagesBulkDetails(this.props.smsMessageBulkId)
			.then((details: ISmsMessageBulkDetails) => {
				this.setState({
					editMode: false,
					details: new SmsMessageBulkFormClass(details)
				})
			})
			.catch(errorLoading => this.setState({ errorLoading }))
			.finally(() => this.setState({ isLoading: false }))
	}

	saveNew = () => {
		this.setState({ errorSaving: undefined, isSaving: true });
		const dataToSend = new SmsMessageBulkPOST_PUT(this.state.details);
		CommunicationApi.addSmsMessagesBulk(dataToSend)
		.then(({ dbKeyReference }) => this.props.onCompleteSaving(parseInt(dbKeyReference)))
		.catch(errorSaving => this.setState({ errorSaving }))
		.finally(() => this.setState({ isSaving: false }))
	}

	saveChanges = () => {
		const { smsMessageBulkId } = this.props;
		if (!smsMessageBulkId)
			return;

		const dataToSend = new SmsMessageBulkPOST_PUT(this.state.details);
		const promise = CommunicationApi.updateSmsMessagesBulk.bind(this, smsMessageBulkId, dataToSend);
		componentRequestHandler(this, promise, undefined, {
			loadingAttrName: 'isSaving',
			errorAttrName: 'errorSaving',
			showSuccessNotification: true
		})
		.then(this.fetchDetails)
		.then(() => this.props.onCompleteSaving())
	}

	delete = () => {
		const { smsMessageBulkId } = this.props;
		if (!smsMessageBulkId) 
			return;

		confirm('Are you sure you want to delete this message?', () => {
			const promise = CommunicationApi.deleteSmsMessagesBulk.bind(this, smsMessageBulkId);
			componentRequestHandler(this, promise, undefined, {
				loadingAttrName: 'isSaving',
				errorAttrName: 'errorSaving',
				showSuccessNotification: true
			})
			.then(this.fetchDetails)
			.then(() => this.props.onCompleteSaving())
		})
	}

	changeForm = (newData: object) => {
		this.setState({
			details: {
				...this.state.details,
				...newData
			}
		})
	}
	
	handleChangeForm = (id: string, value: any) => {
		this.changeForm({ [id]: value })
	}

	handleSaveWorkersListSelection = (selectedWorkersList: WorkerListItemWithExtraData[]) => {
		this.setState({
			details: {
				...this.state.details,
				Workers: [...selectedWorkersList]
			}
		}, this.closeWorkersListSelectionModal);
	}

	handleSave = () => {
		if ($('#sms-bulk-form').valid()) {
			if (this.props.smsMessageBulkId)
				this.saveChanges();
			else
				this.saveNew();
		}
	}

	handleToggleEditMode = () => {
		this.setState({ 
			editMode: !this.state.editMode
		})
	}

	handleClickAddOrEditRecipients = () => {
		this.setState({ showWorkersListSelectionModal: true });
	}

	closeWorkersListSelectionModal = () => {
		this.setState({ showWorkersListSelectionModal: false });
	}

	renderStatus = () => {
		const { details } = this.state;

		if (!details || !details.id)
			return;

		const { text, colorClass } = getSmsStatus(details);

		return (
			<Badge className={`status ${colorClass}`}>
				<b className={`text-${colorClass}`}>{ text?.toUpperCase() }</b>
			</Badge>
		)
	}

	renderBodyContent = () => {
		const { state, props } = this;

		if (state.errorLoading)
			return <ErrorBox error={state.errorLoading} retryFunc={this.fetchDetails} />;

		if (state.isSaving || state.isLoading)
			return <Loader isLoading={state.isLoading} isSaving={state.isSaving} />;		


		const isReadOnly = props.smsMessageBulkId && !state.editMode ? true : false;
		const { totalSent, totalDelivered, totalFailed, WorkersReadOnly } = state.details;
		const totalProcessing = totalSent - totalDelivered - totalFailed;

		return (
			<>
				{ state.errorSaving && <ErrorBox error={state.errorSaving} retryFunc={this.saveNew} /> }
				{ state.errorDeleting && <ErrorBox error={state.errorDeleting} retryFunc={this.delete} /> }
				<SmsDetailsForm
					{...state.details}
					isReadOnly={isReadOnly}
					onChange={this.handleChangeForm}
					onClickAddOrEditRecipients={this.handleClickAddOrEditRecipients}
				/>
				{
					isReadOnly && (
						<>
							<h3 className="title">
								Delivery Status
								<div className="pull-right">
									<Badge className="default">
										{totalSent} Sent
									</Badge>
									&nbsp;
									<Badge className="primary">
										{totalProcessing} Processing
									</Badge>
									&nbsp;
									<Badge className="success">
										{totalDelivered} Delivered
									</Badge>
									&nbsp;
									<Badge className="danger">
										{totalFailed} Failed
									</Badge>
								</div>
							</h3>
							{
								state.details.sentAt ? (
									<Tabs defaultActiveKey={1}>
										{
											totalProcessing > 0 && (
												<Tab eventKey={1} title={<b className="text-primary">Processing ({totalProcessing})</b>}>
													<SmsMessagesDetailsList workersList={WorkersReadOnly.filter(p => p.delivered === null)} />
												</Tab>
											)
										}
										{
											totalDelivered > 0 && (
												<Tab eventKey={totalProcessing > 0 ? 2 : 1} title={<b className="text-success">Delivered ({totalDelivered})</b>}>
													<SmsMessagesDetailsList workersList={WorkersReadOnly.filter(p => p.delivered === true)} />
												</Tab>
											)
										}
										{
											totalFailed > 0 && (
												<Tab eventKey={totalProcessing > 0 ? 3 : 2} title={<b className="text-danger">Failed ({totalFailed})</b>}>
													<SmsMessagesDetailsList workersList={WorkersReadOnly.filter(p => p.delivered === false)} />
												</Tab>
											)
										}
									</Tabs>
								) : (
									<Callout color="info">
										This message has not been sent yet.
									</Callout>
								)
							}
						</>
					)
				}
			</>
		)
	}

	render() {
		const { state, props } = this;

		const totalProcessedPercentage = (state.details.totalDelivered + state.details.totalFailed) / state.details.totalRecipients * 100;
		
		return (
			<>
				{
					state.showWorkersListSelectionModal && (
						<WorkersListSelectionModal 
							selectedWorkerIds={state.details.Workers.map(worker => worker.id)}
							onClose={this.closeWorkersListSelectionModal}
							onSave={this.handleSaveWorkersListSelection}
						/>
					)
				}
				<Modal
					show
					id="sms-bulk-modal"
					bsSize="large"
					backdrop="static"
					onHide={props.onClose}
					onEscapeKeyDown={props.onClose}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{ props.smsMessageBulkId ? `Sms Bulk Details #${props.smsMessageBulkId}` : 'Send New Message' }
							&nbsp;{ !state.isLoading && this.renderStatus() }
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{ 
							state.details.sentAt && (
								state.details.isCompleted ? (
									<Callout icon="info-circle" color="success" title="Message Processed">
										The message has already been processed and cannot be edited nor deleted to preserve the integrity of the data.
									</Callout>
								) : (
									<>
										<Callout icon="info-circle" color="info" title="Processing Message">
											The message is being processed... Come back in a few minutes for the results.
										</Callout>
										<ProgressBar 
											active
											striped 
											now={totalProcessedPercentage > 20 ? totalProcessedPercentage : 100 } 
											label={totalProcessedPercentage > 20 ? Math.round(totalProcessedPercentage) + '%' : 'Processing...'} 
										/>
									</>
								)
							)
						}
						{ 
							state.details.isDeleted && (
								<Callout icon="info-circle" color="danger" title="Message Deleted">
									The message was deleted and cannot be edited to preserve the integrity of the data.
								</Callout>
							)
						}
						{ this.renderBodyContent() }
					</Modal.Body>
					{
						!state.isSaving && !state.isLoading && (
							<Modal.Footer>
								{
									(!props.smsMessageBulkId || state.editMode) && (
										<Button style={{ width: 200 }} bsStyle="success" className="pull-left" onClick={this.handleSave} >
											Save
										</Button>
									)
								}
								{
									!state.details.sentAt && (
										<>
											{
												props.smsMessageBulkId && state.editMode && (
													<Button
														bsStyle="danger"
														onClick={this.delete}
													>
														Delete
													</Button>
												)
											}
											{
												props.smsMessageBulkId && !state.editMode && !state.details.isDeleted && (
													<Button 
														bsStyle="warning"
														className="pull-left"
														onClick={this.handleToggleEditMode}
													>
														Edit
													</Button>
												)
											}
										</>
									)
								}
								<Button onClick={props.onClose}>
									Close
								</Button>
							</Modal.Footer>
						)
					}
				</Modal>
			</>
		)
	}
}

export default SmsModal;