export default [
	{ 
		title: 'Go to MESH', 
		icon: 'globe',
		external: true,
		urlPath: 'https://linfrank.sharepoint.com?source=' + window.origin 
	},
	{ 
		title: 'Employment Policies', 
		icon: 'book',
		external: true,
		urlPath: 'https://linfrank.sharepoint.com/Shared%20Documents/Forms/AllItems.aspx?id=%2FShared%20Documents%2FPolicies%20%26%20Procedures&source=' + window.origin 
 },
	{ 
		title: 'Report an Incident', 
		icon: 'warning',
		external: true,
		urlPath: 'https://www.incidentreporting.com.au/IncidentReporting/Ir.aspx?p=Wmlx/ed67xPKrP9mLAe2yY23cCdzagTQCKpGjLzhEQSRjnTW'
	},
	{
		title: 'Business Contacts List',
		icon: 'phone',
		external: true,
		urlPath: 'https://linfrank.sharepoint.com/SitePages/Contact-List.aspx'
	},
	// { 
	// 	title: 'Policies & Procedures', 
	// 	icon: 'book',
	// 	external: true,
	// 	urlPath: 'https://linfrank.sharepoint.com/Shared%20Documents/Forms/AllItems.aspx?viewid=77d2f2f6-6a17-4221-8b36-209871fdd592&id=%2FShared%20Documents%2FPolicies%20%26%20Procedures&source=' + window.origin 
	// },
	// { 
	// 	title: 'Induction', 
	// 	icon: 'graduation-cap',
	// 	external: true,
	// 	urlPath: '{Link}' + window.origin 
	// },
	// { 
	// 	title: 'Toolboxes', 
	// 	icon: 'check-square-o',
	// 	external: true,
	// 	urlPath: '{Link}' + window.origin 
	// }
]