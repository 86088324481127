import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import CompanyApi from '../../../../app/api/CompanyApi';
import { ErrorBox, Loader } from '../../../common/uiElements';

import CompanyForm from './CompanyForm';
import CompanyView from './CompanyView';

const propTypes = {
	abn: PropTypes.string,
	show: PropTypes.bool,
	onClose: PropTypes.func
};

class CompanyDetailsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEditMode: false,
			isLoading: false,
			error: null,
			companyData: null
		};
	}

	componentDidMount() {
		if (this.props.abn)
			this.fetchCompanyData();	
	}	

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchCompanyData = () => {
		this.setState({ isLoading: true, error: null });
		CompanyApi.getCompany(this.props.abn)
			.then(companyData => {
				if (this.unmounted) return;

				this.setState({
					isLoading: false,
					companyData
				});
			})
			.catch(error => {
				console.error(error);
				this.setState({ isLoading: false, error });
			});
	}

	checkIfUserWantsToClose = () => {
		return window.confirm('Do you really want to continue? All changes that you have done won\'t be saved.');
	}

	handleClose = () => {
		if (!this.state.isEditMode || this.checkIfUserWantsToClose())
			return this.props.onClose();
	}

	handleEditClickBack = () => {
		if (this.checkIfUserWantsToClose())
			this.handleToggleEditMode(false);
	}

	handleToggleEditMode = (isEditMode) => {
		this.setState({ isEditMode });
	}

	handleInactivate = () => {
		this.setState({ isEditMode: false, companyData: null, error: null, isLoading: false });
		this.props.onClose(true);
	}

	renderContent = () => {
		const s = this.state;

		if (s.error)
			return <ErrorBox error={s.error} retryFunc={this.fetchCompanyData} />;

		if (s.isLoading)
			return <Loader text="Loading data. Please wait..." />;

		if (s.isEditMode)
			return (
				<CompanyForm
					isEditMode
					abn={this.props.abn}
					onClickBack={this.handleEditClickBack}
					onSave={this.handleToggleEditMode.bind(this, false)}
					onInactivate={this.handleInactivate}
				/>
			);

		return (
			<CompanyView
				abn={this.props.abn}
				onClickEdit={this.handleToggleEditMode.bind(this, true)}
			/>
		);
	}

	render() {
		const p = this.props;

		return (
			<Modal bsSize="lg" show={p.show} onHide={this.handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Company Details</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ this.renderContent() }
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.handleClose} bsStyle="default">Close</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

CompanyDetailsModal.propTypes = propTypes;

export default CompanyDetailsModal;