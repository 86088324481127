// TODO export methods properly

import * as get from './methods/get';
import * as post from './methods/post';
import * as put from './methods/put';
import {  deleteMethod } from './methods/delete';
import * as utils from './utils';

export default {
	...get,
	...post,
	...put,
	delete: deleteMethod,
	...utils
}