import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormControl } from 'react-bootstrap';

const propTypes = {
	color: PropTypes.oneOf(['default', 'primary', 'info', 'success', 'warning', 'danger']),
	maxLength: PropTypes.number,
	maxInputLength: PropTypes.number,
	onChange: PropTypes.func,
	placeholder:  PropTypes.string,
	type: PropTypes.oneOf(['text', 'number', 'email', 'tel']),
	valuesList: PropTypes.arrayOf(PropTypes.string)
};

const defaultProps = {
	color: 'default',
	type: 'text'
};

class MultipleValuesInput extends Component {
	constructor(props) {
		super();

		this.state = {
			value: '',
			valuesList: props.valuesList || [],
			isFocused: false
		};
	}

	handleChange = (e) => {
		return this.setState({
			value: e.target.value
		});
	}

	handleKeyPress = (e) => {
		if (e.key === 'Enter' || (this.props.type === 'email' && e.key === ';')) {
			e.preventDefault();
			return this.addItem(this.state.value);
		}
	}
	
	handleClickMain = () => {
		return this.input.focus();
	}

	addItem = (value) => {
		let { valuesList } = this.state;
		let { type } = this.props;
		if (value.length > 0) {
			
			if (type === 'email' && !this.validateEmail(value))
				return this.setState({ error: 'This Email is invalid' });

			valuesList.push(value);
			this.setState({ 
				error: false,
				value: '',
				valuesList 
			});

			if (this.props.onChange) {
				this.props.onChange(valuesList);
			}
		}
	}

	removeItem = (index) => {
		let { valuesList } = this.state;
		valuesList.splice(index, 1);
		return this.setState({ 
			valuesList 
		});
	}

	toggleFocused = () => {
		this.addItem(this.state.value);
		return this.setState({
			isFocused: !this.state.isFocused
		});
	}

	validateEmail = (email) => {
		let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		let isValid = reg.test(email);
		return isValid;
	}

	render() {
		let s = this.state;
		let p = this.props;

		return (
			<form>
				<div onClick={this.handleClickMain} 
					className={
						classNames('mvi-main', {'mvi-focused': s.isFocused}, {'error': s.error})
					}
				>
					<ul>
						{
							s.valuesList.map((value, i) => {
								return (
									<li className={classNames('mvi-item', [`mvi-${p.color}`])} key={i}>
										<div className="mvi-value">{ value }</div>
										<div className="mvi-remove" onClick={() => { this.removeItem(i); }}></div>
									</li>
								);
							})
						}
					</ul>
					<FormControl  
						ref={ref => this.input = ref} 
						type={p.type} 
						value={s.value} 
						placeholder={p.placeholder}
						readOnly={s.valuesList.length === p.maxLength} 
						maxLength={p.maxInputLength} 
						style={{width: `${s.value.length < 15 ? 130 : ((s.value.length) * 9)}px`}}
						onChange={this.handleChange} onKeyPress={this.handleKeyPress}
						onFocus={this.toggleFocused} onBlur={this.toggleFocused}
					/>
				</div>
				{
					s.error && <label className="error">{ s.error }</label>
				}
			</form>
		);
	}
}

MultipleValuesInput.propTypes = propTypes;
MultipleValuesInput.defaultProps = defaultProps;

export { MultipleValuesInput };