/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import Icon from 'react-fontawesome';
import './HelperLink.css';

class HelperLink extends Component {
	handleClick = () => {
		sessionStorage.setItem('shouldShowGuideTour', true);
		window.location.reload();
	}

	componentDidMount() {
		setTimeout(() => {
			sessionStorage.removeItem('shouldShowGuideTour');
		}, 15000);
	}
	
	render() {
		return (
			<a onClick={this.handleClick} title="Start a Guide Helper">
				<Icon name="question-circle" className="helper-link animated rubberBand" />
			</a>
		);
	}
}

export { HelperLink };