import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col, FormGroup, FormControl } from 'react-bootstrap';

import { Checkbox, DatePeriodInput, VehicleSearch, UserSearch } from '../../../../common/inputs';
import { PlacesList } from '../../../../common/lists';

const propTypes = {
	hideDriverName: PropTypes.bool,
	startTimeLocal: PropTypes.string,
	endTimeLocal: PropTypes.string,
	workerId: PropTypes.string,
	vehicle: PropTypes.string,
	depot: PropTypes.string,
	status: PropTypes.string,
	hasFaultReport: PropTypes.bool,
	hasVehicleDamage: PropTypes.bool,
	hasMechanicalIssues: PropTypes.bool,
	hasComplianceIssue: PropTypes.bool,
	onChange: PropTypes.func
};

const PreStartListFilter = ({
	startTimeLocal,
	endTimeLocal,
	hideDriverName,
	workerId,
	vehicle,
	depot,
	status,
	hasVehicleDamage,
	hasFaultReport,
	hasMechanicalIssues,
	hasComplianceIssue,
	onChange
}) => {
	const handleChange = (e) => {
		const id = e.target.id;
		const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
		onChange && onChange({ [id]: value });
	};

	const handleChangeVehicle = ({ vehFleetNumber }) => {
		onChange && onChange({ vehicle: vehFleetNumber || '' });
	};

	const handleChangeUser = user => {
		onChange && onChange({ workerId: user.value });
	};

	const handleChangePeriod = (startTimeLocal, endTimeLocal) => {
		startTimeLocal = startTimeLocal.format('YYYY-MM-DD HH:mm');
		endTimeLocal = endTimeLocal.format('YYYY-MM-DD HH:mm');
		onChange && onChange({ startTimeLocal, endTimeLocal });
	};

	return (
		<div>
			<Row>
				<Col sm={7} lg={4}>
					<FormGroup>
						<label>Period</label>
						<DatePeriodInput 
							startDate={moment(startTimeLocal)}
							endDate={moment(endTimeLocal)}
							showDefaultRanges={true} 
							showHours={true}
							onChange={handleChangePeriod}/>
					</FormGroup>
				</Col>
				{
					!hideDriverName && (
						<Col sm={5} lg={4}>
							<FormGroup>
								<label>Driver</label>
								<UserSearch 
									id="workerId" 
									value={workerId} 
									onlyActive={false}
									onChange={handleChangeUser}
								/>
							</FormGroup>
						</Col>
					)
				}
				<Col sm={4}>
					<FormGroup>
						<label>Vehicle</label>
						<VehicleSearch 
							id="vehicle" 
							value={vehicle} 
							onChange={handleChangeVehicle} 
						/>
					</FormGroup>
				</Col>
				<Col sm={4} lg={3}>
					<FormGroup>
						<label>Depot</label>
						<PlacesList id="depot" type="preStart" value={depot} showAllOption={true} onChange={handleChange} />
					</FormGroup>
				</Col>
				<Col sm={4} lg={2}>
					<FormGroup>
						<label>Status</label>
						<FormControl componentClass="select" value={status} id="status" onChange={handleChange}>
							<option value="">All</option>
							<option value="approved">Approved</option>
							<option value="notApproved">Not Approved</option>
							<option value="opened">Opened</option>
						</FormControl>
					</FormGroup>
				</Col>
				<Col sm={12} lg={7}>
					<label>With:</label>
					<ul className="list-inline">
						<li>
							<Checkbox 
								id="hasFaultReport"
								text="Fault Report"
								checked={hasFaultReport}
								onChange={handleChange}
							/>
						</li>
						<li>
							<Checkbox 
								id="hasVehicleDamage"
								text="Vehicle Damage"
								checked={hasVehicleDamage}
								onChange={handleChange}
							/>
						</li>
						<li>
							<Checkbox 
								id="hasMechanicalIssues"
								text="Mechanical Issue"
								checked={hasMechanicalIssues}
								onChange={handleChange}
							/>
						</li>
						<li>
							<Checkbox 
								id="hasComplianceIssue"
								text="Compliance Issue"
								checked={hasComplianceIssue}
								onChange={handleChange}
							/>
						</li>
					</ul>
				</Col>
			</Row>
		</div>
	);
};

PreStartListFilter.propTypes = propTypes;

export default PreStartListFilter;