const root = '/Microservice/PalletLoader';
const endpoints = {
	root,
	search: `${root}/Search`,
	panel: `${root}/Panel`,
	exportReport: `${root}/Report`,
	byId: (loadId: string) => `${root}/${loadId}`,
	loadAuthorisation: (loadId: string) => `${root}/${loadId}/LoadAuthorisation`,
	loadSummary: (loadId: string) => `${root}/${loadId}/LoadSummary`
};

export default endpoints;