import React from 'react';

import { MainContent } from '../../../common/layout';
import CompanyForm from './CompanyForm';

const CompanyAddNewContainer = () => (
    <MainContent title="Add New Company">
        <CompanyForm />
    </MainContent>
);

export default CompanyAddNewContainer;