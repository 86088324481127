/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VMasker from 'vanilla-masker';

const propTypes = {
	id: PropTypes.string,
	refs: PropTypes.func,
	validations: PropTypes.object,
	messages: PropTypes.object,
	data: PropTypes.object,
	className:PropTypes.string, 
	onSubmit: PropTypes.func,
	children: PropTypes.any,
};

const defaultProps = {
	className: ''
};

class Form extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: []
		};
	}

	componentDidMount() {
		this.renderValidations();
		this.props.refs && this.props.refs(this);
	}

	componentDidUpdate() {
		if (this.getCurrentFieldsLength() !== this.state.fields.length)
			this.renderValidations();
	}

	submit = event => {
		event && event.preventDefault();
		if (window.$(this.form).valid() && this.props.onSubmit)
			return this.props.onSubmit(this.props.data);
	}

	renderValidations = () => {
		var form = window.$(this.form);
		this.renderRequiredFields();

		// Validation
		form.validate({
			rules: this.props.validations,
			messages: this.props.messages,
			onfocusout: false,
			ignore: '.ignoreValidation',
			invalidHandler: function(form, validator) {
				let errors = validator.numberOfInvalids();
				if (errors) {                    
					let element = validator.errorList[0].element;
					let container = window.$('.modal').length === 0 ? window.$('html, body') : window.$('.modal-body');
					container.animate({
						scrollTop: window.$(element).offset().top - 250
					}, 600, () => element.focus());

				}
			},
			errorPlacement: function(error, element) {
				const $previous = window.$(window.$(element).prev());
				const parent = window.$(element).parent('.input-group, .signature-box');
				const type = window.$(element).attr('type');

				if ($previous.hasClass('Select'))
					$previous.find('.Select-control').addClass('invalid');
				
				if (window.$(parent).hasClass('signature-box'))
					window.$(parent).addClass('invalid');

				if (parent.length > 0) {
					window.$(parent).after(error);
				} else if (type == 'radio') {
					window.$(element).parents('.radio-list').after(error)
						.find('label').addClass('invalid');
				} else if (type == 'checkbox') {
					window.$(element).parents('.checkbox').find('label').addClass('invalid');
				} 
				else {
					error.insertAfter(element);
				}
			}
		});

		window.$('.phone').attr('maxlength', 12);
		window.$('.abn').attr('maxlength', 14);
		window.$('.tfn').attr('maxlength', 11);
		VMasker(window.$('.phone')).maskPattern('9999 999 999');
		VMasker(window.$('.abn')).maskPattern('99 999 999 999');
		VMasker(window.$('.tfn')).maskPattern('999 999 999');
		VMasker(window.$('.number')).maskPattern('999 999 999');
	}

	renderRequiredFields = () => {
		var fields = window.$(this.form).find('input, textarea, select');
		for (var i = fields.length - 1; i >= 0; i--) {
			var id = fields[i].id;
			fields[i].name = id;
		}

		this.setState({ fields });
	}

	getCurrentFieldsLength = () => {
		let fieldsList = window.$(this.form).find('input, textarea, select');
		let fields = [];
		for (var i = fieldsList.length - 1; i >= 0; i--) {
			fields.push(fieldsList[i].id);
		}
		
		return fields.length;
	}

	render() {
		const { id, className, children } = this.props;
		return (
			<div className={className}>
				<form id={id} ref={ref => this.form = ref} onSubmit={this.submit}>
					{children}
				</form>
			</div>
		);
	}
}

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export { Form };