import moment from 'moment';
import 'moment-duration-format';

export default {
    calculateTotalTime(startTimeUTC, finishTimeUTC) {
        if (!moment(startTimeUTC).isValid() || !moment(finishTimeUTC).isValid())
            return "-";
            
        return moment.duration(moment(finishTimeUTC).diff(moment(startTimeUTC))).format('hh:mm', { trim: false });
    }
}