import React, { Fragment } from 'react';
import { IWorkerBasicInfo } from '../../../app/models/Worker';
import { Popover, Overlay, OverlayTrigger } from 'react-bootstrap';
import { uniqueId } from 'lodash';
import { ILeaveRequestSimple } from '../../../app/models/LeaveRequest';
import moment from 'moment';

interface IProps {
	worker?: IWorkerBasicInfo;
	leaveRequest: ILeaveRequestSimple;
	show?: boolean;
	targetElementId?: string;
}

export const WorkerLeaveRequestOverlayTrigger: React.FC<IProps> = props => {
	if (!props.worker || !props.leaveRequest)
		return (
			<Fragment>
				{ props.children }
			</Fragment>
		);

	const { isApproved, absenceReasonTypeName, startDate, endDate } = props.leaveRequest;

	const title = (
		<b className="text-danger">
			{ isApproved ? 'Approved' : 'Pending'} Leave Request
		</b>
	);

	const content = (
		<Popover title={title} id={'tooltip_roster_' + uniqueId()}>
			{ props.worker.firstName} has { isApproved ? 'an' : 'a' } <b>{ isApproved ? 'approved' : 'pending' }</b> <b>{absenceReasonTypeName} Request</b> from <b>{moment(startDate).format('DD/MM')}</b> to <b>{moment(endDate).format('DD/MM')}</b> and might not be able to execute this task.
			Please check the employee's availability for this date.
		</Popover>
	)
	if (props.show && props.targetElementId)
		return (
			<div>
				{ props.children }
				<Overlay
					show
					placement="top" 
					target={() => document.getElementById(props.targetElementId!)}
				>
					{ content }
				</Overlay>
			</div>
		)

	return (
		<OverlayTrigger
			placement="top" 
			overlay={content}
		>
			{ props.children }
		</OverlayTrigger>
	)
}