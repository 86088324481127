export default {
	root: '/PreStart',
	checkVehicleIsNotOverloaded: '/PreStart/CheckVehicleIsNotOverloaded',
	byId: (preStartId: string) => `/PreStart/${preStartId}`,
	workerSignature: (preStartId: string) => `/PreStart/${preStartId}/ReportedBySignature`,
	checkOut: (preStartId: string) => `/PreStart/${preStartId}/CheckOut`,
	acknowledge: (preStartId: string) => `/PreStart/${preStartId}/CheckOut/Acknowledge`,
	notifySupervisor: (preStartId: string, supervisorId: string) => `/PreStart/${preStartId}/NotifySupervisor/${supervisorId}`,
	addRuns: (preStartId: string) => `/PreStart/${preStartId}/AddRun`,
	registerWorkHoursBreach: (preStartId: string) => `/PreStart/${preStartId}/NotEnoughHoursToCompleteTask`
};