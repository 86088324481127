import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from 'react-fontawesome';

import { FormControl, Row, Col, Tab, Nav, NavItem, FormGroup } from 'react-bootstrap';

import helper from '../helper';

import PreStartApi from '../../../../../app/api/PreStartApi';

// Step Components
import GeneralInformation from '../firstPart/steps/GeneralInformation';
import FaultReportListContainer from '../FaultReportListContainer';
import SecurityQuestions from '../firstPart/steps/SecurityQuestions';
import VehicleMechanicalCheck from '../firstPart/steps/VehicleMechanicalCheck';
import VehicleItemsCheck from '../firstPart/steps/VehicleItemsCheck';
import VehicleDamagesComponent from '../../../vehicles/VehicleDamages';
import DepotReturnInformation from '../lastPart/steps/DepotReturnInformation';

import { ContentBox, ErrorBox, Loader, UserProfilePicture, UserDetailsLink, Callout } from '../../../../common/uiElements';
import MassManagement from '../firstPart/steps/MassManagement';
import DurationParse from '../../../../../app/helpers/DurationParse';
import { GoogleMapContainer } from '../../../../common/googleMaps';
import { VehicleBreadcrumbsList } from '../../../../common/tracking';

const propTypes = {
	preStartId: PropTypes.string,
	data: PropTypes.object,
	showLastPartTab: PropTypes.bool
};

class PreStartViewDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			error: false,
			isLoading: false,
			showBreadcrumbs: false,
			fleetNumberJourney: ''
		};
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	UNSAFE_componentWillMount() {
		var { data } = this.props;

		if (data)
			return this.setState({ 
				data,
				fleetNumberJourney: data.mainVehFleetNumber
			});

		this.fetchData();
	}
	
	fetchData = () => {
		let { preStartId } = this.props;
		preStartId = preStartId || (this.props.params && this.props.params.preStartId);

		if (preStartId) {
			this.setState({ isLoading: true, error: false });
			PreStartApi.getPreStart(preStartId)
				.then(data => {
					if (this.unmounted) return;
					
					this.setState({ 
						data, 
						fleetNumberJourney: data.mainVehFleetNumber,
						isJourneyOverMaxFilterPeriod: moment.duration(moment(data.lastReportedOnLocal).diff(data.inspectedOnLocal)).asMinutes() >= 2880,
						isLoading: false
					});
				})
				.catch(error => {
					console.error(error);
					return this.setState({ isLoading: false, error });
				});
		}
	}

	handleChangeMainTab = currentTab => {
		if (currentTab === 'journey') {
			this.setState({ showBreadcrumbs: true });
		}
	}

	handleChangeVehicleJourney = e => {
		this.setState({fleetNumberJourney: e.target.value });
	}
    
	render() {
		const p = this.props;
		const s = this.state;
		let status;

		let FaultReports, VehicleDamages, FaultReportsLast, VehicleDamagesLast;
		if (s.data) {
			FaultReports = s.data.FaultReports.filter(f => !f.isLastPreStartReport);
			VehicleDamages = s.data.VehicleDamages.filter(v => !v.isLastPreStartReport);

			FaultReportsLast = s.data.FaultReports.filter(f => f.isLastPreStartReport);
			VehicleDamagesLast = s.data.VehicleDamages.filter(v => v.isLastPreStartReport);

			status = helper.renderStatus(s.data);
		}

		if (s.error)
			return <ErrorBox error={s.error} retryFunc={this.fetchData} />;

		if (s.isLoading)
			return <Loader text="Loading data. Please wait..."/>;

		return (
			<Row>
				<Col md={4} lg={3}>
					<div className="box box-primary">
						<div className="box-body box-profile">
							<UserProfilePicture className="profile-user-img img-responsive" azureId={s.data.WorkerReportedBy.azureId}  />
							<h3 className="profile-username text-center">
								<UserDetailsLink azureId={s.data.WorkerReportedBy.azureId}>
									<b>{ s.data.WorkerReportedBy.shortName }</b>
								</UserDetailsLink>
							</h3>
						</div>
					</div>
					<ContentBox color={status.statusColor}>
						<h4 className={`text-${status.statusColor}`}>
							<b>{status.statusText}</b>
						</h4>
					</ContentBox>
					<ContentBox title="First Part" color="primary">
						<ul className="list list-unstyled">
							{
								s.data.processStartedOnLocal && (
									<li>
										<b>Open Pre-Start</b>
										<span className="pull-right">
											{ moment(s.data.processStartedOnLocal).format('DD/MM/YY HH:mm') }
										</span>
									</li>
								)
							}
							{
								s.data.firstPartTimeSpentInSec && (
									<li>
										&nbsp;
										<span className={`pull-right ${helper.isThereProblemFirstPartTimeSpent(s.data.firstPartTimeSpentInSec) ? 'text-danger' : 'text-success'}`}>
											{ DurationParse.toDayHourMinSec(s.data.firstPartTimeSpentInSec, 'short') }
										</span>
									</li>
								)
							}
							<li>
								<b>Submitted</b>
								<span className="pull-right">
									{ moment(s.data.inspectedOnLocal).format('DD/MM/YY HH:mm') }
								</span>
							</li>
							{
								s.data.checkoutTimeSpentInSec && (
									<li>
										&nbsp;
										<span className={`pull-right ${s.data.checkoutTimeSpentInSec > (15 * 60)  ? 'text-danger' : 'text-success'}`}>
											{ DurationParse.toDayHourMinSec(s.data.checkoutTimeSpentInSec, 'short') }
										</span>
									</li>
								)
							}
							<li>
								<b>Checked Out</b>
								<span className="pull-right">
									{ moment(s.data.checkOutOnLocal).isValid() ? moment(s.data.checkOutOnLocal).format('DD/MM/YY HH:mm') : '-' }
								</span>
							</li>
						</ul>
					</ContentBox>
					<ContentBox title="Last Part" color="primary">
						<ul className="list list-unstyled">
							{
								s.data.lastPartProcessStartedOnLocal && (
									<li>
										<b>Open Pre-Start</b>
										<span className="pull-right">
											{ moment(s.data.lastPartProcessStartedOnLocal).format('DD/MM/YY HH:mm') }
										</span>
									</li>
								)
							}
							{
								s.data.lastPartTimeSpentInSec && (
									<li>
										&nbsp;
										<span className={`pull-right ${helper.isThereProblemLastPartTimeSpent(s.data.lastPartTimeSpentInSec) ? 'text-danger' : 'text-success'}`}>
											{ DurationParse.toDayHourMinSec(s.data.lastPartTimeSpentInSec, 'short') }
										</span>
									</li>
								)
							}
							<li>
								<b>Submitted</b>
								<span className="pull-right">
									{ moment(s.data.lastReportedOnLocal).isValid() ? moment(s.data.lastReportedOnLocal).format('DD/MM/YY HH:mm') : '-' }
								</span>
							</li>
							{
								s.data.acknowledgeTimeSpentInSec && (
									<li>
										&nbsp;
										<span className={`pull-right ${s.data.acknowledgeTimeSpentInSec > (15 * 60)  ? 'text-danger' : 'text-success'}`}>
											{ DurationParse.toDayHourMinSec(s.data.acknowledgeTimeSpentInSec, 'short') }
										</span>
									</li>
								)
							}
							<li>
								<b>Acknowledged</b>
								<span className="pull-right">
									{ moment(s.data.lastAcknowledgeOnLocal).isValid() ? moment(s.data.lastAcknowledgeOnLocal).format('DD/MM/YY HH:mm') : '-' }
								</span>
							</li>
						</ul>
					</ContentBox>
				</Col>
				<Col md={8} lg={9}>
					<Tab.Container id="mainPreStartTab" defaultActiveKey={p.showLastPartTab ? 'last' : 'first'}>
						<div>
							<Nav style={{background: '#fff'}} bsStyle="pills" onSelect={this.handleChangeMainTab}>
								<NavItem eventKey="first"> 
									<Icon name="sign-in" /> First
								</NavItem>
								<NavItem eventKey="last">
									<Icon name="sign-out" /> Last
								</NavItem>
								<NavItem eventKey="faultReports">
									<Icon name="warning" />
									<span className="hidden-xs">&nbsp;Fault Reports ({s.data.FaultReports.length})</span>
								</NavItem>
								<NavItem eventKey="vehicleDamages">
									<Icon name="camera" />
									<span className="hidden-xs">&nbsp;Damages ({s.data.VehicleDamages.length})</span>
								</NavItem>
								<NavItem eventKey="runs">
									<Icon name="truck" />
									<span className="hidden-xs">&nbsp;Runs ({s.data.PreStartRuns.length})</span>
								</NavItem>
								<NavItem eventKey="journey">
									<Icon name="map" />
									<span className="hidden-xs">&nbsp;Journey</span>
								</NavItem>
							</Nav>
							<Tab.Content style={{padding: '10px 0 0'}}>
								<Tab.Pane eventKey="first">
									<Tab.Container id="firstPreStartTab" className="nav-tabs-custom" defaultActiveKey="generalInfo">
										<div>
											<Nav bsStyle="tabs">
												<NavItem eventKey="generalInfo">
													<Icon name="info-circle" />&nbsp;
													<span className="hidden-sm hidden-xs">General</span>
												</NavItem>
												<NavItem eventKey="securityQuestions">
													<Icon name="lock" />&nbsp;
													<span className="hidden-sm hidden-xs">Compliance</span>
												</NavItem>
												<NavItem eventKey="mechanicalCheck">
													<Icon name="wrench" />&nbsp;
													<span className="hidden-sm hidden-xs">Mechanical</span>
												</NavItem>
												<NavItem eventKey="itemsCheck">
													<Icon name="check" />&nbsp;
													<span className="hidden-sm hidden-xs">Items</span>
												</NavItem>
											</Nav>
											<Tab.Content>
												<Tab.Pane eventKey="generalInfo">
													<GeneralInformation readOnly showTitle={false} data={s.data} />
												</Tab.Pane>
												<Tab.Pane eventKey="securityQuestions">
													<SecurityQuestions readOnly showTitle={false} data={s.data} />
												</Tab.Pane>
												<Tab.Pane eventKey="mechanicalCheck">
													<VehicleMechanicalCheck readOnly showTitle={false} data={s.data} />
												</Tab.Pane>
												<Tab.Pane eventKey="itemsCheck">
													<VehicleItemsCheck readOnly showTitle={false} data={s.data} />
												</Tab.Pane>
											</Tab.Content>
										</div>
									</Tab.Container>
									{
										s.data.checkOutById && (
											<ContentBox color="primary" title="Checkout Details">
												<Row>
													<Col sm={4}>
														<FormGroup>
															<label>Checked Out By</label>
															<p>
																<UserDetailsLink azureId={s.data.WorkerCheckOutBy.azureId}>
																	{ s.data.WorkerCheckOutBy.shortName }
																</UserDetailsLink>
															</p>
														</FormGroup>
													</Col>
													<Col sm={8}>
														<FormGroup>
															<label>Comments</label>
															<p>
																{ s.data.checkOutComments || '-' }
															</p>
														</FormGroup>
													</Col>
												</Row>
											</ContentBox>
										)
									}
								</Tab.Pane>
								<Tab.Pane eventKey="last">
									<ContentBox color="primary">
										{
											!s.data.lastReportedOnLocal ? (
												<i>The driver has not finished this Pre-Start yet.</i>
											) : (
												<DepotReturnInformation isReadOnly={true} data={s.data} />
											)
										}
									</ContentBox>
									{
										s.data.lastAcknowledgeById && (
											<ContentBox color="primary" title="Acknowledge Details">
												<Row>
													<Col sm={4}>
														<FormGroup>
															<label>Acknowledged By</label>
															<p>
																<UserDetailsLink azureId={s.data.WorkerAcknowledgeBy.azureId}>
																	{ s.data.WorkerAcknowledgeBy.shortName }
																</UserDetailsLink>
															</p>
														</FormGroup>
													</Col>
													<Col sm={8}>
														<FormGroup>
															<label>Comments</label>
															<p>
																{ s.data.lastAcknowledgeComments || '-' }
															</p>
														</FormGroup>
													</Col>
												</Row>
											</ContentBox>
										)
									}
								</Tab.Pane>
								<Tab.Pane eventKey="faultReports">
									<ContentBox color="primary" title="First Part">
										<FaultReportListContainer 
											faultReportItems={FaultReports}
											isReadOnly
											isEditable
										/>
									</ContentBox>
									<ContentBox color="primary" title="Last Part">
										<FaultReportListContainer 
											faultReportItems={FaultReportsLast}
											isReadOnly
											isEditable
										/>
									</ContentBox>
								</Tab.Pane>
								<Tab.Pane eventKey="vehicleDamages">
									<ContentBox color="primary" title="First Part">
										{
											VehicleDamages.length > 0 ? (
												<VehicleDamagesComponent vehicleDamages={VehicleDamages}/>
											) : (
												<p><i>No damage was registered</i></p>
											)
										}
									</ContentBox>
									<ContentBox color="primary" title="Last Part">
										{
											s.data.lastDamageDescription ? (
												<div>
													<FormGroup>
														<label>What happened?</label>
														<p>{s.data.lastDamageDescription}</p>
													</FormGroup>
													<FormGroup>
														<label><Icon name="camera" /> Photos</label>
														<VehicleDamagesComponent vehicleDamages={VehicleDamagesLast}/>
													</FormGroup>
												</div>
											) : (
												<p><i>No damage was registered</i></p>
											)
										}
									</ContentBox>
								</Tab.Pane>
								<Tab.Pane eventKey="runs">
									<ContentBox color="primary">
										<MassManagement 
											readOnly 
											showTitle={false} 
											data={s.data}
											mainVehFleetNumber={s.data.mainVehFleetNumber}
											trailer1VehFleetNumber={s.data.trailer1VehFleetNumber}
											trailer2VehFleetNumber={s.data.trailer2VehFleetNumber}
											palletJackVehFleetNumber={s.data.palletJackVehFleetNumber}
										/>
									</ContentBox>
								</Tab.Pane>
								<Tab.Pane eventKey="journey">
									<ContentBox title="Fleet Number" color="primary">
										<FormControl 
											id="fleetNumberJourney" 
											componentClass="select" 
											value={s.fleetNumberJourney} 
											onChange={this.handleChangeVehicleJourney}
										>
											<option value={s.data.mainVehFleetNumber}>{ s.data.mainVehFleetNumber }</option>
											{ s.data.trailer1VehFleetNumber && <option value={s.data.trailer1VehFleetNumber}>{ s.data.trailer1VehFleetNumber }</option> }
											{ s.data.trailer2VehFleetNumber && <option value={s.data.trailer2VehFleetNumber}>{ s.data.trailer2VehFleetNumber }</option> }
											{ s.data.palletJackVehFleetNumber && <option value={s.data.palletJackVehFleetNumber}>{ s.data.palletJackVehFleetNumber }</option> }
										</FormControl>
									</ContentBox>
									<ContentBox color="primary">
										{
											s.isJourneyOverMaxFilterPeriod && (
												<Callout color="warning">
													<b>The journey below was limited to 12 hours after the submission of the first part as the driver took more than 2 days to complete the last part.</b>
												</Callout>
											)
										}
										<GoogleMapContainer 
											refs={ref => this.map = ref}
											defaultZoom={10}
											height="50vh"
										>
											{
												s.showBreadcrumbs && (
													<VehicleBreadcrumbsList 
														map={this.map}
														startTime={s.data.inspectedOnLocal}
														finishTime={s.data.lastReportedOnLocal}
														vehFleetNumber={s.fleetNumberJourney}
														onLoadBreadcrumbComplete={this.fitBreadcrumbsOnMap}
													/>
												)
											}
										</GoogleMapContainer>
									</ContentBox>
								</Tab.Pane>
							</Tab.Content>
						</div>
					</Tab.Container>
				</Col>
			</Row>
		);
	}
}

PreStartViewDetails.propTypes = propTypes;

export default PreStartViewDetails;


   
