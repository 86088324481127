import React, { useMemo } from 'react';
import { uniqueId } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';

interface IProps {
	text?: string;
	children?: string;
	placement?: 'top' | 'bottom' | 'left' | 'right';
	className?: string;
}

const TooltipComponent: React.FC<IProps> = props => {
	const tooltip = useMemo(() => (
		<Tooltip id={`tooltip_${uniqueId()}`}>
			{props.text || props.children }
		</Tooltip>
	), [props.children, props.text]);

	return (
		<OverlayTrigger placement={props.placement || 'top'} overlay={tooltip}>
			<FontAwesome className={props.className} name="question-circle" style={{ cursor: 'pointer' }} />
		</OverlayTrigger>
	)
}

export { TooltipComponent as Tooltip };
