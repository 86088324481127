import React from 'react';
import ListsApi from '../../../app/api/ListsApi';
import ListContainer from './_ListContainer';
import { FormControlProps } from 'react-bootstrap';

interface IProps extends FormControlProps {
	updateReasonCategoryId?: string;
}

export const TaskUpdateReasonsList: React.FC<IProps> = props => (
	<ListContainer 
		{...props as any}
		promise={() => ListsApi.getTaskUpdateReasons(props.updateReasonCategoryId ? parseInt(props.updateReasonCategoryId) : undefined)}
	/>
);