export default {
	//ADMINS
	ADMIN: 'TMSAccessAdmin',
	ADMIN_STAFF_FULL: 'TMSAccessAdmStaffFull',
	ADMIN_STAFF_SIMPLE: 'TMSAccessAdmStaffSimple',

	// WASHBAY
	WASHBAY_ADMIN: 'TMSAccessWashbayAdmin',
	WASHBAY_MECHANICS: 'TMSAccessWashbayMechanics',
	WASHBAY_OTHERS: 'TMSAccessWashbayOthers',

	// DRIVERS
	DRIVERS: 'TMSAccessDrivers',
	DRIVERS_EXTERNAL: 'TMSAccessDriversExternal',

	// DEPOT
	DEPOT_ADMIN: 'TMSAccessDepotAdmin',
	DEPOT_LOADERS: 'TMSAccessDepotLoaders',

	// OTHERS
	SUPERVISORS: 'TMSAccessSupervisors',
	KIOSK: 'TMSAccessKiosk',
	TV: 'TMSAccessTV'
};