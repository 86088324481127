import moment from 'moment';
import React, { ChangeEvent, Fragment } from 'react';
import { FormControl, InputGroup, Row } from 'react-bootstrap';
import { numberMask } from '../../../../../app/helpers/numbersHelper';
import { IFormField } from '../../../../../app/models/Application';
import { IVehicleRegoNew, VehicleDetailsForm as VehicleDetailsFormClass, VehicleTypes } from '../../../../../app/models/Vehicle';
import { AustralianStatesList, CompaniesList, VehicleMakesList, VehicleTrackingSettingsList, VehicleTypesList } from '../../../../common/lists';
import { FieldGroup, Form } from '../../../../common/uiElements';
import { YesNoRadio } from '../../../../common/inputs';

interface IProps extends VehicleDetailsFormClass {
	isReadOnly?: boolean;
	disableVehicleType?: boolean;
	disableFleetNumber?: boolean;
	disableRego?: boolean;
	disableWorkingState?: boolean;
	showEditRego?: boolean;
	VehicleRegoNew?: IVehicleRegoNew;
	onChange?: (id: string, value?: any) => void;
}

const VehicleDetailsForm: React.FC<IProps> = props => {
	const handleChangeInput = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
		const { id, value } = e.target;
		props.onChange && props.onChange(id, value)
	}

	const handleChangeHasTailgateInput = (e: ChangeEvent<HTMLInputElement>, hasTailgate: boolean) => {
		props.onChange && props.onChange('hasTailgate', hasTailgate)
	}

	const detailFields: IFormField[] = [
		/** FLEET NUMBER */
		{ 
			id: 'fleetNumber',
			label: 'Fleet Number', 
			readOnlyValue: props.fleetNumber, 
			isReadOnly: props.disableFleetNumber,
			sm: 4,
			required: true, 
			inputComponent: <FormControl id="fleetNumber" maxLength={15} value={props.fleetNumber} onChange={handleChangeInput} />
		},
		
		/** WORKING STATE */
		{ 
			id: 'workingStateId',
			label: 'Working State', 
			readOnlyValue: props.workingState, 
			isReadOnly: props.disableWorkingState,
			sm: 4, 
			required: true, 
			inputComponent: <AustralianStatesList id="workingStateId" value={props.workingStateId} onChange={handleChangeInput} /> 
		},
		
		/** REGO */
		{ 
			id: 'regoNumber',
			label: 'REGO', 
			readOnlyValue: props.regoNumber, 
			isReadOnly: props.disableRego,
			sm: 4, 
			show: props.typeId !== VehicleTypes.PALLET_JACK.toString(), 
			required: props.typeId !== VehicleTypes.PALLET_JACK.toString(), 
			inputComponent: <FormControl id="regoNumber" maxLength={7} value={props.regoNumber} onChange={handleChangeInput} /> 
		},

		/** VEHICLE REGO HISTORY: STARTED ON */
		{
			id: 'newRegoStartDate',
			label: 'New REGO Start Date',
			isReadOnly: props.disableRego,
			sm: 4,
			show: props.showEditRego,
			required: true,
			inputComponent: <FormControl id="newRegoStartDate" type="date" value={ props.VehicleRegoNew?.newRegoStartDate } onChange={handleChangeInput} />
		},

		/** VEHICLE REGO HISTORY: CANCEL DESCRIPTION */
		{
			id: 'oldRegoCancelReason',
			label: 'Old REGO Cancel Reason',
			isReadOnly: props.disableRego,
			sm: 4,
			show: props.showEditRego,
			required: true,
			inputComponent: <FormControl id="oldRegoCancelReason" value={ props.VehicleRegoNew?.oldRegoCancelReason || "" } onChange={handleChangeInput} />
		},

		/** REGO STATE */
		{ 
			id: 'regoStateId',
			label: 'REGO State', 
			readOnlyValue: props.regoState, 
			isReadOnly: props.disableRego,
			sm: 4, 
			show: props.typeId !== VehicleTypes.PALLET_JACK.toString(), 
			required: props.typeId !== VehicleTypes.PALLET_JACK.toString(), 
			inputComponent: <AustralianStatesList id="regoStateId" value={props.regoStateId} onChange={handleChangeInput} /> 
		},
		
		/** MAKE */
		{ 
			id: 'makeId',
			label: 'Make', 
			readOnlyValue: props.makeName, 
			sm: 4, 
			required: true, 
			inputComponent: <VehicleMakesList id="makeId" value={props.makeId} onChange={handleChangeInput} /> 
		},
		
		/** YEAR */
		{ 
			id: 'modelYear',
			label: 'Year', 
			readOnlyValue: props.modelYear, 
			sm: 4, 
			required: true, 
			inputComponent: <FormControl id="modelYear" type="number" min={1990} max={moment().year() + 1}  value={props.modelYear} onChange={handleChangeInput} /> 
		},

		/** VIN */
		{ 
			id: 'vin',
			label: 'VIN Number', 
			readOnlyValue: props.vin, 
			sm: 4, 
			required: true, 
			inputComponent: <FormControl id="vin" maxLength={17} value={props.vin} onChange={handleChangeInput} /> 
		},
		
		/** ODOMETER */
		{ 
			id: 'odometerKm',
			label: 'Odometer', 
			readOnlyValue: props.odometerKm ? numberMask(parseInt(props.odometerKm)) + ' Km' : undefined,
			sm: 4, 
			required: false, 
			show: props.typeId !== VehicleTypes.PALLET_JACK.toString(),
			inputComponent: (
				<InputGroup>
					<FormControl 
						id="odometerKm" 
						type="number"
						max={3000000}
						value={props.odometerKm} 
						onChange={handleChangeInput}
					/> 
					<InputGroup.Addon>KM</InputGroup.Addon>
				</InputGroup>
			)
		},
		
		/** FREEZER ON */
		{ 
			id: 'freezerHours',
			label: 'Freezer On Time', 
			readOnlyValue: props.freezerHours ? numberMask(parseInt(props.freezerHours)) + ' hour(s)' : undefined, 
			sm: 4, 
			required: false, 
			show: props.typeId === VehicleTypes.TRAILER.toString() || props.typeId === VehicleTypes.RIGID.toString() || props.typeId === VehicleTypes.B_DOUBLE.toString(), 
			inputComponent: (
				<InputGroup>
					<FormControl
						id="freezerHours"
						type="number"
						max={99999}
						value={props.freezerHours}
						onChange={handleChangeInput}
					/> 
					<InputGroup.Addon>Hours</InputGroup.Addon>
				</InputGroup>
			)
		},

		/** AXLES */
		{ 
			id: 'axles',
			label: 'Axles', 
			readOnlyValue: props.axles, 
			sm: 4, 
			required: props.typeId !== VehicleTypes.PALLET_JACK.toString() && props.typeId !== VehicleTypes.DOLLY.toString(), 
			show: props.typeId !== VehicleTypes.PALLET_JACK.toString() && props.typeId !== VehicleTypes.DOLLY.toString(),
			inputComponent: <FormControl id="axles" type="number" max={6} value={props.axles} onChange={handleChangeInput} /> 
		},

		/** MAX PALLETS */
		{ 
			id: 'maxPallets',
			label: 'Max Pallets', 
			readOnlyValue: props.maxPallets, 
			sm: 4, 
			required: props.typeId !== VehicleTypes.DOLLY.toString(), 
			show: props.typeId === VehicleTypes.TRAILER.toString() || props.typeId === VehicleTypes.RIGID.toString() || props.typeId === VehicleTypes.B_DOUBLE.toString(),
			inputComponent: <FormControl type="number" id="maxPallets" max={50} value={props.maxPallets} onChange={handleChangeInput} /> 
		},

		/** GVM */
		{ 
			id: 'gvm',
			label: 'Gross Vehicle Mass (GVM)', 
			readOnlyValue: props.gvm ? numberMask(parseInt(props.gvm)) + ' Kg' : undefined, 
			sm: 4, 
			required: true, 
			inputComponent: (
				<InputGroup>
					<FormControl 
						id="gvm" 
						type="number" 
						max={50000}
						value={props.gvm} 
						onChange={handleChangeInput} 
					/>
					<InputGroup.Addon>KG</InputGroup.Addon>
				</InputGroup>
			) 
		},

		/** TARE */
		{ 
			id: 'tare',
			label: 'Tare', 
			readOnlyValue: props.tare ? numberMask(parseInt(props.tare)) + ' Kg' : undefined, 
			sm: 4, 
			required: props.typeId !== VehicleTypes.PALLET_JACK.toString(), 
			show: props.typeId !== VehicleTypes.PALLET_JACK.toString(),
			inputComponent: (
				<InputGroup>
					<FormControl 
						id="tare" 
						type="number" 
						max={30000}
						value={props.tare} 
						onChange={handleChangeInput} 
					/>
					<InputGroup.Addon>KG</InputGroup.Addon>
				</InputGroup>
			)
		},

		/** TAILGATE */
		{ 
			id: 'hasTailgate',
			label: 'Has Tailgate?', 
			sm: 4, 
			required: true,
			readOnlyValue: props.hasTailgate ? "YES" : "NO",
			show: props.typeId !== VehicleTypes.PRIME_MOVER.toString() && props.typeId !== VehicleTypes.PALLET_JACK.toString(),
			inputComponent: (
				<YesNoRadio id="hasTailgate" value={props.hasTailgate} onChange={handleChangeHasTailgateInput} />
			)
		},

		/** SUB CONTRACTOR */
		{ 
			id: 'subContractorAbn',
			label: 'Sub Contractor', 
			readOnlyValue: props.subContractorName, 
			sm: 4, 
			required: false, 
			inputComponent: <CompaniesList id="subContractorAbn" value={props.subContractorAbn} onChange={handleChangeInput} /> 
		}
	];

	const trackingFields: IFormField[] = [
		/** TRACKING SETTINGS */
		{ 
			id: 'trackingSettingsId',
			label: 'Unit Settings', 
			readOnlyValue: props.trackingSettingsName, 
			sm: 3, 
			required: !props.subContractorAbn, 
			inputComponent: <VehicleTrackingSettingsList id="trackingSettingsId" value={props.trackingSettingsId} onChange={handleChangeInput} /> 
		},

		/** TEMP ZONES */
		{ 
			id: 'numberTempZones',
			label: 'Number of Temp. Zones', 
			readOnlyValue: props.numberTempZones, 
			sm: 3, 
			required: !props.subContractorAbn && (props.typeId === VehicleTypes.TRAILER.toString() || props.typeId === VehicleTypes.RIGID.toString() || props.typeId === VehicleTypes.B_DOUBLE.toString()),
			show: props.typeId === VehicleTypes.TRAILER.toString() || props.typeId === VehicleTypes.RIGID.toString() || props.typeId === VehicleTypes.B_DOUBLE.toString(),
			inputComponent: <FormControl type="number" max={3} id="numberTempZones" value={props.numberTempZones} onChange={handleChangeInput} /> 
		},

		/** TRACKING DEVICE ID */
		{ 
			id: 'trackingUnitId',
			label: 'Device ID', 
			readOnlyValue: props.trackingUnitId, 
			sm: 3, 
			required: false
		},

		/** TEMP ZONES */
		{ 
			id: 'trackingUnitPhoneNumber',
			label: 'Phone Number', 
			readOnlyValue: props.trackingUnitPhoneNumber && (
				<a href={`tel:${props.trackingUnitPhoneNumber}`}>
					{ props.trackingUnitPhoneNumber }
				</a>
			), 
			sm: 3, 
			required: false
		},
	]

	const renderFields = (fields: IFormField[]) => (
		fields.map(field => {
			if (field.show === false)
				return undefined;

			return (
				<FieldGroup 
					key={field.label} 
					sm={field.sm} 
					label={field.label} 
					required={field.required} 
					optional={field.inputComponent && !props.isReadOnly && !field.required}
				>
					{
						(field.isReadOnly || props.isReadOnly || !field.inputComponent) ? (
							<p>{ field.readOnlyValue || '-'}</p>
						) : (
							field.inputComponent
						)
					}
				</FieldGroup>
			)
		})
	)

	return (	
		<Form 
			id="vehicle-form" 
			validations={{
				typeId: 'required',
				...detailFields.filter(p => p.required).reduce((obj, item) => Object.assign(obj, { [item.id]: 'required' }), {}),
				...trackingFields.filter(p => p.required).reduce((obj, item) => Object.assign(obj, { [item.id]: 'required' }), {})
			}}
		>
			<Row>
				<FieldGroup sm={4} label="Type" required>
					{
						(props.isReadOnly || props.disableFleetNumber) ? (
							<p>{ props.typeName }</p>
						) : (
							<VehicleTypesList 
								id="typeId" 
								value={props.typeId} 
								onChange={handleChangeInput} 
							/>
						)
					}
				</FieldGroup>
				{ props.typeId && renderFields(detailFields) }
			</Row>
			{
				props.typeId && (
					<Fragment>
						<h3 className="title">Tracking</h3>
						<Row key={props.subContractorAbn ? "withSubbie" : "noSubbie"}>
							{ renderFields(trackingFields) }
						</Row>
					</Fragment>
				)
			}
		</Form>
	)
}

export default VehicleDetailsForm;