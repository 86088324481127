import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import { AppInsights } from 'applicationinsights-js';
import { fetchUserData } from '../stores/redux/user';
import { setDefaultFilter } from '../stores/redux/filter';
import { setDefaultMap } from '../stores/redux/map';
import { setAutoDisconnectTimer, startAutoDisconnectTimer } from '../stores/redux/app';
import LoadingData from './LoadingData';
import { UncaughtError, Unauthorized, LoginError, AuthCacheError, IOSVersionOutOfDate } from './errors';
import packageInfo from '../../package.json';
import { initializeMsal } from '../app/config/auth.config';
import{ v4 as uuid } from 'uuid';
import confirm from '../app/helpers/confirm';

class AppContainer extends Component {
	static propTypes = {
		loggedUser: PropTypes.object,
		isLoading: PropTypes.bool,
		fetchUserData: PropTypes.func,
		setDefaultFilter: PropTypes.func,
		setDefaultMap: PropTypes.func,
		setAutoDisconnectTimer: PropTypes.func,
		startAutoDisconnectTimer: PropTypes.func
	}

	componentDidMount() {
		this.initializeApplication();
	}

	initializeApplication = async () => {
		window.AppInsights = AppInsights;
		AppInsights.downloadAndSetup({ instrumentationKey: process.env.REACT_APP_INSIGHTS_KEY });
		await initializeMsal();
		this.checkAutoDisconnect();
		this.props.fetchUserData();
	}

	componentDidUpdate(prevProps) {			
		if (!prevProps.loggedUser && this.props.loggedUser) {
			const azureId = this.props.loggedUser.azureId;
			const azureEmail = this.props.loggedUser.azureEmail;

			AppInsights.setAuthenticatedUserContext(azureId, azureEmail);
			const setAppInsightsId = setInterval(() => {
				if (AppInsights.context) {
					AppInsights.context.application.ver = packageInfo.version;
					AppInsights.context.user.id = azureId;
					AppInsights.context.session.id = uuid();
					window.clearInterval(setAppInsightsId);
					
					// add a script component that runs clarity("set", "user", azureId);
					document.getElementById('root').appendChild(document.createElement('script')).innerHTML = `(function (c, l, a, r, i, t, y) {
						c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) }; t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/"+i;
						y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
						clarity("set", "userId", "${azureId}");
						clarity("set", "sessionId", "${AppInsights.context.session.id}");
						clarity("set", "userEmail", "${azureEmail}");
						clarity("set", "userName", "${this.props.loggedUser.shortName}");
						clarity("set", "env", "${process.env.REACT_APP_ENVIRONMENT}");
						clarity("set", "version", "${packageInfo.version}");
					})(window, document, "clarity", "script", "${process.env.REACT_APP_MICROSOFT_CLARITY_ID}");`;
				}
			}, 100);
		}
	}

	checkAutoDisconnect = () => {
		let autoDisconnectInSec = sessionStorage.getItem('autoDisconnectInSec');
		if (!autoDisconnectInSec)
			return;

		this.props.setAutoDisconnectTimer(autoDisconnectInSec);
		this.props.startAutoDisconnectTimer();
	}

	isUnsupportedIOS = () => {
		const userAgent = window.navigator.userAgent;
		const iOS = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
		const webkit = !!userAgent.match(/WebKit/i);
		const iOSSafari = iOS && webkit && !userAgent.match(/CriOS/i);
		const version = userAgent.match(/Version\/(\d+)/i);

		return iOSSafari && version && parseInt(version[1], 10) <= 13;
	}
	
	getRoutes() {
		return require('../routes').default;
	}

	checkVersion() {
		// Checks if the current UI version is different from the latest UI version, but only in production
		if (process.env.REACT_APP_ENVIRONMENT === "production" && this.props.latestUiVersion && packageInfo.version !== this.props.latestUiVersion)
			confirm("A new version of the TMS is available!", () => window.location.reload(), undefined, { proceedOnly: true, proceedText: 'Click Here to Refresh & Update'});
	}

	onUpdatePages = () => {
		if (this.props.loggedUser && process.env.REACT_APP_ENVIRONMENT === 'production') {
			const pagePath = window.location.pathname;
			AppInsights.trackPageView(pagePath, pagePath);
		}

		// Cleans all states to default values 
		this.props.setDefaultFilter();
		this.props.setDefaultMap();

		// Checks the current UI version
		this.checkVersion();	

		// Scrolls the page to the top
		window.scrollTo(0, 0);
	};

	render() {
		const p = this.props;

		if (p.error)
			return <UncaughtError />;

		if (p.loginError)
			return <LoginError />;

		if (p.cacheError)
			return <AuthCacheError />;

		if (p.isLoading || !p.loggedUser)
			return <LoadingData />;

		if (!p.loggedUser.permissionGroupId)
			return <Unauthorized />;

		if (this.isUnsupportedIOS())
			return <IOSVersionOutOfDate />;

		return (
			<Router 
				routes={this.getRoutes()} 
				history={browserHistory} 
				onUpdate={this.onUpdatePages}
			/>
		);
	}
}

const mapStateToProps = ({ user, app }) => ({ 
	isLoading: user.isLoading,
	error: user.error,
	loginError: user.loginError,
	cacheError: user.cacheError,
	loggedUser: user.data,
	latestUiVersion: app.latestUiVersion
});

const mapDispatchToProps = { 
	fetchUserData, setDefaultFilter, 
	setDefaultMap, setAutoDisconnectTimer, 
	startAutoDisconnectTimer
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);