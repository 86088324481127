import { DriversLicenceType, ListRequestOptions } from "./Application";
import { ILeaveRequestSimple } from "./LeaveRequest";
import { IStaffRosterForTasks } from './StaffRoster';

export enum DriversLicenceTypes {
	C = 'C',
	LR = 'LR',
	MR = 'MR',
	HR = 'HR',
	HC = 'HC',
	MC = 'MC'
}

export interface IWorkerAvailability {
	workerId: string;

	mainTaskTypeId: number;
	mainTaskTypeName: string;
	isCasual: boolean;
	isOvertimeAllowed: boolean;
	ordinaryTotalDaysOn?: number;
	ordinaryTotalDaysOff?: number;
	rotateWithWorkerId?: string;
	workerToRotateWith?: IWorkerBasicInfo;
	ordinaryStartTime?: string; 
	ordinaryHours?: number;
	ordinaryStartingPlaceId?: number;
	ordinaryStartingPlaceName?: string;
	ordinaryRunTypeId?: number;
	ordinaryRunTypeName?: string;
	comments?: string;

	mondayStartTime?: string;
	tuesdayStartTime?: string;
	wednesdayStartTime?: string;
	thursdayStartTime?: string;
	fridayStartTime?: string;
	saturdayStartTime?: string;
	sundayStartTime?: string;

	isMondayOvertime?: boolean;
	isTuesdayOvertime?: boolean;
	isWednesdayOvertime?: boolean;
	isThursdayOvertime?: boolean;
	isFridayOvertime?: boolean;
	isSaturdayOvertime?: boolean;
	isSundayOvertime?: boolean;

	mondayStartingPlaceId?: number;
	tuesdayStartingPlaceId?: number;
	wednesdayStartingPlaceId?: number;
	thursdayStartingPlaceId?: number;
	fridayStartingPlaceId?: number;
	saturdayStartingPlaceId?: number;
	sundayStartingPlaceId?: number;

	mondayStartingPlaceName: string;
	tuesdayStartingPlaceName: string;
	wednesdayStartingPlaceName: string;
	thursdayStartingPlaceName: string;
	fridayStartingPlaceName: string;
	saturdayStartingPlaceName: string;
	sundayStartingPlaceName: string;

	mondayOrdinaryHours?: number;
	tuesdayOrdinaryHours?: number;
	wednesdayOrdinaryHours?: number;
	thursdayOrdinaryHours?: number;
	fridayOrdinaryHours?: number;
	saturdayOrdinaryHours?: number;
	sundayOrdinaryHours?: number;

	mondayRunTypeId?: number;
	tuesdayRunTypeId?: number;
	wednesdayRunTypeId?: number;
	thursdayRunTypeId?: number;
	fridayRunTypeId?: number;
	saturdayRunTypeId?: number;
	sundayRunTypeId?: number;

	mondayRunTypeName?: string;
	tuesdayRunTypeName?: string;
	wednesdayRunTypeName?: string;
	thursdayRunTypeName?: string;
	fridayRunTypeName?: string;
	saturdayRunTypeName?: string;
	sundayRunTypeName?: string;
}

export interface IWorkerBasicInfo {
	azureId: string;
	firstName: string;
	surname: string;
	shortName: string;
	fullName: string;
	phoneNumber?: string;
	photoUrl?: string;
	email?: string;
	
	// Employment Info
	currentCompanyId?: string;
	currentCompanyName?: string;
	currentRoleId?: number;
	currentRoleName?: string;
	currentRoleStatus?: string;

	// Driver licence
	driverLicenceExpiryDate?: string;
	currentLicenceType?: DriversLicenceType;
}

export interface IWorkerForTaskOrRoster extends IWorkerBasicInfo {
	LeaveRequest?: ILeaveRequestSimple;
	Roster?: IStaffRosterForTasks;
}

export interface IWorkerListFilter extends ListRequestOptions {
	workerName?: string;
	currentCompany?: string;
	currentRole?: string;
	showEmployedOnly?: boolean;
}

export class WorkerAvailabilityForm {
	workerId: string;

	mainTaskTypeId: string;
	mainTaskTypeName: string;
	isCasual: boolean;
	isOvertimeAllowed: boolean;
	rotateWithWorkerId: string;
	workerToRotateWith?: IWorkerBasicInfo;
	ordinaryTotalDaysOn: string;
	ordinaryTotalDaysOff: string;
	ordinaryStartTime: string; 
	ordinaryHours: string;
	ordinaryStartingPlaceId: string;
	ordinaryStartingPlaceName: string;
	ordinaryRunTypeId?: string;
	ordinaryRunTypeName?: string;
	comments: string;
	
	mondayStartTime: string;
	tuesdayStartTime: string;
	wednesdayStartTime: string;
	thursdayStartTime: string;
	fridayStartTime: string;
	saturdayStartTime: string;
	sundayStartTime: string;

	isMondayOvertime: boolean;
	isTuesdayOvertime: boolean;
	isWednesdayOvertime: boolean;
	isThursdayOvertime: boolean;
	isFridayOvertime: boolean;
	isSaturdayOvertime: boolean;
	isSundayOvertime: boolean;

	mondayStartingPlaceId: string;
	tuesdayStartingPlaceId: string;
	wednesdayStartingPlaceId: string;
	thursdayStartingPlaceId: string;
	fridayStartingPlaceId: string;
	saturdayStartingPlaceId: string;
	sundayStartingPlaceId: string;

	mondayStartingPlaceName: string;
	tuesdayStartingPlaceName: string;
	wednesdayStartingPlaceName: string;
	thursdayStartingPlaceName: string;
	fridayStartingPlaceName: string;
	saturdayStartingPlaceName: string;
	sundayStartingPlaceName: string;

	mondayOrdinaryHours: string;
	tuesdayOrdinaryHours: string;
	wednesdayOrdinaryHours: string;
	thursdayOrdinaryHours: string;
	fridayOrdinaryHours: string;
	saturdayOrdinaryHours: string;
	sundayOrdinaryHours: string;

	mondayRunTypeId?: string;
	tuesdayRunTypeId?: string;
	wednesdayRunTypeId?: string;
	thursdayRunTypeId?: string;
	fridayRunTypeId?: string;
	saturdayRunTypeId?: string;
	sundayRunTypeId?: string;

	mondayRunTypeName?: string;
	tuesdayRunTypeName?: string;
	wednesdayRunTypeName?: string;
	thursdayRunTypeName?: string;
	fridayRunTypeName?: string;
	saturdayRunTypeName?: string;
	sundayRunTypeName?: string;

	constructor(workerAvailability?: IWorkerAvailability) {
		this.workerId = workerAvailability?.workerId || '';
		this.mainTaskTypeId = workerAvailability?.mainTaskTypeId?.toString() || '';
		this.mainTaskTypeName = workerAvailability?.mainTaskTypeName || '';
		this.isCasual = workerAvailability?.isCasual || false;
		this.isOvertimeAllowed = workerAvailability?.isOvertimeAllowed || false;
		this.ordinaryTotalDaysOn = workerAvailability?.ordinaryTotalDaysOn?.toString() || '';
		this.ordinaryTotalDaysOff = workerAvailability?.ordinaryTotalDaysOff?.toString() || '';
		this.ordinaryStartTime = workerAvailability?.ordinaryStartTime || '';
		this.ordinaryHours = workerAvailability?.ordinaryHours?.toString() || '';
		this.ordinaryStartingPlaceId = workerAvailability?.ordinaryStartingPlaceId?.toString() || '';
		this.ordinaryStartingPlaceName = workerAvailability?.ordinaryStartingPlaceName || '';
		this.rotateWithWorkerId = workerAvailability?.rotateWithWorkerId || '';
		this.workerToRotateWith = workerAvailability?.workerToRotateWith;
		this.ordinaryRunTypeId = workerAvailability?.ordinaryRunTypeId?.toString() || '';
		this.ordinaryRunTypeName = workerAvailability?.ordinaryRunTypeName || '';
		this.comments = workerAvailability?.comments || '';

		this.mondayStartTime = workerAvailability?.mondayStartTime || '';
		this.tuesdayStartTime = workerAvailability?.tuesdayStartTime || '';
		this.wednesdayStartTime = workerAvailability?.wednesdayStartTime || '';
		this.thursdayStartTime = workerAvailability?.thursdayStartTime || '';
		this.fridayStartTime = workerAvailability?.fridayStartTime || '';
		this.saturdayStartTime = workerAvailability?.saturdayStartTime || '';
		this.sundayStartTime = workerAvailability?.sundayStartTime || '';

		this.mondayStartingPlaceId = workerAvailability?.mondayStartingPlaceId?.toString() || '';
		this.tuesdayStartingPlaceId = workerAvailability?.tuesdayStartingPlaceId?.toString() || '';
		this.wednesdayStartingPlaceId = workerAvailability?.wednesdayStartingPlaceId?.toString() || '';
		this.thursdayStartingPlaceId = workerAvailability?.thursdayStartingPlaceId?.toString() || '';
		this.fridayStartingPlaceId = workerAvailability?.fridayStartingPlaceId?.toString() || '';
		this.saturdayStartingPlaceId = workerAvailability?.saturdayStartingPlaceId?.toString() || '';
		this.sundayStartingPlaceId = workerAvailability?.sundayStartingPlaceId?.toString() || '';

		this.mondayStartingPlaceName = workerAvailability?.mondayStartingPlaceName || '';
		this.tuesdayStartingPlaceName = workerAvailability?.tuesdayStartingPlaceName || '';
		this.wednesdayStartingPlaceName = workerAvailability?.wednesdayStartingPlaceName || '';
		this.thursdayStartingPlaceName = workerAvailability?.thursdayStartingPlaceName || '';
		this.fridayStartingPlaceName = workerAvailability?.fridayStartingPlaceName || '';
		this.saturdayStartingPlaceName = workerAvailability?.saturdayStartingPlaceName || '';
		this.sundayStartingPlaceName = workerAvailability?.sundayStartingPlaceName || '';

		this.mondayOrdinaryHours = workerAvailability?.mondayOrdinaryHours?.toString() || '';
		this.tuesdayOrdinaryHours = workerAvailability?.tuesdayOrdinaryHours?.toString() || '';
		this.wednesdayOrdinaryHours = workerAvailability?.wednesdayOrdinaryHours?.toString() || '';
		this.thursdayOrdinaryHours = workerAvailability?.thursdayOrdinaryHours?.toString() || '';
		this.fridayOrdinaryHours = workerAvailability?.fridayOrdinaryHours?.toString() || '';
		this.saturdayOrdinaryHours = workerAvailability?.saturdayOrdinaryHours?.toString() || '';
		this.sundayOrdinaryHours = workerAvailability?.sundayOrdinaryHours?.toString() || '';
		
		this.isMondayOvertime = workerAvailability?.isMondayOvertime || false;
		this.isTuesdayOvertime = workerAvailability?.isTuesdayOvertime || false;
		this.isWednesdayOvertime = workerAvailability?.isWednesdayOvertime || false;
		this.isThursdayOvertime = workerAvailability?.isThursdayOvertime || false;
		this.isFridayOvertime = workerAvailability?.isFridayOvertime || false;
		this.isSaturdayOvertime = workerAvailability?.isSaturdayOvertime || false;
		this.isSundayOvertime = workerAvailability?.isSundayOvertime || false;

		this.mondayRunTypeId = workerAvailability?.mondayRunTypeId?.toString() || '';
		this.tuesdayRunTypeId = workerAvailability?.tuesdayRunTypeId?.toString() || '';
		this.wednesdayRunTypeId = workerAvailability?.wednesdayRunTypeId?.toString() || '';
		this.thursdayRunTypeId = workerAvailability?.thursdayRunTypeId?.toString() || '';
		this.fridayRunTypeId = workerAvailability?.fridayRunTypeId?.toString() || '';
		this.saturdayRunTypeId = workerAvailability?.saturdayRunTypeId?.toString() || '';
		this.sundayRunTypeId = workerAvailability?.sundayRunTypeId?.toString() || '';

		this.mondayRunTypeName = workerAvailability?.mondayRunTypeName || '';
		this.tuesdayRunTypeName = workerAvailability?.tuesdayRunTypeName || '';
		this.wednesdayRunTypeName = workerAvailability?.wednesdayRunTypeName || '';
		this.thursdayRunTypeName = workerAvailability?.thursdayRunTypeName || '';
		this.fridayRunTypeName = workerAvailability?.fridayRunTypeName || '';
		this.saturdayRunTypeName = workerAvailability?.saturdayRunTypeName || '';
		this.sundayRunTypeName = workerAvailability?.sundayRunTypeName || '';
	}
}

export class WorkerAvailabilityPUT_POST {
	mainTaskTypeId: number;
	isCasual: boolean;
	ordinaryTotalDaysOn?: number;
	ordinaryTotalDaysOff?: number;
	rotateWithWorkerId?: string;
	ordinaryStartTime?: string; 
	ordinaryHours?: number;
	ordinaryStartingPlaceId?: number;
	comments?: string;

	mondayStartTime?: string;
	tuesdayStartTime?: string;
	wednesdayStartTime?: string;
	thursdayStartTime?: string;
	fridayStartTime?: string;
	saturdayStartTime?: string;
	sundayStartTime?: string;
	
	isMondayOvertime?: boolean;
	isTuesdayOvertime?: boolean;
	isWednesdayOvertime?: boolean;
	isThursdayOvertime?: boolean;
	isFridayOvertime?: boolean;
	isSaturdayOvertime?: boolean;
	isSundayOvertime?: boolean;
	
	mondayStartingPlaceId?: number;
	tuesdayStartingPlaceId?: number;
	wednesdayStartingPlaceId?: number;
	thursdayStartingPlaceId?: number;
	fridayStartingPlaceId?: number;
	saturdayStartingPlaceId?: number;
	sundayStartingPlaceId?: number;
	
	mondayOrdinaryHours?: number;
	tuesdayOrdinaryHours?: number;
	wednesdayOrdinaryHours?: number;
	thursdayOrdinaryHours?: number;
	fridayOrdinaryHours?: number;
	saturdayOrdinaryHours?: number;
	sundayOrdinaryHours?: number;

	mondayRunTypeId?: number;
	tuesdayRunTypeId?: number;
	wednesdayRunTypeId?: number;
	thursdayRunTypeId?: number;
	fridayRunTypeId?: number;
	saturdayRunTypeId?: number;
	sundayRunTypeId?: number;

	constructor(wa: WorkerAvailabilityForm) {
		this.mainTaskTypeId = parseInt(wa.mainTaskTypeId);
		this.isCasual = wa.isCasual;
		this.ordinaryStartingPlaceId = wa.ordinaryStartingPlaceId ? parseInt(wa.ordinaryStartingPlaceId) : undefined;
		this.ordinaryStartTime = wa.ordinaryStartTime || undefined;
		this.ordinaryHours = wa.ordinaryHours ? parseFloat(wa.ordinaryHours) : undefined;
		this.ordinaryTotalDaysOn = wa.ordinaryTotalDaysOn ? parseInt(wa.ordinaryTotalDaysOn) : undefined;
		this.ordinaryTotalDaysOff = wa.ordinaryTotalDaysOff ? parseInt(wa.ordinaryTotalDaysOff) : undefined;
		this.rotateWithWorkerId = wa.rotateWithWorkerId || undefined;
		this.comments = wa.comments || undefined;

		this.mondayStartTime = wa.ordinaryHours ? undefined : wa.mondayStartTime || undefined;
		this.tuesdayStartTime = wa.ordinaryHours ? undefined : wa.tuesdayStartTime || undefined;
		this.wednesdayStartTime = wa.ordinaryHours ? undefined : wa.wednesdayStartTime || undefined;
		this.thursdayStartTime = wa.ordinaryHours ? undefined : wa.thursdayStartTime || undefined;
		this.fridayStartTime = wa.ordinaryHours ? undefined : wa.fridayStartTime || undefined;
		this.saturdayStartTime = wa.ordinaryHours ? undefined : wa.saturdayStartTime || undefined;
		this.sundayStartTime = wa.ordinaryHours ? undefined : wa.sundayStartTime || undefined;

		this.mondayStartingPlaceId = wa.mondayStartingPlaceId && !wa.ordinaryHours ? parseInt(wa.mondayStartingPlaceId) : undefined;
		this.tuesdayStartingPlaceId = wa.tuesdayStartingPlaceId && !wa.ordinaryHours ? parseInt(wa.tuesdayStartingPlaceId) : undefined;
		this.wednesdayStartingPlaceId = wa.wednesdayStartingPlaceId && !wa.ordinaryHours ? parseInt(wa.wednesdayStartingPlaceId) : undefined;
		this.thursdayStartingPlaceId = wa.thursdayStartingPlaceId && !wa.ordinaryHours ? parseInt(wa.thursdayStartingPlaceId) : undefined;
		this.fridayStartingPlaceId = wa.fridayStartingPlaceId && !wa.ordinaryHours ? parseInt(wa.fridayStartingPlaceId) : undefined;
		this.saturdayStartingPlaceId = wa.saturdayStartingPlaceId && !wa.ordinaryHours ? parseInt(wa.saturdayStartingPlaceId) : undefined;
		this.sundayStartingPlaceId = wa.sundayStartingPlaceId && !wa.ordinaryHours ? parseInt(wa.sundayStartingPlaceId) : undefined;

		this.mondayOrdinaryHours = wa.mondayOrdinaryHours && !wa.ordinaryHours ? parseFloat(wa.mondayOrdinaryHours) : undefined;
		this.tuesdayOrdinaryHours = wa.tuesdayOrdinaryHours && !wa.ordinaryHours ? parseFloat(wa.tuesdayOrdinaryHours) : undefined;
		this.wednesdayOrdinaryHours = wa.wednesdayOrdinaryHours && !wa.ordinaryHours ? parseFloat(wa.wednesdayOrdinaryHours) : undefined;
		this.thursdayOrdinaryHours = wa.thursdayOrdinaryHours && !wa.ordinaryHours ? parseFloat(wa.thursdayOrdinaryHours) : undefined;
		this.fridayOrdinaryHours = wa.fridayOrdinaryHours && !wa.ordinaryHours ? parseFloat(wa.fridayOrdinaryHours) : undefined;
		this.saturdayOrdinaryHours = wa.saturdayOrdinaryHours && !wa.ordinaryHours ? parseFloat(wa.saturdayOrdinaryHours) : undefined;
		this.sundayOrdinaryHours = wa.sundayOrdinaryHours && !wa.ordinaryHours ? parseFloat(wa.sundayOrdinaryHours) : undefined;

		this.mondayRunTypeId = wa.mondayRunTypeId && !wa.ordinaryHours ? parseInt(wa.mondayRunTypeId) : undefined;
		this.tuesdayRunTypeId = wa.tuesdayRunTypeId && !wa.ordinaryHours ? parseInt(wa.tuesdayRunTypeId) : undefined;
		this.wednesdayRunTypeId = wa.wednesdayRunTypeId && !wa.ordinaryHours ? parseInt(wa.wednesdayRunTypeId) : undefined;
		this.thursdayRunTypeId = wa.thursdayRunTypeId && !wa.ordinaryHours ? parseInt(wa.thursdayRunTypeId) : undefined;
		this.fridayRunTypeId = wa.fridayRunTypeId && !wa.ordinaryHours ? parseInt(wa.fridayRunTypeId) : undefined;
		this.saturdayRunTypeId = wa.saturdayRunTypeId && !wa.ordinaryHours ? parseInt(wa.saturdayRunTypeId) : undefined;
		this.sundayRunTypeId = wa.sundayRunTypeId && !wa.ordinaryHours ? parseInt(wa.sundayRunTypeId) : undefined;

		this.isMondayOvertime = wa.mondayStartTime && !wa.ordinaryHours ? wa.isMondayOvertime || false : undefined;
		this.isTuesdayOvertime = wa.tuesdayStartTime && !wa.ordinaryHours ? wa.isTuesdayOvertime || false : undefined;
		this.isWednesdayOvertime = wa.wednesdayStartTime && !wa.ordinaryHours ? wa.isWednesdayOvertime || false : undefined;
		this.isThursdayOvertime = wa.thursdayStartTime && !wa.ordinaryHours ? wa.isThursdayOvertime || false : undefined;
		this.isFridayOvertime = wa.fridayStartTime && !wa.ordinaryHours ? wa.isFridayOvertime || false : undefined;
		this.isSaturdayOvertime = wa.saturdayStartTime && !wa.ordinaryHours ? wa.isSaturdayOvertime || false : undefined;
		this.isSundayOvertime = wa.sundayStartTime && !wa.ordinaryHours ? wa.isSundayOvertime || false : undefined;
	}
}

export interface IWorkerCovidTest {
	id: number;
	testDate: string;
	isPositive: boolean;
	nextTestDueDate?: string;
	isDeleted: boolean;
}

export class WorkerCovidTestForm {
	id?: number;
	testDate: string;
	isPositive?: boolean;
	nextTestDueDate: string;
	isDeleted: boolean;
	testResultFile?: File;

	constructor(ct?: IWorkerCovidTest) {
		this.id = ct?.id;
		this.testDate = ct?.testDate || '';
		this.isPositive = ct?.isPositive;
		this.nextTestDueDate = ct?.nextTestDueDate || '';
		this.isDeleted = ct?.isDeleted || false;
	}
}

export const workersListExportColumns = [
	{ text: 'Name', value: 'name' },
	{ text: 'Employee Number', value: 'employeeNumber' },
	{ text: 'Position Title', value: 'positionTitle' },
	{ text: 'Company', value: 'company' },
	{ text: 'Current Contract', value: 'currentContract' },
	{ text: 'Driver Licence Class', value: 'driverLicenceClass' },
	{ text: 'Licence Expiry Date', value: 'licenceExpiryDate' },
	{ text: 'Date of Birth', value: 'dob' },
	{ text: 'Phone Number', value: 'phoneNumber' },
	{ text: 'E-mail', value: 'email' },
	{ text: 'Employment Status', value: 'employmentStatus' }, 
	{ text: 'Started On', value: 'startedOn' },
	{ text: 'Left Date', value: 'leftOn' },
	{ text: 'Reason for Leaving', value: 'reasonForLeaving' },
	{ text: 'Left Comments', value: 'leftComments' },
	{ text: 'Record Creator', value: 'recordCreatedBy' },
	{ text: 'Home Address', value: 'currentFullAddress'},
	{ text: 'Emergency Contacts', value: 'emergencyContacts'}
];

export interface WorkerListItemWithExtraData {
	id: string;
	name: string;
	mobileNumber: string;
	currentRoleName?: string;
}