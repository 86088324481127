import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loader } from '../../../common/uiElements';

const propTypes = {
	itemRef: PropTypes.func,
	loading: PropTypes.bool,
	lastRefreshInSec: PropTypes.number,
	nextRefreshInSec: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	]),
	totalVehicles: PropTypes.number
};

class InfoBox extends Component {
	constructor() {
		super();
		this.state = {
			nextRefreshInSec: 60,
			lastRefreshInSec: 0
		};
	}

	componentWillUnmount() {
		clearInterval(this.refreshInterval);
	}

	setRefreshInterval = refreshTimeInSec => {
		if (this.refreshInterval || refreshTimeInSec)
			clearInterval(this.refreshInterval);

		this.setState({ nextRefreshInSec: refreshTimeInSec });

		if (!refreshTimeInSec)
			return;

		this.refreshInterval = setInterval(() => {
			this.setState({
				nextRefreshInSec: this.state.nextRefreshInSec - 1,
				lastRefreshInSec: this.state.lastRefreshInSec + 1,
			}, () => {
				if (this.state.nextRefreshInSec === 0) {
					this.props.onShouldRefresh();
					this.setState({ 
						nextRefreshInSec: refreshTimeInSec,
						lastRefreshInSec: 0 
					});
				}
			});
		}, 1000);
	}

	render() {
		const s = this.state;
		const p = this.props;

		return (
			<div ref={p.itemRef} className="panel panel-default info-box-main">
				<div className="panel-body">
					<table>
						<tbody>
							<tr>
								<th>Last Refresh</th>
								<td>{ s.lastRefreshInSec > 60 ? `${s.lastRefreshInSec} seconds` : 'less than one minute' } </td>
							</tr>
							<tr>
								<th>Next Refresh</th>
								{
									p.loading ? (
										<td>Loading data...</td>
									) : (
										s.nextRefreshInSec === ''		 ? (
											<td>Disabled</td>
										) : (
											<td>{ s.nextRefreshInSec } second(s)</td>		
										)
									)
								}
							</tr>
							<tr>
								<th>Total</th>
								<td>
									{ p.totalVehicles } vehicle(s)
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				{
					!window._isMobile && p.loading && <Loader />
				}
			</div>
		);
	}
}

InfoBox.propTypes = propTypes;

export default InfoBox;