import trackingEndpoints from './endpoints/trackingEndpoints';
import httpRequests from './httpRequests';

export default {
	batteryChargesRecentByVeh(vehFleetNumber: string, startTime: number, finishTime: number) {
		const endpoint = trackingEndpoints.batteryChargesRecentByVeh(vehFleetNumber);
		return httpRequests.get(endpoint, { startTime, finishTime });
	},

	
	batteryChargesReportByPj(vehFleetNumber: string) {
		const endpoint = trackingEndpoints.batteryChargesReportByPj(vehFleetNumber);
		return httpRequests.get(endpoint);
	},

	/**
	 * Get a list of the pallet jacks with the battery's information
	 */
	getPalletJackBatteryInfoLive() {
		const endpoint = trackingEndpoints.palletJackBatteryInfoLive;
		return httpRequests.get(endpoint);
	},

	breadcrumbsByVehicle(vehFleetNumber: string) {
		const endpoint = trackingEndpoints.breadcrumbsByVeh(vehFleetNumber);
		return httpRequests.get(endpoint, { lat: 'null', lng: 'null', rangeMeters: 'null' });
	},
	
	breadcrumbsByVehPeriod(vehFleetNumber: string, startTime: number, finishTime: number) {
		const endpoint = trackingEndpoints.breadcrumbsByVeh(vehFleetNumber);
		return httpRequests.get(endpoint, { startTime, finishTime, lat: 'null', lng: 'null', rangeMeters: 'null' });
	},


	breadcrumbsByVehRange(vehFleetNumber: string, startTime: number, finishTime: number) {
		const endpoint = trackingEndpoints.breadcrumbsByVeh(vehFleetNumber);
		return httpRequests.get(endpoint, { startTime, finishTime, lat: 'null', lng: 'null', rangeMeters: 'null' });
	},


	exceptionsByTime(startTime: number, finishTime: number) {
		const endpoint = trackingEndpoints.exceptionsByTime;
		return httpRequests.get(endpoint, { startTime, finishTime });
	},


	fullJourneyReport(vehFleetNumber: string, startTime: number, finishTime: number) {
		const endpoint = trackingEndpoints.fullJourneyReport(vehFleetNumber);
		return httpRequests.get(endpoint, { startTime, finishTime });
	},


	journeyStepsByVeh(vehFleetNumber: string, startTime: number, finishTime: number) {
		const endpoint = trackingEndpoints.journeyStepsByVeh(vehFleetNumber);
		return httpRequests.get(endpoint, { startTime, finishTime });
	},

	
	lastPings(timeLimitMinutes: number, vehTypeFilter: string[], stateFilter: string[]) {
		const endpoint = trackingEndpoints.lastPing;
		return httpRequests.get(endpoint, { vehTypeFilter, timeLimitMinutes, stateFilter });
	},


	palletJackLowBattery(onlyActive: boolean) {
		const endpoint = trackingEndpoints.palletJackLowBattery;
		return httpRequests.get(endpoint, { onlyActive });
	},

	/**
	 * Gets when the vehicle was parked if it's still parked
	 * @param {string} fleetNumber 
	 */
	parkedTimeByVeh(fleetNumber: string) {
		const endpoint = trackingEndpoints.parkedTimeByVeh(fleetNumber);
		return httpRequests.get(endpoint);
	},

	speedTicketsByTime(startTime: number, finishTime: number, stateFilter: string[]) {
		const endpoint = trackingEndpoints.speedTicketsByTime;
		return httpRequests.get(endpoint, { startTime, finishTime, stateFilter });
	},


	vehiclesByPlace(lat: number, lng: number, rangeMeters: number, startTime: number, finishTime: number, vehTypeFilter: string[]) {
		const endpoint = trackingEndpoints.vehiclesByPlace;
		return httpRequests.get(endpoint, { lat, lng, rangeMeters, startTime, finishTime, vehTypeFilter });
	},


	vehiclesMissingNow() {
		const endpoint = trackingEndpoints.vehiclesMissingNow;
		return httpRequests.get(endpoint);
	}
};