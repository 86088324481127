import React, { Component } from 'react';
import { string, number, bool, func } from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import Icon from 'react-fontawesome';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';
import './style.css';

import DurationParse from '../../../../app/helpers/DurationParse';

const propTypes = {
	fleetNumber: string,
	isCharging: bool,
	isBatteryLow: bool,
	currentVoltage: number,
	startedChargingOnLocal: string,
	last24HoursTotalCharges: number,
	last24HoursChargingTimeInMin: number,
	lastPingOnLocal: string,
	lastChargeTotalTimeInMin: number,
	isSimplified: bool,
	onClick: func
};

class PalletJackBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chargingTimeInSec: 0,
			lastPingTimeInSec: 0
		};
	}

	componentWillUnmount() {
		window.clearInterval(this.timer);
	}
	
	UNSAFE_componentWillMount() {
		const { startedChargingOnLocal, lastPingOnLocal } = this.props;
		this.setState({ 
			chargingTimeInSec: moment().diff(moment(startedChargingOnLocal), 'seconds'),
			lastPingTimeInSec: moment().diff(moment(lastPingOnLocal), 'seconds')
		}, this.startTimer);
	}

	startTimer = () => {
		this.timer = setInterval(() => {
			this.setState({
				chargingTimeInSec: this.state.chargingTimeInSec + 60,
				lastPingTimeInSec: this.state.lastPingTimeInSec + 60
			});
		}, 60000);
	}

	handleClick = fleetNumber => {
		this.props.onClick && this.props.onClick(fleetNumber);
	}

	render() {
		const p = this.props;
		const s = this.state;

		const bgColor = p.isBatteryLow ? 'bg-red' : p.isCharging ? 'bg-aqua' : 'bg-green';
		const boxIcon = p.isCharging ? 'plug' : p.isBatteryLow ? 'battery-1' : 'battery-4';

		if (p.isSimplified)
			return (
				<FormGroup className="pallet-jack-box-simplified" onClick={() => this.handleClick(p.fleetNumber)}>
					<Button block className={bgColor}>
						<Icon name={boxIcon} /><br />
						{ p.fleetNumber }
					</Button>
				</FormGroup>
			);
		
		return (
			<div className={classNames('pallet-jack-box', 'small-box', bgColor)} onClick={() => this.handleClick(p.fleetNumber)}>
				<div className="box-content">
					<Row>
						<Col className="pallet-jack-box-title" xs={12}>
							<h3>{ p.fleetNumber }</h3>
							<span>{ (p.currentVoltage / 1000).toFixed(3) }V</span>
							<div className="box-icon">
								<Icon name={boxIcon} />
							</div>
						</Col>
						<Col xs={12}>
							<table className="table table-condensed">
								<tbody>
									{
										p.isCharging ? (
											<tr>
												<th><Icon fixedWidth name="clock-o" /> Started at:</th>
												<td>{ DurationParse.toHourMin(s.chargingTimeInSec, 'short') } ago</td>
											</tr>
										) : (
											<tr>
												<th><Icon fixedWidth name="clock-o" /> Last Charge:</th>
												<td>{ DurationParse.toHourMin(p.lastChargeTotalTimeInMin * 60, 'short') }</td>
											</tr>
										)
									}
									<tr>
										<th><Icon fixedWidth name="plug" /> Last 24hrs:</th>
										<td>Total of { DurationParse.toHourMin(p.last24HoursChargingTimeInMin * 60, 'short') }</td>
									</tr>
									<tr>
										<th><Icon fixedWidth name="map-marker" /> Last Ping:</th>
										<td>{ DurationParse.toHourMin(s.lastPingTimeInSec, 'short') } ago</td>
									</tr>
								</tbody>
							</table>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

PalletJackBox.propTypes = propTypes;

export default PalletJackBox;