import moment from 'moment';
import React from 'react';
import ReactTable, { Column, RowInfo } from 'react-table';
import { IPallet } from '../../../../../../app/models/Pallet';
import FontAwesome from 'react-fontawesome';
import { Callout } from '../../../../../common/uiElements';

interface IProps {
	pallets: IPallet[];
	isConsolidatedPallet: boolean;

	isReadOnly?: boolean;

	hideStore?: boolean;
	locationInTrailer?: boolean;
	hideLoadedAt?: boolean;

	onSelectPallet: (pallet: IPallet, rowIndex: number) => void;
	onDeletePallet: (pallet: IPallet) => void;
}

const PalletsList: React.FC<IProps> = props => (
	<>
		<Callout icon="info-circle" color="info" title="Tap on the pallet to edit" />
		<ReactTable
			minRows={0}
			showPagination={false}
			data={props.pallets}
			getTdProps={(state: any, rowInfo?: RowInfo, column?: Column) => {
				// Disable row selection for the DELETE column
				if (!rowInfo || column?.id === 'delete') return {};

				const pallet = rowInfo.original as IPallet;
				return {
					onClick: () => props.onSelectPallet && props.onSelectPallet(pallet, rowInfo.index)
				}
			}}
			getTrProps={(state: any, rowInfo?: RowInfo) => {
				if (!rowInfo) return {};

				const pallet = rowInfo.original as IPallet;
				return {
					className: pallet.isDeleted ? 'red line-through text-italic text-bold' : '',
				}
			}}
			columns={[
				{
					Header: '#',
					id: 'order',
					width: 30,
					Cell: row => row.index + 1,
				},
				{
					Header: 'Store',
					accessor: 'store',
					show: !props.hideStore,
					Cell: row => !row.value ? 'Unknown Store' : (row.value.storeNumber ? row.value.storeNumber + ' - ' : '' ) + row.value.name,
				},
				{
					Header: 'Pallet ID',
					id: 'palletId',
					width: 150,
					Cell: row => {
						const pallet = row.original as IPallet;

						if (!pallet.isUpstackedWith)
							return pallet.palletId;

						return (
							<>
								{pallet.palletId}
								<br />
								<span>
									{ 
										pallet.isUpstackedWith.map(palletUpstacked => (
											<span className={palletUpstacked.wasPalletTimberKept ? 'text-danger' : 'text-info'} key={palletUpstacked.palletId}>
												{ palletUpstacked.palletId } ({ palletUpstacked.wasPalletTimberKept ? 'Forked Up' : 'Upstacked' })
												<br />
											</span>
										))
									}
								</span>
							</>
						)
					}
				},
				{
					Header: 'Commodity',
					id: 'commodity',
					width: 100,
					Cell: row => {
						const pallet = row.original as IPallet;

						if (!pallet.isUpstackedWith)
							return pallet.commodity;

						return (
							<>
								{pallet.commodity}
								<br />
								<span className="text-muted">
									{ 
										pallet.isUpstackedWith.map(palletUpstacked => (
											<React.Fragment key={palletUpstacked.palletId}>
												{ palletUpstacked.commodity }
												<br />
											</React.Fragment>
										))
									}
								</span>
							</>
						)
					}
				},
				{
					Header: 'Type',
					id: 'palletType',
					width: 80,
					Cell: row => {
						const pallet = row.original as IPallet;

						if (!pallet.isUpstackedWith)
							return pallet.palletType || 'N/A';

						return (
							<>
								{pallet.palletType || 'N/A'}
								<br />
								<span className="text-muted">
									{ 
										pallet.isUpstackedWith.map(palletUpstacked => (
											<React.Fragment key={palletUpstacked.palletId}>
												{ palletUpstacked.palletType || 'N/A' }
												<br />
											</React.Fragment>
										))
									}
								</span>
							</>
						)
					}
				},
				{
					Header: 'Location',
					accessor: 'locationInTrailer',
					width: 80,
					show: !props.locationInTrailer,
					Cell: row => row.value ? row.value === "L" ? "LEFT" : "RIGHT" : '-'
				},
				{
					Header: 'Loaded At',
					accessor: 'loadedOn',
					width: 80,
					show: !props.hideLoadedAt,
					Cell: row => row.value ? moment(row.value).format('HH:mm') :  '-'
				},
				{
					Header: 'Consolidation',
					accessor: 'wasPalletTimberKept',
					show: props.isConsolidatedPallet,
					Cell: row => row.value ? 'Forked Up' : 'Upstacked',
				},
				{
					show: !props.isReadOnly,
					id: 'delete',
					className: 'text-center',
					width: 35,
					accessor: d => d,
					Cell: row => {
						const pallet = row.value as IPallet;

						return (
							<FontAwesome 
								name={pallet.isDeleted ? "undo" : "trash"} 
								title={pallet.isDeleted ? "Undo Deletion" : "Delete Pallet"}
								className={`cursor-pointer text-${pallet.isDeleted ? "info" : "red"}`}
								style={{ fontSize: 18 }} 
								onClick={() => props.onDeletePallet(pallet)}
							/>
						)
					},
				}
			]}
		/>
	</>
);



export default PalletsList;