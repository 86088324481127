import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import TrackingApi from '../../../app/api/TrackingApi';
import { VehicleBreadcrumb } from '../tracking';

const propTypes = {
	vehFleetNumber: PropTypes.string.isRequired,
	startTime: PropTypes.string,
	finishTime: PropTypes.string,
	onLoadBreadcrumbComplete: PropTypes.func
};

class VehicleBreadcrumbsList extends Component {
	constructor(props) {
		super(props);
		this.breadcrumbs = [];

		this.state = {
			breadcrumbsList: []
		};

		this.fetchBreadcrumbs();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	componentDidUpdate(prevProps) {		
		if (prevProps.vehFleetNumber !== this.props.vehFleetNumber)
			return this.fetchBreadcrumbs();
	}

	shouldComponentUpdate(nextProps, nextState) {
		const { breadcrumbsList } = this.state;
		
		// If the breadcrumbs are the same, it doesn't need to re-render
		if (this.props.vehFleetNumber !== nextProps.vehFleetNumber)
			return true;
		else if (breadcrumbsList.length > 0 && nextState.breadcrumbsList.length > 0 && breadcrumbsList[0].trkPingId === nextState.breadcrumbsList[0].trkPingId)
			return false;

		return true;
	}

	fetchBreadcrumbs = () => {	
		let { vehFleetNumber, startTime, finishTime } = this.props;
		
		if (!vehFleetNumber)
			return;

		startTime = moment(startTime || moment().add(-6, 'hours').format('YYYY-MM-DD HH:mm'));
		finishTime = moment(finishTime || moment().format('YYYY-MM-DD 23:59'));
		
		const isOverMaxFilterPeriod = moment.duration(finishTime.diff(startTime)).asMinutes() >= 2880;

		if (isOverMaxFilterPeriod)
			finishTime = moment(startTime).add(12, 'hours');
		
		TrackingApi.breadcrumbsByVehPeriod(vehFleetNumber, startTime.unix(), finishTime.unix())
			.then(breadcrumbsList => {
				if (this.unmounted) return;
			
				this.setState({ breadcrumbsList });
				setTimeout(this.fitMap, 200);
			})
			.catch(error => {
				console.error(error);
			});
	}

	fitMap = () => {
		if (!this.props.map)
			return;
			
		const { breadcrumbsList } = this.state;
		if (breadcrumbsList.length > 0) {
			var bounds = new window.google.maps.LatLngBounds();
			breadcrumbsList.forEach(({ trkPositionLat, trkPositionLong }) => {
				bounds.extend(new window.google.maps.LatLng(trkPositionLat, trkPositionLong))
			});

			//this.props.map.panToBounds(bounds);
			this.props.map.fitBounds(bounds);
		}
	}

	render() {
		const s = this.state;

		if (!s.breadcrumbsList || s.breadcrumbsList.length === 0)
			return <div></div>;

		return s.breadcrumbsList.map((breadcrumb, index) => {
			const { trkPositionLat, trkPositionLong } = breadcrumb;
			if (!trkPositionLat || !trkPositionLong)
				return null;
				
			return (
				<VehicleBreadcrumb 
					key={index}
					{ ...breadcrumb }
				/>
			);
		});
	}
}

VehicleBreadcrumbsList.propTypes = propTypes;

export { VehicleBreadcrumbsList };