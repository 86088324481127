import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

import VehiclesApi from '../../../app/api/VehiclesApi';
import { Loader } from '../../common/uiElements';
import { VehicleDamagePicture } from '../../common/vehicle';
import componentRequestHandler from '../../../app/api/helpers/componentRequestHandler';

export default class VehicleDamages extends Component {
	static propTypes = {
		fleetNumber: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.arrayOf(
				PropTypes.string.isRequired
			),
		]),
		vehicleDamages: PropTypes.array,
		shouldFetchData: PropTypes.bool,
		showOnlyOpenedDamages: PropTypes.bool
	}

	static defaultProps = {
		shouldFetchData: true
	}

	state = {
		vehicleDamages: this.props.vehicleDamages || []
	};

	componentDidMount() {
		if (this.props.shouldFetchData && this.props.fleetNumber)
			return this.fetchVehicleDamages();
	}

	fetchVehicleDamages = () => {
		const promise = () => VehiclesApi.getVehicleDamages(this.props.fleetNumber, this.props.showOnlyOpenedDamages);
		componentRequestHandler(this, promise, 'vehicleDamages');
	}

	loadViewerJs = () => {
		window.$('.image-viewer').each(function() {
			new Viewer(this, {
				title: false,
				toolbar: false,
				movable: false,
			}); 
		});
	}

	render() {
		const { state } = this;

		if (state.isLoading)
			return <Loader />;

		return (
			<div className="vehicle-damages-photos">
				{
					state.vehicleDamages.length === 0 ? (
						<i>No picture has been uploaded</i>
					) : (
						<ul className="list-inline list-unstyled">
							{
								state.vehicleDamages.map(({ id, fleetNumber }) => {
									return (
										<li className="cursor-search" style={{padding: '5px'}} key={id}>
											<VehicleDamagePicture 
												damageId={id}
												fleetNumber={fleetNumber}
												onLoadImage={this.loadViewerJs}
											/>
										</li>
									);
								})
							}
						</ul>
					)
				}
				<div className="clearfix"></div>
			</div>
		);
	}
}