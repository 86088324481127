export default {
	root: '/FaultReport',
	many: '/FaultReport/ForMany',
	exportReport: '/FaultReport/Report',
	byId: (faultReportId: number) => `/FaultReport/${faultReportId}`,
	photoFile: (faultReportId: number, fileId: number) => `/FaultReport/${faultReportId}/Photo/${fileId}`,
	history: (faultReportId: number) => `/FaultReport/${faultReportId}/History`,
	serviceTrackerNumber: (faultReportId: number) => `/FaultReport/${faultReportId}/ServiceTrackerNumber`,
	statusComment: (faultReportId: number) => `/FaultReport/${faultReportId}/StatusComment`,
	quickFix: (faultReportId: number) => `/FaultReport/${faultReportId}/QuickFix`,
}