import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { Button, Table, PanelGroup, Panel, FormGroup } from 'react-bootstrap';
import helper from '../helper';
import { v4 as uuid } from 'uuid';

import FaultReportModalDetails from '../../faultReport/subComponents/FaultReportModalDetails';
import VehicleDamagesComponent from '../../../vehicles/VehicleDamages';
import PreStartDetailsModal from '../subComponents/PreStartDetailsModal';
import { Callout } from '../../../../common/uiElements';

const propTypes = {
	data: PropTypes.object.isRequired,
	securityIssues: PropTypes.array,
	mechanicalIssues: PropTypes.array
};

const defaultProps = {
	securityIssues: [],
	mechanicalIssues: []
};

class CheckoutDetails extends Component {    
	uniqueId = uuid();

	constructor(props) {
		super(props);
		this.state = {
			showFaultReportModal: false,
			selectedFaultReport: {}
		};
	}

	_handleViewFaultReport = (faultReport) => {
		this.setState({ 
			showFaultReportModal: true,
			selectedFaultReport: faultReport
		});
	}

	_handleCloseFaultReportModal = () => {
		this.setState({ 
			showFaultReportModal: false,
			selectedFaultReport: {}
		}); 
	}

	_handleOpenPreStartDetailsModal = () => {
		this.setState({ showPreStartModal: true });
	}

	_handleClosePreStartDetailsModal = () => {
		this.setState({ showPreStartModal: false });
	}

	render() {
		const s = this.state;
		const p = this.props;

		const anyQuestionIssues = p.securityIssues.length + p.mechanicalIssues.length > 0;
		const isLastPreStartReport = p.data.lastReportedOnLocal ? true : false;
		const FaultReports = p.data.FaultReports.filter(f => f.isLastPreStartReport === isLastPreStartReport);
		const VehicleDamages = p.data.VehicleDamages.filter(f => f.isLastPreStartReport === isLastPreStartReport);

		return (
			<div>
				<h3 className="box-title">DETAILS</h3>
				{
					!isLastPreStartReport && helper.isThereProblemFirstPartTimeSpent(p.data.firstPartTimeSpentInSec) && (
						<Callout color="warning" title="">
							<b>The driver took less than 10 minutes to complete this Pre-Start.</b>
						</Callout>
					)
				}
				<PanelGroup id={`checkout-details-panel_` + this.uniqueId} accordion>
					{
						!isLastPreStartReport && (
							<Panel eventKey="1" bsStyle={anyQuestionIssues ? 'danger' : ''}>
								<Panel.Heading>
									<Panel.Title toggle>
										<span className={anyQuestionIssues ? 'text-bold' : ''}>
											{ anyQuestionIssues && <><Icon name="warning" />&nbsp;</>}
											Pre-Start Issues ({p.securityIssues.length + p.mechanicalIssues.length})
										</span>
									</Panel.Title>
								</Panel.Heading>
								<Panel.Body collapsible >
									{
										!anyQuestionIssues ? (
											<i>No issues were registered</i>
										) : (
											<div>
												{
													p.securityIssues.length > 0 && (
														<div>
															<label>Compliance</label>
															<ul className="list">
																{
																	p.securityIssues.map((issue, index) => {	
																		const text = issue.text || issue;
																		const color = issue.color || '';
																		
																		return (
																			<li className={`text-${color}`} key={index}>
																				{ color ? <b>{ text }</b> : text }
																			</li>
																		);
																	})
																}
															</ul>          
														</div>
													)
												}
												{
													p.mechanicalIssues.length > 0 && (
														<div>
															<label>Mechanical</label>
															<ul className="list">
																{
																	p.mechanicalIssues.map((issue, index) => {	
																		const text = issue.text || issue;
																		const color = issue.color || '';

																		return (
																			<li className={`text-${color}`} key={index}>
																				{ color ? <b>{ text }</b> : text }
																			</li>
																		);
																	})
																}
															</ul>          
														</div>
													)
												}
											</div>
										)
									}
								</Panel.Body>									
							</Panel>
						)
					}
					<Panel eventKey="2" bsStyle={FaultReports.length > 0 ? 'danger' : ''}>
						<Panel.Heading>
							<Panel.Title toggle>
								<span className={FaultReports.length > 0 ? 'text-bold' : ''}>
									{ FaultReports.length > 0 && <><Icon name="warning" />&nbsp;</>}
									Fault Reports ({FaultReports.length})
								</span>
							</Panel.Title>
						</Panel.Heading>
						<Panel.Body collapsible>
							{
								FaultReports.length === 0 ? (
									<i>No Fault Report was registered</i>
								) : (
									<Table>
										<thead>
											<tr>
												<th>Fleet Number</th>
												<th>Type</th>
												<th>View</th>
											</tr>
										</thead>
										<tbody>
											{
												FaultReports.map((faultReport, index) => {
													return (
														<tr key={index}>
															<td>{faultReport.reportedVehicleId}</td>
															<td>{faultReport.dtoFaultReportTypeName}</td>
															<td 
																className="action-btn action-view-btn"
																onClick={() => { this._handleViewFaultReport(faultReport); }}
															>
																<Icon name="search" />
															</td>
														</tr>
													);
												})
											}
										</tbody>
									</Table>
								)
							}
							{ 
								s.showFaultReportModal && (
									<FaultReportModalDetails 
										show
										isReadOnly
										isEditable
										data={s.selectedFaultReport} 
										onClose={this._handleCloseFaultReportModal} /> 
								)
							}
						</Panel.Body>
					</Panel>
					<Panel eventKey="3" bsStyle={VehicleDamages.length > 0 ? 'danger' : ''}>
						<Panel.Heading>
							<Panel.Title toggle>
								<span className={VehicleDamages.length > 0 ? 'text-bold' : ''}>
									{ VehicleDamages.length > 0 && <><Icon name="warning" />&nbsp;</>}
									Vehicle Damages ({VehicleDamages.length})
								</span>
							</Panel.Title>
						</Panel.Heading>
						<Panel.Body collapsible>
							{
								((VehicleDamages.length > 0) || (p.data.lastDamageDescription)) ? (
									<div>
										{
											isLastPreStartReport && (
												<div>
													<FormGroup>
														<label>What happened?</label>
														<p>{p.data.lastDamageDescription}</p>
													</FormGroup>
													<label><Icon name="camera" /> Photos</label>
												</div>
											)
										}
										<VehicleDamagesComponent vehicleDamages={VehicleDamages} />   
									</div>
								) : (
									<p><i>No damage was registered</i></p>
								)
							}
						</Panel.Body>
					</Panel>
				</PanelGroup>
				<Button
					block
					bsStyle="info"
					onClick={this._handleOpenPreStartDetailsModal}>
					<Icon name="search" /> See the complete Pre-Start
				</Button>
				{
					s.showPreStartModal && (
						<PreStartDetailsModal 
							show
							data={p.data}
							showLastPartTab={isLastPreStartReport}
							onClose={this._handleClosePreStartDetailsModal}
						/>
					)
				}
			</div>
		);
	}
}

CheckoutDetails.propTypes = propTypes;
CheckoutDetails.defaultProps = defaultProps;

export default CheckoutDetails;