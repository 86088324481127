import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import groups from '../../../app/schemas/PermissionGroups';

import ListsApi from '../../../app/api/ListsApi';
import { Select } from '../../common/inputs';
import { ErrorBox } from '../../common/uiElements';

const propTypes = {
	id: PropTypes.string,
	cssClass: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func
};

const defaultProps = {
	id: 'permissionGroupId',
	cssClass: ''
};

class AzurePermissionGroupsList extends Component {
	constructor() {
		super();

		this.state = {
			isLoading: false,
			error: null,
			list: []
		};
	}

	UNSAFE_componentWillMount() {
		return this.fetchData();
	}
    
	componentWillUnmount() {
		return this.unmounted = true;
	}

	fetchData = () => {
		this.setState({ isLoading: true, error: false, list: [] });
		ListsApi.getAzurePermissionGroups()
			.then(list =>  {
				if (this.unmounted) return;

				if (this.props.loggedUser.permissionGroupName !== groups.ADMIN)
					list = list.filter(l => l.name !== 'Admin');

				this.setState({
					isLoading: false,
					list
				});
			})
			.catch(error => {
				console.error(error);
				this.setState({ error });
			});
	}

	render () {
		const s = this.state;
		
		if (s.error)
			return <ErrorBox error={s.error} retryFunc={this.fetchData} />;
		
		return <Select {...this.props} isLoading={s.isLoading} data={s.list} />;
	}
}

AzurePermissionGroupsList.propTypes = propTypes;
AzurePermissionGroupsList.defaultProps = defaultProps;

const mapStateToProps = (state) => {
	return { loggedUser: state.user.data };
};

const AzurePermissionGroupsListComponent = connect(mapStateToProps)(AzurePermissionGroupsList);

export { AzurePermissionGroupsListComponent as AzurePermissionGroupsList };