import React from 'react';
import Moment from 'moment';
import { v4 as uuid } from 'uuid';
import { extendMoment } from 'moment-range';
import alert from './alert';
import { WindowsOverlappedDetails } from '../../components/common/uiElements';

const moment = extendMoment(Moment);
/**
 * Checks if there are new windows overlapping the existing ones
 * @param {array} windowsToCompareArray The new windows you wanna check
 * @param {array} existingWindowsArray The existing windows to compare
 */
export function thereAreOverlappedWindows(windowsToCompareArray, existingWindowsArray) {
	if (!windowsToCompareArray)
		throw new Error('windowsToCompare cannot be null');
	else if (!existingWindowsArray)
		throw new Error('existingWindowsArray cannot be null');
		
	// Keep the new windows that overlap existed ones to show to the user
	const overlappedWindows = [];

	// Go through all new windows
	windowsToCompareArray.forEach(windowToCompare => {
		const momentNewWindowStartTime = moment('2 ' + windowToCompare.startTime, 'D HH:mm');
		const momentNewWindowEndTime = moment('2 ' + windowToCompare.endTime, 'D HH:mm');
		
		const isWindowToCompareEndGreaterThanStart = isEndTimeGreaterThanStartTime(windowToCompare.startTime, windowToCompare.endTime);
		if (isWindowToCompareEndGreaterThanStart)
			momentNewWindowStartTime.subtract(1, 'day');
		
		const newWindowRange = moment.range([momentNewWindowStartTime, momentNewWindowEndTime]);

		// Go through all current windows
		existingWindowsArray.filter(p => p.dayOfWeek === windowToCompare.dayOfWeek && p.tempId !== windowToCompare.tempId).forEach(currentWindow => {
			const momentCurrentWindowStartTime = moment('2 ' + currentWindow.startTime, 'D HH:mm');
			const momentCurrentWindowEndTime = moment('2 ' + currentWindow.endTime, 'D HH:mm');

			if (isEndTimeGreaterThanStartTime(currentWindow.startTime, currentWindow.endTime))
				momentCurrentWindowStartTime.subtract(1, 'day');

			const currentWindowRange = moment.range([momentCurrentWindowStartTime, momentCurrentWindowEndTime]);
			
			if (newWindowRange.overlaps(currentWindowRange))
				overlappedWindows.push({
					dayOfWeek: windowToCompare.dayOfWeek,
					newStartTime: windowToCompare.startTime,
					newEndTime: windowToCompare.endTime,
					existingStartTime: currentWindow.startTime,
					existingEndTime: currentWindow.endTime
				});
		});
	});

	if (overlappedWindows.length) {
		alert(<WindowsOverlappedDetails overlappedWindows={overlappedWindows} />);
		return true;
	}

	return false;
}

/**
 * Checks if the window is invalid
 * @param {string} startTime 
 * @param {string} endTime 
 * @param {array} selectedDaysOfWeek 
 */
export function isWindowInvalid(startTime, endTime, selectedDaysOfWeek) {
	const isStartTimeValid = startTime && moment(startTime, 'HH:mm', true).isValid();
	const isEndTimeValid = endTime && moment(endTime, 'HH:mm', true).isValid();

	if (selectedDaysOfWeek && selectedDaysOfWeek.length === 0)
		return 'Select at least one Week Day';
	else if (!isStartTimeValid)
		return 'Start Time is invalid';
	else if (!isEndTimeValid)
		return 'End Time is invalid';

	return false;
}

/**
 * 
 * @param {string} startTime 
 * @param {string} endTime 
 */
export function isEndTimeGreaterThanStartTime(startTime, endTime) {
	return moment.duration(moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'))).asMinutes() <= 0;
}

/**
 * Add temporary IDs for later comparison
 * @param {array} windowsArray 
 */
export function setTemporaryIds(windowsArray) {
	return windowsArray.map(p => {
		if (!p.pTempId)
			p.tempId = uuid();

		return p;
	});
}