import React, { Component } from 'react';
import moment from 'moment';
import toastr from 'toastr';
import Icon from 'react-fontawesome';

import { Row, Col, FormGroup, FormControl, Modal, Button } from 'react-bootstrap';
import { MainContent } from '../../../../common/layout';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

import VehiclesApi from '../../../../../app/api/VehiclesApi';

import VehicleDamagesList from './VehicleDamagesList';
import VehicleDamagesListFilter from './VehicleDamagesListFilter';

import { ContentBox, ErrorBox, Loader, Form } from '../../../../common/uiElements';
import { VehicleDamagePicture } from '../../../../common/vehicle';

class VehicleDamagesListContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedVehicleDamage: null,
			vehicleDamagesList: [],
			totalPages: 0,
			totalRecords: 0,
			page: 0,
			pageSize: 10,
			sorted: [],
			filter: {
				filterBy: 'openDate',
				startTime: moment().format('YYYY-MM-DD'),
				endTime: moment().format('YYYY-MM-DD'),
				vehicle: '',
				isFixed: ''
			},
			isLoading: false,
			isSaving: false,
			error: null,
			errorSaving: null
		};

		this.handleSearch = () => {
			this.setState({
				page: 0,
				sorted: []
			}, this.fetchVehicleDamagesList);
		};

		this.handleChangeFilter = (filter) => {
			this.setState({
				filter: {
					...this.state.filter,
					...filter
				}
			});
		};

		this.handleChangePage = page => {
			this.setState({ page }, this.fetchVehicleDamagesList);
		};

		this.handleChangePageSize = pageSize => {
			this.setState({ pageSize }, this.fetchVehicleDamagesList);
		};

		this.handleChangeSorted = sorted => {
			this.setState({ sorted }, this.fetchVehicleDamagesList);
		};

		this.handleViewDetailsModal = vehicleDamage => {
			this.setState({ 
				selectedVehicleDamage: vehicleDamage 
			});
		};

		this.handleCloseDetailsModal = () => {
			this.setState({ selectedVehicleDamage: null });
		};

		this._handleChangeSelectedVehicleDamage = e => {
			const { id, value } = e.target;
			this.setState({
				selectedVehicleDamage: {
					...this.state.selectedVehicleDamage,
					[id]: value
				}
			});
		};
	}    

	UNSAFE_componentWillMount() {
		this.fetchVehicleDamagesList();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	loadImageViewer = () => {
		window.setTimeout(() => {
			window.$('.image-viewer').each(function() {
				new Viewer(this, {
					title: false,
					toolbar: false,
					movable: false,
				}); 
			});
		}, 500);
	}

	fetchVehicleDamagesList = () => {
		this.setState({ isLoading: true, error: null });
		const { filter, pageSize, sorted } = this.state;
		const page = this.state.page + 1;

		if (filter.filterBy === 'all') {
			delete filter.startTime;
			delete filter.endTime;
		} else if (filter.filterBy === 'openDate') {
			filter.startTime = moment(filter.startTime).format('YYYY-MM-DD');
			filter.endTime = moment(filter.endTime).format('YYYY-MM-DD');
		}

		VehiclesApi.getVehicleDamagesList({ ...filter, page, pageSize, sorted })
			.then(data => {
				if (this.unmounted) return;
				
				const { totalRecords, totalPages, records } = data;

				this.setState({ 
					isLoading: false, 
					vehicleDamagesList: records,
					totalPages,
					totalRecords
				}, this.loadImageViewer);
			})
			.catch(error => {
				console.error(error);
				this.setState({ isLoading: false, error });
			});
	}

	handleSubmitDamageStatus = () => {
		if (!window.$(this.form).find('form').valid())
			return;

		this.setState({ isSaving: true, errorSaving: null });

		const { id, fleetNumber, repairedOn, comments, serviceTrackerNumber } = this.state.selectedVehicleDamage;
		VehiclesApi.setVehicleDamageAsRepaired(id, fleetNumber, repairedOn, comments, serviceTrackerNumber)
			.then(() => {
				toastr.success('The data was successfully saved', 'Success!');
				if (this.unmounted) return;
				
				this.setState({ 
					isSaving: false,
					selectedVehicleDamage: null
				}, this.fetchVehicleDamagesList);
			})
			.catch(errorSaving => {
				console.error(errorSaving);
				this.setState({ isSaving: false, errorSaving });
			});
	}
		
	render() {
		const s = this.state;
		//const p = this.props;

		return (
			<MainContent title="Vehicle Damages">
				<ContentBox color="primary" title="Filters">
					<VehicleDamagesListFilter 
						{...s.filter} 
						onChange={this.handleChangeFilter} 
						onPressEnter={this.handleSearch}
					/>
					<hr />
					<Button 
						bsStyle="primary" 
						disabled={s.isLoading}
						block onClick={this.handleSearch}
					>
						Search <Icon name="search" />
					</Button>
				</ContentBox>
				{
					s.error ? (
						<ErrorBox error={s.error} retryFunc={this.fetchVehicleDamagesList} />
					) : (
						<VehicleDamagesList 
							data={s.vehicleDamagesList}
							page={s.page}
							pageSize={s.pageSize}
							totalPages={s.totalPages}
							sorted={s.sorted}
							isLoading={s.isLoading}
							onPageChange={this.handleChangePage}
							onPageSizeChange={this.handleChangePageSize}
							onSortedChange={this.handleChangeSorted}
							onViewDetails={this.handleViewDetailsModal}
						/>
					)
				}
				<Modal show={s.selectedVehicleDamage ? true : false} onHide={this.handleCloseDetailsModal}>
					<Modal.Header closeButton>
						<Modal.Title>Vehicle Damage #{s.selectedVehicleDamage?.id}</Modal.Title>
					</Modal.Header>
					{
						s.selectedVehicleDamage && (
							<Modal.Body>
								{
									s.isSaving ? (
										<Loader isSaving />
									) : (
										<div ref={ref => this.form = ref}>
											<Form
												validations={{ 
													repairedOn: {
														required: true,
														greaterOrEqualThan: '#checkCreatedOn'
													}
												}}

												messages={{ 
													repairedOn: {
														greaterOrEqualThan: 'Must be greater than the report date'
													}
												}}>
												<Row>
												<Col xs={6}>
														<FormGroup>
															<label>Service Tracker Number</label>
															<FormControl
																id="serviceTrackerNumber"
																maxLength={20}
																value={s.selectedVehicleDamage.serviceTrackerNumber || ''}
																onChange={this._handleChangeSelectedVehicleDamage}
															/>
														</FormGroup>
													</Col>
													<Col xs={6}>
														<FormGroup>
															<label>Repaired On</label>
															<FormControl
																id="repairedOn"
																type="date" 
																value={s.selectedVehicleDamage.repairedOn || ''}
																onChange={this._handleChangeSelectedVehicleDamage}/>
															<FormControl id="checkCreatedOn" type="hidden" value={moment(s.selectedVehicleDamage.createdOnLocal).format('YYYY-MM-DD')} />
														</FormGroup>
													</Col>
												</Row>
												<Row>
													<Col xs={3}>
														<FormGroup>
															<label>Reported On</label>
															<p>{ moment(s.selectedVehicleDamage.createdOnLocal).format('DD/MM/YYYY') }</p>
														</FormGroup>
													</Col>
													<Col xs={3}>
														<FormGroup>
															<label>Reported By</label>
															<p>{ s.selectedVehicleDamage.WorkerCreatedBy.shortName }</p>
														</FormGroup>
													</Col>
													<Col xs={6}>
														<FormGroup>
															<label>Closed By</label>
															<p>
																{ s.selectedVehicleDamage.WorkerClosedBy || '-' }
															</p>
														</FormGroup>
													</Col>
													<Col xs={12}>
														<FormGroup>
															<label>Comments</label>
															<FormControl
																id="comments"
																value={s.selectedVehicleDamage.comments || ''}
																placeholder="Type your comments here..."
																componentClass="textarea"
																rows={2}
																onChange={this._handleChangeSelectedVehicleDamage}
															/>
														</FormGroup>
													</Col>
												</Row>
												<div>
													<label>Photo</label><br />
													<VehicleDamagePicture 
														damageId={s.selectedVehicleDamage.id}
														fleetNumber={s.selectedVehicleDamage.fleetNumber}
														onLoadImage={this.loadImageViewer}
													/>
												</div>
											</Form>
										</div>
									)
								}
							</Modal.Body>
						)
					}
					<Modal.Footer>
						{
							s.selectedVehicleDamage && !s.isSaving && (
								<Button type="submit" className="pull-left" onClick={this.handleSubmitDamageStatus} bsStyle="success">Save</Button>
							)
						}
						<Button onClick={this.handleCloseDetailsModal} bsStyle="default">Close</Button>
					</Modal.Footer>
				</Modal>
			</MainContent>
		);
	}
}

export default VehicleDamagesListContainer;