export default {
	loadAddress(lat, lng) {
		return new Promise(resolve => {
			if (!lat || !lng)
				return resolve('Invalid address');
			
			const geocoder = new window.google.maps.Geocoder();
			geocoder.geocode({
				'location': { lat, lng }
			}, (results, status) => {
				
				if (status !== 'OK')
				return resolve('Invalid address');
				
				const result = results[0];
				if (!result || !result.formatted_address)
					return resolve('Address not found');

				return resolve(result.formatted_address.replace(', Australia', ''));
			});
		});
	}
};