import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { Table, FormControl } from 'react-bootstrap';
import { weekDaysShort } from '../../../../../../app/store/weekDays';
import { TimeInput, Checkbox } from '../../../../../common/inputs';
import { Form, TextReadMore } from '../../../../../common/uiElements';

const propTypes = {
	enableEditMode: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	accessWindowsList: PropTypes.arrayOf(
		PropTypes.shape({
			dayOfWeek: PropTypes.number.isRequired,
			startTime: PropTypes.string.isRequired,
			endTime: PropTypes.string.isRequired,
			isAttended: PropTypes.bool,
			id: PropTypes.number
		})
	).isRequired,
	onClickRemove: PropTypes.func,
	onChange: PropTypes.func
};

const defaultProps = {
	enableEditMode: false,
	accessWindowsList: [],
	onClickRemove: () => {},
	onChange: () => {}
};

const WindowsTable = props => {	
	const renderDayOfWeekCell = (dayOfWeek, rowSpan = 1) => (
		<th className="th-week-day" rowSpan={rowSpan}>
			{ dayOfWeek }
		</th>
	);

	const renderHourCellContent = (id, value, errorToShow, tempId) => {
		if (props.enableEditMode)
			return (
				<TimeInput 
					id={id}
					value={value}
					errorToShow={errorToShow}
					onChange={event => props.onChange(tempId, event)}
				/>
			);

		return value;
	};
	
	return (
		<Form>
			<Table>
				<thead>
					<tr>
						<th>DoW</th>
						<th>Start</th>
						<th>End</th>
						<th>Access</th>
						<th>Comments</th>
						{
							!props.isReadOnly && (
								<th className="text-center">
									Remove
								</th>
							)
						}
					</tr>
				</thead>
				<tbody>
					{
						weekDaysShort.map((dayOfWeek, i) => {
							const dayOfWeekNumber = i + 1;
							const accessWindowsPerWeekDay = props.accessWindowsList.filter(p => p.dayOfWeek === dayOfWeekNumber && !p.isDeleted);
							const windowsQty = accessWindowsPerWeekDay.length;
							let trClassName = dayOfWeekNumber % 2 === 0 ? 'even' : 'odd';
			
							if (dayOfWeekNumber === 6)
								trClassName = 'saturday';
							else if (dayOfWeekNumber === 7)
								trClassName = 'sunday';
			
							if (windowsQty === 0)
								return (
									<tr key={i} className={trClassName}>
										{ renderDayOfWeekCell(dayOfWeek) }
										<td>-</td>
										<td>-</td>
										<td>-</td>
										<td>-</td>
										{
											!props.isReadOnly && (
												<td className="text-center">-</td>
											)
										}
									</tr>
								);
			
							return accessWindowsPerWeekDay.map(({ startTime, endTime, isAttended, comments, tempId, invalidMessage }, j) => (
								<tr key={j} className={trClassName}>
									{ j === 0 && renderDayOfWeekCell(dayOfWeek, windowsQty) }
									<td width={120}>
										{ renderHourCellContent('startTime', startTime, invalidMessage, tempId) }
									</td>
									<td width={120}>
										{ renderHourCellContent('endTime', endTime, invalidMessage, tempId) }
									</td>
									<td className={`text-${isAttended ? 'success' : 'danger'}`}>
										{
											props.enableEditMode ? (
												<Checkbox 
													bold
													id="isAttended"
													checked={isAttended}
													onChange={event => props.onChange(tempId, event)}
													text={ isAttended ? 'Attended' : 'Not Attended' }
												/>
											) : (
												<b>{ isAttended ? 'Attended' : 'Not Attended' }</b>
											)
										}
									</td>
									<td >
										{
											props.enableEditMode ? (
												<FormControl
													id="comments" 
													maxLength="1000" 
													componentClass="textarea"
													rows={2}
													value={comments || ''} 
													onChange={event => props.onChange(tempId, event)} 
												/>
											) : (
												<TextReadMore 
													maxLength={50}
													text={comments || '-'} 
												/>
											)
										}
									</td>
									{
										!props.isReadOnly && (
											<td className="table-action-button red">
												<Icon name="trash" onClick={props.onClickRemove.bind(this, tempId)} />
											</td>
										)
									}
								</tr>
							));
						})
					}
				</tbody>
			</Table>
		</Form>
	);
};

WindowsTable.propTypes = propTypes;
WindowsTable.defaultProps = defaultProps;

export default WindowsTable;
