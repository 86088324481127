import React from 'react';
import ListsApi from '../../../app/api/ListsApi';
import ListContainer from './_ListContainer';
import { FormControlProps } from 'react-bootstrap';

interface IProps extends FormControlProps {
	rosterUpdateReasonCategoryId?: string;
}

export const RosterUpdateReasonsList: React.FC<IProps> = props => (
	<ListContainer 
		{...props as any}
		promise={() => ListsApi.getRosterUpdateReasons(props.rosterUpdateReasonCategoryId ? parseInt(props.rosterUpdateReasonCategoryId) : undefined)}
	/>
);