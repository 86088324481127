/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
//import './style.scss';

import { VehicleDamagePicture } from '../../../../common/vehicle';
import Viewer from 'viewerjs';

const propTypes = {
	data: PropTypes.array.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired,
	sorted: PropTypes.arrayOf(
		PropTypes.shape({
			desc: PropTypes.bool.isRequired,
			id: PropTypes.string.isRequired,
		})
	),
	defaultPageSize: PropTypes.number,
	isLoading: PropTypes.bool.isRequired,
	onPageChange: PropTypes.func,
	onPageSizeChange: PropTypes.func,
	onSortedChange: PropTypes.func,
	onFetchData: PropTypes.func,
	onViewDetails: PropTypes.func
};

const VehicleDamagesList = props => {
	const handleSeeFaultReportDetails = (vehicleDamage) => { 
		return props.onViewDetails && props.onViewDetails(vehicleDamage);
	};

	const loadViewerJs = () => {
		window.$('.image-viewer').each(function() {
			new Viewer(this, {
				title: false,
				toolbar: false,
				movable: false,
			}); 
		});
	}

	const columns = [
		{
			Header: 'Tracker No.',
			accessor: 'serviceTrackerNumber',
			width: 90,
			Cell: row => row.value || '-'
		},
		{
			Header: 'Vehicle',
			accessor: 'fleetNumber',
			width: 80,
		},
		{
			Header: 'Created On',
			accessor: 'createdOnLocal',
			width: 100,
			Cell: row => moment(row.value).format('DD/MM/YYYY')
		},
		{
			Header: 'Reported By',
			accessor: 'WorkerCreatedBy.shortName',
		},
		{
			Header: 'Company',
			accessor: 'WorkerCreatedBy.currentCompanyName',
			Cell: row => row.value || '-',
		},
		{
			Header: 'Closed On',
			accessor: 'closedOnLocal',
			width: 100,
			Cell: row => row.value ? moment(row.value).format('DD/MM/YYYY') : '-'
		},
		{
			Header: 'Closed By',
			accessor: 'WorkerClosedBy.shortName',
			Cell: row => row.value || '-',
		},
		{
			Header: 'Repaired On',
			accessor: 'repairedOn',
			width: 100,
			Cell: row => row.value ? moment(row.value).format('DD/MM/YYYY') : '-'
		},
		{
			Header: 'Photo',
			id: 'photo',
			sortable: false,
			width: 130,
			accessor: d => d,
			Cell: row => {
				const { id, fleetNumber } = row.value;
				
				return (
					<div onClick={(e) => e.stopPropagation()}>
						<VehicleDamagePicture 
						damageId={id}
						fleetNumber={fleetNumber}
						onLoadImage={loadViewerJs}
					/>
					</div>
				);
			}
		}
	];

	return (
		<div className="fault-reports-list">
			<ReactTable
				getTrProps={(state, rowInfo) => ({ 
					onClick: () => handleSeeFaultReportDetails(rowInfo.original),
					className: rowInfo.original.repairedOn ? 'green' : '' })}
				data={props.data}
				columns={columns}
				minRows={0}
				manual
				loading={props.isLoading}
				defaultPageSize={props.defaultPageSize}
				page={props.page}
				pageSize={props.pageSize}
				pages={props.totalPages}
				sorted={props.sorted}
				onFetchData={props.onFetchData}
				onPageChange={props.onPageChange}
				onPageSizeChange={props.onPageSizeChange}
				onSortedChange={props.onSortedChange}
				collapseOnDataChange={false}
			/>
		</div>
	);
};

VehicleDamagesList.propTypes = propTypes;

export default VehicleDamagesList;