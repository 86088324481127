import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Button, Modal, FormControl } from 'react-bootstrap';
import moment from 'moment';
import Icon from 'react-fontawesome';

import WorkerApi from '../../../../../app/api/WorkerApi';
import WorkerStatusSchema from '../../../../../app/schemas/worker/WorkerStatusSchema.json';
import WorkerStatusTimelineHelper from '../../../../../app/helpers/WorkerStatusTimelineHelper';

import { Select } from '../../../../common/inputs';
import { CompaniesList, LeftReasonsList, RolesList } from '../../../../common/lists';
import { Form, TimelineContainer } from '../../../../common/uiElements';

const propTypes = {
	id: PropTypes.string,
	data: PropTypes.array,
	onChange: PropTypes.func,
	editMode: PropTypes.bool,
	readOnly: PropTypes.bool,
	
	/** If true, it doesnt show the Add or Edit Status buttons */
	isLoggedUser: PropTypes.bool
};

const defaultProps = {
	id: 'WorkerStatus',
	editMode: false,
	readOnly: false,
	data: [WorkerStatusSchema]
};

const validations = {
	roleNameId: 'required',
	roleWorkingStatus: 'required',
	companyAbn: 'required',
	startedOn: 'required',
	leftOn: 'required',
	leftReasonId: 'required',
	micwayEmployeeNumber: {
		required: true,
		employeeNumber: true
	}
};

class WorkerStatus extends Component {
	constructor() {
		super();
		
		this.state = {
			addModeType: null,
			newStatusData: {},
			newRoleData: {},
			showStatusModal: false,
			isCheckingUser: false,
			isUserAlreadyInUse: false,
		};
	}

	componentWillUnmount() {
		this.unmounted = true;	
	}

	handleChangeInput = (e) => {		
		var { newStatusData } = this.state;

		if (this.props.editMode) {
			return this.setState({
				newStatusData: {
					...newStatusData,
					[e.target.id]: e.target.value
				}
			});
		}
		// When is adding new worker
		else if (this.props.onChange) {
			var { id, data } = this.props;
			let newData = { ...data[0] };
			newData[e.target.id] = e.target.value;

			return this.props.onChange([newData], id);
		}
	}

	handleChangeRole = (e) => {
		const { newRoleData } = this.state;

		if (this.props.editMode) {
			return this.setState({
				newRoleData: {
					...newRoleData,
					[e.target.id]: e.target.value
				}
			});
		}
		else if (this.props.onChange) {
			var { id, data } = this.props;
			let newData = { ...data[0] };
			let newWorkerStatusRole = newData['WorkerStatusRoles'] ? newData['WorkerStatusRoles'][0] : {};
			newData['WorkerStatusRoles'] = [{
				...newWorkerStatusRole,
				[e.target.id]: e.target.value
			}];

			return this.props.onChange([newData], id);
		}
	}

	handleChangeAddModeType = (addModeType) => {
		return this.setState({ 
			addModeType, 
			newStatusData: {},
			showStatusModal: true
		});
	}

	handleCloseModal = () => {
		this.setState({ 
			showStatusModal: false, 
			isUserAlreadyInUse: false,
			isCheckingUser: false,
			newStatusData: {},
			addModeType: false
		});
	}

	checkUserAlreadyInUse = (e) => {
		const element = e.target;

		// Using a timeout to fix when the user clicks to close the Modal
		setTimeout(() => {
			if (this.props.editMode && !this.state.showStatusModal) return;

			let userToCheck = element.value;
			if (!userToCheck || !window.$(element).valid()) return;
			
			window.$('.wizard-buttons button, #modalSaveButton').attr('disabled', true);
			this.setState({
				isUserAlreadyInUse: false,
				isCheckingUser: true
			}, () => {
				userToCheck += '@micway.com.au';
				WorkerApi.checkUserExists(userToCheck) 
					.then(userExists => {
						if (this.props.editMode && !this.state.showStatusModal) return;
	
						this.setState({ isCheckingUser: false });
	
						if (userExists) {
							this.setState({ isUserAlreadyInUse: true });
							window.$(element).focus().addClass('error');
						}
						else {
							window.$(element).removeClass('error').addClass('valid');
							window.$('.wizard-buttons button, #modalSaveButton').removeAttr('disabled');
						}
					})
					.catch(error => {
						this.setState({ isCheckingUser: false });
						console.error(error);
					});
			});
		}, 100);
	}

	isNewDateValid = () => {
		let { data } = this.props;
		if (data.length === 0)
			return true;

		let lastStatusIndex = data.length - 1;
		let lastRoleIndex = data[lastStatusIndex].WorkerStatusRoles.length - 1;
		
		// Get last status
		let lastStatusData = data[lastStatusIndex];

		// Get last role
		let lastRoleData = data[lastStatusIndex].WorkerStatusRoles[lastRoleIndex];

		// Get the date to compare
		let dateToCompare = lastStatusData.leftOn || lastRoleData.leftOn || lastRoleData.startedOn; 

		let { newStatusData } = this.state;
		let newDate = newStatusData.leftOn || newStatusData.startedOn;

		// Check if the new date is after the last change date
		let isValid = new Date(newDate) > new Date(dateToCompare);

		if (!isValid) {
			window.$('#startedOn, #leftOn').focus()
				.addClass('error')
				.after('<label class="error">The date must be greater than ' + moment(dateToCompare).format('DD/MM/YYYY') + '</label>');
		} else {
			window.$('#startedOn, #leftOn')
				.removeClass('error')
				.next('label').remove();
		}

		return isValid;
	}

	onSaveStatus = () => {
		if (window.$(this.divForm).find('form').valid() && this.isNewDateValid()) {	
			var { addModeType, newStatusData, newRoleData } = this.state;
			var { id, data, onChange } = this.props;

			let companyName = window.$('#companyAbn').find(':selected').text();
			let roleName = window.$('#roleNameId').find(':selected').text();
			let leftReasonName = window.$('#leftReasonId').find(':selected').text();
			
			if (addModeType === 'newCompany') {
				newRoleData.startedOn = newStatusData.startedOn;
				newRoleData.roleName = roleName;
				data.push({
					...newStatusData,
					WorkerStatusRoles: [newRoleData],
					companyName
				});
			}
			else {
				let lastStatusIndex = data.length - 1;
				let lastRoleIndex = data[lastStatusIndex].WorkerStatusRoles.length - 1;
				if (addModeType === 'newRole') {
					data[lastStatusIndex].WorkerStatusRoles[lastRoleIndex].leftOn = newStatusData.startedOn;
					data[lastStatusIndex].WorkerStatusRoles[lastRoleIndex].leftComments = newStatusData.leftComments;
					data[lastStatusIndex].WorkerStatusRoles.push({
						startedOn: newStatusData.startedOn,
						...newRoleData,
						roleName
					});
					// Change last company data
					data[lastStatusIndex] = {
						...data[lastStatusIndex]
					};
				}
				else if (addModeType === 'leftCompany') {
					data[lastStatusIndex].WorkerStatusRoles[lastRoleIndex].leftOn = newStatusData.startedOn;
					data[lastStatusIndex].WorkerStatusRoles[lastRoleIndex].leftComments = newStatusData.leftComments;
					// Change last company data
					data[lastStatusIndex] = {
						...data[lastStatusIndex],
						...newStatusData,
						leftReasonName
					};
				}
			}

			if (onChange)
				onChange(data, id);
			
			return this.setState({
				addModeType: null,
				newStatusData: {},
				newRoleData: {}
			});
		}
	}	

	renderTimelineComponent = (data) => {
		let workerStatusTimelineItems = WorkerStatusTimelineHelper.renderTimelineItems(data, this.props.hideWorkerLeftReason || this.props.isLoggedUser);
		return <TimelineContainer items={workerStatusTimelineItems} />;
	}
	
	workerHasActiveCompany = () => {
		let { data } = this.props;
		let lastStatus = data[data.length - 1];
		
		// Check if it has at least one status and the last company Left On field is null (that means active)
		return lastStatus && !lastStatus.leftOn;
	}

	renderStatusForm = () => {
		const s = this.state;
		const p = this.props;

		return (
			<div ref={ref => this.divForm = ref}>
				<Form validations={validations}>
					<Row>
						{
							s.addModeType !== 'leftCompany' && [
								(!s.addModeType || s.addModeType === 'newCompany') && [
									<Col sm={6} key={0}>
										<FormGroup>
											<label>Company *</label>
											<CompaniesList id="companyAbn" onChange={this.handleChangeInput}/>
										</FormGroup>
									</Col>,
									<Col sm={6} key={1}>
										<FormGroup>
											<label>Micway Employee No.</label>
											<FormControl
												id="micwayEmployeeNumber"
												maxLength="6"
												placeholder="e.g.: L00005 (use letter T for subbies)"
												value={p.data.email} 
												onChange={this.handleChangeInput} 
												onBlur={this.checkUserAlreadyInUse}
											/>
											{ s.isCheckingUser && <span>Checking...</span> }
											{ s.isUserAlreadyInUse && (
												<label className="error">
														This username seems to be already in use. Please contact the TMS Administrator.
												</label>
											) }
										</FormGroup>
									</Col>
								],
								<Col sm={6} key={2}>
									<FormGroup>
										<label>Role *</label>
										<RolesList id="roleNameId"  onChange={this.handleChangeRole}/>
									</FormGroup>
								</Col>,
								<Col sm={6} key={3}>
									<FormGroup>
										<label>Role Status *</label>
										<Select 
											id="roleWorkingStatus"
											data={[
												{ id: 'Permanent' },
												{ id: 'Casual' },
												{ id: 'Part-time' }
											]}
											onChange={this.handleChangeRole} 
										/>
									</FormGroup>
								</Col>,
								<Col sm={6} key={4}>
									<FormGroup>
										<label>Started On *</label>
										<FormControl 
											id="startedOn" 
											type="date" 
											onChange={this.handleChangeInput} 
										/>
									</FormGroup>
								</Col>
							]
						}
						{
							p.editMode && (
								s.addModeType !== 'newCompany' && [
									s.addModeType === 'leftCompany' && [
										<Col sm={6} key={5}>
											<FormGroup>
												<label>Left Reason *</label>
												<LeftReasonsList id="leftReasonId" onChange={this.handleChangeInput}/>
											</FormGroup>
										</Col>,
										<Col sm={6} key={6}>
											<FormGroup>
												<label>Left On *</label>
												<FormControl 
													id="leftOn" 
													type="date" 
													maxLength="50" 
													onChange={this.handleChangeInput} 
												/>
											</FormGroup>
										</Col>
									],
									<Col sm={6} key={7}>
										<FormGroup>
											<label>Comments</label>
											<textarea id="leftComments" rows="3" className="form-control" maxLength="255" onChange={this.handleChangeInput}>
											</textarea>
										</FormGroup>
									</Col>
								]
							)
						}
					</Row>
				</Form>
			</div>
		);
	}

	render() {
		const s = this.state;
		const p = this.props;

		if (p.readOnly || p.isLoggedUser) {
			if (p.data.length > 0) 
				return this.renderTimelineComponent(p.data);
				
			return <i>No Status has been registered</i>;
		}
		
		return (
			<div>
				{
					p.editMode && (
						this.workerHasActiveCompany() ? (
							<Row>
								<Col xs={6}>
									<FormGroup>	
										<Button block bsStyle="primary" onClick={() => { this.handleChangeAddModeType('newRole'); }}>
											<Icon name="trophy" /> Changed Role
										</Button>
									</FormGroup>
								</Col>
								<Col xs={6}>
									<FormGroup>	
										<Button block bsStyle="danger" onClick={() => { this.handleChangeAddModeType('leftCompany'); }}>
											<Icon name="stop" /> Left Company
										</Button>
									</FormGroup>
								</Col>
							</Row>
						) : (
							<Row>
								<Col xs={12}>
									<FormGroup>	
										<Button block bsStyle="success" onClick={() => { this.handleChangeAddModeType('newCompany'); }}>
											<Icon name="play" /> Started at a new Company
										</Button>
									</FormGroup>
								</Col>
							</Row>
						)
					)
				}
				{
					(!p.editMode || s.addModeType) && (
						!s.addModeType ? (
							this.renderStatusForm()
						) : (
							<Modal show={s.showStatusModal} onHide={this.handleCloseModal}>
								<Modal.Header closeButton>
									<Modal.Title>Update Worker Status</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									{ this.renderStatusForm() }
								</Modal.Body>
								<Modal.Footer>
									<Button id="modalSaveButton" className="pull-left" bsStyle="success" onClick={this.onSaveStatus}>
										<Icon name="plus" /> Add Status
									</Button>
									<Button onClick={this.handleCloseModal} bsStyle="default">Close</Button>
								</Modal.Footer>
							</Modal>
						)
					)
				}
				{
					p.editMode && p.data.length > 0 && (
						<Row>
							<Col xs={12}>
								<hr />
								{ this.renderTimelineComponent(p.data) }
							</Col>
						</Row>
					)
				}
			</div>
		);
	}
}

WorkerStatus.propTypes = propTypes;
WorkerStatus.defaultProps = defaultProps;

export default WorkerStatus;