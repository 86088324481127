import React from 'react';
import PropTypes from 'prop-types';

import ListsApi from '../../../app/api/ListsApi';
import ListContainer from './_ListContainer';

const propTypes = {
	id: PropTypes.string,
	cssClass: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	showAllOption: PropTypes.bool,
	onlyActive: PropTypes.bool
};

const defaultProps = {
	id: 'companyAbn',
	onlyActive: true
};

const CompaniesList = props => (
	<ListContainer 
		{...props}
		promise={() => ListsApi.getCompanies()}
	/>
);

CompaniesList.propTypes = propTypes;
CompaniesList.defaultProps = defaultProps;

export { CompaniesList };