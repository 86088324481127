/* eslint-disable eqeqeq */

import runTemplate from './endpoints/runTemplateEndpoints';
import httpRequests from './httpRequests';
import { IRunTemplateListItem, RunTemplateMainFilter, IRunTemplateDetails, RunTemplatePOST_PUT } from '../models/Run/RunTemplate';

export default {
	getList(filter: RunTemplateMainFilter): Promise<IRunTemplateListItem> {
		const endpoint = runTemplate.root;
		const filterString = JSON.stringify({ ...filter, page: 0, pageSize: 10000 });
		return httpRequests.get(endpoint, { filterString }).then(result => result.records);
	},

	getDetails(runTemplateId: number): Promise<IRunTemplateDetails> {
		const endpoint = runTemplate.byId(runTemplateId);
		return httpRequests.get(endpoint);
	},

	createRunTemplate(data: RunTemplatePOST_PUT) {
		const endpoint = runTemplate.root;
		return httpRequests.post(endpoint, data);
	},

	updateRunTemplate(runTemplateId: number, data: RunTemplatePOST_PUT) {
		const endpoint = runTemplate.byId(runTemplateId);
		return httpRequests.put(endpoint, data);
	},

	deleteRunTemplate(runTemplateId: number) {
		const endpoint = runTemplate.byId(runTemplateId);
		return httpRequests.delete(endpoint);
	},
};

