import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import jstz from 'jstz';

import DashboardApi from '../../../app/api/DashboardApi';
import { ErrorBox, Loader } from '../uiElements';
import BarChart from '../charts/BarChart';

const propTypes = {
	mostRecentWeekDayNonUtcRef: PropTypes.number,
	stateFilter: PropTypes.array,
	totalPreviousWeek: PropTypes.number,
	totalPerWeek: PropTypes.bool
};

const defaultProps = {
	totalPerWeek: false
};

class WeeklyOdometerDrivenSummary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			isLoading: false,
			error: null,
		};	
	}
	
	UNSAFE_componentWillMount() {
		this.getWeeklyOdometerDrivenSummary();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	getWeeklyOdometerDrivenSummary = () => {
		const { mostRecentWeekDayNonUtcRef, stateFilter, totalPreviousWeek } = this.props;
		const timezoneIanaFormat = jstz.determine().name();
		
		this.setState({ isLoading: true, data: null, error: null });
		DashboardApi.weeklyOdomDrivenSummary(mostRecentWeekDayNonUtcRef, totalPreviousWeek, stateFilter, timezoneIanaFormat)
			.then(data => {
				if (this.unmounted) return;
			
				return this.renderChartData(data);
			})
			.catch(error => {
				this.setState({ error, isLoading: false });
				console.error(error);
			});
	}

	renderChartData = data => {
		var { totalPerWeek = false } = this.props;

		let datasets = [];
		let labels = totalPerWeek ? [] : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
		
		if (totalPerWeek) {
			var colors = [];

			var valuesPerWeek = [];
			data.forEach((obj, index) => {
				valuesPerWeek.push(parseInt(obj.weekOdoMeterTotal / 1000, 10));
				labels.push(index === 0 ? 'This Week' : moment(obj.weekStartNonUtc).format('DD/MM'));
				colors.push(index === 0 ? 'rgba(60, 141, 188, .7)' : 'rgba(51, 169, 117, .5)');
			});

			datasets.push({
				backgroundColor: colors,
				borderWidth: 1,
				data: valuesPerWeek,
				label: 'Total per Week'
			});

		} else {
			const colors = [
				'rgba(60, 141, 188, .7)',
				'rgba(51, 169, 117, .7)',
				'rgba(51, 169, 117, .5)',
				'rgba(51, 169, 117, .3)'
			];

			data.forEach((obj, index) => {
				datasets.push({
					backgroundColor: colors[index],
					borderWidth: 1,
					data: totalPerWeek ? [parseInt(obj.weekOdoMeterTotal / 1000, 10)] : obj.weekOdoMetersDays.map(value => parseInt(value / 1000, 10)),
					label: index === 0 ? 'This Week' : moment(obj.weekStartNonUtc).format('DD/MM')
				});					
			});
		}

		this.setState({ data: { labels, datasets }, isLoading: false });
	}

	render() {
		const s = this.state;

		if (s.isLoading)
			return <Loader text="Loading data..." />;

		if (s.error) 
			return <ErrorBox error="It wasn't possible to retrieve the data" retryFunc={this.getWeeklyOdometerDrivenSummary} />;

		return !s.data ? <p>No data has been found</p> : <BarChart data={s.data} postfix=" Km" displayLegend={!this.props.totalPerWeek}/>;
	}
}

WeeklyOdometerDrivenSummary.propTypes = propTypes;
WeeklyOdometerDrivenSummary.defaultProps = defaultProps;

export default WeeklyOdometerDrivenSummary;