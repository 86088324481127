import React, { Component } from 'react';
import moment from 'moment';
import Icon from 'react-fontawesome';

import FaultReportApi from '../../../../../app/api/FaultReportApi';
import FaultReportListFilter from './FaultReportListFilter';
import FaultReportList from './FaultReportList';
import FaultReportDetailsContainer from '../subComponents/FaultReportDetailsContainer';

import { Modal, Button, FormGroup } from 'react-bootstrap';
import { MainContent } from '../../../../common/layout';
import { ContentBox, ErrorBox } from '../../../../common/uiElements';
import componentRequestHandler from '../../../../../app/api/helpers/componentRequestHandler';

const faultListExportColumns = [
	{ text: 'ID', value: 'faultReportNumber' },
	{ text: 'Tracker No.', value: 'serviceTrackerNumber' },
	{ text: 'Vehicle', value: 'reportedVehicleId' },
	{ text: 'Type', value: 'dtoFaultReportTypeName' },
	{ text: 'Opened On', value: 'reportedOnLocal' },
	{ text: 'Last Update On', value: 'dtoLastUpdateOnLocal' },
	{ text: 'Status', value: 'dtoStatusName' },
	{ text: 'Info', value: 'dtoStatusInfo' },
	{ text: 'Quick Fix', value: 'reportedTypeOption' }
];

class FaultReportListContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedFaultReport: false,
			faultReportsList: [],
			totalPages: 0,
			totalRecords: 0,
			page: 0,
			pageSize: 10,
			sorted: [],
			filter: {
				filterBy: 'all',
				startTimeLocal: moment().add(-7, 'days').format('YYYY-MM-DD HH:mm'),
				endTimeLocal: moment().format('YYYY-MM-DD HH:mm'),
				vehicle: '',
				type: '',
				serviceTrackerNumber: '',
				faultReportNumber: '',
				isOpen: true,
				isFixed: false,
				isNoRepairsRequired: false,
				isMonitored: false,
				isDeferred: false
			},
			isLoading: false,
			isExporting: false,
			error: null,
			errorExporting: null
		};

		this.handleSearch = () => {
			this.setState({
				page: 0,
				sorted: []
			}, this.fetchData);
		};

		this.handleChangeFilter = (filter) => {
			this.setState({
				filter: {
					...this.state.filter,
					...filter
				}
			});
		};

		this.handleChangePage = page => {
			this.setState({ page }, this.fetchData);
		};

		this.handleChangePageSize = pageSize => {
			this.setState({ pageSize }, this.fetchData);
		};

		this.handleChangeSorted = sorted => {
			this.setState({ sorted }, this.fetchData);
		};

		this.onViewFaultReportDetails = (faultReportId) => {
			this.setState({
				selectedFaultReportId: faultReportId
			});
		};

		this.handleCloseDetailsModal = () => {
			this.setState({ selectedFaultReportId: null });
		};
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	UNSAFE_componentWillMount() {
		this.fetchData();   
	}    

	handleExport = () => {
		const { filter } = this.state;
		if (filter.filterBy === 'all') {
			delete filter.startTimeLocal;
			delete filter.endTimeLocal;
		}
		const promise = () => FaultReportApi.getFaultReportsFile({ ...filter, faultListExportColumns });
		componentRequestHandler(this, promise, undefined, {
			loadingAttrName: 'isExporting',
			errorAttrName: 'errorExporting',
		})
	};

	fetchData = () => {
		this.setState({ isLoading: true });
		const { page, pageSize, sorted } = this.state;
		const filter = { ...this.state.filter };
		if (filter.filterBy === 'all') {
			delete filter.startTimeLocal;
			delete filter.endTimeLocal;
		}

		FaultReportApi.getFaultReportsList({ ...filter, page, pageSize, sorted }, this.props.isLoggedUser && this.props.loggedUserId)
			.then(data => {
				if (this.unmounted) return;
			
				const { totalRecords, totalPages, records } = data;
				return this.setState({ 
					isLoading: false, 
					faultReportsList: records,
					totalPages,
					totalRecords
				});
			})
			.catch(error => {
				return this.setState({ isLoading: false, error });
			});
	}
	
	render() {
		const s = this.state;
		
		return (
			<MainContent title="Fault Reports">
				<div>
					<ContentBox color="primary" title="Filters">
						<FaultReportListFilter 
							{...s.filter} 
							onChange={this.handleChangeFilter} 
							onPressEnter={this.handleSearch}
						/>
						<hr />
						<Button 
							bsStyle="primary" 
							disabled={s.isLoading}
							block onClick={this.handleSearch}
						>
									Search <Icon name="search" />
						</Button>
					</ContentBox>
					<FormGroup>
						<Button
							bsStyle="success"
							onClick={this.handleExport}
							disabled={s.isExporting}
						>
							{
								s.isExporting ? (
									<><Icon name="spinner" spin /> Generating</>
								) : (
									<>Export XLS</>
								)
							}
						</Button>
						{ s.errorExporting && <ErrorBox	error={s.errorExporting} retryFunc={this.handleExport} /> }
					</FormGroup>
					<FaultReportList 
						data={this.state.faultReportsList} 
						page={s.page}
						pageSize={s.pageSize}
						totalPages={s.totalPages}
						sorted={s.sorted}
						isLoading={s.isLoading}
						onPageChange={this.handleChangePage}
						onPageSizeChange={this.handleChangePageSize}
						onSortedChange={this.handleChangeSorted}
						onViewFaultReportDetails={this.onViewFaultReportDetails}
					/>
					<Modal bsSize="lg" show={s.selectedFaultReportId ? true : false} onHide={this.handleCloseDetailsModal}>
						<Modal.Header closeButton>
							<Modal.Title>Fault Report Details</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{
								s.selectedFaultReportId && (
									<FaultReportDetailsContainer 
										faultReportId={s.selectedFaultReportId} 
										onSaveNewHistory={this.handleSearch} 
										isEditable
									/>
								)
							}
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={this.handleCloseDetailsModal} bsStyle="default">Close</Button>
						</Modal.Footer>
					</Modal>
				</div>
			</MainContent>
		);
	}
}
export default FaultReportListContainer;