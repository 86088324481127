import qs from 'qs';
import geofenceEndpoints from './endpoints/geofenceEndpoints';
import httpRequests from './httpRequests';
//import { curfewWindows, accessWindows } from './fakeData';

export default {	
	/**
	 * Get the Geofences based on a map position
	 * @param boundaries 
	 */
	getGeofences(boundaries: object) {
		const endpoint = geofenceEndpoints.root;
		const params = { polygon: boundaries };
		const paramsSerializer = (params: object) => qs.stringify(params, { arrayFormat: 'indices' });

		return httpRequests.getWithParams(endpoint, params, paramsSerializer);
	},

	/**
	 * Get the places based on a Geofence
	 * @param geofenceId 
	 */
	getGeofencePlaces(geofenceId: number) {
		const endpoint = geofenceEndpoints.geofencePlaces(geofenceId);
		return httpRequests.get(endpoint);
	},

	/**
	 * Get the geofences with places that contain the store number
	 */
	getGeofencesByStoreNumber(storeNumber: string) {
		const endpoint = geofenceEndpoints.geofencesByStoreNumber(storeNumber);
		return httpRequests.get(endpoint);
	},

	/**
	 * Get all the unlinked places
	 */
	getUnlinkedPlaces() {
		const endpoint = geofenceEndpoints.unlinkedPlaces;
		return httpRequests.get(endpoint);
	},
	
	/**
	 * Get the place data
	 * @param placeId 
	 */
	getPlace(placeId: number) {
		const endpoint = geofenceEndpoints.placeById(placeId);
		return httpRequests.get(endpoint);
	},

	/**
     * Get the MudMap file from the Delivery Point
     * @param accessPointId 
     */
	getMudMapFile(accessPointId: number) {
		const endpoint = geofenceEndpoints.mudMapFile(accessPointId);
		return httpRequests.downloadFile(endpoint);
	},

	/**
	 * Save a new Geofence
	 * @param data
	 */
	saveNewGeofence(data: object) {
		const endpoint = geofenceEndpoints.root;
		return httpRequests.postWithFiles(endpoint, data);
	},

	/**
	 * Update the Geofence data
	 * @param geofenceId 
	 * @param data
	 */
	updateGeofence(geofenceId: number, data: object) {
		const endpoint = geofenceEndpoints.byId(geofenceId);
		return httpRequests.putWithFiles(endpoint, data);
	},

	/**
	 * Update the Place data
	 * @param placeId
	 * @param data
	 */
	updatePlace(placeId: number, data: object) {
		const endpoint = geofenceEndpoints.placeById(placeId);
		return httpRequests.put(endpoint, data);
	},

	/**
	 * Set a Geofence to inactive
	 * @param geofenceId 
	 */
	deleteGeofence(geofenceId: number) {
		const endpoint = geofenceEndpoints.byId(geofenceId);
		return httpRequests.delete(endpoint);
	},

		/**
	 * Gets the windows of an Access Point
	 * @param placeAccessPointId Access Point ID
	 * @param placeCategoryCommodityLinkId The ID of the Link with Place Category and Commodity Type
	 */
	getAccessWindows(placeAccessPointId: number, placeCategoryCommodityLinkId: number) {
		let endpoint = geofenceEndpoints.accessWindows(placeAccessPointId);
		if (placeCategoryCommodityLinkId)
			endpoint = geofenceEndpoints.accessWindowsByCommodity(placeAccessPointId, placeCategoryCommodityLinkId);

		return httpRequests.get(endpoint);
		
		//return Promise.resolve(accessWindows.filter(p => p.placeAccessPointId === placeAccessPointId));
	},

		/**
	 * Gets the curfew windows of an Access Point
	 * @param placeAccessPointId Access Point ID
	 */
	getCurfewWindows(placeAccessPointId: number) {
		const endpoint = geofenceEndpoints.curfews(placeAccessPointId);
		return httpRequests.get(endpoint);
		
		//return Promise.resolve(curfewWindows.filter(p => p.placeAccessPointId === placeAccessPointId));
	}
};