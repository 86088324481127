import MeApi from '../../app/api/MeApi';

const initialState = {
	data: null,
	isLoading: true,
	loginError: false,
	cacheError: false,
	error: null
};

/** 
 * ACTION TYPES
*/
export const FETCH_USER_DATA = 'auth/FETCH_USER_DATA';
export const SET_USER_DATA = 'auth/SET_USER_DATA';
export const SET_ERROR = 'auth/SET_ERROR';
export const SET_LOGIN_ERROR = 'auth/SET_LOGIN_ERROR';
export const SET_CACHE_ERROR = 'auth/SET_CACHE_ERROR';
export const SIGN_OUT = 'auth/SIGN_OUT';

/**
 * ACTIONS
 */
export function fetchUserData() {
	return dispatch => {
		dispatch({ type: FETCH_USER_DATA });
		MeApi.getUserDetails()
			.then(userData => {
				dispatch({ type: SET_USER_DATA, payload: userData });
			})
			.catch(error => {
				if (error.details)
					if (error.details.indexOf('AADSTS50058') !== -1)
						dispatch({ type: SET_CACHE_ERROR });
					else
						dispatch({ type: SET_ERROR, payload: error });
				else
					dispatch({ type: SET_ERROR, payload: error });
			});
	};
}

/**
 * REDUCER
 */
export default (state = initialState, { type, payload }) => {
	switch (type) {
	case FETCH_USER_DATA:
		return { ...initialState, isLoading: true };
	case SET_USER_DATA:
		return { ...state, isLoading: false, data: payload };
	case SET_ERROR:
		return { ...state, isLoading: false, error: payload };		
	case SET_LOGIN_ERROR:
		return { ...state, isLoading: false, loginError: true };	
	case SET_CACHE_ERROR:
		return { ...state, isLoading: false, cacheError: true };	
	case SIGN_OUT:
		return initialState;
	default:
		return state;
	}
};