/* eslint-disable eqeqeq */

import roster from './endpoints/rosterEndpoints';
import { IStaffRosterDetails, IStaffRosterListItem, StaffRosterCANCEL, StaffRosterPOST, StaffRosterPUT } from '../models/StaffRoster';
import httpRequests from './httpRequests';

export default {
	/**
	 * Gets a list with the staff roster based on the start time
	 */
	getRosterList(startTimeLocal: string, finishTimeLocal: string, onlyActive = true, startingDepotId?: number, workerId?: string): Promise<IStaffRosterListItem[]> {
		const endpoint = roster.root;
		const filterString = JSON.stringify({ startTimeLocal, finishTimeLocal, startingDepotId, workerId, onlyActive, pageSize: 10000, sorted: [], page: 0 });
		return httpRequests.get(endpoint, { filterString }).then(result => result.records);
	},

  getRosterDetails(rosterId: number): Promise<IStaffRosterDetails> {
		const endpoint = roster.byId(rosterId);
		return httpRequests.get(endpoint);
  },

	createRoster(data: StaffRosterPOST) {
		const endpoint = roster.root;
		return httpRequests.post(endpoint, data);
	},

	updateRoster(rosterId: number, data: StaffRosterPUT) {
		const endpoint = roster.byId(rosterId);
		return httpRequests.put(endpoint, data);
	},

	cancelRoster(rosterId: number, data: StaffRosterCANCEL) {
		const endpoint = roster.cancelRoster(rosterId);
		return httpRequests.put(endpoint, data);
	},
};