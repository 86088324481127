import React from 'react';
import ReactTable, { RowInfo } from 'react-table';
import { ISmsMessageBulkWorkerListItem } from '../../../../../app/models/Communication/SmsMessageBulkModel';
import moment from 'moment';
import './SmsMessagesDetailsList.scss';

interface IProps {
	workersList: ISmsMessageBulkWorkerListItem[];
}

const SmsMessagesDetailsList: React.FC<IProps> = props => {
	return (
		<ReactTable
			className="sms-messages-details-list"
			pageSize={999999}
			sorted={[{ id: 'workerName', desc: false }]}
			showPagination={false}
			resizable={false}
			data={props.workersList}
			minRows={0}
			getTrProps={(state: any, rowInfo?: RowInfo) => {
				if (!rowInfo)
					return {};

				const data = rowInfo.original as ISmsMessageBulkWorkerListItem;
				let colorClass = 'blue';

				if (data.delivered === false)
					colorClass = 'red';
				else if (data.delivered === true) 
						colorClass = 'green';

				return { className: colorClass };
			}}
			columns={[
				{
					Header: 'Employee',
					accessor: 'workerName'
				}, 
				{
					Header: 'Mobile Number',
					accessor: 'mobileNumber'
				}, 
				{
					Header: 'Attempts',
					accessor: 'attempts',
					width: 80
				},
				{
					Header: 'Sent At',
					accessor: 'lastSentAt',
					width: 120,
					Cell: row => row.value ? moment.utc(row.value).local().format('DD/MM/YYYY HH:mm') : '-',
				},
				{
					Header: 'Error',
					accessor: 'error',
					Cell: (row: any) => row.value || '-'
				},
				{
					Header: 'Status',
					accessor: 'delivered',
					width: 80,
					Cell: (row: any) => {
						if (row.value === true)
							return <b className="text-success">Delivered</b>;
						else if (row.value === false)
							return <b className="text-danger">Failed</b>;
						else
							return <b className="text-info">Processing</b>;
					}
				},
			]}
		/>
	)
}

export default SmsMessagesDetailsList;