import { ListRequestOptions, ListReturn } from '../models/Application';
import { IPalletLoadListItem, IPalletLoadDetails, PalletLoad_PUT, PalletLoadListFilter } from '../models/Pallet';
import palletLoadingEndpoints from './endpoints/palletTrackingEndpoints';
import httpRequests from './httpRequests';

export default {
	/**
     * Gets the List of Pallet Loads
  */
	getLoadsList(listOptions: ListRequestOptions, filters: PalletLoadListFilter): Promise<ListReturn<IPalletLoadDetails>> {
		const endpoint = palletLoadingEndpoints.search;
		return httpRequests.get(endpoint, { filterString: JSON.stringify({ ...listOptions, ...filters }) });
	},

	getLoadsListForPanel(minutes = 360): Promise<IPalletLoadListItem[]> {
		const endpoint = palletLoadingEndpoints.panel;
		return httpRequests.get(endpoint, { minutes });
	},

	getLoadDetails(loadId: string): Promise<IPalletLoadDetails> {
		const endpoint = palletLoadingEndpoints.byId(loadId);
		return httpRequests.get(endpoint);
	},

	/**
		 * Export the Worker List to a file
		 */
	getLoadsListReportFile(filterString: PalletLoadListFilter) {
		const endpoint = palletLoadingEndpoints.exportReport;

		return httpRequests.downloadFile(endpoint, { filterString: JSON.stringify(filterString) });
	},
	
	/**
	 * Get the Load Authorisation PDF file
	 * @param loadId load ID
	 * @returns Load Authorisation PDF file
	 */
	getLoadAuthorisationFile(loadId: string): Promise<Blob> {
		const endpoint = palletLoadingEndpoints.loadAuthorisation(loadId);
		return httpRequests.downloadFile(endpoint);
	},

	/**
	 * Get the Load Summary PDF file
	 * @param loadId load ID
	 * @returns Load Summary PDF file
	 */
	getLoadSummaryFile(loadId: string): Promise<Blob> {
		const endpoint = palletLoadingEndpoints.loadSummary(loadId);
		return httpRequests.downloadFile(endpoint);
	},

	createLoad(data: PalletLoad_PUT) { // TODO: Change to POST data
		const endpoint = palletLoadingEndpoints.root;
		return httpRequests.post(endpoint, data);
	},

	updateLoad(id: string, data: PalletLoad_PUT) {
		const endpoint = palletLoadingEndpoints.byId(id);
		return httpRequests.put(endpoint, data);
	},

	deleteLoad(loadId: string) {
		const endpoint = palletLoadingEndpoints.byId(loadId);
		return httpRequests.delete(endpoint);
	},
};