import moment from 'moment';

const initialState = {
	endTime: moment().utc().unix(),
	location: {
		lat: -33.7555692,
		lng: 150.90469859999996
	},
	range: 100,
	startTime: moment().add(-1, 'm').utc().unix(),
	vehicle: null
};

/**
 * ACTION TYPES
 */
export const SET_DEFAULT = 'tms-web-ui/filters/SET_DEFAULT';
export const SET_LOCATION = 'tms-web-ui/filters/SET_LOCATION';
export const SET_PERIOD = 'tms-web-ui/filters/SET_PERIOD';
export const SET_RANGE = 'tms-web-ui/filters/SET_RANGE';
export const SET_VEHICLE = 'tms-web-ui/filters/SET_VEHICLE';

/**
 * ACTIONS
 */
export function setDefaultFilter() {
	return {
		type: SET_DEFAULT
	};
}

export function setLocationFilter(lat, lng) {
	return {
		type: SET_LOCATION,
		payload: { lat, lng }
	};
}

export function setPeriodFilter(startTime, endTime) {
	return {
		type: SET_PERIOD,
		payload: { startTime, endTime }
	};
}

export function setRangeFilter(range) {
	return {
		type: SET_RANGE,
		payload: range
	};
}

export function setVehicleFilter(vehicle) {
	return {
		type: SET_VEHICLE,
		payload: vehicle
	};
}

/**
 * REDUCER
 */
export default (state = initialState, { type, payload }) => {
	switch (type) {
	case SET_LOCATION: 
		return {
			...state,
			location: {
				...payload
			}
		};
	case SET_PERIOD: 
		return {
			...state,
			startTime: moment(payload.startTime).utc().unix() || moment().utc().unix(),
			endTime: moment(payload.endTime).utc().unix() || moment().utc().unix()
		};
	case SET_RANGE: 
		return { ...state, range: payload };
	case SET_VEHICLE: 
		return { ...state, vehicle: payload };
	case SET_DEFAULT: 
		return initialState;
	default:
		return state;
	}
};