/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import sortBy from 'lodash/sortBy';
import cloneObject from 'lodash/cloneDeep';
import { v4 as uuid } from 'uuid';
import AccessWindows from './AccessWindows';
import ListsApi from '../../../../../../app/api/ListsApi';
import componentRequestHandler from '../../../../../../app/api/helpers/componentRequestHandler';
import { Tab, Row, Col, Nav, NavItem } from 'react-bootstrap';
import { Callout, Loader, ErrorBox, ContentBox } from '../../../../../common/uiElements';
import confirm from '../../../../../../app/helpers/confirm';
import AccessWindowsReplicateModal from './AccessWindowsReplicateModal';

export default class AccessWindowsContainer extends Component {
	static propTypes = {
		placeCategoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		accessWindows: PropTypes.array
	}

	state = {
		showReplicationModal: false,

		/**Access Windows */
		accessWindows: [],
		isLoadingAccessWindowsList: false,
		errorAccessWindowsList: null,

		/** Commodities */
		commodityTypesList: [],
		selectedPlaceCategoryCommodityLinkId: null,
		selectedCommodityLinkIdToReplicate: '',
		isLoadingCommoditiesList: false,
		errorCommoditiesList: null
	}

	componentDidMount() {
		this.fetchCommoditiesList();
		this.copyWindowsToEditedList();
	}	

	componentDidUpdate(prevProps) {
		if (prevProps.placeCategoryId !== this.props.placeCategoryId)
			this.fetchCommoditiesList();
	}

	fetchCommoditiesList = () => {
		const promise = () => ListsApi.getCommodityTypesLinksList(this.props.placeCategoryId);
		componentRequestHandler(this, promise, 'commodityTypesList', { 
			loadingAttrName: 'isLoadingCommoditiesList', 
			errorAttrName: 'errorCommoditiesList'
		});
	}

	/** Used to help with manipulation on the client side */
	setTemporaryIds = windowsArray => {
		// Add temporary IDs for later comparison
		return windowsArray.map(p => {
			if (!p.pTempId)
				p.tempId = uuid();

			return p;
		});
	}

	getWindowsSelectedCommodity = () => {
		const { accessWindows, selectedPlaceCategoryCommodityLinkId } = this.state;
		return accessWindows.filter(p => p.placeCategoryCommodityLinkId === selectedPlaceCategoryCommodityLinkId);
	}
	
	copyWindowsToEditedList = () => {
		if (!this.props.accessWindows)
			return;

		const accessWindows = this.setTemporaryIds(cloneObject(this.props.accessWindows));
		this.setState({ accessWindows });
	}

	replicateAccessWindows = () => {
		const { selectedCommodityLinkIdToReplicate, accessWindows } = this.state;

		let windowsReplicationSource = cloneObject(this.getWindowsSelectedCommodity());
		windowsReplicationSource.forEach(window => {
			window.id = null;
			window.placeCategoryCommodityLinkId = parseInt(selectedCommodityLinkIdToReplicate);
		});
		windowsReplicationSource = this.setTemporaryIds(windowsReplicationSource);

		// Set to deleted windows that already exist for the selected commodity
		accessWindows.filter(p => p.placeCategoryCommodityLinkId === parseInt(selectedCommodityLinkIdToReplicate))
			.forEach(window => window.isDeleted = true);

		this.setState({
			selectedPlaceCategoryCommodityLinkId: selectedCommodityLinkIdToReplicate,
			showReplicationModal: false,
			selectedCommodityLinkIdToReplicate: '',
			accessWindows: [
				...accessWindows,
				...windowsReplicationSource
			]
		});
		toastr.success('Replication successfully completed!', 'Success');
	}

	handleSelectCommodity = selectedPlaceCategoryCommodityLinkId => {
		this.setState({ selectedPlaceCategoryCommodityLinkId });
	}

	handleChangeAccessWindows = updatedAccessWindowsList => {
		const { accessWindows, selectedPlaceCategoryCommodityLinkId } = this.state;
		updatedAccessWindowsList = this.setTemporaryIds(updatedAccessWindowsList);
		updatedAccessWindowsList.forEach(window => {
			window.placeCategoryCommodityLinkId = selectedPlaceCategoryCommodityLinkId;
		});

		this.setState({
			accessWindows: [
				...accessWindows.filter(p => p.placeCategoryCommodityLinkId !== selectedPlaceCategoryCommodityLinkId),
				...updatedAccessWindowsList
			]
		});
	}

	handleClickReplicateWindows = () => {
		confirm(
			'The windows of the selected commodity will be overridden. Do you really wish to continue?',
			this.replicateAccessWindows
		);
	}

	handleCloseWindowReplicationModal = () => {
		this.setState({
			showReplicationModal: false,
			selectedCommodityLinkIdToReplicate: ''
		});
	}

	renderCommoditiesList = () => {
		const { 
			isLoadingCommoditiesList,
			commodityTypesList,
			errorCommoditiesList
		} = this.state;

		if (errorCommoditiesList)
			return <ErrorBox error={errorCommoditiesList} retryFunc={this.fetchCommoditiesList} />;
		
		return (
			<ContentBox bordered noPadding title="Commodities">
				{
					isLoadingCommoditiesList ? (
						<div style={{ padding: '10px' }}>
							<Loader />
						</div>
					) : (
						commodityTypesList.length ? (
							<Nav bsStyle="pills" stacked>
								{
									commodityTypesList.map(({ id, name }) => (
										<NavItem key={id} eventKey={id}>
											{ name }
										</NavItem>		
									))
								}
							</Nav>
						) : (
							<div style={{ padding: 10 }}>
								<i>No commodity has been registered for this place category</i>
							</div>
						)
					)
				}
			</ContentBox>
		);
	}

	renderAccessWindows = isReadOnly => {
		const { selectedPlaceCategoryCommodityLinkId } = this.state;

		if (!selectedPlaceCategoryCommodityLinkId)
			return <Callout color="info">Select a Commodity</Callout>;

		const selectedAccessWindowsList = sortBy(this.getWindowsSelectedCommodity(), ['startTime']);
		
		return (
			<AccessWindows 
				showReplicationButton
				isReadOnly={isReadOnly}
				accessWindowsList={selectedAccessWindowsList}
				onChange={this.handleChangeAccessWindows}
				onClickReplicateButton={() => this.setState({ showReplicationModal: true })}
			/>
		);
	}

	render() {
		const { state, props } = this;

		return (
			<Tab.Container 
				activeKey={state.selectedPlaceCategoryCommodityLinkId}
				id="access_point_windows_tab" 
				onSelect={this.handleSelectCommodity}
			>
				<React.Fragment>
					<Row>
						<Col lg={3}>
							{ this.renderCommoditiesList() }				
						</Col>
						<Col lg={9}>
							{ this.renderAccessWindows() }
						</Col>
					</Row>
					<AccessWindowsReplicateModal 
						show={state.showReplicationModal}
						selectedCommodityLinkId={state.selectedCommodityLinkIdToReplicate}
						placeCategoryId={props.placeCategoryId}
						onClickReplicate={this.handleClickReplicateWindows}
						onChangeCommodityType={e => this.setState({ selectedCommodityLinkIdToReplicate: parseInt(e.target.value) })}
						onClose={this.handleCloseWindowReplicationModal}
					>
						{ this.renderAccessWindows(true) }
					</AccessWindowsReplicateModal>
				</React.Fragment>
			</Tab.Container>
		);
	}
}