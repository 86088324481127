/* eslint-disable eqeqeq */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import toastr from 'toastr';
import Icon from 'react-fontawesome';
import { Button, Modal, FormGroup } from 'react-bootstrap';

import confirm from '../../../../app/helpers/confirm';
import { MainContent } from '../../../common/layout';
import { ErrorBox, Loader, ContentBox, ButtonExport, Callout } from '../../../common/uiElements';

import LeaveRequestListFilter from './LeaveRequestListFilter';
import LeaveRequestList from './LeaveRequestList';
import LeaveRequestDetails from './LeaveRequestDetails';
import MeApi from '../../../../app/api/MeApi';
import LeaveRequestApi from '../../../../app/api/LeaveRequestApi';
import { leaveRequestsListExportColumns } from '../../../../app/models/LeaveRequest';
import componentRequestHandler from '../../../../app/api/helpers/componentRequestHandler';
import { isBlockOutPeriod } from './leaveRequestHelper';
import alert from '../../../../app/helpers/alert';

const propTypes = {
	loggedUserId: PropTypes.string,
	isLoggedUser: PropTypes.bool,
};

class LeaveRequestsContainer extends Component {
	constructor() {
		super();

		const year = moment().year();
		const week = moment().isoWeek();
		const weekObject = moment().year(year).isoWeek(week);
		const startDate = weekObject.startOf('isoweek').format('YYYY-MM-DD');
		const endDate = weekObject.endOf('isoweek').format('YYYY-MM-DD');
		
		this.state = {
			leaveRequestsList: [],
			leaveRequestData: null,
			isLoading: false,
			isSaving: false,
			isExporting: false,
			errorLoading: undefined,
			errorSaving: undefined,
			errorExporting: undefined,
			totalPages: 0,
			totalRecords: 0,
			page: 0,
			pageSize: 10,
			sorted: [],
			filter: {
				filterBy: '',
				startDate,
				endDate,
				workerId: '',
				status: '',
				withPay: '',
				isRevised: '',
				hideAlreadyTaken: true,
				typeId: '',
				year,
				week
			},
		};
	}

	UNSAFE_componentWillMount() {
		const { params } = this.props;
		
		if (params && params.id)
			return this.fetchData(params.id);

		this.fetchDataList();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchData = () => {
		this.setState({ isLoading: true, errorLoading: null });
		
		LeaveRequestApi.getLeaveRequestDetails(this.props.params.id)
			.then(leaveRequestData => {
				this.setState({
					leaveRequestData,
					isLoading: false
				});
			})
			.catch(error => {
				console.error(error);
				this.setState({ errorLoading: error, isLoading: false  });
			});
	}

	fetchDataList = () => {
		this.setState({ isLoading: true, errorLoading: null });

		const { filter, page, pageSize, sorted } = this.state;
		const filterParam = { ...filter, page, pageSize, sorted };

		let promise = LeaveRequestApi.getLeaveRequestsList.bind(this, filterParam);
		if (this.props.isLoggedUser)
			promise = MeApi.getLeaveRequestsList.bind(this, filterParam);
		
		promise()
			.then(data => {
				if (this.unmounted) return;

				const { totalRecords, totalPages, records } = data;

				return this.setState({ 
					isLoading: false, 
					leaveRequestsList: records,
					totalPages,
					totalRecords
				});
			})
			.catch(error => {
				console.error(error);
				this.setState({ errorLoading: error, isLoading: false });
			});
	}

	handleSubmitRequest = () => {
		this.setState({ isSaving: true, errorSaving: null });

		const { leaveRequestData } = this.state;
		const isReview = !this.props.isLoggedUser;
		const { id } = leaveRequestData;

		let promise = LeaveRequestApi.saveNewLeaveRequest.bind(this, leaveRequestData);
		if (id)
			promise = LeaveRequestApi.updateLeaveRequest.bind(this, id, leaveRequestData, isReview);

		promise()
			.then(() => {				
				toastr.success('The data was successfully saved', 'Success!');
				if (this.unmounted) return;

				if (this.props.params && this.props.params.id)
					return this.setState({ isSaving: false }, this.fetchData);

				this.setState({ leaveRequestData: null, isSaving: false }, this.fetchDataList);
			})
			.catch(error => {
				console.error(error);
				this.setState({ isSaving: false, errorSaving: error });
			});
	}

	cancelRequest = leaveRequestId => {
		this.setState({ isSaving: true });

		LeaveRequestApi.cancelLeaveRequest(leaveRequestId)
			.then(() => {
				toastr.success('The data was successfully saved', 'Success!');
				if (this.unmounted) return;

				if (this.props.params && this.props.params.id)
					return this.setState({ isSaving: false }, this.fetchData);

				this.setState({ isSaving: false, leaveRequestData: null }, this.fetchDataList);
			})
			.catch(error => {
				console.error(error);
				this.setState({ isSaving: false, errorSaving: error });
			});
	}
	
	handleChangeLeaveRequestData = data => {
		if (data.startDate && data.endDate && (data.startDate !== this.state.leaveRequestData.startDate || data.endDate !== this.state.leaveRequestData.endDate)) {
			const blockedPeriod = this.props.isLoggedUser && isBlockOutPeriod(data.startDate, data.endDate);
			if (blockedPeriod)
				alert(
					<Callout title="Block Out Period" color="warning" icon="warning">
							You have endeavoured to apply for leave during a BLOCK OUT period. Be aware that your request might be denied.<br /><br/>
							<b>Block Out Period:</b><br />
							{ blockedPeriod }
					</Callout>
				, undefined);
		}

		this.setState({
			leaveRequestData: {
				...this.state.leaveRequestData,
				...data
			}
		});
	}

	handleSearch = () => {
		this.setState({
			page: 0,
			sorted: []
		}, this.fetchDataList);
	}

	handleChangeFilter = filter => {
		this.setState({
			filter: {
				...this.state.filter,
				...filter
			}
		});
	}

	handleChangePage = page => {
		this.setState({ page }, this.fetchDataList);
	}

	handleChangePageSize = pageSize => {
		this.setState({ pageSize, page: 0 }, this.fetchDataList);
	}

	handleChangeSorted = sorted => {
		this.setState({ sorted }, this.fetchDataList);
	}	

	handleClickSave = () => {
		this.form.submit();
	}

	handleClickAddNew = () => {
		this.setState({
			leaveRequestData: {
				id: null,
				typeId: '',
				startDate: moment().format('YYYY-MM-DD'),
				endDate: moment().format('YYYY-MM-DD'),
				withPay: undefined,
				comments: ''
			}
		});
	}

	handleCancelRequest = () => {
		const { id } = this.state.leaveRequestData;
		confirm('Do you really want do cancel this request?', () => this.cancelRequest(id));
	}

	handleViewDetails = leaveRequestData => {
		this.setState({
			leaveRequestData
		});
	}

	handleCloseDetailsModal = () => {
		this.setState({
			errorSaving: null,
			leaveRequestData: null
		});
	}

	handleToggleExportModal = () => {
		this.setState({ showExportModal: !this.state.showExportModal });
	}

	handleChangeFieldsToExport = (fields) => {
		this.setState({ fields });
	}

	handleExport = () => {
		const { filter, fields } = this.state;
		const promise = () => LeaveRequestApi.getLeaveRequestsListReportFile({ ...filter, fields });
		componentRequestHandler(this, promise, undefined, {
			loadingAttrName: 'isExporting',
			errorAttrName: 'errorExporting',
		})
		.finally(this.handleToggleExportModal);
	}

	renderAddEditModal = () => {
		const { isSaving, errorSaving, leaveRequestData } = this.state;
		
		if (!leaveRequestData || leaveRequestData.typeId == undefined)
			return;

		const { id, isActive, startDate, endDate, revisedOnLocal } = leaveRequestData;
		const { isLoggedUser } = this.props;
		const alreadyTaken = moment(endDate) <= moment();
		
		const isReadOnly = (id && ((isLoggedUser && revisedOnLocal && !alreadyTaken) || !isActive)) ? true : false;
		const isReviewEnabled = id && isActive && (!isLoggedUser || (isLoggedUser && revisedOnLocal) || alreadyTaken) ? true : false;

		const blockedPeriod = this.props.isLoggedUser && isBlockOutPeriod(startDate, endDate);

		return (
			<Modal show onHide={this.handleCloseDetailsModal}>
				<Modal.Header closeButton>
					<Modal.Title>
						{ leaveRequestData.id ? 'Edit' : 'New' } Leave Request
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						errorSaving && (
							<ErrorBox error={errorSaving} retryFunc={this.handleClickSave}/>
						)
					}
					{
						isSaving ? (
							<Loader text="Saving the data. Please wait..." />
						) : (
							<>
								<LeaveRequestDetails 
									{ ...leaveRequestData }
									isReadOnly={isReadOnly}
									isReviewEnabled={isReviewEnabled}
									hideEmployee={isLoggedUser}
									formRef={ref => this.form = ref} 
									onChange={this.handleChangeLeaveRequestData}
									onSubmitRequest={this.handleSubmitRequest}
									onCancel={this.handleCancelRequest}
								/>
								{
									blockedPeriod && (
										<Callout title="Block Out Period" color="warning" icon="warning">
											You have endeavoured to apply for leave during a BLOCK OUT period. Be aware that your request might be denied.<br /><br/>
											<b>Block Out Period:</b> { blockedPeriod }
										</Callout>
									)
								}
							</>
						)
					}
				</Modal.Body>
				{
					!isSaving && (
						<Modal.Footer>
							{
								!isReadOnly && (
									<Button className="pull-left" onClick={this.handleClickSave} bsStyle="success">
										{ isReviewEnabled ? 'Submit Review' : 'Submit Request' }
									</Button>
								)
							}
							<Button onClick={this.handleCloseDetailsModal} bsStyle="default">Close</Button>
						</Modal.Footer>
					)
				}
			</Modal>
		);
	}
	
	render() {
		const { state, props } = this;

		return (
			<MainContent title={props.isLoggedUser ? 'My Leave Requests' : props.params && props.params.id ? 'Leave Request Details' : 'Leave Requests'}>
				{ this.renderAddEditModal() }
				<FormGroup>
					<Button
						bsStyle="success"
						block
						bsSize="large"
						onClick={this.handleClickAddNew}
					>
						<Icon name="plus"/>&nbsp;
						{
							props.isLoggedUser ? (
								'Submit Leave Request'
							) : (
								'Add a Leave Request'
							)	
						}
					</Button>
				</FormGroup>
				{
					!props.isLoggedUser && (
						<ContentBox title="Filter" color="primary">
							<LeaveRequestListFilter 
								{...state.filter}
								onChange={this.handleChangeFilter}
							/>
							<Button 
								block
								bsStyle="primary" 
								disabled={state.isLoading}
								onClick={this.handleSearch}
							>
								Search <Icon name="search" />
							</Button>
						</ContentBox>
					)
				}
				{
					state.errorLoading ? (
						<ErrorBox error={state.errorLoading} retryFunc={this.fetchDataList} />
					) : (
						<Fragment>
							{
								!props.isLoggedUser && (
									<ButtonExport 
										showModal={state.showExportModal}
										fields={leaveRequestsListExportColumns}
										selectedFields={state.fields}
										isLoading={state.isExporting}
										error={state.errorExporting}
										onToggleModal={this.handleToggleExportModal}
										onChangeFields={this.handleChangeFieldsToExport}
										onClickExport={this.handleExport}
									/>
								)
							}
							<LeaveRequestList 
								data={state.leaveRequestsList}
								page={state.page}
								pageSize={state.pageSize}
								totalPages={state.totalPages}
								sorted={state.sorted}
								isLoading={state.isLoading}
								hideEmployee={props.isLoggedUser}
								showSimpleList={window._isMobile}
								isCancelEnabled={props.isLoggedUser}
								onPageChange={this.handleChangePage}
								onPageSizeChange={this.handleChangePageSize}
								onSortedChange={this.handleChangeSorted}
								onViewDetails={this.handleViewDetails}
							/>
						</Fragment>
					)
				}
			</MainContent>
		);
	}
}

LeaveRequestsContainer.propTypes = propTypes;

const mapStateToProps = state => ({ loggedUserId: state.user.data.azureId });

export default connect(mapStateToProps)(LeaveRequestsContainer);