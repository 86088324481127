export const Notifications = {
	hubName: 'globalNotificationsHub',
	client: {
		DISPLAY_NOTIFICATION: 'displayNotification'
	},
	server: {}
};

export const PreStart = {
	hubName: 'preStartHub',
	client: {
		SUPERVISOR_REFRESH_SCREEN: 'supervisorRefreshScreen',
		SUPERVISOR_REFRESH_BOX_LOCKER_STATUS: 'supervisorRefreshPreStartBoxLockerStatus',
		DRIVER_REFRESH_ANSWER: 'driverRefreshPreStartAnswer',
		DRIVER_REFRESH_CHECKING_STATUS: 'driverRefreshCheckingStatus'
	},
	server: {
		SUPERVISOR_LOAD_PRE_START_LIST: 'supervisorLoadPreStartList',
		SUPERVISOR_CHANGE_BOX_LOCKER_STATUS: 'supervisorChangeBoxLockerStatus',
		SUPERVISOR_IS_CHECKING_OUT: 'supervisorIsCheckingOut'
	}
};