import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { Panel, FormGroup, FormControl, Button, InputGroup } from 'react-bootstrap';
import { VehicleTypes as VehicleTypesInput, Checkbox } from'../../../common/inputs';

const propTypes = {
	showFiltersBox: PropTypes.bool,
	showSettingsBox: PropTypes.bool,
	vehTypeFilter: PropTypes.arrayOf(PropTypes.number),
	palletJackStatusFilter: PropTypes.arrayOf(PropTypes.number),
	stateFilter: PropTypes.string,
	enableTrafficLayer: PropTypes.bool,
	lastPingTimeLimitInMin: PropTypes.any,
	refreshTimeInSec: PropTypes.any,
	onEnableTrafficLayer: PropTypes.func,
	onChangeFilter: PropTypes.func,
	onForceRefresh: PropTypes.func
};

const FilterAndSettings = ({
	showFiltersBox,
	showSettingsBox,
	vehTypeFilter,
	// palletJackStatusFilter,
	// stateFilter,
	enableTrafficLayer,
	lastPingTimeLimitInMin,
	refreshTimeInSec,
	onEnableTrafficLayer,
	onChangeFilter,
	onForceRefresh
}) => (
	<Fragment>
		<Panel className={!showFiltersBox ? 'hidden' : ''}>
			<Panel.Heading>
				<Panel.Title className="text-center" componentClass="h3">
					<b>FILTERS</b>
				</Panel.Title> 
			</Panel.Heading>
			<Panel.Body>
				<FormGroup>
					<label><Icon name="truck" /> Vehicles</label>
					<VehicleTypesInput
						value={vehTypeFilter}
						onChange={value => onChangeFilter(value, 'vehTypeFilter')}
					/>
				</FormGroup>
				{/* {
					vehTypeFilter.indexOf(VehicleTypes.PALLET_JACK) !== -1 && (
						<FormGroup>
							<label><Icon name="plug" /> Pallet Jack - Status </label>
							<CheckboxList 
								value={palletJackStatusFilter}
								items={[
									{ text: 'On Charge', value: PalletJackStatusTypes.ON_CHARGE },
									{ text: 'Battery OK', value: PalletJackStatusTypes.BATTERY_OK },
									{ text: 'Low Battery', value: PalletJackStatusTypes.LOW_BATTERY }
								]}
								onChange={value => onChangeFilter(value, 'palletJackStatusFilter')}
							/>
						</FormGroup>
					)	
				}
				<FormGroup>
					<label><Icon name="globe" /> State</label>
					<RadioButtonList 
						value={stateFilter}
						items={[
							{ text: 'All', value: '' }, 
							{ text: 'NSW', value: 'NSW' },
							{ text: 'QLD', value: 'QLD' }
						]}
						onChange={(e, value) => onChangeFilter(value, 'stateFilter')}
					/>
				</FormGroup> */}
			</Panel.Body>
		</Panel>
		<Panel className={!showSettingsBox ? 'hidden' : ''}>
			<Panel.Heading>
				<b>SETTINGS</b>
			</Panel.Heading>
			<Panel.Body>
				<FormGroup>
					<label><Icon name="map" /> Map Options</label>
					<Checkbox
						block
						text="Traffic Layer"
						checked={enableTrafficLayer}
						onChange={onEnableTrafficLayer}
					/>
				</FormGroup>
				<FormGroup>
					<label><Icon name="refresh" /> Auto Refresh Time</label>
					<InputGroup>
						<FormControl
							value={refreshTimeInSec}
							bsSize="small"
							componentClass="select"
							onChange={e => onChangeFilter(e.target.value, 'refreshTimeInSec')}
						>
							<option value="">Disabled</option>
							<option value="30">30 seconds</option>
							<option value="60">1 minute</option>
							<option value="120">2 minutes</option>
							<option value="180">3 minutes</option>
							<option value="300">5 minutes</option>
						</FormControl>
						<InputGroup.Button>
							<Button 
								bsStyle="info" 
								bsSize="small"
								onClick={onForceRefresh}
							>
								Force Refresh
							</Button>
						</InputGroup.Button>
					</InputGroup>
				</FormGroup>
				<FormGroup>
					<label><Icon name="clock-o" /> Last Ping Limit</label>
					<FormControl
						id="lastPingTimeLimitInMin"
						value={lastPingTimeLimitInMin}
						bsSize="small"
						componentClass="select"
						onChange={e => onChangeFilter(e.target.value, 'lastPingTimeLimitInMin')}
					>
						<option value="30">30 minutes</option>
						<option value="60">1 hour</option>
						<option value="120">2 hours</option>
						<option value="360">6 hours</option>
						<option value="720">12 hours</option>
						<option value="1440">1 day</option>
						<option value="2880">2 days</option>
					</FormControl>
				</FormGroup>
			</Panel.Body>
		</Panel>
	</Fragment>
);

FilterAndSettings.propTypes = propTypes;

export default FilterAndSettings;