import React from 'react';
import PropTypes from 'prop-types';

import ListsApi from '../../../app/api/ListsApi';
import ListContainer from './_ListContainer';

const propTypes = {
	id: PropTypes.string,
	cssClass: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onLoadList: PropTypes.func,
	showAllOption: PropTypes.bool,
	onlyActive: PropTypes.bool
};

const defaultProps = {
	id: 'id',
	onlyActive: true
};


const SupervisorsList = props => (
	<ListContainer 
		{...props}
		promise={ListsApi.getPreStartSupervisors}
	/>
);

SupervisorsList.propTypes = propTypes;
SupervisorsList.defaultProps = defaultProps;

export { SupervisorsList };