import React, { Component } from 'react';
import moment from 'moment';
import toastr from 'toastr';
import Icon from 'react-fontawesome';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';
import PayrollApi from '../../../app/api/PayrollApi';

import { MainContent } from '../../common/layout';
import { ContentBox, Loader } from '../../common/uiElements';
import { DatePeriodInput, RadioButtonList, YearsAndWeekDays } from '../../common/inputs';

class BundyClockReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			error: null,
			filterBy: 'period',
			startDate: moment().add(-7, 'days'),
			endDate: moment(),
			year: moment().year(),
			week: moment().isoWeek()
		};
	}

	handleChange = e => {
		const { id, value } = e.target;
		if (id === 'year')
			this.setState({ week: '1' });
			
		this.setState({ [id]: value });
	}

	handleChangePeriod = (startDate, endDate) => {
		this.setState({ startDate, endDate });
	}

	handleChangeWeek = ({ week, year }) => {
		this.setState({ week, year });
	}

	handleClickGenerateReport = () => {
		this.setState({ isLoading: true });
		
		let promise;
		if (this.state.filterBy === 'period') {
			const startDate = moment(this.state.startDate).unix();
			const endDate = moment(this.state.endDate).unix();
			promise = PayrollApi.getBundyClockByPeriod(startDate, endDate);
		} else {
			const { year, week } = this.state;
			promise = PayrollApi.getBundyClockByWeek(year, week);
		}
		
		promise.then(() => {
			this.setState({ isLoading: false });
		})
			.catch(error => {
				console.error(error);
				toastr.error('The file could not be retrieved', 'Something went wrong');
				this.setState({ isLoading: false });
			});
	}
	
	render() {
		const s = this.state;

		return (
			<MainContent title="Bundy Clock">
				<ContentBox color="primary">
					<FormGroup>
						<label>Filter By</label>
						<RadioButtonList 
							id="filterBy"
							value={s.filterBy}
							items={[{text: 'Period', value: 'period'}, {text: 'Week', value: 'week'}]} 
							onChange={this.handleChange} 
						/>
					</FormGroup>
					<Row>
						<Col md={6} lg={4}>
							{
								s.filterBy === 'period' ? (
									<DatePeriodInput 
										showDefaultRanges
										startDate={s.startDate} 
										endDate={s.endDate} 
										showHours={false} 
										format="DD/MM/YYYY" 
										onChange={this.handleChangePeriod}
									/>
								) : (
									<YearsAndWeekDays 
										week={s.week}
										year={s.year} 
										onChange={this.handleChangeWeek} 
									/>
								)
							}
						</Col>
					</Row>
					<br />
					<Button bsStyle="primary" disabled={s.isLoading} onClick={this.handleClickGenerateReport}>
						{
							s.isLoading ? (
								<span><Loader inline white /> Generating report...</span>
							) : (
								<span><Icon fixedWidth name="line-chart" /> Generate Report</span>
							)
						}
					</Button>
				</ContentBox>
					
			</MainContent>
		);
	}
}

export default BundyClockReport;