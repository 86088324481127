/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from 'react-fontawesome';
import { Row, Col, FormGroup, Modal, Button } from 'react-bootstrap';
import classNames from 'classnames';

// UI Elements
import { Form } from '../../../common/uiElements';

const propTypes = {
	id: PropTypes.string,
	title: PropTypes.string.isRequired,
	data: PropTypes.array,
	editMode: PropTypes.bool,
	onChange: PropTypes.func,
	readOnly: PropTypes.bool,
	fieldsSchema: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			input: PropTypes.element.isRequired,
			colsClass: PropTypes.string.isRequired,
			showOnTable: PropTypes.bool,
			isRequired: PropTypes.bool,
			dtoFieldName: PropTypes.string
		})
	).isRequired,
	dataSchema: PropTypes.object.isRequired,

	validations: PropTypes.object,

	/** If true, it will keep the last data active from DB */
	mustHaveOneActive: PropTypes.bool,

	/** If true, the user won't be able to edit any already added data  */
	isEditable: PropTypes.bool,
	
	/** If true, the user won't be able to delete any already added data  */
	isRemovable: PropTypes.bool,

	/** If true, the user won't be able to active any already added data  */
	isActivatable: PropTypes.bool
};

const defaultProps = {
	validations: {},
	editMode: false,
	readOnly: false,
	mustHaveOneActive: true,
	data: []
};

class MultipleFieldsWithModal extends Component {
	constructor(props) {
		super();

		this.state = {
			currentActive: null,
			showModal: false,
			indexDataModal: null,
			dataModal: {
				...props.dataSchema
			}
		};
	}

	UNSAFE_componentWillMount() {
		// Store the current active in case of need to keep at least one active
		if (this.props.mustHaveOneActive) {
			const currentActive = this.props.data.find(p => p.isActive);
			currentActive && this.setState({ currentActive });
		}
		
		let data = this.reorderData(this.props.data);
		return this.onChange(data);
	}


	onChange = (data) => {
		var { id, onChange } = this.props;
		data = this.reorderData(data);
		if(onChange)
			return onChange(data, id);
	}

	reorderData = (data) => {
		return data.sortBy(function(o){ return [ -o.isActive, -new Date(o.lastChangedOn || moment()) ]; });	
	}

	handleChangeInput = (e, dtoFieldName) => {
		let fieldId = e.target.id;
		let dataModal = {
			...this.state.dataModal,
			[fieldId]: e.target.value
		};
		
		if (dtoFieldName)
			dataModal[dtoFieldName] = e.target.options[e.target.selectedIndex].text;

		return this.setState({ dataModal });
	}

	handleAdd = () => {
		this.setState({
			showModal: true
		});
	}

	handleSetActive = (indexData) => {
		var { data } = this.props;
		for (let i in data) {
			if (indexData === parseInt(i))
				data[i].isActive = true;
			else
				data[i].isActive = false;
		}

		return this.onChange(data);
	}

	// handleSetInactive = (indexData) => {
	// 	var { data } = this.props;
	// 	data[indexData].isActive = false;

	// 	return this.onChange(data);
	// }

	handleToggleDeletedStatus = (indexData) => {
		let { data } = this.props;
		const { currentActive } = this.state;
		
		if (data[indexData].id)
			data[indexData].isDeleted = !data[indexData].isDeleted;
		else
			data.splice(indexData, 1);

		const hasOneActive = data.find(p => p.isActive);
		if (!hasOneActive && currentActive) {
			let dataToBeActive = data.find(p => p.id === currentActive.id);
			dataToBeActive.isActive = true;
			dataToBeActive.isDeleted = false;
		}

		return this.onChange(data);
	}

	handleSave = () => {
		if (window.$(this.modalBody).find('form').valid()) {
			let { data } = this.props;
			let { dataModal, indexDataModal } = this.state;

			if (indexDataModal !== null) {
				data[indexDataModal] = dataModal;
			} 
			else {
				data.push(dataModal);
				this.handleSetActive(data.length - 1);
			}

			this.onChange(data);
			return this.setState({
				showModal: false,
				indexDataModal: null,
				dataModal: {
					...this.props.dataSchema
				}
			});
		}
	}

	handleCancel = () => {
		this.setState({
			showModal: false,
			dataModal: {
				...this.props.dataSchema
			}
		});
	}

	renderHeader = () => {
		var { fieldsSchema } = this.props;

		return (
			<thead>
				<tr>
					{
						fieldsSchema.map((field, index) => (
							field.showOnTable && <th key={index}>{field.label}</th>
						))
					}
					<th className="text-center" colSpan={3}>Actions</th>
				</tr>
			</thead>
		);
	}

	renderRow = (data, indexData) => {
		var { fieldsSchema, editMode, readOnly, isEditable, isRemovable, isActivatable } = this.props;
		var { id, isActive, isDeleted } = data;

		return (
			<tr key={indexData} className={classNames({'deleted': isDeleted})}>
				{
					fieldsSchema.map((field, indexField) => (
						field.showOnTable && (
							<td key={indexField}>
								{ field.dtoFieldName ? data[field.dtoFieldName] : data[field.id] }
							</td>
						)
					))
				}
				{
					!isEditable && !isRemovable && !isActivatable && id ? (
						<td className="text-center">-</td>
					) : [
						editMode && isActivatable && !isActive && (
							<td className="text-center" width={80} key={0}>
								{
									isDeleted ? (
										<span>-</span>
									) : (
										<Button bsStyle="primary" bsSize="sm" onClick={this.handleSetActive.bind(this, indexData)}>
										Set as Current
										</Button>
									) 
								}
							</td>
						),
						!readOnly && (isEditable || !id) && (
							<td className="text-center" width={40} key={1}>
								{
									isDeleted ? (
										<span>-</span>
									) : (
										<a 
											className={`${readOnly ? 'text-primary' : 'text-warning'} cursor-pointer`}
											title="Edit"
											onClick={() => { this.setState({ showModal: true, indexDataModal: indexData, dataModal: data }); }}
										>
											<Icon name="edit" size="2x" />
										</a>
									)
								}
							</td>
						),
						!readOnly && (isRemovable || !id) && (
							<td className="text-center" colSpan={isDeleted ? 2 : 1} width={40} key={2}>
								{
									isDeleted ? (
										<a className="text-success cursor-pointer" title="Undo" onClick={() => { this.handleToggleDeletedStatus(indexData);}}>
											<Icon name="undo" size="2x" />
										</a>
									) : (
										<a className="text-danger cursor-pointer" title="Delete" onClick={() => { this.handleToggleDeletedStatus(indexData);}}>
											<Icon name="trash" size="2x" />
										</a>
									)
								}
							</td>
						)
					]
				}
			</tr>
		);
	}

	render() {
		const s = this.state;
		var { fieldsSchema, editMode, readOnly, data, title, validations, isActivatable } = this.props;
		let indexData = 0;

		if (data.length === 0 && readOnly)
			return <i>No { title } has been registered.</i>;

		let activesList = data.filter(d => d.isActive);
		let inactiveList = data.filter(d => !d.isActive);
		let newItem = data.find(d => !d.id);

		return (
			<div>
				{
					((editMode && (isActivatable || !newItem)) || (!readOnly && !editMode && data.length === 0)) && (
						<Button bsStyle="warning" onClick={this.handleAdd}>
							<Icon name="plus" /> {`Add a new ${title}`}
						</Button>
					)
				}
				{
					data.length > 0 && (
						<Row>
							<Col xs={12}>
								<div>
									{ (editMode || readOnly ) && <h3 className="title">Current</h3> }
									{
										activesList.length === 0 ? (
											<i>No {title} has been activated yet.</i>
										) : (
											<div className="table-responsive">
												<table className="table table-bordered">
													{ this.renderHeader() }
													<tbody>
														{
															activesList.map(dataActive => (													
																this.renderRow(dataActive, indexData++)
															))
														}										
													</tbody>
												</table>		
											</div>
										)
									}
									{
										inactiveList.length > 0 && (
											<div>
												<h3 className="title">History</h3>
												<div className="table-responsive">
													<table className="table table-bordered table-condensed">
														{ this.renderHeader() }
														<tbody>
															{
																inactiveList.map(dataInactive => this.renderRow(dataInactive, indexData++))
															}
														</tbody>
													</table>
												</div>
											</div>
										)
									}
								</div>
							</Col>
						</Row>
					)
				}
				
				<Modal show={s.showModal} onHide={this.handleCancel}>
					<Modal.Header closeButton>
						<Modal.Title>Add { title }</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div ref={ref => this.modalBody = ref}>
							<Form validations={validations}>
								{
									fieldsSchema.map((field, index) => {
										let inputElement = React.cloneElement(field.input, {
											id: field.id,
											value: s.dataModal[field.id],
											onChange: (event) => this.handleChangeInput(event, field.dtoFieldName)
										});

										return (
											<div key={index} className={field.colsClass}>
												<FormGroup>
													<label>{field.label} {field.isRequired ? '*' : '' }</label>
													{
														readOnly ? (
															<p>{ field.dtoFieldName ? s.dataModal[field.dtoFieldName] : s.dataModal[field.id] }</p>
														) : (
															inputElement
														)
													}
												</FormGroup>
											</div>
										);
									})
								}
							</Form>
						</div>
					</Modal.Body>
					<Modal.Footer>
						{
							!readOnly && (
								<Button bsStyle="success" onClick={this.handleSave}>
									Save
								</Button>
							)
						}
						<Button bsStyle="danger" onClick={this.handleCancel}>
							{readOnly ? 'Close' : 'Cancel'}
						</Button>
					</Modal.Footer>       
				</Modal>
			</div>
		);
	}
}

MultipleFieldsWithModal.propTypes = propTypes;
MultipleFieldsWithModal.defaultProps = defaultProps;

export { MultipleFieldsWithModal };
