import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { CommodityTypesLinksList } from '../../../../../common/lists';

const propTypes = {
	show: PropTypes.bool,
	selectedCommodityLinkId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	placeCategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onClose: PropTypes.func,
	onChangeCommodityType: PropTypes.func,
	onClickReplicate: PropTypes.func,
	children: PropTypes.any,
};

const AccessWindowsReplicateModal = props => {
	return (
		<Modal show={props.show} onHide={props.onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Replicate Windows</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h4><b>Select the commodity you wish to replicate the windows bellow to:</b></h4>
				<CommodityTypesLinksList 
					placeCategoryId={props.placeCategoryId} 
					value={props.selectedCommodityLinkId}
					onChange={props.onChangeCommodityType}
				/>
				<hr />
				{ props.children }
			</Modal.Body> 
			<Modal.Footer>
				{
					props.selectedCommodityLinkId && (
						<Button bsStyle="success" className="pull-left" onClick={props.onClickReplicate}>
							Replicate Now
						</Button>
					)
				}
				<Button bsStyle="danger" onClick={props.onClose}>Cancel</Button>
			</Modal.Footer>
		</Modal>
	);
};

AccessWindowsReplicateModal.propTypes = propTypes;

export default AccessWindowsReplicateModal;