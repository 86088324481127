import store from '../stores/redux';
import groups from '../app/schemas/PermissionGroups';

import Home from '../components/Home';
import PreStartCheckout from '../components/features/panels/PreStartCheckout';
import { Main, ChangeLog } from '../components/common/layout';

// Errors
import { Forbidden, NotFound, UncaughtError, IOSVersionOutOfDate } from '../components/errors';

// Routes
import meRoutes from './me';
//import adminRoutes from './admin';
import complianceRoutes from './compliance';
import communicationRoutes from './communication';
import panelsRoutes from './panels';
import payrollRoutes from './payroll';
import managementRoutes from './management';
import trackingRoutes from './tracking';
import vehiclesRoutes from './vehicles';
import rosterRoutes from './roster';
import intranet from './intranet';
import { allowFeature } from '../app/helpers/permissionHelper';

// Load the Redux Store
var userData = store.getState().user.data;

// When user enter on a page
const onEnter = (nextState, replace) => {
	const returnPath = localStorage.getItem('returnPath');
	if (returnPath) {
		localStorage.removeItem('returnPath');
		return replace(returnPath);
	} else if (nextState.location.pathname.indexOf('auth/callback') !== -1 
		|| nextState.location.pathname.indexOf('null') !== -1)
		return replace('/');

	return checkAuthorization(nextState, replace);
};

// When user changes page
const onChange = (prevState, nextState, replace) => {
	if (prevState.location.pathname === nextState.location.pathname)
		return;

	return checkAuthorization(nextState, replace);
};

const checkAuthorization = (nextState, replace) => {
	const route = nextState.routes.filter(r => r.authorize || r.restrict || r.allowedPermissionId);
	if (route.length > 0) {
		let canAccess = false;
		for (var i = 0; i < route.length; i++) {
			const { authorize, restrict, allowedPermissionId } = route[i];
			if ((checkADGroupPermission(authorize) && checkADGroupRestriction(restrict)) || allowFeature(allowedPermissionId))
				canAccess = true;
			else
				canAccess = false;
		}

		if (nextState.location.pathname !== '/forbidden' && !canAccess) {
			replace('/forbidden');	
		}	
	}
};

function checkADGroupPermission(authorize) {
	const userGroupName = userData.permissionGroupName;

	if (!authorize || userGroupName === groups.ADMIN)
		return true;

	return authorize.indexOf(userGroupName) !== -1;
}

function checkADGroupRestriction(restrict) {
	const userGroupName = userData.permissionGroupName;
	
	if (!restrict || userGroupName === groups.ADMIN)
		return true;
  
	return restrict.indexOf(userGroupName) === -1;
}

// temporary way to block tv users to see anything but the check out
const isTvUser = userData.permissionGroupName === groups.TV;
let routes = [{
	path: '/',
	component: Main,
	indexRoute: { 
		component: isTvUser ? PreStartCheckout : Home 
	},
	onEnter,
	onChange,
	childRoutes: [
		{ path: 'changelog', component: ChangeLog },
		{ path: 'error', component: UncaughtError },
		{ path: 'forbidden', component: Forbidden },
		{ path: 'versionOutdated', component: IOSVersionOutOfDate },
		meRoutes,
		//adminRoutes,
		managementRoutes,
		communicationRoutes,
		payrollRoutes,
		complianceRoutes,
		rosterRoutes,
		trackingRoutes,
		vehiclesRoutes,
		panelsRoutes,
		{ path: 'index.html', component: isTvUser ? PreStartCheckout : Home },
		{ path: '*', component: NotFound }
	]
}];


function renderMenuItems(routes) {
	var items = [];
	for (var i = 0; i < routes.length; i++) {
		const route = routes[i];
    
		// Just adding a Page Not Found in case that the route doesn't have an index route
		if (!route.indexRoute)
			route.indexRoute = { component: NotFound };

		const { path, menuItem, authorize, restrict, allowedPermissionId, childRoutes } = route;

		if (
			menuItem 
			&& (
				(checkADGroupPermission(authorize) && checkADGroupRestriction(restrict))
				|| allowFeature(allowedPermissionId)
			)
		) {
			const { title, icon } = menuItem;
			const urlPath = path.replace(/\(([^)]+)\)/g, '');
			items.push({
				urlPath: urlPath !== '/' ? '/' + urlPath : urlPath,
				title: title, 
				icon: icon,
				nested: childRoutes ? renderMenuItems(childRoutes) : undefined
			});
		}
	}
  
	return items.length > 0 ? items : null;
}

let menuItems = renderMenuItems(routes[0].childRoutes);

menuItems = [
	{ title: 'MENU', header: true },
	{ title: 'Home', urlPath: '/', icon: 'home' },
	...menuItems,
	{ title: 'MESH', header: true },
	...intranet
];

export default routes;
export { menuItems };