import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { Button, FormGroup } from 'react-bootstrap';

// APIs
import CompanyApi from '../../../../../app/api/CompanyApi';

// Components
import CompaniesList from './CompaniesList';
import CompaniesListFilter from './CompaniesListFilter';
import CompanyDetailsModal from '../CompanyDetailsModal';

// UI Elements
import { ContentBox, ErrorBox } from '../../../../common/uiElements';

class CompaniesListContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedCompanyAbn: null,
			companiesList: [],
			totalPages: 0,
			totalRecords: 0,
			page: 0,
			pageSize: 10,
			sorted: [],
			filter: {
				companyName: '',
				abn: ''
			},
			isLoading: true,
			error: null
		};
	}    

	componentDidMount() {
		this.fetchCompaniesList();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchCompaniesList = () => {
		this.setState({ isLoading: true });
		const { filter, page, pageSize, sorted } = this.state;
		CompanyApi.getCompaniesList({ ...filter, page, pageSize, sorted })
			.then(data => {
				if (this.unmounted) return;
			
				const { totalRecords, totalPages, records } = data;

				return this.setState({ 
					isLoading: false, 
					companiesList: records,
					totalPages,
					totalRecords
				});
			})
			.catch(error => {
				return this.setState({ isLoading: false, error });
			});
	}

	handleSearch = () => {
		this.setState({
			page: 0,
			sorted: []
		}, this.fetchCompaniesList);
	};

	handleChangeFilter = (filter) => {
		this.setState({
			filter: {
				...this.state.filter,
				...filter
			}
		});
	};

	handleChangePage = page => {
		this.setState({ page }, this.fetchCompaniesList);
	};

	handleChangePageSize = pageSize => {
		this.setState({ 
			pageSize, 
			page:0 // Reset page to 0 when changing page size
		}, this.fetchCompaniesList);
	};

	handleChangeSorted = sorted => {
		this.setState({ sorted }, this.fetchCompaniesList);
	};

	handleViewDetails = (azureId) => {
		this.setState({ selectedCompanyAbn: azureId });
	};

	handleCloseDetails = (refreshList) => {
		this.setState({ selectedCompanyAbn: null }, () => {
			if (refreshList)
				this.fetchCompaniesList();
		});
	};

	handleBackToList = () => {
		this.setState({ selectedCompanyAbn: null });
	};
	
	render() {
		const { state } = this;

		return (
			<Fragment>
				{ 
					state.selectedCompanyAbn && (
						<CompanyDetailsModal 
							show
							abn={state.selectedCompanyAbn}
							onClose={this.handleCloseDetails} /> 
					)
				}
				<ContentBox color="primary" title="Filters">
					<CompaniesListFilter 
						{...state.filter} 
						onChange={this.handleChangeFilter} 
						onPressEnter={this.handleSearch} 
					/>					
				</ContentBox>
				<FormGroup>
					<Button 
						bsStyle="primary" 
						disabled={state.isLoading}
						block 
						onClick={this.handleSearch}
					>
							Search <Icon name="search" />
					</Button>
				</FormGroup>
				{
					state.error ? (
						<ErrorBox error={state.error} retryFunc={this.fetchCompaniesList} />
					) : (
						<CompaniesList 
							data={state.companiesList}
							page={state.page}
							pageSize={state.pageSize}
							totalPages={state.totalPages}
							sorted={state.sorted}
							isLoading={state.isLoading}
							onPageChange={this.handleChangePage}
							onPageSizeChange={this.handleChangePageSize}
							onSortedChange={this.handleChangeSorted}
							onViewDetails={this.handleViewDetails}
						/>
					)
				}
			</Fragment>
		);
	}
}

CompaniesListContainer.propsTypes = {
	isDirectory: PropTypes.bool
};

export default CompaniesListContainer;