/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { InputGroup, FormControl } from 'react-bootstrap';
import { getEndDateFromYearWeekNumber, getStartDateFromYearWeekNumber } from '../../../app/helpers/dateTimeHelper';
import { parseInt } from 'lodash';
import { Years } from './Years';
import { WeeksOfYear } from './WeeksOfYear';
import { IYearWeekDropdownReturn } from '../../../app/models/Application';

interface IProps {
	week: number;
	year: number;
	yearReadOnly?: boolean;
	weekReadOnly?: boolean;
	minYear?: number;
	maxYear?: number;
	weekNumbersToDisable?: number[];
	onChange: (data: IYearWeekDropdownReturn) => void;
}

const YearsAndWeekDays: React.FC<IProps> = props => {
	const [week, setWeek] = useState(props.week);
	const [year, setYear] = useState(props.year || moment().year());

	useEffect(() => {
		if (!props.onChange)
			return;

		const startDate = getStartDateFromYearWeekNumber(year, week).format('YYYY-MM-DD');
		const endDate = getEndDateFromYearWeekNumber(year, week).format('YYYY-MM-DD');
		props.onChange({ week, year, startDate, endDate });
	}, [week, year])

	function handleChangeYear(e: React.ChangeEvent<FormControl & HTMLInputElement>) {
		setYear(parseInt(e.target.value));
		setWeek(1);
	}

	function handleChangeWeek(e: React.ChangeEvent<FormControl & HTMLInputElement>) {
		setWeek(parseInt(e.target.value));
	}

	return (
		<InputGroup style={{ width: '100%' }}>
			{
				props.yearReadOnly ? (
					<p>{ props.year }</p>
				) : (
					<Years
						id="year" 
						min={props.minYear}
						max={props.maxYear}
						value={year}
						onChange={handleChangeYear}
					/>
				)
			}
			<InputGroup.Button style={{ width: 0 }}></InputGroup.Button>
			{
				props.weekReadOnly ? (
					<p>
						Week {props.week} -&nbsp;
						{ getStartDateFromYearWeekNumber(props.year, props.week).format('DD/MM') } to&nbsp;
						{ getEndDateFromYearWeekNumber(props.year, props.week).format('DD/MM') }
					</p>
				) : (
					<WeeksOfYear 
						id="weekNumber"
						year={year}
						value={week || ''}
						weekNumbersToDisable={props.weekNumbersToDisable}
						onChange={handleChangeWeek}
					/>
				)
			}
		</InputGroup>
	)
}

export { YearsAndWeekDays };