import React from "react";
import PropTypes from "prop-types";
import { Row, Col, FormGroup, FormControl } from "react-bootstrap";

import { Checkbox } from "../../../../common/inputs";
import { CompaniesList, RolesList } from "../../../../common/lists";
import { FieldGroup } from "../../../../common/uiElements";

const propTypes = {
  workerName: PropTypes.string,
  currentCompany: PropTypes.string,
  currentRole: PropTypes.string,
  showEmployedOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
};

const WorkersListFilter = props => {
  const handleChange = (e) => {
    const id = e.target.id;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    props.onChange && props.onChange({ [id]: value });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      props.onPressEnter && props.onPressEnter();
    }
  };

  return (
    <div>
      <Row>
        <Col sm={6} lg={3}>
          <FormGroup>
            <label>Name</label>
            <FormControl
              id="workerName"
              value={props.workerName}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
            />
          </FormGroup>
        </Col>
        <Col sm={6} lg={3}>
          <FormGroup>
            <label>Current Company</label>
            <CompaniesList
              id="currentCompany"
              value={props.currentCompany}
              showAllOption={true}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col sm={6} lg={3}>
          <FormGroup>
            <label>Current Role</label>
            <RolesList
              id="currentRole"
              value={props.currentRole}
              showAllOption={true}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col sm={6} lg={3}>
          <FieldGroup label="Active Only">
            <Checkbox
              block
              id="showEmployedOnly"
              text="Show employed workers only"
              checked={props.showEmployedOnly}
              onChange={handleChange}
            />
          </FieldGroup>
        </Col>
      </Row>
    </div>
  );
};

WorkersListFilter.propTypes = propTypes;

export default WorkersListFilter;
