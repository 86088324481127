import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';

import { setRangeFilter } from '../../../stores/redux/filter';
import noUiSlider from 'nouislider';
import 'nouislider/distribute/nouislider.min.css';
import '../../../app/assets/scripts/wNumb.js';

const propTypes = {
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	step: PropTypes.number,
	start: PropTypes.number,
	pips: PropTypes.array,
	decimals: PropTypes.number,
	prefix: PropTypes.string,
	postfix: PropTypes.string,
	inputField: PropTypes.bool,
	onChange: PropTypes.func,
	onUpdate: PropTypes.func
};

const defaultProps = {
	decimals: 0,
	prefix: '',
	postfix: ''
};

class RangeSlider extends Component {
	componentDidMount() {
		noUiSlider.create(this.sliderTrackingFilters, this.getOptions());

		const { setRangeFilter, onChange, onUpdate } = this.props;
		this.sliderTrackingFilters.noUiSlider.on('update', range => {
			setRangeFilter(range[0]);
			onUpdate && onUpdate(range);
		});

		this.sliderTrackingFilters.noUiSlider.on('change', range => {
			onChange && onChange(range);
		});

		if (this.props.inputField) {
			this.sliderTrackingFilters.noUiSlider.on('update', (values, handle) => {
				this.rangeInput.value = values[handle];
			});
		} 
	}

	createPips = (min, max) => {
		if (max <= 1000)
			return [min, 200, 400, 600, 800, max];
		if (max <= 2000)
			return [min, 500, 1000, 1500, max];
		if (max <= 3000)
			return [min, 500, 1500, 2000, 2500, max];
		if (max <= 4000)
			return [min, 1000, 2000, 3000, max];
		if (max <= 5000)
			return [min, 1000, 2000, 3000, 4000, max];
	}

	getOptions = () => {
		var {
			min, max, step, start, pips, decimals, 
			prefix, postfix 
		} = this.props;

		const options = {
			start: (start || min),
			step: (step || (max / 100)),
			range: {
				'min': min,
				'max': max
			},
			format: window.wNumb({
				decimals
			}),
			pips: {
				mode: 'values',
				values: (pips || this.createPips(min, max)),
				density: 4,
				format: window.wNumb({
					decimals,
					prefix,
					postfix,
					thousand: '.'
				})
			}
		};

		return options;
	}

	handleBlur = e => {
		var range = e.target.value;
		if (range < this.props.min) {
			this.sliderTrackingFilters.noUiSlider.set(this.props.min);      
		}
	}

	handleChange = e => {
		var range = e.target.value;
		if (range >= this.props.min) {
			this.sliderTrackingFilters.noUiSlider.set(range);
		}
	}


	render() {
		const p = this.props;

		return (
			<div>
				<div className="noUi-margin">
					{
						p.inputField && (
							<Col sm={2} style={{paddingLeft: 0}}>
								<FormControl 
									inputRef={ref => this.rangeInput = ref}
									type="number"
									onChange={this.handleChange} 
									onBlur={this.handleBlur} 
								/>
							</Col>
						)
					}
					<Col sm={10}>
						<div ref={ref => this.sliderTrackingFilters = ref}></div>
					</Col>
					{ p.pips && <div style={{height: 60}}></div> }
				</div>
			</div>
		);
	}
}

RangeSlider.propTypes = propTypes;
RangeSlider.defaultProps = defaultProps;

const mapDispatchToProps = { setRangeFilter };

const RangeSliderComponent = connect(null, mapDispatchToProps)(RangeSlider);

export { RangeSliderComponent as RangeSlider } ;