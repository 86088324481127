/* eslint-disable react/no-direct-mutation-state */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DurationParse from '../../../../app/helpers/DurationParse';
import { MainContent } from '../../../common/layout';

import WorkerApi from '../../../../app/api/WorkerApi';
import PreStartFirstCheck from './firstPart/PreStartFirstCheck';
import PreStartLastCheck from './lastPart/PreStartLastCheck';
import PreStartWaitingApproval from './subComponents/PreStartWaitingApproval';
import { ContentBox, ErrorBox, Loader } from '../../../common/uiElements';
import PreStartSecondRun from './PreStartSecondRun';
import PreStartSecondRunQuestion from './subComponents/PreStartSecondRunQuestion';
import PropTypes from 'prop-types';
import MeApi from '../../../../app/api/MeApi';

const propTypes = {
	isPreStartV2: PropTypes.bool
}

const status = {
/** If the last pre start has the "last part" completed or it was not approved and the submitted time is over 30 minutes */
	NEW_PRE_START: 0,

	/** If the last pre start submitted time is less than 5 minutes (for approved) or 30 minutes (not approved) */
	APPROVAL_STATUS_MESSAGE: 1,
	
	/** If the last pre start is pending for approval */
	IS_WAITING_APPROVAL: 2,

	/** If the last pre start was approved and its submitted time is over 5 minutes */
	SHOW_ADD_SECOND_RUN: 3,

	/** If the last pre start was approved and its submitted time is over 5 minutes */
	SHOW_LAST_PART: 4
};

class PreStartContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lastPreStartData: null,
			isLoading: true,
			error: null,
			nextStepTimeSeconds: null,
			showSecondRunQuestion: false,
			preStartStatus: status.NEW_PRE_START,

			tasks: undefined,
			isLoadingTasks: false,
			errorLoadingTasks: undefined
		};

		this.routes = {
			router: this.props.router,
			route: this.props.route,
		};

		// redirect user to compliance/preStart/new if the user is not in the /new route
		if (this.props.route && this.props.route.path !== 'new') {
			this.routes.router.push('/compliance/preStart/new');
		}
	}
	
	componentDidMount() {
		this.fetchLastPreStartData();
	}

	componentWillUnmount() {
		window.clearInterval(this.nextStepCounter);
	}

	fetchLastPreStartData = () => {
		this.setState({ isLoading: true, error: null });
		WorkerApi.getLastPreStart(this.props.loggedUserId)
			.then(lastPreStartData => {            
				if (this.unmounted) return;
				
				this.setState({ lastPreStartData, isLoading: false }, this.setPreStartStatus);
			})
			.catch(error => {
				console.error(error);
				this.setState({ isLoading: false, error });
			})
	}

	fetchTasks = () => {
		this.setState({ isLoadingTasks: true, errorLoadingTasks: undefined });

		const startTime = moment().add(-2, 'hours').format('YYYY-MM-DD HH:mm');
		const finishTime = moment().add(2, 'hours').format('YYYY-MM-DD HH:mm');

		MeApi.getTasks(startTime, finishTime)
			.then(tasks => {
				let pendingRunTasks = tasks.filter(task => task.TaskType?.isRunRequired && task.TaskType?.isDriversLicenceRequired);
				this.setState({ tasks: pendingRunTasks })
			})
			.catch(errorLoadingTasks => this.setState({ errorLoadingTasks }))
			.finally(() => this.setState({ isLoadingTasks: false }));
	}

	setPreStartStatus = () => {
		const { lastPreStartData } = this.state;

		// If the user doesn't have any pre-start at all
		if (!lastPreStartData) 
			return this.setState({ preStartStatus: status.NEW_PRE_START }, this.fetchTasks);

		const { 
			checkOutIsApproved, inspectedOnLocal, checkOutOnLocal, lastReportedOnLocal,
			mainVehFleetNumber, trailer1VehFleetNumber
		} = lastPreStartData;
		
		// Conditions
		const isFinalPreStartCompleted = lastReportedOnLocal ? true : false;
		
		// If the last pre start is fully completed
		if (isFinalPreStartCompleted) {
			// Show a new pre-start form and then fetch the tasks
			return this.setState({ preStartStatus: status.NEW_PRE_START }, this.fetchTasks);
		} else {
			const isPendingForApproval = !checkOutOnLocal;
			const inspectedOnMinutes = moment().diff(moment(inspectedOnLocal), 'minutes');
			//If the last pre start is pending for approval
			if (isPendingForApproval) {
				// Show the Waiting for Approval component
				return this.setState({ preStartStatus: status.IS_WAITING_APPROVAL });
			}
			
			// If the last pre start was approved
			if (checkOutIsApproved === true) {
				// If it has been 12 or more hours from the inspected On, show the last-part form
				if (inspectedOnMinutes >= (60 * 12))
					return this.setState({ preStartStatus: status.SHOW_LAST_PART });

				// If it is just a prime mover, move to last part
				// TODO get vehicle type to check from API 
				else if (!trailer1VehFleetNumber && mainVehFleetNumber.length === 3 && mainVehFleetNumber[0] === '2') 
					return this.setState({ preStartStatus: status.SHOW_LAST_PART });
				// Show the question to check if the driver is going to do another run
				else
					return this.setState({ showSecondRunQuestion: true });
			}

			// If the last pre start was not approved
			else if (checkOutIsApproved === false) {
				// Show a new pre-start form
				return this.setState({ preStartStatus: status.NEW_PRE_START }, this.fetchTasks);
			}
			else {
				// Show a new pre-start form
				return this.setState({ preStartStatus: status.NEW_PRE_START }, this.fetchTasks);
			}
		}
	}

	showLastPart = () => {
		this.setState({ 
			showSecondRunQuestion: false,
			preStartStatus: status.SHOW_LAST_PART
		});
	}

	handleClickAddPayloadsNextRun = nextTaskRuns => {
		this.setState({ 
			nextTaskRuns,
			showSecondRunQuestion: false, 
			preStartStatus: status.SHOW_ADD_SECOND_RUN ,
		});
	}
	
	renderPreStartContent = () => {
		var { lastPreStartData, preStartStatus, nextTaskRuns, tasks } = this.state;
		
		switch (preStartStatus) {
		case status.NEW_PRE_START:
			return (
				<PreStartFirstCheck 
					{...this.routes}
					tasks={tasks}
					isPreStartV2={this.props.isPreStartV2}
					isFirstTime={!lastPreStartData}
					lastPreStartData={lastPreStartData}
				/>
			);
		case status.IS_WAITING_APPROVAL:
			return <PreStartWaitingApproval preStartId={lastPreStartData.id} />;
		case status.APPROVAL_STATUS_MESSAGE:
			return (
				<ContentBox color="primary">
					<Loader />
					<h4 className="text-center">
						Please, wait for <b>{DurationParse.toMinSec(this.state.nextStepTimeSeconds)}</b> to fill the last form
					</h4>
				</ContentBox>
			);
		case status.SHOW_ADD_SECOND_RUN:
			return (
				<PreStartSecondRun 
					preStartId={lastPreStartData.id} 
					{...lastPreStartData}
					PreStartRuns={nextTaskRuns}
					tasks={tasks}
					isPreStartV2={this.props.isPreStartV2 || nextTaskRuns?.length > 0}
					onClickBack={() => this.setState({ showSecondRunQuestion: true })}
				/>
			);
		case status.SHOW_LAST_PART:
			return <PreStartLastCheck  {...this.routes} data={lastPreStartData} />;
		default:
			return <PreStartFirstCheck {...this.routes} isFirstTime={!lastPreStartData} lastPreStartData={lastPreStartData} />;
		}
	}

	renderContent = () => {
		const { state } = this;

		if (state.isLoading)
			return <Loader text="Checking your Pre-Start history..." />;

		if (state.isLoadingTasks)
			return <Loader text="Checking your Pending Tasks..." />;

		if (state.error)
			return <ErrorBox error={state.error} retryFunc={this.fetchLastPreStartData} />;

		if (state.errorLoadingTasks)
			return <ErrorBox error={state.errorLoadingTasks} retryFunc={this.fetchTasks} />;

		if (state.showSecondRunQuestion)
			return (
				<PreStartSecondRunQuestion
					tasks={state.tasks}
					isPreStartV2={this.props.isPreStartV2}
					preStartId={state.lastPreStartData.id}
					mainVehFleetNumber={state.lastPreStartData.mainVehFleetNumber}
					trailer1VehFleetNumber={state.lastPreStartData.trailer1VehFleetNumber}
					trailer2VehFleetNumber={state.lastPreStartData.trailer2VehFleetNumber}
					dollyVehFleetNumber={state.lastPreStartData.dollyVehFleetNumber}
					onClickProceedToLastPart={this.showLastPart}
					onClickAddPayloadsNextRun={this.handleClickAddPayloadsNextRun}
				/>
			)

		return this.renderPreStartContent();
	}

	render() {
		return (
			<MainContent title="Pre-Start - Check List">
				{ this.renderContent() }
			</MainContent>
		);
	}
}

PreStartContainer.propTypes = propTypes;

const mapStateToProps = state => {
	return { loggedUserId: state.user.data.azureId };
};

export default connect(mapStateToProps)(PreStartContainer);