/* eslint-disable eqeqeq */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from 'react-fontawesome';
import DurationParse from '../../../app/helpers/DurationParse';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

const propTypes = {
	vehicle: PropTypes.object
};

var VehicleDetails = (props) => {
	var { vehicle } = props;

	if (!vehicle)
		return <div></div>;

	const vehicleData = { ...vehicle };
	
	window.$.each(vehicleData, (index, value) => {
		if (!value || value == '0')
			vehicleData[index] = '-';
	}); 

	return (
		<div>
			<Row className="aligned-row vehicle-details">
				<Col sm={12} md={4}>
					<table className="table">
						<tbody>
							<tr><td><Icon name="microchip" /> <b>ID:</b></td><td>{vehicleData.vehDeviceId}</td></tr>
							<tr><td><Icon name="map-marker" /> <b>Last Ping:</b></td><td>{moment.unix(vehicle.vehLastPingEpoch).format('DD/MM/YY HH:mm:ss')}</td></tr>
							<tr><td><Icon name="globe" /> <b>State:</b></td><td>{vehicleData.vehState}</td></tr>
							<tr><td><Icon name="cube" /> <b>Type:</b></td><td>{vehicleData.vehTypeName}</td></tr>
						</tbody>
					</table>
				</Col>
				<Col sm={12} md={4}>
					<table className="table">
						<tbody>
							<tr><td><Icon name="check" /> <b>Fleet:</b></td><td>{vehicleData.vehFleetNumber}</td></tr>
							<tr><td><Icon name="check" /> <b>REGO:</b></td><td>{vehicleData.vehRego}</td></tr>
							<tr><td><Icon name="check" /> <b>VIN:</b></td><td>{vehicleData.vehVin}</td></tr>
						</tbody>
					</table>
				</Col>
				<Col sm={12} md={4}>
					<table className="table">
						<tbody>
							<tr><td><Icon name="check" /> <b>Name:</b></td><td>{vehicleData.vehMakeName}</td></tr>
							<tr><td><Icon name="check" /> <b>Model:</b></td><td>{vehicleData.vehMakeModelName}</td></tr>
							<tr><td><Icon name="check" /> <b>Year:</b></td><td>{vehicleData.vehModelYear}</td></tr>
						</tbody>
					</table>
				</Col>
				<Col sm={12} md={4}>
					<table className="table">
						<tbody>
							<tr><td><Icon name="dashboard" /> <b>Odometer:</b></td><td>{vehicleData.vehOdometerTotalMeter === '-' ? '-' : (Math.round(vehicleData.vehOdometerTotalMeter / 1000) + 'Km')}</td></tr>
							<tr><td><Icon name="gears" /> <b>Engine-ON Time:</b></td><td>{vehicleData.vehEngineOnTotalSec === '-' ? '-' : DurationParse.toHour(vehicleData.vehEngineOnTotalSec)}</td></tr>
							<tr><td><Icon name="snowflake-o" /> <b>Freezer-ON Time:</b></td><td>{vehicleData.vehFreezerOnTotalSec === '-' ? '-' : DurationParse.toHour(vehicle.vehFreezerOnTotalSec)}</td></tr>
						</tbody>
					</table>
				</Col>
				<Col sm={12} md={4}>
					<table className="table">
						<tbody>
							<tr><td><Icon name="check" /> <b>Payload:</b></td><td>{vehicleData.vehPayload}</td></tr>
							<tr><td><Icon name="check" /> <b>GVM:</b></td><td>{vehicleData.vehGvm}</td></tr>
							<tr><td><Icon name="check" /> <b>TARE:</b></td><td>{vehicleData.vehTare}</td></tr>
						</tbody>
					</table>
				</Col>
				<Col sm={12} md={4}>
					<table className="table">
						<tbody>
							<tr><td><Icon name="cubes" /> <b>Max Pallets:</b></td><td>{vehicleData.vehMaxPallets}</td></tr>
							<tr><td><Icon name="thermometer" /> <b>Temp. Zones:</b></td><td>{vehicleData.vehNumberTempZones}</td></tr>
						</tbody>
					</table>
				</Col>
			</Row>
		</div>
	);
};

VehicleDetails.propTypes = propTypes;

const mapStateToProps = state => ({ vehicle: state.filters.vehicle });

const VehicleDetailsComponent = connect(mapStateToProps)(VehicleDetails);
export { VehicleDetailsComponent as VehicleDetails };
