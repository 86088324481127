/* eslint-disable react/prop-types */
import React from 'react';
import { ConfirmModal } from '../../components/common/layout';
import { confirmAlert } from 'react-confirm-alert';

/**
 * @param {String|Object} content 
 * @param {Function} proceed 
 * @param {Function=} cancel 
 * @param {Object=} options
 * @param {String=} options.proceedText
 * @param {String=} options.cancelText
 */
export default (content, proceed, cancel, options) => {
	confirmAlert({
		customUI: (({ onClose }) => (
			<ConfirmModal
				{...options}
				text={typeof content === 'string' ? content : null}
				proceed={() => { proceed && proceed(); onClose(); }}
				cancel={() => { cancel && cancel(); onClose(); }}
			>
				{ content }
			</ConfirmModal>
		))
	});
};