import Cookies from 'js-cookie';
import store from '../../stores/redux';

export default  {
	set: (key, value, options = {}) => {
		const loggedUserId = store.getState().user.data.azureId;
		const currentCookies = Cookies.getJSON(loggedUserId);
		const newCookies = JSON.stringify({ ...currentCookies, [key]: value });
		Cookies.set(loggedUserId, newCookies, { sameSite: 'None', ...options });
	},

	get: key => {
		const loggedUserId = store.getState().user.data.azureId;
		const cookies = Cookies.getJSON(loggedUserId);
		return cookies && cookies[key];
	}
};