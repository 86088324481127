/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'cropperjs';
import Icon from 'react-fontawesome';
import { Row, Col, FormGroup, Button, FormControl, Modal } from 'react-bootstrap';
import { Loader } from '../../common/uiElements';
import 'cropperjs/dist/cropper.css';

const propTypes = {
	id: PropTypes.string,
	imgUrl: PropTypes.string, 
	title: PropTypes.string, 
	aspectRatio: PropTypes.number,
	quality: PropTypes.number,
	showPreview: PropTypes.bool,
	showClickableElement: PropTypes.bool,
	clickableElementText: PropTypes.string,
	file: PropTypes.object,
	data: PropTypes.object,
	resultSize: PropTypes.shape({
		width: PropTypes.number.isRequired,
		height: PropTypes.number.isRequired
	}),
	onChange: PropTypes.func,
	onCloseModal: PropTypes.func,
};

const defaultProps = {
	quality: 0.85
};

class ImageWithCropping extends Component {
	constructor() {
		super();
		this.state = {
			imgUrl: null,
			isLoading: false,
			isSaving: false,
			error: false,
			result: null,
			showModal: false
		};
	}

	componentWillUnmount() {
		this.unmounted = true;
	}
  
	componentDidMount() {
		var { imgUrl, file, showClickableElement } = this.props;
		
		if (showClickableElement)
			this.fileInput.addEventListener('click', e => e.stopPropagation(), false);

		if (imgUrl) {
			this.setState({ imgUrl });
		} else if (file && typeof file === 'object' && FileReader) {
			var fr = new FileReader();
			fr.readAsDataURL(file);
			fr.onload = () => this.setState({ imgUrl: fr.result });
		}
	}

	handleRotate = () => {
		this.cropper.rotate(90);
	};

	handleCloseModal = () => {
		if (this.unmounted) return;

		if (!this.state.isSaving) {
			this.setState({ showModal: false });
			this.fileInput.value = null;
		}
	};

	handleChange = e => {
		if (this.unmounted) return;

		var file = e.target.files;
		if (file && file.length > 0) {
			if (file[0].type.indexOf('image') === -1) {
				e.target.value = null;
				return alert('You can only add images.');
			}

			this.setState({ 
				showModal: true, 
				isLoading: true,
				error: false, 
				result: null
			}, () => {
				setTimeout(() => {
					return this.loadFile(file[0]);
				}, 500);
			});
		}
	};

	applyCropper = () => {
		const { aspectRatio } = this.props;
		this.cropper = new Cropper(this.cropperImg, {
			aspectRatio,
			autoCropArea: 0.85,
			minContainerHeight: 250,
			dragMode: 'move',
			cropBoxMovable: false,
			cropBoxResizable: false,
			toggleDragModeOnDblclick: false,
			movable: true,
			guides: false
		});
	}

	onSave = () => {    
		this.setState({
			isSaving: true
		}, () => {
			setTimeout(() => {
				var { id, quality, resultSize, showPreview } = this.props;
				this.cropper.getCroppedCanvas(resultSize)
					.toBlob(file => {
						var returnObj = id ? { [id]: file } : file;
						if (FileReader && file && showPreview) {
							var fr = new FileReader();
							fr.readAsDataURL(file);
							fr.onload = () => { 
								this.setState({ 
									showModal: false, 
									isSaving: false, 
									result: null,
									imgUrl: fr.result
								}, () => this.props.onChange(returnObj));
							};
						} else {
							this.setState({ 
								showModal: false, 
								isSaving: false, 
								result: null
							}, () => this.props.onChange(returnObj));
						}
					}, 'image/jpeg', quality);
			}, 400);
		});
	}

	loadFile = file => {
		if (FileReader && file) {
			var fr = new FileReader();
			fr.readAsDataURL(file);

			fr.onload = () => {
				this.setState({ isLoading: false, result: fr.result });
				this.applyCropper();
			};

			fr.onerror = err => {
				this.setState({ 
					error: true,
					isLoading: false,
					result: null
				});
				console.error(err);
			};
		} else {
			this.setState({ 
				result: null,
				error: false,
				isLoading: false
			});
		}
	}

	render() {  
		const s = this.state;
		const { id, title, showClickableElement, clickableElementText } = this.props; 

		return (
			<div>
				{
					showClickableElement ? (
						<Fragment>
							<a onClick={() => this.fileInput.click()}>
								<u>{ clickableElementText }</u>
							</a>
							<FormControl
								inputRef={ref => this.fileInput = ref} 
								id={id} 
								type="file" 
								className="hidden" 
								accept="image/*" 
								onChange={ this.handleChange }
							/>
						</Fragment>
					) : (
						<Row>
							<Col xs={12}>
								<FormGroup>
									<label>{title}</label>
									<FormControl
										inputRef={ref => this.fileInput = ref} 
										id={id} 
										type="file" 
										className={s.imgUrl && !s.result ? 'ignoreValidation' : ''} 
										accept="image/*" 
										onChange={ this.handleChange }
									/>
									{
										s.imgUrl && (
											<p>
												<br />
												<img 
													className="img-responsive img-thumbnail" 
													style={{maxHeight: '200px'}} 
													src={ s.imgUrl } 
													alt="original"
												/>
											</p>
										)
									}
								</FormGroup>
							</Col>
						</Row>
					)
				}
				<Modal show={s.showModal} onHide={this.handleCloseModal}>
					<Modal.Body>
						{
							s.isLoading || s.isSaving ? (
								<div className="text-center">
									<Loader />
									<h5>
										<b>{s.isSaving ? 'Cropping' : 'Loading'} image...</b>
									</h5>
								</div>
							) : (
								<div>
									<FormGroup>
										<Button
											block
											bsSize="sm"
											bsStyle="info"
											onClick={ this.handleRotate }
										>
											Rotate <Icon name="rotate-right" />
										</Button>
									</FormGroup>
									<FormGroup>
										<img 
											ref={ref => this.cropperImg = ref} 
											style={{maxWidth: '100%'}} 
											src={s.result || ''}
											alt="cropped"
										/>
									</FormGroup>
								</div>
							)
						}
					</Modal.Body>
					<Modal.Footer>
						<Button bsStyle="success" disabled={s.isSaving || s.isLoading} onClick={ this.onSave }>Crop & Save</Button>
						<Button bsStyle="danger" disabled={s.isSaving} onClick={ this.handleCloseModal }>Cancel</Button>
					</Modal.Footer>       
				</Modal>
			</div>
		);
	}
}

ImageWithCropping.propTypes = propTypes;
ImageWithCropping.defaultProps = defaultProps;

export { ImageWithCropping };