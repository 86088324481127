import React from 'react';
import Icon from 'react-fontawesome';
import { MainContent } from '../../../common/layout';
import { SortingRule} from 'react-table';
import { ContentBox, ErrorBox } from '../../../common/uiElements';
import { IVehicleBasicInfo } from '../../../../app/models/Vehicle';
import { IVehicleMaintenanceListDetails, VehicleMaintenanceListFilter } from '../../../../app/models/VehicleMaintenance';
import { IError, ListRequestOptions, ListReturn } from '../../../../app/models/Application';
import componentRequestHandler from '../../../../app/api/helpers/componentRequestHandler';
import MaintenanceListFilter from './list/MaintenanceListFilter';
import VehicleMaintenanceList from './list/MaintenanceList';
import VehicleMaintenanceApi from '../../../../app/api/VehicleMaintenanceApi';
import { Button, FormGroup } from 'react-bootstrap';
import { Moment } from 'moment';
import VehicleMaintenanceModal from './details/VehicleMaintenanceModal';

interface IState {
	filter: VehicleMaintenanceListFilter;
	listRequestOptions: ListRequestOptions;
	listReturn: ListReturn<IVehicleMaintenanceListDetails>
	showModal: boolean,
	selectedMaintenanceId?: number;
	isLoading: boolean;
	error?: IError;
}

class VehiclesMaintenance extends React.Component<{}, IState> {
	state = {
		filter: new VehicleMaintenanceListFilter(),
		listReturn: new ListReturn<IVehicleMaintenanceListDetails>(),
		listRequestOptions: new ListRequestOptions(),
		showModal: false,
		selectedMaintenanceId: undefined,
		isLoading: false,
		error: undefined
	}

	componentDidMount() {
		this.fetchList();
	}

	fetchList = () => {
		const { filter, listRequestOptions } = this.state;
		const promise = () => VehicleMaintenanceApi.getList(listRequestOptions, filter);
		componentRequestHandler(this, promise, 'listReturn');
	}

	changeFilter = (fieldId: string, value: any) => {
		this.setState({
			listRequestOptions: new ListRequestOptions(),
			filter: {
				...this.state.filter,
				[fieldId]: value
			}
		}, this.fetchList)
	}

	changeListOption = (fieldId: string, value: any) => {
		this.setState({
			listRequestOptions: {
				...this.state.listRequestOptions,
				[fieldId]: value
			}
		}, this.fetchList)
	}

	handleChangePage = (page: number) => {
		this.changeListOption('page', page);
	};

	handleChangePageSize = (pageSize: number) => {
		this.changeListOption('pageSize', pageSize);
		this.changeListOption('page', 0);
	};

	handleChangeSorted = (sorted: SortingRule[]) => {
		this.changeListOption('sorted', sorted);
	};

	handleChangeVehicle = (vehicle?: IVehicleBasicInfo) => {
		this.changeFilter('fleetNumber', vehicle?.fleetNumber);
	}

	handleChangePeriod = (startTime: Moment, finishTime: Moment) => {
		this.setState({
			filter: {
				...this.state.filter,
				startTimeLocal: startTime.format('YYYY-MM-DD HH:mm'),
				finishTimeLocal: finishTime.format('YYYY-MM-DD HH:mm'),
			}
		}, this.fetchList)
	}

	handleChangeVehicleTypes = (vehicleTypeIds: number[]) => {
		this.changeFilter('vehicleTypeIds', vehicleTypeIds);
	}

	handleSelectMaintenance = (selectedMaintenanceId?: number) => {
		this.setState({ 
			showModal: true,
			selectedMaintenanceId 
		})
	}

	handleCloseDetailsModal = () => {
		this.setState({ 
			showModal: false,
			selectedMaintenanceId: undefined 
		})
	}

	handleCompleteSaving = () => {
		this.fetchList();
		this.handleCloseDetailsModal()
	}

	render() {
		const { state } = this;
		
		return (
			<MainContent title="Vehicles Services">
				{
					state.showModal && (
						<VehicleMaintenanceModal 
							maintenanceId={state.selectedMaintenanceId}
							onClose={this.handleCloseDetailsModal}
							onCompleteSaving={this.handleCompleteSaving}
						/>
					)
				}
				<FormGroup>
					<Button
						block
						bsStyle="success"
						onClick={() => this.handleSelectMaintenance()}
					>
						<Icon name="plus" /> Book Service 
					</Button>
				</FormGroup>
				<ContentBox title="Filters">
					<MaintenanceListFilter 
						{...state.filter}
						onChangeVehicle={this.handleChangeVehicle}
						onChangeVehicleType={this.handleChangeVehicleTypes}
						onChangePeriod={this.handleChangePeriod}
					/>
				</ContentBox>
				{
					state.error ? (
						<ErrorBox 
							error={state.error} 
							retryFunc={this.fetchList}
						/>
					) : (
						<VehicleMaintenanceList 
							{...state.listRequestOptions}
							{...state.listReturn}
							isLoading={state.isLoading}
							onPageChange={this.handleChangePage}
							onPageSizeChange={this.handleChangePageSize}
							onSortedChange={this.handleChangeSorted}
							onSelectRow={this.handleSelectMaintenance}
						/>
					)
				}
			</MainContent>
		)
	}
}

export default VehiclesMaintenance;