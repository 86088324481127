import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { Row, Col, FormGroup, FormControl, InputGroup, Button } from 'react-bootstrap';
import googleMapsHelper from '../../../app/helpers/googleMapsHelper';

const propTypes = {
	address: PropTypes.shape({
		address1: PropTypes.string,
		address2: PropTypes.string,
		city: PropTypes.string,
		state: PropTypes.string,
		postCode: PropTypes.string,
		country: PropTypes.string
	}),
	isReadOnly: PropTypes.bool,
	onChange: PropTypes.func,
};

const defaultProps = {
	address: {
		address1: '',
		address2: '',
		city: '',
		state: '',
		postCode: '',
		country: ''
	}
};

class CompleteAddressInput extends Component {
	constructor(props) {
		super(props);

		this.defaultAddress = {
			address1: '',
			address2: '',
			city: '',
			state: '',
			postCode: '',
			country: ''
		};

		this.state = this.props.address || this.defaultAddress;
	}
  
	componentDidMount() {
		this.setFieldAutoComplete();
	}

	handleChange = () => {
		this.props.onChange && this.props.onChange(this.state);
		//this.autoCompleteInput.value = '';
	}

	handleChangeInput = e => {
		const { id, value } = e.target;

		return this.setState({ [id]: value }, this.handleChange);
	}

	handleClear = () => {
		this.setState(this.defaultAddress, this.setFieldAutoComplete);
	}

	setFieldAutoComplete = () => {
		if (!this.autoCompleteInput)
			return;

		const options = {
			types: ['geocode', 'establishment'],
			componentRestrictions: { country: 'au' }
		};

		this.autocomplete = new window.google.maps.places.Autocomplete((this.autoCompleteInput), options);
		this.autocomplete.addListener('place_changed', this.fillInAddress);
		window.$(this.autocomplete).val('').focus();
		this.handleChange();
	}

	fillInAddress = () => {
		var place = this.autocomplete.getPlace();
		if (!place.address_components.length)
			return;

		var address = googleMapsHelper.destructGoogleMapsAddress(place.address_components);
		return this.setState(address, this.handleChange);
	}

	geolocate = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(position => {
				const geolocation = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};

				const circle = new window.google.maps.Circle({
					center: geolocation,
					radius: position.coords.accuracy
				});

				this.autocomplete.setBounds(circle.getBounds());
			});
		}
	}

	render() {   
		const { state, props } = this;

		return (
			<div ref={ref => this.form = ref}>
				{
					!props.isReadOnly && (
						<FormGroup>
							{
								!state.city ? (
									<Fragment>
										<InputGroup>
											<InputGroup.Addon>
												<Icon name="map-marker" />
											</InputGroup.Addon>
											<FormControl 
												id="address1"
												inputRef={ref => this.autoCompleteInput = ref}
												placeholder="Type the address..." 
												onFocus={this.geolocate}
											/>
										</InputGroup>
										<p className="text-muted">
											<i>If Google can't find the number or street, try at least the street or suburb, then complete the form afterwards</i>
										</p>
									</Fragment>
								) : (
									<Button
										block
										bsStyle="warning"
										onClick={this.handleClear}
									>
										Clear and Change Address
									</Button>
								)
							}
						</FormGroup>
					)
				}
				{
					(state.city || props.isReadOnly) && (
						<Fragment>
							<Row className="aligned-row">
								<Col sm={6}>
									<FormGroup>
										<label>Street number and name</label>
										{
											props.isReadOnly ? (
												<p>{state.address1}</p>
											) : (
												<FormControl 
													id="address1" 
													value={state.address1} 
													placeholder="e.g.: 7-9 Smoothy Place"
													onChange={this.handleChangeInput} 
												/>
											)
										}
									</FormGroup>
								</Col>
								{
									(!props.isReadOnly || (props.isReadOnly && state.address2)) && (
										<Col sm={6}>
											<FormGroup>
												<label>Unit / Building number</label>
												{
													props.isReadOnly ? (
														<p>{state.address2}</p>
													) : (
														<FormControl 
															id="address2" 
															value={state.address2} 
															placeholder="e.g.: Unit 33"
															onChange={this.handleChangeInput}
														/>
													)
												}
											</FormGroup>
										</Col>
									)
								}
							</Row>
							<Row>
								<Col sm={3}>
									<FormGroup>
										<label>City</label>
										<p>{state.city}</p>
									</FormGroup>
								</Col>
								<Col sm={3}>
									<FormGroup>
										<label>State</label>
										<p>{state.state}</p>
									</FormGroup>
								</Col>
								<Col sm={3}>
									<FormGroup>
										<label>Post Code</label>
										<p>{state.postCode}</p>
									</FormGroup>
								</Col>
								<Col sm={3}>
									<FormGroup>
										<label>Country</label>
										<p>{state.country}</p>
									</FormGroup>
								</Col>
							</Row>
						</Fragment>
					)
				}
			</div>
		);
	}
}

CompleteAddressInput.propTypes = propTypes;
CompleteAddressInput.defaultProps = defaultProps;

export { CompleteAddressInput };