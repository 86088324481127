/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';
import moment from 'moment';
import Icon from 'react-fontawesome';

import CompanyApi from '../../../../app/api/CompanyApi';
import { CompleteAddressInput } from '../../../common/inputs';
import { ContentBox, ErrorBox, Loader } from '../../../common/uiElements';
import componentRequestHandler from '../../../../app/api/helpers/componentRequestHandler';

class CompanyView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			error: false,
			isLoading: false,
			showArchivedFiles: false
		};
	}

	componentDidMount() {
		if (this.props.abn)
			this.fetchData();
	}	

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchData = () => {
		const promise = () => CompanyApi.getCompany(this.props.abn, true);
		componentRequestHandler(this, promise, 'data');
	}

	toggleArchivedFiles = () => {
		this.setState({ showArchivedFiles: !this.state.showArchivedFiles });
	}

	render() {
		const { state, props } = this;

		var filesArray = [];
		if (state.data && state.data.CompanyDocuments) {
			state.data.CompanyDocuments.forEach(function(doc) {
				if (!doc.isArchived || state.showArchivedFiles) {
					var typeId = doc.typeId;
					var typeName = doc.typeName;
					var isAlreadyAdded = filesArray.map(x => x.typeId).indexOf(typeId) !== -1;
			
					if (!isAlreadyAdded)
						filesArray.push({ typeId, typeName, files: [] });

					var typeIdIndex = filesArray.map(x => x.typeId).indexOf(typeId);
					filesArray[typeIdIndex].files.push(doc);
				}
			});
		}				

		return (
			<div>
				<Row>
					{
						props.showBackButton && (
							<Col xs={4} sm={3} md={2}>
								<FormGroup>
									<Link className="btn btn-block btn-danger" to="/management/companies">
										<Icon name="arrow-left" /> Back
									</Link>
								</FormGroup>
							</Col>
						)
					}
					{
						!state.isLoading && !state.error && state.data && (
							<Col xs={8} sm={4} md={3}>
								<FormGroup>
									{
										props.onClickEdit ? (
											<Button block bsStyle="warning" onClick={props.onClickEdit}>
												<Icon name="edit" /> Edit
											</Button>
										) : (
											<Link className="btn btn-block btn-warning" to={('/management/companies/edit/' + state.data.abn)}>
												<Icon name="edit" /> Edit
											</Link>
										)
									}
								</FormGroup>
							</Col>
						)
					}
				</Row>
				{
					state.isLoading ? (
						<Loader isLoading />
					) : (
						state.error ? (
							<ErrorBox error={state.error} retryFunc={this.fetchData} />
						) : (
							state.data && (
								<Fragment>
									<ContentBox title="About" bordered>
										<Row>
											<Col sm={6} md={3}>
												<FormGroup>
													<label>Is Sub-Contractor?</label><br/>
													{ state.data.isSubContractor ? 'YES' : 'NO' }
												</FormGroup>
											</Col>
											<Col sm={6} md={3}>
												<FormGroup>
													<label>Company Name</label><br/>
													{state.data.companyName || '-'}
												</FormGroup>
											</Col>
											<Col sm={6} md={3}>
												<FormGroup>
													<label>ABN</label><br/>
													{state.data.abn || '-'}
												</FormGroup>
											</Col>
											<Col sm={6} md={3}>
												<FormGroup>
													<label>Commencement Date</label><br/>
													{moment(state.data.commencementDate).format('DD/MM/YYYY')}
												</FormGroup>
											</Col>
										</Row>
									</ContentBox>
									<ContentBox title="Contact" bordered>
										<Row>
											<Col sm={4}>
												<FormGroup>
													<label>Phone 1</label><br/>
													{state.data.phone1 || '-'}
												</FormGroup>
											</Col>
											<Col sm={4}>
												<FormGroup>
													<label>Phone 2</label><br/>
													{state.data.phone2 || '-'}
												</FormGroup>
											</Col>
											<Col sm={4}>
												<FormGroup>
													<label>FAX</label><br/>
													{state.data.fax || '-'}
												</FormGroup>
											</Col>
										</Row>
									</ContentBox>
									<ContentBox title="Address" bordered>
										<CompleteAddressInput isReadOnly address={{...state.data }} />
									</ContentBox>
									<ContentBox title="Documents" bordered>
										{
											state.data.CompanyDocuments && state.data.CompanyDocuments.length === 0 ? (
												<i>No Files uploaded.</i>
											) : (
												<div className="nav-tabs-custom">
													<ul className="nav nav-tabs">
														<li className="cursor-pointer" onClick={this.toggleArchivedFiles}>
															{
																state.showArchivedFiles ? (
																	<a className="text-muted">
																		<Icon name="eye-slash" /> Hide archived
																	</a>
																) : (
																	<a>
																		<Icon name="eye" /> Show archived
																	</a>
																)
															}
														</li>
														{
															filesArray.map((tab, index) => {
																return (
																	<li className={index === 0 ? 'active' : ''} key={index}>
																		<a href={('#' + tab.typeId)} data-toggle="tab" aria-expanded="true">{tab.typeName} ({tab.files.length})</a>
																	</li>
																);
															})
														}
													</ul>
													<div className="tab-content">
														{
															filesArray.map((tab, index) => {
																return (
																	<div className={('tab-pane ' + (index === 0 ? 'active' : ''))} id={tab.typeId} key={index}>
																		<table className="table table-bordered table-condensed">
																			<thead>
																				<tr>
																					<th>Type</th>
																					<th>Description</th>
																					<th>Number</th>
																					<th>Expiry Date</th>
																					<th>Download</th>
																				</tr>
																			</thead>
																			<tbody>
																				{
																					tab.files.map((file, indexFile) => {
																						var { typeName, description, number, expiryDate } = file;
																						return (
																							<tr className={(file.isArchived ? 'text-muted' : '')} key={indexFile}>
																								<td>{typeName == '' ? '-' : typeName}</td>
																								<td>{description == '' ? '-' : description}</td>
																								<td>{number == '' ? '-' : number}</td>
																								<td>{expiryDate ? moment(expiryDate).format('DD/MM/YYYY') : '-'}</td>
																								<td width={40} className="text-center">
																									<a onClick={() => CompanyApi.getCompanyDocument(state.data.abn, file.id)}>
																										<Icon name="download" />
																									</a>
																								</td>
																							</tr>
																						);
																					})
																				}
																			</tbody>
																		</table>
																	</div>
																);
															})
														}
													</div>
												</div>
											)
										}
									</ContentBox>
								</Fragment>
							)
						)
					)
				}
			</div>
		);
	}
}

export default CompanyView;